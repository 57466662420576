import CKEditorUtils from '@/core/utils/CKEditorUtils';

/**
 * Ensures that any commands for the CKEditor toolbar which should be in a disabled state, remain in a disabled state
 */
export default class CkEditorCommandStateManager {
  private _disabledCommands: DisabledCommand[] = [];
  constructor(private editor) {}

  /**
   *
   * @param toolbarItemStates A plain JS Object where all properties should have a boolean value
   *
   */
  public syncCommands(toolbarItemStates: {}) {
    const commandNames = Object.getOwnPropertyNames(toolbarItemStates);
    commandNames.forEach((commandName) => {
      const existingStateIndex = this._disabledCommands.findIndex(
        (x) => x.commandName == commandName
      );
      const targetState = toolbarItemStates[commandName];

      // if we already have an existing state for this command
      if (existingStateIndex >= 0) {
        if (!targetState) {
          // If the target state is due to be disabled do nothing
          return;
        } else {
          // if the command should be enabled, clear the existing disabled state
          // remove it from the array
          this._disabledCommands[existingStateIndex].enabledCommand();
          this._disabledCommands.splice(existingStateIndex, 1);
        }
      }

      if (targetState) {
        this.enableCommand(this.editor, commandName);
      } else {
        // if the command should disabled
        // create an internal state, call disableCommand on utils which handles all the
        // internal ckeditor magic to ensure buttons remain disabled
        this._disabledCommands.push({
          commandName: commandName,
          enabledCommand: this.disableCommand(this.editor, commandName),
        });
      }
    });
  }

  public enableAllCommands() {
    this._disabledCommands.forEach((state) => {
      state.enabledCommand();
    });
    this._disabledCommands = [];
  }

  private enableCommand(editor, commandName: string): void {
    var cmd = editor.commands.get(commandName);
    if (!cmd) {
      return;
    }

    cmd.isEnabled = true;
  }
  /**
   * Internal method which handles all the CK editor magic to keep a command disabled
   * @param editor the editor instance which the commands belongs too
   * @param commandName the name of the command to disable
   * @returns
   */
  private disableCommand(editor, commandName): () => void {
    var cmd = editor.commands.get(commandName);
    cmd.on('set:isEnabled', forceDisable, { priority: 'highest' });
    cmd.isEnabled = false;

    return () => {
      cmd.off('set:isEnabled', forceDisable);
      cmd.refresh();
    };
    function forceDisable(evt) {
      evt.return = false;
      evt.stop();
    }
  }
}
interface DisabledCommand {
  commandName: string;
  enabledCommand: () => void;
}
