import {
  ElementType,
  FileAttachmentDto,
  ThemeAttachmentDto,
  ThemeDto,
  ThemeElementDto,
} from '@/api/models';
import PaletteCategory from '@/components/DiagramPalette/PaletteCategory';
import PaletteItem from '@/components/DiagramPalette/PaletteItem';
import store from '@/core/services/store';
import {
  DOCUMENT_NAMESPACE,
  REFRESH_CURRENT_THEME,
} from '@/core/services/store/document.module';
import ElementSvgRenderUtils from '@/core/utils/ElementSvgRenderUtils';
import SvgPaletteBehaviour from '../SvgPaletteBehaviour';
import appConfig from '@/core/config/appConfig';
import { IElementDataProvider } from './IElementDataProvider';
import { convertUrlToDataUrl, getImageSize } from '@/core/utils/common.utils';
import PaletteSubCategory from '../PaletteSubCategory';
import ThemeElementEdgeBehaviour from '../ThemeElementEdgeBehaviour';
import ThemeElementNodeBehaviour from '../ThemeElementNodeBehaviour';
import i18n from '@/core/plugins/vue-i18n';
/**
 * Defines a list of element types that can be rendered into SVG's.
 */
const renderableElementTypes: ElementType[] = [
  ElementType.Node,
  ElementType.Edge,
];
export default class ThemedElementsDataProvider
  implements IElementDataProvider
{
  private items: PaletteItem[] = null;

  constructor() {
    //subscribe to any changes of the current theme
  }
  async getElements(): Promise<PaletteItem[]> {
    // before we return elements, must ensure the document.currentTheme has the correct elements attached.
    await store.dispatch(`${DOCUMENT_NAMESPACE}/${REFRESH_CURRENT_THEME}`);
    await this.setItems(store.state.document.currentTheme);
    await this.createRenders();

    return this.items;
  }

  /**
   * Sets the local @property items property based of the current @param theme
   * @param theme theme to generate palette items from
   * @returns
   */
  private async setItems(theme: ThemeDto) {
    if (!theme?.elements) {
      return;
    }
    // convert all theme elements to PaletteItems
    const elements = theme.elements.map<PaletteItem>(
      this.createPaletteItemFromThemeElement
    );
    // convert all theme attachements to PaletteItems
    let attachments: PaletteItem[] = [];
    if (theme.attachments && theme.attachments.length > 0) {
      attachments = theme.attachments.map<PaletteItem>(
        ThemedElementsDataProvider.createPaletteItemFromAttachment
      );
    }
    for (let att of attachments) {
      if (!att.img.startsWith('data:')) {
        const image = await convertUrlToDataUrl(att.img);
        att.img = image;
      }
      const dim = await getImageSize(att.img);
      att.data.Width = dim.width;
      att.data.Height = dim.height;
    }

    // concat theme elements and attachements and store a copy for rendering.
    this.items = elements.concat(attachments);
  }

  /**
   * Takes a @param themeElement and converts it into a @type PaletteItem
   * @param themeElement The theme element to convert
   * @returns @type PaletteItem
   */
  private createPaletteItemFromThemeElement(
    themeElement: ThemeElementDto
  ): PaletteItem {
    if (themeElement.elementType == ElementType.Node) {
      return {
        category: PaletteCategory.Elements,
        img: null,
        data: {
          element: themeElement,
        },
        text: themeElement.name,
        id: themeElement.id,
        behaviour: ThemeElementNodeBehaviour.INSTANCE,
        canDrag: true,
        subcategory: PaletteSubCategory.ThemedElements,
      };
    } else if (themeElement.elementType == ElementType.Edge) {
      return {
        category: PaletteCategory.Lines,
        img: null,
        data: {
          element: themeElement,
        },
        text: themeElement.name,
        id: themeElement.id,
        behaviour: ThemeElementEdgeBehaviour.INSTANCE,
        canDrag: true,
        subcategory: PaletteSubCategory.ThemedElements,
      };
    }
  }
  /**
   * Takes a @param attachment and converts it into a @type PaletteItem
   * @param attachment The attachment to convert
   * @returns @type PaletteItem
   */
  private static createPaletteItemFromAttachment(
    attachment: ThemeAttachmentDto
  ): PaletteItem {
    return {
      category: PaletteCategory.Logos,
      img: `${appConfig.apiBaseUrl + attachment.fileAttachment.path}`,
      data: attachment,
      text: attachment.fileAttachment.filename,
      id: attachment.id,
      behaviour: SvgPaletteBehaviour.INSTANCE,
      canDrag: true,
    };
  }

  /**
   * Creates render of all @property items
   * @returns
   */
  private async createRenders() {
    if (!this.items || this.items.length <= 0) {
      return;
    }
    // filter items and create their render
    for (const item of this.items.filter(this.shouldCreateRender)) {
      await this.createRender(item);
    }
  }

  private async createRender(paletteItem: PaletteItem) {
    const themeElement: ThemeElementDto = paletteItem.data.element; // default theme element

    // invoke render creation
    let src = await ElementSvgRenderUtils.createSvgFromThemeElement(
      themeElement
    );
    paletteItem.img = src;
  }

  /**
   * Determines whether a render should be generated for the given palette item
   * @param paletteItem
   * @returns true if create render
   */
  private shouldCreateRender(paletteItem: PaletteItem): boolean {
    return (
      paletteItem.category == PaletteCategory.Elements ||
      (paletteItem.category == PaletteCategory.Lines &&
        renderableElementTypes.indexOf(paletteItem.data.element.elementType) >=
          0)
    );
  }
}
