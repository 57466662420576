


























































































































































import { Vue, Component } from 'vue-property-decorator';

import ColorPicker from '@/components/ColorPicker/ColorPicker.vue';

@Component({
  name: 'Dropdowns',
  components: { ColorPicker },
})
export default class Dropdowns extends Vue {
  palettes = [
    {
      name: this.$t('STANDARD').toString(),
      colors: [
        '#000000',
        '#ffffff',
        '#ff0309',
        '#ff980c',
        '#ffff11',
        '#00ff00',
        '#1effff',
        '#4985e7',
        '#150aff',
        '#ff0aff',
      ],
    },
  ];

  openHandler() {
    console.log('test');
  }
}
