











































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

type buttonSizes = 'sm' | 'md' | 'lg' | 'inline';
type buttonVariants = 'default' | 'purple' | 'cyan' | 'transparent' | 'link';

const sizes = {
  sm: 'j-px-8 j-py-7',
  md: 'j-px-12 j-py-9',
  lg: 'j-px-15 j-py-13',
  inline: 'j-px-0 j-py-0',
};

const sizesSquared = {
  sm: 'j-p-7',
  md: 'j-p-9',
  lg: 'j-p-13',
};

const buttonVariants = {
  default:
    'j-text-main-dark j-bg-main-dark-10 hover:j-bg-main-dark-20 focus:j-bg-main-dark-20 active:j-bg-main-dark-40 disabled:j-bg-main-dark-05',
  purple:
    'j-text-white j-bg-accent-purple hover:j-bg-accent-purple-90 focus:j-bg-accent-purple-80 active:j-bg-accent-purple-80',
  cyan: 'j-text-white j-bg-accent-cyan hover:j-bg-accent-cyan-90 focus:j-bg-accent-cyan-80 active:j-bg-accent-cyan-80',
  transparent: 'j-text-main-dark j-bg-transparent',
  link: 'j-text-accent-purple j-bg-transparent'
};

const buttonTextVariants = {
  default:
    'j-text-accent-main-dark hover:j-text-accent-main-dark-60 focus:j-text-accent-main-dark-40 active:j-text-accent-main-dark-40',
  purple:
    'j-text-accent-purple hover:j-text-accent-purple-90 focus:j-text-accent-purple-80 active:j-text-accent-purple-80',
  cyan: 'j-text-accent-cyan hover:j-text-accent-cyan-90 focus:j-text-accent-cyan-80 active:j-text-accent-cyan-80',
  transparent: 'j-text-main-dark',
  link: 'j-text-accent-purple'
};

@Component({
  name: 'AppButton',
})
export default class AppButton extends Vue {
  @Prop({ default: 'md' }) size: buttonSizes; // implement validator
  @Prop({ default: 'default' }) variant: buttonVariants; // implement validator
  @Prop({ default: false, type: Boolean }) text: boolean;
  @Prop({ default: null, type: String }) prependIcon: string;
  @Prop({ default: null, type: String }) appendIcon: string;
  @Prop({ default: null, type: String }) icon: string;
  @Prop({ default: '14', type: String }) iconSize: string;
  @Prop({ default: null, type: Boolean }) square: boolean; // only if prop icon defined
  @Prop({ default: false, type: Boolean }) loading: boolean;
  @Prop({ default: false, type: Boolean }) fullWidth: boolean;
  @Prop({ default: null }) to: RawLocation;
  @Prop({ default: false, type: Boolean }) disabled: boolean;

  get isIconSquared() {
    return !!this.icon && this.square;
  }

  get variantClass() {
    return [
      buttonVariants[this.variant],
      ...[this.isIconSquared ? sizesSquared[this.size] : sizes[this.size]],
    ];
  }

  get textVariantClass() {
    return buttonTextVariants[this.variant];
  }
}
