import {
  DragDropEffects,
  DragDropItem,
  DragSource,
  FreeNodePortLocationModel,
  GraphEditorInputMode,
  GraphItemTypes,
  IEdge,
  IGraph,
  IInputModeContext,
  IModelItem,
  ItemDropInputMode,
  Point,
  PortDirections,
  Rect,
  Size,
} from 'yfiles';
import { addClass, removeClass } from '@/core/utils/html.utils';
import IPaletteBehaviour from '@/components/DiagramPalette/IPaletteBehaviour';
import Vue from 'vue';
import DiagramUtils from '@/core/utils/DiagramUtils';
import PaletteItem from '@/components/DiagramPalette/PaletteItem';
import StyleCreator from '@/core/utils/StyleCreator';
import GraphService from '@/core/services/graph/graph.service';
import config from '@/core/config/diagram.definition.config';
import { AnnotationType } from '@/core/common/AnnotationType';
import { EdgeVisualType } from '@/api/models';
import PaletteItemOffsetHelper from './PaletteItemOffsetHelper';
import SystemEntityTypes from '@/core/services/corporate/SystemEntityTypes';

export default class ThemeElementEdgeBehaviour
  extends Vue
  implements IPaletteBehaviour
{
  private readonly _dragPreviewZIndex: number;
  private static _instance: ThemeElementEdgeBehaviour = null;

  public static get INSTANCE(): ThemeElementEdgeBehaviour {
    return (
      ThemeElementEdgeBehaviour._instance ??
      (ThemeElementEdgeBehaviour._instance = new ThemeElementEdgeBehaviour())
    );
  }

  constructor() {
    super();
    this._dragPreviewZIndex = 10000;
  }

  themeElementDropInputMode: ThemeEdgeDropInputMode = null;

  insert(event, item, graphService: GraphService) {
    const offsetX = PaletteItemOffsetHelper.getOffsetX();
    const offsetY = PaletteItemOffsetHelper.getOffsetY();

    const itemPositionX =
      graphService.graphComponent.viewport.minX +
      graphService.graphComponent.viewport.width /
        config.offsetRightFromCanvasLeftBound.large +
      offsetX;

    const itemPositionY =
      graphService.graphComponent.viewport.centerY -
      graphService.graphComponent.viewport.height / 4 +
      offsetY;

    this.itemCreator(
      graphService.graphComponent.inputModeContext,
      graphService.graphComponent.graph,
      item,
      null,
      DiagramUtils.snapToNearestGridPoint(
        new Point(itemPositionX, itemPositionY)
      ),
      graphService
    );

    PaletteItemOffsetHelper.updatePalettItemDropOffset(
      graphService.graphComponent
    );
  }

  startDrag(event, item, graphService) {
    const inputMode = graphService.graphComponent
      .inputMode as GraphEditorInputMode;

    if (this.themeElementDropInputMode) {
      inputMode.remove(this.themeElementDropInputMode);
    }

    this.themeElementDropInputMode = new ThemeEdgeDropInputMode();
    this.themeElementDropInputMode.itemCreator = (
      context,
      graph,
      draggedItem,
      dropTarget,
      dropLocation
    ) =>
      this.itemCreator(
        context,
        graph,
        draggedItem,
        dropTarget,
        dropLocation,
        graphService
      );

    inputMode.add(this.themeElementDropInputMode);

    // We also want to show a preview of dragged node, while the dragging is not within the GraphComponent.
    // For this, we can provide an element that will be placed at the mouse position during the drag gesture.
    // Of course, this should resemble the node that is currently dragged.
    const dragPreview = document.createElement('div');
    dragPreview.setAttribute('id', 'draggable-theme');
    dragPreview.setAttribute('style', `z-index:${this._dragPreviewZIndex}`);

    dragPreview.appendChild(event.target.cloneNode(true));

    let dragSource = new DragSource(dragPreview);

    dragSource.startDrag(
      new DragDropItem(IEdge.$class.name, item),
      DragDropEffects.ALL,
      true,
      dragPreview
    );

    dragSource.addQueryContinueDragListener((src, args) => {
      if (args.dropTarget === null) {
        removeClass(dragPreview, 'd-none');
      } else {
        addClass(dragPreview, 'd-none');
      }
    });
  }

  private itemCreator(
    context: IInputModeContext,
    graph: IGraph,
    draggedItem: any,
    dropTarget: IModelItem | null,
    dropLocation: Point,
    graphService: GraphService
  ): PaletteItem | null {
    const offset = 50;

    const nodeStyleDto = DiagramUtils.getSystemDefaultEdgeToNoWhereNodeStyle();
    const nodeStyle = StyleCreator.createNodeStyle(nodeStyleDto, []);

    const sourceNodeTag = DiagramUtils.createNewNodeTag(
      SystemEntityTypes.EDGE_TO_NOWHERE_NODE,
      nodeStyleDto
    );
    const targetNodeTag = DiagramUtils.createNewNodeTag(
      SystemEntityTypes.EDGE_TO_NOWHERE_NODE,
      nodeStyleDto
    );

    sourceNodeTag.quickBuildDisabled = true;
    sourceNodeTag.isAnnotation = true;
    sourceNodeTag.annotationType = AnnotationType.EdgeToNowhereNode;
    sourceNodeTag.isIncluded = true;

    targetNodeTag.quickBuildDisabled = true;
    targetNodeTag.isAnnotation = true;
    targetNodeTag.annotationType = AnnotationType.EdgeToNowhereNode;
    targetNodeTag.isIncluded = true;

    let sourcePoint = DiagramUtils.snapToNearestGridPoint(
      new Point(dropLocation.x - offset, dropLocation.y - offset)
    );
    const source = graph.createNode(
      new Rect(sourcePoint, new Size(1, 1)),
      nodeStyle,
      sourceNodeTag
    );

    let targetPoint = DiagramUtils.snapToNearestGridPoint(
      new Point(dropLocation.x + offset, dropLocation.y + offset)
    );
    const target = graph.createNode(
      new Rect(targetPoint, new Size(1, 1)),
      nodeStyle,
      targetNodeTag
    );

    const sourcePort = graph.addPort(
      source,
      FreeNodePortLocationModel.NODE_CENTER_ANCHORED
    );
    const targetPort = graph.addPort(
      target,
      FreeNodePortLocationModel.NODE_CENTER_ANCHORED
    );

    const edgeStyleDto = draggedItem.data.element.style;
    const edgeStyle = StyleCreator.createEdgeStyle(edgeStyleDto);

    const edgeTag =
      graphService.diagramTypeHelper.createEdgeTagFromThemeElement(
        draggedItem.data.element
      );
    DiagramUtils.fixEdgePort(edgeTag, true, PortDirections.SOUTH);
    DiagramUtils.fixEdgePort(edgeTag, false, PortDirections.WEST);
    edgeTag.name = draggedItem.data.element.name;
    edgeTag.autoCreated = true;
    edgeTag.isOrphan = true;
    edgeTag.isFixedInLayout = true;

    const edge = graph.createEdge(sourcePort, targetPort, edgeStyle, edgeTag);

    if (
      edge.tag.style.visualType !== EdgeVisualType.Arc &&
      edge.tag.style.visualType !== EdgeVisualType.Straight
    ) {
      graph.addBend(
        edge,
        new Point(dropLocation.x - offset, dropLocation.y + offset)
      );
    }

    context.canvasComponent.focus();

    if (this.themeElementDropInputMode) {
      (context.canvasComponent.inputMode as GraphEditorInputMode).remove(
        this.themeElementDropInputMode
      );
    }
    return draggedItem;
  }
}

class ThemeEdgeDropInputMode extends ItemDropInputMode<PaletteItem> {
  previewSourceNodeOffset: Point = null;
  previewTargetNodeOffset: Point = null;
  previewBendOffset: Point = null;

  constructor() {
    super(IEdge.$class.name);
    this.previewSourceNodeOffset = new Point(-50, -50);
    this.previewTargetNodeOffset = new Point(50, 50);
    this.previewBendOffset = new Point(-50, 50);
  }

  /**
   * @returns {IEdge}
   */
  // @ts-ignore
  get draggedItem() {
    return this.dropData;
  }

  /**
   * @param {Point} dragLocation - The location to return the drop target for.
   * @returns {IModelItem}
   */
  getDropTarget(dragLocation) {
    const parentMode = this.inputModeContext.parentInputMode;
    if (parentMode instanceof GraphEditorInputMode) {
      const hitItems = parentMode.findItems(dragLocation, [
        GraphItemTypes.NODE,
        GraphItemTypes.EDGE,
      ]);
      if (hitItems.size > 0) {
        return hitItems.first();
      }
    }
    return null;
  }

  initializePreview() {
    if (this.showPreview && this.draggedItem) {
      super.initializePreview();
    }
  }

  /**
   * @param {IGraph} previewGraph - The preview graph to fill.
   */
  populatePreviewGraph(previewGraph: IGraph) {
    const graph = previewGraph;

    const item = this.dropData as PaletteItem;
    let style = StyleCreator.createEdgeStyle(item.data.element.style);

    const source = graph.createNode(
      new Rect(this.previewSourceNodeOffset, new Size(0, 0))
    );
    const target = graph.createNode(
      new Rect(this.previewTargetNodeOffset, new Size(0, 0))
    );

    const dummyEdge = graph.createEdge(source, target, style);

    if (
      item.data.element.style.visualType !== EdgeVisualType.Arc &&
      item.data.element.style.visualType !== EdgeVisualType.Straight
    ) {
      graph.addBend(dummyEdge, this.previewBendOffset);
    }
  }

  /**
   * @param {IGraph} previewGraph - The preview graph to update.
   * @param {Point} dragLocation - The current drag location.
   */
  updatePreview(previewGraph: IGraph, dragLocation: Point) {
    previewGraph.setNodeCenter(
      previewGraph.nodes.elementAt(0),
      dragLocation.add(this.previewSourceNodeOffset)
    );
    previewGraph.setNodeCenter(
      previewGraph.nodes.elementAt(1),
      dragLocation.add(this.previewTargetNodeOffset)
    );
    const edge = previewGraph.edges.first();
    previewGraph.clearBends(edge);
    if (
      this.dropData.data.element.style.visualType !== EdgeVisualType.Arc &&
      this.dropData.data.element.style.visualType !== EdgeVisualType.Straight
    ) {
      previewGraph.addBend(edge, dragLocation.add(this.previewBendOffset));
    }
    if (this.inputModeContext.canvasComponent !== null) {
      this.inputModeContext.canvasComponent.invalidate();
    }
  }
}
