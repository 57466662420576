import {
  CompositeStyleDefinitionDto,
  CompositeNodeStyleShapeDto,
  DashStyleType,
  NodeShape,
} from '@/api/models';
import { DefaultColors } from '@/core/common/DefaultColors';

/**
 * A static definition of a composite node style, consists of an Triangle with a nested Triangle with a nested Triangle
 * @returns a set of definitions
 */
export default function triangleWithTriangleWithTriangleDefinitions(): CompositeStyleDefinitionDto[] {
  return [
    new CompositeStyleDefinitionDto(
      new CompositeNodeStyleShapeDto(
        {
          color: DefaultColors.WHITE,
        },
        {
          thickness: 1,
          dashStyle: {
            type: DashStyleType.Solid,
          },
          fill: {
            color: DefaultColors.BLACK,
          },
        },
        NodeShape.Triangle
      ),
      {
        bottom: 0,
        right: 0,
        top: 0,
        left: 0,
      }
    ),
    new CompositeStyleDefinitionDto(
      new CompositeNodeStyleShapeDto(
        {
          color: DefaultColors.WHITE,
        },
        {
          thickness: 1,
          dashStyle: {
            type: DashStyleType.Solid,
          },
          fill: {
            color: DefaultColors.BLACK,
          },
        },
        NodeShape.Triangle
      ),
      {
        bottom: 5,
        right: 5,
        top: 5,
        left: 5,
      }
    ),
    new CompositeStyleDefinitionDto(
      new CompositeNodeStyleShapeDto(
        {
          color: DefaultColors.WHITE,
        },
        {
          thickness: 1,
          dashStyle: {
            type: DashStyleType.Solid,
          },
          fill: {
            color: DefaultColors.BLACK,
          },
        },
        NodeShape.Triangle
      ),
      {
        bottom: 10,
        right: 10,
        top: 10,
        left: 10,
      }
    ),
  ];
}
