import { IEdge, INode } from 'yfiles';
import DataPropertyTagParameter from '../parameters/DataPropertyTagParameter';
import { ITypedMatcher } from './IMatcher';

export default class DataPropertyTagMatcher
  implements ITypedMatcher<INode | IEdge, DataPropertyTagParameter> {
  public canMatch(
    candidate: any,
    parameter: DataPropertyTagParameter
  ): boolean {
    return candidate instanceof INode || candidate instanceof IEdge;
  }

  public isMatch(
    candidate: INode | IEdge,
    parameter: DataPropertyTagParameter
  ): boolean {
    // Tag filters behave like metadata and should not affect the search results
    return true;

    // const dataPropertyTags = candidate.tag?.dataPropertyTags;
    // if (!dataPropertyTags) {
    //   return false;
    // }

    // return dataPropertyTags.some((tag) => {
    //   return tag.name == parameter?.tagName;
    // });
  }
}
