import { ShapeNodeShape, Arrow, ArrowType, Point } from 'yfiles';

import {
  NodeSize,
  NodeShape,
  DashStyleType,
  NodeVisualType,
  DataPropertyType,
  EdgeVisualType,
  CompositeShape,
} from '@/api/models';

let config = {
  nodeSizeFactors: {
    [NodeSize.ExtraSmall]: 1,
    [NodeSize.Small]: 1.8,
    [NodeSize.Medium]: 2.6,
    [NodeSize.Large]: 3.4,
    [NodeSize.ExtraLarge]: 4.2,
  },
  nodeBaseSizes: {
    width: 45,
    height: 45,
  },
  nodeShapes: {
    [NodeShape.Circle]: ShapeNodeShape.ELLIPSE,
    [NodeShape.Oval]: ShapeNodeShape.ELLIPSE,
    [NodeShape.Square]: ShapeNodeShape.RECTANGLE,
    [NodeShape.Rectangle]: ShapeNodeShape.RECTANGLE,
    [NodeShape.Triangle]: ShapeNodeShape.TRIANGLE,
    [NodeShape.RoundedRectangle]: ShapeNodeShape.ROUND_RECTANGLE,
    [NodeShape.Diamond]: ShapeNodeShape.DIAMOND,
    [NodeShape.ShearedRectangle]: ShapeNodeShape.SHEARED_RECTANGLE,
    [NodeShape.ShearedRectangle2]: ShapeNodeShape.SHEARED_RECTANGLE2,
    [NodeShape.Triangle2]: ShapeNodeShape.TRIANGLE2,
    [NodeShape.Star5]: ShapeNodeShape.STAR5,
    [NodeShape.Star6]: ShapeNodeShape.STAR6,
    [NodeShape.Star8]: ShapeNodeShape.STAR8,
    [NodeShape.Trapez]: ShapeNodeShape.TRAPEZ,
    [NodeShape.Trapez2]: ShapeNodeShape.TRAPEZ2,
    [NodeShape.Hexagon]: ShapeNodeShape.HEXAGON,
    [NodeShape.Octagon]: ShapeNodeShape.OCTAGON,
    [NodeShape.FatArrow]: ShapeNodeShape.FAT_ARROW,
    [NodeShape.FatArrow2]: ShapeNodeShape.FAT_ARROW2,
    [NodeShape.Pill]: ShapeNodeShape.ROUND_RECTANGLE,
    [NodeShape.TrapezShort]: ShapeNodeShape.TRAPEZ2,
  },
  nodeGridSizes: {
    [NodeShape.Circle]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 8],
        [NodeSize.Small]: [12, 12],
        [NodeSize.Medium]: [18, 18],
        [NodeSize.Large]: [26, 26],
        [NodeSize.ExtraLarge]: [32, 32],
      },
    },
    [NodeShape.Oval]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 4],
        [NodeSize.Small]: [12, 6],
        [NodeSize.Medium]: [18, 10],
        [NodeSize.Large]: [26, 12],
        [NodeSize.ExtraLarge]: [32, 16],
      },
    },
    [NodeShape.Square]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 8],
        [NodeSize.Small]: [12, 12],
        [NodeSize.Medium]: [18, 18],
        [NodeSize.Large]: [26, 26],
        [NodeSize.ExtraLarge]: [32, 32],
      },
    },
    [NodeShape.Rectangle]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 4],
        [NodeSize.Small]: [12, 6],
        [NodeSize.Medium]: [18, 10],
        [NodeSize.Large]: [26, 12],
        [NodeSize.ExtraLarge]: [32, 16],
      },
    },
    [NodeShape.Triangle]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 4],
        [NodeSize.Small]: [12, 6],
        [NodeSize.Medium]: [18, 10],
        [NodeSize.Large]: [26, 12],
        [NodeSize.ExtraLarge]: [32, 16],
      },
    },
    [NodeShape.RoundedRectangle]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 4],
        [NodeSize.Small]: [12, 6],
        [NodeSize.Medium]: [18, 10],
        [NodeSize.Large]: [26, 12],
        [NodeSize.ExtraLarge]: [32, 16],
      },
    },
    [NodeShape.Octagon]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 8],
        [NodeSize.Small]: [12, 12],
        [NodeSize.Medium]: [18, 18],
        [NodeSize.Large]: [26, 26],
        [NodeSize.ExtraLarge]: [32, 32],
      },
    },
    [NodeShape.Diamond]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 8],
        [NodeSize.Small]: [12, 12],
        [NodeSize.Medium]: [18, 18],
        [NodeSize.Large]: [26, 26],
        [NodeSize.ExtraLarge]: [32, 32],
      },
    },
    [NodeShape.ShearedRectangle]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 8],
        [NodeSize.Small]: [12, 12],
        [NodeSize.Medium]: [18, 18],
        [NodeSize.Large]: [26, 26],
        [NodeSize.ExtraLarge]: [32, 32],
      },
    },
    [NodeShape.ShearedRectangle2]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 8],
        [NodeSize.Small]: [12, 12],
        [NodeSize.Medium]: [18, 18],
        [NodeSize.Large]: [26, 26],
        [NodeSize.ExtraLarge]: [32, 32],
      },
    },
    [NodeShape.Triangle2]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 4],
        [NodeSize.Small]: [12, 6],
        [NodeSize.Medium]: [18, 10],
        [NodeSize.Large]: [26, 12],
        [NodeSize.ExtraLarge]: [32, 16],
      },
    },
    [NodeShape.Star5]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 8],
        [NodeSize.Small]: [12, 12],
        [NodeSize.Medium]: [18, 18],
        [NodeSize.Large]: [26, 26],
        [NodeSize.ExtraLarge]: [32, 32],
      },
    },
    [NodeShape.Star6]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 8],
        [NodeSize.Small]: [12, 12],
        [NodeSize.Medium]: [18, 18],
        [NodeSize.Large]: [26, 26],
        [NodeSize.ExtraLarge]: [32, 32],
      },
    },
    [NodeShape.Star8]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 8],
        [NodeSize.Small]: [12, 12],
        [NodeSize.Medium]: [18, 18],
        [NodeSize.Large]: [26, 26],
        [NodeSize.ExtraLarge]: [32, 32],
      },
    },
    [NodeShape.Trapez]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 8],
        [NodeSize.Small]: [12, 12],
        [NodeSize.Medium]: [18, 18],
        [NodeSize.Large]: [26, 26],
        [NodeSize.ExtraLarge]: [32, 32],
      },
    },
    [NodeShape.Trapez2]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 8],
        [NodeSize.Small]: [12, 12],
        [NodeSize.Medium]: [18, 18],
        [NodeSize.Large]: [26, 26],
        [NodeSize.ExtraLarge]: [32, 32],
      },
    },
    [NodeShape.Hexagon]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 8],
        [NodeSize.Small]: [12, 12],
        [NodeSize.Medium]: [18, 18],
        [NodeSize.Large]: [26, 26],
        [NodeSize.ExtraLarge]: [32, 32],
      },
    },
    [NodeShape.FatArrow]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 8],
        [NodeSize.Small]: [12, 12],
        [NodeSize.Medium]: [18, 18],
        [NodeSize.Large]: [26, 26],
        [NodeSize.ExtraLarge]: [32, 32],
      },
    },
    [NodeShape.FatArrow2]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 8],
        [NodeSize.Small]: [12, 12],
        [NodeSize.Medium]: [18, 18],
        [NodeSize.Large]: [26, 26],
        [NodeSize.ExtraLarge]: [32, 32],
      },
    },
    [NodeShape.Pill]: {
      sizes: {
        [NodeSize.ExtraSmall]: [10, 4],
        [NodeSize.Small]: [14, 6],
        [NodeSize.Medium]: [20, 8],
        [NodeSize.Large]: [26, 10],
        [NodeSize.ExtraLarge]: [32, 12],
      },
    },
    [NodeShape.TrapezShort]: {
      sizes: {
        [NodeSize.ExtraSmall]: [8, 4],
        [NodeSize.Small]: [12, 6],
        [NodeSize.Medium]: [18, 9],
        [NodeSize.Large]: [26, 13],
        [NodeSize.ExtraLarge]: [32, 16],
      },
    },
  },
  nodeLabelOffsets: {
    shape: {
      [NodeShape.Triangle]: new Point(0, 10),
      [NodeShape.Triangle2]: new Point(0, -15),
    },
    composite: {
      [CompositeShape.RectanglesWithOval]: new Point(15, 5),
      [CompositeShape.TriangleStackedRight]: new Point(9.5, 10),
      [CompositeShape.TriangleStackedLeft]: new Point(-9.5, 10),
    },
  },
  // padding applied to help determine the where a nodes label can be moved too
  nodeLabelPadding: 9,
  nodeRadiusFlexRatios: {
    [NodeShape.Pill]: 0.25,
  },
  svgElementFactors: {
    [NodeSize.ExtraSmall]: 3,
    [NodeSize.Small]: 5,
    [NodeSize.Medium]: 6,
    [NodeSize.Large]: 7,
    [NodeSize.ExtraLarge]: 9,
  },
  label: {
    //COLORHERE
    defaultLabel: {
      backgroundColor: 'white',
      inset: 20,
    },
    imageLabel: {
      backgroundColor: 'white',
      inset: 0,
    },
    edgeLabel: {
      backgroundColor: 'white',
      inset: 0,
    },
    annotationTextLabel: {
      backgroundColor: 'transparent',
      inset: 0,
      margin: 10,
    },
    distanceToEdge: 5,
  },
  grid: {
    size: 12,
    jumpSize: 1,
    nodeSpacingFactors: 8,
    color: '#DEDEDE',
  },
  stretchNodes: [
    NodeShape.Oval,
    NodeShape.Rectangle,
    NodeShape.RoundedRectangle,
  ],
  stretchNodeFactor: 2,
  supportedNodeVisualTypes: [
    NodeVisualType.Shape,
    NodeVisualType.Composite,
    NodeVisualType.Image,
  ],
  supportedEdgeVisualTypes: [
    EdgeVisualType.Curved,
    EdgeVisualType.Straight,
    EdgeVisualType.Elbow,
    EdgeVisualType.Arc,
  ],
  supportedDashStyles: [DashStyleType.Solid, DashStyleType.Dash],
  defaultFontStyles: [
    {
      model: 'normal',
      view: 'normal',
      title: 'Normal',
    },
    {
      model: 'heading',
      view: 'h1',
      title: 'Heading',
    },
    {
      model: 'subheading',
      view: 'h2',
      title: 'Subheading',
    },
    {
      model: 'footnote',
      view: 'h3',
      title: 'Footnote',
    },
  ],
  supportedFontFamilies: [
    'Arial',
    'Arial Narrow',
    'Calibri',
    'Courier New',
    'Georgia',
    'Trebuchet MS',
    'Times New Roman',
    'Verdana',
  ],
  supportedFontStyles: ['Normal', 'Italic'],
  supportedFontWeights: ['Normal', 'Bold'],
  supportedFontTextDecoration: ['None', 'Underline'],
  supportedFontSizes: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24],
  dataPropertyControlTypes: [
    { type: DataPropertyType.Boolean, icon: 'la-toggle-on' },
    { type: DataPropertyType.Date, icon: 'la-calendar-day' },
    { type: DataPropertyType.DateTime, icon: 'la-business-time' },
    { type: DataPropertyType.Dropdown, icon: 'la-stream' },
    { type: DataPropertyType.Html, icon: 'la-code' },
    { type: DataPropertyType.MultiOptions, icon: 'la-list' },
    { type: DataPropertyType.MultiSelect, icon: 'la-list' },
    { type: DataPropertyType.Number, icon: 'la-arrows-alt-v' },
    { type: DataPropertyType.Options, icon: 'la-list' },
    { type: DataPropertyType.Range, icon: 'la-sliders-h' },
    { type: DataPropertyType.Time, icon: 'la-clock' },
    { type: DataPropertyType.Text, icon: 'la-comment' },
  ],
  zoomLevels: [0.25, 0.35, 0.5, 0.65, 0.85, 1, 1.2, 2, 4],
  defaultZoomLevel: 1.2,
  defaultEdgeLabelHitTestRadius: 18,
  defaultHitTestRadius: 4,
  appearingGridZoomLevel: 0.84,
  defaultFontFamily: 'Arial',
  defaultFontSize: 10,
  defaultTextColor: '#000000',
  defaultFontWeight: 'normal',
  defaultFontStyle: 'normal',
  defaultTextDecoration: 'none',
  defaultTextBoxStyle: {
    backgroundColor: '',
    borderStyle: 'solid',
    borderWidth: '0px',
    borderColor: '#000000',
    textOverflow: 'clip',
    overflow: 'hidden',
    fontSize: '10pt',
    fontFamily: 'Arial',
    fontWeight: '',
    color: '#000000',
    fontStyle: 'normal',
    textDecoration: 'none',
  },
  maxDecoratorSlots: 4,
  offsetRightFromCanvasLeftBound: {
    small: 8,
    medium: 6,
    large: 4,
  },
  paletteItemDropOffsetStep: 1,
  cursorsUrls: {
    DOT_NORMAL: '/resources/cursors/dot-normal.cur',
    DOT_SMALL: '/resources/cursors/dot-normal.cur',
    CIRCLE: '/resources/cursors/dot-normal.cur',
  },
  diagramLogoWidth: 200,
  sideBarWidth: 280,
  sideBarThumbWidth: 20,
  quickBuildCompleteFadeOutMs: 7000,
  contextMenu: {
    contextMenuWidth: 180,
    submenuMaxWidth: 270,
  },
  maxNodeEdgeRadius: 50,
  diagramScrollbarSize: 15,
};

export default config;
