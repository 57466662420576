export enum CommandType {
  'bold',
  'underline',
  'italic',
  'fontsize',
  'fontfamily',
  'fontcolor',
  'fontbackgroundcolor',
  'indent',
  'bulletedList',
  'numberedList',
  'imageinsert',
  'heading',
  'backgroundColor',
  'borderColor',
  'borderWidth',
  'pageBreak',
  'insertTable',
  'undo',
  'redo',
  'cut',
  'copy',
  'paste',
}
