import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class ErrorMixin extends Vue {
  error403(returnUrl?: string, message?: string) {
    this.$bvModal
      .msgBoxOk(
        message
          ? message
          : this.$t('YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_RESOURCE'),
        {
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
        }
      )
      .then(() => {
        this.$router.push(returnUrl);
      });
  }

  error404(returnUrl?: string) {
    this.$router.push({
      name: '404',
      query: {
        returnUrl: returnUrl,
      },
    });
  }
}
