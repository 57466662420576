import {
  Size,
  IInputModeContext,
  Point,
  INode,
  IRenderContext,
  Visual,
  SvgVisual,
  Rect,
  GraphComponent,
  GraphEditorInputMode,
  CanvasComponent,
} from 'yfiles';
import HitResult, { HitResultLocation } from '../HitResult';
import ImageButtonNodeDecorator from './ImageButtonNodeDecorator';
import JigsawNodeDecorator from './JigsawNodeDecorator';
import DecorationState from '../DecorationState';
import DecorationStateManager from '@/core/services/DecorationStateManager';
import store from '@/core/services/store';
import { QuickBuildState } from '@/api/models';
import {
  DOCUMENT_NAMESPACE,
  GET_READONLY,
} from '@/core/services/store/document.module';
import DiagramUtils from '@/core/utils/DiagramUtils';

export interface CycleShapeButtonState extends DecorationState {
  alwaysVisible: boolean;
}

/**
 * A decorator placed around the nodes permiter
 */
export default class CycleShapeButtonDecorator implements JigsawNodeDecorator {
  public $class = 'CycleShapeButtonDecorator';
  public static INSTANCE: CycleShapeButtonDecorator =
    new CycleShapeButtonDecorator();
  private imageButtonNodeDecorator: ImageButtonNodeDecorator;
  private imageSrc = '/media/svg/shapes/shapes-solid.svg';

  constructor(options?: { size?: Size }) {
    let size = options?.size ?? new Size(28, 28);
    // create imageButton to proxy the rendering
    this.imageButtonNodeDecorator = new ImageButtonNodeDecorator({
      imageSrc: this.imageSrc,
      size: size,
    });
    this.imageButtonNodeDecorator.getLayout = this.getLocation.bind(this);
  }
  isVisible(renderContext: IRenderContext, node: INode): boolean {
    const gc = renderContext.canvasComponent as GraphComponent;
    const isReadonly = store.getters[
      `${DOCUMENT_NAMESPACE}/${GET_READONLY}`
    ] as boolean;

    if (
      isReadonly ||
      gc.selection.selectedEdges.size > 0 ||
      gc.selection.selectedNodes.size > 1 ||
      this.isEdgeCreationInProgress(gc)
    ) {
      return false;
    }

    return true;
  }

  isHit(context: IInputModeContext, location: Point, node: INode): HitResult {
    let hitResult = this.imageButtonNodeDecorator.isHit(
      context,
      location,
      node
    );

    if (hitResult.isHit) {
      return new HitResult({
        hitLocation: HitResultLocation.DECORATOR,
        isHit: true,
        meta: null,
        decoratorType: this.$class,
      });
    }
    return HitResult.NONE;
  }

  createVisual(context: IRenderContext, node: INode): Visual {
    let visual = this.imageButtonNodeDecorator.createVisual(
      context,
      node
    ) as SvgVisual;

    const hitResult = this.isHit(
      context.canvasComponent.inputModeContext,
      context.canvasComponent.lastMouseEvent.location,
      node
    );

    const isNodeSelected = this.isNodeSelected(context, node);
    const isLabelEditing = DiagramUtils.isLabelEditing(
      context.canvasComponent,
      node
    );

    const state = this.getState(node);
    const gc = context.canvasComponent as GraphComponent;
    this.attachEvents(visual.svgElement, gc);
    this.setVisibility(
      visual,
      state,
      isNodeSelected,
      isLabelEditing,
      hitResult,
      gc
    );
    return visual;
  }

  updateVisual(
    context: IRenderContext,
    node: INode,
    oldVisual: Visual
  ): Visual {
    if (oldVisual == null) {
      return this.createVisual(context, node);
    }

    let visual = this.imageButtonNodeDecorator.updateVisual(
      context,
      node,
      oldVisual
    ) as SvgVisual;

    const state = this.getState(node);
    const hitResult = this.isHit(
      context.canvasComponent.inputModeContext,
      context.canvasComponent.lastMouseEvent.location,
      node
    );
    const gc = context.canvasComponent as GraphComponent;
    const isNodeSelected = this.isNodeSelected(context, node);
    const isLabelEditing = DiagramUtils.isLabelEditing(
      context.canvasComponent,
      node
    );
    if (visual != oldVisual) {
      this.attachEvents(visual.svgElement, gc);
    }

    this.setVisibility(
      visual,
      state,
      isNodeSelected,
      isLabelEditing,
      hitResult,
      gc
    );
    return visual;
  }

  private isNodeSelected(context: IRenderContext, node: INode): boolean {
    return (context.canvasComponent as GraphComponent).selection.isSelected(
      node
    );
  }

  private getLocation(node: INode): Rect {
    const nodeLayout = node.layout;
    let size = this.imageButtonNodeDecorator.size;

    let x = nodeLayout.maxX + size.width / 2 - 13;
    let y = nodeLayout.maxY + 1;

    return new Rect(x, y, size.width, size.height);
  }

  attachEvents(element: SVGElement, gc: CanvasComponent) {
    element.addEventListener('mouseenter', () => {
      gc.invalidate();
    });
    element.addEventListener('mouseleave', () => {
      gc.invalidate();
    });
  }

  private isEdgeCreationInProgress(graphComponent: GraphComponent): boolean {
    let geim = graphComponent.inputMode as GraphEditorInputMode;

    return geim?.createEdgeInputMode?.isCreationInProgress;
  }

  private setVisibility(
    visual: SvgVisual,
    state: CycleShapeButtonState,
    isNodeSelected: boolean,
    isLabelEditing: boolean,
    hitResult: HitResult,
    graphComponent: GraphComponent
  ) {
    if (isNodeSelected && isLabelEditing) {
      this.hide(visual.svgElement);
      return;
    }

    const noEdgesSelected = graphComponent.selection.selectedEdges.size === 0;

    if (noEdgesSelected && state.alwaysVisible) {
      this.show(visual.svgElement);
      return;
    }
    if (noEdgesSelected && this.quickStartEnabled) {
      this.show(visual.svgElement);
      return;
    }

    if (
      noEdgesSelected &&
      isNodeSelected &&
      graphComponent.selection.selectedNodes.size <= 1 &&
      !this.isEdgeCreationInProgress(graphComponent)
    ) {
      this.show(visual.svgElement);
      return;
    }

    if (
      noEdgesSelected &&
      hitResult.isHit &&
      hitResult.decoratorType == this.$class &&
      !this.isEdgeCreationInProgress(graphComponent)
    ) {
      this.show(visual.svgElement);
      return;
    }

    this.hide(visual.svgElement);
  }

  private show(element: SVGElement) {
    element.style.animation = '';
    element.style.opacity = '1';
    element.style.cursor = 'pointer';
  }

  private hide(element: SVGElement) {
    element.style.opacity = '0';
    element.style.cursor = 'default';
  }

  private getState(node: INode): CycleShapeButtonState {
    return DecorationStateManager.getState(
      CycleShapeButtonDecorator.INSTANCE,
      node
    ) as CycleShapeButtonState;
  }

  private get quickStartEnabled(): boolean {
    return (
      store?.state?.document?.quickBuildState === QuickBuildState.InProgress ??
      false
    );
  }

  public defaultState(): CycleShapeButtonState {
    return {
      alwaysVisible: false,
    };
  }
}
