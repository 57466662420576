
















































































































import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'Selects',
})
export default class Selects extends Vue {
  selection = { icon: 'owner', label: 'Owner only', value: '1' };
  selectionValueOnly = '4';
  // selection = { icon: 'owner', label: 'Owner only', value: '1' };
  // selectionValueOnly = '4';
  demoOptions = [
    { icon: 'owner', label: 'Owner only', value: '1' },
    { icon: 'users-group', label: 'Group access', value: '2' },
    { icon: 'users-group', label: 'Another', value: '3' },
    {
      icon: 'users-group',
      label: 'Logn loooong loooong loooong text',
      value: '4',
    },
  ];
  disabledOptions = ['1', '3'];

  multipleSelection = ['2'];

  demoOptions2 = [
    { emoji: '💠', text: 'Cool stuff!', id: 1 },
    { emoji: '💩', text: 'Not Cool stuff!', id: 2 },
    { emoji: '🍏', text: 'Nice green apple', id: 3 },
    { emoji: '🌶️', text: 'Hop Chilli Pepper', id: 4 },
  ];
  selectedDemoOptions2 = null;
  selectedDemoOptionsMul2 = [2];

  logoPositions = [
    { text: 'Hidden', value: 0, translatedText: 'Hidden' },
    { text: 'TopLeft', value: 1, translatedText: 'Top Left' },
    { text: 'TopRight', value: 2, translatedText: 'Top Right' },
    { text: 'BottomLeft', value: 3, translatedText: 'Bottom Left' },
    { text: 'BottomRight', value: 4, translatedText: 'Bottom Right' },
  ];
  selectedLogoPosition = 1;
}
