import {
  NodeStyleOverrideDto,
  ShapeNodeStyleDto,
  CompositeNodeStyleDto,
  DataPropertyStyleDto,
  DataPropertyDto,
  CreateOrEditDataPropertyDto,
  INodeStyleDto,
} from '@/api/models';
import { ToggleState, ToggleStateType } from '@/core/common/ToggleState';
import CompositeNodeStyle from '@/core/styles/composite/CompositeNodeStyle';
import JigsawNodeStyle from '@/core/styles/JigsawNodeStyle';
import DataPropertyUtils from '@/core/utils/DataPropertyUtils';
import DiagramUtils from '@/core/utils/DiagramUtils';
import StyleCreator from '@/core/utils/StyleCreator';
import { IGraph, INode, ShapeNodeStyle } from 'yfiles';
import DocumentService from '../document/DocumentService';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import BackgroundGraphService from './BackgroundGraphService';
import DiagramWriter from './serialization/diagram-writer.service';
import ThemeService from './ThemeService';

type NodeStyleFormatFunction = (
  graph: IGraph,
  item: INode,
  styleValue: any,
  compositeStyleIndex?: any
) => void;

export default class DataPropertyStyleService {
  /**
   * Turn on/off using of data property style and apply it for given node
   * @param dataPropertyDefinitionId
   * @param graph
   * @param node
   * @param toggleState
   * @returns
   */
  public static toggleDataPropertyStyleState(
    dataPropertyDefinitionId: number,
    graph: IGraph,
    node: INode,
    toggleState: ToggleStateType
  ) {
    const previousDataPropertyStyleState = node.tag.dataPropertyStyle.isActive;
    const selectedDataProperty = DataPropertyUtils.getDataPropertyFromNode(
      node,
      dataPropertyDefinitionId
    );
    if (!selectedDataProperty) {
      this.updateNodeWithThemeStyle(node, graph);
      return;
    }

    // Set node's data property style state or create if it is not exists
    let dpStyle = DataPropertyStyleService.getDataPropertyStyle(
      dataPropertyDefinitionId,
      selectedDataProperty
    );
    if (dpStyle && toggleState != ToggleStateType.NO_CHANGE) {
      node.tag.dataPropertyStyle.isActive =
        ToggleState.fromToggleStateType(toggleState);
    } else {
      dpStyle = DataPropertyStyleService.createDataPropertyStyle(
        selectedDataProperty,
        node
      );
      DataPropertyStyleService.addDataPropertyStyle(dpStyle);
      node.tag.dataPropertyStyle.isActive =
        ToggleState.fromToggleStateType(toggleState);
    }

    // Update node style
    if (node.tag.dataPropertyStyle.isActive) {
      DataPropertyStyleService.updateNodeWithDataPropertyStyle(
        graph,
        node,
        dpStyle
      );
    } else if (
      // need to use here "previousDataPropertyStyleState" to check for previous value before it was changed.
      previousDataPropertyStyleState &&
      toggleState == ToggleStateType.OFF
    ) {
      this.updateNodeWithThemeStyle(node, graph);
    }
  }

  /**
   * Update data property style and apply it for all nodes
   * @param dataPropertyDefinitionId
   * @param graph
   * @param node
   * @param styleValue fill, stroke, dash style, thickness
   * @param action function that will be called to update node with given style value
   */
  public static formatDataPropertyNodeStyle(
    dataPropertyDefinitionId: number,
    graph: IGraph,
    node: INode,
    styleValue: any,
    action: NodeStyleFormatFunction
  ) {
    const selectedDataProperty = DataPropertyUtils.getDataPropertyFromNode(
      node,
      dataPropertyDefinitionId
    );
    // Update nodes with new style value
    if (selectedDataProperty) {
      graph.nodes.forEach((item) => {
        if (
          item.style instanceof JigsawNodeStyle &&
          (item.style.baseStyle instanceof ShapeNodeStyle ||
            item.style.baseStyle instanceof CompositeNodeStyle) &&
          item.tag.dataPropertyStyle.isActive
        ) {
          let nodeDataProperty = DataPropertyUtils.getDataPropertyFromNode(
            item,
            dataPropertyDefinitionId
          );
          if (
            nodeDataProperty &&
            nodeDataProperty.value == selectedDataProperty.value
          ) {
            if (item.style.baseStyle instanceof ShapeNodeStyle) {
              action(graph, item, styleValue);
            } else if (item.style.baseStyle instanceof CompositeNodeStyle) {
              item.style.baseStyle.styleDefinitions.forEach((def, index) => {
                action(graph, item, styleValue, index);
              });
            }

            item.tag.dataPropertyStyle.isActive = true;
          }
        }
      });
    }

    DataPropertyStyleService.updateDataPropertyStyle(
      dataPropertyDefinitionId,
      selectedDataProperty,
      node
    );
  }

  /**
   * Update data property style for all pages
   * @param dataPropertyDefinitionId
   * @param node
   * @param styleValue fill, stroke, dash style, thickness
   * @param action function that will be called to update node with given style value
   */
  public static formatDataPropertyNodeStyleForAllPages(
    dataPropertyDefinitionId: number,
    node: INode,
    styleValue: any,
    action: NodeStyleFormatFunction
  ) {
    DocumentService.pagesWithDiagram.forEach((page) => {
      let backgroundGraphService = new BackgroundGraphService(page.diagram);
      DataPropertyStyleService.formatDataPropertyNodeStyle(
        dataPropertyDefinitionId,
        backgroundGraphService.graph,
        node,
        styleValue,
        action
      );
      page.diagram = DiagramWriter.fromGraph(
        backgroundGraphService.graph,
        page.diagram
      );

      if (page.subPageRefs) {
        for (const ref of page.subPageRefs) {
          backgroundGraphService = new BackgroundGraphService(ref.diagram);
          DataPropertyStyleService.formatDataPropertyNodeStyle(
            dataPropertyDefinitionId,
            backgroundGraphService.graph,
            node,
            styleValue,
            action
          );
          ref.diagram = DiagramWriter.fromGraph(
            backgroundGraphService.graph,
            ref.diagram
          );
        }
      }
    });

    EventBus.$emit(EventBusActions.DOCUMENT_DATA_PROPERTY_STYLE_CHANGED);
  }

  /**
   * Apply given data property style for node
   * @param graph
   * @param node
   * @param dpStyle
   */
  public static updateNodeWithDataPropertyStyle(
    graph: IGraph,
    node: INode,
    dpStyle: DataPropertyStyleDto
  ) {
    const originalStyle = DiagramWriter.convertNodeStyle(node);
    if (originalStyle instanceof ShapeNodeStyleDto) {
      originalStyle.fill = dpStyle.style.fill;
      originalStyle.stroke = dpStyle.style.stroke;
    } else if (originalStyle instanceof CompositeNodeStyleDto) {
      originalStyle.styleDefinitions.forEach((definition) => {
        definition.nodeStyle.fill = dpStyle.style.fill;
        definition.nodeStyle.stroke = dpStyle.style.stroke;
      });
    }
    const newStyle = StyleCreator.createNodeStyle(originalStyle);
    DiagramUtils.setStyle(graph, node, newStyle, true);
    node.tag.dataPropertyStyle.isActive = true;
  }

  /**
   * Apply current theme style for node after data property style switched off
   * Keep original shape and size
   * @param node
   * @param graph
   */
  public static updateNodeWithThemeStyle(node: INode, graph: IGraph) {
    node.tag.dataPropertyStyle.isActive = false;
    const el = ThemeService.getCurentThemeElementByNode(node);
    if (el) {
      const nodeStyle = DiagramWriter.convertNodeStyle(node);
      const themeElementStyle = { ...el.style };

      if (nodeStyle instanceof ShapeNodeStyleDto) {
        themeElementStyle.shape = nodeStyle.shape;
        themeElementStyle.size = nodeStyle.size;
        themeElementStyle.visualType = nodeStyle.visualType;
      }

      const newNodeStyle = StyleCreator.createNodeStyle(themeElementStyle);
      DiagramUtils.setStyle(graph, node, newNodeStyle);
      node.tag.style = themeElementStyle;
    }
  }

  /**
   * Update node style with original node style, if data property style is active
   * @param nodeStyle
   * @param node
   * @returns
   */
  public static updateNodeStyleWithDataPropertyStyle(
    nodeStyle: INodeStyleDto,
    node: INode
  ): INodeStyleDto {
    if (
      node.style instanceof JigsawNodeStyle &&
      node.style.baseStyle instanceof ShapeNodeStyle &&
      node.tag.dataPropertyStyle.isActive
    ) {
      const overridedNodeStyle = DiagramWriter.convertNodeStyle(
        node
      ) as ShapeNodeStyleDto;
      (nodeStyle as ShapeNodeStyleDto).fill = overridedNodeStyle.fill;
      (nodeStyle as ShapeNodeStyleDto).stroke = overridedNodeStyle.stroke;
    } else if (
      node.style instanceof JigsawNodeStyle &&
      node.style.baseStyle instanceof CompositeNodeStyle &&
      node.tag.dataPropertyStyle.isActive
    ) {
      const overridedNodeStyle = DiagramWriter.convertNodeStyle(
        node
      ) as CompositeNodeStyleDto;
      const styleDefinitions = (nodeStyle as CompositeNodeStyleDto)
        .styleDefinitions;
      for (let i = 0; i < styleDefinitions.length; ++i) {
        styleDefinitions[i].nodeStyle.fill =
          overridedNodeStyle.styleDefinitions[i].nodeStyle.fill;
        styleDefinitions[i].nodeStyle.stroke =
          overridedNodeStyle.styleDefinitions[i].nodeStyle.stroke;
      }
    }

    return nodeStyle;
  }

  public static updateDataPropertyStyle(
    dataPropertyDefinitionId: number,
    dataProperty: DataPropertyDto | CreateOrEditDataPropertyDto,
    node: INode
  ) {
    let dpStyle = DataPropertyStyleService.getDataPropertyStyle(
      dataPropertyDefinitionId,
      dataProperty
    );
    if (dpStyle) {
      const nodeStyleDto = DiagramWriter.convertNodeStyle(node);
      if (nodeStyleDto instanceof ShapeNodeStyleDto) {
        dpStyle.style.fill = nodeStyleDto.fill;
        dpStyle.style.stroke = nodeStyleDto.stroke;
      } else if (nodeStyleDto instanceof CompositeNodeStyleDto) {
        dpStyle.style.fill = nodeStyleDto.styleDefinitions[0].nodeStyle.fill;
        dpStyle.style.stroke =
          nodeStyleDto.styleDefinitions[0].nodeStyle.stroke;
      }
    } else {
      dpStyle = DataPropertyStyleService.createDataPropertyStyle(
        dataProperty,
        node
      );
      DataPropertyStyleService.addDataPropertyStyle(dpStyle);
    }
  }

  public static createDataPropertyStyle(
    dataProperty: DataPropertyDto | CreateOrEditDataPropertyDto,
    node: INode
  ): DataPropertyStyleDto {
    const nodeStyleDto = DiagramWriter.convertNodeStyle(node);
    let overrideStyle = new NodeStyleOverrideDto();
    if (nodeStyleDto instanceof ShapeNodeStyleDto) {
      overrideStyle.fill = nodeStyleDto.fill;
      overrideStyle.stroke = nodeStyleDto.stroke;
    } else if (nodeStyleDto instanceof CompositeNodeStyleDto) {
      overrideStyle.fill = nodeStyleDto.styleDefinitions[0].nodeStyle.fill;
      overrideStyle.stroke = nodeStyleDto.styleDefinitions[0].nodeStyle.stroke;
    }
    return new DataPropertyStyleDto(
      dataProperty.dataPropertyDefinitionId,
      dataProperty.value,
      overrideStyle
    );
  }

  public static getDataPropertyStyle(
    dataPropertyDefinitionId: number,
    dataProperty: DataPropertyDto | CreateOrEditDataPropertyDto
  ): DataPropertyStyleDto {
    return DocumentService.currentDocument.dataPropertyStyles.find(
      (dp) =>
        dp.dataPropertyDefinitionId == dataPropertyDefinitionId &&
        dp.value == dataProperty.value
    );
  }

  public static addDataPropertyStyle(
    style: DataPropertyStyleDto
  ): DataPropertyStyleDto {
    DocumentService.currentDocument.dataPropertyStyles.push(style);
    return style;
  }

  public static removeDataPropertyStyle(style: DataPropertyStyleDto) {
    const index = DocumentService.currentDocument.dataPropertyStyles.findIndex(
      (s) =>
        s.dataPropertyDefinitionId == style.dataPropertyDefinitionId &&
        s.value == style.value
    );
    if (index >= 0) {
      DocumentService.currentDocument.dataPropertyStyles.splice(index, 1);
    }
  }

  public static isDataPropertyStyleActive(node: INode) {
    return !!node?.tag?.dataPropertyStyle?.isActive;
  }
}
