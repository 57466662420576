import axios, {
    AxiosResponse
} from 'axios';
import {
    GetDiagramForViewDto,
    ZadarResponse,
    CloneDiagramDto,
    AIOutputDto
} from './models'
export default class DiagramsApiService {
    public static getDiagramForView(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetDiagramForViewDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Diagrams/GetDiagramForView`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static clone(body: CloneDiagramDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < number >>> {
        return axios.request( < any > {
            url: `/api/services/app/Diagrams/Clone`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static delete(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Diagrams/Delete`,
            method: 'delete',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static uploadFile(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < AIOutputDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Diagrams/UploadFile`,
            method: 'post',
            clientConfig: clientConfig
        })
    }
}