import { PluginObject } from 'vue';

const componentContext = require.context('@/components/shared', true, /\.vue$/);

function registerComponent(fileName: any, context: any, vue) {
  const config = context(fileName);
  const pathParts = fileName.split('/');
  const compName = pathParts[pathParts.length - 1].replace('.vue', '');
  if (compName.startsWith('App')) {
    console.debug(`Component ${compName} being installed`);
    vue.component(compName, config.default || config);
  }
}

export const SharedComponents: PluginObject<any> = {
  install(Vue) {
    console.debug('Installing shared components...');

    Vue.component('Compute', {
      render() {
        return this.$scopedSlots.default(this.$attrs);
      },
    });

    Vue.component('VNodeWrapper', {
      props: {
        value: { type: [Object, Array], required: true },
        tag: { type: String, default: 'div' },
        options: {
          type: Object,
          default: () => {},
        },
      },

      render: function (h) {
        let nodes = this.value;
        if (!Array.isArray(nodes)) {
          nodes = [nodes];
        }
        return h(this.tag, this.options, nodes);
      },
    });

    componentContext
      .keys()
      .forEach((fileName) =>
        registerComponent(fileName, componentContext, Vue)
      );
  },
};
