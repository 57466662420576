import axios, {
    AxiosResponse
} from 'axios';
import {
    PagedResultDtoOfGetThemeForViewDto,
    ZadarResponse,
    ThemeDto,
    CloneThemeDto,
    GetThemeForViewDto,
    SetDefaultThemeInput,
    GetThemeOutput,
    CreateOrEditThemeDto,
    FileDto,
    EntityDto
} from './models'
export default class ThemesApiService {
    public static getAll(params ? : {
        filter ? : string,
        isDefault ? : boolean,
        includeLogo ? : boolean,
        sorting ? : string,
        skipCount ? : number,
        maxResultCount ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < PagedResultDtoOfGetThemeForViewDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Themes/GetAll`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static clone(body: CloneThemeDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < ThemeDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Themes/Clone`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static getDefaultThemeForView(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetThemeForViewDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Themes/GetDefaultThemeForView`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static setDefaultTheme(body: SetDefaultThemeInput, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Themes/SetDefaultTheme`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static getTheme(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetThemeOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Themes/GetTheme`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static createOrEdit(body: CreateOrEditThemeDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Themes/CreateOrEdit`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static create(body: CreateOrEditThemeDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < number >>> {
        return axios.request( < any > {
            url: `/api/services/app/Themes/Create`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static delete(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Themes/Delete`,
            method: 'delete',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static
    export (body: EntityDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < FileDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Themes/Export`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static importTheme(body: GetThemeOutput, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Themes/ImportTheme`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
}