enum PaletteCategory {
  Elements,
  ClipArt,
  Annotate,
  Logos,
  Text,
  Lines,
  //Arrows,
  //Shapes,
  //Paint
}

export default PaletteCategory;
