import PaletteCategory from '@/components/DiagramPalette/PaletteCategory';
import PaletteItem from '@/components/DiagramPalette/PaletteItem';
import AnnotationPaletteBehaviour from '@/components/DiagramPalette/AnnotationPaletteBehaviour';

import annotationData from './AnnotationElementData';
import { IElementDataProvider } from './IElementDataProvider';

export default class TextBoxDataProvider implements IElementDataProvider {
  getElements(): PaletteItem[] {
    return annotationData.map<PaletteItem>((e) => {
      return {
        category: PaletteCategory.Text,
        subcategory: 'Text',
        img: e.imagePath,
        data: e,
        text: e.name,
        id: e.id,
        behaviour: AnnotationPaletteBehaviour.INSTANCE,
        canDrag: true,
      };
    });
  }
}
