import { BvMsgBoxOptions } from 'bootstrap-vue';
import { VNode } from 'vue/types/umd';

export default class ModalDialogOptions implements BvMsgBoxOptions {
  // Default values
  size = 'sm';
  buttonSize = 'sm';
  okVariant = 'success';
  okTitle = 'YES';
  cancelTitle = 'NO';
  footerClass = 'p-2';
  hideHeaderClose = false;
  centered = true;

  constructor(
    public type: ModalDialogType,
    public content: string | VNode[],
    public title?: string,
    public callback?: (result: any) => void
  ) {}
}

export enum ModalDialogType {
  OK,
  Confirm,
}
