








import { Vue, Component, Prop } from 'vue-property-decorator';

const iconsJSON = require('../../assets/icon-font/selection.json');

function propValidator(v) {
  const iconNames = iconsJSON.icons.map((i) => i.properties.name);
  const isIcon = iconNames.includes(v);

  if (!isIcon) {
    console.error(`${v} is not valid icon name`);
    return false;
  }

  return true;
}

@Component({
  name: 'AppIcon',
})
export default class AppIcon extends Vue {
  @Prop({ required: true, validator: propValidator }) name: string;
  @Prop({ default: 24 }) size: string;

  get iconClasses() {
    return `icon-${this.name}`;
  }

  get iconStyles() {
    return { 'font-size': `${this.size}px` };
  }
}
