import {
  ArrowStyleDto,
  CompositeNodeStyleDto,
  DashStyleDto,
  DashStyleType,
  EdgeStyleDto,
  EdgeVisualType,
  ElementType,
  FillDto,
  ImageNodeStyleDto,
  INodeStyleDto,
  NodeShape,
  NodeVisualType,
  ShapeNodeStyleDto,
  StrokeDto,
  ThemeElementDto,
} from '@/api/models';
import {
  ArcEdgeStyle,
  Arrow,
  ArrowType,
  DashStyle,
  IEdgeStyle,
  ILabelStyle,
  ImageNodeStyle,
  ImageNodeStyleRenderer,
  INode,
  INodeStyle,
  Insets,
  PolylineEdgeStyle,
  ShapeNodeShape,
  ShapeNodeStyle,
  SolidColorFill,
  Stroke,
} from 'yfiles';
import { JigsawShapeNodeStyleRenderer } from '../styles/JigsawShapeNodeStyleRenderer';
import DiagramUtils from './DiagramUtils';
import diagramConfig from '@/core/config/diagram.definition.config';
import {
  JigsawArcEdgeStyleRenderer,
  JigsawPolylineEdgeStyleRenderer,
} from '../services/graph/EdgeStyleRenderers';
import JigsawNodeStyle from '../styles/JigsawNodeStyle';
import JigsawNodeDecorator from '../styles/decorators/JigsawNodeDecorator';
import CompositeNodeStyle, {
  StyleDefinition,
} from '@/core/styles/composite/CompositeNodeStyle';
import { JigsawImageNodeStyleRenderer } from '@/core/styles/JigsawImageNodeStyleRenderer';
import JurisdictionDecorator from '../styles/decorators/JurisdictionDecorator';
import IndicatorDecorators from '../styles/decorators/IndicatorDecorators';
import QuickBuildButtonsNodeDecorator from '../styles/decorators/QuickBuildButtonsNodeDecorator';
import CycleShapeButtonDecorator from '../styles/decorators/CycleShapeButtonDecorator';
import EdgeCreationButtonDecorator from '../styles/decorators/EdgeCreationButtonDecorator';
import { AnnotationType } from '../common/AnnotationType';
import FilterDecorators from '../styles/decorators/FilterDecorators';
import defaultArrowStyle from '../config/defaultArrowStyle';
import QuickStartDeleteButtonDecorator from '@/core/styles/decorators/QuickStartDeleteButtonDecorator';
import JigsawRichTextLabelStyle from '../styles/JigsawRichTextLabelStyle';

/**
 * All methods on this class should take a Jigsaw Style Dto (EdgeStyleDto, NodeStyleDto or LabelStyleDto) and create a yFiles implementaiton of those style
 *
 */
export default class StyleCreator {
  public static createElementStyle(
    themeElement: ThemeElementDto
  ): IEdgeStyle | INodeStyle {
    switch (+themeElement.elementType) {
      case ElementType.Node:
        return StyleCreator.createNodeStyle(themeElement.style);
      case ElementType.Edge:
        return StyleCreator.createEdgeStyle(themeElement.style);
    }
    throw 'Unknown Element type';
  }

  public static createLabelStyle(): ILabelStyle {
    return new JigsawRichTextLabelStyle();
  }

  public static getNodeShape(nodeShape: NodeShape): ShapeNodeShape {
    let shape = diagramConfig.nodeShapes[nodeShape];
    if (typeof shape === 'undefined') {
      throw 'Unsupported node shape ' + nodeShape;
    }
    return shape;
  }

  public static getArrowType(arrowStyle: ArrowStyleDto): ArrowType {
    try {
      return defaultArrowStyle.find(
        (x) => x.name.toLowerCase() == arrowStyle.type.toLowerCase()
      ).type;
    } catch (error) {
      // TODO:
      return ArrowType.NONE;
    }
  }

  public static createNodeStyle(
    style: INodeStyleDto,
    decorators?: JigsawNodeDecorator[]
  ): JigsawNodeStyle {
    if (!style) {
      style = DiagramUtils.getSystemDefaultNodeStyle();
    }

    switch (Number(style.visualType)) {
      case NodeVisualType.Shape:
        const shapeNodeStyleDto = style as ShapeNodeStyleDto;
        let fill = StyleCreator.createFill(shapeNodeStyleDto.fill);
        let stroke = StyleCreator.createStroke(shapeNodeStyleDto.stroke);

        const shapeNodeStyle = new ShapeNodeStyle({
          shape: StyleCreator.getNodeShape(shapeNodeStyleDto.shape),
          fill: fill,
          stroke: stroke,
          renderer: new JigsawShapeNodeStyleRenderer(shapeNodeStyleDto.shape),
        });
        return new JigsawNodeStyle(shapeNodeStyle, decorators);

      case NodeVisualType.Image:
        const imageNodeStyleDto = style as ImageNodeStyleDto;
        let imageNodeStyle = new ImageNodeStyle({
          image: imageNodeStyleDto.imageUrl,
          renderer: new ImageNodeStyleRenderer(),
        });
        return new JigsawNodeStyle(imageNodeStyle, decorators);
      case NodeVisualType.Composite:
        let compositeNodeStyleDto = style as CompositeNodeStyleDto;

        let base = new CompositeNodeStyle(
          compositeNodeStyleDto.shape,
          compositeNodeStyleDto.styleDefinitions.map((x) => {
            let insets = null;
            if (x.insets) {
              insets = new Insets(
                x.insets.left,
                x.insets.top,
                x.insets.right,
                x.insets.bottom
              );
            }
            return {
              insets: insets,
              nodeStyle: new ShapeNodeStyle({
                shape: StyleCreator.getNodeShape(x.nodeStyle.shape),
                fill: StyleCreator.createFill(x.nodeStyle.fill),
                stroke: StyleCreator.createStroke(x.nodeStyle.stroke),
                renderer: new JigsawShapeNodeStyleRenderer(x.nodeStyle.shape),
              }),
            } as StyleDefinition;
          })
        );
        return new JigsawNodeStyle(base, decorators);
    }
    throw `Unknown node visual type ${style.visualType}`;
  }

  public static createArrow(arrowStyle: ArrowStyleDto, stroke: Stroke): Arrow {
    if (arrowStyle == null || !arrowStyle.type) {
      arrowStyle = {
        type: 'none',
        scale: 1.0,
        fill: { color: '#FCC238' },
      };
    }
    let arrowType: ArrowType = StyleCreator.getArrowType(arrowStyle);

    return new Arrow({
      type: arrowType,
      fill: arrowStyle.fill ? this.createFill(arrowStyle.fill) : stroke.fill,
      scale: +arrowStyle.scale,
    });
  }
  public static JigsawPolylineEdgeStyleRenderer =
    new JigsawPolylineEdgeStyleRenderer();
  public static createEdgeStyle(style: EdgeStyleDto): IEdgeStyle {
    if (!style) {
      style = DiagramUtils.getSystemDefaultEdgeStyle();
    }
    let stroke = StyleCreator.createStroke(style.stroke);
    let targetArrow = StyleCreator.createArrow(style.targetArrow, stroke);
    let sourceArrow = StyleCreator.createArrow(style.sourceArrow, stroke);
    let height = style.height ?? diagramConfig.grid.size * 2;

    const v = style.visualType;
    switch (v) {
      case EdgeVisualType.Curved:
        const style = new PolylineEdgeStyle({
          renderer: StyleCreator.JigsawPolylineEdgeStyleRenderer,
          stroke: stroke,
          targetArrow: targetArrow,
          sourceArrow: sourceArrow,
        });
        style.smoothingLength = diagramConfig.grid.size * 2;
        return style;
      case EdgeVisualType.Arc:
        return new ArcEdgeStyle({
          renderer: new JigsawArcEdgeStyleRenderer(),
          stroke: stroke,
          targetArrow: targetArrow,
          sourceArrow: sourceArrow,
          height: height,
          fixedHeight: true,
        });
      case EdgeVisualType.Straight:
      case EdgeVisualType.Elbow:
        return new PolylineEdgeStyle({
          renderer: StyleCreator.JigsawPolylineEdgeStyleRenderer,
          stroke: stroke,
          targetArrow: targetArrow,
          sourceArrow: sourceArrow,
        });
    }
    console.warn('Unknown egde visual type');
    return new PolylineEdgeStyle({
      renderer: new JigsawPolylineEdgeStyleRenderer(),
      stroke: stroke,
      targetArrow: targetArrow,
      sourceArrow: sourceArrow,
    });
  }

  public static createFill(fill: FillDto): SolidColorFill {
    return new SolidColorFill(fill.color);
  }

  public static createDashStyle(dashStyle: DashStyleDto): DashStyle {
    if (dashStyle.dashes) {
      return new DashStyle(dashStyle.dashes, dashStyle.offset);
    }

    return StyleCreator.getDashStyle(dashStyle.type ?? DashStyleType.Solid);
  }

  public static getDashStyle(dashStyleType: DashStyleType): DashStyle {
    switch (+dashStyleType) {
      case DashStyleType.Dash:
        return DashStyle.DASH;
      case DashStyleType.DashDot:
        return DashStyle.DASH_DOT;
      case DashStyleType.DashDotDot:
        return DashStyle.DASH_DOT_DOT;
      case DashStyleType.Dot:
        return DashStyle.DOT;
      case DashStyleType.Solid:
        return DashStyle.SOLID;
    }
    // this is not unreachable code
    // ignore this error
    console.warn('Unknown dashstyle ');
    return DashStyle.SOLID;
  }

  public static createStroke(stroke: StrokeDto): Stroke {
    return new Stroke({
      fill: StyleCreator.createFill(stroke.fill),
      dashStyle: StyleCreator.createDashStyle(stroke.dashStyle),
      thickness: stroke.thickness,
    });
  }

  public static getNodeDecorators(node: INode): JigsawNodeDecorator[] {
    if (!node || !node.tag) return [];
    const tag = node.tag;
    if (tag.isGroupNode) {
      return [];
    }
    if (tag.isAnnotation) {
      if (tag.annotationType == AnnotationType.Text) {
        return [EdgeCreationButtonDecorator.INSTANCE];
      } else if (
        tag.annotationType == AnnotationType.ClipArt ||
        tag.annotationType == AnnotationType.Shape
      ) {
        return [
          IndicatorDecorators.INSTANCE,
          FilterDecorators.INSTANCE,
          JurisdictionDecorator.INSTANCE,
          EdgeCreationButtonDecorator.INSTANCE,
        ];
      } else {
        return [];
      }
    }

    return [
      // CycleShapeButtonDecorator.INSTANCE,
      QuickBuildButtonsNodeDecorator.INSTANCE,
      IndicatorDecorators.INSTANCE,
      FilterDecorators.INSTANCE,
      JurisdictionDecorator.INSTANCE,
      EdgeCreationButtonDecorator.INSTANCE,
      QuickStartDeleteButtonDecorator.INSTANCE,
    ];
  }
}
