import { GraphComponent, IGraph } from 'yfiles';
import DiagramUtils from '@/core/utils/DiagramUtils';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import ExportOptions from './ExportOptions';
import ExportProviderFactory from './providers/ExportProviderFactory';
import FileSaveSupport from './FileSaveSupport';
import DiagramDataExportService from './data/DiagramDataExportService';
import b64toBlob from '../graph/b64ToBlob';
import {
  DocumentAttachmentType,
  DocumentPageContentType,
  DataExportDocumentDto,
  DataExportDiagramDto,
} from '@/api/models';
import DiagramExportApiService from '@/api/DiagramExportApiService';
import { ExportFormat } from './ExportFormat';
import SvgExportProvider from './providers/SvgExportProvider';
import { ExportArea } from './ExportArea';
import ThumbnailBuilder from './thumb/ThumbnailBuilder';
import BackgroundGraphService from '../graph/BackgroundGraphService';
import printJS from 'print-js';
import AnalyticsService from '../analytics/AnalyticsService';
import { AnalyticsEvent } from '../analytics/AnalyticsEvent';
import ContentPagination from './ContentPagination';
import IExportProvider from './providers/IExportProvider';
import { blobToBase64 } from '@/core/utils/common.utils';
import IExportResult from './providers/IExportResult';
import { waitDocumentHasFocus } from '@/core/utils/html.utils';
import NotificationUtils, {
  NotificationType,
} from '@/core/utils/NotificationUtils';
import ExportUtils from './ExportUtils';
import DocumentService from '../document/DocumentService';

export default class ExportService {
  public static dataExportService: DiagramDataExportService =
    new DiagramDataExportService();

  public static async export(options: ExportOptions): Promise<Blob | string> {
    DocumentService.prepareDocumentForSaving();
    EventBus.$emit(
      EventBusActions.DOCUMENT_EXPORT_STARTED,
      options.showNotification
    );

    options.metadata = {
      currentPage: options.pages[0],
      currentPageNumber: 1,
    };

    let exportResult: IExportResult = null;
    try {
      exportResult = await this.exportBlob(options);
    } finally {
      this.destroyAdditionalElements(options);
      EventBus.$emit(EventBusActions.DOCUMENT_EXPORT_FINISHED);
    }
    return exportResult?.result;
  }

  private static async exportBlob(
    options: ExportOptions
  ): Promise<IExportResult> {
    let exportResult: IExportResult;
    if (options.area == ExportArea.Document) {
      exportResult = await ExportService.exportDocument(options);
    } else if (options.area == ExportArea.PageThumbnail) {
      exportResult = await ExportService.exportPageThumbnail(options);
    } else if (options.area == ExportArea.Graph) {
      exportResult = await ExportService.exportGraph(options);
    }

    if (options.withData) {
      exportResult = await ExportService.exportDocumentWithData(
        options,
        exportResult,
        ExportProviderFactory.getProvider(options.format)
      );
    }

    if (options.clipboard) {
      const data = exportResult.result as any;
      const item = new ClipboardItem({
        [exportResult.mimeType]: data,
      });
      try {
        await waitDocumentHasFocus(15000);
        await navigator.clipboard.write([item]);

        NotificationUtils.notify(
          `Diagram copied to clipboard`,
          NotificationType.Success
        );
        AnalyticsService.trackEvent(AnalyticsEvent.DocumentExported);
      } catch (e) {
        console.error(e);
        NotificationUtils.notify(
          `Export to clipboard failed, please try again`,
          NotificationType.Error
        );
      }
    } else if (options.download) {
      const fileName = options.document.name
        ? `${options.document.name}.${exportResult.fileExtension}`
        : `document.${exportResult.fileExtension}`;
      await FileSaveSupport.save(exportResult.result as Blob, fileName);
      AnalyticsService.trackEvent(AnalyticsEvent.DocumentExported);
    } else if (options.print) {
      const pdfUrl = URL.createObjectURL(exportResult.result);
      printJS({ printable: pdfUrl, type: 'pdf', showModal: true });
      AnalyticsService.trackEvent(AnalyticsEvent.DocumentPrinted);
    }

    return exportResult;
  }

  private static async exportDocument(
    options: ExportOptions
  ): Promise<IExportResult> {
    const exportProvider = ExportProviderFactory.getProvider(options.format);
    return exportProvider.exportGraphAsBlob(options, null);
  }

  private static async exportPageThumbnail(
    options: ExportOptions
  ): Promise<IExportResult> {
    if (!options.metadata.currentPage) {
      throw 'No page to export';
    }
    const exportPage = options.metadata.currentPage;
    const builder = new ThumbnailBuilder();
    builder.pageType = exportPage.page.pageType;
    builder.contentType = exportPage.page.contentType;
    builder.contentColumns = exportPage.page.contentColumns;
    builder.diagramPosition = exportPage.page.diagramPosition;

    let subPageRef = null;
    if (
      exportPage.subPageIndex !== undefined &&
      exportPage.subPageIndex !== null
    ) {
      const subPages = ContentPagination.splitPagedContentIntoPages(
        exportPage.page.content
      );
      builder.content = subPages[exportPage.subPageIndex];
      subPageRef = exportPage.page.subPageRefs?.find(
        (sp) => sp.subPageIndex === exportPage.subPageIndex
      );
    } else {
      builder.content = exportPage.page.content;
    }

    builder.content = ExportUtils.tryInlineContentStyles(
      builder.contentType,
      builder.content
    );

    const diagram = subPageRef?.diagram ?? exportPage.page.diagram;

    if (diagram) {
      const sourceGraph = new BackgroundGraphService(diagram).graph;
      builder.graphSvg = (
        await ExportService.exportGraphAsSvg(options, sourceGraph, true)
      ).result as string;
    }
    if (options.document?.hasSteps) {
      builder.pageBackground = DiagramUtils.getDocumentAttachmentPath(
        options.document,
        DocumentAttachmentType.PageBackground
      );
      const headerHtml = subPageRef
        ? subPageRef.headerHtml
        : exportPage.page.headerHtml;
      builder.headerHtml = ExportUtils.tryInlineContentStyles(
        DocumentPageContentType.Html,
        headerHtml ?? ''
      );
      builder.headerStyle = options.document?.headerStyle;
      builder.headerDividerIsImage =
        DiagramUtils.getDocumentAttachmentPath(
          options.document,
          DocumentAttachmentType.HeaderSeparator
        ) !== null;
      const footerHtml = subPageRef
        ? subPageRef.footerHtml
        : exportPage.page.footerHtml;
      builder.footerHtml = ExportUtils.tryInlineContentStyles(
        DocumentPageContentType.Html,
        footerHtml ?? ''
      );
      builder.footerStyle = options.document?.footerStyle;
      builder.footerDividerIsImage =
        DiagramUtils.getDocumentAttachmentPath(
          options.document,
          DocumentAttachmentType.FooterSeparator
        ) !== null;
      builder.headerBackground = DiagramUtils.getDocumentAttachmentPath(
        options.document,
        DocumentAttachmentType.HeaderBackground
      );
      builder.footerBackground = DiagramUtils.getDocumentAttachmentPath(
        options.document,
        DocumentAttachmentType.FooterBackground
      );
      builder.includeHeader = exportPage.page.showHeader;
      builder.includeFooter = exportPage.page.showFooter;

      builder.logo = DiagramUtils.getDocumentAttachmentPath(
        options.document,
        DocumentAttachmentType.Logo
      );
    }

    return {
      fileExtension: 'jpg',
      mimeType: 'image/jpg',
      result: await builder.getThumbnail(),
    };
  }

  private static async exportGraph(
    options: ExportOptions
  ): Promise<IExportResult> {
    if (!options.metadata.currentPage) {
      throw 'No page to export';
    }
    const exportPage = options.metadata.currentPage;
    const sourceGraph = new BackgroundGraphService(exportPage.page.diagram)
      .graph;
    const graphComponent: GraphComponent = ExportUtils.copyGraphComponent(
      sourceGraph,
      options.withFilters,
      options.format
    );

    const exportProvider = ExportProviderFactory.getProvider(options.format);

    const exportBlob = await exportProvider.exportGraphAsBlob(
      options,
      graphComponent
    );
    graphComponent.cleanUp();

    return exportBlob;
  }

  public static async exportGraphAsSvg(
    options: ExportOptions,
    sourceGraph: IGraph,
    withFilters: boolean
  ): Promise<IExportResult> {
    const graphComponent: GraphComponent = ExportUtils.copyGraphComponent(
      sourceGraph,
      withFilters,
      ExportFormat.Svg
    );
    const exportProvider = ExportProviderFactory.getProvider(
      ExportFormat.Svg
    ) as SvgExportProvider;

    const exportResult = await exportProvider.exportGraphAsString(
      options,
      graphComponent
    );
    graphComponent.cleanUp();
    return exportResult;
  }

  private static async exportDocumentWithData(
    options: ExportOptions,
    exportResult: IExportResult,
    exportProvider: IExportProvider
  ): Promise<IExportResult> {
    const exportBase64 = await blobToBase64(exportResult.result as Blob);

    let payload: DataExportDocumentDto = {
      documentId: options.document.id,
      documentName: options.document.name,
      documentFile: exportBase64,
      documentFileName: `document.${exportResult.fileExtension}`,
      documentMimeType: exportResult.mimeType,
      diagrams: [],
    };
    for (const page of options.pages) {
      if (page.page?.diagram) {
        const exportPage = page;
        const sourceGraph = new BackgroundGraphService(exportPage.page.diagram)
          .graph;
        const data = ExportService.dataExportService.export(
          sourceGraph,
          options
        );

        const dataExport = new DataExportDiagramDto(
          exportPage.page.diagram.id,
          options.document.name,
          data.nodes,
          data.edges
        );

        payload.diagrams.push(dataExport);
      }
    }

    const response = await DiagramExportApiService.documentDataExport(payload);

    return {
      mimeType: 'application/zip',
      fileExtension: 'zip',
      size: exportResult.size,
      result: b64toBlob(response.data.result),
    };
  }

  private static destroyAdditionalElements(options: ExportOptions) {
    for (const exportPage of options.pages) {
      if (exportPage.additionalElements) {
        for (const el of exportPage.additionalElements) {
          el.destroy();
        }
      }
    }
  }
}
