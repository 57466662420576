
















import { Vue, Prop, Component } from 'vue-property-decorator';
import Button from './Button.vue';

type TVariant = 'default' | 'green' | 'red';

const variantClasses = {
  default: 'j-text-accent-purple hover:j-bg-accent-purple-05',
  green: 'j-text-success-green hover:j-bg-success-green-05',
  red: 'j-text-danger-red hover:j-bg-danger-red-05',
};

@Component({
  name: 'AppButtonGroup',
  components: { Button },
})
export default class AppButtonGroup extends Vue {
  @Prop({ required: true })
  value: string | number;

  @Prop({ required: true })
  options: any[];

  @Prop({ default: 'default' })
  variant: TVariant;

  @Prop({ default: false, type: Boolean })
  autoWidth: boolean;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: null })
  disabledOptions: Array<string | number>;

  @Prop({ default: 'value' })
  valueKey: string;

  @Prop({ default: 'label' })
  labelKey: string;

  @Prop({ default: 'icon' })
  iconKey: string;

  get formattedOptions() {
    return this.options.map((option) => {
      if (typeof option === 'string') {
        return {
          value: option,
          label: option,
          icon: null,
          disabled:
            this.disabledOptions && this.disabledOptions.includes(option),
        };
      } else {
        // TODO implement validation
        // if (!Object.prototype.hasOwnProperty.call(option, this.valueKey)) {
        //   throw new Error(
        //     `valueKey ${this.valueKey} doesn't exist in option interface`
        //   );
        // }

        return {
          value: option[this.valueKey],
          label: option[this.labelKey],
          icon: option[this.iconKey],
          disabled:
            this.disabledOptions &&
            this.disabledOptions.includes(option[this.valueKey]),
        };
      }
    });
  }

  get widthClasses() {
    return { 'j-w-full': this.autoWidth };
  }

  get typeColorClass() {
    return variantClasses[this.variant];
  }

  handleSelect(value) {
    this.$emit('input', value);
    this.$emit('change', value);
  }
}
