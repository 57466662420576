import {
  CanvasComponent,
  GraphComponent,
  GraphEditorInputMode,
  IInputModeContext,
  INode,
  IRenderContext,
  Point,
  Rect,
  ShapeNodeShape,
  Size,
  SvgVisual,
  Visual,
} from 'yfiles';
import DecorationStateManager from '@/core/services/DecorationStateManager';
import HitResult from '../HitResult';
import ImageButtonNodeDecorator from './ImageButtonNodeDecorator';
import JigsawNodeDecorator from './JigsawNodeDecorator';
import DecorationState from '../DecorationState';
import store from '@/core/services/store';
import { QuickBuildState } from '@/api/models';
import DiagramUtils from '@/core/utils/DiagramUtils';

export class QuickStartDeleteButtonDecoratorMeta {
  constructor(public node: INode) { }
}

export interface QuickStartDeleteButtonState extends DecorationState {
  alwaysVisible: boolean;
}

/**
 * A decorator placed around the nodes permiter
 */
export default class QuickStartDeleteButtonDecorator
  implements JigsawNodeDecorator {
  public $class = 'QuickStartDeleteButtonDecorator';
  public static INSTANCE: QuickStartDeleteButtonDecorator = new QuickStartDeleteButtonDecorator();
  private imageButtonNodeDecorator: ImageButtonNodeDecorator;
  private imageSrc = '/media/yFiles/icons/times-circle-regular.svg';
  private imageSrcHover = '/media/yFiles/icons/times-circle-regular-hovered.svg';

  constructor(options?: { size?: Size }) {
    let size = options?.size ?? new Size(16, 16);
    // create imageButton to proxy the rendering
    this.imageButtonNodeDecorator = new ImageButtonNodeDecorator({
      imageSrc: this.imageSrc,
      size: size,
    });
    this.imageButtonNodeDecorator.getLayout = this.getLocation.bind(this);
  }

  isVisible(renderContext: IRenderContext, node: INode): boolean {
    return this.quickStartEnabled;
  }

  isHit(context: IInputModeContext, location: Point, node: INode): HitResult {
    if (!this.quickStartEnabled) return HitResult.NONE;
    const gc = context.canvasComponent as GraphComponent;
    if (gc.selection.selectedNodes.size > 1) {
      return HitResult.NONE;
    }
    let hitResult = this.imageButtonNodeDecorator.isHit(
      context,
      location,
      node
    );

    if (hitResult.isHit) {
      hitResult.decoratorType = this.$class;
      hitResult.meta = new QuickStartDeleteButtonDecoratorMeta(node);
    }

    return hitResult;
  }

  createVisual(context: IRenderContext, node: INode): Visual {
    let visual = this.imageButtonNodeDecorator.createVisual(
      context,
      node
    ) as SvgVisual;

    const hitResult = this.isHit(
      context.canvasComponent.inputModeContext,
      context.canvasComponent.lastMouseEvent.location,
      node
    );

    const isNodeSelected = this.isNodeSelected(context, node);
    const state = this.getState(node);
    const gc = context.canvasComponent as GraphComponent;
    this.attachEvents(visual.svgElement, gc);
    this.setVisibility(visual, state, isNodeSelected, hitResult, gc);
    return visual;
  }

  updateVisual(
    context: IRenderContext,
    node: INode,
    oldVisual: Visual
  ): Visual {
    if (oldVisual == null) {
      return this.createVisual(context, node);
    }
    let visual = this.imageButtonNodeDecorator.updateVisual(
      context,
      node,
      oldVisual
    ) as SvgVisual;

    const state = this.getState(node);
    const hitResult = this.isHit(
      context.canvasComponent.inputModeContext,
      context.canvasComponent.lastMouseEvent.location,
      node
    );

    const isNodeSelected = this.isNodeSelected(context, node);
    const gc = context.canvasComponent as GraphComponent;
    if (visual != oldVisual) {
      this.attachEvents(visual.svgElement, gc);
    }
    this.setVisibility(visual, state, isNodeSelected, hitResult, gc);
    return visual;
  }

  private getLocation(node: INode): Rect {
    const nodeLayout = node.layout;
    let size = this.imageButtonNodeDecorator.size;
    let xOffset = -size.width / 4;
    let yOffset = -size.height / 4;

    const shape = DiagramUtils.getNodeShape(node);
    if (shape == ShapeNodeShape.TRIANGLE) {
      xOffset = 12;
      yOffset = 12;
    }
    let x = nodeLayout.x + xOffset;
    let y = nodeLayout.y + yOffset;

    return new Rect(x, y, size.width, size.height);
  }

  private isNodeSelected(context: IRenderContext, node: INode): boolean {
    return (context.canvasComponent as GraphComponent).selection.isSelected(
      node
    );
  }

  private isEdgeCreationInProgress(graphComponent: GraphComponent): boolean {
    let geim = graphComponent.inputMode as GraphEditorInputMode;
    return geim?.createEdgeInputMode?.isCreationInProgress ?? false;
  }

  private setVisibility(
    visual: SvgVisual,
    state: QuickStartDeleteButtonState,
    isNodeSelected: boolean,
    hitResult: HitResult,
    graphComponent: GraphComponent
  ) {



    const noEdgesSelected = graphComponent.selection.selectedEdges.size === 0;

    if (noEdgesSelected && state.alwaysVisible) {
      this.show(visual.svgElement);
      return;
    }
    if (noEdgesSelected && this.quickStartEnabled) {
      this.show(visual.svgElement);
      return;
    }

    if (
      noEdgesSelected &&
      isNodeSelected &&
      graphComponent.selection.selectedNodes.size <= 1 &&
      !this.isEdgeCreationInProgress(graphComponent)
    ) {
      this.show(visual.svgElement);
      return;
    }

    if (
      noEdgesSelected &&
      hitResult.isHit &&
      hitResult.decoratorType == this.$class &&
      !this.isEdgeCreationInProgress(graphComponent)
    ) {
      this.show(visual.svgElement);
      return;
    }

    this.hide(visual.svgElement);
  }

  private show(element: SVGElement) {
    element.style.animation = '';
    element.style.opacity = '1';
    element.style.cursor = 'pointer';
  }

  attachEvents(element: SVGElement, gc: CanvasComponent) {
    element.addEventListener('mouseenter', () => {
      gc.invalidate();
    });
    element.addEventListener('mouseleave', () => {
      gc.invalidate();
    });
  }

  private hide(element: SVGElement) {
    element.style.opacity = '0';
    element.style.cursor = 'default';
  }

  private getState(node: INode): QuickStartDeleteButtonState {
    return DecorationStateManager.getState(
      QuickStartDeleteButtonDecorator.INSTANCE,
      node
    ) as QuickStartDeleteButtonState;
  }

  private get quickStartEnabled(): boolean {
    return (
      store?.state?.document?.quickBuildState === QuickBuildState.InProgress ??
      false
    );
  }

  public defaultState(): QuickStartDeleteButtonState {
    return {
      alwaysVisible: false,
    };
  }
}
