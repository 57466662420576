import {
  ArrowStyleDto,
  CompositeNodeStyleDto,
  CompositeShape,
  CompositeStyleDefinitionDto,
  DataPropertyDefinitionItemDto,
  EdgeStyleDto,
  EdgeVisualType,
  FillDto,
  ImageNodeStyleDto,
  NodeShape,
  NodeVisualType,
  ShapeNodeStyleDto,
  StrokeDto,
} from '@/api/models';
import { INode, IEdge, IModelItem } from 'yfiles';
import DiagramWriter from '../services/graph/serialization/diagram-writer.service';
import SparkMD5 from 'spark-md5';
import { AnnotationType } from '../common/AnnotationType';
import DataPropertyUtils from './DataPropertyUtils';
import appConsts from '../config/appConsts';

interface IHashDataNode {
  groupUuid: string;
  isGroupNode: boolean;
  style: IHashDataNodeStyle;
  isAnnotation: boolean;
  name: string;
  annotationType: AnnotationType;
  isDataPropertyStyleActive?: boolean;
  dataProperties?: {
    jurisdiction: any;
  };
}

interface IHashDataEdge {
  name: string;
  style: IHashDataEdgeStyle;
}

interface IHashDataDataPropertyDefinitionItem {
  definitionId: number;
  imageData: string;
  itemValue: string;
}

interface IHashDataEdgeStyle {
  stroke: StrokeDto;
  sourceArrow: ArrowStyleDto;
  targetArrow: ArrowStyleDto;
  visualType: EdgeVisualType;
}

interface IHashDataNodeStyle {
  visualType: NodeVisualType;
  shape?: NodeShape | CompositeShape;
  fill?: FillDto;
  stroke?: StrokeDto;
  image?: string;
  definitions?: CompositeStyleDefinitionDto[];
}

export default class GraphElementsHashGenerator {
  public static getElementHashKey(item: IModelItem): string {
    if (item instanceof INode) {
      return this.getNodeHashKey(item);
    } else if (item instanceof IEdge) {
      return this.getEdgeHashKey(item);
    }
    return null;
  }

  public static getNodeHashKey(node: INode): string {
    const jurisdiction = DataPropertyUtils.getDataPropertyFromNode(
      node,
      appConsts.JURISDICTION_DEFINITION_ID
    );
    let data: IHashDataNode = {
      groupUuid: node.tag.groupUuid,
      isGroupNode: node.tag.isGroupNode,
      style: this.getNodeStyle(node),
      isAnnotation: node.tag.isAnnotation,
      name: node.tag?.name?.toLowerCase(),
      annotationType: node.tag.annotationType,
      isDataPropertyStyleActive: node.tag?.dataPropertyStyle?.isActive,
      dataProperties: {
        jurisdiction: jurisdiction?.value,
      },
    };

    return SparkMD5.hash(JSON.stringify(data));
  }

  public static getEdgeHashKey(edge: IEdge): string {
    let data: IHashDataEdge = {
      style: this.getEdgeStyle(edge),
      name: edge.tag?.name?.toLowerCase(),
    };

    return SparkMD5.hash(JSON.stringify(data));
  }

  public static getDataPropertyDefinitionItem(
    definition: DataPropertyDefinitionItemDto
  ): string {
    const data: IHashDataDataPropertyDefinitionItem = {
      definitionId: definition.dataPropertyDefinitionId,
      imageData: definition.imageData,
      itemValue: definition.itemValue,
    };

    return SparkMD5.hash(JSON.stringify(data));
  }

  private static getNodeStyle(node: INode): IHashDataNodeStyle {
    const nodeStyleDto = DiagramWriter.convertNodeStyle(node);

    if (nodeStyleDto instanceof ShapeNodeStyleDto) {
      return {
        fill: nodeStyleDto.fill,
        stroke: nodeStyleDto.stroke,
        visualType: nodeStyleDto.visualType,
        shape: nodeStyleDto.shape,
      };
    } else if (nodeStyleDto instanceof ImageNodeStyleDto) {
      return {
        image: nodeStyleDto.imageUrl,
        visualType: nodeStyleDto.visualType,
      };
    } else if (nodeStyleDto instanceof CompositeNodeStyleDto) {
      let definitions = [];
      for (const definition of nodeStyleDto.styleDefinitions) {
        let def = {
          insets: definition.insets,
          style: {
            fill: definition.nodeStyle.fill,
            stroke: definition.nodeStyle.stroke,
            shape: definition.nodeStyle.shape,
          },
        };
        definitions.push(def);
      }
      return {
        shape: nodeStyleDto.shape,
        definitions: definitions,
        visualType: nodeStyleDto.visualType,
      };
    }
  }

  private static getEdgeStyle(edge: IEdge): IHashDataEdgeStyle {
    const edgeStyleDto: EdgeStyleDto = DiagramWriter.convertEdgeStyle(edge);
    return {
      stroke: edgeStyleDto.stroke,
      sourceArrow: edgeStyleDto.sourceArrow,
      targetArrow: edgeStyleDto.targetArrow,
      visualType: edgeStyleDto.visualType,
    };
  }
}
