import { RouteConfig } from 'vue-router';

const Playground = () => import('@/playgrounds/Playground.vue')

const context = require.context('@/playgrounds/items', true, /\.vue$/)

export const playgroundElementRoutes: RouteConfig[] = context.keys().map(context).map((m: any) => {
  return m?.default?.options.name ? {
    path: m.default?.options.name.toLowerCase(),
    name: m.default?.options.name,
    component: m.default
  } : null
}).filter(r => r)

export const playgroundRoutes: RouteConfig = {
  path: '/playground',
  name: 'Playground',
  redirect: { name: playgroundElementRoutes[0].name },
  component: Playground,
  children: playgroundElementRoutes
}