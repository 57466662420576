import Vue from 'vue';
const EventBus = new Vue();

const EventBusActions = {
  APPLICATION_USER_AUTHORISED: 'application:user-authorised',
  APPLICATION_USER_UNAUTHORiSED: 'application:user-unauthorised',
  APPLICATION_IDLE_ONIDLE: 'application:idle-onidle',
  APPLICATION_IDLE_ONACTIVE: 'application:idle-onactive',
  APPLICATION_SESSION_TIMED_OUT: 'application:session-timed-out',
  APPLICATION_ERROR: 'application-error',
  APPLICATION_HOME: 'application-home',
  DOCUMENT_AUTO_SAVE: 'document:auto-save',
  DOCUMENT_UNLOCK: 'document:unlock',
  DOCUMENT_SAVE: 'document:save',
  DOCUMENT_SAVE_AS: 'document:save-as',
  DOCUMENT_SAVE_AS_TEMPLATE: 'document:save-as-template',
  DOCUMENT_SAVED: 'document:saved',
  DOCUMENT_SAVE_CONFLICT: 'document:save-conflict',
  DOCUMENT_CLOSE: 'document:close',
  DOCUMENT_TOOLBAR_LASSOTYPE: 'document:lassotype',
  DOCUMENT_EXPORT_DIAGRAM: 'document:export-diagram',
  DOCUMENT_EXPORT_DOCUMENT: 'document:export-document',
  DOCUMENT_EXPORT_STARTED: 'document:export-started',
  DOCUMENT_EXPORT_FINISHED: 'document:export-finished',
  DOCUMENT_PRINT_DIAGRAM: 'document:print-diagram',
  DOCUMENT_PRINT_DOCUMENT: 'document:print-document',
  DOCUMENT_SHARE_DOCUMENT: 'document:share-document',
  DOCUMENT_TOGGLE_GRID: 'document:toggle-grid',
  DOCUMENT_TOGGLE_GRAPH_OVERVIEW: 'document:toggle-graph-overview',
  DOCUMENT_TOGGLE_LOGO: 'document:toggle-logo',
  DOCUMENT_TOGGLE_TABLE: 'document:toggle-table',
  DOCUMENT_TOGGLE_LEGEND: 'document:toggle-legend',
  DOCUMENT_CLIPBOARD_ACTION_DIAGRAM: 'document:clipboard-action-diagram',
  DOCUMENT_ZOOM_ACTION_DIAGRAM: 'document:zoom-action-diagram',
  DOCUMENT_UPLOAD_FILE_DIAGRAM: 'document:upload-file-diagram',
  DOCUMENT_UPLOAD_EXCEL: 'document:upload-excel',
  DOCUMENT_UPLOAD_GRAPHML: 'document:upload-graphml',
  DOCUMENT_TOOLBAR_APPLY_THEME: 'document:apply-theme',
  DOCUMENT_FONT_SIZE_CHANGED: 'document:font-size-changed',
  DOCUMENT_FONT_WEIGHT_CHANGED: 'document:font-weight-changed',
  DOCUMENT_FONT_FAMILY_CHANGED: 'document:font-family-changed',
  DOCUMENT_FONT_STYLE_CHANGED: 'document:font-style-changed',
  DOCUMENT_TEXT_DECORATION_CHANGED: 'document:text-decoration-changed',
  DOCUMENT_TEXT_COLOR_CHANGED: 'document:text-color-changed',
  DOCUMENT_OUTLINE_COLOR_CHANGED: 'document:outline-color-changed',
  DOCUMENT_OUTLINE_DASH_STYLE_CHANGED: 'document:outline-dash-style-changed',
  DOCUMENT_OUTLINE_ARROW_TYPE_CHANGED: 'document:outline-arrow-type-changed',
  DOCUMENT_OUTLINE_ARROW_COLOR_CHANGED: 'document:outline-arrow-color-changed',
  DOCUMENT_CLONE_THEME: 'document:clone-theme',
  DOCUMENT_NEW_DIAGRAM_MODAL: 'document:new-diagram-modal',
  DOCUMENT_UPDATE_NAME: 'document:update_name',
  DOCUMENT_OPEN_DIAGRAM_MODAL: 'document:open-diagram-modal',
  DOCUMENT_ELEMENT_PALETTE_REFRESH: 'document:palette-refresh',
  DOCUMENT_SIDEBAR_BUTTON_CLICK: 'document:sidebar-button-click',
  DOCUMENT_CANVAS_CLICK: 'document:canvas-click',
  DOCUMENT_UNDO_ACTION: 'document:undo-action',
  DOCUMENT_REDO_ACTION: 'document:redo-action',
  DOCUMENT_SET_PAGE: 'document:set-page',
  DOCUMENT_CONVERTED_TO_STEPS: 'document: converted-to-steps',
  DOCUMENT_PAGE_LAYOUT_CHANGED: 'document:page-layout-changed',
  DOCUMENT_PAGE_CHANGED: 'document:page-changed',
  DOCUMENT_PAGE_TYPE_CHANGED: 'document:page-type-changed',
  DOCUMENT_PAGE_CONTENT_COLUMNS_CHANGED:
    'document:page-content-columns-changed',
  DOCUMENT_PAGE_DIAGRAM_POSITION_CHANGED:
    'document:page-diagram-position-changed',
  DOCUMENT_PAGE_CREATED: 'document:page-created',
  DOCUMENT_PAGE_ADDED: 'document:page-added',
  DOCUMENT_STEPS_VIEW_RESET: 'document:steps-view-reset',
  DOCUMENT_STEPS_PAGE_MOVED: 'document:steps-page-moved',
  DOCUMENT_CONTENT_CHANGED: 'document:content-changed',
  DOCUMENT_CONTENT_SET: 'document:content-set',
  DOCUMENT_SHOW_HEADER_FOOTER_CONTEXT_MENU:
    'document:show-header-footer-context-menu',
  DOCUMENT_THEME_APPLIED: 'document:theme-applied',
  DOCUMENT_DATA_PROPERTY_STYLE_CHANGED: 'document: data-property-style-changed',
  DOCUMENT_UNLINK_DIAGRAM: 'document:unlink-diagram',
  DOCUMENT_LOADED: 'document:document-loaded',
  DOCUMENT_CKEDITOR_FOCUS_CHANGED: 'document: ckEditor-focus-changed',
  DOCUMENT_SELECT_NEXT_PAGE: 'document: select-next-page',
  DOCUMENT_SELECT_PREVIOUS_PAGE: 'document:select-previous-page',
  DOCUMENT_PREVIEW: 'document:preview',
  DOCUMENT_ACCESS_REQUESTED: 'document:access-requested',
  RIBBON_PIN_CHANGED: 'ribbon:pin-changed',

  DIAGRAM_TOGGLE_AUTO_ZOOM_FIT: 'diagram:toggle-auto-zoom-fit',
  DIAGRAM_AUTO_ZOOM_FIT_CHANGED: 'diagram:auto-zoom-fit-changed',
  DIAGRAM_ZOOM_CHANGED: 'diagram:zoom-changed',
  DIAGRAM_ITEM_CREATED: 'diagram:item-created',
  DIAGRAM_NODE_CREATED: 'diagram:node-created',
  DIAGRAM_NODE_REMOVED: 'diagram:node-removed',
  DIAGRAM_NODE_REMOVING: 'diagram:node-removing',
  DIAGRAM_NODE_LAYOUT_CHANGED: 'diagram:node-layout-changed',
  DIAGRAM_EDGE_CREATED: 'diagram:edge-created',
  DIAGRAM_EDGE_REMOVED: 'diagram:edge-removed',
  DIAGRAM_EDGE_PORT_CHANGED: 'diagram:edge-port-changed',
  DIAGRAM_NODE_STYLE_CHANGED: 'diagram:node-style-changed',
  DIAGRAM_EDGE_STYLE_CHANGED: 'diagram:edge-style-changed',
  DIAGRAM_LABEL_TEXT_CHANGED: 'diagram:label-text-changed',
  DIAGRAM_LABEL_STYLE_CHANGED: 'diagram:label-style-changed',
  DIAGRAM_THEME_CHANGED: 'diagram:theme-changed',
  DIAGRAM_EDGE_TYPE_CHANGED: 'diagram:edge-type-changed',
  DIAGRAM_NODE_TYPE_CHANGED: 'diagram:node-type-changed',
  DIAGRAM_CLEAR_SELECTION: 'diagram:clear-selection',
  DIAGRAM_CENTER_ON_ITEM: 'diagram:center-on-item',
  DIAGRAM_HIGHLIGHT_ITEM: 'diagram:highlight-item',
  DIAGRAM_GROUP_FILL_CHANGED: 'diagram:group-fill-changed',
  DIAGRAM_UNLINKED: 'diagram:unlinked',
  BEGIN_EDGE_CREATION: 'diagram:begin-edge-creation',
  DIAGRAM_FIT_GRAPH_BOUNDS: 'diagram:fit-graph-bounds',
  DIAGRAM_SHOW_EDGE_SELECTOR: 'diagram:show-edge-selector',
  DIAGRAM_CHANGED: 'diagram:changed',
  DIAGRAM_ELEMENT_HASH_KEY_CHANGED: 'diagram:element-hash-key-changed',
  SHOW_TEMPLATE_DETAILS_MODAL: 'diagram:show-template-modal',
  SHOW_IMPORTS_MODAL: 'diagram:show-imports-modal',
  DRAGGABLE_ELEMENTS_ITEM_SELECTED: 'draggable-elements:item-selected',
  PALETTE_ITEM_CLICK_DENIED: 'palette:item-click-denied',

  QUICK_BUILD_CHANGED: 'quick-build-changed',
  QUICK_BUILD_OPERATION_DENIED: 'quick-build-operation-denied',
  TOGGLE_QUICK_PANEL: 'quick-panel:toggle',
  SHOW_QUICK_PANEL: 'quick-panel:show',
  HIDE_QUICK_PANEL: 'quick-panel:hide',
  QUICK_PANEL_STATE_CHANGED: 'quick-panel:state-changed',

  DATA_PROPERTIES_DATA_PROPERTY_CHANGED:
    'data-properties:data-property-changed',
  DATA_PROPERTIES_DECORATOR_CHANGED: 'data-properties:decorator-changed',
  DATA_PROPERTIES_OPEN_DELETE_MODAL: 'data-properties:open-delete-modal',
  DATA_PROPERTIES_ATTACHMENT_ADDED: 'data-properties: attachment-added',
  DATA_PROPERTIES_DATA_PROPERTY_DISPLAY_TYPE_CHANGED:
    'data-properties:display-type-changed',
  PEOPLE_NODE_DECORATOR_CHANGED: 'people-node-decorator-changed',

  GRAPH_INIT_COMPLETE: 'graph:init-event',
  GRAPH_DISPOSED: 'graph:disposed',
  TOGGLE_NODE_TYPE: 'quick:toggle-node-type',
  TOGGLE_ASIDE_EXPANDED_VIEW: 'aside:toggle-expanded-view',
  ADJUST_OVERLAPPING_PORTS: 'adjust-overlapping-ports',
  SIDE_BAR_LOGO_CLICKED: 'sidebar:logo-clicked',

  SEARCH_RESULTS_CHANGED: 'search:results-changed',
  SEARCH_FILTERS_CLEAR: 'search:filters-clear',
  SEARCH_FILTERS_CHANGED: 'search:filters-changed',
  SEARCH_FILTERS_BEHAVIOUR_APPLIED: 'search:filters-behaviour-applied',

  CONTEXT_MENU_ITEM_MOUSE_ENTER: 'context-menu:context-menu-item-mouse-enter',

  SHOW_MODAL_DIALOG: 'modal-dialog:show',

  THEME_DELETED: 'theme:deleted',
  THEME_CREATED: 'theme:created',
  THEME_CLONED: 'theme:cloned',
  LABEL_EDITING_STARTED: 'diagram:label-editing-started',

  FILEUPLOAD_REMOVE_ALL_FILES: 'fileupload:remove-all-files'
};

export { EventBus, EventBusActions };
