import { CurrentUserProfileEditDto } from '@/api/models';
import appConfig from '@/core/config/appConfig';
import Vue from 'vue';

export default class PendoService {
  public static setAuthenticatedUser(user: CurrentUserProfileEditDto) {
    if (appConfig?.pendo?.enabled) {
      if (pendo) {
        const additionalDetail = appConfig.pendo?.additionalDetail ?? false;
        pendo.initialize({
          visitor: {
            id: window.location.hostname + ':' + user.userId,
            email: additionalDetail ? user.emailAddress : '',
            language: additionalDetail ? user.language : '',
            authenticationSource: additionalDetail
              ? user.authenticationSource
              : '',
            hostname: window.location.hostname,
            licenced: user.isLicensed,
          },
          account: {
            id: window.location.hostname,
            hostname: window.location.hostname,
            version: appConfig.version,
          },
        });
      } else {
        console.error('Pendo not injected');
      }
    }
  }

  public static injectPendo(apiKey) {
    try {
      if (appConfig?.pendo?.enabled) {
        (function (apiKey) {
          (function (p, e, n, d, o) {
            var v, w, x, y, z;
            o = p[d] = p[d] || {};
            o._q = o._q || [];
            v = [
              'initialize',
              'identify',
              'updateOptions',
              'pageLoad',
              'track',
            ];
            for (w = 0, x = v.length; w < x; ++w)
              (function (m) {
                o[m] =
                  o[m] ||
                  function () {
                    o._q[m === v[0] ? 'unshift' : 'push'](
                      [m].concat([].slice.call(arguments, 0))
                    );
                  };
              })(v[w]);
            y = e.createElement(n);
            y.async = !0;
            y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
            z = e.getElementsByTagName(n)[0];
            z.parentNode.insertBefore(y, z);
          })(window, document, 'script', 'pendo');
        })(apiKey);
      }
    } catch (e) {
      console.debug('Pendo failure', e);
    }
  }
}
