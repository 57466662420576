import {
  EmptyColor,
  TransparentColor,
} from '@/components/ColorPicker/ColorPickerConsts';
import { encodeHtml } from '@/core/utils/html.utils';

export default class PdfMakeUtils {
  public static createDataAttribute(props: any) {
    const propString = encodeHtml(JSON.stringify(props));
    return `data-pdfmake="${propString}"`;
  }

  public static ensureValidHtml(html: string): string {
    if (!html) {
      return html;
    }
    html = html.replace(/background-color:\s*initial;?/gm, '');
    html = html.replace(/text-decoration:\s*initial;?/gm, '');
    html = html.replace(/text-decoration:\s*none;?/gm, '');
    html = html.replace(
      new RegExp(`background-color:\\s*${EmptyColor};?`, 'gm'),
      ''
    );
    html = html.replace(/list-style-type:\s*lower-latin;?/gm, 'list-style-type:lower-alpha;');
    html = html.replace(/list-style-type:\s*upper-latin;?/gm, 'list-style-type:upper-alpha;');
    html = html.replace(
      new RegExp(`background-color:\\s*${TransparentColor};?`, 'gm'),
      ''
    );
    return html;
  }

  public static applyVerticalAlignment(node, rowIndex, align, offset) {
    const allCellHeights = node.table.body[rowIndex].map(
      (innerNode, columnIndex) => {
        const mFindInlineHeight = this.findInlineHeight(
          innerNode,
          node.table.widths[columnIndex]._calcWidth
        );
        return mFindInlineHeight.height;
      }
    );
    const maxRowHeight = Math.max(...allCellHeights);
    node.table.body[rowIndex].forEach((cell, ci) => {
      if (allCellHeights[ci] && maxRowHeight > allCellHeights[ci]) {
        let topMargin;

        let cellAlign = align;
        if (Array.isArray(align)) {
          cellAlign = align[ci];
        }

        if (cellAlign === 'bottom') {
          topMargin = maxRowHeight - allCellHeights[ci];
        } else if (cellAlign === 'center') {
          topMargin = (maxRowHeight - allCellHeights[ci]) / 2;
        }

        if (topMargin) {
          if (cell._margin) {
            cell._margin[1] = topMargin + offset;
          } else {
            cell._margin = [0, topMargin + offset, 0, 0];
          }
        }
      }
    });
  }

  private static mapTableBodies(innerTableCell, maxWidth, usedWidth) {
    const mFindInlineHeight = PdfMakeUtils.findInlineHeight(
      innerTableCell,
      maxWidth,
      usedWidth
    );

    usedWidth = mFindInlineHeight.width;
    return mFindInlineHeight.height;
  }

  private static findInlineHeight(cell, maxWidth, usedWidth = 0) {
    let calcLines = (inlines) => {
      if (!inlines)
        return {
          height: 0,
          width: 0,
        };
      let currentMaxHeight = 0;
      let lastHadLineEnd = false;
      for (const currentNode of inlines) {
        usedWidth += currentNode.width;
        if (usedWidth > maxWidth || lastHadLineEnd) {
          currentMaxHeight += currentNode.height;
          usedWidth = currentNode.width;
        } else {
          currentMaxHeight = Math.max(currentNode.height, currentMaxHeight);
        }
        lastHadLineEnd = !!currentNode.lineEnd;
      }
      return {
        height: currentMaxHeight,
        width: usedWidth,
      };
    };
    if (cell._offsets) {
      usedWidth += cell._offsets.total;
    }
    if (cell._inlines && cell._inlines.length) {
      return calcLines(cell._inlines);
    } else if (cell.stack && cell.stack[0]) {
      return cell.stack
        .map((item) => {
          return this.findInlineHeight(item, maxWidth);
        })
        .reduce((prev, next) => {
          return {
            height: prev.height + next.height,
            width: Math.max(prev.width + next.width),
          };
        });
    } else if (cell.table) {
      let currentMaxHeight = 0;
      for (const currentTableBodies of cell.table.body) {
        const innerTableHeights = currentTableBodies.map(this.mapTableBodies);
        currentMaxHeight = Math.max(...innerTableHeights, currentMaxHeight);
      }
      return {
        height: currentMaxHeight,
        width: usedWidth,
      };
    } else if (cell._height) {
      usedWidth += cell._width;
      return {
        height: cell._height,
        width: usedWidth,
      };
    }

    return {
      height: null,
      width: usedWidth,
    };
  }
}
