


































































import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'ButtonGroup',
})
export default class ButtonGroup extends Vue {
  selectedValueInline = 'One';

  demoOptionsPrimitive = ['Tailwind', 'Bootstrap', 'Vuetify'];
  selectedValue = 'Tailwind';

  demoOptions = [
    { icon: 'owner', label: 'Owner only', value: 'ow' },
    { icon: 'users-group', label: 'Group access', value: 'gr' },
  ];

  selectedAccessDemo = null;
}
