




































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'AppLoadingOverlay',
})
export default class AppLoadingOverlay extends Vue {
  @Prop({ default: false, type: Boolean })
  loading: boolean;

  @Prop({ default: 64 })
  size: number | string;

  @Prop({ default: '#3433E1' })
  color: string;

  get sizeStyle() {
    return {
      width: `${this.size}px`,
      heiht: `${this.size}px`,
    };
  }
}
