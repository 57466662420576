import { Size } from 'yfiles';
import GroupNodeStyle from '../styles/GroupNodeStyle';

// IMPORTANT: All the measurements are in points (pt) unless stated otherwise
export default {
  orientation: 'landscape',
  pageSize: new Size(842, 595), // A4 landscape size in points (96 dpi)
  usablePageArea: new Size(835, 595), // Actual usable page area size in PDFMake
  minDiagramSize: new Size(100, 100), // Minimum size of the exported diagram canvas

  // Margins (in points) relative to A4 paper size
  pageMargins: {
    left: 35,
    top: 60,
    right: 35,
    bottom: 60,
  },
  headerHeight: 50,
  footerHeight: 50,
  dividerMargin: 4,
  pageLogoWidth: 250,
  pageLogoMargin: 5,
  pageLogoPadding: 5,
  stepsLogoMargin: 0,
  pageContentClass: 'document-page-content',
  contentPadding: 8, // (For split pages) Right-pad content to ensure similar size in the pdf output
  diagramHorizontalPadding: 20, // (For split pages) Right-pad the diagram to not overlap with content
  diagramVerticalPadding: 10, // (For split pages) Vertical padding to avoid moving diagram to next page
  diagramLogoSize: new Size(200, 200),
  diagramLegendScale: {
    large: 1,
    medium: 0.73,
    small: 0.56,
  },
  diagramMargins: GroupNodeStyle.INSTANCE.offset, // Inner diagram svg margins (in px)
  thumbScale: 0.25,
  /**
   * Because of pdfkit we need to use points for all measurements instead of pixels
   * The measurement can be converted from pixels to points using this multiplier (72dpi <=> 96dpi)
   */
  upscaleMultiplier: 96 / 72,
  /**
   * Insert page break when content is within the specified threshold of the page height (in px)
   */
  pageBreakThreshold: 18,
  /**
   * 1x50 px blank image to fix an issue with text vertical alignment in header/footer
   */
  blankImageUrl:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAxCAQAAACxZguvAAAADklEQVR42mNkYGAcZAgADu4AMsQuEYIAAAAASUVORK5CYII=',
  /**
   * Will get replaced with the current page number when used inside header/footer content
   */
  pageNumberVariable: '{{page}}',

  /**
   * Will get removed when showing page numbers is switched off
   */
  defaultPageNumberText: 'Page {{page}}',
  /**
   * Should be used as default color for replacing image from dividers when generating thumbnails
   */
  dividerDefaultColor: '#808080',
  /**
   * Activates the pdfmake playground mode
   */
  pdfmakePlaygroundMode: false,
};
