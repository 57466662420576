



























import { Vue, Component, Prop, Provide } from 'vue-property-decorator';

import AppFlyout, { Placement, FlyoutTriggers } from '../AppFlyout.vue';

@Component({
  name: 'AppDropdown',
})
export default class AppDropdown extends Vue {
  @Prop({ default: 'bottom' })
  placement: Placement;

  @Prop({ default: 'click' })
  trigger: FlyoutTriggers;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: null })
  maxWidth: string | number;

  @Prop({ default: null })
  minWidth: string | number;

  @Prop({ default: false, type: Boolean })
  appendToBody: boolean;

  @Prop({ default: null })
  activatorClass: string;

  @Prop({ default: 10, type: Number })
  offset: number;

  @Prop({ default: false, type: Boolean })
  targetLess: boolean;

  /**
   * prevent click event bubbling outside the root element of the component
   */
  @Prop({ default: false, type: Boolean })
  propagate: boolean;

  $refs: {
    flyoutRef: AppFlyout;
  };

  get styles() {
    return { minWidth: `${this.minWidth}px` };
  }

  get flyoutRef(): AppFlyout {
    return this.flyoutRef;
  }

  show(evt?: MouseEvent) {
    this.$refs.flyoutRef.show(evt);
  }

  @Provide() private hideDropdown() {
    this.$refs.flyoutRef.hide();
  }
}
