









import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'AppDropdownGroupLabel',
})
export default class AppDropdownGroupLabel extends Vue {}
