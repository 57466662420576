

































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { Validations } from 'vuelidate-property-decorators';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';

import { ChangePasswordInput } from '@/api/models';
import ProfileApiService from '@/api/ProfileApiService';

@Component({
  name: 'AppUpdatePasswordModal'
})
export default class AppUpdatePasswordModal extends mixins(
  validationMixin
) {
  @Prop({ required: true })
  value: boolean;

  @Validations()
  validations = {
    currentPassword: { required, minLength: minLength(3) },
    newPassword: { required, minLength: minLength(3) },
    repeatNewPassword: { required, minLength: minLength(3), sameAsPassword: sameAs('newPassword') }
  };

  currentPassword: string = '';
  newPassword: string = '';
  repeatNewPassword: string = '';

  loading: boolean = false;

  get errorLabels () {
    return {
      currentPassword: this.$v.currentPassword.$invalid && this.$v.currentPassword.$dirty
        ? this.$t('CURRENT_PASSWORD_IS_REQUIRED') : '',
      newPassword: this.$v.newPassword.$invalid && this.$v.newPassword.$dirty
        ? this.$t('NEW_PASSWORD_IS_REQUIRED') : '',
      repeatNewPassword: this.$v.repeatNewPassword.$invalid && this.$v.repeatNewPassword.$dirty
        ? this.$t('PASSWORD_IS_REQUIRED_AND_MUST_MATCH') : ''
    }
  }

  onSubmitHandler () {
    this.$v.$touch();
    if (this.$v.$anyError) {
      return;
    }

    this.loading = true;

    const updatePasswordData = new ChangePasswordInput(
      this.$v.currentPassword.$model,
      this.$v.newPassword.$model
    );

    ProfileApiService.changePassword(updatePasswordData)
      .then((response) => {
        if (response.status == 200) {
          this.$notify({
            title: this.$t('PASSWORD_SUCCESSFULLY_UPDATED').toString(),
            type: 'success',
          });

          this.onValueChangeHandler(false);
        } else {
          this.$notify({
            title: this.$t('UNABLE_TO_UPDATE_PASSWORD').toString(),
            type: 'error',
          });
        }
      })
      .catch((error) => {
        this.$notify({
          title: this.$t('ERROR_UPDATING_PASSWORD').toString(),
          type: 'error',
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onValueChangeHandler (value: boolean) {
    this.$emit('input', value)
    this.resetForm();
  }

  resetForm() {
    this.currentPassword = '';
    this.newPassword = '';
    this.repeatNewPassword = '';

    this.$v.$reset();
  }
}
