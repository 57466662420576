var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"j-min-w-240 j-w-240 j-flex j-flex-col j-justify-between j-h-full j-bg-palatinate-blue j-pt-36 j-pb-16"},[_c('div',{staticClass:"j-w-full j-flex j-justify-between j-flex-grow j-flex-col"},[_c('div',{staticClass:"j-w-full"},[_c('div',{staticClass:"j-w-full j-px-24"},[_c('router-link',{staticClass:"j-w-full j-max-w-160 j-block",attrs:{"to":{ name: 'landing' }}},[_c('img',{attrs:{"src":"/resources/icons/dash/full_logo.svg","alt":"Jigsaw Logo"}})])],1),_c('div',{staticClass:"j-w-full j-mt-24"},_vm._l((_vm.navigationRoutesList),function(item,index){return _c('router-link',{key:index,attrs:{"to":{ name: item.routeName },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
var navigate = ref.navigate;
return [_c('div',{staticClass:"sidebar-item tall:j-h-60 j-h-44",class:{
              'j-bg-accent-purple': isExactActive,
            },on:{"click":navigate}},[_c('div',{staticClass:"j-min-w-24 j-w-24 j-h-24 j-flex j-justify-center j-items-center"},[_c('AppIcon',{staticClass:"j-text-white",attrs:{"name":item.icon}})],1),_c('div',{staticClass:"j-w-full j-flex j-items-center j-truncate"},[_c('p',{staticClass:"j-text-15 j-text-white j-pl-16 j-leading-none j-truncate"},[_vm._v(" "+_vm._s(item.label)+" ")]),(item.countDanger)?_c('p',{staticClass:"j-text-14 j-text-white j-px-8 j-py-5 j-rounded-8 j-bg-pinkinity j-ml-8 j-leading-none"},[_vm._v(" "+_vm._s(item.countDanger)+" ")]):(item.count)?_c('p',{staticClass:"j-text-14 j-text-white j-opacity-40 j-ml-8 j-leading-none"},[_vm._v(" ("+_vm._s(item.count)+") ")]):_vm._e()])])]}}],null,true)})}),1)]),_c('div',{staticClass:"j-w-full j-mt-24"},[(_vm.hasPermission(_vm.ApplicationPermissions.ThemesRead))?_c('router-link',{attrs:{"to":_vm.themePageRoute,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var isExactActive = ref.isExactActive;
            var navigate = ref.navigate;
return [_c('div',{staticClass:"sidebar-item tall:j-h-60 j-h-44",class:{
            'j-bg-accent-purple': isExactActive
          },on:{"click":navigate}},[_c('div',{staticClass:"j-min-w-24 j-w-24 j-h-24 j-flex j-justify-center j-items-center"},[_c('AppIcon',{staticClass:"j-text-white",attrs:{"name":"themes"}})],1),_c('div',{staticClass:"j-w-full j-flex j-items-center j-truncate"},[_c('p',{staticClass:"j-text-15 j-text-white j-pl-16 j-leading-none j-truncate"},[_vm._v(" "+_vm._s(_vm.$t('THEMES'))+" ")])])])]}}],null,false,3330662190)}):_vm._e(),(_vm.canCreateImportFromExcel)?_c('router-link',{attrs:{"to":_vm.importPageRoute,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var isExactActive = ref.isExactActive;
          var navigate = ref.navigate;
return [_c('div',{staticClass:"sidebar-item tall:j-h-60 j-h-44",class:{
            'j-bg-accent-purple': isExactActive,
          },on:{"click":navigate}},[_c('div',{staticClass:"j-min-w-24 j-w-24 j-h-24 j-flex j-justify-center j-items-center"},[_c('AppIcon',{staticClass:"j-text-white",attrs:{"name":"folder-new"}})],1),_c('div',{staticClass:"j-w-full j-flex j-items-center j-truncate"},[_c('p',{staticClass:"j-text-15 j-text-white j-pl-16 j-leading-none j-truncate"},[_vm._v(" "+_vm._s(_vm.$t('IMPORT'))+" ")])])])]}}],null,false,96678904)}):_vm._e(),_c('a',{staticClass:"sidebar-item tall:j-h-60 j-h-44",attrs:{"href":_vm.feedbackEmailLink}},[_c('div',{staticClass:"j-min-w-24 j-w-24 j-h-24 j-flex j-justify-center j-items-center"},[_c('AppIcon',{staticClass:"j-text-white",attrs:{"name":"feedback","size":"24"}})],1),_c('div',{staticClass:"j-w-full j-flex j-items-center j-truncate"},[_c('p',{staticClass:"j-text-15 j-text-white j-pl-16 j-leading-none j-truncate"},[_vm._v(" "+_vm._s(_vm.$t('FEEDBACK'))+" ")])])])],1)]),_c('SidebarProfileDropdown')],1)}
var staticRenderFns = []

export { render, staticRenderFns }