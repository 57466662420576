














































































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Getter } from 'vuex-class';

import PermissionMixin from '@/mixins/Permission.mixin';
import { ClientHostSettingsDto, CurrentUserProfileEditDto } from '@/api/models';
import { GET_CURRENT_SETTINGS } from '@/core/services/store/settings.module';
import { GET_IS_DOCUMENTMANAGER } from '@/core/services/store/user.module';

import SidebarProfileDropdown from './components/SidebarProfileDropdown.vue';
import { GET_CURRENT_USER } from '@/core/services/store/user.module';
import FeaturesMixin from '@/mixins/FeaturesMixin';

type TNavigationItem = {
  routeName: string;
  label: string;
  icon: string;
  count?: number;
  countDanger?: number;
};

@Component({
  name: 'AppSidebar',
  components: {
    SidebarProfileDropdown,
  },
})
export default class AppSidebar extends mixins(PermissionMixin, FeaturesMixin) {
  @Getter(GET_CURRENT_SETTINGS)
  currentSettings: ClientHostSettingsDto;

  @Getter(GET_CURRENT_USER)
  currentUser: CurrentUserProfileEditDto;

  @Getter(GET_IS_DOCUMENTMANAGER)
  isDocumentManager: boolean;

  get navigationRoutesNonLicensedList(): TNavigationItem[] {
    return [
      {
        routeName: 'landing',
        label: this.$t('HOME'),
        icon: 'home',
      },
    ];
  }
  get importPageRoute() {
    return { name: 'hub-import', query: { page: '1' } };
  }
  get navigationRoutesList(): TNavigationItem[] {
    return this.currentUser.isLicensed
      ? this.navigationRoutesListLicensed
      : this.navigationRoutesNonLicensedList;
  }

  get navigationRoutesListLicensed(): TNavigationItem[] {
    return [
      {
        routeName: this.$routeNames.home,
        label: this.$t('HOME'),
        icon: 'home',
      },

      ...(this.isDocumentManager
        ? [
            {
              routeName: this.$routeNames.documentManager,
              label: this.$t('DOCUMENT_MANAGER'),
              icon: 'folder-folder',
            },
          ]
        : []),
      {
        routeName: this.$routeNames.accessRequests,
        label: this.$t('REQUESTS'),
        icon: 'requests',
      },
      {
        routeName: this.$routeNames.allDocuments,
        label: this.$t('ALL_DOCUMENTS'),
        icon: 'folder-folder',
      },
      {
        routeName: this.$routeNames.favourites,
        label: this.$t('FAVOURITES'),
        icon: 'favorites-outline',
      },
      // Temporary disabled
      // {
      //   routeName: 'templates',
      //   label: this.$t('TEMPLATES'),
      //   icon: 'templates',
      //   count: this.folderStats.templates,
      // },
      {
        routeName: this.$routeNames.deletedFiles,
        label: this.$t('DELETED_FILES'),
        icon: 'delete',
      },
    ];
  }

  get themePageRoute() {
    return { name: this.$routeNames.themes, query: { page: '1' } };
  }

  get feedbackEmailLink() {
    let link = `mailto:${this.currentSettings.feedbackSettings.toEmail}?`;
    if (this.currentSettings.feedbackSettings.ccEmail) {
      link += `cc=${this.currentSettings.feedbackSettings.ccEmail}&`;
    }
    if (this.currentSettings.feedbackSettings.bccEmail) {
      link += `bcc=${this.currentSettings.feedbackSettings.bccEmail}`;
    }
    return link;
  }

  get canCreateImportFromExcel() {
    return this.hasFeature(this.featureType.Import);
  }
}
