import { DashStyleType, EdgeVisualType } from '@/api/models';
import i18n from '@/core/plugins/vue-i18n';

export default [
  {
    category: 'arrows',
    imagePath: '/media/svg/arrows/arrow-1.svg',
    id: 190001,
    name: 'STRAIGHT_ARROW',
    subcategory: 'Arrows',
    style: {
      visualType: EdgeVisualType.Straight,
      stroke: {
        dashStyle: {
          type: DashStyleType.Solid,
        },
        fill: {
          color: '#000000',
        },
        thickness: 1,
      },
      sourceArrow: {
        scale: 2,
        type: 'none',
      },
      targetArrow: {
        scale: 2,
        type: 'triangle',
      },
    },
  },
  {
    category: 'arrows',
    imagePath: '/media/svg/arrows/arrow-3.svg',
    id: 190003,
    name: 'ARC_ARROW',
    subcategory: 'Arrows',
    style: {
      visualType: EdgeVisualType.Arc,
      stroke: {
        dashStyle: {
          type: DashStyleType.Solid,
        },
        fill: {
          color: '#000000',
        },
        thickness: 1,
      },
      sourceArrow: {
        scale: 2,
        type: 'none',
      },
      targetArrow: {
        scale: 2,
        type: 'triangle',
      },
    },
  },
];
