





















































































































import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'Inputs',
})
export default class Inputs extends Vue {
  inputText = '';
  inputNumber = 0;
  password = '';

  textareaText = '';
}
