import DecorationStateManager from '@/core/services/DecorationStateManager';
import {
  FreeLabelModel,
  GraphComponent,
  IGraph,
  IInputModeContext,
  ImageNodeStyle,
  INode,
  IRenderContext,
  Point,
  Rect,
  SimpleLabel,
  SimpleNode,
  Size,
  SvgVisual,
  SvgVisualGroup,
  Visual,
} from 'yfiles';
import DecorationState from '../DecorationState';
import HitResult from '../HitResult';
import JigsawNodeStyleBase from '../JigsawNodeStyleBase';
import JigsawNodeDecorator from './JigsawNodeDecorator';
import {
  DataPropertyDisplayType,
  DataPropertyDisplayTypeNames,
} from '@/core/common/DataPropertyDisplayType';
import DataPropertyUtils, {
  StaticDataPropertyNames,
} from '@/core/utils/DataPropertyUtils';
import DiagramUtils from '@/core/utils/DiagramUtils';

import JigsawRichTextLabelStyle from '../JigsawRichTextLabelStyle';
import { stripHtml, measureText } from '@/core/utils/html.utils';
import i18n from '@/core/plugins/vue-i18n';
import { AnnotationType } from '@/core/common/AnnotationType';
export interface JurisdictionDecorationState extends DecorationState {
  jurisdictionFlagImage: string;
  jurisdictionName: string;
  stateName: string;
  stateInitials: string;
}

export default class JurisdictionDecorator implements JigsawNodeDecorator {
  public $class: string = 'JurisdictionDecorator';
  public static INSTANCE: JurisdictionDecorator = new JurisdictionDecorator();
  /**
   * Dummy decoration node, used for layout positioning
   */
  public dummyDecorationNode: SimpleNode;
  /**
   * Image style used for rendering the indicator
   */
  public imageStyleJurisdiction: ImageNodeStyle;
  /**
   * Size  of indicator
   */
  public size: Size;
  /**
   * The maximum number of slots of render
   */
  constructor(options?: { size?: Size }) {
    this.size = options?.size ?? new Size(24, 18);
    /*setup */
    // dummy node for rendering
    this.dummyDecorationNode = new SimpleNode();
    // image style for rendering
    this.imageStyleJurisdiction = new ImageNodeStyle();
    // set default dummy node layout
    this.dummyDecorationNode.layout = new Rect(new Point(0, 0), this.size);
  }

  isVisible(renderContext: IRenderContext, node: INode): boolean {
    return (
      this.isJurisdictionDecoratorVisible(node) ||
      this.isJurisdictionLabelVisible(node) ||
      this.isStateDecoratorVisible(node) ||
      this.isStateLabelVisible(node)
    );
  }

  public getDecorationState(node: INode): JurisdictionDecorationState {
    return DecorationStateManager.getState(
      JurisdictionDecorator.INSTANCE,
      node
    );
  }

  public isJurisdictionDecoratorVisible(node: INode): boolean {
    return (
      JurisdictionUtils.hasJurisdictionSet(node) &&
      JurisdictionUtils.canRender(
        node,
        DataPropertyDisplayTypeNames.Jurisdiction,
        DataPropertyDisplayType.Decorator
      )
    );
  }

  public isJurisdictionLabelVisible(node: INode): boolean {
    return (
      JurisdictionUtils.hasJurisdictionSet(node) &&
      JurisdictionUtils.canRender(
        node,
        DataPropertyDisplayTypeNames.Jurisdiction,
        DataPropertyDisplayType.NodeLabel
      )
    );
  }
  public isStateDecoratorVisible(node: INode): boolean {
    return (
      JurisdictionUtils.hasStateSet(node) &&
      JurisdictionUtils.canRender(
        node,
        DataPropertyDisplayTypeNames.State,
        DataPropertyDisplayType.Decorator
      )
    );
  }

  public isStateLabelVisible(node: INode): boolean {
    return (
      JurisdictionUtils.hasStateSet(node) &&
      JurisdictionUtils.canRender(
        node,
        DataPropertyDisplayTypeNames.State,
        DataPropertyDisplayType.NodeLabel
      )
    );
  }

  createVisual(context: IRenderContext, node: INode): Visual {
    const initialLayout = this.getInitialLayout(node);
    const svgGroup = new SvgVisualGroup();
    const decorationState = this.getDecorationState(node);
    const jurisdictionDecoratorVisible =
      this.isJurisdictionDecoratorVisible(node);
    //const jurisdictionLabelVisible = this.isJurisdictionLabelVisible(node);
    const stateDecoratorVisible = this.isStateDecoratorVisible(node);
    //const stateLabelVisible = this.isStateLabelVisible(node);
    //create jurisdiction flag visual

    this.dummyDecorationNode.layout = initialLayout;

    // Broken into 3 steps
    // 1. Jurisdiction Flag
    // 2. State Initials
    // 3. Jurisdiction and State Labels
    // NODE DECORATORS

    const newFlagVisualJurisdiction =
      this.renderJurisdictionNodeDecoratorVisual(
        null,
        context,
        decorationState,
        jurisdictionDecoratorVisible,
        stateDecoratorVisible,
        this.getInitialLayout(node)
      );
    // add the visual to the group
    svgGroup.add(newFlagVisualJurisdiction);

    // create state flag visual
    var stateVisualGroup = this.renderStateNodeDecoratorVisual(
      null,
      context,
      decorationState,
      stateDecoratorVisible,
      initialLayout
    );
    svgGroup.add(stateVisualGroup);

    // // NODE LABELS
    // // Create jurisdiction & state label label visual
    // let newJurisdictionLabelVisual = null;

    // newJurisdictionLabelVisual = this.createLabelVisual(
    //   context,
    //   node,
    //   decorationState,
    //   jurisdictionLabelVisible,
    //   stateLabelVisible
    // );
    // if (newJurisdictionLabelVisual) {
    //   svgGroup.add(newJurisdictionLabelVisual);
    // }

    return svgGroup;
  }

  updateVisual(
    context: IRenderContext,
    node: INode,
    oldVisual: SvgVisualGroup
  ): Visual {
    if (oldVisual.children.size == 0) {
      return this.createVisual(context, node);
    }
    const initialLayout = this.getInitialLayout(node);
    const decorationState = this.getDecorationState(node);
    const jurisdictionDecoratorVisible =
      this.isJurisdictionDecoratorVisible(node);
    //const jurisdictionLabelVisible = this.isJurisdictionLabelVisible(node);
    const stateDecoratorVisible = this.isStateDecoratorVisible(node);
    //const stateLabelVisible = this.isStateLabelVisible(node);

    // J U R I S D I C T I O N
    // get the old visual
    let jurisdictionVisual = oldVisual.children.elementAt(0);
    // update or recreate
    // this may return the same instance
    jurisdictionVisual = this.renderJurisdictionNodeDecoratorVisual(
      jurisdictionVisual,
      context,
      decorationState,
      jurisdictionDecoratorVisible,
      stateDecoratorVisible,
      initialLayout
    );

    // replace
    if (jurisdictionVisual != oldVisual.children.elementAt(0)) {
      oldVisual.children.set(0, jurisdictionVisual);
    }

    // S T A T E
    // get the old visual
    let stateVisual = oldVisual.children.elementAt(1);

    // update or recreate
    // this may return the same instance
    stateVisual = this.renderStateNodeDecoratorVisual(
      stateVisual,
      context,
      decorationState,
      stateDecoratorVisible,
      initialLayout
    );
    // replace
    if (stateVisual != oldVisual.children.elementAt(1)) {
      oldVisual.children.set(1, stateVisual);
    }

    // // N O D E  L A B E L S
    // // if we have a label in the old visual group
    // if (oldVisual.children.size == 3) {
    //   let labelVisual = oldVisual.children.elementAt(2);

    //   labelVisual = this.updateLabelVisual(
    //     context,
    //     labelVisual,
    //     node,
    //     decorationState,
    //     jurisdictionLabelVisible,
    //     stateLabelVisible
    //   );
    //   if (labelVisual != oldVisual.children.elementAt(2)) {
    //     oldVisual.children.set(2, labelVisual);
    //   }
    // }
    return oldVisual;
  }

  renderJurisdictionNodeDecoratorVisual(
    oldVisual: SvgVisual,
    context: IRenderContext,
    state: JurisdictionDecorationState,
    jurisdictionDecoratorVisible: boolean,
    stateDecoratorVisible: boolean,
    layout: Rect
  ): SvgVisual {
    // return an empty group if we don't need to render anything
    if (!jurisdictionDecoratorVisible) {
      return new SvgVisualGroup();
    }

    this.dummyDecorationNode.layout = layout;

    if (stateDecoratorVisible) {
      // adjust the jurisdiction flag location to accomdate for the state visual.
      let newLocation = this.dummyDecorationNode.layout
        .toPoint()
        .subtract(new Point(this.size.width, 0));
      this.dummyDecorationNode.layout = new Rect(newLocation, this.size);
    }

    // if nothing has changed, return the old visual
    if (
      oldVisual &&
      oldVisual[JigsawNodeStyleBase.RenderCacheKey] &&
      oldVisual[JigsawNodeStyleBase.RenderCacheKey].previousFlag ==
        state.jurisdictionFlagImage
    ) {
      this.imageStyleJurisdiction.image = state.jurisdictionFlagImage;
      // but translate it
      return this.imageStyleJurisdiction.renderer
        .getVisualCreator(this.dummyDecorationNode, this.imageStyleJurisdiction)
        .updateVisual(context, oldVisual) as SvgVisual;
    }

    this.imageStyleJurisdiction.image = state.jurisdictionFlagImage;
    const flagVisual = this.imageStyleJurisdiction.renderer
      .getVisualCreator(this.dummyDecorationNode, this.imageStyleJurisdiction)
      .createVisual(context) as SvgVisual;
    flagVisual[JigsawNodeStyleBase.RenderCacheKey] = {
      previousFlag: state.jurisdictionFlagImage,
    };
    return flagVisual;
  }

  renderStateNodeDecoratorVisual(
    oldVisual: SvgVisual,
    context: IRenderContext,
    state: JurisdictionDecorationState,
    stateDecoratorVisible: boolean,
    layout: Rect
  ): SvgVisual {
    // when we have no initials or not displaying the state
    // return empty group, nothing to show
    if (!stateDecoratorVisible) {
      return new SvgVisualGroup();
    }

    // if we have an old visual, comes from the updateVisual
    // and the initials haven't changed, do nothing and return the old visual
    if (
      oldVisual != null &&
      oldVisual[JigsawNodeStyleBase.RenderCacheKey] &&
      oldVisual[JigsawNodeStyleBase.RenderCacheKey].previousInitials ==
        state.stateInitials
    ) {
      const oldVisualGroup = oldVisual as SvgVisualGroup;

      this.updateCircleSVGLocation(
        oldVisualGroup.children.elementAt(0).svgElement,
        layout
      );
      this.updateStateInitialsSVGLocation(
        oldVisualGroup.children.elementAt(1).svgElement,
        layout,
        state.stateInitials
      );
      return oldVisual;
    }
    // if we got this far we need to create all the visuals.

    // render outer circle
    const circleSvgElement = this.createCircleSVG(this.stateVisualSize);

    // render state initials as text
    const stateInitialsSvgElement = this.createStateInitialsSVG(
      state.stateInitials,
      10
    );

    const circleSvgVisual = this.updateCircleSVGLocation(
      circleSvgElement,
      layout
    );
    const stateInitialsSvgVisual = this.updateStateInitialsSVGLocation(
      stateInitialsSvgElement,
      layout,
      state.stateInitials
    );

    const group = new SvgVisualGroup();
    group.add(circleSvgVisual);
    group.add(stateInitialsSvgVisual);
    group[JigsawNodeStyleBase.RenderCacheKey] = {
      previousInitials: state.stateInitials,
    };
    return group;
  }

  createCircleSVG(size: number, strokeWidth: number = 1): SVGElement {
    const circle = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'ellipse'
    );
    circle.setAttribute('rx', size.toString());
    circle.setAttribute('ry', size.toString());
    circle.setAttribute('stroke', 'black');
    circle.setAttribute('fill', 'white');
    circle.setAttribute('stroke-width', strokeWidth.toString());
    return circle;
  }

  createStateInitialsSVG(stateInitials: string, fontSize: number): SVGElement {
    const textState = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'text'
    );
    const fontFamily = 'arial';
    textState.textContent = stateInitials;
    textState.setAttribute('fill', 'black');
    textState.setAttribute('font-size', fontSize.toString());
    textState.setAttribute('font-family', fontFamily);
    textState.setAttribute('class', 'heavy');
    textState.setAttribute('lengthAdjust', 'spacingAndGlyphs');
    textState.setAttribute('font-size', `${fontSize}`);

    return textState;
  }

  hasChildItems(node: INode, dataPropertyDefinitionId: number): boolean {
    const dataProperty = node.tag.dataProperties.find(
      (dp) => dp.dataPropertyDefinitionId == dataPropertyDefinitionId
    );

    if (!dataProperty) {
      return false;
    }
    const dataPropertyDefinition = DataPropertyUtils.getDefinition(
      dataProperty.dataPropertyDefinitionId
    );
    if (!dataPropertyDefinition) {
      return false;
    }

    const dataPropertyDefinitionItem =
      DataPropertyUtils.getDefinitionItemByDataPropertyName(
        node,
        dataPropertyDefinition.label
      );
    if (!dataPropertyDefinitionItem) {
      return false;
    }

    return DataPropertyUtils.hasChildren(dataPropertyDefinitionItem);
  }

  /**
   * Defines the size that the circle wrapping the state intials should be
   * This size keeps it the same height as the flag visual for jurisdiction
   * This value is effectivley doubled during render
   */
  private stateVisualSize: number = 9;
  /**
   * Padding to seperate the state visual and jurisdiction visual
   */
  private stateVisualPadding: number = 5;

  updateStateInitialsSVGLocation(
    stateInitialsSvg: SVGElement,
    initialLayout: Rect,
    stateInitials: string
  ) {
    const fontSize = 10;
    const fontFamily = 'arial';
    // measure the text to ensure we can calculate center positions
    const html = `<div style="font-size: ${fontSize}px;font-family:${fontFamily}">${stateInitials}</div>`;
    const textSize = measureText(html);

    const stateCircleLocation = this.getStateCircleLocation(initialLayout);
    SvgVisual.setTranslate(
      stateInitialsSvg,
      stateCircleLocation.x - textSize.width / 2.25,
      stateCircleLocation.y + textSize.height / 4.25
    );
    return new SvgVisual(stateInitialsSvg);
  }

  updateCircleSVGLocation(circle: SVGElement, initialLayout: Rect): SvgVisual {
    const location = this.getStateCircleLocation(initialLayout);
    SvgVisual.setTranslate(circle, location.x, location.y);
    return new SvgVisual(circle);
  }

  /**
   * Gets the base position for the state circle
   * @param initialLayout the initial layout to base it's position off. Usually the jurisdiction flag x,y
   * @returns
   */
  public getStateCircleLocation(initialLayout: Rect) {
    let x = initialLayout.x + this.stateVisualSize + this.stateVisualPadding;
    let y = initialLayout.y + this.stateVisualSize;

    return new Point(x, y);
  }

  getLabelPosition(node: INode, labelSize: Size): Point {
    // place just inside the node, at the bottom

    const existingLabel = DiagramUtils.getLabel(node);
    let anchorX: number, anchorY: number, width: number;
    if (existingLabel) {
      ({ anchorX, anchorY, width } = existingLabel.layout);
    }

    if (!existingLabel?.layout || !stripHtml(existingLabel?.text)?.trim()) {
      // when we have no existing label
      //create a dummy anchor point

      // we need to get the labels height, measure a single character
      const { height } = JigsawRichTextLabelStyle.measureTextRaw(
        'A',
        node.layout.width
      );

      // get the node's default label parameter (for positioning)
      const labelModel = DiagramUtils.getLabelModelParameter(node);

      // create a dummy label
      const dummyLabel = new SimpleLabel({
        owner: node,
        layoutParameter: labelModel,
        preferredSize: new Size(0, height),
      });

      // extract the correct anchorX/Y
      ({ anchorX, anchorY } = labelModel.model.getGeometry(
        dummyLabel,
        labelModel
      ));

      // adjust for the width of the label
      anchorX -= labelSize.width * 0.5;
    } else {
      anchorY += labelSize.height;
      anchorX = anchorX + width * 0.5 - labelSize.width * 0.5;
    }
    return new Point(anchorX, anchorY);
  }

  public getLabelSize(text: string): Size {
    return JigsawRichTextLabelStyle.measureTextRaw(text);
  }

  createLabelVisual(
    context: IRenderContext,
    node: INode,
    state: JurisdictionDecorationState,
    jurisdictionLabelVisible: boolean,
    stateLabelVisible: boolean
  ): SvgVisual {
    if (!jurisdictionLabelVisible && !stateLabelVisible) {
      return new SvgVisualGroup();
    }
    const text = this.buildLabelText(
      node,
      jurisdictionLabelVisible,
      stateLabelVisible,
      state
    );
    const labelSize = this.getLabelSize(text);
    const pointJurisdiction = this.getLabelPosition(node, labelSize);
    const label = new SimpleLabel({
      layoutParameter:
        FreeLabelModel.INSTANCE.createAbsolute(pointJurisdiction),
      text: text,
      preferredSize: labelSize,
      style: new JigsawRichTextLabelStyle(),
    });

    const style = new JigsawRichTextLabelStyle();
    const labelVisual = style.renderer
      .getVisualCreator(label, style)
      .createVisual(context) as SvgVisual;

    labelVisual[JigsawNodeStyleBase.RenderCacheKey] = {
      previousLabel: text,
      labelSize: labelSize,
    };
    return labelVisual;
  }

  updateLabelVisual(
    context: IRenderContext,
    oldVisual: SvgVisual,
    node: INode,
    state: JurisdictionDecorationState,
    jurisdictionLabelVisible: boolean,
    stateLabelVisible: boolean
  ): SvgVisual {
    const renderCache = oldVisual[JigsawNodeStyleBase.RenderCacheKey];
    const text = this.buildLabelText(
      node,
      jurisdictionLabelVisible,
      stateLabelVisible,
      state
    );
    if (!renderCache || renderCache.previousLabel != text) {
      return this.createLabelVisual(
        context,
        node,
        state,
        jurisdictionLabelVisible,
        stateLabelVisible
      );
    }
    const labelPosition = this.getLabelPosition(node, renderCache.labelSize);
    SvgVisual.setTranslate(
      oldVisual.svgElement,
      labelPosition.x,
      labelPosition.y - renderCache.labelSize.height
    );
    return oldVisual;
  }

  public buildLabelText(
    node: INode,
    jurisdictionLabelVisible: boolean,
    stateLabelVisible: boolean,
    state: JurisdictionDecorationState
  ) {
    let color = JurisdictionUtils.getTextColor(node);

    let text = '';
    const styleString = `font-size:8pt;line-height:1.1em`;
    if (jurisdictionLabelVisible) {
      text += `<p style="text-align:center;color:${color}"><span>(${state.jurisdictionName})</span></p>`;
    }
    if (stateLabelVisible) {
      text += `<p style="text-align:center;color:${color}"><span>(${state.stateName})</span></p>`;
    }
    return `<div style="${styleString}">${text}</div>` ?? '';
  }

  isHit(context: IInputModeContext, location: Point, node: INode): HitResult {
    // indicators are not clickable
    return HitResult.NONE;
  }

  getInitialLayout(node: INode) {
    const nodeLayout = node.layout;
    let size = this.dummyDecorationNode.layout.toSize();

    const x = nodeLayout.x + nodeLayout.width - 5 - size.width;
    let y = nodeLayout.y + 3;

    if (node.tag.annotationType == AnnotationType.ClipArt) {
      y -= size.width;
    }

    return new Rect(x, y, size.width, size.height);
  }

  public defaultState(): JurisdictionDecorationState {
    return {
      jurisdictionFlagImage: null,
      jurisdictionName: null,
      stateName: null,
      stateInitials: null,
    };
  }
}
export type JurisdictionValueType = 'jurisdiction' | 'state';
export interface IPersistedDataPropertyDisplayTypes {
  [key: string]: DataPropertyDisplayType[];
}
export class JurisdictionUtils {
  /**
   * Sync the nodes label, removing or adding the jurisdiction text
   * @param graph
   * @param node
   * @returns
   */
  public static syncJurisdictionLabelElement(graph: IGraph, node: INode): void {
    JurisdictionUtils.syncLabelElement(graph, node, 'jurisdiction');
  }

  /**
   * Sync the nodes label, removing or adding the state text
   * @param graph
   * @param node
   * @ret
   */
  public static syncStateLabelElement(graph: IGraph, node: INode): void {
    JurisdictionUtils.syncLabelElement(graph, node, 'state');
  }

  /**
   * Sync the label for the given @param type
   * If a value has been set and the label can be render, then an element will be inserted, otherwise removed
   * This is short hand for calling either @method setLabelElement or @method removeLabelElement
   * @param graph
   * @param node
   * @param type
   * @returns
   */
  public static syncLabelElement(
    graph: IGraph,
    node: INode,
    type: JurisdictionValueType
  ): void {
    const displayType = JurisdictionUtils.getDisplayType(type);
    const dpDisplayTypes = node.tag.dataPropertyDisplayTypes[displayType];
    if (!dpDisplayTypes) {
      return;
    }
    if (
      this.hasValueSet(node, type) &&
      JurisdictionUtils.canRender(
        node,
        displayType,
        DataPropertyDisplayType.NodeLabel
      )
    ) {
      JurisdictionUtils.setLabelElement(
        graph,
        node,
        JurisdictionUtils.formatValue(node, type),
        type
      );
    } else {
      JurisdictionUtils.removeLabelElement(graph, node, type);
    }
  }

  /**
   * Appends the currently selected jurisdiction text to the nodes label
   * @param graph
   * @param node
   */
  public static setJurisidctionLabelElement(graph: IGraph, node: INode) {
    JurisdictionUtils.setLabelElement(
      graph,
      node,
      JurisdictionUtils.formatValue(node, 'jurisdiction'),
      'jurisdiction'
    );
  }

  /**
   * Appends the currently selected state text to the nodes label
   * @param graph
   * @param node
   */
  public static setStateLabelElement(graph: IGraph, node: INode) {
    JurisdictionUtils.setLabelElement(
      graph,
      node,
      JurisdictionUtils.formatValue(node, 'state'),
      'state'
    );
  }

  /**
   * Removes the jurisdiction label from the node's text
   * @param graph
   * @param node
   */
  public static removeJurisdictionLabelElement(graph: IGraph, node: INode) {
    this.removeLabelElement(graph, node, 'jurisdiction');
  }
  /**
   * Removes the state label from the node's text
   * @param graph
   * @param node
   */
  public static removeStateLabelElement(graph: IGraph, node: INode) {
    this.removeLabelElement(graph, node, 'state');
  }

  /**
   * Ensures the nodes label has a <p> containing the @param labelValue wrapped in a <span>
   * If the label aleady has a <p type="true"> then the content of this will be updated
   * @param graph
   * @param node
   * @param labelValue
   * @param type
   * @returns
   */
  public static setLabelElement(
    graph: IGraph,
    node: INode,
    labelValue: string,
    type: JurisdictionValueType
  ) {
    if (!node) {
      return;
    }
    let label = DiagramUtils.getLabel(node);

    if (!label) {
      label = DiagramUtils.addOrCreateLabel(graph, node);
    }

    // create a temporary container that we can use for element manipulation
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = label.text;

    // creates a selector for the given type e.g [state="true"]
    const attributeSelector = JurisdictionUtils.getAttributeSelector(type);

    // attempted to locate an element matching the selector or create one
    const paragraph: HTMLElement =
      tempContainer.querySelector(attributeSelector) ??
      document.createElement('p');

    // element was found and not created
    // clear any existing elements
    if (paragraph.childElementCount > 1) {
      var nodes = paragraph.childNodes;
      for (let index = 0; index < nodes.length; index++) {
        const element = nodes[index];
        paragraph.removeChild(element);
      }
    }

    // find or create a span to use that we can style appropriatly
    const span: HTMLElement =
      (paragraph.firstElementChild as HTMLElement) ??
      document.createElement('span');

    // span was created and not found, it needs to be configured
    if (!paragraph.getAttribute(type)) {
      paragraph.appendChild(span);
      paragraph.setAttribute(type, 'true');
      paragraph.style.textAlign = 'center';
      span.style.fontSize = '8pt';
      span.style.color = JurisdictionUtils.getTextColor(node);
      tempContainer.appendChild(paragraph);
    }

    span.innerHTML = labelValue;
    graph.setLabelText(label, tempContainer.innerHTML);
  }

  /**
   * removes the <p> tag from the nodes label, based on @param attribute
   * @param graph
   * @param node
   * @param type
   * @returns
   */
  public static removeLabelElement(
    graph: IGraph,
    node: INode,
    type: JurisdictionValueType
  ) {
    if (!node) {
      return;
    }
    const label = DiagramUtils.getLabel(node);
    if (!label) {
      return;
    }
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = label.text;
    const paragraph = tempContainer.querySelector(
      JurisdictionUtils.getAttributeSelector(type)
    );
    if (!paragraph) {
      return;
    }
    tempContainer.removeChild(paragraph);
    graph.setLabelText(label, tempContainer.innerHTML);
  }

  /**
   * Gets the text color from the current label. This is a best guess as the label could contain multiple colors
   * @param node
   * @returns
   */
  public static getTextColor(node: INode): string {
    let color = '#000000';
    // try get color from the current labe
    if (node.labels.size > 0) {
      const labelText = DiagramUtils.getLabelValue(node);
      if (labelText) {
        const colorMatches = Array.from(
          labelText.matchAll(/(;|"|')(\s*color:\s*)(.*?)(;|"|')/gm),
          (d) => d[3]
        );
        if (colorMatches.length > 0) {
          color = colorMatches[0];
        }
      }
    }
    return color;
  }

  /**
   * Returns a formatted value to be used as the label value
   * @param node
   * @param type
   * @returns
   */
  public static formatValue(node: INode, type: JurisdictionValueType): string {
    const state = DecorationStateManager.getState<JurisdictionDecorationState>(
      JurisdictionDecorator.INSTANCE,
      node
    );
    if (!state) {
      return null;
    }
    switch (type) {
      case 'jurisdiction':
        return `(${state.jurisdictionName})`;
      case 'state':
        return `(${state.stateName})`;
    }
    throw `Unsupported type ${type}`;
  }

  /**
   * Checks the decoration state to see if a value has been set for the given @param type
   * @param node
   * @param type
   * @returns
   */
  public static hasValueSet(node: INode, type: JurisdictionValueType): boolean {
    switch (type) {
      case 'jurisdiction':
        return JurisdictionUtils.hasJurisdictionSet(node);

      case 'state':
        return JurisdictionUtils.hasStateSet(node);
    }
    throw `Unsupported type ${type}`;
  }
  /**
   * Checks the decoration state to see if a value has been set for Jurisdiction
   * @param node
   * @param type
   * @returns
   */
  public static hasJurisdictionSet(node: INode) {
    return !!JurisdictionUtils.getDecorationState(node).jurisdictionName;
  }
  /**
   * Checks the decoration state to see if a value has been set for State
   * @param node
   * @param type
   * @returns
   */
  public static hasStateSet(node: INode) {
    return !!JurisdictionUtils.getDecorationState(node).stateName;
  }

  /**
   * Returns true if the given displayTypeName and displayType should be rendered
   * @param node the node to check against
   * @param displayTypeName
   * @param displayType
   * @returns
   */
  public static canRender(
    node: INode,
    displayTypeName: DataPropertyDisplayTypeNames,
    displayType: DataPropertyDisplayType
  ) {
    const dpDisplayTypes = node.tag.dataPropertyDisplayTypes[displayTypeName];
    if (!dpDisplayTypes) {
      return;
    }
    return dpDisplayTypes.includes(displayType);
  }

  /**
   * Gets the decoration state from the node
   * @param node
   * @returns
   */
  private static getDecorationState(node: INode): JurisdictionDecorationState {
    return DecorationStateManager.getState<JurisdictionDecorationState>(
      JurisdictionDecorator.INSTANCE,
      node
    );
  }

  public static setJurisdictionDecorationState(
    node: INode,
    graphComponent: GraphComponent
  ) {
    const state = DecorationStateManager.getState(
      JurisdictionDecorator.INSTANCE,
      node
    ) as JurisdictionDecorationState;
    const dpDefinitionItem =
      DataPropertyUtils.getDefinitionItemByDataPropertyName(
        node,
        StaticDataPropertyNames.Jurisdiction
      );
    state.jurisdictionFlagImage = dpDefinitionItem?.imageData;
    state.jurisdictionName = i18n.t(dpDefinitionItem?.itemValue).toString();
    const dpDefinitionItemState =
      DataPropertyUtils.getDefinitionItemByDataPropertyName(
        node,
        StaticDataPropertyNames.State
      );
    state.stateName = i18n.t(dpDefinitionItemState?.itemValue).toString();
    state.stateInitials = dpDefinitionItemState?.itemInitials;
    graphComponent.invalidate();
    graphComponent.graph.invalidateDisplays();
  }

  public static getDisplayType(
    type: JurisdictionValueType
  ): DataPropertyDisplayTypeNames {
    switch (type) {
      case 'jurisdiction':
        return DataPropertyDisplayTypeNames.Jurisdiction;
      case 'state':
        return DataPropertyDisplayTypeNames.State;
    }
    throw `Unsupported type ${type}`;
  }
  private static getAttributeSelector(type: JurisdictionValueType): string {
    return `[${type}="true"]`;
  }

  public static persistJurisdictionOptions(
    selectedNode: INode,
    persistedDataPropertyDisplayTypes: IPersistedDataPropertyDisplayTypes
  ) {
    const dataPropertyDisplayTypeJurisdictionValues =
      selectedNode.tag.dataPropertyDisplayTypes[
        DataPropertyDisplayTypeNames.Jurisdiction
      ];
    const persistedDataPropertyDisplayJurisdictionTypes =
      persistedDataPropertyDisplayTypes[
        DataPropertyDisplayTypeNames.Jurisdiction
      ];

    selectedNode.tag.dataPropertyDisplayTypes[
      DataPropertyDisplayTypeNames.Jurisdiction
    ] = dataPropertyDisplayTypeJurisdictionValues.filter((x) => {
      if (
        !persistedDataPropertyDisplayJurisdictionTypes ||
        !persistedDataPropertyDisplayJurisdictionTypes.length
      )
        return true;
      return !persistedDataPropertyDisplayJurisdictionTypes.includes(x);
    });
  }
}
