import Vue from 'vue';
import appConfig from '@/core/config/appConfig';
import { ToastPosition } from 'vue-toasted';
import i18n from '@/core/plugins/vue-i18n';
export enum NotificationType {
  Error,
  Warning,
  Success,
  Information,
}

export default class NotificationUtils {
  public static notify(
    message: string,
    messageType: NotificationType,
    toastDuration = appConfig.toastDurationms,
    position: ToastPosition = 'top-right'
  ) {
    switch (messageType) {
      case NotificationType.Error:
        Vue.toasted.error(message, {
          className: ['jigsawMainColor'],
          duration: toastDuration,
          position,
        });
        break;

      case NotificationType.Warning:
        Vue.toasted.error(message, {
          className: ['jigsawMainColor'],
          duration: toastDuration,
          position,
        });
        break;

      case NotificationType.Success:
        Vue.toasted.success(message, {
          className: ['jigsawMainColor'],
          duration: toastDuration,
          position,
        });
        break;

      case NotificationType.Information:
        Vue.toasted.info(message, {
          className: ['jigsawMainColor'],
          duration: toastDuration,
          position,
        });
        break;
    }
  }
}
