import { DocumentAttachmentType } from '@/api/models';
import appConfig from '@/core/config/appConfig';
import ExportConfig from '@/core/config/ExportConfig';
import BackgroundDomService from '../../BackgroundDomService';
import ExportOptions from '../ExportOptions';
import ExportPage from '../ExportPage';
import ExportPageElement from '../ExportPageElement';
import { ExportPageElementType } from '../ExportPageElementType';
import IAdditionalElementProvider from './IAdditionalElementProvider';

export default class LogoAsImageProvider implements IAdditionalElementProvider {
  async get(
    options: ExportOptions,
    exportPage: ExportPage
  ): Promise<ExportPageElement[]> {
    const logo = options.document?.attachments?.find(
      (a) => a.attachmentType == DocumentAttachmentType.Logo
    );
    if (!logo) {
      return null;
    }

    return await new Promise<ExportPageElement[]>((resolve, reject) => {
      try {
        const logoImageUrl = `${
          appConfig.apiBaseUrl + logo.fileAttachment.path
        }`;
        const img = document.createElement('img');
        img.style.width = `${ExportConfig.diagramLogoSize.width}px`;
        img.style.maxHeight = `${ExportConfig.diagramLogoSize.height}px`;
        if (!options.document.hasSteps) {
          img.style.padding = `${ExportConfig.pageLogoPadding}px`;
        }

        // Add logo to document to render & apply styles
        const logoContainer = BackgroundDomService.createElement('div');
        logoContainer.style.position = 'absolute';
        logoContainer.append(img);
        BackgroundDomService.appendElement(logoContainer);

        const logoExport = new ExportPageElement(
          null,
          ExportPageElementType.Logo,
          img,
          {
            position: options.document.logoPosition,
            padding: 20,
          }
        );
        logoExport.destroy = () => {
          logoContainer.remove();
        };

        // Wait until logo image is loaded before proceeding with the export
        img.src = logoImageUrl;
        img.onload = () => resolve([logoExport]);
        img.onerror = () => reject();
      } catch (e) {
        reject(e);
      }
    });
  }
}
