import { Component, Vue, Prop } from 'vue-property-decorator';
const ckEditorImport = () =>
  import('@jigsaw/ckeditor5-custom-build/build/ckeditor.js');
import basicEditorConfig from '@/core/config/ckEditor/basicEditorConfig';
import ITextEditor from '@/core/common/ITextEditor';
import { DOCUMENT_NAMESPACE, GET_READONLY } from '../store/document.module';
import { Getter } from 'vuex-class';
import CKEditorInspector from '@ckeditor/ckeditor5-inspector'; //<< ENABLE FOR INSPECTION DONT DELETE
import ICommandHandler from './ICommandHandler';
import CkEditorCommandHandler from './CkEditorCommandHandler';
import CKEditorUtils, {
  ribbonToolsSelector,
  toolbarSelector,
} from '@/core/utils/CKEditorUtils';
import CkEditorCommandStateManager from '../graph/CkEditorCommandStateManager';

@Component({})
export default class CkEditorInstanceMixin extends Vue {
  protected editorIsReady: boolean;
  protected autoUnfocus: boolean = true;

  editor: ITextEditor;
  editorType: any = null;

  @Prop({
    required: false,
    default: () => {
      return () => {
        return {
          fontBackgroundColor: false,
          bulletedList: false,
          numberedList: false,
          alignment: false,
          strikethrough: false,
          subscript: false,
          superscript: false,
          heading: false,
          insertTable: false,
          insertPageBreak: false,
        };
      };
    },
  })
  getToolbarItemStates: (isFocused: boolean) => any;

  @Prop({
    default: () => basicEditorConfig.getConfig(),
  })
  editorConfiguration: any;
  @Prop({
    default: () => toolbarSelector,
  })
  toolbarSelector: any;

  @Getter(GET_READONLY, { namespace: DOCUMENT_NAMESPACE })
  isReadOnly: boolean;

  private commandHandler: ICommandHandler;
  private ckEditorCommandStateManager: CkEditorCommandStateManager;
  /**
   *
   */
  constructor() {
    super();

    //this.toolbars = new Mapper<any, any>();
  }

  async created() {
    const ckEditor = await ckEditorImport();
    this.editorType = ckEditor.default;
    CKEditorUtils.ensureTranslationsLoaded();
  }

  public onEditorReady(editor) {
    this.editor = editor;
    // ENABLE THIS FOR INSPECTOR ->
    //CKEditorInspector.attach(editor);

    this.editor.execute('setHtmlAttributes', 'table', 'table', 'style');
    CKEditorUtils.addElementsToFocusTracker(editor);
    this.commandHandler = new CkEditorCommandHandler(editor);
    this.ckEditorCommandStateManager = new CkEditorCommandStateManager(editor);
    this.editorIsReady = true;

    CKEditorUtils.toolbarStateSync(
      editor,
      this.toolbarSelector,
      ribbonToolsSelector,
      false,
      this.commandHandler
    );
    this.ckEditorCommandStateManager.syncCommands(
      this.getToolbarItemStates(false)
    );

    if (this.isReadOnly) return;

    editor.editing.view.document.on(
      'change:isFocused',
      (evt, data, isFocused) => {
        CKEditorUtils.toolbarStateSync(
          editor,
          this.toolbarSelector,
          ribbonToolsSelector,
          isFocused,
          this.commandHandler
        );
        this.ckEditorCommandStateManager.syncCommands(
          this.getToolbarItemStates(isFocused)
        );
      }
    );

    // Disable drag & drop
    editor.editing.view.document.on(
      'dragstart',
      (evt, data) => {
        // Stop executing next callbacks.
        evt.stop();

        // Prevent the default event action.
        data.preventDefault();
      },
      { priority: 'high' }
    );
    editor.editing.view.document.on(
      'drop',
      (evt, data) => {
        // Stop executing next callbacks.
        evt.stop();

        // Prevent the default event action.
        data.preventDefault();
      },
      { priority: 'high' }
    );
    editor.editing.view.document.on(
      'dragover',
      (evt, data) => {
        evt.stop();
        data.preventDefault();
      },
      { priority: 'high' }
    );
  }

  plainTextToHtml(text: string): string {
    text = text
      // Encode <>.
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      // Creates a paragraph for each double line break.
      .replace(/\r?\n\r?\n/g, '</p><p>')
      // Creates a line break for each single line break.
      .replace(/\r?\n/g, '<br>')
      // Preserve trailing spaces (only the first and last one – the rest is handled below).
      .replace(/^\s/, '&nbsp;')
      .replace(/\s$/, '&nbsp;')
      // Preserve other subsequent spaces now.
      .replace(/\s\s/g, ' &nbsp;');

    if (text.includes('</p><p>') || text.includes('<br>')) {
      // If we created paragraphs above, add the trailing ones.
      text = `<p>${text}</p>`;
    }
    return text;
  }
}
