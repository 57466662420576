


























































































import { debounce } from '@/core/common/DebounceDecorator';
import { isPrimitive } from '@/core/utils/common.utils';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

export type BreadcrumbItem = {
  id: string | number;
  label: string;
};

export type BreadcrumDropdownOption = {
  label: string;
  command: string;
};

export type CrumbSize = 'default' | 'sm';

export type LastItemOptions = Array<BreadcrumDropdownOption | 'divider'>;

// TODO: add translation
const defaultLastItemOptions: () => LastItemOptions = () => [
  { label: 'Share', command: 'share' },
  { label: 'Copy link', command: 'copy' },
  'divider',
  { label: 'Download files as PDF', command: 'download_pdf' },
  { label: 'Download files as Powerpoint', command: 'download_ppt' },
  'divider',
  { label: 'Rename', command: 'rename' },
  { label: 'Add to favorites', command: 'add_to_favourites' },
];

const crumbSizes = {
  sm: 'j-text-14 j-p-5 j-py-9',
  default: 'j-text-20 j-py-10 j-px-12',
};
const crumbDividerSizes = {
  sm: '12',
  default: '16',
};

@Component({
  name: 'AppBreadcrumb',
})
export default class AppBreadcrumb extends Vue {
  @Prop({ required: true, type: Array })
  crumbs: BreadcrumbItem[];

  @Prop({ default: defaultLastItemOptions, type: Array })
  lastItemOptions: LastItemOptions;

  @Prop({ default: 'default', type: String })
  size: CrumbSize;

  $refs!: {
    appBreadcrumb: HTMLElement;
    appBreadcrumbWrap: HTMLDivElement;
  };

  visibleCount: number = Infinity;
  resizeObserver = null as ResizeObserver;

  get isIncompact() {
    return this.crumbs.length > this.visibleCount;
  }

  get dropdownItemsCrumbs() {
    if (this.isIncompact) {
      return this.crumbs.slice(0, -this.visibleCount);
    }

    return [];
  }

  get visibleItemsCrumbs() {
    return this.crumbs.slice(-this.visibleCount);
  }

  get visibleLastCrumbOptions() {
    if (this.lastItemOptions.length) {
      return this.lastItemOptions.map((option) => {
        if (isPrimitive(option)) {
          return { divider: true };
        }

        return {
          divider: false,
          ...(option as BreadcrumDropdownOption),
        };
      });
    }

    return [];
  }

  get crumbSizeClass() {
    return crumbSizes[this.size];
  }
  get crumbDividerSize() {
    return crumbDividerSizes[this.size];
  }

  @Watch('crumbs')
  onCrumbsChangeHandler() {
    this.handleCrumbsWidth();
  }

  mounted() {
    this.resizeObserver = new ResizeObserver(() => {
      this.handleCrumbsWidth();
    });

    this.resizeObserver.observe(this.$refs.appBreadcrumbWrap);
  }

  beforeDestroy() {
    this.resizeObserver.disconnect();
  }

  async handleCrumbsWidth() {
    this.visibleCount = Infinity;
    await this.$nextTick();

    const breadcrumbsWidth = this.$refs.appBreadcrumbWrap.clientWidth - 16; // 14 is just margin

    const breadcrumbItemsArray = Array.from(this.$refs.appBreadcrumb.children)
      .slice()
      .reverse();

    let breadcrumbVisibleItemsWidth = 0;
    for (let i = 0; i < breadcrumbItemsArray.length; i++) {
      breadcrumbVisibleItemsWidth += breadcrumbItemsArray[i].scrollWidth;

      if (breadcrumbVisibleItemsWidth > breadcrumbsWidth && i > 0) {
        this.visibleCount = i;
        break;
      }
    }
  }

  isLast(index: number) {
    return index === this.visibleItemsCrumbs.length - 1;
  }

  selected(crumb: BreadcrumbItem) {
    this.$emit('selected', crumb);
  }

  lastItemCommandHandler(crumb: BreadcrumbItem, command: string) {
    this.$emit('custom:action', { item: crumb, command });
  }
}
