import Vue from 'vue';
import moment from 'moment';

import { formatCsvString } from '@/core/utils/common.utils';

const upper = (value: string) => {
  if (typeof value === 'string') {
    return value.toUpperCase();
  }

  return value;
};

const time = (value: string, format: string = 'L') => {
  if (value && moment(value).isValid()) {
    return moment(value).format(format);
  }

  return value;
};

export const fromNow = (value: string) => {
  if (value && moment(value).isValid()) {
    const isLessThen = moment(value).diff(Date.now(), 'hours') > -21;

    return isLessThen ? moment(value).fromNow() : time(value);
  }

  return value;
};

// do not remove
// Vue.filter('highlight', function (words, query) {
//   const check = new RegExp(query, 'ig');
//   return words.toString().replace(check, function (matchedText) {
//     return "<span class='j-bg-accent-purple-20'>" + matchedText + '</span>';
//   });
// });

Vue.filter('upper', upper);
Vue.filter('time', time);
Vue.filter('fromNow', fromNow);
Vue.filter('formatCsvString', formatCsvString);
