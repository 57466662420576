<template>
  <div class="j-w-full j-h-1 j-my-4 j-bg-main-dark-20" />
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'AppDropdownDivider',
})
export default class AppDropdownDivider extends Vue {}
</script>
