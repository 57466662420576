








































/**
 * This Component is powered by @popperjs (positioning engine)
 * Plugin home page: https://popper.js.org/
 */

import { Vue, Component, Prop } from 'vue-property-decorator';
import { createPopper, Modifier } from '@popperjs/core';

export type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

export type FlyoutTriggers = 'click' | 'hover' | 'contextmenu';

const sideClass = {
  top: {
    'enter-class': 'j-opacity-0 j--translate-y-10',
    'leave-to-class': 'j-opacity-0 j--translate-y-10',
  },
  bottom: {
    'enter-class': 'j-opacity-0 j-translate-y-10',
    'leave-to-class': 'j-opacity-0 j-translate-y-10',
  },
  left: {
    'enter-class': 'j-opacity-0 j--translate-x-10',
    'leave-to-class': 'j-opacity-0 j--translate-x-10',
  },
  right: {
    'enter-class': 'j-opacity-0 j-translate-x-10',
    'leave-to-class': 'j-opacity-0 j-translate-x-10',
  },
};

function generateGetBoundingClientRect(x = 0, y = 0) {
  return () => ({
    width: 0,
    height: 0,
    top: y,
    right: x,
    bottom: y,
    left: x,
  });
}

@Component({
  name: 'AppFlyout',
})
export default class AppFlyout extends Vue {
  @Prop({ default: 'auto' })
  placement: Placement;

  @Prop({ default: 10 })
  offset: number;

  @Prop({ default: 'click' })
  trigger: FlyoutTriggers;

  @Prop({ default: null })
  contentClass: string;

  @Prop({ default: null })
  activatorClass: string;

  @Prop({ default: false, type: Boolean })
  showArrow: boolean;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: null })
  maxWidth: string | number;

  /**
   * only if @Prop trigger is set to "hover"
   * preserve flyout open when the trigger is set to "hover" until mouse above it
   */
  @Prop({ default: false, type: Boolean })
  interactive: boolean;

  @Prop({ default: false, type: Boolean })
  blockContent: boolean;

  @Prop({ default: false, type: Boolean })
  appendToBody: boolean;

  @Prop({ default: false, type: Boolean })
  targetLess: boolean;

  /**
   * prevent click event bubbling outside the root element of the component
   */
  @Prop({ default: false, type: Boolean })
  propagate: boolean;

  isVisible = false;
  transitionClasses = null;
  popperInstance = null;

  // Custom modifier to handle dynamic placement transition
  placementHandler: Modifier<'placementHandler', any> = {
    name: 'placementHandler',
    enabled: true,
    phase: 'main',
    fn: ({ state }) => {
      this.setTransitionSide(state.placement);
    },
  };

  $refs!: {
    button: HTMLElement;
    popper: HTMLElement;
  };

  get popperStyles() {
    return {
      maxWidth: `${this.maxWidth}px`,
    };
  }

  get isHoverInteractive() {
    return this.trigger === 'hover' && this.interactive;
  }

  rootClickHandler(evt: MouseEvent) {
    if (!this.propagate) {
      evt.stopPropagation();
    }
  }

  setTransitionSide(placement: Placement) {
    const [side] = placement.split('-');
    this.transitionClasses = sideClass[side];
  }

  private toggleVisibility(isVisible) {
    if (this.disabled) return;

    if (this.$refs.popper) {
      if (this.appendToBody) {
        document.body.appendChild(this.$refs.popper);
      }
      this.isVisible = isVisible;

      // Enable/Disabled the event listeners
      this.popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: 'eventListeners', enabled: isVisible },
        ],
      }));

      if (isVisible) {
        this.popperInstance.update();
      }

      this.$emit('visibilityChanged', isVisible);
    }
  }

  private contentMouseleaveHandler() {
    this.hide();
  }

  toggle() {
    if (this.disabled) return;

    if (this.$refs.popper && this.trigger === 'click') {
      this.isVisible ? this.hide() : this.show();
    }
  }

  virtualElement = {
    getBoundingClientRect: null,
  };

  show(originalEvent?: MouseEvent) {
    if (this.targetLess) {
      const { clientX, clientY } = originalEvent;

      this.virtualElement.getBoundingClientRect = generateGetBoundingClientRect(
        clientX,
        clientY
      );

      if (!this.popperInstance) {
        this.createPopperInstance(this.virtualElement as HTMLElement);
      }

      // should perform the update in the next DOM update cycle
      this.$nextTick(() => {
        this.popperInstance.update();
      });
    }

    this.toggleVisibility(true);
  }

  hide() {
    if (!this.isVisible) return;

    this.toggleVisibility(false);
  }

  createPopperInstance(target = this.$refs.button) {
    const popperInstance = createPopper(target, this.$refs.popper, {
      placement: this.placement,
      modifiers: [
        this.placementHandler,
        {
          name: 'offset',
          options: { offset: [0, Number(this.offset)] },
        },
        {
          name: 'arrow',
          options: { padding: 10 },
        },
        // Disable the event listeners on init
        { name: 'eventListeners', enabled: false },
      ],
    });

    popperInstance.update();

    this.popperInstance = popperInstance;
  }

  beforeDestroy() {
    if (this.appendToBody && this.$refs.popper.parentNode === document.body) {
      this.$refs.popper.remove();
    }
  }

  mounted() {
    if (this.targetLess) {
      return;
    }

    this.createPopperInstance();
  }
}
