import {
  DocumentDto,
  DocumentPageContentType,
  DocumentPageDto,
  DocumentPageType,
} from '@/api/models';
import ExportConfig from '@/core/config/ExportConfig';
import { copyElementAttributes } from '@/core/utils/html.utils';
import { Size } from 'yfiles';
import BackgroundDomService from '../BackgroundDomService';
import ContentPaginationItem from './ContentPaginationItem';
import ExportUtils from './ExportUtils';

export default class ContentPagination {
  public static readonly rootContainerClass = 'root-container';
  public static readonly chunkContainerClass = 'chunk-container';
  public static readonly innerContainerId = 'inner-container';
  public static readonly autoPageBreakClass = 'auto-page-break';
  public static readonly manualPageBreakClass = 'page-break';
  public static readonly manualPageBreakTypeAttribute = 'data-type';
  public static readonly pageIndexAttribute = 'data-index';
  public static readonly pageElementTag = 'page';
  public static readonly paragraphNodeName = 'P';
  public static readonly paragraphSplitIdAttribute = 'spid';
  public static readonly splitParagraphsBetweenPages = true;

  public static movePage(
    contentHtml: string,
    sourcePageIndex: number,
    targetPageIndex: number
  ): string {
    if (sourcePageIndex == targetPageIndex) {
      return contentHtml;
    }

    const tempContainer = this.createTempContainer();
    tempContainer.innerHTML = contentHtml;

    const sourcePage: HTMLElement = tempContainer.querySelector(
      `${this.pageElementTag}[${this.pageIndexAttribute}="${sourcePageIndex}"]`
    );
    const targetPage: HTMLElement = tempContainer.querySelector(
      `${this.pageElementTag}[${this.pageIndexAttribute}="${targetPageIndex}"]`
    );

    if (!sourcePage || !targetPage) {
      return contentHtml;
    }

    // Insert target page before or after source (depending on direction)
    if (sourcePageIndex > targetPageIndex) {
      tempContainer.insertBefore(sourcePage, targetPage);
    } else {
      tempContainer.insertBefore(targetPage, sourcePage);
    }

    // Surround both pages with manual page breaks
    if (
      sourcePageIndex > 0 &&
      !this.isManualPageBreak(sourcePage.previousSibling)
    ) {
      sourcePage.before(this.createManualPageBreak('outside'));
    }
    if (
      sourcePage.nextSibling &&
      !this.isManualPageBreak(sourcePage.nextSibling)
    ) {
      sourcePage.after(this.createManualPageBreak('outside'));
    }
    if (
      targetPageIndex > 0 &&
      !this.isManualPageBreak(targetPage.previousSibling)
    ) {
      targetPage.before(this.createManualPageBreak('outside'));
    }
    if (
      targetPage.nextSibling &&
      !this.isManualPageBreak(targetPage.nextSibling)
    ) {
      targetPage.after(this.createManualPageBreak('outside'));
    }

    // Update page index attributes
    tempContainer
      .querySelectorAll(this.pageElementTag)
      .forEach((page, index) => {
        page.setAttribute(this.pageIndexAttribute, index.toString());
      });

    return tempContainer.innerHTML;
  }

  public static removePage(contentHtml: string, pageIndex: number): string {
    const tempContainer = this.createTempContainer();
    tempContainer.innerHTML = contentHtml;

    const page: HTMLElement = tempContainer.querySelector(
      `${this.pageElementTag}[${this.pageIndexAttribute}="${pageIndex}"]`
    );

    if (!page) {
      return contentHtml;
    }

    // Remove manual page break after the page (if exists)
    // Not sure if this is the correct behaviour
    if (page.nextElementSibling?.matches('.' + this.manualPageBreakClass)) {
      page.nextElementSibling.remove();
    }
    page.remove();

    // Update page index attributes
    tempContainer
      .querySelectorAll(this.pageElementTag)
      .forEach((page, index) => {
        page.setAttribute(this.pageIndexAttribute, index.toString());
      });

    return tempContainer.innerHTML;
  }

  public static mergePages(
    leftContentHtml: string,
    rightContentHtml: string
  ): string {
    leftContentHtml = this.ensurePagedContentHtml(leftContentHtml);
    rightContentHtml = this.ensurePagedContentHtml(rightContentHtml);
    return `${leftContentHtml}${rightContentHtml}`;
  }

  public static ensurePagedContentHtml(contentHtml: string) {
    if (!contentHtml) {
      return `<${this.pageElementTag}></${this.pageElementTag}>`;
    } else if (!contentHtml.startsWith(`<${this.pageElementTag}`)) {
      return `<${this.pageElementTag}>${contentHtml}</${this.pageElementTag}>`;
    }
    return contentHtml;
  }

  public static getPageCount(page: DocumentPageDto): number {
    if (page.content && page.contentType == DocumentPageContentType.Html) {
      return this.getPageCountFromContent(page.content);
    }
    return 1;
  }

  public static getPageCountFromContent(content: string): number {
    if (content) {
      const rx = new RegExp(`</${this.pageElementTag}>`, 'gm');
      const match = content.match(rx);
      if (match) {
        return match.length;
      }
    }
    return 1;
  }

  public static getContinuousPageIndexes(contentHtml: string): number[][] {
    if (!contentHtml) {
      return [];
    }
    let currentPageIndex = 0;
    let currentGroup: number[] = [];
    const pageIndexes = [currentGroup];

    const tempContainer = this.createTempContainer();
    tempContainer.innerHTML = contentHtml;
    for (const element of tempContainer.children) {
      if (element.matches(this.pageElementTag)) {
        currentGroup.push(currentPageIndex);
        currentPageIndex++;
      }

      if (this.isManualPageBreak(element) || this.isAutoPageBreak(element)) {
        currentGroup = [];
        pageIndexes.push(currentGroup);
      }
    }
    return pageIndexes;
  }

  public static getPageNumber(
    document: DocumentDto,
    page: DocumentPageDto
  ): number {
    return document.pages.reduce((a, b) => {
      if (
        b.contentType == DocumentPageContentType.Layout ||
        document.pages.indexOf(b) >= document.pages.indexOf(page)
      ) {
        return a;
      } else {
        return a + ContentPagination.getPageCount(b);
      }
    }, 1);
  }

  public static appendElementData(
    contentHtml: string,
    columns: number,
    pageType: DocumentPageType
  ): string {
    const tempContainer = this.createTempPageContainer(
      contentHtml,
      columns,
      pageType
    );
    BackgroundDomService.appendElement(tempContainer);

    for (const childNode of tempContainer.childNodes) {
      if (BackgroundDomService.isHtmlElement(childNode)) {
        const bounds = childNode.getBoundingClientRect();
        childNode.dataset.height = bounds.height.toFixed(1);
        childNode.dataset.width = bounds.width.toFixed(1);
      }
    }

    tempContainer.remove();
    return tempContainer.innerHTML;
  }

  /**
   * Splits page content into columns.
   * @param [pageHtml] Page html content represented as a string
   * @param [columns] Number of columns to split into
   * @param [pageType] Type of the page (Content/Split)
   * @returns Array of column htmls
   */
  public static splitPageIntoColumns(
    pageHtml: string,
    columns: number,
    pageType: DocumentPageType
  ) {
    const tempContainer = this.createTempPageContainer(
      pageHtml,
      columns,
      pageType
    );
    BackgroundDomService.appendElement(tempContainer);

    let currentY: number = null;
    let currentColumnElement = document.createElement('div');
    let columnElements = [currentColumnElement];

    for (const childNode of tempContainer.childNodes) {
      if (BackgroundDomService.isHtmlElement(childNode)) {
        const bounds = childNode.getBoundingClientRect();
        // Start a new column if Y has decreased
        if (currentY && currentY >= bounds.y) {
          currentColumnElement = document.createElement('div');
          columnElements.push(currentColumnElement);
        }
        currentY = bounds.y;
      }
      currentColumnElement.append(childNode.cloneNode(true));
    }

    tempContainer.remove();
    return columnElements.map((el) => el.innerHTML);
  }

  /**
   * Splits paged content into separate page html chunks
   * Paged content is html which was already split before into <page></page> elements
   * Usually this will be the output from CKEditor
   * If in doubt use this function instead of splitRawContentIntoPages
   * @param [contentHtml] Paged html content represented as a string
   * @returns Page chunks
   */
  public static splitPagedContentIntoPages(contentHtml: string): string[] {
    if (!contentHtml) return [''];
    const match = [...contentHtml.matchAll(/<page.*?>(.*?)<\/page>/gm)];
    if (match.length > 0) {
      return match.map((m) => m[1]);
    } else {
      return [contentHtml];
    }
  }

  /**
   * Merges page html chunks back into single paged content html
   * @param [pages] Page chunks (output from splitPagedContentIntoPages)
   * @returns Content html
   */
  public static mergePagesIntoPagedContent(pages: string[]): string {
    if (!pages?.length) return '';
    return pages
      .map(
        (pageHtml, index) =>
          `<${this.pageElementTag} ${this.pageIndexAttribute}="${index}">${pageHtml}</${this.pageElementTag}>`
      )
      .join('');
  }

  /**
   * Calculate the raw (non-pages/processed) html content size based on its width
   * @param [contentHtml] Raw html content represented as a string
   * @param [columns] Number of content columns
   * @param [pageType] Type of the page (Content/Split)
   * @returns Content size (width & height)
   */
  public static measureRawContentSize(
    contentHtml: string,
    columns: number,
    pageType: DocumentPageType
  ): Size {
    const tempContainer = this.createTempPageContainer(
      contentHtml,
      columns,
      pageType
    );
    BackgroundDomService.appendElement(tempContainer);
    const size = tempContainer.getBoundingClientRect();
    tempContainer.remove();
    return new Size(size.width, size.height);
  }

  /**
   * Splits raw (non-paged/processed) html content into page-sized chunks.
   * Raw content is any html without the <page> elements
   * Usually this will be called from within the CKEditor via PageLayout Handler
   * @param [contentHtml] Raw html content represented as a string
   * @param [columns] Number of content columns
   * @param [pageType] Type of the page (Content/Split)
   * @returns Page chunks
   */
  public static splitRawContentIntoPages(
    contentHtml: string,
    columns: number,
    pageType: DocumentPageType
  ): ContentPaginationItem[] {
    const pageBodySize = ExportUtils.calculatePageBodySize(pageType);
    const pageBodySizePixels = new Size(
      pageBodySize.width * ExportConfig.upscaleMultiplier,
      pageBodySize.height * ExportConfig.upscaleMultiplier
    );

    const tempContainer = this.createTempContainer(
      pageBodySizePixels.width,
      pageBodySizePixels.height
    );
    tempContainer.innerHTML = contentHtml ?? '';

    // Move elements of out their page containers
    tempContainer
      .querySelectorAll(this.pageElementTag)
      .forEach((page) => (page.outerHTML = page.innerHTML));

    BackgroundDomService.appendElement(tempContainer);

    // Split content container into chunk elements with max height of pageSize height
    const maxChunkSize = new Size(
      Math.ceil(pageBodySizePixels.width),
      Math.ceil(pageBodySizePixels.height - ExportConfig.pageBreakThreshold)
    );
    const rootContainer = this.splitNodeIntoChunks(
      tempContainer,
      maxChunkSize,
      columns,
      this.pageElementTag
    );

    // Extract the output child nodes into array of content pages
    const items: ContentPaginationItem[] = [];
    for (const childNode of rootContainer.childNodes) {
      const element = childNode as HTMLElement;
      const size = element.getBoundingClientRect();
      const page = new ContentPaginationItem();
      page.element = element;
      page.size = new Size(size.width, size.height);
      items.push(page);
    }

    // Remove unneeded containers from dom
    tempContainer.remove();
    rootContainer.remove();

    return items;
  }

  /**
   * Splits node into chunks of the specified max height.
   * Traverses recursively through the element tree adding nested elements
   * one by one into the container until max height is reached. When this happens,
   * creates a new container and repeats the process for the remaining elements
   * @param [parentNode] The node which will be split
   * @param [maxChunkSize] Maximum size of the chunk after which the page will be split
   * @param [columns] Number of content columns
   * @param [recursiveSplitSelector] Recursively split children of matched elements
   * @param [currentContainer] Internal container to add nested elements into
   * @returns Root container
   */
  private static splitNodeIntoChunks(
    parentNode: HTMLElement,
    maxChunkSize: Size,
    columns: number,
    recursiveSplitSelector: string,
    currentContainer: HTMLElement = null
  ): HTMLElement {
    // Locate the root container to add chunks into
    let rootContainer =
      currentContainer != null
        ? this.getNodeParents(
            currentContainer,
            (n) =>
              BackgroundDomService.isHtmlElement(n) &&
              n.classList.contains(this.rootContainerClass)
          )[0].parentElement
        : null;

    // Create a new root container and a nested chunk container if empty
    if (!rootContainer) {
      rootContainer = this.createRootContainer(parentNode);
      currentContainer = this.createChunkContainer(
        rootContainer,
        maxChunkSize,
        columns
      );
    }

    // Merge back paragraphs which were split between pages
    if (this.splitParagraphsBetweenPages) {
      const splitParagraphs = [
        ...parentNode.querySelectorAll(
          `${this.paragraphNodeName}[${this.paragraphSplitIdAttribute}]`
        ),
      ];
      const mergedParagraphs = [];
      for (const firstParagraph of splitParagraphs) {
        if (
          !firstParagraph.parentElement ||
          mergedParagraphs.includes(firstParagraph)
        ) {
          continue;
        }
        mergedParagraphs.push(firstParagraph);

        const spid = firstParagraph.getAttribute(
          this.paragraphSplitIdAttribute
        );
        const secondParagraph = splitParagraphs.find(
          (p) =>
            p != firstParagraph &&
            !mergedParagraphs.includes(p) &&
            p.getAttribute(this.paragraphSplitIdAttribute) == spid
        );

        if (secondParagraph) {
          this.mergeParagraphs(firstParagraph, secondParagraph);
          mergedParagraphs.push(secondParagraph);
        }
      }
    }

    // Recursively traverse through all the child nodes of the current node
    const childNodes = [...parentNode.childNodes];
    for (let i = 0; i < childNodes.length; i++) {
      let childNode = childNodes[i];
      const isAutoPageBreakNode = this.isAutoPageBreak(childNode);

      // Ignore auto page break elements as they will get reapplied/reset
      if (isAutoPageBreakNode) {
        continue;
      }
      const isManualPageBreakNode = this.isManualPageBreak(childNode);
      let chunkContainer = rootContainer.lastElementChild as HTMLElement;

      // Current container has been moved into the next chunk
      if (!chunkContainer.contains(currentContainer) && currentContainer.id) {
        currentContainer = chunkContainer.querySelector(
          '#' + currentContainer.id
        );
      }
      // If current container is not set or not part of the current chunk, reset it to current chunk
      if (!currentContainer || !chunkContainer.contains(currentContainer)) {
        currentContainer = chunkContainer;
      }

      if (
        childNode.hasChildNodes() &&
        BackgroundDomService.isHtmlElement(childNode) &&
        childNode.matches(recursiveSplitSelector) &&
        !isManualPageBreakNode
      ) {
        const innerContainer = this.createInnerContainer(
          rootContainer,
          currentContainer,
          childNode
        );
        // Split child node into chunks recursively
        this.splitNodeIntoChunks(
          childNode,
          maxChunkSize,
          columns,
          recursiveSplitSelector,
          innerContainer
        );

        // Remove inner container when empty (happens when all the content went into the next chunk)
        if (!innerContainer.hasChildNodes()) {
          innerContainer.remove();
        }
      } else {
        currentContainer.append(childNode);

        const nonPageBreakNodes = [...chunkContainer.childNodes].filter(
          (n) => !this.isAutoPageBreak(n) && !this.isManualPageBreak(n)
        );
        const isChunkContainerOverflowing = () =>
          chunkContainer.scrollWidth > maxChunkSize.width ||
          chunkContainer.scrollHeight > maxChunkSize.height;

        const canBreakPage =
          currentContainer.childElementCount > 1 ||
          (this.splitParagraphsBetweenPages &&
            currentContainer.childElementCount == 1 &&
            currentContainer.firstElementChild.nodeName ==
              this.paragraphNodeName);

        // Current chunk content overflows max size or manual page break, create a new chunk
        if (
          canBreakPage &&
          nonPageBreakNodes.length > 0 &&
          (isManualPageBreakNode || isChunkContainerOverflowing())
        ) {
          // Split paragraphs between pages
          if (
            this.splitParagraphsBetweenPages &&
            childNode.nodeName == this.paragraphNodeName &&
            isChunkContainerOverflowing()
          ) {
            const paragraph = childNode as HTMLParagraphElement;
            const paragraphLines = this.splitParagraphIntoLines(
              paragraph.outerHTML,
              columns,
              maxChunkSize
            );

            if (paragraphLines.length > 1) {
              const prevPageParagraph = this.createSplitParagraph(paragraph);
              const nextPageParagraph = this.createSplitParagraph(paragraph);

              chunkContainer.append(prevPageParagraph);
              childNode.remove();

              let isOverflowing = false;
              for (const line of paragraphLines) {
                if (!isOverflowing) {
                  prevPageParagraph.append(line);
                  if (isChunkContainerOverflowing()) {
                    isOverflowing = true;
                  }
                }
                if (isOverflowing) {
                  nextPageParagraph.append(line);
                }
              }

              if (prevPageParagraph.childElementCount > 0) {
                childNode = nextPageParagraph;
              } else {
                prevPageParagraph.remove();
              }
            }
          }

          const previousContainer = currentContainer;

          // Create a new chunk container inside the root container
          chunkContainer = this.createChunkContainer(
            rootContainer,
            maxChunkSize,
            columns
          );
          currentContainer = chunkContainer;

          if (!isManualPageBreakNode) {
            // Get all node parents up to the chunk container level
            const parents = this.getNodeParents(
              childNode,
              (n) =>
                BackgroundDomService.isHtmlElement(n) &&
                n.classList.contains(this.chunkContainerClass)
            );

            // Recreate the original dom structure of the child node in the new chunk
            for (const parent of parents) {
              // Create a new inner container with the same id as the original (split into two, preserving the id)
              const innerContainer = this.createInnerContainer(
                rootContainer,
                currentContainer,
                parent as HTMLElement
              );
              currentContainer = innerContainer;
            }

            // Move child node into the newly created chunk
            currentContainer.append(childNode);
          }

          // Remove margin & padding from previous container as this is the last
          // element on a previous page and these no longer apply
          previousContainer.style.paddingBottom = '0';
          previousContainer.style.marginBottom = '0';
        }
      }
    }

    return rootContainer;
  }

  /**
   * Splits paragraph into lines based on the specified max width
   * @param [paragraphHtml]  Paragraph html content represented as a string
   * @param [maxSize] Maximum size of the of paragraph after which it will be split
   * @param [columns] Number of content columns
   * @returns Array of paragraph lines
   */
  public static splitParagraphIntoLines(
    paragraphHtml: string,
    columns: number,
    maxSize: Size
  ): HTMLElement[] {
    const wordElementTag = 'w';
    const lineElementTag = 'line';
    const positionDiffThreshold = 3; // Difference in word positions to start a new line

    // Wrap each paragraph word in a <w></w> element
    const wrappedParagraphHtml = paragraphHtml.replace(
      ///(?<!(<\/?[^>]*|&[^;]*))(<br>|((?!\s|<|&nbsp;).)+)/g, // uncomment to wrap nbsp in <w></w> as well
      /(?<!(<\/?[^>]*|&[^;]*))(<br>|[^\s<]+)/g,
      `$1<${wordElementTag}>$2</${wordElementTag}>`
    );

    const tempContainer = this.createTempContainer(
      maxSize.width,
      maxSize.height
    );
    tempContainer.innerHTML = wrappedParagraphHtml;
    if (columns > 0) {
      tempContainer.style.columnCount = columns.toString();
      tempContainer.style.columnFill = 'auto';
    }
    BackgroundDomService.appendElement(tempContainer);

    const lines: HTMLElement[] = [];
    let currentLine: HTMLElement = null;
    const words = tempContainer.getElementsByTagName(wordElementTag);

    // Loop through each paragraph word
    let previousPos: DOMRect = null;
    let previousWord: Element = null;
    for (const word of words) {
      // Calculate the position difference between subsequent words
      const currentPos = word.getBoundingClientRect();
      const posDiff = previousPos
        ? previousPos.bottom - currentPos.bottom
        : null;

      // If current word position is significantly different from the last, start a new line
      if (posDiff == null || Math.abs(posDiff) > positionDiffThreshold) {
        currentLine = document.createElement(lineElementTag);
        currentLine.style.display = 'block';
        lines.push(currentLine);
      }
      previousPos = currentPos;

      // Clone the original word
      let clonedWord = word.cloneNode(true) as HTMLElement;

      // Recreate the parent element structure to reapply the styles
      const stopElement = tempContainer.firstElementChild;
      let currentParent = word.parentElement;
      while (currentParent && currentParent != stopElement) {
        const wordContainer = currentParent.cloneNode() as HTMLElement;
        wordContainer.appendChild(clonedWord);
        clonedWord = wordContainer;
        currentParent = currentParent.parentElement;
      }

      // Append any whitespace preceding the word to the current line
      if (currentLine.childElementCount > 0) {
        if (
          word.previousSibling &&
          word.previousSibling.nodeType == 3 // #text node
        ) {
          clonedWord.prepend(word.previousSibling.textContent);
        } else if (
          previousWord &&
          previousWord.nextSibling &&
          previousWord.nextSibling.nodeType == 3 // #text node
        ) {
          clonedWord.prepend(previousWord.nextSibling.textContent);
        }
      }
      // Append word to the current line
      currentLine.append(clonedWord);
      previousWord = word;
    }

    // Remove unneeded temp container from dom
    tempContainer.remove();

    return lines;
  }

  /**
   * Merges two paragraphs into a single paragraph
   * @returns Single paragraph
   */
  public static mergeParagraphs(
    firstParagraph: Element,
    secondParagraph: Element
  ): Element {
    this.trimParagraphNbsp(firstParagraph);
    this.trimParagraphNbsp(secondParagraph);

    firstParagraph.innerHTML =
      firstParagraph.innerHTML + ' ' + secondParagraph.innerHTML;
    firstParagraph.removeAttribute(this.paragraphSplitIdAttribute);
    secondParagraph.remove();
    return firstParagraph;
  }

  /**
   * Merges page chunks back into single raw (non-paged/processed) content element
   * @param [items] Page chunks (output from splitContentIntoPages)
   * @returns Content element
   */
  public static margePagesIntoRawContent(
    items: ContentPaginationItem[]
  ): HTMLElement {
    const tempContainer = document.createElement('div');
    for (const item of items) {
      for (const node of item.element.childNodes) {
        const clonedNode = node.cloneNode(true);
        this.mergeNodes(tempContainer, clonedNode);
      }
    }
    tempContainer
      .querySelectorAll(`[id^='${this.innerContainerId}']`)
      .forEach((element) => {
        element.removeAttribute('id');
      });
    return tempContainer;
  }

  private static mergeNodes(parentNode: Node, targetNode: Node) {
    if (
      !BackgroundDomService.isHtmlElement(parentNode) ||
      !BackgroundDomService.isHtmlElement(targetNode) ||
      !targetNode.id ||
      !targetNode.id.startsWith(this.innerContainerId)
    ) {
      parentNode.appendChild(targetNode);
      return;
    }

    const sourceNode = parentNode.querySelector('#' + targetNode.id);
    if (sourceNode) {
      for (const childNode of targetNode.childNodes) {
        this.mergeNodes(sourceNode, childNode);
      }
    } else {
      parentNode.appendChild(targetNode);
    }
  }

  private static isAutoPageBreak(node: Node) {
    return (
      BackgroundDomService.isHtmlElement(node) &&
      node.classList.contains(this.autoPageBreakClass)
    );
  }

  private static isManualPageBreak(node: Node) {
    return (
      BackgroundDomService.isHtmlElement(node) &&
      node.classList.contains(this.manualPageBreakClass)
    );
  }

  private static createTempPageContainer(
    html: string,
    columns: number,
    pageType: DocumentPageType
  ): HTMLElement {
    const pageBodySize = ExportUtils.calculatePageBodySize(pageType);
    const pageBodySizePixels = new Size(
      pageBodySize.width * ExportConfig.upscaleMultiplier,
      pageBodySize.height * ExportConfig.upscaleMultiplier
    );

    const tempContainer = this.createTempContainer(
      Math.ceil(pageBodySizePixels.width),
      Math.ceil(pageBodySizePixels.height - ExportConfig.pageBreakThreshold)
    );
    tempContainer.innerHTML = html ?? '';
    if (columns > 0) {
      tempContainer.style.columnCount = columns.toString();
      tempContainer.style.columnFill = 'auto';
    }

    return tempContainer;
  }

  private static createTempContainer(
    width?: number,
    height?: number
  ): HTMLElement {
    const tempContainer = BackgroundDomService.createElement('div');
    tempContainer.className = ExportConfig.pageContentClass;
    tempContainer.style.width = width ? width + 'px' : null;
    tempContainer.style.height = height ? height + 'px' : null;
    tempContainer.style.position = 'absolute';
    return tempContainer;
  }

  private static createRootContainer(parentNode: HTMLElement): HTMLElement {
    const rootContainer = BackgroundDomService.createElement('div');
    copyElementAttributes(parentNode, rootContainer);
    rootContainer.classList.add(this.rootContainerClass);
    rootContainer.style.height = '';
    BackgroundDomService.appendElement(rootContainer);
    return rootContainer;
  }

  private static createChunkContainer(
    rootContainer: HTMLElement,
    maxSize: Size,
    columns: number
  ): HTMLElement {
    const chunkContainer = BackgroundDomService.createElement('div');
    chunkContainer.className = this.chunkContainerClass;
    chunkContainer.style.width = maxSize.width + 'px';
    chunkContainer.style.height = maxSize.height + 'px';
    if (columns > 0) {
      chunkContainer.style.columnCount = columns.toString();
      chunkContainer.style.columnFill = 'auto';
    }
    rootContainer.append(chunkContainer);
    return chunkContainer;
  }

  private static createInnerContainer(
    rootContainer: HTMLElement,
    parentContainer: HTMLElement,
    parentNode: HTMLElement
  ): HTMLElement {
    // Create a new container and copy all the original attributes
    const innerContainer = document.createElement(parentNode.nodeName);
    copyElementAttributes(parentNode, innerContainer);
    let id = parentNode.id;
    if (!id) {
      // Set id to the next available one
      const nextId =
        rootContainer.querySelectorAll(`[id^="${this.innerContainerId}"]`)
          .length + 1;
      id = `${this.innerContainerId}-${nextId}`;
    }
    innerContainer.id = id;
    parentContainer.append(innerContainer);
    return innerContainer;
  }

  private static createManualPageBreak(
    type: 'inside' | 'outside'
  ): HTMLElement {
    const pageBreak = document.createElement('div');
    pageBreak.className = this.manualPageBreakClass;
    pageBreak.setAttribute(this.manualPageBreakTypeAttribute, type);
    return pageBreak;
  }

  private static createSplitParagraph(
    sourceParagraph: HTMLElement
  ): HTMLElement {
    const paragraph = document.createElement(this.paragraphNodeName);
    copyElementAttributes(sourceParagraph, paragraph);
    return paragraph;
  }

  /**
   * Get all node parents up to the stop condition
   * @param node
   * @param [stopCondition] When to stop traversing up the element tree
   * @returns
   */
  private static getNodeParents(
    node: Node,
    stopCondition: (n: Node) => boolean
  ): Node[] {
    const parents: Node[] = [];
    while (node.parentNode && !stopCondition(node.parentNode)) {
      node = node.parentNode;
      parents.splice(0, 0, node);
    }
    return parents;
  }

  /**
   * Removes first and last occurence of &nbsp; from paragraph html
   */
  private static trimParagraphNbsp(paragraph: Element) {
    const nbspChar = String.fromCharCode(160);

    // Remove first occurence of &nbsp; if text content starts with it
    if (paragraph.textContent[0] == nbspChar) {
      paragraph.innerHTML = paragraph.innerHTML.replace(/&nbsp;/, '');
    }
    // Remove last occurence of &nbsp; if text content ends with it
    if (paragraph.textContent[paragraph.textContent.length - 1] == nbspChar) {
      paragraph.innerHTML = paragraph.innerHTML.replace(
        /&nbsp;(?!.*&nbsp;)/,
        ''
      );
    }
  }
}
