import ISearchParameter from './ISearchParameter';

export default class OwnershipParameter implements ISearchParameter {
  public nodeUuid: string;

  constructor(nodeUuid: string) {
    this.nodeUuid = nodeUuid;
  }

  public equals(other: ISearchParameter): boolean {
    return (
      other instanceof OwnershipParameter && other.nodeUuid == this.nodeUuid
    );
  }
}
