import { clamp } from 'lodash';
import {
  BaseClass,
  IVisualCreator,
  IHitTestable,
  ShapeNodeStyle,
  SimpleNode,
  Size,
  ShapeNodeShape,
  IGraph,
  SolidColorFill,
  SvgVisualGroup,
  SvgVisual,
  INode,
  Point,
  Rect,
  Stroke,
  Color,
  GraphComponent,
  ShapeNodeStyleRenderer,
  IRenderContext,
  NodeStyleBase,
  ICanvasContext,
  IInputModeContext,
  GeneralPath,
  Insets,
} from 'yfiles';
import diagramConfig from '@/core/config/diagram.definition.config';
export default class GroupNodeStyle extends NodeStyleBase {
  public static INSTANCE: GroupNodeStyle = new GroupNodeStyle();
  private dummyStyle: ShapeNodeStyle;
  private dummyNode: SimpleNode;
  private gridSpaces = 8;
  private size: Size = new Size(
    diagramConfig.grid.size * this.gridSpaces,
    diagramConfig.grid.size * this.gridSpaces
  );
  private opacity: number = 0.3;
  private roundRectArcRadius = 0;
  constructor() {
    super();

    let renderer = new ShapeNodeStyleRenderer();

    renderer.roundRectArcRadius = this.roundRectArcRadius;
    this.dummyStyle = new ShapeNodeStyle({
      shape: ShapeNodeShape.ROUND_RECTANGLE,
      stroke: null,
      renderer: renderer,
    });

    this.dummyNode = new SimpleNode({
      style: this.dummyStyle,
    });
  }

  get offset(): Insets {
    return new Insets(
      this.size.width / 2,
      this.size.height / 2,
      this.size.width / 2,
      this.size.height / 2
    );
  }

  isHit(renderContext, location: Point, node: INode): boolean {
    return false;
  }

  createVisual(renderContext: IRenderContext, node: INode) {
    let graphComponent = renderContext.canvasComponent as GraphComponent;
    const graph = graphComponent.graph as IGraph;

    // new visual which will contain all visuals for this parent's children
    const group = new SvgVisualGroup();

    // get all the children for this parent
    const children = graph.nodes.filter(
      (n) => !n.tag?.isGroupNode && n.tag?.groupUuid == node.tag?.groupUuid
    );

    // iterate over the children to create their visual background
    children.forEach((childNode) => {
      // using a dummy node we get modify it's style/layout to be in the correct position
      this.dummyNode.layout = this.getLayout(childNode);
      this.dummyStyle.fill = new SolidColorFill(node.tag.groupColor);

      // offload rendering to yFiles
      const childVisual = this.dummyStyle.renderer
        .getVisualCreator(this.dummyNode, this.dummyStyle)
        .createVisual(renderContext) as SvgVisual;
      group.add(childVisual);
    });

    // apply opacity to the parent visual group
    // if all nodes in the group are selected apply a higher opacity, giving it a "selected" look
    const allSelected = children.every((n) =>
      graphComponent.selection.isSelected(n)
    );
    group.svgElement.style.opacity = `${clamp(
      allSelected ? this.opacity + 0.3 : this.opacity,
      0.1,
      1
    )}`;

    return group;
  }

  updateVisual(
    renderContext: IRenderContext,
    oldVisual: SvgVisualGroup,
    node: INode
  ) {
    return this.createVisual(renderContext, node);
  }
  getLayout(node: INode): Rect {
    let newSize = node.layout.toSize().toMutableSize();
    newSize.width += this.size.width;
    newSize.height += this.size.height;
    let offset = new Point(
      (newSize.width - node.layout.width) / 2,
      (newSize.height - node.layout.height) / 2
    );

    const newLocation = node.layout.toPoint().subtract(offset);
    return new Rect(newLocation, newSize.toSize());
  }

  isVisible(context: IRenderContext, rect: Rect, node: INode): boolean {
    let graphComponent = context.canvasComponent as GraphComponent;
    const graph = graphComponent.graph as IGraph;

    // get all the children for this parent
    const children = graph.getChildren(node).toArray();
    if (children.length === 0) {
      return false;
    }

    for (let i = 0; i < children.length; i++) {
      const element = children[i];
      if (rect.intersects(this.getLayout(element).toRect())) {
        return true;
      }
    }
    return false;
  }

  getBounds(context: ICanvasContext, node: INode): Rect {
    return Rect.EMPTY;
  }
}
