import { ApplicationPermissions } from '@/api/ApplicationPermissions';
import { CurrentUserProfileEditDto } from '@/api/models';
import ProfileApiService from '@/api/ProfileApiService';
import userConfig from '@/core/config/userConfig';
import { Module } from 'vuex';

// action types
export const GET_PROFILE = 'getProfile';
export const CHANGE_USER_LANGUAGE = 'changeUserLanguage';
// mutation types
export const SET_PROFILE = 'setProfile';
export const SET_USER_LANGUAGE = 'setUserLanguage';
// getters
export const GET_CURRENT_USER = 'currentUser';
export const GET_IS_DOCUMENTMANAGER = 'isDocumentManager';
export const GET_USER_PERMISSIONS = 'userPermissions';
export const GET_HAS_USER_SELECTED_LANGUAGE = 'hasUserSelectedLanguage';

interface State {
  user: CurrentUserProfileEditDto;
}

const getInitialState = (): State => {
  return {
    user: null,
  };
};

const userModule: Module<State, any> = {
  namespaced: false,
  state: getInitialState(),
  getters: {
    [GET_CURRENT_USER](state) {
      return state.user;
    },
    [GET_USER_PERMISSIONS](state) {
      return state.user.permissions;
    },
    [GET_IS_DOCUMENTMANAGER](state) {
      let isDocumentManager: boolean = false;
      if (state.user.permissions) {
        isDocumentManager = state.user.permissions.includes(
          ApplicationPermissions.DocumentsManager.key
        );
      }
      return isDocumentManager;
    },
    [GET_HAS_USER_SELECTED_LANGUAGE](state) {
      return !!state.user?.language;
    },
  },
  actions: {
    [GET_PROFILE]({ commit, getters, dispatch }) {
      if (getters.currentUser) {
        return Promise.resolve();
      }
      return ProfileApiService.getCurrentUserProfileForEdit()
        .then(({ data }) => {
          commit(SET_PROFILE, data?.result);
          Object.assign(userConfig, data?.result);
        })
        .catch(() => {
          commit(SET_PROFILE, null);
        });
    },
    [SET_PROFILE](context, profile) {
      context.commit(SET_PROFILE, profile);
    },
    [CHANGE_USER_LANGUAGE]({ state, commit }, payload: string) {
      return ProfileApiService.changeLanguage({
        language: payload,
      })
        .then(() => {
          commit(SET_USER_LANGUAGE, payload);
        })
        .catch(() => {
          commit(SET_USER_LANGUAGE, null);
        });
    },
  },

  mutations: {
    [SET_PROFILE](state, profile) {
      state.user = profile;
    },
    [SET_USER_LANGUAGE](state, language) {
      state.user.language = language;
    },
  },
};

export default userModule;
