



















































































import { OVERRIDE_LAYOUT_CONFIG } from '@/core/services/store/config.module';
import { mapGetters, mapState } from 'vuex';
import Vue from 'vue';
import vSelect from 'vue-select';
import { EventBusActions } from './core/services/events/eventbus.service';
import ModalDialogAdapter from '@/components/Modal/ModalDialogAdapter.vue';
import ModalIdle from '@/components/ModalIdle/ModalIdle.vue';
import AuthService from './core/services/auth/AuthService';
import CssVariablesService from './core/services/CssVariablesService';
import Debug from '@/components/DebugComponent/Debug.vue';
import { DEBUG_NAMESPACE } from '@/core/services/store/debug.module';
Vue.component('v-select', vSelect);

export default {
  name: 'MetronicVue',
  components: {
    ModalDialogAdapter,
    ModalIdle,
    Debug,
  },
  beforeMount() {
    this.eventBusAction(EventBusActions.APPLICATION_IDLE_ONIDLE, (payload) => {
      // Show IdleModal if enabled and logged in
      const sessionTimeout =
        this.$store.getters?.currentSettings.sessionTimeout;
      if (sessionTimeout) {
        if (
          AuthService.isLoggedIn &&
          sessionTimeout.showLockScreenWhenTimedOut
        ) {
          if (document.hidden) {
            this.$router.push({
              name: 'logout',
            });
          } else this.$bvModal.show('modal-idle');
        }
        // Logout user if IdleModal not enabled
        if (sessionTimeout.showLockScreenWhenTimedOut === false) {
          this.$router.push({
            name: 'logout',
          });
        }
      }
    });
    this.eventBusAction(EventBusActions.APPLICATION_ERROR, (payload) => {
      this.applicationError = payload;
      this.$bvModal.show('ApplicationErrorModal');
    });
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);

    // Set background image
    const bg = this.layoutConfig('self.body.background-image');
    document.body.style.backgroundImage = `url('${bg}')`;

    CssVariablesService.applyCssVariables();
  },
  computed: {
    ...mapGetters(['layoutConfig', 'isAuthenticated']),
    ...mapState('debug', {
      debugEnabled: (state: any) => state.enabled,
    }),
  },
  methods: {
    reload: function () {
      this.reloading = true;
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
  },
  data: function () {
    return {
      reloading: false,
      applicationError: {
        refresh: false,
        message: '',
        title: '',
        navigate: null,
      },
    };
  },
};
