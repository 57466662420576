



















import { Vue, Component, Prop, Model } from 'vue-property-decorator';

@Component({
  name: 'AppRadio',
})
export default class AppCheckbox extends Vue {
  @Model('checked', { type: [String, Number] })
  readonly modelValue!: string | number;

  @Prop({ required: true, type: String })
  name: string;

  @Prop({ default: null, type: [String, Number] })
  value: string | number;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: null })
  id: string | number;

  private _uid: string;
  inputId: string | number;

  get isChecked() {
    return this.modelValue === this.value;
  }

  get inputClasses() {
    return [
      this.isChecked ? 'icon-radio-checked' : 'icon-radio-unchecked',
      !this.disabled
        ? 'j-text-accent-purple hover:j-text-accent-purple-90 focus:j-text-accent-purple-80'
        : 'j-text-main-dark-20 j-cursor-not-allowed',
    ];
  }

  get labelClasses() {
    return !this.disabled
      ? 'j-text-main-dark'
      : 'j-text-main-dark-20 j-cursor-not-allowed j-select-none';
  }

  created() {
    this.inputId = this.id || this._uid;
  }
}
