





















import CkEditorInstanceMixin from '@/core/services/editor/CkEditorInstanceMixin';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import '@/assets/sass/editor/_ckeditor.scss';
import '@/assets/sass/editor/_page-content.scss';
import CommandManager from '@/core/services/editor/CommandManager';
import { measureElement } from '@/core/utils/html.utils';
import IItem from '../IItem';
import _ from 'lodash';

@Component({
  name: 'TextElement',
  components: {
    ckeditor: CKEditor.component,
  },
})
export default class TextElement extends mixins(CkEditorInstanceMixin) {
  @Prop()
  item: any;
  @Prop()
  content: any;

  public editable: boolean = false;

  private textElementContentChanged = _.debounce(() => {
    this.onValueChanged();
  }, 300);

  public mounted() {
    this.autoUnfocus = false;
    if (!this.item.meta) {
      throw 'meta not set';
    }
  }

  public setData(data: string) {
    this.editor.setData(data);
  }

  public getData(): string {
    return this.editor.getData();
  }

  public focus(): void {
    this.editable = true;
    const self = this;
    setTimeout(() => {
      this.editor.isReadOnly = false;
      self.editor.focus();
      self.editor.execute('selectAll');
    }, 0);
  }

  public blur() {
    this.editable = false;
    this.$nextTick(() => {
      CommandManager.INSTANCE.unfocus();
    });
    (this.$refs['text'] as HTMLElement).blur();
    window.getSelection().empty();
    this.editor.isReadOnly = true;
  }

  public ensureValues() {
    this.item.meta.element = this;

    this.setData(this.item.meta.html);
  }

  @Watch('item')
  public onItemChange(newValue, oldValue) {
    this.ensureValues();
  }

  public editorReady(editor) {
    this.onEditorReady(editor);
    this.editor.model.document.on('change:data', () => {
      this.updateItemHeight();
      this.textElementContentChanged();
    });
    this.ensureValues();
  }

  public updateItemHeight() {
    const item = this.item as IItem;
    const wrapper = document.createElement('div');
    // constrain the width, so the height is calculated
    wrapper.style.width = `${item.layout.width}px`;
    wrapper.style.wordBreak = 'break-all';
    // apply correct class so styles match
    wrapper.classList.add('document-page-content');
    // grab current html
    wrapper.innerHTML = this.editor.getData();
    const size = measureElement(wrapper);
    // update the height
    (this.item as IItem).layout.height = size.height <= 40 ? 40 : size.height;
  }

  private onValueChanged() {
    if (!this.editorIsReady) {
      throw 'Editor not ready';
    }
    this.item.meta.html = this.getData();
    this.$emit('change', this.item);
  }
}
