import {
  ILabel,
  ILabelModelParameter,
  INode,
  BaseClass,
  Point,
  ILabelModel,
  IEdge,
} from 'yfiles';
import JigsawEdgeLabelModel from './JigsawEdgeLabelModel';

export default class JigsawEdgeLabelModelParameter extends BaseClass<ILabelModelParameter>(
  ILabelModelParameter
) {
  /**
   *
   * @param _model
   * @param options
   */

  public ratio: number = null;
  public segmentIndex: number = null;
  public distance: number = null;
  public left: boolean = false;

  constructor(
    private readonly _model: JigsawEdgeLabelModel,
    readonly options: {
      readonly ratio: number;
      readonly segmentIndex: number;
      readonly distance: number;
      readonly left: boolean;
    }
  ) {
    super();

    this.ratio = options.ratio;
    this.segmentIndex = options.segmentIndex;
    this.distance = options.distance;
    this.left = options.left;
  }

  public clone(): this {
    return this;
  }

  get model(): ILabelModel {
    return this._model;
  }

  public supports(label: ILabel): boolean {
    return label.owner instanceof IEdge;
  }
}
