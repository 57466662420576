import ExportConfig from '@/core/config/ExportConfig';
import {
  BridgeManager,
  GraphComponent,
  GraphMLSupport,
  GraphObstacleProvider,
  Size,
  SvgExport,
} from 'yfiles';
import b64toBlob from '../../graph/b64ToBlob';
import GraphExportHelper from '../GraphExportHelper';
import ExportOptions from '../ExportOptions';
import IExportProvider from './IExportProvider';
import { Base64Utils } from '@/core/utils/Base64Utils';
import ExportPageElement from '../ExportPageElement';
import IExportResult from './IExportResult';
import { PageElementPosition } from '@/api/models';
import IAdditionalElementProvider from '../additional-element-providers/IAdditionalElementProvider';
import LegendAsImgProvider from '../additional-element-providers/LegendAsImgProvider';
import LogoAsImageProvider from '../additional-element-providers/LogoAsImgProvider';
import { ExportArea } from '../ExportArea';
import { ExportFormat } from '../ExportFormat';

export default class SvgExportProvider implements IExportProvider {
  private _fileExtension = 'svg';
  private _mimeType = 'image/svg+xml';

  async exportGraphAsString(
    options: ExportOptions,
    graphComponent: GraphComponent
  ): Promise<IExportResult> {
    // Return empty SVG when graph is empty
    if (graphComponent.contentRect.isEmpty) {
      return {
        fileExtension: this._fileExtension,
        mimeType: this._mimeType,
        size: Size.EMPTY,
        result: '<svg></svg>',
      };
    }
    await this.appendAdditionalElements(options);

    const appendAdditionalElements =
      (options.format == ExportFormat.Svg ||
        options.format == ExportFormat.Png) &&
      options.area != ExportArea.PageThumbnail;
    let minExportSize = appendAdditionalElements
      ? ExportConfig.minDiagramSize
      : Size.EMPTY;

    if (options.metadata.currentPage?.additionalElements?.length > 0) {
      minExportSize = Size.EMPTY;
    }
    //minExportSize = Size.EMPTY;
    const exporter = new SvgExport(graphComponent.contentRect, 1);
    exporter.encodeImagesBase64 = true;
    exporter.inlineSvgImages = true;

    const svgElement = <SVGElement>(
      await exporter.exportSvgAsync(graphComponent)
    );

    const exportSize = await GraphExportHelper.finalizeSvgElement(
      svgElement,
      appendAdditionalElements
        ? options.metadata.currentPage.additionalElements
        : [],
      graphComponent.contentRect,
      options.metadata?.currentPage?.page?.pageType,
      minExportSize
    );

    const exportString = SvgExport.exportSvgString(svgElement);
    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      size: exportSize,
      result: exportString,
    };
  }

  async exportGraphAsBase64(
    options: ExportOptions,
    graphComponent: GraphComponent,
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    const exportResult = await this.exportGraphAsString(
      options,
      graphComponent
    );
    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      size: exportResult.size,
      result: Base64Utils.encode(exportResult.result),
    };
  }

  async exportGraphAsBlob(
    options: ExportOptions,
    graphComponent: GraphComponent,
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    const base64 = await this.exportGraphAsBase64(
      options,
      graphComponent,
      graphMLSupport
    );
    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      size: base64.size,
      result: b64toBlob(base64.result, this._mimeType),
    };
  }

  private async appendAdditionalElements(
    options: ExportOptions
  ): Promise<void> {
    if (options.format != ExportFormat.Svg) {
      return Promise.resolve();
    }
    for (const exportPage of options.pages) {
      if (!exportPage.additionalElements) {
        exportPage.additionalElements = [];
      }
      // Don't append Logo and Legend to thumbnails
      if (options.area == ExportArea.PageThumbnail) {
        continue;
      }

      // Logo
      if (
        options.document.logoPosition != PageElementPosition.Hidden &&
        exportPage.page.showLogo
      ) {
        const logoProvider = new LogoAsImageProvider();
        const additionalElement = await logoProvider.get(options, exportPage);
        if (additionalElement) {
          exportPage.additionalElements.push(...additionalElement);
        }
      }

      // Diagram legend
      if (
        options.document.legendPosition != PageElementPosition.Hidden &&
        exportPage.page.showLegend
      ) {
        const legendProvider: IAdditionalElementProvider =
          new LegendAsImgProvider();

        const additionalElement = await legendProvider.get(options, exportPage);
        if (additionalElement) {
          exportPage.additionalElements.push(...additionalElement);
        }
      }
    }
  }
}
