export default {
  'marker-green': {
    background: '#059c19',
  },
  'marker-yellow': {
    background: '#cac407',
  },
  'marker-red': {
    background: '#ec3c6e',
  },
};
