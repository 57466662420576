import config from '@/core/config/diagram.definition.config';
import { GraphComponent } from 'yfiles';

export default class PaletteItemOffsetHelper {
  private static _offsetXIncrement = 5;
  private static _paletteItemDropOffsetX = 0;
  private static _paletteItemDropOffsetY = 0;
  private static readonly _paletteItemDropOffsetStep =
    config.paletteItemDropOffsetStep;

  public static getOffsetX() {
    return this._paletteItemDropOffsetX * config.grid.size;
  }

  public static getOffsetY() {
    return this._paletteItemDropOffsetY * config.grid.size;
  }

  public static updatePalettItemDropOffset(
    graphComponent: GraphComponent,
    additionalOffsetIncrement = 0
  ) {
    let shoudReturn = false;
    if (this.offsetYReachedBounds(graphComponent)) {
      this._paletteItemDropOffsetX = this._offsetXIncrement;
      this._paletteItemDropOffsetY = 0;
      this._offsetXIncrement += 5;
      shoudReturn = true;
    }
    if (this.offsetXReachedBounds(graphComponent)) {
      this._offsetXIncrement = 0;
      this._paletteItemDropOffsetX = this._offsetXIncrement;
      this._paletteItemDropOffsetY = 0;
      this._offsetXIncrement += 5;
      shoudReturn = true;
    }

    if (shoudReturn) {
      return;
    }

    this._paletteItemDropOffsetY +=
      this._paletteItemDropOffsetStep + additionalOffsetIncrement;
  }

  private static offsetYReachedBounds(graphComponent: GraphComponent): boolean {
    return (
      graphComponent.viewport.centerY -
        graphComponent.viewport.height / 4 +
        PaletteItemOffsetHelper.getOffsetY() >=
      graphComponent.viewport.maxY - graphComponent.viewport.height / 4
    );
  }

  private static offsetXReachedBounds(graphComponent: GraphComponent): boolean {
    return (
      PaletteItemOffsetHelper.getOffsetX() +
        graphComponent.viewport.minX +
        graphComponent.viewport.width /
          config.offsetRightFromCanvasLeftBound.large >=
      graphComponent.viewport.centerX - 150
    );
  }
}
