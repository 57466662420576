import {
  CompositeNodeStyleDto,
  CompositeShape,
  CreateOrEditDocumentDto,
  CreateOrEditThemeDto,
  DashStyleType,
  DiagramDto,
  DocumentAttachmentType,
  DocumentDto,
  EdgeStyleDto,
  EdgeVisualType,
  ElementType,
  FontDto,
  FontStyleDto,
  ImageNodeStyleDto,
  INodeStyleDto,
  LabelStyleDto,
  NodeShape,
  NodeSize,
  NodeVisualType,
  ShapeNodeStyleDto,
  ThemeDto,
  ThemeElementDto,
} from '@/api/models';

import config from '@/core/config/diagram.definition.config';
import {
  CanvasComponent,
  EdgeSegmentLabelModel,
  EdgeSides,
  GraphComponent,
  GraphEditorInputMode,
  IEdge,
  IEdgeStyle,
  IEnumerable,
  IGraph,
  ILabel,
  ILabelModelParameter,
  ILabelOwner,
  ILabelStyle,
  ImageNodeStyle,
  IModelItem,
  INode,
  INodeStyle,
  InteriorLabelModelPosition,
  IOrientedRectangle,
  IPort,
  IRectangle,
  List,
  Point,
  PortCandidate,
  PortDirections,
  PortSide,
  Reachability,
  Rect,
  ShapeNodeShape,
  ShapeNodeStyle,
  SimpleNode,
  Size,
} from 'yfiles';

import ILabelTag from '../common/ILabelTag';
import StyleCreator from './StyleCreator';
import IEdgeTag from '../common/IEdgeTag';

import {
  generateUuid,
  getAngle,
  HexToRgb,
  randomRgb,
  roundToClosest,
} from './common.utils';
import INodeTag from '../common/INodeTag';

import { RotatableNodeStyleDecorator } from '@/core/services/graph/RotatableNodes.js';
import DiagramWriter from '@/core/services/graph/serialization/diagram-writer.service';
import { EventBus, EventBusActions } from '../services/events/eventbus.service';
import { AnnotationType } from '@/core/common/AnnotationType';
import { ElementTypeColor } from '../common/ElementTypeColor';
import { DefaultColors } from '../common/DefaultColors';
import JigsawNodeStyle from '../styles/JigsawNodeStyle';
import CompositeNodeStyleDefinitionsService from '../styles/composite/CompositeNodeStyleDefinitionsService';
import { JigsawImageNodeStyleRenderer } from '@/core/styles/JigsawImageNodeStyleRenderer';
import IndicatorDecorators from '../styles/decorators/IndicatorDecorators';
import CompositeNodeStyle from '../styles/composite/CompositeNodeStyle';
import EdgeCreationButtonDecorator from '../styles/decorators/EdgeCreationButtonDecorator';
import GroupNodeStyle from '../styles/GroupNodeStyle';
import FilterDecorators from '../styles/decorators/FilterDecorators';
import store from '../services/store';
import {
  DOCUMENT_NAMESPACE,
  GET_LEGEND_DEFINITION,
  GET_PERSISTED_DATA_PROPERTY_DISPLAY_TYPES,
  GET_THEME_ELEMENT_BY_NAME,
} from '../services/store/document.module';
import GraphElementsComparer from './GraphElementsComparer';
import {
  DataPropertyDisplayType,
  DataPropertyDisplayTypeNames,
} from '@/core/common/DataPropertyDisplayType';
import ILegendDefinition from '@/components/DiagramLegend/ILegendDefinition';
import appConfig from '../config/appConfig';
import { JigsawShapeNodeStyleRenderer } from '../styles/JigsawShapeNodeStyleRenderer';
import ExportService from '../services/export/ExportService';
import JurisdictionDecorator from '../styles/decorators/JurisdictionDecorator';
import JigsawExteriorNodeLabelModel from '../services/graph/label-models/JigsawExteriorNodeLabelModel';
import JigsawExteriorNodeLabelModelParameter from '../services/graph/label-models/JigsawExteriorNodeLabelModelParameter';
import JigsawInteriorNodeLabelModel from '../services/graph/label-models/JigsawInteriorNodeLabelModel';
import JigsawInteriorNodeLabelModelParameter from '../services/graph/label-models/JigsawInteriorNodeLabelModelParameter';
import { LabelModelType } from '../services/graph/label-models/LabelModelType';
import CKEditorUtils from './CKEditorUtils';
import i18n from '../plugins/vue-i18n';
import GraphElementsHashGenerator from './GraphElementsHashGenerator';
import { cloneDeep } from 'lodash';
import { stripHtml } from './html.utils';
import diagramConfig from '@/core/config/diagram.definition.config';
import CkTextEditorInputMode from '../services/graph/CkTextEditorInputMode';
import JigsawEdgeLabelModel from '../services/graph/label-models/JigsawEdgeLabelModel';
import SystemEntityTypes from '../services/corporate/SystemEntityTypes';

export const ZERO_WIDTH_SPACE = '\u200B';
export default class DiagramUtils {
  public static getSystemDefaultNodeStyle(): INodeStyleDto {
    return new ShapeNodeStyleDto(
      {
        color: DefaultColors.BLUE,
      },
      
      {
        dashStyle: {
          type: DashStyleType.Solid,
        },
        fill: {
          color: DefaultColors.ORANGE,
        },
        thickness: 1,
      },
      NodeShape.Circle,
      NodeSize.ExtraSmall,
      NodeVisualType.Shape,
      DiagramUtils.getSystemDefaultLabelStyle()
    );
  }

  public static getSystemDefaultCompositeNodeStyle(): CompositeNodeStyleDto {
    const defaultCompositeShape = CompositeShape.RectangleWithTriangle;
    return new CompositeNodeStyleDto(
      defaultCompositeShape,
      CompositeNodeStyleDefinitionsService.getStyleDefinitions(
        defaultCompositeShape
      ),
      NodeVisualType.Composite,
      NodeSize.Small,
      DiagramUtils.getSystemDefaultLabelStyle()
    );
  }

  public static getSystemDefaultImageNodeStyle(): ImageNodeStyleDto {
    return new ImageNodeStyleDto(
      NodeVisualType.Image,
      null,
      null,
      null,
      null,
      null,
      DiagramUtils.getSystemDefaultLabelStyle()
    );
  }

  public static getSystemDefaultLabelStyle(): LabelStyleDto {
    return {
      fill: {
        color: DefaultColors.BLACK,
      },
      font: {
        fontSize: config.defaultFontSize,
        fontFamily: config.defaultFontFamily,
        fontStyle: config.supportedFontStyles[0],
        fontWeight: config.supportedFontWeights[0],
        textDecoration: config.supportedFontTextDecoration[0],
      },
    };
  }

  public static getSystemDefaultEdgeStyle(): EdgeStyleDto {
    return {
      visualType: EdgeVisualType.Straight,
      stroke: {
        dashStyle: {
          type: DashStyleType.Solid,
        },
        fill: {
          color: DefaultColors.ORANGE,
        },
        thickness: 1,
      },
      sourceArrow: {
        scale: 1,
        type: 'none',
        fill: {
          color: DefaultColors.ORANGE,
        },
      },
      targetArrow: {
        scale: 1,
        type: 'triangle',
        fill: {
          color: DefaultColors.ORANGE,
        },
      },
      bridge: true,
      labelStyle: DiagramUtils.getSystemDefaultLabelStyle(),
    };
  }

  public static getSystemDefaultStyle(elementType: ElementType): any {
    switch (+elementType) {
      case ElementType.Node:
        return DiagramUtils.getSystemDefaultNodeStyle();
      case ElementType.Edge:
        return DiagramUtils.getSystemDefaultEdgeStyle();
    }
    throw `Unknown element type ${elementType}`;
  }

  public static getSystemDefaultEdgeToNoWhereNodeStyle(): INodeStyleDto {
    return new ShapeNodeStyleDto(
      {
        color: DefaultColors.TRANSPARENT,
      },
      {
        dashStyle: {
          type: DashStyleType.Solid,
        },
        fill: {
          color: DefaultColors.TRANSPARENT,
        },
        thickness: 0,
      },
      NodeShape.Circle,
      NodeSize.Small,
      NodeVisualType.Shape,
      DiagramUtils.getSystemDefaultLabelStyle()
    );
  }

  public static getSystemDefaultEdgeToNoWhereHoverNodeStyle(): INodeStyleDto {
    return new ShapeNodeStyleDto(
      {
        color: DefaultColors.TRANSPARENT,
      },
      {
        dashStyle: {
          type: DashStyleType.Solid,
        },
        fill: {
          color: DefaultColors.TRANSPARENT,
        },
        thickness: 0,
      },
      NodeShape.RoundedRectangle,
      NodeSize.Small,
      NodeVisualType.Shape,
      DiagramUtils.getSystemDefaultLabelStyle()
    );
  }

  public static getPlaceholderEdgeStyle(): EdgeStyleDto {
    return {
      bridge: false,
      visualType: EdgeVisualType.Straight,
      stroke: {
        dashStyle: {
          type: DashStyleType.Solid,
        },
        fill: {
          color: DefaultColors.GREY,
        },
        thickness: 1,
      },
      sourceArrow: {
        scale: 1,
        type: 'none',
      },
      targetArrow: {
        scale: 1,
        type: 'triangle',
      },
      labelStyle: DiagramUtils.getSystemDefaultLabelStyle(),
    };
  }

  public static getGraphComponentNodes(
    graph: IGraph,
    element: IEdge | INode
  ): IEnumerable<INode> {
    if (!element) new List<INode>();
    let node: INode = INode.isInstance(element)
      ? element
      : IEdge.isInstance(element)
      ? element.sourceNode || element.targetNode
      : null;
    if (node == null) {
      return new List<INode>();
    }
    return new Reachability({
      startNodes: node,
      directed: false,
    }).run(graph).reachableNodes;
  }

  /**
   * Try to remove the label from the given @param labelOwner
   * @param graph
   * @param labelOwner
   *
   * This method has an internal try/catch which will swallow any exceptions set @param shouldThrow to true if you want errors throw;
   */
  public static tryRemoveLabelOrLabelOwner(
    graph: IGraph,
    labelOwner: ILabelOwner,
    shouldThrow?: boolean
  ) {
    try {
      if (
        labelOwner instanceof INode &&
        labelOwner.tag.annotationType === AnnotationType.Text
      ) {
        const style = DiagramUtils.unwrapNodeStyle(labelOwner).baseStyle;
        if (style instanceof ShapeNodeStyle) {
          const isDefaultFill = style.fill.hasSameValue('TRANSPARENT');
          const isDefaultOutline =
            style.stroke.fill.hasSameValue('TRANSPARENT');

          if (isDefaultFill && isDefaultOutline) {
            graph.remove(labelOwner);
            return;
          }
        }
      }

      let label = DiagramUtils.getLabel(labelOwner);
      if (label == null) {
        return;
      }

      return graph.remove(label);
    } catch (ex) {
      if (shouldThrow) {
        throw ex;
      }
    }
  }

  public static getPlaceholderLabelStyle(): LabelStyleDto {
    return {
      fill: {
        color: DefaultColors.BLACK,
      },
      font: {
        fontStyle: 'Normal',
        fontSize: 9,
        fontFamily: 'Arial',
        fontWeight: 'bold',
        textDecoration: 'none',
      },
    };
  }

  public static serializeDiagram(
    diagram: DiagramDto,
    graphComponent: GraphComponent
  ): DiagramDto {
    diagram = DiagramWriter.fromGraphComponent(graphComponent, diagram);
    const legendDefinition = store.getters[
      `${DOCUMENT_NAMESPACE}/${GET_LEGEND_DEFINITION}`
    ] as ILegendDefinition;
    diagram.legend =
      legendDefinition != null ? JSON.stringify(legendDefinition) : null;
    return diagram;
  }

  /**
   * Gets the text only portion of a label, should not include any html
   * @param item
   * @returns
   */
  public static getPlaceholderLabelText(item: INode | IEdge): string {
    if (item.tag.labelIsPlaceholder) {
      return `[${i18n.t(item.tag.name)}]`;
    }
    return `[${item.tag.name}]`;
  }

  /**
   * This is the counterpart to @method getPlaceholderLabelText
   * Will return the included HTML
   * @param item the node for which the label is being created
   * @param text optional, the text to use for the label
   * @param labelStyleDto the label style to use for the label, if none if specified,
   * it'll fallback to extracting it from the node style, then the system default
   *
   */
  public static getHtmlLabelContent(
    item: INode | IEdge,
    text?: string,
    labelStyleDto?: LabelStyleDto
  ): string {
    labelStyleDto =
      labelStyleDto ??
      DiagramUtils.tryExtractLabelStyleFromOwner(item) ??
      DiagramUtils.getSystemDefaultLabelStyle();

    return CKEditorUtils.createHtmlStringFromStyle(
      labelStyleDto.fill,
      labelStyleDto.font,
      text
    );
  }

  public static setLabel(
    graph: IGraph,
    labelOwner: ILabelOwner,
    text: string = ''
  ): ILabel {
    if (!INode.isInstance(labelOwner) && !IEdge.isInstance(labelOwner)) {
      return;
    }

    text =
      text ?? DiagramUtils.getHtmlLabelContent(labelOwner as INode | IEdge);
    if (text === undefined || text == null) {
      return;
    }

    let tag = DiagramUtils.createNewLabelTag();

    const label = DiagramUtils.addOrCreateLabel(graph, labelOwner, tag);

    DiagramUtils.setLabelValue(graph, labelOwner, text);

    return label;
  }

  public static createNewLabelTag(): ILabelTag {
    return {
      position: { type: null, position: null },
    };
  }

  public static isLabelEditing(
    graphComponent: GraphComponent | CanvasComponent,
    item: ILabel | ILabelOwner
  ): boolean {
    const geim = graphComponent.inputMode as GraphEditorInputMode;
    const textEditorInputMode =
      geim.textEditorInputMode as CkTextEditorInputMode;

    if (!textEditorInputMode?.editing) {
      return false;
    }
    let label: ILabel = null;
    if (INode.isInstance(item) || IEdge.isInstance(item)) {
      label = DiagramUtils.getLabel(item);
    } else if (ILabel.isInstance(item)) {
      label = item;
    } else {
      throw 'Unsupported label owner';
    }
    return textEditorInputMode.label == label;
  }

  public static getLabel(labelOwner: ILabelOwner): ILabel {
    if (!ILabelOwner.isInstance(labelOwner)) {
      throw 'Not label owner';
    }

    if (
      labelOwner == null ||
      labelOwner.labels == null ||
      labelOwner.labels.size <= 0
    ) {
      return null;
    }
    return labelOwner.labels.first();
  }

  public static getLabelValue(labelOwner: ILabelOwner): string {
    if (!ILabelOwner.isInstance(labelOwner)) {
      throw 'Not label owner';
    }
    return DiagramUtils.getLabel(labelOwner)?.text;
  }

  public static addOrCreateLabel(
    graph: IGraph,
    labelOwner: ILabelOwner,
    tag?: ILabelTag
  ): ILabel {
    if (!ILabelOwner.isInstance(labelOwner)) {
      throw 'Not label owner';
    }
    let label = null;
    if (labelOwner.labels.size <= 0) {
      label = graph.addLabel(
        labelOwner,
        '',
        DiagramUtils.getLabelModelParameter(labelOwner),
        StyleCreator.createLabelStyle(),
        null,
        tag ?? DiagramUtils.createNewLabelTag()
      );
    } else {
      label = labelOwner.labels.first();
    }
    return label;
  }

  public static selectLabel(graphComponent: GraphComponent): boolean {
    if (
      graphComponent.selection.size === 0 ||
      graphComponent.selection.selectedNodes.size > 1 ||
      graphComponent.selection.selectedEdges.size > 1 ||
      (graphComponent.selection.selectedNodes.size > 0 &&
        graphComponent.selection.selectedEdges.size > 0)
    ) {
      return false;
    }

    const selectedItem = graphComponent.selection.first();

    if (
      selectedItem &&
      ((INode.isInstance(selectedItem) &&
        !(
          selectedItem.tag.isAnnotation &&
          (selectedItem.tag.annotationType == AnnotationType.Logos ||
            selectedItem.tag.annotationType == AnnotationType.Text)
        )) ||
        IEdge.isInstance(selectedItem))
    ) {
      const label = DiagramUtils.getLabel(selectedItem);
      if (label) {
        graphComponent.selection.setSelected(label, true);
        graphComponent.focus();
        return true;
      }
    }
    return false;
  }

  public static tryExtractLabelStyleFromOwner(
    item: INode | IEdge
  ): LabelStyleDto {
    return (item as any).tag?.style?.labelStyle;
  }

  public static midPoint(loc1: Point, loc2: Point): Point {
    return new Point((loc1.x + loc2.x) / 2, (loc1.y + loc2.y) / 2);
  }

  public static getLabelModelParameter(
    labelOwner: ILabelOwner
  ): ILabelModelParameter {
    if (INode.isInstance(labelOwner)) {
      return DiagramUtils.getNodeLabelParameter(labelOwner);
    }
    if (IEdge.isInstance(labelOwner)) {
      return DiagramUtils.getEdgeLabelParameter(labelOwner);
    }
  }

  private static getNodeLabelParameter(node: INode): ILabelModelParameter {
    if (node.tag.isGroupNode) {
      return JigsawInteriorNodeLabelModel.CENTER;
    }
    if (
      node.tag.isAnnotation &&
      (node.tag.annotationType == AnnotationType.ClipArt ||
        node.tag.annotationType == AnnotationType.Logos)
    ) {
      return JigsawExteriorNodeLabelModel.SOUTH;
    }

    let ownerStyle = node.style;
    if (ownerStyle instanceof JigsawNodeStyle) {
      ownerStyle = ownerStyle.baseStyle;
    }
    if (ownerStyle instanceof ImageNodeStyle) {
      return JigsawExteriorNodeLabelModel.SOUTH;
    }
    const nodeStyle = DiagramUtils.unwrapNodeStyle(node);
    if (nodeStyle instanceof JigsawNodeStyle) {
      let labelOffset: Point = null;
      const shape = (node.tag?.style as any)?.shape;
      if (nodeStyle.baseStyle instanceof ShapeNodeStyle) {
        labelOffset = diagramConfig.nodeLabelOffsets.shape[shape];
      }
      if (nodeStyle.baseStyle instanceof CompositeNodeStyle) {
        labelOffset = diagramConfig.nodeLabelOffsets.composite[shape];
      }

      if (labelOffset != null) {
        return new JigsawInteriorNodeLabelModel().createParameterFromOffset(
          labelOffset
        );
      }
    }
    return JigsawInteriorNodeLabelModel.CENTER;
  }

  private static getEdgeLabelParameter(labelOwner: IEdge) {
    return new JigsawEdgeLabelModel().createDefaultParameter();
  }

  public static createNewEdgeTag(name: string, style?: EdgeStyleDto): IEdgeTag {
    return {
      autoCreated: false,
      definitionCustomised: false,
      edited: false,
      id: null,
      isAnnotation: false,
      isFixedInLayout: false,
      placeholder: false,
      sourcePortFixed: false,
      targetPortFixed: false,
      style: style ?? DiagramUtils.getSystemDefaultEdgeStyle(),
      uuid: generateUuid(),
      dataProperties: [],
      dataPropertyTags: [],
      attachments: [],
      name: name,
      busid: null,
      isIncluded: true,
      isOrphan: false,
      sourcePortDirection: null,
      targetPortDirection: null,
      labelIsPlaceholder: true,
    };
  }

  public static createNewNodeTag(
    name: string,
    style?: INodeStyleDto
  ): INodeTag {
    return {
      definitionCustomised: false,
      id: null,
      groupUuid: null,
      isFixedInLayout: false,
      isGroupNode: false,
      groupColor: null,
      isLocked: false,
      layer: null,
      quickBuildDisabled: false,
      style: style ?? DiagramUtils.getSystemDefaultNodeStyle(),
      uuid: generateUuid(),
      dataProperties: [],
      isAnnotation: false,
      attachments: [],
      name: name,
      annotationType: AnnotationType.None,
      displayOrder: 0,
      decorationStates: {},
      isIncluded: true,
      dataPropertyTags: [],
      hovered: false,
      dataPropertyDisplayTypes: {
        [DataPropertyDisplayTypeNames.Jurisdiction]:
          DiagramUtils.getFilteredNodeTagJurisdictionDataPropertyDisplayTypes(),
        [DataPropertyDisplayTypeNames.State]: [
          DataPropertyDisplayType.NodeLabel,
          DataPropertyDisplayType.Decorator,
        ],
      },
      labelIsPlaceholder: true,
      dataPropertyStyle: {
        isActive: false,
      },
    };
  }

  public static getFilteredNodeTagJurisdictionDataPropertyDisplayTypes() {
    let persistedDataPropertyDisplayTypes =
      store.getters[
        `${DOCUMENT_NAMESPACE}/${GET_PERSISTED_DATA_PROPERTY_DISPLAY_TYPES}`
      ];
    if (
      persistedDataPropertyDisplayTypes &&
      persistedDataPropertyDisplayTypes[
        DataPropertyDisplayTypeNames.Jurisdiction
      ] &&
      persistedDataPropertyDisplayTypes[
        DataPropertyDisplayTypeNames.Jurisdiction
      ].length
    ) {
      return [
        DataPropertyDisplayType.NodeLabel,
        DataPropertyDisplayType.Decorator,
      ].filter(
        (dp) =>
          !persistedDataPropertyDisplayTypes[
            DataPropertyDisplayTypeNames.Jurisdiction
          ].includes(dp)
      );
    }

    return [
      DataPropertyDisplayType.NodeLabel,
      DataPropertyDisplayType.Decorator,
    ];
  }

  public static groupNodes(
    graph: IGraph,
    nodes: INode[],
    parent: INode = null,
    groupColor: string = null
  ) {
    if (!parent) {
      let tag = DiagramUtils.createNewNodeTag('GROUP');
      tag.isGroupNode = true;
      tag.groupUuid = generateUuid();

      if (!groupColor.startsWith('rgb')) {
        const color = HexToRgb(groupColor) ?? randomRgb();
        tag.groupColor = `rgb(${color.r},${color.g},${color.b})`;
      } else {
        tag.groupColor = groupColor;
      }

      parent = graph.createGroupNode(
        null,
        null,
        new JigsawNodeStyle(GroupNodeStyle.INSTANCE, []),
        tag
      );
    }

    nodes
      .map((i) => i as INode)
      .forEach((n) => {
        n.tag.groupUuid = parent.tag.groupUuid;
        graph.setParent(n, parent);
      });

    graph.invalidateDisplays();
  }

  public static ungroupNodes(graph: IGraph, nodes: INode[]) {
    nodes
      .filter((x) => x.tag.groupUuid)
      .forEach((node) => {
        graph.setParent(node, null);
        node.tag.groupUuid = null;
        node.tag.groupColor = null;
      });

    graph.invalidateDisplays();
  }

  public static calculatePortRatios(port: IPort): Point {
    let node = port.owner as INode;
    let ratioY = (port.location.y - node.layout.y) / node.layout.height;
    let ratioX = (port.location.x - node.layout.x) / node.layout.width;
    return new Point(ratioX, ratioY);
  }

  public static snapToNearestGridPoint(
    location: Point,
    jumpSize?: number
  ): Point {
    let x = DiagramUtils.snapToNearGrid(location.x, jumpSize);
    let y = DiagramUtils.snapToNearGrid(location.y, jumpSize);
    return new Point(x, y);
  }

  public static snapToNearGrid(pos: number, jumpSize?: number): number {
    jumpSize = jumpSize ?? config.grid.jumpSize;
    let jumpFactor = config.grid.size * jumpSize;
    return roundToClosest(pos, jumpFactor);
  }

  /**
   * Snap to the near grid and apply offset based on the original position
   */
  public static snapToNearGridAndOffset(
    originalPos: number,
    newPos: number,
    jumpSize?: number
  ): number {
    let snap = DiagramUtils.snapToNearGrid(originalPos, jumpSize);
    let offset = snap - originalPos;
    return DiagramUtils.snapToNearGrid(newPos - offset, jumpSize) + offset;
  }

  public static getNodeSize(nodeStyle: INodeStyleDto): Size {
    let width: number = null;
    let height: number = null;
    switch (+nodeStyle.visualType) {
      case NodeVisualType.Shape:
      case NodeVisualType.Composite:
        let shapeStyle: ShapeNodeStyleDto | CompositeNodeStyleDto = nodeStyle;
        let nodeShape: NodeShape;
        if (shapeStyle.visualType == NodeVisualType.Shape) {
          nodeShape = (shapeStyle as ShapeNodeStyleDto).shape;
        } else {
          nodeShape = (shapeStyle as CompositeNodeStyleDto).styleDefinitions[0]
            .nodeStyle.shape;
        }
        let nodeGridSizes = config.nodeGridSizes[nodeShape] as any;
        if (
          nodeGridSizes &&
          nodeGridSizes.sizes &&
          nodeGridSizes.sizes[shapeStyle.size]
        ) {
          let nodeSize = nodeGridSizes.sizes[shapeStyle.size];
          let x = nodeSize[0];
          let y = nodeSize[1];
          width = x * config.grid.size;
          height = y * config.grid.size;
        } else {
          let multipler = DiagramUtils.getSizeFactor(shapeStyle.size);
          if (config.stretchNodes.indexOf(Number(nodeShape)) >= 0) {
            width = width * config.stretchNodeFactor;
          }
          height = height * multipler;
          width = width * multipler;
        }
        break;

      case NodeVisualType.Image:
        const mediumSize =
          config.nodeGridSizes[NodeShape.Square].sizes[NodeSize.Medium];

        width = mediumSize[0] * DiagramUtils.getSizeFactor(NodeSize.ExtraLarge);
        height =
          mediumSize[1] * DiagramUtils.getSizeFactor(NodeSize.ExtraLarge);
        break;
      default:
        throw 'Unknown visual type';
    }

    return new Size(
      DiagramUtils.snapToNearGrid(width),
      DiagramUtils.snapToNearGrid(height)
    );
  }

  public static getSizeFactor(size: number): number {
    let sizeFactor = config.nodeSizeFactors[Number(size)];
    if (typeof sizeFactor === 'undefined') {
      throw 'unknown size ' + size;
    }
    return sizeFactor;
  }

  public static createSimpleShapeNode(
    shape: ShapeNodeShape,
    fill: string,
    location?: Point,
    defaultSize?: Size
  ): SimpleNode {
    let simpleNode = new SimpleNode();
    const size = defaultSize ?? new Size(60, 60);
    const style = new ShapeNodeStyle({
      shape: shape,
      fill: fill,
    });

    simpleNode.layout = new Rect(
      location?.x ?? 0,
      location?.y ?? 0,
      size.width,
      size.height
    );
    const decorators = [
      IndicatorDecorators.INSTANCE,
      FilterDecorators.INSTANCE,
      JurisdictionDecorator.INSTANCE,
      EdgeCreationButtonDecorator.INSTANCE,
    ];
    simpleNode.style = new JigsawNodeStyle(style.clone(), decorators);
    simpleNode.tag = DiagramUtils.createNewNodeTag(null, null);
    simpleNode.tag.isAnnotation = true;
    simpleNode.tag.annotationType = AnnotationType.Shape;

    return simpleNode;
  }

  public static createSimpleNode(
    name: string,
    nodeStyle: INodeStyle,
    size: Size,
    location?: Point
  ): SimpleNode {
    let simpleNode = new SimpleNode();
    simpleNode.layout = new Rect(
      location?.x ?? 0,
      location?.y ?? 0,
      size.width,
      size.height
    );
    simpleNode.style = nodeStyle.clone();
    simpleNode.tag = DiagramUtils.createNewNodeTag(
      name,
      DiagramWriter.convertNodeStyle(simpleNode)
    );

    return simpleNode;
  }

  public static createSimpleImageNode(
    name: string,
    imgsrc: string,
    size?: Size,
    location?: Point
  ): SimpleNode {
    let simpleNode = new SimpleNode();

    if (size == null) size = new Size(60, 60);

    const style = new JigsawNodeStyle(
      new ImageNodeStyle({
        image: imgsrc,
        renderer: new JigsawImageNodeStyleRenderer(),
      }),
      [
        JurisdictionDecorator.INSTANCE,
        IndicatorDecorators.INSTANCE,
        FilterDecorators.INSTANCE,
        EdgeCreationButtonDecorator.INSTANCE,
      ]
    );

    //https://codeburst.io/managing-svg-images-in-vue-js-applications-88a0570d8e88

    simpleNode.layout = new Rect(
      location?.x ?? 0,
      location?.y ?? 0,
      size.width,
      size.height
    );
    const rotatestyle = new RotatableNodeStyleDecorator(style.clone(), 0);
    simpleNode.style = rotatestyle as any; //style.clone();

    //simpleNode.ports.foreach((p) => {});

    simpleNode.tag = DiagramUtils.createNewNodeTag(
      name,
      DiagramWriter.convertNodeStyle(simpleNode)
    );

    return simpleNode;
  }

  public static getNodeSide(nodeLayout: IRectangle, point: Point) {
    const deltaX = Math.abs(nodeLayout.center.x - point.x);
    const deltaY = Math.abs(nodeLayout.center.y - point.y);

    if (deltaX === 0 && deltaY === 0) {
      return PortSide.ANY;
    }

    if (deltaX > deltaY) {
      if (point.x < nodeLayout.center.x) {
        return PortSide.WEST;
      } else {
        return PortSide.EAST;
      }
    } else {
      if (point.y < nodeLayout.center.y) {
        return PortSide.NORTH;
      } else {
        return PortSide.SOUTH;
      }
    }
  }

  public static setLabelValue(
    graph: IGraph,
    labelOwner: ILabelOwner,
    value: string
  ): ILabel {
    if (!graph.contains(labelOwner)) return;
    let label = DiagramUtils.addOrCreateLabel(graph, labelOwner);
    const isHtmlValue = /<\/p>\s*$/.test(value);
    const text = isHtmlValue
      ? value
      : DiagramUtils.getHtmlLabelContent(labelOwner as INode | IEdge, value);
    graph.setLabelText(label, text);
    return label;
  }

  public static applyThemeElementSizeToNode(
    graph: IGraph,
    node: INode,
    themeElement: ThemeElementDto
  ) {
    const newNodeSize = DiagramUtils.getNodeSize(themeElement.style);
    const newNodeLayout = new Rect(node.layout.toPoint(), newNodeSize);
    graph.setNodeLayout(node, newNodeLayout);
  }

  public static changeNodeType(
    graph: IGraph,
    node: INode,
    themeElement: ThemeElementDto
  ) {
    if (themeElement.elementType != ElementType.Node) {
      throw 'Invalid theme element for node';
    }

    const oldType = node.tag.name;
    node.tag.style = themeElement.style;
    node.tag.name = themeElement.name;

    // Copy the node center point for later use
    const nodeCenter = node.layout.center;

    let newStyle: JigsawNodeStyle = StyleCreator.createNodeStyle(
      themeElement.style
    );

    // Copy style changes over the new theme element style if original style was modified
    const currentStyle = DiagramUtils.unwrapNodeStyle(node).baseStyle;

    // Extract original theme element style to check if it was modified
    const originalThemeElement = store.getters[
      `${DOCUMENT_NAMESPACE}/${GET_THEME_ELEMENT_BY_NAME}`
    ](oldType) as ThemeElementDto;

    if (originalThemeElement) {
      const originalStyle = StyleCreator.createNodeStyle(
        originalThemeElement.style
      ).baseStyle;
      const originalNodeSize = DiagramUtils.getNodeSize(
        originalThemeElement.style
      );

      // Apply new theme element size if node size is unchanged
      if (node.layout.toSize().equals(originalNodeSize)) {
        DiagramUtils.applyThemeElementSizeToNode(graph, node, themeElement);
      }

      if (
        !GraphElementsComparer.nodeStylesEqual(currentStyle, originalStyle) &&
        newStyle.baseStyle instanceof ShapeNodeStyle &&
        currentStyle instanceof ShapeNodeStyle
      ) {
        const shapeNodeStyle = new ShapeNodeStyle({
          shape: StyleCreator.getNodeShape(themeElement.style.shape),
          fill: currentStyle.fill,
          stroke: currentStyle.stroke,
          renderer: new JigsawShapeNodeStyleRenderer(themeElement.style.shape),
        });
        newStyle = new JigsawNodeStyle(shapeNodeStyle);
      }
    } else {
      DiagramUtils.applyThemeElementSizeToNode(graph, node, themeElement);
    }

    DiagramUtils.setStyle(graph, node, newStyle);
    // Maintain the original center point
    graph.setNodeCenter(node, nodeCenter);
    let label = DiagramUtils.getLabel(node);

    if (label && node.tag.labelIsPlaceholder) {
      let labelText = DiagramUtils.getPlaceholderLabelText(node);
      labelText = DiagramUtils.getHtmlLabelContent(node, labelText);
      const nodeLabelParameter = DiagramUtils.getNodeLabelParameter(node);
      graph.setLabelLayoutParameter(label, nodeLabelParameter);
      DiagramUtils.setLabelValue(graph, node, labelText);
    }

    if (label) {
      const nodeLabelParameter = DiagramUtils.getNodeLabelParameter(node);
      graph.setLabelLayoutParameter(label, nodeLabelParameter);
    }

    EventBus.$emit(EventBusActions.DIAGRAM_NODE_TYPE_CHANGED, {
      node: node,
      oldType: oldType,
      newType: node.tag.name,
    });
  }

  public static changeEdgeType(
    graph: IGraph,
    edge: IEdge,
    themeElement: ThemeElementDto
  ) {
    if (themeElement.elementType != ElementType.Edge) {
      throw 'Invalid theme element for edge';
    }

    const themeElementStyle = cloneDeep(themeElement.style);
    edge.tag.style = themeElementStyle;
    edge.tag.name = themeElement.name;
    let style = StyleCreator.createEdgeStyle(themeElementStyle);
    DiagramUtils.setStyle(graph, edge, style);

    let label = DiagramUtils.getLabel(edge);

    if (label) {
      DiagramUtils.setStyle(graph, label, StyleCreator.createLabelStyle());
    }
  }

  public static getDashStyleIconSrc(dashStyleType: DashStyleType): string {
    switch (dashStyleType) {
      case DashStyleType.Solid:
        return '/media/dash-style-lines/straight-horizontal-line.svg';

      case DashStyleType.Dash:
        return '/media/dash-style-lines/dash-line.svg';

      default:
        return '/media/dash-style-lines/straight-horizontal-line.svg';
    }
  }

  public static isImageNode(node: INode): boolean {
    let style = node.style;
    if (style instanceof JigsawNodeStyle) {
      style = style.baseStyle;
    }

    if (node.style instanceof ImageNodeStyle) {
      return true;
    }
  }

  public static getElementColor(
    item: IModelItem,
    elementType: ElementTypeColor,
    compositeStyleIndex?: number
  ): string {
    try {
      if (INode.isInstance(item) && item.tag.isGroupNode) {
        return 'transparent';
      }
      switch (elementType) {
        case ElementTypeColor.NodeFill:
          return DiagramWriter.convertColorAsRgb(
            (item as any).style.baseStyle.fill.color
          );
        case ElementTypeColor.CompositeNodeFill:
          return DiagramWriter.convertColorAsRgb(
            (item as any).style.baseStyle.styleDefinitions[compositeStyleIndex]
              .nodeStyle.fill.color
          );
        case ElementTypeColor.NodeOutline:
          return DiagramWriter.convertColorAsRgb(
            (item as any).style.baseStyle.stroke.fill.color
          );
        case ElementTypeColor.CompositeNodeOutline:
          return DiagramWriter.convertColorAsRgb(
            (item as any).style.baseStyle.styleDefinitions[compositeStyleIndex]
              .nodeStyle.stroke.fill.color
          );
        case ElementTypeColor.EdgeOutline:
          return DiagramWriter.convertColorAsRgb(
            (item as any).style.stroke.fill.color
          );
        case ElementTypeColor.TargetArrowFill:
          return DiagramWriter.convertColorAsRgb(
            (item as any).style.targetArrow.fill.color
          );
        case ElementTypeColor.SourceArrowFill:
          return DiagramWriter.convertColorAsRgb(
            (item as any).style.sourceArrow.fill.color
          );

        default:
          return 'transparent';
      }
    } catch (e) {
      console.log('Error: ', e);
      return 'transparent';
    }
  }

  public static getShapeNodeShapeName(shape: ShapeNodeShape): string {
    switch (shape) {
      case ShapeNodeShape.ELLIPSE:
        return i18n.t('OVAL').toString();
      case ShapeNodeShape.RECTANGLE:
      case ShapeNodeShape.ROUND_RECTANGLE:
        return i18n.t('RECTANGLE').toString();
      case ShapeNodeShape.TRIANGLE:
      case ShapeNodeShape.TRIANGLE2:
        return i18n.t('TRIANGLE').toString();
      case ShapeNodeShape.DIAMOND:
        return i18n.t('DIAMOND').toString();
      case ShapeNodeShape.SHEARED_RECTANGLE:
      case ShapeNodeShape.SHEARED_RECTANGLE2:
        return i18n.t('SHEARED_RECTANGLE').toString();
      case ShapeNodeShape.STAR5:
        return i18n.t('STAR5').toString();
      case ShapeNodeShape.STAR6:
        return i18n.t('STAR6').toString();
      case ShapeNodeShape.TRAPEZ:
      case ShapeNodeShape.TRAPEZ2:
        return i18n.t('TRAPEZ').toString();
      case ShapeNodeShape.HEXAGON:
        return i18n.t('HEXAGON').toString();
      case ShapeNodeShape.FAT_ARROW:
      case ShapeNodeShape.FAT_ARROW2:
        return i18n.t('ARROW').toString();
      case ShapeNodeShape.STAR8:
        return i18n.t('STAR8').toString();
      case ShapeNodeShape.OCTAGON:
        return i18n.t('OCTAGON').toString();
    }
    return 'Unknown Shape';
  }

  public static getNodeShapeName(shape: NodeShape): string {
    switch (shape) {
      case NodeShape.Oval:
        return 'Oval';
      case NodeShape.Rectangle:
      case NodeShape.RoundedRectangle:
        return 'Rectangle';
      case NodeShape.Triangle:
      case NodeShape.Triangle2:
        return 'Triangle';
      case NodeShape.Diamond:
        return 'Diamond';
      case NodeShape.ShearedRectangle:
      case NodeShape.ShearedRectangle2:
        return 'Sheared Rectangle';
      case NodeShape.Star5:
        return 'Star 5';
      case NodeShape.Star6:
        return 'Star 6';
      case NodeShape.Star8:
        return 'Star 8';
      case NodeShape.Trapez:
      case NodeShape.Trapez2:
      case NodeShape.TrapezShort:
        return 'Trapez';
      case NodeShape.Hexagon:
        return 'Hexagon';
      case NodeShape.FatArrow:
      case NodeShape.FatArrow2:
        return 'Arrow';
      case NodeShape.Octagon:
        return 'Octagon';
      case NodeShape.Pill:
        return 'Pill';
    }
    return 'Unknown Shape';
  }

  /**
   * Gets the shape of a node
   * This will deconstruct a JigsawNodeStyle, targeting it's base.
   * This does support getting the "outer" shape of a composite base style.
   * @param node
   * @returns node shape
   */
  public static getNodeShape(node: INode): ShapeNodeShape {
    let style = DiagramUtils.unwrapNodeStyle(node) as any;
    style = style.baseStyle;

    if (style instanceof CompositeNodeStyle) {
      style = style.styleDefinitions[0].nodeStyle;
    }
    if (style instanceof ShapeNodeStyle) {
      return style.shape;
    }

    return null;
  }

  public static updateSvgFilteredStyles(
    item: INode | IEdge | ILabel,
    svgElement: SVGElement,
    filterKey?: string
  ): void {
    if (INode.isInstance(item) && item.tag.isGroupNode) {
      return;
    }

    let isWhite = false;

    if (INode.isInstance(item)) {
      isWhite = svgElement.innerHTML.includes('fill="rgb(255,255,255)"');
    }

    const filter =
      filterKey && filterKey.length > 0 ? `url(#${filterKey})` : '';

    const parentItem = ILabel.isInstance(item) ? item.owner : item;
    const isIncluded = parentItem?.tag?.isIncluded;
    if (isIncluded) {
      svgElement.style.filter = '';
      svgElement.classList.remove('unselected-item');
      svgElement.classList.remove('unselected-item-white');
      svgElement.classList.remove('unselected-item-edge');
    } else {
      svgElement.style.filter = filter;
      svgElement.classList.add('unselected-item');
      if (isWhite) svgElement.classList.add('unselected-item-white');
      if (IEdge.isInstance(item))
        svgElement.classList.add('unselected-item-edge');
    }
  }

  public static unwrapNodeStyle(node: INode): JigsawNodeStyle {
    let style = node.style;
    if (style instanceof RotatableNodeStyleDecorator) {
      style = style.wrapped;
    }

    if (style instanceof JigsawNodeStyle) {
      return style;
    }
    throw 'Cannot unwrap style';
  }

  /**
   * Given a set of ports and a location, this will return the closest.
   * @param availablePorts
   * @param location
   */
  public static findClosestPort(
    availablePorts: IPort[],
    location: Point,
    maxDistance: number = null
  ): IPort {
    let lowestDistance = null;
    let bestPort = null;
    for (let i = 0; i < availablePorts.length; i++) {
      const port = availablePorts[i];
      let distance = location.distanceTo(port.location);
      if (maxDistance != null && distance > maxDistance) {
        continue;
      }

      if (lowestDistance == null || distance < lowestDistance) {
        lowestDistance = distance;
        bestPort = port;
      }
    }
    return bestPort;
  }

  public static findClosestPorts(
    availablePorts: IPort[],
    location: Point,
    maxDistance: number
  ): IPort[] {
    const ports = [];
    for (let i = 0; i < availablePorts.length; i++) {
      const port = availablePorts[i];
      let distance = location.distanceTo(port.location);
      if (distance > maxDistance) {
        continue;
      }
      ports.push(port);
    }
    return ports;
  }
  /**
   *
   * @param anchor
   * @param location
   */
  public static getSide(anchor: Point, location: Point): PortSide {
    // angle in degrees

    var angle = getAngle(anchor, location);

    if (angle > 45 && angle <= 135) {
      return PortSide.EAST;
    } else if (angle > 135 && angle <= 225) {
      return PortSide.SOUTH;
    } else if (angle > 225 && angle <= 315) {
      return PortSide.WEST;
    } else if ((angle > 315 && angle <= 360) || (angle >= 0 && angle <= 45)) {
      return PortSide.NORTH;
    }

    throw 'Unknown side';
  }

  /**
   * Returns you the PortSide for the given @param port relative to it's owner
   * @param port
   * @returns
   */
  public static getPortSide(port: IPort): PortSide {
    let node = port.owner as INode;

    return DiagramUtils.getSide(node.layout.center, port.location);
  }

  public static createPortCandidateFromPort(
    port: IPort,
    portDirection: PortDirections
  ): PortCandidate {
    const node = port.owner as INode;
    let diff = port.location.subtract(node.layout.center);
    return PortCandidate.createCandidate(diff.x, diff.y, portDirection, 0);
  }

  public static createPortCandidate(
    node: INode,
    xOffset: number,
    yOffset: number,
    portDirection: PortDirections,
    cost: number
  ): PortCandidate {
    return PortCandidate.createCandidate(xOffset, yOffset, portDirection, cost);
  }

  public static clearFixedEdgePort(edgeTag: IEdgeTag, sourceEnd: boolean) {
    if (sourceEnd) {
      edgeTag.sourcePortFixed = false;
      edgeTag.sourcePortDirection = null;
    } else {
      edgeTag.targetPortFixed = false;
      edgeTag.targetPortDirection = null;
    }
  }

  public static fixEdgePort(
    edgeTag: IEdgeTag,
    sourceEnd: boolean,
    portDirection: PortDirections
  ) {
    if (portDirection == null) {
      throw 'Invalid port direction';
    }
    if (sourceEnd) {
      edgeTag.sourcePortFixed = true;
      edgeTag.sourcePortDirection = portDirection;
    } else {
      edgeTag.targetPortFixed = true;
      edgeTag.targetPortDirection = portDirection;
    }
  }

  public static getPortDirectionName = (dir: PortDirections) => {
    if (dir === PortDirections.NORTH) return 'North';
    if (dir === PortDirections.EAST) return 'East';
    if (dir === PortDirections.SOUTH) return 'South';
    if (dir === PortDirections.WEST) return 'West';
    if (dir === PortDirections.ANY) return 'Any';
    if (dir === PortDirections.AGAINST_THE_FLOW) return 'Against the flow';
    if (dir === PortDirections.LEFT_IN_FLOW) return 'Left in flow';
    if (dir === PortDirections.RIGHT_IN_FLOW) return 'Right in flow';
    if (dir === PortDirections.WITH_THE_FLOW) return 'With the flow';
    return 'Unknown';
  };

  public static reverseEdgeDirection(edge: IEdge, graph: IGraph) {
    const sourcePortFixed = edge.tag.sourcePortFixed;
    const targetPortFixed = edge.tag.targetPortFixed;
    const sourcePortDirection = edge.tag.sourcePortDirection;
    const targetPortDirection = edge.tag.targetPortDirection;

    edge.tag.sourcePortFixed = targetPortFixed;
    edge.tag.sourcePortDirection = targetPortDirection;

    edge.tag.targetPortFixed = sourcePortFixed;
    edge.tag.targetPortDirection = sourcePortDirection;
    graph.reverse(edge);
  }

  // public static getLogoAsBase64(
  //   document: DocumentDto | CreateOrEditDocumentDto
  // ): string {
  //   if (!document || !document.attachments?.length) return null;
  //   const logoAttachment = document.attachments.find(
  //     (a) => a.attachmentType == DocumentAttachmentType.Logo
  //   );

  //   if (!logoAttachment) return null;

  //   return appConfig.apiBaseUrl + logoAttachment.fileAttachment.path;
  // }

  public static getDocumentAttachmentPath(
    document: DocumentDto | CreateOrEditDocumentDto,
    attachmentType: DocumentAttachmentType
  ): string {
    if (!document || !document.attachments?.length) return null;
    const attachment = document.attachments.find(
      (a) => a.attachmentType == attachmentType
    );

    if (attachment?.fileAttachment?.path) {
      return appConfig.apiBaseUrl + attachment.fileAttachment.path;
    } else {
      return null;
    }
  }

  public static getDocumentAttachmentId(
    document: DocumentDto | CreateOrEditDocumentDto,
    attachmentType: DocumentAttachmentType
  ): string {
    if (!document || !document.attachments?.length) return null;
    const attachment = document.attachments.find(
      (a) => a.attachmentType == attachmentType
    );

    if (attachment?.fileAttachment?.fileId) {
      return attachment?.fileAttachment?.fileId;
    } else {
      return null;
    }
  }

  public static graphHasFilters(graph: IGraph): boolean {
    if (!graph) return false;
    const graphItems = [...graph.nodes, ...graph.edges];
    return (
      ExportService.dataExportService.currentTagFilters?.length > 0 ||
      graphItems.some((item) => item.tag?.isIncluded === false)
    );
  }

  public static diagramHasFilters(diagram: DiagramDto): boolean {
    if (!diagram) return false;
    const graphItems = [...diagram.nodes, ...diagram.edges];
    return graphItems.some((item) => item.isIncluded === false);
  }

  public static graphHasData(graph: IGraph): boolean {
    if (!graph) return false;
    const graphItems = [...graph.nodes, ...graph.edges];
    return graphItems.some(
      (item) =>
        item.tag?.dataProperties?.length > 0 ||
        item.tag?.attachments?.length > 0
    );
  }

  public static isTemplateElement(
    diagram: DiagramDto,
    model: IModelItem
  ): boolean {
    if (
      diagram == null ||
      (!(model instanceof INode) && !(model instanceof IEdge))
    ) {
      return false;
    }

    if (!diagram?.templates) {
      return false;
    }

    return (
      diagram.templates.findIndex((x) => x.elementUuid == model.tag.uuid) >= 0
    );
  }

  /**
   * Gets the default font from the given themes font styles
   * @param theme
   * @returns
   */
  public static getDefaultThemeFont(
    theme: ThemeDto | CreateOrEditThemeDto
  ): FontStyleDto {
    return theme?.fontStyles?.find((x) => x.isDefault);
  }

  /**
   * Creates a HTML style string based on the given font style
   * @param font
   * @returns
   */
  public static buildFontStyleString(font: FontDto): string {
    if (!font) {
      return '';
    }
    return `font-size:${font.fontSize}pt;font-family:'${font.fontFamily}';font-weight:${font.fontWeight};text-decoration:${font.textDecoration};font-style:${font.fontStyle}`;
  }

  public static buildLabelStyleString(labelStyle: LabelStyleDto): string {
    if (!labelStyle) {
      return '';
    }
    let styleString = DiagramUtils.buildFontStyleString(labelStyle.font);

    if (labelStyle.fill?.color) {
      styleString += `;color:${labelStyle.fill.color};`;
    }
    return styleString;
  }

  /**
   * Creates the initial page content that should be used
   * @param theme
   * @returns
   */
  public static getInitialPageContent(theme: ThemeDto | CreateOrEditThemeDto) {
    if (!theme) {
      return null;
    }
    const defaultFont = DiagramUtils.getDefaultThemeFont(theme);
    if (!defaultFont) {
      return null;
    }
    const styleString = DiagramUtils.buildFontStyleString(defaultFont.style);
    return `<p><span style="${styleString}">${ZERO_WIDTH_SPACE}</span></p>`;
  }

  public static getLabelModelType(
    input: ILabel | ILabelModelParameter
  ): LabelModelType {
    let parameter: ILabelModelParameter;

    if (
      input instanceof JigsawInteriorNodeLabelModelParameter ||
      input instanceof JigsawExteriorNodeLabelModelParameter
    ) {
      parameter = input;
    } else if (input instanceof ILabel) {
      parameter = input.layoutParameter;
    }

    if (parameter instanceof JigsawInteriorNodeLabelModelParameter) {
      return LabelModelType.Interior;
    }
    if (parameter instanceof JigsawExteriorNodeLabelModelParameter) {
      return LabelModelType.Exterior;
    }

    return LabelModelType.Unknown;
  }

  public static areSizeEquals(a: Size, b: Size): boolean {
    if (a.width != b.width) {
      return false;
    }
    if (a.height != b.height) {
      return false;
    }
    return true;
  }

  /**
   * Wraps graph's setStyle method to track elements hash key changes, which are used in the DiagramLegend component
   */
  public static setStyle(
    graph: IGraph,
    item: IModelItem,
    style: INodeStyle | IEdgeStyle | ILabelStyle,
    dataPropertyStyleIsActive = false
  ) {
    if (INode.isInstance(item) || IEdge.isInstance(item)) {
      const previousKey = GraphElementsHashGenerator.getElementHashKey(item);
      graph.setStyle(item as any, style as any);
      const newKey = GraphElementsHashGenerator.getElementHashKey(item);
      if (newKey !== previousKey) {
        EventBus.$emit(EventBusActions.DIAGRAM_ELEMENT_HASH_KEY_CHANGED, {
          previousKey,
          newKey,
        });
      }
    } else {
      graph.setStyle(item as any, style as any);
    }

    if (INode.isInstance(item)) {
      item.tag.dataPropertyStyle.isActive = dataPropertyStyleIsActive;
    }
  }

  public static validateSystemOwnedName(elementName: string): string {
    const systemOwnedName = Object.values(SystemEntityTypes).find(
      (name) => name.toUpperCase() === elementName?.toUpperCase()
    );

    return systemOwnedName ? systemOwnedName : elementName;
  }

  public static getNodeLabelMaxWidth(label: ILabel): number {
    if (
      label.layoutParameter.model instanceof JigsawExteriorNodeLabelModel ||
      label.layoutParameter.model instanceof JigsawInteriorNodeLabelModel
    ) {
      return label.layoutParameter.model.getMaxWidth(label);
    }

    if (label.owner instanceof INode) {
      return label.owner.layout.width;
    }

    throw 'Unsupported label';
  }
  /**
   * Generates new UUID's for the given tag
   * Updates data properties
   * Updated attachments
   * @param tag
   * @returns
   */
  public static regenerateItemUuid(tag: INodeTag | IEdgeTag) {
    const copiedTag = JSON.parse(JSON.stringify(tag)) as INodeTag | IEdgeTag;
    copiedTag.id = null;
    copiedTag.uuid = generateUuid();

    const dpUuidMapping = {};
    copiedTag.dataProperties?.forEach((d) => {
      if (d.uuid) {
        const newUuid = generateUuid();
        dpUuidMapping[d.uuid] = newUuid;
        d.uuid = newUuid;
      }
      d.diagramEdgeId = null;
      d.diagramId = null;
      d.diagramNodeId = null;
      d.id = null;
    });

    copiedTag.dataPropertyTags?.forEach((d) => {
      d.dataPropertyUuid = dpUuidMapping[d.dataPropertyUuid];
      d.id = null;
      d.diagramEdgeId = null;
      d.diagramNodeId = null;
      d.fileAttachmentId = null;
    });

    copiedTag.attachments?.forEach((a) => {
      a.dataPropertyUuid = dpUuidMapping[a.dataPropertyUuid];
      a.diagramId = null;
      a.diagramNodeId = null;
      a.diagramEdgeId = null;
      a.id = null;
    });
    return copiedTag;
  }
}
