


















import { Vue, Prop, Component } from 'vue-property-decorator';

@Component({
  name: 'Button',
})
export default class Button extends Vue {
  @Prop({ required: true })
  isActive: boolean;

  @Prop({ required: true })
  icon: string | null;

  @Prop({ required: true })
  label: string | null;

  @Prop({ required: true })
  variantClass: string;

  @Prop({ required: true })
  fullWidth: boolean;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  get buttonClasses() {
    return this.isActive
      ? !this.disabled
        ? this.activeClasses
        : 'disabled:j-border-main-dark-40 j-z-1'
      : 'j-text-main-dark j-border-main-dark-20 hover:j-bg-main-dark-05';
  }

  get activeClasses() {
    return [this.variantClass, 'disabled:j-border-main-dark-10 j-z-1'];
  }

  get widthClasses() {
    return { 'j-w-full': this.fullWidth };
  }

  handleSelect(value) {
    this.$emit('input', value);
  }
}
