import { convertSvgElementToImage } from '@/core/utils/common.utils';
import { GraphComponent, ImageNodeStyle, Size } from 'yfiles';
import ExportOptions from '../ExportOptions';
import ExportUtils from '../ExportUtils';
import IExportResult from './IExportResult';
import IExportProvider from './IExportProvider';
import { PageElementPosition } from '@/api/models';
import LogoAsImageProvider from '../additional-element-providers/LogoAsImgProvider';
import LegendAsImgProvider from '../additional-element-providers/LegendAsImgProvider';
import { ExportPageElementType } from '../ExportPageElementType';
import ExportPage from '../ExportPage';
const vsdxImport = () => import('vsdx-export-for-yfiles');

export default class VisioExportProvider implements IExportProvider {
  private _fileExtension = 'vsdx';

  private _mimeType = 'application/vnd.ms-visio.drawing';

  async exportGraphAsBase64(
    options: ExportOptions,
    graphComponent: GraphComponent
  ): Promise<IExportResult> {
    await this.attachAdditionalElements(graphComponent, options);
    const vsdx = await vsdxImport();
    const vsdxExport = new vsdx.VsdxExport();

    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      result: await vsdxExport.writeBase64(
        graphComponent,
        await this.getExportConfig()
      ),
    };
  }

  async exportGraphAsBlob(
    options: ExportOptions,
    graphComponent: GraphComponent
  ): Promise<IExportResult> {
    await this.attachAdditionalElements(graphComponent, options);
    const vsdx = await vsdxImport();
    const vsdxExport = new vsdx.VsdxExport();

    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      result: await vsdxExport.writeBlob(
        graphComponent,
        await this.getExportConfig()
      ),
    };
  }

  private async getExportConfig() {
    const vsdx = await vsdxImport();
    var config = vsdx.VsdxExportConfiguration.createDefault();
    config.cssStyleSheet = '';
    config.evaluateFormulas = false;
    config.masterProviders.add(new vsdx.SvgProvider());
    return config;
  }

  private async attachAdditionalElements(
    graphComponent: GraphComponent,
    options: ExportOptions
  ) {
    const exportPage: ExportPage = options.metadata.currentPage;
    exportPage.additionalElements = [];
    const logoVisible: boolean =
      options.document.logoPosition != PageElementPosition.Hidden &&
      exportPage.page.showLogo;
    const legendVisible: boolean =
      options.document.legendPosition != PageElementPosition.Hidden &&
      exportPage.page.showLegend;

    if (logoVisible) {
      const logoProvider = new LogoAsImageProvider();
      const additionalElement = await logoProvider.get(options, exportPage);
      exportPage.additionalElements.push(...additionalElement);
    }

    if (legendVisible) {
      const legendProvider = new LegendAsImgProvider();
      const additionalElement = await legendProvider.get(options, exportPage);
      exportPage.additionalElements.push(...additionalElement);
    }

    for (const el of exportPage.additionalElements) {
      const svg = await el.toSvgAsync();
      const image = await convertSvgElementToImage(svg, 'png');
      const position = ExportUtils.getAdditionalElementPosition(
        graphComponent.contentRect,
        el.options.position,
        image.height,
        image.width
      );
      let style = new ImageNodeStyle({ image: image.src });
      let node = graphComponent.graph.createNode({
        layout: {
          x: position.x,
          y: position.y,
          width: image.width,
          height: image.height,
        },
      });
      graphComponent.graph.setStyle(node, style);
    }
  }
}
