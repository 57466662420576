var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"j-w-full"},[_c('div',{staticClass:"j-mb-32"},[_c('h2',{staticClass:"j-text-30 j-mb-14"},[_vm._v("AppTable Base")]),_c('div',{staticClass:"j-mb-14"},[_c('AppButton',{on:{"click":_vm.addMoreData}},[_vm._v("Add more data")]),_c('div',[_vm._v(" Applied filters [obj]: "),_c('pre',[_vm._v(_vm._s(_vm.filters))])]),_c('div',[_vm._v(" Selected row's id [Array]: "),_c('pre',[_vm._v(_vm._s(_vm.selectedRowIds))])])],1),_c('AppTable',{attrs:{"fields":_vm.fields,"items":_vm.items.result.items,"selectable":"","selection":_vm.selectedRowIds,"unique-key-path":"document.id","filters":_vm.filters},on:{"update:selection":function($event){_vm.selectedRowIds=$event},"update:filters":function($event){_vm.filters=$event},"filterChange":_vm.onFiltersChanged},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.document.name)+" ")]}},{key:"creationTime",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"j-flex j-items-center"},[_c('AppUserStack',{attrs:{"users":[data.document.createdBy],"id-key":"id"}}),_c('span',{staticClass:"j-inline-flex j-flex-col j-ml-8"},[_c('span',{staticClass:"j-text-14"},[_vm._v(" "+_vm._s(data.document.createdBy.name)+" "+_vm._s(data.document.createdBy.surname)+" ")]),_c('span',{staticClass:"j-text-base j-text-main-dark-60"},[_vm._v(" "+_vm._s(_vm._f("time")(data.document.creationTime))+" ")])])],1)]}}])})],1),_c('div',{staticClass:"j-mb-32"},[_c('h2',{staticClass:"j-text-30 j-mb-14"},[_vm._v("AppTable Base Custom Header/Body Cells")]),_c('AppTable',{attrs:{"fields":_vm.fields,"items":this.items2.result.items,"unique-key-path":"document.id"},scopedSlots:_vm._u([{key:"header_name",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.label)+" custom")]}},{key:"name",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.document.name)+" ")]}},{key:"creationTime",fn:function(ref){
var data = ref.data;
var hovered = ref.hovered;
var selected = ref.selected;
return [_c('AppUserStack',{attrs:{"users":[data.document.createdBy],"id-key":"id"}}),_vm._v(" | "+_vm._s(hovered)+" | "+_vm._s(selected)+" ")]}}])})],1),_c('div',{staticClass:"j-mb-32"},[_c('h2',{staticClass:"j-text-30 j-mb-14"},[_vm._v("AppTable Empty Items (+ custom slot)")]),_c('AppTable',{attrs:{"fields":_vm.fields,"items":[],"unique-key-path":"document.id","selectable":""}}),_c('AppTable',{attrs:{"fields":_vm.fields,"items":[],"unique-key-path":"document.id","selectable":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" Custom message ")]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }