import { elementToSVG } from '@jigsaw/dom-to-svg';
import {
  LabelStyleBase,
  IRenderContext,
  ILabel,
  SvgVisual,
  Size,
  INode,
  Visual,
  IEdge,
  Rect,
  Point,
  GraphComponent,
  Insets,
} from 'yfiles';
import diagramConfig from '../config/diagram.definition.config';
import BackgroundDomService from '../services/BackgroundDomService';
import DiagramUtils, { ZERO_WIDTH_SPACE } from '../utils/DiagramUtils';
import { findElementParents, stripHtml } from '../utils/html.utils';

const RenderCacheKey = 'RichTextCacheKey';
const RenderContainerElementId = 'rt-label-render-container';
const DebugEnabled = false;
export default class JigsawRichTextLabelStyle extends LabelStyleBase {
  private static cachedSvgElements = {};
  private static cachedTextSizes = {};
  insets: Insets = null;

  constructor() {
    super();
    this.insets = new Insets(0);
  }

  public static clearCache() {
    JigsawRichTextLabelStyle.cachedSvgElements = {};
    JigsawRichTextLabelStyle.cachedTextSizes = {};
  }

  private createDebugVisual(label: ILabel): SVGGElement {
    // create a debug rect;
    const rect1 = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'rect'
    );
    rect1.style.stroke = 'red';
    rect1.style.strokeWidth = '3px';
    rect1.style.fill = 'transparent';

    const size = this.getSize(label);
    rect1.setAttribute('width', `${size.width}px`);
    rect1.setAttribute('height', `${size.height}px`);

    rect1.setAttribute('x', '0');
    rect1.setAttribute('y', '0');

    const rect2 = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'rect'
    );
    rect2.style.stroke = 'green';
    rect2.style.strokeWidth = '1px';
    rect2.style.fill = 'transparent';
    2;
    var rawSize = JigsawRichTextLabelStyle.measureTextRaw(
      label.text,
      size.width
    );
    rect2.setAttribute('width', `${size.width}px`);
    rect2.setAttribute('height', `${rawSize.height}px`);

    rect2.setAttribute('x', '0'); // this.paddingX.toString());
    rect2.setAttribute('y', '0');

    const g = window.document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );

    // g.appendChild(rect1);
    g.appendChild(rect2);
    return g;
  }

  private createOrUpdateRenderCache(
    cacheOwner: any,
    label: ILabel
  ): IRenderCache {
    const renderCache: IRenderCache = cacheOwner[RenderCacheKey];

    let ownerLayout: Rect = null;
    if (INode.isInstance(label.owner)) {
      ownerLayout = label.owner.layout.toRect();
    }
    if (!renderCache) {
      const labelRect = label.layoutParameter.model.getGeometry(
        label,
        label.layoutParameter
      );
      return ((cacheOwner[RenderCacheKey] as IRenderCache) = {
        text: label.text,
        ownerLayout: ownerLayout,
        labelSize: labelRect.toSize(),
        labelAnchor: new Point(labelRect.anchorX, labelRect.anchorY),
        requiresRedraw: false,
      });
    }
    if (renderCache.text != label.text) {
      renderCache.requiresRedraw = true;
    }

    if (
      ownerLayout &&
      !DiagramUtils.areSizeEquals(
        ownerLayout.toSize(),
        renderCache.ownerLayout.toSize()
      )
    ) {
      renderCache.requiresRedraw = true;
    }

    return renderCache;
  }

  createVisual(context: IRenderContext, label: ILabel): SvgVisual {
    if (!label?.text?.trim()) {
      return null;
    }
    let plainText = stripHtml(label.text);
    if (!plainText.trim()) {
      return null;
    }

    // This implementation creates a 'g' element and uses it for the rendering of the label.
    const g = window.document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );
    const svgVisual = new SvgVisual(g);
    const renderCache = this.createOrUpdateRenderCache(svgVisual, label);
    if (DebugEnabled && label.owner instanceof IEdge) {
      var debugElement = this.createDebugVisual(label);
      // Render the label
      g.appendChild(debugElement);
    }
    this.render(label, g, renderCache);
    this.transformElement(label, g, renderCache);

    return svgVisual;
  }

  updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    label: ILabel
  ): Visual {
    const svgVisual = oldVisual as SvgVisual;
    var renderCache = this.createOrUpdateRenderCache(svgVisual, label);
    if (renderCache.requiresRedraw) {
      (context.canvasComponent as GraphComponent).graph.setLabelPreferredSize(
        label,
        this.getSize(label)
      );
      return this.createVisual(context, label);
    }
    this.transformElement(
      label,
      svgVisual.svgElement as SVGElement,
      renderCache
    );

    return oldVisual;
  }

  private transformElement(
    label: ILabel,
    el: SVGElement,
    renderCache: IRenderCache
  ) {
    // const labelSize = renderCache.labelSize;
    // const labelAnchor = renderCache.labelAnchor;
    const t = label.layoutParameter.model.getGeometry(
      label,
      label.layoutParameter
    );

    el.setAttribute(
      'transform',
      `translate(${t.anchorX} ${t.anchorY - t.height})`
    );
  }

  private static getRenderContainer(): HTMLElement {
    let renderContainer = BackgroundDomService.getElementById(
      RenderContainerElementId
    );
    if (renderContainer) {
      renderContainer.style.width = '';
      renderContainer.style.maxWidth = '';
      renderContainer.style.height = '';
      renderContainer.style.maxHeight = '';
      return renderContainer;
    }

    const outerContainer = BackgroundDomService.createElement('div');
    outerContainer.style.position = 'absolute';
    if (DebugEnabled) {
      outerContainer.style.left = '300px';
      outerContainer.style.top = '200px';
      outerContainer.style.zIndex = '999';
      outerContainer.style.border = '1px solid red';
    }

    renderContainer = BackgroundDomService.createElement('div');
    renderContainer.id = RenderContainerElementId;
    renderContainer.classList.add(
      ...['document-page-content', 'diagram-content']
    );
    renderContainer.style.wordBreak = 'break-word';
    //SY: this is added as a workaround for a bug in the dom-to-svg-library
    renderContainer.style.whiteSpace = 'normal';
    outerContainer.appendChild(renderContainer);
    BackgroundDomService.appendElement(outerContainer);

    return renderContainer;
  }

  private render(
    label: ILabel,
    container: Element,
    renderData: IRenderCache
  ): void {
    if (label.text == null || label.text == '') {
      return;
    }

    const svgElement = this.getSvgElement(renderData.labelSize, label);

    if (label.owner instanceof IEdge || label.owner instanceof INode) {
      // apply a white background to edges so they sit on top of the edge properly
      const bgRect = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'rect'
      );
      bgRect.style.fill = 'white';
      bgRect.setAttribute('width', `${renderData.labelSize.width}px`);
      bgRect.setAttribute('height', `${renderData.labelSize.height}px`);

      container.appendChild(bgRect);
    }
    // add the SVG without the styles to the container
    container.appendChild(svgElement);
  }

  private getSvgElement(size: Size, label: ILabel) {
    const cachedSvgElementKey = `${label.text}_${size.width}_${size.height}`;
    const cachedSvgElement = JigsawRichTextLabelStyle.cachedSvgElements[
      cachedSvgElementKey
    ] as SVGElement;
    if (cachedSvgElement) {
      return cachedSvgElement.cloneNode(true);
    }

    const renderContainer = JigsawRichTextLabelStyle.getRenderContainer();
    renderContainer.innerHTML = JigsawRichTextLabelStyle.prepareTextContent(
      label.text
    );
    renderContainer.style.width = size.width + 'px';
    renderContainer.style.height = size.height + 'px';

    // we null this out to prevent the generated SVG having the ID copied, yes elementToSVG also duplicates the ID.
    renderContainer.id = null;

    const svg = elementToSVG(renderContainer);
    renderContainer.id = RenderContainerElementId;

    const svgElement = svg.documentElement as unknown as SVGElement;

    this.fixSvgElementStyles(svg, label);
    // this allows labels that are wider than their container to still be visible
    svgElement.style.overflow = 'visible';

    // elementToSVG will copy all styles from the document into the SVG, including fonts and external bits.
    // this, when added back into the dom causes a flicker as the browser tries to reload these
    // external resources again
    // so we need to remove them

    // find all style elements within the SVG
    var styles = svgElement.querySelectorAll('style');

    // loop them
    styles.forEach((d) => {
      // remove them
      svgElement.removeChild(d);
    });

    JigsawRichTextLabelStyle.cachedSvgElements[cachedSvgElementKey] =
      svgElement.cloneNode(true);

    return svgElement;
  }

  public static prepareTextContent(text: string): string {
    if (!text) {
      return text;
    }
    // We need to wrap all whitespace in spans otherwise in the pdf export they don't get rendered at all
    text = text.replace(
      /(?<!(<\/?[^>]*|&[^;]*))([\s]+)</g,
      '$1<span>$2</span><'
    );
    // Remove all leftover ZWSs
    text = text.replaceAll(ZERO_WIDTH_SPACE, '');
    return text.trim();
  }

  public static measureTextRaw(text: string, maxWidth?: number): Size {
    if (text == null || text == '') {
      return Size.EMPTY;
    }

    const cachedTextSizeKey = `${text}_${maxWidth ?? 0}`;
    const cachedTextSize =
      JigsawRichTextLabelStyle.cachedTextSizes[cachedTextSizeKey];
    if (cachedTextSize) {
      return cachedTextSize;
    }
    let renderContainer = JigsawRichTextLabelStyle.getRenderContainer();
    renderContainer.innerHTML =
      JigsawRichTextLabelStyle.prepareTextContent(text);
    renderContainer.style.maxWidth = maxWidth ? `${maxWidth}px` : '';

    const clientRect = renderContainer.getBoundingClientRect();
    const size = new Size(clientRect.width, clientRect.height);

    JigsawRichTextLabelStyle.cachedTextSizes[cachedTextSizeKey] = size;

    return size;
  }

  private getSizeForNode(label: ILabel): Size {
    if (!(label.owner instanceof INode)) {
      throw 'owner is not an node';
    }

    const maxWidth = DiagramUtils.getNodeLabelMaxWidth(label);

    var rawSize = JigsawRichTextLabelStyle.measureTextRaw(
      label.text,
      maxWidth
    );

    return new Size(
      rawSize.width + this.insets.horizontalInsets,
      rawSize.height + this.insets.verticalInsets
    );
  }

  private getSizeForEdge(label: ILabel): Size {
    if (!(label.owner instanceof IEdge)) {
      throw 'owner is not an edge';
    }
    const htmlEl = JigsawRichTextLabelStyle.measureTextRaw(label.text);
    //window.document.body.appendChild(htmlEl);
    //const clientRect = htmlEl.getBoundingClientRect();
    const size = new Size(
      Math.ceil(htmlEl.width) + this.insets.horizontalInsets,
      Math.ceil(htmlEl.height) + this.insets.verticalInsets
    );

    //window.document.body.removeChild(htmlEl);
    return size;
  }

  private getSize(label: ILabel): Size {
    if (label.owner instanceof INode) {
      return this.getSizeForNode(label);
    } else if (label.owner instanceof IEdge) {
      return this.getSizeForEdge(label);
    }
    throw 'unsupported ILabelOwner';
  }
  isVisible() {
    return true;
  }

  getPreferredSize(label: ILabel): Size {
    return this.getSize(label);
    let renderCache = labelRenderCache[label.owner.tag.uuid] as IRenderCache;
    if (!renderCache) {
      renderCache = this.createOrUpdateRenderCache({}, label);
      labelRenderCache[label.owner.tag.uuid] = renderCache;
    }
    return renderCache.labelSize;
  }

  fixSvgElementStyles(svgDocument: XMLDocument, label: ILabel) {
    const propertyMap = [
      {
        name: 'textDecorationLine',
        attributeName: 'text-decoration',
      },
    ];
    const textElements = svgDocument.querySelectorAll('text');

    for (const textElement of textElements) {
      const style = textElement.style;

      for (const prop of propertyMap) {
        const value = style[prop.name];
        if (value == '') {
          continue;
        }
        textElement.setAttribute(prop.attributeName, value);
      }

      // Apply text decoration attribute based on parent elements
      const textDecorations = [];
      const parents = findElementParents(textElement);
      const hasUnderline = parents.some((p) => p.dataset.tag == 'u');
      const hasStrikethrough = parents.some((p) => p.dataset.tag == 's');
      if (hasUnderline) {
        textDecorations.push('underline');
      }
      if (hasStrikethrough) {
        textDecorations.push('line-through');
      }

      if (textDecorations.length > 0) {
        style.textDecorationLine = textDecorations.join(' ');
        textElement.setAttribute('text-decoration', textDecorations.join(' '));
      }

      // Set baseline-shift values according the label preffered height
      // Used on Pdf export
      if (label.owner instanceof IEdge) {
        let baseLineShift = this.calculateBaseLine(label.preferredSize.height);
        textElement.setAttribute('data-label-baseline-shift', baseLineShift);
      }
      // Set node line type
      if (label.owner instanceof INode) {
        textElement.setAttribute('data-label-baseline-shift', '5');
      }
    }

    // needed for visio export
    // seems like during the export vsdx if see "stroke" attribute always render it (even if stroke-width is 0)
    const rootBackgroundAndBordersElements = svgDocument.querySelectorAll(
      '[data-stacking-layer="rootBackgroundAndBorders"]'
    );
    for (const el of rootBackgroundAndBordersElements) {
      const rect = el.firstElementChild;

      if (rect) {
        const strokeWidth = rect.getAttribute('stroke-width');

        if (strokeWidth === '0px' || strokeWidth === '0') {
          rect.removeAttribute('stroke');
          rect.removeAttribute('stroke-width');
        }
      }
    }
  }

  calculateBaseLine(height: number) {
    if (height >= 18 && height <= 20) {
      return '3';
    }
    if (height >= 22 && height <= 23) {
      return '4';
    }
    if (height >= 25 && height <= 26) {
      return '5';
    }
    if (height >= 30 && height <= 33) {
      return '6';
    }
    if (height >= 37 && height <= 42) {
      return '7';
    }
    return '5';
  }
}

const labelRenderCache = {};
class IRenderCache {
  text: string;
  ownerLayout: Rect;
  labelSize: Size;
  labelAnchor: Point;
  requiresRedraw: boolean;
}
