import svgData from './SvgElementData';
import { IElementDataProvider } from './IElementDataProvider';
import PaletteItem from '../PaletteItem';
import SvgPaletteBehaviour from '../SvgPaletteBehaviour';
import ElementSvgRenderUtils from '@/core/utils/ElementSvgRenderUtils';

export default class SvgElementDataProvider implements IElementDataProvider {
  getElements(): PaletteItem[] {
    return svgData.map<PaletteItem>((e) => {
      return {
        category: e.Category,
        subcategory: e.Subcategory,
        img: e.ImagePath,
        data: e,
        text: e.Name,
        id: e.Id,
        behaviour: SvgPaletteBehaviour.INSTANCE,
        canDrag: true,
      };
    });
  }
}
