var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"j-relative j-inline-flex",class:{ 'j-w-full': _vm.fullWidth }},[_c('div',{staticClass:"select__main j-material-easing",class:[
      {
        'is-disabled': _vm.disabled,
        'is-open': _vm.isOpen,
        'j-max-h-40': _vm.fixedHeight,
        'j-w-full': _vm.fullWidth,
      } ],style:({
      minWidth: (_vm.minWidth + "px")
    }),attrs:{"tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.toggle.apply(null, arguments)},"click":_vm.toggle}},[_vm._t("selection",function(){return [_vm._v(" "+_vm._s(_vm.selectedValue || _vm.placeholder)+" ")]},{"value":_vm.rawValue,"hasInitialValue":_vm.hasInitialValue,"setSelection":_vm.setSelection}),_c('AppIcon',{staticClass:"j-absolute j-right-8 j-transform j-material-easing",class:_vm.isOpen ? 'j-rotate-180' : 'j-rotate-0',attrs:{"name":"arrow-drop-down","size":"20"}})],2),_c('transition',{attrs:{"enter-active-class":"j-transform j-transition j-duration-200 j-material-easing","enter-class":"j-opacity-0 j-translate-y-10","enter-to-class":"j-opacity-100 j-translate-y-0","leave-active-class":"j-transform j-transition j-duration-100 j-material-easing","leave-class":"j-opacity-100 j-translate-y-0","leave-to-class":"j-opacity-0 j-translate-y-10"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"select__list"},[_vm._l((_vm.options),function(item){return [_c('Compute',{attrs:{"isSelected":_vm.isSelected(item),"isDisabled":_vm.isDisabled(item)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var isSelected = ref.isSelected;
    var isDisabled = ref.isDisabled;
return [_c('li',{key:item.label,staticClass:"select__item",class:{
              'is-selected': isSelected,
              'is-disabled': isDisabled,
            },attrs:{"tabindex":isDisabled ? -1 : 0},on:{"click":function($event){isDisabled ? $event.stopPropagation() : _vm.setSelection(item)}}},[_vm._t("options",function(){return [_vm._v(" "+_vm._s(item[_vm.labelKey])+" ")]},{"option":item,"isSelected":isSelected,"isDisabled":isDisabled})],2)]}}],null,true)})]})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }