import Vue from 'vue';
import ExportOptions from '../ExportOptions';
import ExportPageElement from '../ExportPageElement';
import IAdditionalElementProvider from './IAdditionalElementProvider';
import store from '../../store';
import { DiagramDto, DocumentPageType } from '@/api/models';
import ExportPage from '../ExportPage';
import { DiagramSize } from '@/view/pages/document/DiagramSize';
import BackgroundDomService from '../../BackgroundDomService';
import ILegendDefinition from '@/components/DiagramLegend/ILegendDefinition';

// IMPORTANT: DiagramLegend has to be lazy-loaded, otherwise it would break the dependency chain
const diagramLegendImport = () =>
  import('@/components/DiagramLegend/DiagramLegend.vue');

export default class LegendAsImgProvider implements IAdditionalElementProvider {
  async get(
    options: ExportOptions,
    exportPage: ExportPage
  ): Promise<ExportPageElement[]> {
    const exportElements: ExportPageElement[] = [];
    // Get all diagrams on the current page
    const diagrams: DiagramDto[] = [];
    if (exportPage.page.diagram) {
      diagrams.push(exportPage.page.diagram);
    }
    if (exportPage.page.subPageRefs) {
      for (const ref of exportPage.page.subPageRefs) {
        diagrams.push(ref.diagram);
      }
    }

    for (const diagram of diagrams) {
      let diagramSize = DiagramSize.Large;

      const filteredDiagram = { ...diagram };
      if (filteredDiagram.legend && options.withFilters) {
        const legendDefinition = <ILegendDefinition>(
          JSON.parse(filteredDiagram.legend)
        );
        legendDefinition.items = legendDefinition.items.filter(
          (x) => !x.isFiltered
        );
        filteredDiagram.legend = JSON.stringify(legendDefinition);
      }

      // define diagramSize scale
      if (options.document.hasSteps) {
        diagramSize =
          exportPage.page.pageType == DocumentPageType.Diagram
            ? DiagramSize.Medium
            : DiagramSize.Small;
      }

      // Dynamically instantiate DiagramLegend Vuejs component
      const diagramLegend = await diagramLegendImport();
      const diagramLegendClass = Vue.extend(diagramLegend.default);
      const diagramLegendInstance = new diagramLegendClass({
        store: store,
        propsData: {
          diagram: filteredDiagram,
          isReadOnly: true,
          diagramSize,
          isExport: true,
        },
      }) as Vue;

      // Init legend & load definition from page.diagram
      (<any>diagramLegendInstance).init();
      diagramLegendInstance.$mount();

      // Add legend to document to render & apply styles
      const diagramLegendContainer = BackgroundDomService.createElement('div');
      diagramLegendContainer.style.position = 'absolute';
      diagramLegendContainer.append(diagramLegendInstance.$el);
      BackgroundDomService.appendElement(diagramLegendContainer);

      const convertSymbolsToImages = true; // !options.print && !options.download;
      const diagramLegendExport: ExportPageElement = (<any>(
        diagramLegendInstance
      )).export(diagram.id, convertSymbolsToImages);
      exportElements.push(diagramLegendExport);
      diagramLegendExport.destroy = () => {
        diagramLegendContainer.remove();
      };
      diagramLegendInstance.$destroy();
    }

    return Promise.resolve(exportElements);
  }
}
