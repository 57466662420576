import Insets from './Insets';
import Point from './Point';
import Size from './Size';

export default class Rect {
  public static get EMPTY() {
    return new Rect(0, 0, 0, 0);
  }
  constructor(
    public x: number,
    public y: number,
    public width: number,
    public height: number
  ) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }

  get center() {
    return { x: this.x + this.width / 2, y: this.y + this.height / 2 };
  }

  get maxY() {
    return this.y + this.height;
  }

  get maxX() {
    return this.x + this.width;
  }

  public equal(layout: Rect): boolean {
    const x = layout.x === this.x;
    const y = layout.y === this.y;
    const width = layout.width === this.width;
    const height = layout.height === this.height;

    return x && y && width && height;
  }

  contains(location: Point, insets?: Insets) {
    const rect = this.getReduced(insets);

    return (
      location.x >= rect.x &&
      location.x <= rect.maxX &&
      location.y >= rect.y &&
      location.y <= rect.maxY
    );
  }

  private getReduced(insets: Insets) {
    if (!insets) {
      return this.clone();
    }
    const rect = this.clone();
    rect.x += insets.left;
    rect.width -= insets.right + insets.left;
    rect.y += insets.bottom;
    rect.height -= insets.top + insets.bottom;
    return rect;
  }

  public clone(): Rect {
    return new Rect(this.x, this.y, this.width, this.height);
  }
  public constrainTo(parent: Rect): Rect {
    let x = this.x;
    let y = this.y;
    if (this.x < parent.x) {
      x = parent.x;
    } else if (this.maxX > parent.width) {
      x = parent.width - this.width;
    }

    if (this.y < parent.y) {
      y = parent.y;
    } else if (this.maxY > parent.height) {
      y = parent.height - this.height;
    }

    return new Rect(x, y, this.width, this.height);
  }

  public toSize(): Size {
    return new Size(this.width, this.height);
  }

  public static fromHtmlElement(element: HTMLElement): Rect {
    return new Rect(
      element.offsetLeft,
      element.offsetTop,
      element.clientWidth,
      element.clientHeight
    );
  }
}
