






























































































































import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'Notification',
})
export default class Notification extends Vue {
  notify1() {
    this.$notify({
      title: 'Notification Text',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum, veniam suscipit corporis a molestias, accusantium hic quod doloribus possimus sit ipsa dolores vitae reprehenderit, expedita error architecto eum. Magni, perspiciatis.',
    });
  }

  notify2() {
    this.$notify({
      title: 'Document created',
      type: 'success',
    });
  }

  notify3() {
    this.$notify({
      title: 'Error creating page',
      type: 'error',
    });
  }

  notify4() {
    this.$notify({
      title:
        'To make manual edits to your diagram, please first disable quick start.',
      type: 'warning',
    });
  }
}
