import ExportConfig from '@/core/config/ExportConfig';
import {
  convertUrlToDataUrl,
  ensureFullUri,
  getImageSize,
  scaleAndCropToBounds,
} from '@/core/utils/common.utils';
import { ImageMeta, TextMeta } from './Components';
import IItem from './IItem';
import Size from '@/core/common/Size';
import Rect from '@/core/common/Rect';

export default class ElementSerializer {
  public static serializeToJson(items: IItem[]): string {
    const json = JSON.stringify(items, (key, value) => {
      if (key == 'element') {
        return null;
      }
      return value;
    });
    return json;
  }

  public static deserializeFromJson(json: string): IItem[] {
    if (!json) {
      return [];
    }
    const items = JSON.parse(json) as IItem[];
    items.forEach((e) => {
      const layout = Rect.EMPTY;
      e.layout = Object.assign(layout, e.layout);
    });
    return items;
  }

  public static async serializeToHtml(items: IItem[]): Promise<string> {
    let container = document.createElement('div');
    for (let item of items) {
      let element: HTMLElement = null;
      let size = new Size(item.layout.width, item.layout.height);
      switch (item.type) {
        case 'ImageElement':
          const imgUrl = ensureFullUri((item.meta as ImageMeta).imageSrc);
          const img = document.createElement('img');
          img.src = await convertUrlToDataUrl(imgUrl);
          const imageSize = await getImageSize(imgUrl);
          size = scaleAndCropToBounds(imageSize, ExportConfig.pageSize);
          element = img;
          break;

        case 'TextElement':
          const div = document.createElement('div');
          div.innerHTML = (item.meta as TextMeta).html;
          element = div;

          break;
      }

      element.style.position = 'absolute';
      element.style.left = item.layout.x + 'px';
      element.style.top = item.layout.y + 'px';
      element.style.width = size.width + 'px';
      element.style.height = size.height + 'px';
      container.appendChild(element);
    }
    return container.outerHTML;
  }
}
