import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/core/services/events/eventbus.service';
@Component({})
export default class EventBusMixin extends Vue {
  eventBusActionsHandlers: Array<{ action: string; method: Function }> = [];

  eventBusAction(action: string, method: Function) {
    this.eventBusActionsHandlers.push({ action, method });
  }
  eventBusEmit(action: string, payload?: any) {
    console.debug(`[EMIT] E-BUS: ${action} ${payload}`);
    EventBus.$emit(action, payload);
  }

  mounted() {
    if (!this.hasHandlers()) {
      return;
    }

    this.eventBusActionsHandlers.forEach((handler) => {
      console.debug(`[ON] E-BUS: ${handler.action}`);
      EventBus.$on(handler.action, handler.method);
    });
  }

  destroyed() {
    if (!this.hasHandlers()) {
      return;
    }
    if (this.eventBusActionsHandlers.length > 0)
      console.debug('EventBusMixin Destroy');
    this.eventBusActionsHandlers.forEach((handler) => {
      console.debug(`[OFF] E-BUS: ${handler.action}`);
      EventBus.$off(handler.action, handler.method);
    });
  }

  private hasHandlers() {
    return (
      this.eventBusActionsHandlers &&
      this.eventBusActionsHandlers.length &&
      this.eventBusActionsHandlers.length > 0
    );
  }
}
