// This needs to be kept in sync with styles for the document page content (see _page-content.scss) and pdf export (see PdfStyles.ts) and ThumbnailBuilder.ts
// IMPORTANT: All sizes are in points (pt), 1px = 0.75pt
export default `
body {
  margin: 0;
  line-height: normal;
  font-size: 12pt;
  font-family: Arial, sans-serif;
  overflow-wrap: break-word;
}
.image {
  margin: 0;
}
table td, table td * {
  vertical-align: top;
}
figure {
  margin: 0;
}
figure > table td:nth-child(1) {
  padding-right: 10px;
}
.two-column-layout {
  column-count: 2;
  column-fill: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: unset;
  margin: 0;
  margin-bottom: 5pt;
  line-height: normal;
}
h1 {
  font-size: 24pt;
}
h2 {
  font-size: 22pt;
}
h3 {
  font-size: 20pt;
}
h4 {
  font-size: 18pt;
}
h5 {
  font-size: 16pt;
}
h6 {
  font-size: 14pt;
}
p {
  margin: 0;
  margin-bottom: 14pt;
  margin-top: 0 !important;
  break-inside: avoid !important;
}
ul, ol {
  margin: 0;
  margin-bottom: 5pt;
}
li {
  margin: 0;
  margin-left: 5pt;
}
table {
  margin: 0;
  margin-bottom: 5px;
  width: 100%;
}
i {
  font-size: unset;
  color: unset;
}
`;
