import { IMatcher } from '../matchers/IMatcher';
import ISearchParameter from '../parameters/ISearchParameter';
import ISpecification from './ISpecification';

export default class MatchSpecification<TParameter extends ISearchParameter>
  implements ISpecification {
  private matchers: IMatcher[];
  private parameter: TParameter;

  constructor(matchers: IMatcher[], parameter: TParameter) {
    this.matchers = matchers;
    this.parameter = parameter;
  }

  public isSatisfiedBy(candidate: any): boolean {
    let matchCount = 0;
    const someMatched = this.matchers.some((m) => {
      if (m.canMatch(candidate, this.parameter)) {
        matchCount++;
        return m.isMatch(candidate, this.parameter);
      } else {
        return false;
      }
    });
    return matchCount > 0 && someMatched;
  }
}
