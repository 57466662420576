import SessionApiService from '@/api/SessionApiService';
import appConfig from '@/core/config/appConfig';
// action types
export const GET_TENANT_CONFIG = 'getTenantConfig';
export const SET_TENANT_CONFIG = 'setTenantConfig';
export const LOAD_TENANT_CONFIG = 'loadTenantConfig';

export default {
  state: {
    config: {},
  },
  getters: {
    [GET_TENANT_CONFIG](state) {
      return state.config;
    },
  },
  actions: {
    [LOAD_TENANT_CONFIG](context) {
      SessionApiService.getCurrentLoginInformations().then((response) => {
        let config = response.data.result;
        if (config.tenant != null) {
          config.tenant['logoUrl'] =
            new URL(appConfig.apiBaseUrl, document.baseURI).origin +
            '/TenantCustomization/GetLogo?tenantId=' +
            config.tenant.id;
          context.commit(SET_TENANT_CONFIG, config);
        }
      });
    },
  },
  mutations: {
    [SET_TENANT_CONFIG](state, payload) {
      state.config = payload;
    },
  },
};
