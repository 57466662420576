import axios, { AxiosResponse } from 'axios';
import {
  DataPropertyDefinitionItemDto,
  ZadarResponse,
  CreateOrEditDataPropertyDefinitionItemDto,
  FileAttachmentDto,
} from './models';
export default class DataPropertyDefinitionItemApiService {
  public static getAll(clientConfig?: {
    ignoreError?: boolean;
  }): Promise<AxiosResponse<ZadarResponse<DataPropertyDefinitionItemDto[]>>> {
    return axios.request(<any>{
      url: `/api/services/app/DataPropertyDefinitionItem/GetAll`,
      method: 'get',
      clientConfig: clientConfig,
    });
  }
  public static getdataPropertyDefinitionItemForView(
    params?: {
      id?: number;
    },
    clientConfig?: {
      ignoreError?: boolean;
    }
  ): Promise<AxiosResponse<ZadarResponse<DataPropertyDefinitionItemDto>>> {
    return axios.request(<any>{
      url: `/api/services/app/DataPropertyDefinitionItem/GetdataPropertyDefinitionItemForView`,
      method: 'get',
      params: params,
      clientConfig: clientConfig,
    });
  }
  public static getdataPropertyDefinitionItemForEdit(
    params?: {
      id?: number;
    },
    clientConfig?: {
      ignoreError?: boolean;
    }
  ): Promise<
    AxiosResponse<ZadarResponse<CreateOrEditDataPropertyDefinitionItemDto>>
  > {
    return axios.request(<any>{
      url: `/api/services/app/DataPropertyDefinitionItem/GetdataPropertyDefinitionItemForEdit`,
      method: 'get',
      params: params,
      clientConfig: clientConfig,
    });
  }
  public static createOrEdit(
    body: CreateOrEditDataPropertyDefinitionItemDto,
    clientConfig?: {
      ignoreError?: boolean;
    }
  ): Promise<AxiosResponse<any>> {
    return axios.request(<any>{
      url: `/api/services/app/DataPropertyDefinitionItem/CreateOrEdit`,
      method: 'post',
      data: body,
      clientConfig: clientConfig,
    });
  }
  public static create(
    body: CreateOrEditDataPropertyDefinitionItemDto,
    clientConfig?: {
      ignoreError?: boolean;
    }
  ): Promise<AxiosResponse<ZadarResponse<number>>> {
    return axios.request(<any>{
      url: `/api/services/app/DataPropertyDefinitionItem/Create`,
      method: 'post',
      data: body,
      clientConfig: clientConfig,
    });
  }
  public static createPersonFromAttachment(
    body: FileAttachmentDto,
    clientConfig?: {
      ignoreError?: boolean;
    }
  ): Promise<AxiosResponse<ZadarResponse<number>>> {
    return axios.request(<any>{
      url: `/api/services/app/DataPropertyDefinitionItem/CreatePersonFromAttachment`,
      method: 'post',
      data: body,
      clientConfig: clientConfig,
    });
  }
  public static createPeopleFromAttachments(
    body?: FileAttachmentDto[],
    clientConfig?: {
      ignoreError?: boolean;
    }
  ): Promise<AxiosResponse<any>> {
    return axios.request(<any>{
      url: `/api/services/app/DataPropertyDefinitionItem/CreatePeopleFromAttachments`,
      method: 'post',
      data: body,
      clientConfig: clientConfig,
    });
  }

  public static update(
    body: CreateOrEditDataPropertyDefinitionItemDto,
    clientConfig?: {
      ignoreError?: boolean;
    }
  ): Promise<AxiosResponse<any>> {
    return axios.request(<any>{
      url: `/api/services/app/DataPropertyDefinitionItem/Update`,
      method: 'put',
      data: body,
      clientConfig: clientConfig,
    });
  }
  public static delete(
    params?: {
      id?: number;
    },
    clientConfig?: {
      ignoreError?: boolean;
    }
  ): Promise<AxiosResponse<any>> {
    return axios.request(<any>{
      url: `/api/services/app/DataPropertyDefinitionItem/Delete`,
      method: 'delete',
      params: params,
      clientConfig: clientConfig,
    });
  }
}
