import LanguageApiService from '@/api/LanguageApiService';
import i18n from '@/core/plugins/vue-i18n';
import MomentLocalization from '../utils/MomentLocalizationUtils';

const i18nService = {
  defaultLanguage: 'en-GB',
  languages: [
    {
      name: 'English',
      displayName: 'English',
      icon: 'famfamfam-flag-gb',
      isDisabled: false,
    },
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   * @param saveToLangLocale
   */
  setActiveLanguage(lang, saveToLangLocale = true) {
    if (!lang) {
      lang = this.defaultLanguage;
      console.warn('No language specified, use default');
    }
    if (saveToLangLocale) {
      i18n.locale = lang;
    }
    localStorage.setItem('language', lang);
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */

  getActiveLanguage() {
    return localStorage.getItem('language') || this.defaultLanguage;
  },

  /**
   * Setts app language, get a translation for chosen language and manage it
   * contains all available languages that might be switched to
   * (need to be initialized before the app mount)
   *
   * @param {string | null} userLanguage selected user language (in UserProfile DTO)
   */
  async init() {
    //Get and map available languages for language selection menus
    const languages = await LanguageApiService.getLanguages();
    const result = languages.data.result;
    this.languages = result.items
      .filter((l) => !l.isDisabled)
      .map((x) => {
        return {
          name: x.name,
          displayName: x.displayName,
          icon: x.icon,
          isDisabled: x.isDisabled,
        };
      });
    this.defaultLanguage = result.defaultLanguageName;

    //Set language and get translations from backend. Set i18n active language.
    const targetLanguage = this.getActiveLanguage();

    const languageResponse = await LanguageApiService.getTranslations({
      targetCulture: targetLanguage,
    });
    let messages = languageResponse.data.result as any;
    i18n.setLocaleMessage(i18n.locale, messages);
    i18nService.setActiveLanguage(targetLanguage);
    //Set localisation for moment js
    MomentLocalization.setMomentLocaleAndFormat(
      i18nService.getActiveLanguage().toString()
    );
  },
};

export default i18nService;
