import _ from 'lodash';
import {
  ILabelModel,
  ILabelModelParameterProvider,
  ILabelModelParameterFinder,
  Class,
  ILabel,
  ILabelModelParameter,
  IOrientedRectangle,
  Point,
  OrientedRectangle,
  ILookup,
  IEnumerable,
  List,
  BaseClass,
  IEdge,
  IPoint,
  PathType,
} from 'yfiles';
import JigsawEdgeLabelModelParameter from './JigsawEdgeLabelModelParameter';

export default class JigsawEdgeLabelModel extends BaseClass<
  ILabelModel,
  ILabelModelParameterProvider,
  ILabelModelParameterFinder
>(ILabelModel, ILabelModelParameterProvider, ILabelModelParameterFinder) {
  /**
   * Only comes into effect when enableSnapping is false
   */
  private maxDistance: number = 25;

  /**
   * Defines at what distance from the edge the label should snap to @property snapDistance
   */
  private snapThreshold = 15;

  /**
   * If the @property snapThreshold has been reached, then then this value will be used for snapping
   */
  private snapDistance = 20;

  /**
   * Enables snapping along three 'tracks', disable this to allow free placement within @property maxDistance
   */
  private enableSnapping = true;

  /**
   * Returns instances of the support interfaces (which are actually the model instance itself)
   */
  lookup(type: Class): Object | null {
    if (type === ILabelModelParameterProvider.$class) {
      // If we request a ILabelModelParameterProvider AND we use discrete label candidates, we return the label model
      // itself, otherwise, null is returned, which means that continuous label positions are supported.
      return this;
    } else if (type === ILabelModelParameterFinder.$class) {
      // If we request a ILabelModelParameterProvider, we return the label model itself, so we can always retrieve a
      // matching parameter for a given actual position.
      return this;
    }
    return null;
  }

  getGeometry(
    label: ILabel,
    layoutParameter: ILabelModelParameter
  ): IOrientedRectangle {
    const labelSize = label.preferredSize;
    if (!(layoutParameter instanceof JigsawEdgeLabelModelParameter)) {
      throw 'layoutParameter must be of type JigsawExteriorNodeLabelModelParameter';
    }
    if (!(label.owner instanceof IEdge)) {
      throw 'Label owner must be IEdge';
    }
    const points = this.getEdgePoints(label.owner);
    const labelPosition = this.getLabelPosition(
      label,
      points,
      layoutParameter.ratio,
      layoutParameter.segmentIndex,
      layoutParameter.left,
      layoutParameter.distance
    );

    return new OrientedRectangle(
      labelPosition.toMutablePoint(),
      labelSize.toMutableSize()
    );
  }

  /**
   *
   * @param label The label for which we are calculating the position
   * @param points The points along the label's edge, ports, bends etc, this is used to break up the edge into segments
   * @param ratio The ratio along the segmentIndex where the label should be placed
   * @param segmentIndex The segment index
   * @param left Which side of the edge the label is placed
   * @param distance The distance from the edge at which the label should be placed
   * @returns
   */

  private getLabelPosition(
    label: ILabel,
    points: IPoint[],
    ratio: number,
    segmentIndex: number,
    left: boolean,
    distance: number
  ): Point {
    const labelSize = label.preferredSize;

    if (!(label.owner instanceof IEdge)) {
      throw 'Label owner must be IEdge';
    }

    // the segment no longer exists, fallback to 0
    if (segmentIndex >= points.length - 1) {
      segmentIndex = 0;
    }
    // The start of the segment
    const start = points[segmentIndex];
    // The end of the segment
    const end = points[segmentIndex + 1];

    // difference between x/y coordinates for stand and end
    const xDiff = end.x - start.x;
    const yDiff = end.y - start.y;

    // if the label is on the "left" of the line, then is distance should be flipped to a negative value
    distance = left ? distance * -1 : distance;

    //clamp the distance  between the negative and positive maxDistance
    distance = _.clamp(distance, this.maxDistance * -1, this.maxDistance);

    // calculate the angle of the line in degrees 0-360
    let angle =
      ((((-(Math.atan2(start.x - end.x, start.y - end.y) * (180 / Math.PI)) %
        360) +
        360) %
        360) *
        Math.PI) /
      180;

    // calculate a position along the edge using ratio
    const vectorX = start.x + xDiff * ratio - labelSize.width / 2;
    const vectorY = start.y + yDiff * ratio + labelSize.height / 2;

    // apply  a distance from the line at the correct angle to generate a point
    const midPointX = vectorX + distance * Math.cos(angle);
    const midPointY = vectorY + distance * Math.sin(angle);
    return new Point(midPointX, midPointY);
  }

  createDefaultParameter(): ILabelModelParameter {
    return this.createParameterForSegment(0, 0.5, 0, false);
  }

  createParameterForSegment(
    segmentIndex: number,
    ratio: number,
    distance: number,
    left: boolean
  ): JigsawEdgeLabelModelParameter {
    return new JigsawEdgeLabelModelParameter(this, {
      ratio: ratio,
      segmentIndex: segmentIndex,
      distance: distance,
      left: left,
    });
  }

  getContext(label: ILabel, parameter: ILabelModelParameter): ILookup {
    return ILookup.EMPTY;
  }

  getParameters(
    label: ILabel,
    model: ILabelModel
  ): IEnumerable<ILabelModelParameter> {
    return new List<ILabelModelParameter>();
  }

  private getEdgePoints(edge: IEdge): IPoint[] {
    const bends = [];
    const path = edge.style.renderer
      .getPathGeometry(edge, edge.style)
      .getPath();
    const cursor = path.createCursor();
    while (cursor.moveNext()) {
      switch (cursor.pathType) {
        case PathType.MOVE_TO:
        case PathType.LINE_TO:
          bends.push(cursor.currentEndPoint);
      }
    }

    return [edge.sourcePort.location, ...bends, edge.targetPort.location];
  }

  findBestParameter(
    label: ILabel,
    model: ILabelModel,
    layout: IOrientedRectangle
  ): ILabelModelParameter {
    if (!(label.owner instanceof IEdge)) {
      throw 'Label owner must be IEdge';
    }
    const points = this.getEdgePoints(label.owner);
    const totalSegments = points.length - 1;

    let closestSegmentIndex = 0;
    let distance = Number.MAX_SAFE_INTEGER;
    let closestSegmentStart = null;
    let closestSegmentEnd = null;
    const labelSize = label.layout.toSize();

    // Create a new point from the layout X/Y, then append have the label width so it't doesn't jump
    // and stays anchored to the cursor
    // comment the .add to see the behavior we're preventing.
    let point = new Point(layout.anchorX, layout.anchorY).add(
      new Point(labelSize.width / 2, -labelSize.height / 2)
    );

    for (let index = 0; index < totalSegments; index++) {
      const segmentStart = points[index];
      const segmentEnd = points[index + 1];
      const distanceToSegment = this.getDistanceToSegment(
        point.x,
        point.y,
        segmentStart.x,
        segmentStart.y,
        segmentEnd.x,
        segmentEnd.y
      );

      if (distanceToSegment < distance) {
        distance = distanceToSegment;
        closestSegmentIndex = index;
        closestSegmentStart = segmentStart;
        closestSegmentEnd = segmentEnd;
      }
    }
    let ratioInfo = this.getSegmentRatioInfo(
      new Point(point.x, point.y),
      closestSegmentStart,
      closestSegmentEnd
    );
    const { min, max } = this.minMaxRatio();
    return new JigsawEdgeLabelModelParameter(this, {
      segmentIndex: closestSegmentIndex,
      ratio: _.clamp(ratioInfo.ratio, min, max),
      distance: this.getSnapDistance(distance),
      left: ratioInfo.left,
    });
  }

  private getSnapDistance(distance: number): number {
    if (!this.enableSnapping) {
      return distance;
    }
    return distance > this.snapThreshold ? this.snapDistance : 0;
  }

  private getSegmentRatioInfo(
    point: IPoint,
    segmentA: IPoint,
    segmentB: IPoint
  ): IRatioInfo {
    const atob = { x: segmentB.x - segmentA.x, y: segmentB.y - segmentA.y };
    const atop = { x: point.x - segmentA.x, y: point.y - segmentA.y };
    const len = atob.x * atob.x + atob.y * atob.y;
    let dot = atop.x * atob.x + atop.y * atob.y;
    const t = Math.min(1, Math.max(0, dot / len));
    dot =
      (segmentB.x - segmentA.x) * (point.y - segmentA.y) -
      (segmentB.y - segmentA.y) * (point.x - segmentA.x);

    return {
      point: {
        x: segmentA.x + atob.x * t,
        y: segmentA.y + atob.y * t,
      },
      left: dot < 1,
      dot: dot,
      ratio: t,
    };
  }

  private getDistanceToSegment(
    x: number,
    y: number,
    x1: number,
    y1: number,
    x2: number,
    y2: number
  ): number {
    const xDiff = x - x1;
    const yDiff = y - y1;
    const segmentXDiff = x2 - x1;
    const segmentYDiff = y2 - y1;

    const dot = xDiff * segmentXDiff + yDiff * segmentYDiff;
    const lengthSquared =
      segmentXDiff * segmentXDiff + segmentYDiff * segmentYDiff;
    let p = -1;
    if (lengthSquared != 0) {
      p = dot / lengthSquared;
    }

    let xx: number = 0;
    let yy: number = 0;

    if (p < 0) {
      xx = x1;
      yy = y1;
    } else if (p > 1) {
      xx = x2;
      yy = y2;
    } else {
      xx = x1 + p * segmentXDiff;
      yy = y1 + p * segmentYDiff;
    }

    const dx = x - xx;
    const dy = y - yy;
    return Math.sqrt(dx * dx + dy * dy);
  }

  private minMaxRatio(): { min: number; max: number } {
    let min = 0;
    let max = 1;
    return { min, max };
  }
}
interface IRatioInfo {
  point: {
    x: number;
    y: number;
  };
  left: boolean;
  dot: number;
  ratio: number;
}
