import {
  DataPropertyDefinitionDto,
  DataPropertyDefinitionItemDto,
} from '@/api/models';
import {
  INode,
  IEdge,
  ShapeNodeStyle,
  PolylineEdgeStyle,
  BezierEdgeStyle,
  Stroke,
  IArrow,
  INodeStyle,
  IEdgeStyle,
  Insets,
  ArcEdgeStyle,
  ImageNodeStyle,
} from 'yfiles';
import appConsts from '../config/appConsts';
import DataPropertyStyleService from '../services/graph/DataPropertyStyleService';
import CompositeNodeStyle, {
  StyleDefinition,
} from '../styles/composite/CompositeNodeStyle';
import JigsawNodeStyle from '../styles/JigsawNodeStyle';
import DataPropertyUtils from './DataPropertyUtils';

export default class GraphElementsComparer {
  public static nodesEqual(nodeA: INode, nodeB: INode): boolean {
    // Check if node entities are different
    if (nodeA.tag.name?.toLowerCase() !== nodeB.tag.name?.toLowerCase()) {
      return false;
    }

    // if one is a group node and the other isn't
    if (nodeA.tag.isGroupNode != nodeB.tag.isGroupNode) {
      return false;
    }

    // both sides are group nodes and both share the same group id
    if (
      nodeA.tag.isGroupNode &&
      nodeB.tag.isGroupNode &&
      nodeA.tag.groupUuid == nodeB.tag.groupUuid
    ) {
      return true;
    }
    // both group nodes, but not the same group
    if (
      nodeA.tag.isGroupNode &&
      nodeB.tag.isGroupNode &&
      nodeA.tag.groupUuid !== nodeB.tag.groupUuid
    ) {
      return false;
    }
    // Check if both nodes are annotations with the same name
    if (
      nodeA.tag.isAnnotation &&
      nodeB.tag.isAnnotation &&
      nodeA.tag.annotationType == nodeB.tag.annotationType &&
      nodeA.tag.name &&
      nodeB.tag.name &&
      nodeA.tag.name == nodeB.tag.name
    ) {
      return true;
    }
    // Check if both nodes have a style decorator, otherwise fallback to not equal
    if (
      !(nodeA.style instanceof JigsawNodeStyle) ||
      !(nodeB.style instanceof JigsawNodeStyle)
    ) {
      return false;
    }

    // Check if both nodes have same state of data property style
    let styleIsActiveA = nodeA.tag?.dataPropertyStyle?.isActive;
    let styleIsActiveB = nodeB.tag?.dataPropertyStyle?.isActive;
    if (
      styleIsActiveA !== styleIsActiveB &&
      styleIsActiveA != null &&
      styleIsActiveB != null
    ) {
      return false;
    }

    // If both node have activated data property style - check if their data properties are equal
    if (
      DataPropertyStyleService.isDataPropertyStyleActive(nodeA) &&
      DataPropertyStyleService.isDataPropertyStyleActive(nodeB)
    ) {
      if (!this.nodeDataPropertiesEqual(nodeA, nodeB)) {
        return false;
      }
    }

    // Check if node styles are different
    return this.nodeStylesEqual(nodeA.style.baseStyle, nodeB.style.baseStyle);
  }

  public static nodeDataPropertiesEqual(nodeA: INode, nodeB: INode): boolean {
    let dataPropertyA = DataPropertyUtils.getDataPropertyFromNode(
      nodeA,
      appConsts.JURISDICTION_DEFINITION_ID
    );
    let dataPropertyB = DataPropertyUtils.getDataPropertyFromNode(
      nodeB,
      appConsts.JURISDICTION_DEFINITION_ID
    );

    return dataPropertyA?.value === dataPropertyB?.value;
  }

  public static nodeStylesEqual(
    styleA: INodeStyle,
    styleB: INodeStyle
  ): boolean {
    if (styleA instanceof ShapeNodeStyle && styleB instanceof ShapeNodeStyle) {
      return GraphElementsComparer.shapeNodeStyleAreEqual(styleA, styleB);
    } else if (
      styleA instanceof ImageNodeStyle &&
      styleB instanceof ImageNodeStyle
    ) {
      return GraphElementsComparer.imageNodeStylesAreEqual(styleA, styleB);
    } else if (
      styleA instanceof CompositeNodeStyle &&
      styleB instanceof CompositeNodeStyle
    ) {
      return GraphElementsComparer.compositeNodeStylesAreEqual(styleA, styleB);
    } else {
      return false;
    }
  }

  private static shapeNodeStyleAreEqual(
    styleA: ShapeNodeStyle,
    styleB: ShapeNodeStyle
  ) {
    return (
      styleA.shape == styleB.shape &&
      styleA?.fill?.hasSameValue(styleB.fill) &&
      this.strokesEqual(styleA.stroke, styleB.stroke)
    );
  }

  private static compositeNodeStylesAreEqual(
    styleA: CompositeNodeStyle,
    styleB: CompositeNodeStyle
  ) {
    // if they differ in size, then easy check
    if (styleA.styleDefinitions.length != styleB.styleDefinitions.length) {
      return false;
    }

    for (let index = 0; index < styleA.styleDefinitions.length; index++) {
      const styleDefA = styleA.styleDefinitions[index];
      const styleDefB = styleB.styleDefinitions[index];

      if (
        !GraphElementsComparer.styleDefinitionsAreEqual(styleDefA, styleDefB)
      ) {
        // when any definition is no the same, they return false
        return false;
      }
    }

    return true;
  }

  private static imageNodeStylesAreEqual(
    styleA: ImageNodeStyle,
    styleB: ImageNodeStyle
  ) {
    return styleA.image == styleB.image;
  }

  private static styleDefinitionsAreEqual(
    styleDefinitionA: StyleDefinition,
    styleDefinitionB: StyleDefinition
  ): boolean {
    // test the insets first
    if (
      !GraphElementsComparer.insetsAreEqual(
        styleDefinitionA.insets as Insets,
        styleDefinitionB.insets as Insets
      )
    ) {
      return false;
    }

    // fall back to default node style comparison
    return GraphElementsComparer.nodeStylesEqual(
      styleDefinitionA.nodeStyle,
      styleDefinitionB.nodeStyle
    );
  }

  private static insetsAreEqual(insetsA: Insets, insetsB: Insets): boolean {
    if (insetsA == null && insetsB == null) {
      return true;
    }
    return (
      insetsA?.top == insetsB?.top &&
      insetsA?.right == insetsB?.right &&
      insetsA?.bottom == insetsB?.bottom &&
      insetsA?.left == insetsB?.left
    );
  }

  public static edgesEqual(edgeA: IEdge, edgeB: IEdge): boolean {
    // Check if edge types are different
    if (edgeA.tag.name != edgeB.tag.name) {
      return false;
    }

    // Check if edge styles are different
    return this.edgeStylesEqual(edgeA.style, edgeB.style);
  }

  public static edgeStylesEqual(styleA: IEdgeStyle, styleB: IEdgeStyle) {
    if (
      styleA instanceof PolylineEdgeStyle &&
      styleB instanceof PolylineEdgeStyle
    ) {
      return (
        styleA.smoothingLength == styleB.smoothingLength &&
        this.arrowsEqual(styleA.sourceArrow, styleB.sourceArrow) &&
        this.arrowsEqual(styleA.targetArrow, styleB.targetArrow) &&
        this.strokesEqual(styleA.stroke, styleB.stroke)
      );
    } else if (
      styleA instanceof BezierEdgeStyle &&
      styleB instanceof BezierEdgeStyle
    ) {
      return (
        this.arrowsEqual(styleA.sourceArrow, styleB.sourceArrow) &&
        this.arrowsEqual(styleA.targetArrow, styleB.targetArrow) &&
        this.strokesEqual(styleA.stroke, styleB.stroke)
      );
    } else if (
      styleA instanceof ArcEdgeStyle &&
      styleB instanceof ArcEdgeStyle
    ) {
      return (
        this.arrowsEqual(styleA.sourceArrow, styleB.sourceArrow) &&
        this.arrowsEqual(styleA.targetArrow, styleB.targetArrow) &&
        this.strokesEqual(styleA.stroke, styleB.stroke)
      );
    } else {
      return false;
    }
  }

  public static strokesEqual(strokeA: Stroke, strokeB: Stroke): boolean {
    return (
      strokeA.fill.hasSameValue(strokeB.fill) &&
      strokeA.lineCap == strokeB.lineCap &&
      strokeA.lineJoin == strokeB.lineJoin &&
      strokeA.miterLimit == strokeB.miterLimit &&
      strokeA.thickness == strokeB.thickness &&
      (strokeA.dashStyle?.dashes?.size ?? 0) ==
        (strokeB.dashStyle?.dashes?.size ?? 0) &&
      (!strokeA.dashStyle?.dashes ||
        !strokeB.dashStyle?.dashes ||
        strokeA.dashStyle.dashes.every(
          (v, i) => v === strokeB.dashStyle.dashes.get(i)
        ))
    );
  }

  public static arrowsEqual(arrowA: IArrow, arrowB: IArrow): boolean {
    return (
      arrowA.length == arrowB.length && arrowA.cropLength == arrowB.cropLength
    );
  }
}
