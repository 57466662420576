class BackgroundDomService {
  private backgroundIFrame: HTMLIFrameElement;
  private elementsContainer: HTMLDivElement;
  private isDebug = false;

  private get document() {
    return this.backgroundIFrame.contentDocument;
  }

  private get window() {
    return this.backgroundIFrame.contentWindow.window;
  }

  private get container() {
    return this.elementsContainer;
  }

  constructor() {
    this.init();
  }

  public init() {
    if (this.backgroundIFrame) {
      this.backgroundIFrame.remove();
    }
    this.backgroundIFrame = this.createBackgroundIFrame();
    window.document.body.appendChild(this.backgroundIFrame);
    this.document.open();
    this.document.write(
      '<!DOCTYPE html><html><head></head><body></body></html>'
    );
    this.document.close();
    this.elementsContainer = this.createElementsContainer();
    this.document.body.appendChild(this.elementsContainer);

    this.copyStyles();
  }

  public createElement(tagName: string) {
    return this.document.createElement(tagName);
  }

  public appendElement(element: HTMLElement) {
    this.container.appendChild(element);
  }

  public removeElement(element: HTMLElement) {
    this.container.removeChild(element);
  }

  public getElementById(elementId: string): HTMLElement {
    return this.document.getElementById(elementId);
  }

  public isHtmlElement(obj: any): obj is HTMLElement {
    return (
      obj instanceof window.HTMLElement ||
      obj instanceof this.window.HTMLElement
    );
  }

  public copyStyles() {
    // Remove all existing styles and links from an iframe
    const iframeStyles = this.document.head.getElementsByTagName('style');
    for (const iframeStyle of [...iframeStyles]) {
      iframeStyle.remove();
    }
    const iframeLinks = this.document.head.querySelectorAll('link[as="style"]');
    for (const iframeLink of [...iframeLinks]) {
      iframeLink.remove();
    }

    // Copy new parent styles and links into an iframe
    const parentStyles = window.document.head.getElementsByTagName('style');
    for (const parentStyle of parentStyles) {
      const iframeStyle = this.document.createElement('style');
      iframeStyle.innerHTML = parentStyle.innerHTML;
      this.document.head.appendChild(iframeStyle);
    }
    const parentLinks =
      window.document.head.querySelectorAll('link[as="style"]');
    for (const parentLink of parentLinks) {
      const iframeLink = this.document.createElement('link');
      iframeLink.rel = 'stylesheet';
      iframeLink.href = (parentLink as HTMLLinkElement).href;
      this.document.head.appendChild(iframeLink);
    }
  }

  private createBackgroundIFrame(): HTMLIFrameElement {
    const iframe = window.document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.margin = '0';
    iframe.style.padding = '0';

    if (!this.isDebug) {
      iframe.style.top = '-30000px';
      iframe.width = '0';
      iframe.height = '0';
    } else {
      iframe.style.top = '1000px';
      iframe.width = '100%';
      iframe.height = '100%';
    }

    return iframe;
  }

  private createElementsContainer(): HTMLDivElement {
    const container = window.document.createElement('div');
    if (!this.isDebug) {
      container.style.overflow = 'hidden';
      container.style.left = '-30000px';
    }
    container.style.position = 'absolute';
    container.style.width = '3000px';
    container.style.height = '3000px';
    return container;
  }
}

let instance = new BackgroundDomService();
export default instance;
