import PaletteItem from '@/components/DiagramPalette/PaletteItem';
import { ShapeNodeStyle } from 'yfiles';
import i18n from '@/core/plugins/vue-i18n';
import ShapeElementData from './ShapeElementData';
import { IElementDataProvider } from './IElementDataProvider';
import ElementSvgRenderUtils from '@/core/utils/ElementSvgRenderUtils';
import PaletteCategory from '../PaletteCategory';
import ShapePaletteBehaviour from '../ShapePaletteBehaviour';
import diagramConfig from '@/core/config/diagram.definition.config';
import {
  DashStyleType,
  NodeSize,
  NodeVisualType,
  ShapeNodeStyleDto,
} from '@/api/models';
import DiagramUtils from '@/core/utils/DiagramUtils';
import StyleCreator from '@/core/utils/StyleCreator';
import { DefaultColors } from '@/core/common/DefaultColors';

export default class ShapeElementDataProvider implements IElementDataProvider {
  getElements(): PaletteItem[] {
    return ShapeElementData.map<PaletteItem>((e) => {
      const styleDto = new ShapeNodeStyleDto(
        { color: e.fill },
        {
          thickness: 1,
          dashStyle: {
            type: DashStyleType.Solid,
          },
          fill: {
            color: DefaultColors.BLACK,
          },
        },
        e.shape,
        NodeSize.ExtraSmall,

        NodeVisualType.Shape
      );
      const size = DiagramUtils.getNodeSize(styleDto);

      const style = StyleCreator.createNodeStyle(styleDto);

      return {
        category: PaletteCategory.Annotate,
        subcategory: 'Shapes',
        img: ElementSvgRenderUtils.createSvgFromGraphNodeStyle(
          style,
          size.width,
          size.height
        ),
        data: e,
        text: e.name.toUpperCase().replace(' ', '_'),
        id: e.id,
        behaviour: ShapePaletteBehaviour.INSTANCE,
        canDrag: true,
      };
    });
  }
}
