const origin =
  window.location.protocol +
  '//' +
  window.location.hostname +
  (window.location.port ? ':' + window.location.port : '');

export let customPdfFonts = {
  'Arial': {
    normal: origin + '/media/fonts/pdf/Arial.ttf',
    bold: origin + '/media/fonts/pdf/Arialbd.ttf',
    italics: origin + '/media/fonts/pdf/Ariali.ttf',
    bolditalics: origin + '/media/fonts/pdf/Arialbi.ttf',
  },
  'ArialNarrow': {
    normal: origin + '/media/fonts/pdf/ArialN.ttf',
    bold: origin + '/media/fonts/pdf/ArialNbd.ttf',
    italics: origin + '/media/fonts/pdf/ArialNi.ttf',
    bolditalics: origin + '/media/fonts/pdf/ArialNbi.ttf',
  },
  'Arial Narrow': {
    normal: origin + '/media/fonts/pdf/ArialN.ttf',
    bold: origin + '/media/fonts/pdf/ArialNbd.ttf',
    italics: origin + '/media/fonts/pdf/ArialNi.ttf',
    bolditalics: origin + '/media/fonts/pdf/ArialNbi.ttf',
  },
  'Calibri': {
    normal: origin + '/media/fonts/pdf/Calibri.ttf',
    bold: origin + '/media/fonts/pdf/Calibribd.ttf',
    italics: origin + '/media/fonts/pdf/Calibrii.ttf',
    bolditalics: origin + '/media/fonts/pdf/Calibribi.ttf',
  },
  'CourierNew': {
    normal: origin + '/media/fonts/pdf/Courier.ttf',
    bold: origin + '/media/fonts/pdf/Courierbd.ttf',
    italics: origin + '/media/fonts/pdf/Courieri.ttf',
    bolditalics: origin + '/media/fonts/pdf/Courierbi.ttf',
  },
  'Courier New': {
    normal: origin + '/media/fonts/pdf/Courier.ttf',
    bold: origin + '/media/fonts/pdf/Courierbd.ttf',
    italics: origin + '/media/fonts/pdf/Courieri.ttf',
    bolditalics: origin + '/media/fonts/pdf/Courierbi.ttf',
  },
  'Georgia': {
    normal: origin + '/media/fonts/pdf/Georgia.ttf',
    bold: origin + '/media/fonts/pdf/Georgiabd.ttf',
    italics: origin + '/media/fonts/pdf/Georgiai.ttf',
    bolditalics: origin + '/media/fonts/pdf/Georgiabi.ttf',
  },
  'TimesNewRoman': {
    normal: origin + '/media/fonts/pdf/Times.ttf',
    bold: origin + '/media/fonts/pdf/Timesbd.ttf',
    italics: origin + '/media/fonts/pdf/Timesi.ttf',
    bolditalics: origin + '/media/fonts/pdf/Timesbi.ttf',
  },
  'Times New Roman': {
    normal: origin + '/media/fonts/pdf/Times.ttf',
    bold: origin + '/media/fonts/pdf/Timesbd.ttf',
    italics: origin + '/media/fonts/pdf/Timesi.ttf',
    bolditalics: origin + '/media/fonts/pdf/Timesbi.ttf',
  },
  'TrebuchetMs': {
    normal: origin + '/media/fonts/pdf/Trebuchet.ttf',
    bold: origin + '/media/fonts/pdf/Trebuchetbd.ttf',
    italics: origin + '/media/fonts/pdf/Trebucheti.ttf',
    bolditalics: origin + '/media/fonts/pdf/Trebuchetbi.ttf',
  },
  'Trebuchet MS': {
    normal: origin + '/media/fonts/pdf/Trebuchet.ttf',
    bold: origin + '/media/fonts/pdf/Trebuchetbd.ttf',
    italics: origin + '/media/fonts/pdf/Trebucheti.ttf',
    bolditalics: origin + '/media/fonts/pdf/Trebuchetbi.ttf',
  },
  'Verdana': {
    normal: origin + '/media/fonts/pdf/Verdana.ttf',
    bold: origin + '/media/fonts/pdf/Verdanabd.ttf',
    italics: origin + '/media/fonts/pdf/Verdanai.ttf',
    bolditalics: origin + '/media/fonts/pdf/Verdanabi.ttf',
  },
};
