import DocumentPagesApiService from '@/api/DocumentPagesApiService';
import DocumentsApiService from '@/api/DocumentsApiService';
import DiagramsApiService from '@/api/DiagramsApiService';
import {
  DocumentPageDto,
  DiagramDto,
  DocumentDto,
  CreateOrEditDocumentPageDto,
  CreateOrEditDocumentDto,
  DocumentPageType,
  DocumentPageContentType,
  CurrentUserProfileEditDto,
} from '@/api/models';
import IApplicationError from '@/core/common/IApplicatonError';
import Mutex from '@/core/common/Mutex';
import DiagramUtils from '@/core/utils/DiagramUtils';
import router from '@/router';
import { GraphComponent } from 'yfiles/typings/yfiles-api-npm';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import { ExportArea } from '../export/ExportArea';
import { ExportFormat } from '../export/ExportFormat';
import ExportOptions from '../export/ExportOptions';
import ExportService from '../export/ExportService';
import GraphService from '../graph/graph.service';
import GraphInitCompleteEventArgs from '../graph/GraphInitCompleteEventArgs';
import store from '../store';
import {
  DOCUMENT_NAMESPACE,
  GET_SELECTED_PAGE,
  GET_SELECTED_DIAGRAM,
  UPDATE_SELECTED_DIAGRAM,
  GET_DOCUMENT,
  SET_DOCUMENT,
  GET_READONLY,
  SET_READONLY,
  GET_SAVE_FAILED,
  SET_SAVE_FAILED,
  SET_DOCUMENT_MODIFICATION_TIME,
  UNLOAD_DOCUMENT,
} from '../store/document.module';
import { GET_CURRENT_USER } from '@/core/services/store/user.module';
import DocumentHashHelper from './DocumentHashHelper';
import i18n from '@/core/plugins/vue-i18n';
import DocumentSyncService from './sync/DocumentSyncService';
import { SaveAsOptions } from './SaveAsOptions';
import { cloneDeep } from 'lodash';
import { RouterParams } from '@/core/config/routerParams';

class DocumentService {
  public syncService = new DocumentSyncService();

  private graphComponent: GraphComponent = null;
  private graphService: GraphService = null;
  private saveMutex = new Mutex();
  private closeMutex = new Mutex();
  private isInitialized = false;

  get selectedPage(): DocumentPageDto {
    return store.getters[`${DOCUMENT_NAMESPACE}/${GET_SELECTED_PAGE}`];
  }

  get selectedDiagram(): DiagramDto {
    return store.getters[`${DOCUMENT_NAMESPACE}/${GET_SELECTED_DIAGRAM}`];
  }

  get currentDocument(): DocumentDto {
    return store.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT}`];
  }

  get isReadOnly(): boolean {
    return store.getters[`${DOCUMENT_NAMESPACE}/${GET_READONLY}`];
  }

  get lastSaveFailed(): boolean {
    return store.getters[`${DOCUMENT_NAMESPACE}/${GET_SAVE_FAILED}`];
  }

  get documentPages(): DocumentPageDto[] {
    return store.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT}`]?.pages;
  }

  get pagesWithDiagram(): DocumentPageDto[] {
    return store.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT}`]?.pages.filter(
      (page) => page?.diagram
    );
  }

  get currentUser(): CurrentUserProfileEditDto {
    return store.getters[GET_CURRENT_USER];
  }

  // TODO revisit DocumentService & DocumentDetails lifecycle
  public init() {
    if (this.isInitialized) {
      return;
    }
    this.isInitialized = true;

    DocumentHashHelper.clearAllHashes();
    EventBus.$on(EventBusActions.GRAPH_INIT_COMPLETE, (args) => {
      this.graphInitComplete(args);
    });

    EventBus.$on(EventBusActions.GRAPH_DISPOSED, () => {
      this.onGraphDispose();
    });

    router.beforeEach((to, from, next) => {
      if (
        (RouterParams.documentId in from.params &&
          !(RouterParams.documentId in to.params)) ||
        (RouterParams.documentId in from.params &&
          RouterParams.documentId in to.params &&
          from.params[RouterParams.documentId] !=
            to.params[RouterParams.documentId])
      ) {
        this.closeDocument().finally(() => {
          next();
        });
      } else {
        next();
      }
    });

    const documentStateChanged = () => {
      if (this.currentDocument) {
        this.syncService.start({
          documentId: this.currentDocument.id,
          isReadOnly: this.isReadOnly,
        });
      } else {
        this.syncService.stop();
      }
    };
    store.watch((state) => this.currentDocument, documentStateChanged);
    store.watch((state) => this.isReadOnly, documentStateChanged);
  }

  public async saveDocument(
    document: DocumentDto,
    forceSave: boolean = false
  ): Promise<boolean> {
    if (this.isReadOnly) {
      return;
    }

    let hasSaved = false;
    const unlock = await this.saveMutex.lock();
    try {
      this.prepareDocumentForSaving();

      const changedPages = await this.getChangedPages(document);
      const currentHash = await DocumentHashHelper.getCurrentDocumentHash(
        document
      );
      const oldHash = DocumentHashHelper.getStoredDocumentHash(document.id);

      if (currentHash != oldHash || changedPages.length > 0 || forceSave) {
        const body = this.createDocumentBody(document);
        const result = await DocumentsApiService.createOrEdit(body);
        document.lastModificationTime = result.data.result.lastModificationTime;
        DocumentHashHelper.storeDocumentHash(document.id, currentHash);

        // Save changed pages
        for (const changedPage of changedPages) {
          const response = await this.savePage(document, changedPage.page);
          if (response.data.success) {
            DocumentHashHelper.storePageHash(
              changedPage.page.id,
              changedPage.hash
            );
          }
        }
      } else {
        // Nothing has changed, no need to push anything to the server
        // Regardless, still perform the lastModificationTime check to make sure there are no save conflicts
        const lastModificationTime = (
          await DocumentsApiService.getDocumentLastModificationTime({
            documentId: document.id,
          })
        ).data.result;
        if (lastModificationTime > document.lastModificationTime) {
          EventBus.$emit(EventBusActions.DOCUMENT_SAVE_CONFLICT);
          throw Error('save_conflict');
        }
      }

      store.commit(`${DOCUMENT_NAMESPACE}/${SET_SAVE_FAILED}`, false);
      hasSaved = true;
    } catch (error) {
      store.commit(`${DOCUMENT_NAMESPACE}/${SET_SAVE_FAILED}`, true);
      if (error?.isAxiosError !== undefined) {
        if (error.isAxiosError) {
          if (error.response?.status == 409) {
            EventBus.$emit(EventBusActions.DOCUMENT_SAVE_CONFLICT);
          } else {
            let payload: IApplicationError = {
              title: i18n.t('UNABLE_TO_SAVE').toString(),
              message: error.response.data.error.message,
              refresh: false,
              navigate: 'landing',
            };
            EventBus.$emit(EventBusActions.APPLICATION_ERROR, payload);
          }
        }
      }
    } finally {
      unlock();
    }
    return hasSaved;
  }

  private async savePage(
    document: DocumentDto,
    page: CreateOrEditDocumentPageDto
  ) {
    if (page.diagram) {
      await this.generatePageThumbnail(page);
    }

    let response = await DocumentPagesApiService.createOrEdit({
      ...page,
      filters: document.filters,
    });
    page.id = response.data.result;
    page.isPristine = false;
    return response;
  }

  public prepareDocumentForSaving() {
    if (this.selectedDiagram && this.graphComponent && this.graphService) {
      const updatedDiagram = DiagramUtils.serializeDiagram(
        this.selectedDiagram,
        this.graphComponent
      );
      this.selectedDiagram.quickBuildState =
        this.graphService.quickBuildService.quickBuildState;
      store.dispatch(
        `${DOCUMENT_NAMESPACE}/${UPDATE_SELECTED_DIAGRAM}`,
        updatedDiagram
      );
    }
  }

  public createDocumentBody(document: DocumentDto): CreateOrEditDocumentDto {
    return {
      id: document.id,
      name: document.name,
      isPristine: false,
      hasSteps: document.hasSteps,
      description: document.description,
      themeId: document.lastSelectedThemeId,
      headerHtml: document.headerHtml,
      footerHtml: document.footerHtml,
      headerStyle: document.headerStyle,
      footerStyle: document.footerStyle,
      logoPosition: document.logoPosition,
      legendPosition: document.legendPosition,
      attachments: document.attachments,
      showHeader: document.showHeader,
      showFooter: document.showFooter,
      tableSwatch: document.tableSwatch,
      fontStyles: document.fontStyles,
      coverPageTemplate: document.coverPageTemplate,
      fillerPageTemplate: document.fillerPageTemplate,
      closingPageTemplate: document.closingPageTemplate,
      showPageNumbering: document.showPageNumbering,
      lastModificationTime: document.lastModificationTime,
      defaultPageType: document.defaultPageType,
      dataPropertyStyles: document.dataPropertyStyles,
      autoSave: document.autoSave,
    };
  }

  public async generatePageThumbnail(page: DocumentPageDto) {
    const exportPages = [{ page: page }];
    const options: ExportOptions = {
      area: ExportArea.PageThumbnail,
      document: this.currentDocument,
      pages: exportPages,
      format: ExportFormat.Png,
      withData: false,
      withFilters: false,
      download: false,
      clipboard: false,
      print: false,
    };

    page.diagram.thumb = (await ExportService.export(options)) as string;
  }

  public async closeDocument(saveDocument: boolean = true): Promise<void> {
    const unlock = await this.closeMutex.lock();
    try {
      if (this.currentDocument) {
        if (!this.lastSaveFailed) {
          if (saveDocument) {
            await this.saveDocument(this.currentDocument);
          }

          if (
            this.currentDocument.lockedByUser?.id === this.currentUser.userId ||
            !this.currentDocument.lockedByUser
          ) {
            await this.unlockDocument(this.currentDocument);
          }
        }
        await store.dispatch(`${DOCUMENT_NAMESPACE}/${UNLOAD_DOCUMENT}`);
      }
    } finally {
      unlock();
    }
  }

  public async unlockDocument(document: DocumentDto) {
    if (!document) {
      return await Promise.resolve();
    }
    return await DocumentsApiService.unlockDocument({ id: document.id });
  }

  public async isDocumentDirty(document: DocumentDto) {
    // Documents and pages should have a hash created when loaded. Should not be null
    if (!document) {
      return false;
    }
    const currentDocumentHash = await DocumentHashHelper.getCurrentDocumentHash(
      document
    );
    const oldDocumentHash = DocumentHashHelper.getStoredDocumentHash(
      document.id
    );

    if (oldDocumentHash != null) {
      if (currentDocumentHash != oldDocumentHash) {
        return true;
      }
    }

    for (const page of document.pages) {
      const currentPageHash = await DocumentHashHelper.getCurrentPageHash(page);
      const oldPageHash = DocumentHashHelper.getStoredPageHash(page.id);

      if (oldPageHash != null) {
        if (currentPageHash != oldPageHash) {
          return true;
        }
      }
    }
    return false;
  }

  public async getChangedPages(
    document: DocumentDto
  ): Promise<{ page: DocumentPageDto; hash: string }[]> {
    const changedPages = [];
    for (const page of document.pages) {
      const currentHash = await DocumentHashHelper.getCurrentPageHash(page);
      const oldHash = DocumentHashHelper.getStoredPageHash(page.id);
      if (currentHash != oldHash) {
        changedPages.push({ page: page, hash: currentHash });
      }
    }
    return changedPages;
  }

  public async cloneCurrentDocument(options: SaveAsOptions): Promise<number> {
    this.prepareDocumentForSaving();

    let selectedPage = cloneDeep(this.selectedPage);
    let document = cloneDeep(this.currentDocument) as DocumentDto;
    const index = (document.pages as Array<any>).findIndex(
      (p) => p.id == selectedPage.id
    );
    document.pages[index] = selectedPage;
    document.name = options.documentName;

    document = await this.prepareDocumentPagesForCloning(
      document,
      options,
      true
    );

    document.thumb = document.pages.find(
      (page) => page.id == selectedPage.id
    ).diagram?.thumb;

    const documentResult = await DocumentsApiService.clone(document);

    this.updateDocumentModificationTime();

    return documentResult.data.result;
  }

  public async cloneDocument(documentId: number): Promise<number> {
    const documentResult = await DocumentsApiService.get({
      id: documentId,
      tryLock: false,
    });

    let document = documentResult.data.result.document as DocumentDto;
    document.name = i18n.t('COPY').toString() + ' ' + document.name;

    document = await this.prepareDocumentPagesForCloning(document, null, true);

    const documentCloned = await DocumentsApiService.clone(document);

    return documentCloned.data.result;
  }

  public updateDocumentModificationTime() {
    store.commit(
      `${DOCUMENT_NAMESPACE}/${SET_DOCUMENT_MODIFICATION_TIME}`,
      new Date()
    );
  }

  public documentHasFilters(document: DocumentDto): boolean {
    return (
      ExportService.dataExportService.currentTagFilters?.length > 0 ||
      document.pages.some(
        (page) => page.diagram && DiagramUtils.diagramHasFilters(page.diagram)
      )
    );
  }

  /**
   * Returns the default content type for the given @pageType
   * @param pageType
   * @returns
   */
  public getDefaultPageContentType(
    pageType: DocumentPageType
  ): DocumentPageContentType {
    switch (pageType) {
      case DocumentPageType.Content:
        return DocumentPageContentType.Html;
      case DocumentPageType.Diagram:
        return DocumentPageContentType.None;
      case DocumentPageType.Split:
        return DocumentPageContentType.Html;
      default:
        throw `Unknown page type ${pageType}`;
    }
  }

  private graphInitComplete(args: GraphInitCompleteEventArgs) {
    this.graphComponent = args.graphComponent;
    this.graphService = args.graphService;
  }

  private onGraphDispose() {
    this.graphComponent = null;
    this.graphService = null;
  }

  private async prepareDocumentPagesForCloning(
    document: DocumentDto,
    options: SaveAsOptions,
    getDiagram: boolean = false
  ): Promise<DocumentDto> {
    for (let order = 0; order < document.pages.length; order++) {
      const page = document.pages[order];

      if (getDiagram) {
        const diagramResult = await DiagramsApiService.getDiagramForView({
          id: page.diagramId,
        });

        page.diagram = diagramResult.data.result.diagram;
      }

      if (page?.diagram) {
        await this.generatePageThumbnail(page);

        page.filterDefinition = null;
        if (options?.withFilters || (document.filters.length && getDiagram)) {
          page.diagram.nodes = page.diagram.nodes.filter(
            (node) => node.isIncluded
          );
          page.diagram.edges = page.diagram.edges.filter(
            (edge) => edge.isIncluded
          );
        } else {
          page.diagram.nodes.forEach((node) => {
            node.isIncluded = true;
          });
          page.diagram.edges.forEach((edge) => {
            edge.isIncluded = true;
          });
        }
      }
    }

    return document;
  }
}

const instance = new DocumentService();
export default instance;
