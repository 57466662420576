import {
  CompositeNodeStyleDto,
  CreateOrEditThemeDto,
  CurrentUserProfileEditDto,
  DocumentPageDto,
  DocumentPageLayoutType,
  ElementType,
  INodeStyleDto,
  ShapeNodeStyleDto,
  ThemeDto,
} from '@/api/models';
import { ArcEdgeStyle, IEdge, IGraph, INode, ShapeNodeStyle } from 'yfiles';
import store from '@/core/services/store/index';
import StyleCreator from '@/core/utils/StyleCreator';
import DiagramUtils from '@/core/utils/DiagramUtils';
import {
  DOCUMENT_NAMESPACE,
  GET_CURRENT_THEME,
  GET_SELECTED_SUBPAGE_INDEX,
  SET_CURRENT_THEME,
  SET_PAGE_FOOTERS_FROM_THEME,
  SET_PAGE_HEADERS_FROM_THEME,
  SET_SUBPAGE_FOOTER_FROM_THEME,
  SET_SUBPAGE_HEADER_FROM_THEME,
  UPDATE_DOCUMENT_FROM_THEME,
} from '../store/document.module';
import BackgroundGraphService from './BackgroundGraphService';
import {
  THEMES_NAMESPACE,
  GET_THEME,
  GET_DEFAULT_THEME,
} from '../store/theme.module';
import {
  getFooterKey,
  getHeaderKey,
} from '@/core/services/document/DocumentConsts';
import NodeIndicatorService from './NodeIndicatorService';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import NotificationUtils, {
  NotificationType,
} from '@/core/utils/NotificationUtils';
import CKEditorUtils from '@/core/utils/CKEditorUtils';
import DiagramWriter from '@/core/services/graph/serialization/diagram-writer.service';
import i18n from '../../../core/plugins/vue-i18n';
import BackgroundDomService from '../BackgroundDomService';
import JigsawNodeStyle from '@/core/styles/JigsawNodeStyle';
import { cloneDeep } from 'lodash';
import CompositeNodeStyle from '@/core/styles/composite/CompositeNodeStyle';
import DataPropertyStyleService from './DataPropertyStyleService';
import { JurisdictionUtils } from '@/core/styles/decorators/JurisdictionDecorator';
import { PeopleUtils } from '@/core/utils/PeopleUtils';
import { UserRole } from '@/core/common/UserRole';

export default class ThemeService {
  static get currentTheme(): ThemeDto {
    return store.getters[
      `${DOCUMENT_NAMESPACE}/${GET_CURRENT_THEME}`
    ] as ThemeDto;
  }

  private static applyNodeThemes(theme: ThemeDto, graph: IGraph) {
    try {
      graph.nodes.forEach((node) => {
        ThemeService.applyNodeTheme(theme, node, graph);
      });
    } catch (error) {
      console.log(error);
    }
  }

  private static applyNodeTheme(theme: ThemeDto, node: INode, graph: IGraph) {
    let el = theme.elements.find(
      (x) =>
        x.name.toLowerCase() == node.tag.name.toLowerCase() &&
        x.elementType == ElementType.Node
    );
    if (el) {
      let elementStyle = cloneDeep(el.style) as INodeStyleDto;

      elementStyle =
        DataPropertyStyleService.updateNodeStyleWithDataPropertyStyle(
          cloneDeep(el.style),
          node
        );

      const newNodeStyle = StyleCreator.createNodeStyle(elementStyle);

      DiagramUtils.setStyle(
        graph,
        node,
        newNodeStyle,
        node.tag.dataPropertyStyle.isActive
      );

      //update tag
      node.tag.style = elementStyle as INodeStyleDto;
      if (node.tag.labelIsPlaceholder) {
        let labelText = DiagramUtils.getHtmlLabelContent(
          node,
          DiagramUtils.getPlaceholderLabelText(node)
        );
        DiagramUtils.setLabelValue(graph, node, labelText);
      }

      JurisdictionUtils.syncJurisdictionLabelElement(graph, node);
      JurisdictionUtils.syncStateLabelElement(graph, node);
      NodeIndicatorService.syncIndicators(node);
      //By default, role is shown
      PeopleUtils.syncPersonNode(node, graph, true);
    }
  }

  private static applyEdgeThemes(theme: ThemeDto, graph: IGraph) {
    try {
      graph.edges.forEach((edge) => {
        ThemeService.applyEdgeTheme(theme, edge, graph);
      });
    } catch (error) {
      console.log(error);
    }
  }
  private static applyEdgeTheme(theme: ThemeDto, edge: IEdge, graph: IGraph) {
    let el = theme.elements.find(
      (x) =>
        x.name.toLowerCase() == edge.tag.name.toLowerCase() &&
        x.elementType == ElementType.Edge
    );
    if (el) {
      const newEdgeStyle = StyleCreator.createEdgeStyle(el.style);

      // Keep original height for Arc line not to reformat it
      if (newEdgeStyle instanceof ArcEdgeStyle) {
        newEdgeStyle.height = (edge.style as ArcEdgeStyle).height;
      }

      DiagramUtils.setStyle(graph, edge, newEdgeStyle);
      //update tag
      edge.tag.style = el.style;
    }
  }

  private static applyThemeForCKEditor(theme: ThemeDto) {
    CKEditorUtils.setCkEditorFontStyles(theme.fontStyles);
  }

  private static async applyThemeForPageHeaders(
    page: DocumentPageDto,
    oldHeaderHtml: string,
    newHeaderHtml: string
  ) {
    if (page.headerHtml == oldHeaderHtml) {
      await store.dispatch(
        `${DOCUMENT_NAMESPACE}/${SET_PAGE_HEADERS_FROM_THEME}`,
        {
          page: page,
          headerHtml: newHeaderHtml,
        }
      );
      const selectedSubpageIndex =
        store.getters[`${DOCUMENT_NAMESPACE}/${GET_SELECTED_SUBPAGE_INDEX}`];
      if (
        !page.subPageRefs.some((p) => p.subPageIndex === selectedSubpageIndex)
      ) {
        EventBus.$emit(EventBusActions.DOCUMENT_CONTENT_SET, {
          key: getHeaderKey(
            page.id,
            store.getters[`${DOCUMENT_NAMESPACE}/${GET_SELECTED_SUBPAGE_INDEX}`]
          ),
          content: newHeaderHtml,
        });
      }
    }
    page.subPageRefs.forEach(async (subPage) => {
      if (
        subPage?.diagramId !== page.diagramId &&
        subPage.headerHtml == oldHeaderHtml
      ) {
        await store.dispatch(
          `${DOCUMENT_NAMESPACE}/${SET_SUBPAGE_HEADER_FROM_THEME}`,
          {
            subPage: subPage,
            headerHtml: newHeaderHtml,
          }
        );
        EventBus.$emit(EventBusActions.DOCUMENT_CONTENT_SET, {
          key: getHeaderKey(page.id, subPage.subPageIndex),
          content: newHeaderHtml,
        });
      }
    });
  }

  private static async applyThemeForPageFooters(
    page: DocumentPageDto,
    oldFooterHtml: string,
    newFooterHtml: string
  ) {
    if (page.footerHtml == oldFooterHtml) {
      await store.dispatch(
        `${DOCUMENT_NAMESPACE}/${SET_PAGE_FOOTERS_FROM_THEME}`,
        {
          page: page,
          footerHtml: newFooterHtml,
        }
      );
      const selectedSubpageIndex =
        store.getters[`${DOCUMENT_NAMESPACE}/${GET_SELECTED_SUBPAGE_INDEX}`];
      if (
        !page.subPageRefs.some((p) => p.subPageIndex === selectedSubpageIndex)
      ) {
        EventBus.$emit(EventBusActions.DOCUMENT_CONTENT_SET, {
          key: getFooterKey(
            page.id,
            store.getters[`${DOCUMENT_NAMESPACE}/${GET_SELECTED_SUBPAGE_INDEX}`]
          ),
          content: newFooterHtml,
        });
      }
    }
    page.subPageRefs.forEach(async (subPage) => {
      if (
        subPage?.diagramId !== page.diagramId &&
        subPage.footerHtml == oldFooterHtml
      ) {
        await store.dispatch(
          `${DOCUMENT_NAMESPACE}/${SET_SUBPAGE_FOOTER_FROM_THEME}`,
          {
            subPage: subPage,
            headerHtml: newFooterHtml,
          }
        );
        EventBus.$emit(EventBusActions.DOCUMENT_CONTENT_SET, {
          key: getFooterKey(page.id, subPage.subPageIndex),
          content: newFooterHtml,
        });
      }
    });
  }

  static async updateDocument(theme: ThemeDto) {
    await store.dispatch(
      `${DOCUMENT_NAMESPACE}/${UPDATE_DOCUMENT_FROM_THEME}`,
      {
        theme: theme,
      }
    );
  }

  /**
   *
   * @param theme Can be null, will apply element default style.
   */
  static applyThemeForDiagram(theme: ThemeDto, graph: IGraph) {
    ThemeService.applyNodeThemes(theme, graph);
    ThemeService.applyEdgeThemes(theme, graph);
  }

  static async applyTheme(
    themeId: number,
    graph?: IGraph,
    runThemeService = true
  ) {
    const oldTheme = ThemeService.currentTheme;
    let newTheme = (await store.dispatch(`${THEMES_NAMESPACE}/${GET_THEME}`, {
      id: themeId,
      ignoreError: true,
    })) as ThemeDto;

    if (!newTheme) {
      newTheme = await store.dispatch(
        `${THEMES_NAMESPACE}/${GET_DEFAULT_THEME}`
      );
    }

    await store.dispatch(
      `${DOCUMENT_NAMESPACE}/${SET_CURRENT_THEME}`,
      newTheme
    );

    if (runThemeService) {
      //apply to all pages
      const filteredPages = (
        store.state.document.document.pages as DocumentPageDto[]
      ).filter((p) => p.templateType == DocumentPageLayoutType.None);
      for (const page of filteredPages) {
        if (page?.diagram) {
          //load the page diagram a backgroundGraph
          let backgroundGraphService = new BackgroundGraphService(page.diagram);

          ThemeService.applyThemeForDiagram(
            newTheme,
            backgroundGraphService.graph
          );
          page.diagram = DiagramWriter.fromGraph(
            backgroundGraphService.graph,
            page.diagram
          );

          // apply theme to unlinked page diagrams
          if (page.subPageRefs) {
            for (const ref of page.subPageRefs) {
              backgroundGraphService = new BackgroundGraphService(ref.diagram);
              ThemeService.applyThemeForDiagram(
                newTheme,
                backgroundGraphService.graph
              );
              ref.diagram = DiagramWriter.fromGraph(
                backgroundGraphService.graph,
                ref.diagram
              );
            }
          }
        }

        await ThemeService.applyThemeForPageHeaders(
          page,
          oldTheme.headerHtml,
          newTheme.headerHtml
        );
        await ThemeService.applyThemeForPageFooters(
          page,
          oldTheme.footerHtml,
          newTheme.footerHtml
        );
      }
    }

    if (graph) {
      ThemeService.applyThemeForDiagram(newTheme, graph);
    }

    await ThemeService.updateDocument(newTheme);

    this.applyThemeForCKEditor(newTheme);
    BackgroundDomService.copyStyles();

    EventBus.$emit(EventBusActions.DOCUMENT_THEME_APPLIED, {
      newThemeId: newTheme.id,
      oldThemeId: oldTheme.id,
    });

    NotificationUtils.notify(
      i18n.t('THEME_X_HAS_BEEN_APPLIED', [newTheme.name]).toString(),
      NotificationType.Success
    );
  }

  public static getCurentThemeElementByNode(node: INode) {
    return ThemeService.currentTheme.elements.find(
      (x) =>
        x.name.toLowerCase() == node.tag.name?.toLowerCase() &&
        x.elementType == ElementType.Node
    );
  }

  public static hasThemePermission(
    currentUser: CurrentUserProfileEditDto,
    theme: CreateOrEditThemeDto
  ) {
    const hasProperRole = (role) =>
      role == UserRole.Admin || role == UserRole.SuperUser;
    return (
      currentUser.userId == theme.creatorUserId ||
      currentUser.roles.some(hasProperRole) ||
      theme.isEditable
    );
  }
}
export enum TablePaletteColorType {
  Header = 'header',
  Color1 = 'color1',
  Color2 = 'color2',
}
