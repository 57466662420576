import * as _ from 'lodash';
import appConfig from '../appConfig';
import userConfig from '../userConfig';

export default class ConfigurationBuilder {
  /**
   *
   * @param baseConfig The base configuration
   * @param config The overrides
   * @returns @param baseConfig + overrides from @param config
   */
  public static configure(baseConfig, config?: any) {
    config = _.mergeWith(
      _.cloneDeep(baseConfig),
      config ?? {},
      (objValue, srcValue) => {
        // This will ensure that any objects in the "config" object always win when they are arrays,
        // instead of trying to merge
        // We could always tell the srcValue here to win if a value is defined but that might be a bit overkill.
        if (_.isArray(objValue)) {
          return srcValue;
        }
        //let default merge occur
      }
    );

    const uc = userConfig;
    if (uc.language) {
      if (uc.language.toLowerCase() == 'en-gb') {
        // CKEditor built in translations for 'en' is using US spelling by default
        config.language = uc.language.toLowerCase();
      } else {
        config.language = uc.language.substr(0, 2);
      }
    }

    if (!config.removePlugins) {
      config.removePlugins = [];
    }
    console.group('CKEditor ConfigurationBuilder');
    console.debug('Building Conditional CKEditor Configuration');

    conditionalPluginConfig.forEach((configItem) => {
      const enabled = configItem.isEnabled();
      console.debug(
        `${configItem.pluginName} is ${enabled ? 'enabled' : 'disabled'}`
      );
      if (configItem.isEnabled()) {
        config = _.merge(config, configItem.setup());
      } else {
        if (config.removePlugins.indexOf(configItem.pluginName) < 0) {
          console.debug(`Removing plugin ${configItem.pluginName}`);
          config.removePlugins.push(configItem.pluginName);
        }
      }
    });
    console.groupEnd();
    return config;
  }
}

const conditionalPluginConfig = [
  {
    pluginName: 'WProofreader',
    isEnabled: () => appConfig.spellChecker.enabled,
    setup: () => {
      return {
        wproofreader: {
          lang: appConfig.spellChecker.defaultLanguage, // sets the default language
          serviceId: appConfig.spellChecker.serviceId, // required for the Cloud version only
          srcUrl: appConfig.spellChecker.server,
        },
        toolbar: {
          items: ['|', 'WProofreader'],
        },
      };
    },
  },
];
