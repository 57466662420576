var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"j-mb-32"},[_c('h2',{staticClass:"j-text-30 j-mb-14"},[_vm._v("Select Base")]),_c('div',{staticClass:"j-flex j-gap-16"},[_c('AppSelect',{attrs:{"options":_vm.demoOptions},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}}),_c('AppSelect',{attrs:{"options":_vm.demoOptions,"return-single-value":""},model:{value:(_vm.selectionValueOnly),callback:function ($$v) {_vm.selectionValueOnly=$$v},expression:"selectionValueOnly"}}),_c('AppSelect',{attrs:{"options":_vm.demoOptions,"disabled":""},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1)]),_c('div',{staticClass:"j-mb-32"},[_c('h2',{staticClass:"j-text-30 j-mb-14"},[_vm._v("Select - disabled options, full-width")]),_c('AppSelect',{attrs:{"options":_vm.demoOptions,"disabled-options":_vm.disabledOptions},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}}),_c('AppSelect',{attrs:{"options":_vm.demoOptions,"disabled-options":_vm.disabledOptions,"full-width":""},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1),_c('div',{staticClass:"j-mb-32"},[_c('h2',{staticClass:"j-text-30 j-mb-14"},[_vm._v("Select - multiple")]),_c('AppSelect',{attrs:{"options":_vm.demoOptions,"multiple":""},model:{value:(_vm.multipleSelection),callback:function ($$v) {_vm.multipleSelection=$$v},expression:"multipleSelection"}})],1),_c('div',{staticClass:"j-mb-32"},[_c('h2',{staticClass:"j-text-30 j-mb-14"},[_vm._v("Select - slots (selection, options)")]),_c('div',{staticClass:"j-flex j-flex-col j-items-start j-gap-16"},[_c('AppSelect',{attrs:{"options":_vm.demoOptions2,"value-key":"id","label-key":"text"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var hasInitialValue = ref.hasInitialValue;
var value = ref.value;
return [(value)?_c('span',[_vm._v(_vm._s(value.emoji)+" "+_vm._s(value.text))]):_vm._e()]}},{key:"options",fn:function(ref){
var option = ref.option;
var isDisabled = ref.isDisabled;
var isSelected = ref.isSelected;
return [_c('span',[_vm._v(_vm._s(option.emoji)+" "+_vm._s(option.text))])]}}]),model:{value:(_vm.selectedDemoOptions2),callback:function ($$v) {_vm.selectedDemoOptions2=$$v},expression:"selectedDemoOptions2"}}),_c('AppSelect',{attrs:{"options":_vm.demoOptions2,"fixed-height":false,"multiple":"","value-key":"id","label-key":"text"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var hasInitialValue = ref.hasInitialValue;
var value = ref.value;
var setSelection = ref.setSelection;
return [(value)?_vm._l((value),function(v){return _c('span',{key:v.id,staticClass:"j-inline-flex j-items-center j-px-8 j-py-4 j-rounded-full j-border-1 j-border-accent-purple"},[_vm._v(" "+_vm._s(v.emoji)+" "+_vm._s(v.text)+" "),_c('span',{staticClass:"icon-close-circle j-text-16 j-ml-4",on:{"click":function($event){$event.stopPropagation();return setSelection(v)}}})])}):_vm._e()]}},{key:"options",fn:function(ref){
var option = ref.option;
var isDisabled = ref.isDisabled;
var isSelected = ref.isSelected;
return [_c('span',[_vm._v(_vm._s(option.emoji)+" "+_vm._s(option.text))])]}}]),model:{value:(_vm.selectedDemoOptionsMul2),callback:function ($$v) {_vm.selectedDemoOptionsMul2=$$v},expression:"selectedDemoOptionsMul2"}})],1)]),_c('div',{staticClass:"j-mb-32"},[_c('h2',{staticClass:"j-text-30 j-mb-14"},[_vm._v("Theme export Logo Position")]),_c('div',{staticClass:"j-flex j-flex-col j-items-start j-gap-16"},[_c('AppSelect',{attrs:{"options":_vm.logoPositions,"label-key":"translatedText","return-single-value":""},model:{value:(_vm.selectedLogoPosition),callback:function ($$v) {_vm.selectedLogoPosition=$$v},expression:"selectedLogoPosition"}}),_c('AppSelect',{attrs:{"options":_vm.logoPositions,"disabled-options":[2],"label-key":"translatedText","return-single-value":""},model:{value:(_vm.selectedLogoPosition),callback:function ($$v) {_vm.selectedLogoPosition=$$v},expression:"selectedLogoPosition"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }