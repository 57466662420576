




































































































import {
  Vue,
  Component,
  Prop,
  PropSync,
  Provide,
  Watch,
} from 'vue-property-decorator';
import { RecycleScroller } from 'vue-virtual-scroller';

import { getNestedProp } from '@/core/utils/common.utils';
import { AppTableField, TIndexedObject, TTableRow } from '.';

import TableHeaderCell from './TableHeaderCell.vue';

@Component({
  name: 'AppTable',
  components: { TableHeaderCell },
})
export default class AppTable extends Vue {
  @Prop({ required: true })
  fields: AppTableField[];

  @Prop({ required: true })
  items: TIndexedObject[];

  @PropSync('sorting', { default: () => {}, type: Object })
  syncedSorting: TIndexedObject<string>;

  @Prop({ default: 'id', type: String })
  uniqueKeyPath: string;

  @Prop({ default: false, type: Boolean })
  selectable: string;

  @Prop({ default: () => [], type: Array })
  selection: Array<string | number>;

  @Prop({ default: true, type: Boolean })
  showPlaceholder: boolean;

  @Prop({ default: 85, type: Number })
  rowHeight: number;

  private hoveredRowId = null;
  private selectedRowIds = [];

  tableSorting: TIndexedObject = {};
  scrollObserver = null as IntersectionObserver;

  $refs: {
    scrollContainer: RecycleScroller;
    loadMoreTrigger: HTMLElement;
  };

  @Watch('selection', { immediate: true })
  handleSelectionChange(newVal: Array<string | number>) {
    this.selectedRowIdsSync = newVal;
  }

  @Watch('sorting', { immediate: true })
  handleFiltersChange(newVal: Array<string | number>) {
    this.tableSorting = newVal;
  }

  get selectedRowIdsSync() {
    return this.selectedRowIds;
  }
  set selectedRowIdsSync(value: Array<string | number>) {
    this.selectedRowIds = value;
    this.$emit('update:selection', value);
  }

  get isEmpty() {
    return !this.items.length;
  }

  get itemsToDisplay() {
    return this.items.map((r, index) => {
      const uniqKeyValue: number | string = getNestedProp(
        r,
        this.uniqueKeyPath
      );
      if (uniqKeyValue === undefined) {
        throw new Error(
          'AppTable.vue - Invalide prop "uniqueKeyPath" specified'
        );
      }

      return {
        ...r,
        _originalIndex: index,
        _appTableItemId: uniqKeyValue,
      };
    });
  }

  get rowsIds(): Array<string | number> {
    return this.itemsToDisplay.map((i) => i._appTableItemId);
  }

  get isAllRowSelected() {
    return this.rowsIds.every((rowId) =>
      this.selectedRowIdsSync.includes(rowId)
    );
  }

  get gridStyles() {
    return {
      gridTemplateColumns: this.selectable
        ? `40px repeat(${this.fields.length}, 1fr)`
        : `repeat(${this.fields.length}, 1fr)`,
    };
  }

  @Watch('isEmpty', { immediate: true })
  onIsEmptyChangeHandler() {
    if (!this.isEmpty) {
      this.$nextTick(() => {
        this.initScrollObserver();
      });
    } else {
      this.scrollObserver?.disconnect();
    }
  }

  beforeDestroy() {
    this.scrollObserver?.disconnect();
  }

  @Provide()
  filterHandler(key: string, direction: string) {
    const filtersCopy = {
      key: key,
      value: direction,
    };

    this.syncedSorting = filtersCopy;
    this.tableSorting = filtersCopy;

    this.$emit('filterChange', filtersCopy);
  }

  rowClickHandler(row: TTableRow) {
    this.$emit('row-click', row);
  }
  rowDoubleClickHandler(row: TTableRow) {
    this.$emit('row-double-click', row);
  }
  rowContextMenuHandler(evt, row: TTableRow) {
    const item = this.items[row._originalIndex];
    this.$emit('row-contextmenu', { evt, item });
  }

  selectAllRows() {
    this.selectedRowIdsSync = this.isAllRowSelected ? [] : this.rowsIds;
  }

  rowMouseoverHandler(row: TTableRow) {
    if (this.hoveredRowId === row._appTableItemId) return;

    this.hoveredRowId = row._appTableItemId;
  }

  rowMouseleaveHandler() {
    this.hoveredRowId = null;
  }

  isRowHovered(row: TTableRow) {
    return this.hoveredRowId === row._appTableItemId;
  }

  isRowSelected(row: TTableRow) {
    return this.selectedRowIds.includes(row._appTableItemId);
  }

  initScrollObserver() {
    const options = {
      root: this.$refs.scrollContainer.$el,
    };

    this.scrollObserver = new IntersectionObserver(([target]) => {
      if (target.isIntersecting && this.itemsToDisplay.length > 0) {
        this.$emit('load-more');
      }
    }, options);

    this.scrollObserver.observe(this.$refs.loadMoreTrigger);
  }
}
