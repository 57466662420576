import {
  INodeStyle,
  IRenderContext,
  INode,
  Visual,
  SvgVisualGroup,
  SvgVisual,
  IInputModeContext,
  Rect,
  Point,
  GeneralPath,
  VoidNodeStyle,
  ICanvasContext,
  ShapeNodeShape,
  ShapeNodeStyle,
  Fill,
  Stroke,
  SimpleNode,
  Insets,
  Size,
  GraphEditorInputMode,
  AdjacencyTypes,
} from 'yfiles';
import CycleShapeButtonDecorator from './decorators/CycleShapeButtonDecorator';
import EdgeCreationButtonDecorator from './decorators/EdgeCreationButtonDecorator';
import JurisdictionDecorator from './decorators/JurisdictionDecorator';
import IndicatorDecorators from './decorators/IndicatorDecorators';
import JigsawNodeDecorator from './decorators/JigsawNodeDecorator';
import QuickBuildButtonsNodeDecorator from './decorators/QuickBuildButtonsNodeDecorator';
import JigsawNodeStyleBase from './JigsawNodeStyleBase';
import DiagramUtils from '@/core/utils/DiagramUtils';
import { SvgDefs } from '@/core/services/graph/SvgDefs';
import GroupNodeStyle from './GroupNodeStyle';
import FilterDecorators from './decorators/FilterDecorators';
import { GraphComponent } from 'yfiles/typings/yfiles-api-npm';
import { AnnotationType } from '../common/AnnotationType';
import QuickStartDeleteButtonDecorator from '@/core/styles/decorators/QuickStartDeleteButtonDecorator';
import diagramConfig from '@/core/config/diagram.definition.config';
import DataPropertiesDecorator from './decorators/DataPropertiesDecorator';
/**
 * Jigsaw node style
 */
export default class JigsawNodeStyle extends JigsawNodeStyleBase {
  private _baseStyle: INodeStyle;
  private selectionVisualStyle: ShapeNodeStyle;
  private selectionVisualDummyNode: SimpleNode;
  private _selectionVisualPadding: Insets = new Insets(5);
  public get baseStyle(): INodeStyle {
    return this._baseStyle;
  }
  /**
   * Creates an instance of jigsaw node style.
   * @param baseStyle
   * @param [decorators] An array of decorators to attach to the node
   */
  constructor(baseStyle: INodeStyle, decorators?: JigsawNodeDecorator[]) {
    super(decorators);
    this._baseStyle = baseStyle;
    if (!decorators) {
      // Defining a standard set of decorators to apply to a node, if decorators are supplied then no defaults should be applied
      // this.addDecorator(CycleShapeButtonDecorator.INSTANCE);
      this.addDecorator(QuickBuildButtonsNodeDecorator.INSTANCE);
      this.addDecorator(IndicatorDecorators.INSTANCE);
      this.addDecorator(FilterDecorators.INSTANCE);
      this.addDecorator(JurisdictionDecorator.INSTANCE);
      this.addDecorator(EdgeCreationButtonDecorator.INSTANCE);
      this.addDecorator(QuickStartDeleteButtonDecorator.INSTANCE);
    }
  }

  createVisual(context: IRenderContext, node: INode): Visual {
    if (this._baseStyle instanceof VoidNodeStyle) {
      return null;
    }
    this.selectionVisualDummyNode = new SimpleNode();

    let baseVisual = this.baseStyle.renderer
      .getVisualCreator(node, this.baseStyle)
      .createVisual(context);

    let group = new SvgVisualGroup();
    group.add(baseVisual as SvgVisual);
    let decorators = super.createVisual(context, node) as SvgVisual;
    group.add(this.createSelectionVisual(context, node));
    group.add(decorators);

    DiagramUtils.updateSvgFilteredStyles(
      node,
      group.svgElement,
      SvgDefs.definitions.SEARCH_BLUR
    );
    return group;
  }

  updateVisual(context: IRenderContext, oldVisual: SvgVisual, node: INode) {
    if (this._baseStyle instanceof VoidNodeStyle) {
      return null;
    }
    let oldVisualGroup = oldVisual as SvgVisualGroup;
    let baseVisual = oldVisualGroup.children.get(0);
    baseVisual = this.baseStyle.renderer
      .getVisualCreator(node, this.baseStyle)
      .updateVisual(context, baseVisual) as SvgVisual;
    oldVisualGroup.children.set(0, baseVisual);

    const selectionVisual = this.updateSelectionVisual(
      context,
      oldVisualGroup.children.get(1) as SvgVisual,
      node
    );
    oldVisualGroup.children.set(1, selectionVisual);

    // update
    let decorators = oldVisualGroup.children.elementAt(2) as SvgVisualGroup;
    // D'Sync bug: temporary fix to uwrap decorators (possibly bug in yFiles)
    if (decorators.children.size != this.decorators.length) {
      decorators = decorators.children.get(2) as SvgVisualGroup;
    }

    decorators = super.updateVisual(
      context,
      decorators,
      node
    ) as SvgVisualGroup;

    oldVisualGroup.children.set(2, decorators);

    DiagramUtils.updateSvgFilteredStyles(
      node,
      oldVisualGroup.svgElement,
      SvgDefs.definitions.SEARCH_BLUR
    );

    return oldVisualGroup;
  }
  isHit(inputModeContext: IInputModeContext, location: Point, node: INode) {
    if (this._baseStyle instanceof GroupNodeStyle) {
      return this._baseStyle.isHit(inputModeContext, location, node);
    }
    return super.isHit(inputModeContext, location, node);
  }

  isInBox(context: IInputModeContext, rectangle: Rect, node: INode): boolean {
    // return only box containment test of baseStyle - we don't want the decoration to be marquee selectable
    return this.baseStyle.renderer
      .getMarqueeTestable(node, this.baseStyle)
      .isInBox(context, rectangle);
  }

  getIntersection(node: INode, inner: Point, outer: Point): Point | null {
    return this.baseStyle.renderer
      .getShapeGeometry(node, this.baseStyle)
      .getIntersection(inner, outer);
  }

  isInside(node: INode, location: Point): boolean {
    // return only inside test of baseStyle
    return this.baseStyle.renderer
      .getShapeGeometry(node, this.baseStyle)
      .isInside(location);
  }

  getOutline(node: INode): GeneralPath {
    return this.baseStyle.renderer
      .getShapeGeometry(node, this.baseStyle)
      .getOutline();
  }

  isVisible(context: ICanvasContext, rectangle: Rect, node: INode) {
    return this.baseStyle.renderer
      .getVisibilityTestable(node, this.baseStyle)
      .isVisible(context, rectangle);
  }

  clone(): any {
    return new JigsawNodeStyle(
      this.baseStyle.clone(),
      this.decorators.map((x) => x)
    );
  }

  getBounds(context: ICanvasContext, node: INode): Rect {
    return this.baseStyle.renderer
      .getBoundsProvider(node, this.baseStyle)
      .getBounds(context);
  }

  private setSelectionOpacity(
    context: IRenderContext,
    node: INode,
    visual: SvgVisual
  ) {
    const gc = context.canvasComponent as GraphComponent;
    if (
      node.tag.isAnnotation &&
      node.tag.annotationType == AnnotationType.ArrowHead
    ) {
      const geim = gc.inputMode as GraphEditorInputMode;
      if (geim) {
        const hoverInputMode = geim.itemHoverInputMode;
        if (hoverInputMode) {
          const relatedEdges = gc.graph.edgesAt(node, AdjacencyTypes.ALL);
          if (
            hoverInputMode.currentHoverItem === node ||
            relatedEdges.some(
              (edge) =>
                gc.selection.isSelected(edge) ||
                edge === hoverInputMode.currentHoverItem ||
                edge.sourceNode === hoverInputMode.currentHoverItem ||
                edge.targetNode === hoverInputMode.currentHoverItem ||
                gc.selection.isSelected(edge.sourceNode) ||
                gc.selection.isSelected(edge.targetNode)
            )
          ) {
            visual.svgElement.style.opacity = '1';
          } else {
            visual.svgElement.style.opacity = '0';
          }
        }
      }
    } else {
      visual.svgElement.style.opacity = gc.selection?.isSelected(node)
        ? '1'
        : '0';
    }
  }

  private createSelectionVisualStyle(node: INode): ShapeNodeStyle {
    const shape = DiagramUtils.getNodeShape(node) ?? ShapeNodeShape.RECTANGLE;
    if (
      node.tag.isAnnotation &&
      node.tag.annotationType == AnnotationType.ArrowHead
    ) {
      this.selectionVisualStyle = new ShapeNodeStyle({
        shape: shape,
        fill: Fill.from('transparent'),
        stroke: Stroke.from('2px solid red'),
      });
    } else {
      this.selectionVisualStyle = new ShapeNodeStyle({
        shape: shape,
        fill: Fill.from('transparent'),
        stroke: Stroke.from('1px solid rgb(45, 221, 215)'),
      });
    }

    return this.selectionVisualStyle;
  }

  private createSelectionVisual(
    context: IRenderContext,
    node: INode
  ): SvgVisual {
    this.createSelectionVisualStyle(node);

    if (node.tag.annotationType == AnnotationType.EdgeToNowhereNode) {
      return new SvgVisualGroup();
    }

    this.selectionVisualDummyNode.layout = this.getSelectionLayout(node);

    const visual = this.selectionVisualStyle.renderer
      .getVisualCreator(
        this.selectionVisualDummyNode,
        this.selectionVisualStyle
      )
      .createVisual(context) as SvgVisual;
    this.setSelectionOpacity(context, node, visual);
    return visual;
  }

  private updateSelectionVisual(
    context: IRenderContext,
    group: SvgVisual,
    node: INode
  ): SvgVisual {
    if (node.tag.annotationType == AnnotationType.EdgeToNowhereNode) {
      return new SvgVisualGroup();
    }
    this.selectionVisualDummyNode.layout = this.getSelectionLayout(node);

    const visual = this.selectionVisualStyle.renderer
      .getVisualCreator(
        this.selectionVisualDummyNode,
        this.selectionVisualStyle
      )
      .updateVisual(context, group) as SvgVisual;

    this.setSelectionOpacity(context, node, visual);
    return visual;
  }

  private getSelectionLayout(node: INode): Rect {
    const shape = this.selectionVisualStyle.shape;
    let insets = this.calculcateInsets(shape, node);

    let rect = node.layout.toRect().getEnlarged(insets);
    let widthAdjustment = 0;
    let heightAdjustment = 0;
    if (rect.width <= 0) {
      widthAdjustment = diagramConfig.grid.size;
    }
    if (rect.height <= 0) {
      heightAdjustment = diagramConfig.grid.size;
    }
    rect = new Rect(
      rect.toPoint(),
      new Size(rect.width + widthAdjustment, rect.height + heightAdjustment)
    );

    return rect;
  }

  private calculcateInsets(shape: ShapeNodeShape, node: INode): Insets {
    const insets = this._selectionVisualPadding;
    if (
      shape !== ShapeNodeShape.TRIANGLE &&
      shape !== ShapeNodeShape.TRIANGLE2
    ) {
      return insets;
    }
    const left = (node.layout.width * insets.left) / 100;
    const top = (node.layout.height * insets.top) / 100;
    const right = (node.layout.width * insets.right) / 100;
    const bottom = (node.layout.height * insets.bottom) / 100;
    return new Insets(left, top, right, bottom);
  }
}
