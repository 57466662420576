import { GraphCopier, IGraph, INode } from 'yfiles';

export default class GraphCopierHelper {
  public static copyGraph(sourceGraph: IGraph, targetGraph: IGraph) {
    const copier: GraphCopier = new GraphCopier({
      referentialIdentityTypes: 'tags',
    });
    copier.copy({
      sourceGraph: sourceGraph,
      filter: (item) => {
        return true;
      },
      elementCopiedCallback: (original, copy) => {
        if (original?.tag) {
          copy.tag = JSON.parse(JSON.stringify(original.tag));
        }
      },
      targetGraph: targetGraph,
    });
  }
}
