






























import { Component, Vue } from 'vue-property-decorator';
import IdleManager from '@/core/services/idle/IdleManager';
import { LogoutConfirmationConfig } from '@/core/config/LogoutConfirmationConfig';
import { Getter } from 'vuex-class';
import {
  GET_CURRENT_SETTINGS,
  GET_SETTINGS,
} from '@/core/services/store/settings.module';
import { ClientHostSettingsDto } from '@/api/models';
import { EventBusActions } from '@/core/services/events/eventbus.service';
@Component({
  name: 'ModalIdle',
})
export default class ModalIdle extends Vue {
  @Getter(GET_CURRENT_SETTINGS)
  currentSettings: ClientHostSettingsDto;

  get max(): number | null {
    return this.currentSettings.sessionTimeout
      ? this.currentSettings.sessionTimeout.showTimeOutNotificationSecond
      : 0;
  }
  value: number = 0;
  content: string = LogoutConfirmationConfig.content;
  timer: null | ReturnType<typeof setTimeout> = null;

  logout() {
    this.hideModal();
    this.eventBusEmit(EventBusActions.APPLICATION_SESSION_TIMED_OUT);
  }
  hideModal() {
    this.stopTimer();
    IdleManager.INSTANCE.restart();
    this.$bvModal.hide('modal-idle');
  }
  startTimer() {
    this.value =
      this.currentSettings.sessionTimeout.showTimeOutNotificationSecond;
    this.timer = setInterval(() => {
      this.value--;
      if (this.value === 0) {
        this.stopTimer();
        this.hideModal();
        this.logout();
      }
    }, 1000);
  }
  stopTimer() {
    clearInterval(this.timer);
    this.timer = null;
    this.value =
      this.currentSettings.sessionTimeout.showTimeOutNotificationSecond;
  }

  beforeDestroy() {
    this.stopTimer();
  }
}
