import { NodeShape } from '@/api/models';

export default [
  {
    name: 'Diamond',
    shape: NodeShape.Diamond,
    id: 70001,
    fill: '#ABDEE6',
  },
  {
    name: 'Circle',
    shape: NodeShape.Circle,
    id: 70002,
    fill: '#CBAACB',
  },
  {
    name: 'Arrow',
    shape: NodeShape.FatArrow,
    id: 70003,
    fill: '#FFFFB5',
  },
  {
    name: 'Arrow',
    shape: NodeShape.FatArrow2,
    id: 70004,
    fill: '#F3B0C3',
  },
  {
    name: 'Hexagon',
    shape: NodeShape.Hexagon,
    id: 70005,
    fill: '#C6DBDA',
  },
  {
    name: 'Rectangle',
    shape: NodeShape.Rectangle,
    id: 70006,
    fill: '#FEE1E8',
  },
  {
    name: 'Rounded Rectangle',
    shape: NodeShape.RoundedRectangle,
    id: 70007,
    fill: '#FED7C3',
  },
  {
    name: 'Sheared Rectangle',
    shape: NodeShape.ShearedRectangle,
    id: 70008,
    fill: '#CCE2CB',
  },
  {
    name: 'Sheared Rectangle',
    shape: NodeShape.ShearedRectangle2,
    id: 70009,
    fill: '#B6CFB6',
  },
  { name: 'Star 5', shape: NodeShape.Star5, id: 70010, fill: '#b7a8df' },
  { name: 'Star 6', shape: NodeShape.Star6, id: 70011, fill: '#d25b4f' },
  {
    name: 'Trapez',
    shape: NodeShape.Trapez,
    id: 70012,
    fill: '#55CBCD',
  },
  {
    name: 'Trapez',
    shape: NodeShape.Trapez2,
    id: 70013,
    fill: '#FCB9AA',
  },
  {
    name: 'Triangle',
    shape: NodeShape.Triangle,
    id: 70014,
    fill: '#ECEAE4',
  },
  {
    name: 'Triangle',
    shape: NodeShape.Triangle2,
    id: 70015,
    fill: '#ECD5E3',
  },
  {
    name: 'Pill',
    shape: NodeShape.Pill,
    id: 70016,
    fill: '#FFA500',
  },
];