import {
  DocumentPageContentType,
  DocumentPageType,
  PageElementPosition,
} from '@/api/models';
import { AnnotationType } from '@/core/common/AnnotationType';
import Point from '@/core/common/Point';
import ExportConfig from '@/core/config/ExportConfig';
import CycleShapeButtonDecorator from '@/core/styles/decorators/CycleShapeButtonDecorator';
import EdgeCreationButtonDecorator from '@/core/styles/decorators/EdgeCreationButtonDecorator';
import FilterDecorators from '@/core/styles/decorators/FilterDecorators';
import { JurisdictionUtils } from '@/core/styles/decorators/JurisdictionDecorator';
import QuickBuildButtonsNodeDecorator from '@/core/styles/decorators/QuickBuildButtonsNodeDecorator';
import QuickStartDeleteButtonDecorator from '@/core/styles/decorators/QuickStartDeleteButtonDecorator';
import DataPropertyUtils from '@/core/utils/DataPropertyUtils';
import DiagramUtils from '@/core/utils/DiagramUtils';
import { htmlStylesToInline } from '@/core/utils/html.utils';
import {
  BridgeManager,
  GraphComponent,
  GraphObstacleProvider,
  IGraph,
  Rect,
  Size,
  VoidNodeStyle,
} from 'yfiles';
import GraphCopierHelper from '../graph/GraphCopierHelper';
import JigsawGraphModelManager from '../graph/JigsawGraphModelManager';
import NodeIndicatorService from '../graph/NodeIndicatorService';
import { ExportFormat } from './ExportFormat';

export default class ExportUtils {
  public static calculatePageBodySize(pageType: DocumentPageType): Size {
    let width =
      ExportConfig.usablePageArea.width -
      (ExportConfig.pageMargins.left + ExportConfig.pageMargins.right);
    if (pageType == DocumentPageType.Split) {
      width /= 2;
      width -= ExportConfig.contentPadding;
    }
    let height =
      ExportConfig.usablePageArea.height -
      (ExportConfig.pageMargins.top + ExportConfig.pageMargins.bottom);
    return new Size(width, height);
  }

  public static calculateDiagramSize(pageType: DocumentPageType): Size {
    const size = this.calculatePageBodySize(pageType).toMutableSize();
    if (pageType == DocumentPageType.Split) {
      size.width -= ExportConfig.diagramHorizontalPadding;
      size.height -= ExportConfig.diagramVerticalPadding;
    }
    return size.toSize();
  }

  public static getPageHeaderSize(): Size {
    return new Size(ExportConfig.pageSize.width, ExportConfig.headerHeight);
  }

  public static getPageFooterSize(): Size {
    return new Size(ExportConfig.pageSize.width, ExportConfig.footerHeight);
  }

  public static getAdditionalElementPosition(
    contentRect: Rect,
    position: PageElementPosition | Point,
    imageHeight: number,
    imageWidth: number
  ): { x: number; y: number } {
    if (<PageElementPosition>position in PageElementPosition) {
      switch (position) {
        case PageElementPosition.BottomLeft:
          return {
            x: contentRect.bottomLeft.x,
            y: contentRect.bottomLeft.y + 10,
          };
        case PageElementPosition.BottomRight:
          return {
            x: contentRect.bottomRight.x - imageWidth,
            y: contentRect.bottomRight.y + 10,
          };
        case PageElementPosition.TopLeft:
          return {
            x: contentRect.topLeft.x,
            y: contentRect.topLeft.y - imageHeight - 10,
          };
        case PageElementPosition.TopRight:
          return {
            x: contentRect.topRight.x - imageWidth,
            y: contentRect.topRight.y - imageHeight - 10,
          };
      }
    } else {
      return {
        x: contentRect.width * 0.01 * (<Point>position).x,
        y: contentRect.height * 0.01 * (<Point>position).y,
      };
    }
    return null;
  }

  public static copyGraphComponent(
    sourceGraph: IGraph,
    withFilters: boolean,
    format: ExportFormat
  ) {
    const graphComponentCopy = new GraphComponent();
    graphComponentCopy.graphModelManager = new JigsawGraphModelManager(
      graphComponentCopy
    );
    const bridgeManager = new BridgeManager();
    bridgeManager.canvasComponent = graphComponentCopy;
    bridgeManager.addObstacleProvider(new GraphObstacleProvider());
    GraphCopierHelper.copyGraph(sourceGraph, graphComponentCopy.graph);
    graphComponentCopy.invalidate();

    graphComponentCopy.graph.nodes.forEach((node) => {
      const style = DiagramUtils.unwrapNodeStyle(node);
      style.removeDecorator(QuickBuildButtonsNodeDecorator.INSTANCE.$class);
      style.removeDecorator(CycleShapeButtonDecorator.INSTANCE.$class);
      style.removeDecorator(EdgeCreationButtonDecorator.INSTANCE.$class);
      style.removeDecorator(FilterDecorators.INSTANCE.$class);
      style.removeDecorator(QuickStartDeleteButtonDecorator.INSTANCE.$class);

      if (
        node &&
        node.tag.isAnnotation &&
        (node.tag.annotationType == AnnotationType.EdgeToNowhereNode ||
          node.tag.annotationType == AnnotationType.ArrowHead)
      ) {
        graphComponentCopy.graph.setStyle(node, new VoidNodeStyle());
      }
    });

    // Set indicator and data property decorator state
    graphComponentCopy.graph.nodes.forEach((node) => {
      NodeIndicatorService.syncIndicators(node);
      JurisdictionUtils.setJurisdictionDecorationState(
        node,
        graphComponentCopy
      );
    });

    if (withFilters) {
      const excludedNodes = graphComponentCopy.graph.nodes
        .filter((n) => n.tag?.isIncluded === false)
        .toArray();
      for (let node of excludedNodes) {
        graphComponentCopy.graph.remove(node);
      }

      const excludedEdges = graphComponentCopy.graph.edges
        .filter((e) => e.tag?.isIncluded === false)
        .toArray();
      for (let edge of excludedEdges) {
        graphComponentCopy.graph.remove(edge);
      }
    } else {
      for (let node of graphComponentCopy.graph.nodes) {
        node.tag.isIncluded = true;
      }

      for (let edge of graphComponentCopy.graph.edges) {
        edge.tag.isIncluded = true;
      }
    }

    // Group nodes are currently rendered as empty blocks in Visio
    // Remove all group nodes and background group visuals until we can sort this out
    if (format == ExportFormat.Visio) {
      graphComponentCopy.graph.nodes
        .filter((n) => n.tag?.isGroupNode)
        .toArray()
        .forEach((node) => {
          graphComponentCopy.graph.remove(node);
        });
    }

    // updateContentRect does not include group nodes in calculation so add offset manually
    graphComponentCopy.updateContentRect(ExportConfig.diagramMargins);
    return graphComponentCopy;
  }

  public static tryInlineContentStyles(
    contentType: DocumentPageContentType,
    content: string
  ) {
    if (content && contentType == DocumentPageContentType.Html) {
      content = htmlStylesToInline(content, {
        recursive: true,
        properties: [
          'font-size',
          'font-family',
          'font-style',
          'font-weight',
          'text-decoration-line',
        ],
        containerClassList: [ExportConfig.pageContentClass],
      });
    }

    return content;
  }
}
