import {
  CompositeNodeStyleShapeDto,
  CompositeShape,
  CompositeStyleDefinitionDto,
  DashStyleType,
  NodeShape,
  NodeSize,
  NodeVisualType,
  ShapeNodeStyleDto,
} from '@/api/models';
import { DefaultColors } from '@/core/common/DefaultColors';
import roundedRectangleWithTriangle2Definitions from './styledefinitions/RoundedRectangleWithTriangle2Definitions';
import ovalWithRectangleDefinitions from './styledefinitions/OvalWithRectangleDefinitions';
import ovalWithTriangleDefinitions from './styledefinitions/OvalWithTriangleDefinitions';
import roundedRectangleWithOvalDefinitions from './styledefinitions/RoundedRectangleWithOvalDefinitions';
import roundedRectangleWithTriangleDefinitions from './styledefinitions/RoundedRectangleWithTriangleDefinitions';
import triangleWithOvalDefinitions from './styledefinitions/TriangleWithOvalDefinitions';
import triangleWithRectangleDefinitions from './styledefinitions/TriangleWithRectangleDefinitions';
import rectangleWithTriangle2Definitions from './styledefinitions/RectangleWithTriangle2Definitions';
import ovalWithTriangle2Definitions from './styledefinitions/OvalWithTriangle2Definitions';
import rectangleWithTriangleDefinitions from './styledefinitions/RectangleWithTriangleDefinitions';
import rectangleWithCrossDefinitions from './styledefinitions/RectangleWithCrossDefinition';
import rectangleWithOvalDefinitions from './styledefinitions/RectangleWithOvalDefinitions';
import rectanglesWithOvalDefinitions from './styledefinitions/RectanglesWithOvalDefinitions';
import rectangleWithDiamondDefinitions from './styledefinitions/RectangleWithDiamond';
import rectangleWithRectangleDefinitions from './styledefinitions/RectangleWithRectangleDefinitions';
import rectangleWithRectangleWithRectangleDefinitions from './styledefinitions/RectangleWithRectangleWithRectangleDefinitions';
import triangleWithTriangleWithTriangleDefinitions from './styledefinitions/TriangleWithTriangleWithTriangleDefinitions';
import ovalWithOvalWithOvalDefinitions from './styledefinitions/OvalWithOvalWithOvalDefinitions';
import triangleStackedRightDefinitions from './styledefinitions/TriangleStackedRightDefinitions';
import triangleStackedLeftDefinitions from './styledefinitions/TriangleStackedLeftDefinitions';

export default class CompositeNodeStyleDefinitionsService {
  public static getStyleDefinitions(
    shape: CompositeShape
  ): CompositeStyleDefinitionDto[] {
    /**** */
    switch (+shape) {
      case CompositeShape.RoundedRectangleWithTriangle2:
        return roundedRectangleWithTriangle2Definitions();
      case CompositeShape.OvalWithRectangle:
        return ovalWithRectangleDefinitions();
      case CompositeShape.OvalWithTriangle:
        return ovalWithTriangleDefinitions();
      case CompositeShape.RoundedRectangleWithOval:
        return roundedRectangleWithOvalDefinitions();
      case CompositeShape.RoundedRectangleWithTriangle:
        return roundedRectangleWithTriangleDefinitions();
      case CompositeShape.RectangleWithTriangle:
        return rectangleWithTriangleDefinitions();
      case CompositeShape.TriangleWithOval:
        return triangleWithOvalDefinitions();
      case CompositeShape.TriangleWithRectangle:
        return triangleWithRectangleDefinitions();
      case CompositeShape.RectangleWithTriangle2:
        return rectangleWithTriangle2Definitions();
      case CompositeShape.OvalWithTriangle2:
        return ovalWithTriangle2Definitions();
      case CompositeShape.RectangleWithCross:
        return rectangleWithCrossDefinitions();
      case CompositeShape.RectangleWithOval:
        return rectangleWithOvalDefinitions();
      case CompositeShape.RectanglesWithOval:
        return rectanglesWithOvalDefinitions();
      case CompositeShape.RectangleWithDiamond:
        return rectangleWithDiamondDefinitions();
      case CompositeShape.RectangleWithRectangle:
        return rectangleWithRectangleDefinitions();
      case CompositeShape.RectangleWithRectangleWithRectangle:
        return rectangleWithRectangleWithRectangleDefinitions();
      case CompositeShape.TriangleWithTriangleWithTriangle:
        return triangleWithTriangleWithTriangleDefinitions();
      case CompositeShape.OvalWithOvalWithOval:
        return ovalWithOvalWithOvalDefinitions();
      case CompositeShape.TriangleStackedRight:
        return triangleStackedRightDefinitions();
      case CompositeShape.TriangleStackedLeft:
        return triangleStackedLeftDefinitions();
    }
  }
}
