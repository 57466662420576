class IsTenantAvailableInput {
    constructor(public tenancyName: string) {}
}
enum TenantAvailabilityState {
    Available = 1, InActive = 2, NotFound = 3
}
class IsTenantAvailableOutput {
    constructor(public state ? : TenantAvailabilityState, public tenantId ? : number, public serverRootAddress ? : string) {}
}
class ResolveTenantIdInput {
    constructor(public c ? : string) {}
}
class RegisterInput {
    constructor(public name: string, public surname: string, public userName: string, public emailAddress: string, public password: string, public captchaResponse ? : string) {}
}
class RegisterOutput {
    constructor(public canLogin: boolean) {}
}
class SendPasswordResetCodeInput {
    constructor(public emailAddress: string) {}
}
class ResetPasswordInput {
    constructor(public userId: number, public resetCode ? : string, public password ? : string, public returnUrl ? : string, public singleSignIn ? : string, public c ? : string) {}
}
class ResetPasswordOutput {
    constructor(public canLogin: boolean, public userName ? : string) {}
}
class SendEmailActivationLinkInput {
    constructor(public emailAddress: string) {}
}
class ActivateEmailInput {
    constructor(public userId: number, public confirmationCode ? : string, public c ? : string) {}
}
class ImpersonateInput {
    constructor(public userId: number, public tenantId ? : number) {}
}
class ImpersonateOutput {
    constructor(public impersonationToken ? : string, public tenancyName ? : string) {}
}
class DelegatedImpersonateInput {
    constructor(public userDelegationId: number) {}
}
class SwitchToLinkedAccountInput {
    constructor(public targetUserId: number, public targetTenantId ? : number) {}
}
class SwitchToLinkedAccountOutput {
    constructor(public switchAccountToken ? : string, public tenancyName ? : string) {}
}
class AuditLogListDto {
    constructor(public executionTime: string, public executionDuration: number, public id: number, public userId ? : number, public userName ? : string, public impersonatorTenantId ? : number, public impersonatorUserId ? : number, public serviceName ? : string, public methodName ? : string, public parameters ? : string, public clientIpAddress ? : string, public clientName ? : string, public browserInfo ? : string, public exception ? : string, public customData ? : string) {}
}
class PagedResultDtoOfAuditLogListDto {
    constructor(public totalCount: number, public items ? : AuditLogListDto[]) {}
}
class FileDto {
    constructor(public fileName: string, public fileToken: string, public fileType ? : string) {}
}
class NameValueDto {
    constructor(public name ? : string, public value ? : string) {}
}
enum EntityChangeType {
    Created = 0, Updated = 1, Deleted = 2
}
class EntityChangeListDto {
    constructor(public changeTime: string, public entityChangeSetId: number, public id: number, public userId ? : number, public userName ? : string, public entityTypeFullName ? : string, public changeType ? : EntityChangeType, public changeTypeName ? : string) {}
}
class PagedResultDtoOfEntityChangeListDto {
    constructor(public totalCount: number, public items ? : EntityChangeListDto[]) {}
}
class EntityPropertyChangeDto {
    constructor(public entityChangeId: number, public id: number, public newValue ? : string, public originalValue ? : string, public propertyName ? : string, public propertyTypeFullName ? : string, public tenantId ? : number) {}
}
class CacheDto {
    constructor(public name ? : string) {}
}
class ListResultDtoOfCacheDto {
    constructor(public items ? : CacheDto[]) {}
}
class EntityDtoOfString {
    constructor(public id ? : string) {}
}
enum FriendshipState {
    Accepted = 1, Blocked = 2
}
class FriendDto {
    constructor(public friendUserId: number, public unreadMessageCount: number, public isOnline: boolean, public friendTenantId ? : number, public friendUserName ? : string, public friendTenancyName ? : string, public friendProfilePictureId ? : string, public state ? : FriendshipState) {}
}
class GetUserChatFriendsWithSettingsOutput {
    constructor(public serverTime: string, public friends ? : FriendDto[]) {}
}
enum ChatSide {
    Sender = 1, Receiver = 2
}
enum ChatMessageReadState {
    Unread = 1, Read = 2
}
class ChatMessageDto {
    constructor(public userId: number, public targetUserId: number, public creationTime: string, public id: number, public tenantId ? : number, public targetTenantId ? : number, public side ? : ChatSide, public readState ? : ChatMessageReadState, public receiverReadState ? : ChatMessageReadState, public message ? : string, public sharedMessageId ? : string) {}
}
class ListResultDtoOfChatMessageDto {
    constructor(public items ? : ChatMessageDto[]) {}
}
class MarkAllUnreadMessagesOfUserAsReadInput {
    constructor(public userId: number, public tenantId ? : number) {}
}
class SubscribableEditionComboboxItemDto {
    constructor(public isSelected: boolean, public isFree ? : boolean, public value ? : string, public displayText ? : string) {}
}
class ListResultDtoOfSubscribableEditionComboboxItemDto {
    constructor(public items ? : SubscribableEditionComboboxItemDto[]) {}
}
class FindUsersInput {
    constructor(public excludeCurrentUser: boolean, public maxResultCount: number, public skipCount: number, public tenantId ? : number, public filter ? : string) {}
}
class PagedResultDtoOfNameValueDto {
    constructor(public totalCount: number, public items ? : NameValueDto[]) {}
}
class GetDefaultEditionNameOutput {
    constructor(public name ? : string) {}
}
class Widget {
    constructor(public height: number, public width: number, public positionX: number, public positionY: number, public widgetId ? : string) {}
}
class Page {
    constructor(public id ? : string, public name ? : string, public widgets ? : Widget[]) {}
}
class Dashboard {
    constructor(public dashboardName ? : string, public pages ? : Page[]) {}
}
class SavePageInput {
    constructor(public dashboardName ? : string, public application ? : string, public pages ? : Page[]) {}
}
class RenamePageInput {
    constructor(public dashboardName ? : string, public id ? : string, public name ? : string, public application ? : string) {}
}
class AddNewPageInput {
    constructor(public dashboardName ? : string, public name ? : string, public application ? : string) {}
}
class AddNewPageOutput {
    constructor(public pageId ? : string) {}
}
class AddWidgetInput {
    constructor(public width: number, public height: number, public widgetId ? : string, public pageId ? : string, public dashboardName ? : string, public application ? : string) {}
}
class WidgetFilterOutput {
    constructor(public id ? : string, public name ? : string) {}
}
class WidgetOutput {
    constructor(public id ? : string, public name ? : string, public description ? : string, public filters ? : WidgetFilterOutput[]) {}
}
class DashboardOutput {
    constructor(public name ? : string, public widgets ? : WidgetOutput[]) {}
}
class DataPropertyDto {
    constructor(public id: number, public uuid ? : string, public value ? : any, public dataPropertyDefinitionId ? : number, public diagramId ? : number, public diagramNodeId ? : number, public diagramEdgeId ? : number) {}
}
class GetDataPropertyForViewDto {
    constructor(public dataProperty ? : DataPropertyDto, public diagramName ? : string) {}
}
class PagedResultDtoOfGetDataPropertyForViewDto {
    constructor(public totalCount: number, public items ? : GetDataPropertyForViewDto[]) {}
}
class CreateOrEditDataPropertyDto {
    constructor(public uuid ? : string, public value ? : string, public dataPropertyDefinitionId ? : number, public diagramId ? : number, public diagramNodeId ? : number, public diagramEdgeId ? : number, public id ? : number) {}
}
enum DataPropertyType {
    Text = 0, Number = 1, DateTime = 2, Date = 3, Time = 4, Dropdown = 5, MultiSelect = 6, Html = 7, Boolean = 8, Range = 9, Options = 10, MultiOptions = 11
}
enum DataPropertyValueScope {
    System = 0, Diagram = 1, Decorator = 2
}
class DataPropertyDefinitionItemDto {
    constructor(public itemValue ? : string, public isDeleted ? : boolean, public itemInitials ? : string, public dataPropertyDefinitionId ? : number, public imageData ? : string, public parentItemId ? : number, public id ? : number) {}
}
class DataPropertyDefinitionDto {
    constructor(public min ? : number, public max ? : number, public controlType ? : DataPropertyType, public valueScope ? : DataPropertyValueScope, public label ? : string, public dataPropertyDefinitionItems ? : DataPropertyDefinitionItemDto[], public documentId ? : number, public hint ? : string, public id ? : number) {}
}
class GetDataPropertyDefinitionForViewDto {
    constructor(public dataPropertyDefinition ? : DataPropertyDefinitionDto) {}
}
class CreateOrEditDataPropertyDefinitionDto {
    constructor(public key ? : string, public dataPropertyDefinitionItems ? : DataPropertyDefinitionItemDto[], public min ? : number, public max ? : number, public controlType ? : DataPropertyType, public valueScope ? : DataPropertyValueScope, public label ? : string, public documentId ? : number, public hint ? : string, public id ? : number) {}
}
class GetDataPropertyDefinitionForEditOutput {
    constructor(public dataPropertyDefinition ? : CreateOrEditDataPropertyDefinitionDto) {}
}
enum DataPropertyTagContext {
    Property = 0, Value = 1, Attachment = 2
}
class DataPropertyTagDto {
    constructor(public context ? : DataPropertyTagContext, public name ? : string, public color ? : string, public diagramNodeId ? : number, public diagramEdgeId ? : number, public dataPropertyUuid ? : string, public dataPropertyValue ? : string, public dataPropertyDefinitionId ? : number, public fileAttachmentId ? : number, public id ? : number) {}
}
class GetDataPropertyTagForViewDto {
    constructor(public dataPropertyTag ? : DataPropertyTagDto) {}
}
class PagedResultDtoOfGetDataPropertyTagForViewDto {
    constructor(public totalCount: number, public items ? : GetDataPropertyTagForViewDto[]) {}
}
class DataPropertyValueDto {
    constructor(public id: number, public dataPropertyItemId ? : number) {}
}
class GetDataPropertyValueForViewDto {
    constructor(public dataPropertyValue ? : DataPropertyValueDto, public dataPropertyItemItemValue ? : string) {}
}
class PagedResultDtoOfGetDataPropertyValueForViewDto {
    constructor(public totalCount: number, public items ? : GetDataPropertyValueForViewDto[]) {}
}
class CreateOrEditDataPropertyValueDto {
    constructor(public dataPropertyItemId ? : number, public id ? : number) {}
}
class GetDataPropertyValueForEditOutput {
    constructor(public dataPropertyValue ? : CreateOrEditDataPropertyValueDto, public dataPropertyItemItemValue ? : string) {}
}
class DataPropertyValueDataPropertyItemLookupTableDto {
    constructor(public id: number, public displayName ? : string) {}
}
class PagedResultDtoOfDataPropertyValueDataPropertyItemLookupTableDto {
    constructor(public totalCount: number, public items ? : DataPropertyValueDataPropertyItemLookupTableDto[]) {}
}
class DateToStringOutput {
    constructor(public dateString ? : string) {}
}
class NameValueOfString {
    constructor(public name ? : string, public value ? : string) {}
}
class StringOutput {
    constructor(public output ? : string) {}
}
class HeaderFooterStyleDto {
    constructor(public backgroundColor ? : string, public borderColor ? : string, public borderWidth ? : number, public borderStyle ? : string, public dividerColor ? : string, public dividerHeight ? : number, public dividerImgFileId ? : string, public backgroundImgFileId ? : string) {}
}
enum DocumentPageType {
    Diagram = 0, Split = 1, Content = 2
}
enum DocumentPageContentType {
    None = 0, Html = 1, Layout = 2
}
class LayoutDto {
    constructor(public x: number, public y: number, public width: number, public height: number) {}
}
class TemplatePageItemStyle {
    constructor(public backgroundColor ? : string, public borderStyle ? : string, public borderWidth ? : string, public borderColor ? : string, public textOverflow ? : string, public overflow ? : string, public fontSize ? : string, public fontFamily ? : string, public fontWeight ? : string, public fontStyle ? : string, public textDecoration ? : string, public color ? : string) {}
}
class TemplatePageItemMeta {
    constructor(public imageSrc ? : string, public html ? : string, public style ? : TemplatePageItemStyle) {}
}
class TemplatePageItem {
    constructor(public type ? : string, public layout ? : LayoutDto, public meta ? : TemplatePageItemMeta) {}
}
class TemplatePage {
    constructor(public pageItems ? : TemplatePageItem[]) {}
}
enum NodeVisualType {
    Shape = 0, Image = 1, Composite = 2, Group = 3
}
class INodeStyleDto {
    constructor(public visualType ? : NodeVisualType) {}
}
class FileAttachmentDto {
    constructor(public fileId: string, public id: number, public filename ? : string, public mimeType ? : string, public description ? : string, public diagramId ? : number, public diagramNodeId ? : number, public diagramEdgeId ? : number, public dataPropertyDefinitionId ? : number, public dataPropertyUuid ? : string, public dataPropertyValue ? : string, public path ? : string, public fileData ? : string) {}
}
class PowerPointDiagramNodeDto {
    constructor(public isGroupNode: boolean, public children ? : PowerPointDiagramNodeDto[], public svgPath ? : string, public uuid ? : string, public data ? : any, public layout ? : LayoutDto, public style ? : INodeStyleDto, public groupUuid ? : string, public label ? : string, public isIncluded ? : boolean, public dataProperties ? : DataPropertyDto[], public dataPropertyTags ? : DataPropertyTagDto[], public elementBehaviourId ? : number, public attachments ? : FileAttachmentDto[], public id ? : number) {}
}
class FontDto {
    constructor(public fontSize: number, public fontFamily ? : string, public fontStyle ? : string, public fontWeight ? : string, public textDecoration ? : string) {}
}
class FillDto {
    constructor(public color ? : string) {}
}
class LabelStyleDto {
    constructor(public font ? : FontDto, public fill ? : FillDto) {}
}
enum DashStyleType {
    Solid = 0, Dash = 1, Dot = 2, DashDot = 3, DashDotDot = 4
}
class DashStyleDto {
    constructor(public dashes ? : number[], public offset ? : number, public type ? : DashStyleType) {}
}
class StrokeDto {
    constructor(public thickness: number, public fill ? : FillDto, public dashStyle ? : DashStyleDto) {}
}
class ArrowStyleDto {
    constructor(public scale: number, public type ? : string, public fill ? : FillDto) {}
}
enum EdgeVisualType {
    Curved = 0, Straight = 1, Elbow = 2, Arc = 3
}
class EdgeStyleDto {
    constructor(public bridge: boolean, public labelStyle ? : LabelStyleDto, public stroke ? : StrokeDto, public sourceArrow ? : ArrowStyleDto, public targetArrow ? : ArrowStyleDto, public visualType ? : EdgeVisualType, public height ? : number) {}
}
enum PortDirection {
    Unknown = 0, South = 1, North = 2, West = 4, East = 8
}
class EdgePortDto {
    constructor(public x: number, public y: number, public portDirection ? : PortDirection) {}
}
enum PathCommandTypes {
    MOVE_TO = 0, LINE_TO = 1, QUAD_TO = 2, CUBIC_TO = 3, CLOSE = 4
}
class PointDto {
    constructor(public x: number, public y: number) {}
}
class PathCommand {
    constructor(public type ? : PathCommandTypes, public point ? : PointDto) {}
}
class PowerPointDiagramEdgeDto {
    constructor(public svgPath ? : string, public labelLayout ? : LayoutDto, public data ? : any, public style ? : EdgeStyleDto, public bends ? : any, public sourcePort ? : EdgePortDto, public targetPort ? : EdgePortDto, public uuid ? : string, public sourceNodeUuid ? : string, public targetNodeUuid ? : string, public pathCommands ? : PathCommand[], public label ? : string, public isIncluded ? : boolean, public dataProperties ? : DataPropertyDto[], public dataPropertyTags ? : DataPropertyTagDto[], public elementBehaviourId ? : number, public attachments ? : FileAttachmentDto[], public id ? : number) {}
}
enum TCellContentType {
    String = 0, PngImage = 1
}
class TCellContentDto {
    constructor(public contentType ? : TCellContentType, public data ? : any, public options ? : any) {}
}
class TCellDto {
    constructor(public content ? : TCellContentDto, public horizontalMerge ? : boolean, public gridSpan ? : number) {}
}
class TRowOptionsDto {
    constructor(public height: number) {}
}
class TRowDto {
    constructor(public cells ? : TCellDto[], public options ? : TRowOptionsDto) {}
}
class TColumnOptionsDto {
    constructor(public width: number) {}
}
class TColumnDto {
    constructor(public options ? : TColumnOptionsDto) {}
}
enum PageElementPosition {
    Hidden = 0, TopLeft = 1, TopRight = 2, BottomLeft = 3, BottomRight = 4, Custom = 5
}
class TDataDto {
    constructor(public rows ? : TRowDto[], public columns ? : TColumnDto[], public location ? : PointDto, public position ? : PageElementPosition) {}
}
enum DiagramPosition {
    Left = 0, Right = 1
}
class PowerPointExportSlideDto {
    constructor(public showHeader: boolean, public showFooter: boolean, public slideNumber: number, public showLogo: boolean, public documentPageType ? : DocumentPageType, public documentPageContentType ? : DocumentPageContentType, public templatePage ? : TemplatePage, public nodes ? : PowerPointDiagramNodeDto[], public edges ? : PowerPointDiagramEdgeDto[], public textPanelContent ? : string[], public tables ? : TDataDto[], public diagramPosition ? : DiagramPosition, public headerHtml ? : string, public footerHtml ? : string) {}
}
class PowerPointExportDto {
    constructor(public showSlideNumber: boolean, public isStepsDocument: boolean, public headerStyle ? : HeaderFooterStyleDto, public footerStyle ? : HeaderFooterStyleDto, public backgroundImageFileAttachmentId ? : string, public logoFileAttachmentId ? : string, public slides ? : PowerPointExportSlideDto[], public logoPosition ? : PageElementPosition) {}
}
class PowerPointFileDto {
    constructor(public base64String ? : string, public metaData ? : any) {}
}
class DataExportFileAttachmentDto {
    constructor(public id: number, public fileId ? : string, public fileName ? : string, public mimeType ? : string) {}
}
class DataExportDataPropertyDto {
    constructor(public id: number, public text ? : string, public definition ? : string, public attachments ? : DataExportFileAttachmentDto[]) {}
}
class DataExportNodeDto {
    constructor(public id: number, public text ? : string, public symbol ? : string, public properties ? : DataExportDataPropertyDto[], public attachments ? : DataExportFileAttachmentDto[]) {}
}
class DataExportEdgeDto {
    constructor(public id: number, public sourceNodeId: number, public targetNodeId: number, public text ? : string, public symbol ? : string, public properties ? : DataExportDataPropertyDto[], public attachments ? : DataExportFileAttachmentDto[]) {}
}
class DataExportDiagramDto {
    constructor(public diagramId: number, public diagramName ? : string, public nodes ? : DataExportNodeDto[], public edges ? : DataExportEdgeDto[]) {}
}
class DataExportDocumentDto {
    constructor(public documentId: number, public documentName ? : string, public documentFile ? : string, public documentFileName ? : string, public documentMimeType ? : string, public diagrams ? : DataExportDiagramDto[]) {}
}
class DiagramImportRowValueDto {
    constructor(public id: number, public data ? : string, public diagramImportRowId ? : number, public diagramTemplateId ? : number) {}
}
class DiagramImportRowDto {
    constructor(public processed: string, public id: number, public name ? : string, public diagramImportId ? : number, public values ? : DiagramImportRowValueDto[]) {}
}
class GetDiagramImportRowForViewDto {
    constructor(public diagramImportRow ? : DiagramImportRowDto, public diagramImportName ? : string) {}
}
class PagedResultDtoOfGetDiagramImportRowForViewDto {
    constructor(public totalCount: number, public items ? : GetDiagramImportRowForViewDto[]) {}
}
class CreateOrEditDiagramImportRowDto {
    constructor(public processed: string, public name ? : string, public diagramImportId ? : number, public id ? : number) {}
}
class GetDiagramImportRowForEditOutput {
    constructor(public diagramImportRow ? : CreateOrEditDiagramImportRowDto, public diagramImportName ? : string) {}
}
class DiagramImportRowDiagramImportLookupTableDto {
    constructor(public id: number, public displayName ? : string) {}
}
class PagedResultDtoOfDiagramImportRowDiagramImportLookupTableDto {
    constructor(public totalCount: number, public items ? : DiagramImportRowDiagramImportLookupTableDto[]) {}
}
class GetDiagramImportRowValueForViewDto {
    constructor(public diagramImportRowValue ? : DiagramImportRowValueDto, public diagramImportRowName ? : string, public diagramTemplateName ? : string) {}
}
class PagedResultDtoOfGetDiagramImportRowValueForViewDto {
    constructor(public totalCount: number, public items ? : GetDiagramImportRowValueForViewDto[]) {}
}
class CreateOrEditDiagramImportRowValueDto {
    constructor(public data ? : string, public diagramImportRowId ? : number, public diagramTemplateId ? : number, public id ? : number) {}
}
class GetDiagramImportRowValueForEditOutput {
    constructor(public diagramImportRowValue ? : CreateOrEditDiagramImportRowValueDto, public diagramImportRowName ? : string, public diagramTemplateName ? : string) {}
}
class DiagramImportRowValueDiagramImportRowLookupTableDto {
    constructor(public id: number, public displayName ? : string) {}
}
class PagedResultDtoOfDiagramImportRowValueDiagramImportRowLookupTableDto {
    constructor(public totalCount: number, public items ? : DiagramImportRowValueDiagramImportRowLookupTableDto[]) {}
}
class DiagramImportRowValueDiagramTemplateLookupTableDto {
    constructor(public id: number, public displayName ? : string) {}
}
class PagedResultDtoOfDiagramImportRowValueDiagramTemplateLookupTableDto {
    constructor(public totalCount: number, public items ? : DiagramImportRowValueDiagramTemplateLookupTableDto[]) {}
}
class DiagramImportDto {
    constructor(public exportReady: boolean, public generateDiagram: boolean, public rowCount: number, public creationTime: string, public id: number, public diagramId ? : number, public name ? : string, public fileImportObjectId ? : string, public fileExportObjectId ? : string, public exportType ? : string) {}
}
class GetDiagramImportForViewDto {
    constructor(public diagramImport ? : DiagramImportDto, public diagramName ? : string) {}
}
class PagedResultDtoOfGetDiagramImportForViewDto {
    constructor(public totalCount: number, public items ? : GetDiagramImportForViewDto[]) {}
}
class CreateOrEditDiagramImportDto {
    constructor(public diagramId ? : number, public id ? : number) {}
}
class GetDiagramImportForEditOutput {
    constructor(public diagramImport ? : CreateOrEditDiagramImportDto, public diagramName ? : string) {}
}
class DiagramImportDiagramLookupTableDto {
    constructor(public id: number, public displayName ? : string) {}
}
class PagedResultDtoOfDiagramImportDiagramLookupTableDto {
    constructor(public totalCount: number, public items ? : DiagramImportDiagramLookupTableDto[]) {}
}
class DiagramNodeDto {
    constructor(public isGroupNode: boolean, public uuid ? : string, public data ? : any, public layout ? : LayoutDto, public style ? : INodeStyleDto, public groupUuid ? : string, public label ? : string, public isIncluded ? : boolean, public dataProperties ? : DataPropertyDto[], public dataPropertyTags ? : DataPropertyTagDto[], public elementBehaviourId ? : number, public attachments ? : FileAttachmentDto[], public id ? : number) {}
}
class DiagramEdgeDto {
    constructor(public data ? : any, public style ? : EdgeStyleDto, public bends ? : any, public sourcePort ? : EdgePortDto, public targetPort ? : EdgePortDto, public uuid ? : string, public sourceNodeUuid ? : string, public targetNodeUuid ? : string, public pathCommands ? : PathCommand[], public label ? : string, public isIncluded ? : boolean, public dataProperties ? : DataPropertyDto[], public dataPropertyTags ? : DataPropertyTagDto[], public elementBehaviourId ? : number, public attachments ? : FileAttachmentDto[], public id ? : number) {}
}
enum DiagramTemplateType {
    Text = 0, Table = 1, Header = 2, Footer = 3, FileName = 4
}
class DiagramTemplateDto {
    constructor(public isConditional: boolean, public diagramId: number, public id: number, public name ? : string, public elementUuid ? : string, public templateType ? : DiagramTemplateType) {}
}
enum QuickBuildState {
    Initial = 0, InProgress = 1, Complete = 2
}
class DiagramDto {
    constructor(public isTemplate: boolean, public id: number, public name ? : string, public description ? : string, public thumb ? : string, public image ? : string, public legend ? : any, public nodes ? : DiagramNodeDto[], public edges ? : DiagramEdgeDto[], public templates ? : DiagramTemplateDto[], public attachments ? : FileAttachmentDto[], public dataProperties ? : DataPropertyDto[], public quickBuildState ? : QuickBuildState) {}
}
class GetDiagramForViewDto {
    constructor(public diagram ? : DiagramDto) {}
}
class CloneDiagramDto {
    constructor(public documentId: number, public id: number, public diagram ? : DiagramDto) {}
}
class AllConnection {
    constructor(public fig0_id: number, public fig1_id: number, public line_id ? : number[]) {}
}
class SemanticConnection {
    constructor(public fig0_id: number, public fig1_id: number, public line_id ? : number[]) {}
}
class NodeConnection {
    constructor(public fig_0: number, public fig_1: number) {}
}
class Connections {
    constructor(public all_connections ? : AllConnection[], public semantic_connections ? : SemanticConnection[], public node_connections ? : NodeConnection[]) {}
}
class Cv {
    constructor(public num_classes: number, public boxes ? : [][], public labels ? : string[], public keypoints ? : [][], public scores ? : null[], public ids ? : number[], public connections ? : Connections) {}
}
class Ocr {
    constructor(public labels ? : string[], public boxes ? : [][], public confidences ? : null[]) {}
}
class AIOutputDto {
    constructor(public cv ? : Cv, public ocr ? : Ocr) {}
}
class FileBase64Dto {
    constructor(public fileName ? : string, public mimeType ? : string, public base64Data ? : string) {}
}
class GetDiagramTemplateForViewDto {
    constructor(public diagramTemplate ? : DiagramTemplateDto) {}
}
class PagedResultDtoOfGetDiagramTemplateForViewDto {
    constructor(public totalCount: number, public items ? : GetDiagramTemplateForViewDto[]) {}
}
class CreateOrEditDiagramTemplateDto {
    constructor(public isConditional: boolean, public name ? : string, public diagramId ? : number, public elementUuid ? : string, public templateType ? : DiagramTemplateType, public id ? : number) {}
}
class GetDiagramTemplateForEditOutput {
    constructor(public diagramTemplate ? : CreateOrEditDiagramTemplateDto) {}
}
class UserDto {
    constructor(public id: number, public name ? : string, public surname ? : string, public emailAddress ? : string) {}
}
enum DocumentAccessLevel {
    View = 0, Edit = 1, EditShare = 2
}
class DocumentAccessDto {
    constructor(public documentId: number, public userId: number, public user ? : UserDto, public accessLevel ? : DocumentAccessLevel) {}
}
class DocumentAccessRequestDto {
    constructor(public documentId: number, public userId: number, public user ? : UserDto) {}
}
class PendingAccessRequestDto {
    constructor(public documentId: number, public userId: number, public creationTime: string, public documentName ? : string, public documentThumb ? : string, public user ? : UserDto) {}
}
class GetPendingAccessRequestsOutputDto {
    constructor(public pendingAccessRequests ? : PendingAccessRequestDto[], public mostRecentRequest ? : string) {}
}
class RequestDocumentAccessDto {
    constructor(public documentId: number, public message ? : string, public accessLevel ? : string) {}
}
class GrantDocumentAccessDto {
    constructor(public documentId: number, public userId: number, public accessLevel ? : DocumentAccessLevel, public message ? : string) {}
}
class ChangeDocumentAccessDto {
    constructor(public documentId: number, public userId: number) {}
}
class ChangeDocumentIsPublicDto {
    constructor(public documentId: number, public isPublic: boolean) {}
}
class ChangeDocumentDefaultAccessLevelDto {
    constructor(public documentId: number, public accessLevel ? : DocumentAccessLevel) {}
}
class DocumentStepDto {
    constructor(public order: number, public documentId: number, public id: number, public name ? : string, public description ? : string, public pages ? : DocumentPageDto[], public parentDocumentStepId ? : number, public parentDocumentStep ? : DocumentStepDto) {}
}
enum DocumentPageLayoutType {
    None = 0, Cover = 1, Filler = 2, Closing = 3
}
enum PageOrientation {
    Portrait = 0, Landscape = 1
}
class DocumentSubPageDto {
    constructor(public pageId: number, public diagramId: number, public subPageIndex: number, public page ? : DocumentPageDto, public diagram ? : DiagramDto, public headerHtml ? : string, public footerHtml ? : string, public id ? : number) {}
}
class DocumentPageDto {
    constructor(public documentId: number, public order: number, public contentColumns: number, public showLogo: boolean, public showLegend: boolean, public showHeader: boolean, public showFooter: boolean, public isPristine: boolean, public documentStepId ? : number, public diagramId ? : number, public name ? : string, public description ? : string, public diagram ? : DiagramDto, public documentStep ? : DocumentStepDto, public content ? : string, public headerHtml ? : string, public footerHtml ? : string, public pageType ? : DocumentPageType, public contentType ? : DocumentPageContentType, public templateType ? : DocumentPageLayoutType, public orientation ? : PageOrientation, public diagramPosition ? : DiagramPosition, public filterDefinition ? : string, public subPageRefs ? : DocumentSubPageDto[], public id ? : number) {}
}
class GetDocumentPageForViewDto {
    constructor(public documentPage ? : DocumentPageDto) {}
}
class DocumentFilterDto {
    constructor(public order: number, public isSystem: boolean, public name ? : string, public definition ? : string, public documentId ? : number, public id ? : number) {}
}
class CreateOrEditDocumentPageDto {
    constructor(public documentId: number, public order: number, public contentColumns: number, public showLogo: boolean, public showLegend: boolean, public showHeader: boolean, public showFooter: boolean, public isPristine: boolean, public name ? : string, public description ? : string, public content ? : string, public headerHtml ? : string, public footerHtml ? : string, public filters ? : DocumentFilterDto[], public documentStepId ? : number, public diagramId ? : number, public documentStep ? : DocumentStepDto, public diagram ? : DiagramDto, public pageType ? : DocumentPageType, public contentType ? : DocumentPageContentType, public templateType ? : DocumentPageLayoutType, public orientation ? : PageOrientation, public diagramPosition ? : DiagramPosition, public filterDefinition ? : string, public subPageRefs ? : DocumentSubPageDto[], public id ? : number) {}
}
class InsertNewPageOutputDto {
    constructor(public documentPageId: number, public diagramId ? : number) {}
}
class StepsPagePropertiesDto {
    constructor(public showHeader: boolean, public showFooter: boolean, public showLegend: boolean, public showLogo: boolean, public order: number, public pageType ? : DocumentPageType, public contentType ? : DocumentPageContentType, public templateType ? : DocumentPageLayoutType, public content ? : string, public id ? : number) {}
}
enum PagePosition {
    First = 0, AfterFirst = 1, BeforeLast = 2, Last = 3, BeforeSelected = 4, AfterSelected = 5
}
class PageTemplateDto {
    constructor(public content ? : any, public defaultFont ? : FontDto, public pagePosition ? : PagePosition) {}
}
enum DocumentAttachmentType {
    Logo = 0, CoverImage = 1, HeaderSeparator = 2, FooterSeparator = 3, FillerImage = 4, ClosingImage = 5, HeaderBackground = 6, FooterBackground = 7, PageBackground = 8
}
class DocumentAttachmentDto {
    constructor(public documentId: number, public fileAttachmentId: number, public document ? : DocumentDto, public fileAttachment ? : FileAttachmentDto, public attachmentType ? : DocumentAttachmentType, public id ? : number) {}
}
class FontStyleDto {
    constructor(public isDefault: boolean, public cssElement ? : string, public model ? : string, public title ? : string, public style ? : FontDto) {}
}
class NodeStyleOverrideDto {
    constructor(public fill ? : FillDto, public stroke ? : StrokeDto) {}
}
class DataPropertyStyleDto {
    constructor(public dataPropertyDefinitionId: number, public value ? : any, public style ? : NodeStyleOverrideDto) {}
}
class DocumentDto {
    constructor(public lastSelectedThemeId: number, public isPublic: boolean, public isPristine: boolean, public hasSteps: boolean, public showHeader: boolean, public showFooter: boolean, public showPageNumbering: boolean, public ownerUserId: number, public importProcessed: boolean, public autoSave: boolean, public isDeleted: boolean, public creationTime: string, public id: number, public pages ? : DocumentPageDto[], public steps ? : DocumentStepDto[], public dataPropertyDefinitions ? : DataPropertyDefinitionDto[], public filters ? : DocumentFilterDto[], public thumb ? : string, public headerHtml ? : string, public footerHtml ? : string, public coverPageTemplate ? : PageTemplateDto, public fillerPageTemplate ? : PageTemplateDto, public closingPageTemplate ? : PageTemplateDto, public pdfFileId ? : string, public pdfData ? : string, public headerStyle ? : HeaderFooterStyleDto, public footerStyle ? : HeaderFooterStyleDto, public logoPosition ? : PageElementPosition, public legendPosition ? : PageElementPosition, public attachments ? : DocumentAttachmentDto[], public tableSwatch ? : any, public fontStyles ? : FontStyleDto[], public lockedByUser ? : UserDto, public lockedCreated ? : string, public defaultPageType ? : DocumentPageType, public defaultAccessLevel ? : DocumentAccessLevel, public access ? : DocumentAccessDto[], public accessRequests ? : DocumentAccessRequestDto[], public dataPropertyStyles ? : DataPropertyStyleDto[], public importId ? : number, public name ? : string, public description ? : string, public createdBy ? : UserDto, public lastUpdatedBy ? : string, public deleterUserId ? : number, public deletionTime ? : string, public lastModificationTime ? : string, public lastModifierUserId ? : number, public creatorUserId ? : number) {}
}
class GetDocumentForViewDto {
    constructor(public document ? : DocumentDto, public accessLevel ? : DocumentAccessLevel) {}
}
class PagedResultDtoOfGetDocumentForViewDto {
    constructor(public totalCount: number, public items ? : GetDocumentForViewDto[]) {}
}
class EditDocumentNameDto {
    constructor(public documentId: number, public name: string) {}
}
class EditDocumentAutoSaveDto {
    constructor(public documentId: number, public autoSave: boolean) {}
}
class MarkAsFavouriteRequest {
    constructor(public itemId: number) {}
}
class UpdateDocumentStructureDto {
    constructor(public pages ? : DocumentPageDto[], public steps ? : DocumentStepDto[], public id ? : number) {}
}
class CreateOrEditDocumentDto {
    constructor(public name: string, public isPristine: boolean, public hasSteps: boolean, public themeId: number, public showHeader: boolean, public showFooter: boolean, public showPageNumbering: boolean, public autoSave: boolean, public description ? : string, public headerHtml ? : string, public footerHtml ? : string, public coverPageTemplate ? : PageTemplateDto, public fillerPageTemplate ? : PageTemplateDto, public closingPageTemplate ? : PageTemplateDto, public headerStyle ? : HeaderFooterStyleDto, public footerStyle ? : HeaderFooterStyleDto, public logoPosition ? : PageElementPosition, public legendPosition ? : PageElementPosition, public attachments ? : DocumentAttachmentDto[], public tableSwatch ? : any, public fontStyles ? : FontStyleDto[], public lastModificationTime ? : string, public defaultPageType ? : DocumentPageType, public dataPropertyStyles ? : DataPropertyStyleDto[], public folderId ? : number, public importId ? : number, public id ? : number) {}
}
class CreateOrEditDocumentOutput {
    constructor(public id: number, public lastModificationTime ? : string) {}
}
class EntityDto {
    constructor(public id: number) {}
}
class RestoreDeletedEntityRequestDto {
    constructor(public entityId: number) {}
}
class GetDocumentStepForViewDto {
    constructor(public documentStep ? : DocumentStepDto, public documentName ? : string) {}
}
class PagedResultDtoOfGetDocumentStepForViewDto {
    constructor(public totalCount: number, public items ? : GetDocumentStepForViewDto[]) {}
}
class CreateOrEditDocumentStepDto {
    constructor(public order: number, public documentId: number, public name ? : string, public description ? : string, public id ? : number) {}
}
class GetDocumentStepForEditOutput {
    constructor(public documentStep ? : CreateOrEditDocumentStepDto, public documentName ? : string) {}
}
class DynamicParameterDto {
    constructor(public id: number, public parameterName ? : string, public inputType ? : string, public permission ? : string, public tenantId ? : number) {}
}
class ListResultDtoOfDynamicParameterDto {
    constructor(public items ? : DynamicParameterDto[]) {}
}
class IValueValidator {
    constructor(public name ? : string, public attributes ? : any) {}
}
class IInputType {
    constructor(public name ? : string, public attributes ? : any, public validator ? : IValueValidator) {}
}
class DynamicParameterValueDto {
    constructor(public dynamicParameterId: number, public id: number, public value ? : string, public tenantId ? : number) {}
}
class ListResultDtoOfDynamicParameterValueDto {
    constructor(public items ? : DynamicParameterValueDto[]) {}
}
class EditionListDto {
    constructor(public id: number, public name ? : string, public displayName ? : string, public dailyPrice ? : number, public weeklyPrice ? : number, public monthlyPrice ? : number, public annualPrice ? : number, public waitingDayAfterExpire ? : number, public trialDayCount ? : number, public expiringEditionDisplayName ? : string) {}
}
class ListResultDtoOfEditionListDto {
    constructor(public items ? : EditionListDto[]) {}
}
class EditionEditDto {
    constructor(public displayName: string, public id ? : number, public expiringEditionId ? : number) {}
}
class LocalizableComboboxItemDto {
    constructor(public value ? : string, public displayText ? : string) {}
}
class LocalizableComboboxItemSourceDto {
    constructor(public items ? : LocalizableComboboxItemDto[]) {}
}
class FeatureInputTypeDto {
    constructor(public name ? : string, public attributes ? : any, public validator ? : IValueValidator, public itemSource ? : LocalizableComboboxItemSourceDto) {}
}
class FlatFeatureDto {
    constructor(public parentName ? : string, public name ? : string, public displayName ? : string, public description ? : string, public defaultValue ? : string, public inputType ? : FeatureInputTypeDto) {}
}
class GetEditionEditOutput {
    constructor(public edition ? : EditionEditDto, public featureValues ? : NameValueDto[], public features ? : FlatFeatureDto[]) {}
}
class EditionCreateDto {
    constructor(public displayName: string, public id ? : number, public dailyPrice ? : number, public weeklyPrice ? : number, public monthlyPrice ? : number, public annualPrice ? : number, public trialDayCount ? : number, public waitingDayAfterExpire ? : number, public expiringEditionId ? : number) {}
}
class CreateEditionDto {
    constructor(public featureValues: NameValueDto[], public edition ? : EditionCreateDto) {}
}
class UpdateEditionDto {
    constructor(public featureValues: NameValueDto[], public edition ? : EditionEditDto) {}
}
class MoveTenantsToAnotherEditionDto {
    constructor(public sourceEditionId: number, public targetEditionId: number) {}
}
enum ElementType {
    Node = 0, Edge = 1
}
class ElementBehaviourDto {
    constructor(public id: number, public name ? : string, public elementType ? : ElementType, public data ? : any) {}
}
class GetElementBehaviourForViewDto {
    constructor(public elementBehaviour ? : ElementBehaviourDto) {}
}
class PagedResultDtoOfGetElementBehaviourForViewDto {
    constructor(public totalCount: number, public items ? : GetElementBehaviourForViewDto[]) {}
}
class EmailTemplateDto {
    constructor(public id: number, public key ? : string, public languageName ? : string, public description ? : string, public subject ? : string, public body ? : string) {}
}
class EmailRecipientDto {
    constructor(public name ? : string, public email ? : string) {}
}
class PrepareEmailTemplateRequestDto {
    constructor(public to ? : EmailRecipientDto[], public key ? : string, public languageName ? : string, public substitutions ? : any) {}
}
class PreparedEmailDto {
    constructor(public to ? : EmailRecipientDto[], public cc ? : EmailRecipientDto[], public bcc ? : EmailRecipientDto[], public body ? : string, public subject ? : string) {}
}
class EntityDynamicParameterDto {
    constructor(public dynamicParameterId: number, public id: number, public entityFullName ? : string, public dynamicParameterName ? : string, public tenantId ? : number) {}
}
class ListResultDtoOfEntityDynamicParameterDto {
    constructor(public items ? : EntityDynamicParameterDto[]) {}
}
class EntityDynamicParameterValueDto {
    constructor(public entityDynamicParameterId: number, public id: number, public value ? : string, public entityId ? : string) {}
}
class ListResultDtoOfEntityDynamicParameterValueDto {
    constructor(public items ? : EntityDynamicParameterValueDto[]) {}
}
class GetAllEntityDynamicParameterValuesOutputItem {
    constructor(public entityDynamicParameterId: number, public parameterName ? : string, public inputType ? : IInputType, public selectedValues ? : string[], public allValuesInputTypeHas ? : string[]) {}
}
class GetAllEntityDynamicParameterValuesOutput {
    constructor(public items ? : GetAllEntityDynamicParameterValuesOutputItem[]) {}
}
class InsertOrUpdateAllValuesInputItem {
    constructor(public entityDynamicParameterId: number, public entityId ? : string, public values ? : string[]) {}
}
class InsertOrUpdateAllValuesInput {
    constructor(public items ? : InsertOrUpdateAllValuesInputItem[]) {}
}
class CleanValuesInput {
    constructor(public entityDynamicParameterId: number, public entityId ? : string) {}
}
class GetFileAttachmentForViewDto {
    constructor(public fileAttachment ? : FileAttachmentDto, public diagramName ? : string) {}
}
class PagedResultDtoOfGetFileAttachmentForViewDto {
    constructor(public totalCount: number, public items ? : GetFileAttachmentForViewDto[]) {}
}
enum ThemeAttachmentType {
    Logo = 0, CoverImage = 1, HeaderSeparator = 2, FooterSeparator = 3, FillerImage = 4, ClosingImage = 5, HeaderBackground = 6, FooterBackground = 7, PageBackground = 8
}
class CreateOrEditFileAttachmentDto {
    constructor(public fileId: string, public filename ? : string, public mimeType ? : string, public description ? : string, public diagramId ? : number, public diagramNodeId ? : number, public diagramEdgeId ? : number, public dataPropertyDefinitionId ? : number, public dataPropertyUuid ? : string, public dataPropertyValue ? : string, public attachmentType ? : ThemeAttachmentType, public id ? : number) {}
}
class GetFileAttachmentForEditOutput {
    constructor(public fileAttachment ? : CreateOrEditFileAttachmentDto, public diagramName ? : string) {}
}
enum FolderItemType {
    Folder = 0, File = 1
}
class IFolderItemDto {
    constructor(public id: number, public isFavourite: boolean, public name ? : string, public type ? : FolderItemType, public deletionTime ? : string, public removalTime ? : string, public pathSegments ? : string[], public path ? : string) {}
}
enum FolderSearchType {
    Folder = 0, Favourites = 1, Templates = 2, Deleted = 3, Shared = 4
}
class SearchFolderInputDto {
    constructor(public sorting ? : string, public searchFilter ? : string, public searchType ? : FolderSearchType) {}
}
class SearchFolderResultDto {
    constructor(public items ? : IFolderItemDto[]) {}
}
class ListFolderContentsInputDto {
    constructor(public limit: number, public offset: number, public folderId ? : number, public sorting ? : string) {}
}
enum FolderAccessLevel {
    View = 0, Edit = 1, EditShare = 2
}
class FolderItemDto {
    constructor(public childItemCount: number, public isFavourite: boolean, public id: number, public type ? : FolderItemType, public creatorUser ? : UserDto, public lastModificationTime ? : string, public sharedWith ? : UserDto[], public creationTime ? : string, public deletionTime ? : string, public parent ? : FolderItemDto, public removalTime ? : string, public currentUserAccessLevel ? : FolderAccessLevel, public pathSegments ? : string[], public path ? : string, public name ? : string) {}
}
class FolderItemListResultDto {
    constructor(public hasMore: boolean, public items ? : IFolderItemDto[], public folder ? : FolderItemDto) {}
}
class CreateFolderDto {
    constructor(public name ? : string, public parentFolderId ? : number) {}
}
class RenameFolderRequestDto {
    constructor(public id: number, public name ? : string) {}
}
class FolderDto {
    constructor(public isFavourite: boolean, public id: number, public name ? : string) {}
}
class DeleteFolderDto {
    constructor(public id: number) {}
}
class MoveItemDto {
    constructor(public id: number, public type ? : FolderItemType) {}
}
class MoveItemsRequestDto {
    constructor(public items ? : MoveItemDto[], public folderId ? : number) {}
}
class CopyItemDto {
    constructor(public id: number, public type ? : FolderItemType) {}
}
class CopyItemsRequestDto {
    constructor(public folderId: number, public items ? : CopyItemDto[]) {}
}
class GrantFolderAccessDto {
    constructor(public folderId: number, public userId: number, public accessLevel ? : FolderAccessLevel, public message ? : string) {}
}
class RevokeFolderAccessDto {
    constructor(public folderId: number, public userId: number) {}
}
class FolderAccessDto {
    constructor(public folderId: number, public userId: number, public user ? : UserDto, public accessLevel ? : FolderAccessLevel) {}
}
class CreateFriendshipRequestInput {
    constructor(public userId: number, public tenantId ? : number) {}
}
class CreateFriendshipRequestByUserNameInput {
    constructor(public tenancyName: string, public userName ? : string) {}
}
class BlockUserInput {
    constructor(public userId: number, public tenantId ? : number) {}
}
class UnblockUserInput {
    constructor(public userId: number, public tenantId ? : number) {}
}
class AcceptFriendshipRequestInput {
    constructor(public userId: number, public tenantId ? : number) {}
}
class TopStatsData {
    constructor(public newTenantsCount: number, public newSubscriptionAmount: number, public dashboardPlaceholder1: number, public dashboardPlaceholder2: number) {}
}
class RecentTenant {
    constructor(public id: number, public creationTime: string, public name ? : string) {}
}
class GetRecentTenantsOutput {
    constructor(public recentTenantsDayCount: number, public maxRecentTenantsShownCount: number, public tenantCreationStartDate: string, public recentTenants ? : RecentTenant[]) {}
}
class ExpiringTenant {
    constructor(public remainingDayCount: number, public tenantName ? : string) {}
}
class GetExpiringTenantsOutput {
    constructor(public subscriptionEndAlertDayCount: number, public maxExpiringTenantsShownCount: number, public subscriptionEndDateStart: string, public subscriptionEndDateEnd: string, public expiringTenants ? : ExpiringTenant[]) {}
}
enum ChartDateInterval {
    Daily = 1, Weekly = 2, Monthly = 3
}
class IncomeStastistic {
    constructor(public date: string, public amount: number, public label ? : string) {}
}
class GetIncomeStatisticsDataOutput {
    constructor(public incomeStatistics ? : IncomeStastistic[]) {}
}
class TenantEdition {
    constructor(public value: number, public label ? : string) {}
}
class GetEditionTenantStatisticsOutput {
    constructor(public editionStatistics ? : TenantEdition[]) {}
}
class GeneralSettingsEditDto {
    constructor(public timezone ? : string, public timezoneForComparison ? : string) {}
}
class SessionTimeOutSettingsEditDto {
    constructor(public isEnabled: boolean, public timeOutSeconds: number, public showTimeOutNotificationSecond: number, public showLockScreenWhenTimedOut: boolean) {}
}
class HostUserManagementSettingsEditDto {
    constructor(public isEmailConfirmationRequiredForLogin: boolean, public smsVerificationEnabled: boolean, public isCookieConsentEnabled: boolean, public isQuickThemeSelectEnabled: boolean, public useCaptchaOnLogin: boolean, public sessionTimeOutSettings ? : SessionTimeOutSettingsEditDto) {}
}
class EmailSettingsEditDto {
    constructor(public smtpPort: number, public smtpEnableSsl: boolean, public useCloudCredentials: boolean, public enableEmailSending: boolean, public defaultFromAddress ? : string, public defaultFromDisplayName ? : string, public smtpHost ? : string, public smtpUserName ? : string, public smtpPassword ? : string, public smtpDomain ? : string) {}
}
class TenantManagementSettingsEditDto {
    constructor(public allowSelfRegistration: boolean, public isNewRegisteredTenantActiveByDefault: boolean, public useCaptchaOnRegistration: boolean, public defaultEditionId ? : number) {}
}
class PasswordComplexitySetting {
    constructor(public requireDigit: boolean, public requireLowercase: boolean, public requireNonAlphanumeric: boolean, public requireUppercase: boolean, public requiredLength: number) {}
}
class UserLockOutSettingsEditDto {
    constructor(public isEnabled: boolean, public maxFailedAccessAttemptsBeforeLockout: number, public defaultAccountLockoutSeconds: number) {}
}
class TwoFactorLoginSettingsEditDto {
    constructor(public isEnabledForApplication: boolean, public isEnabled: boolean, public isEmailProviderEnabled: boolean, public isSmsProviderEnabled: boolean, public isRememberBrowserEnabled: boolean, public isGoogleAuthenticatorEnabled: boolean) {}
}
class SecuritySettingsEditDto {
    constructor(public allowOneConcurrentLoginPerUser: boolean, public useDefaultPasswordComplexitySettings: boolean, public passwordComplexity ? : PasswordComplexitySetting, public defaultPasswordComplexity ? : PasswordComplexitySetting, public userLockOut ? : UserLockOutSettingsEditDto, public twoFactorLogin ? : TwoFactorLoginSettingsEditDto) {}
}
class HostBillingSettingsEditDto {
    constructor(public legalName ? : string, public address ? : string) {}
}
class OtherSettingsEditDto {
    constructor(public isQuickThemeSelectEnabled: boolean) {}
}
class FacebookExternalLoginProviderSettings {
    constructor(public appId ? : string, public appSecret ? : string) {}
}
class GoogleExternalLoginProviderSettings {
    constructor(public clientId ? : string, public clientSecret ? : string, public userInfoEndpoint ? : string) {}
}
class TwitterExternalLoginProviderSettings {
    constructor(public consumerKey ? : string, public consumerSecret ? : string) {}
}
class MicrosoftExternalLoginProviderSettings {
    constructor(public clientId ? : string, public clientSecret ? : string) {}
}
class ExternalLoginProviderSettingsEditDto {
    constructor(public facebook ? : FacebookExternalLoginProviderSettings, public google ? : GoogleExternalLoginProviderSettings, public twitter ? : TwitterExternalLoginProviderSettings, public microsoft ? : MicrosoftExternalLoginProviderSettings) {}
}
class HostSettingsEditDto {
    constructor(public general ? : GeneralSettingsEditDto, public userManagement ? : HostUserManagementSettingsEditDto, public email ? : EmailSettingsEditDto, public tenantManagement ? : TenantManagementSettingsEditDto, public security ? : SecuritySettingsEditDto, public billing ? : HostBillingSettingsEditDto, public otherSettings ? : OtherSettingsEditDto, public externalLoginProviderSettings ? : ExternalLoginProviderSettingsEditDto) {}
}
class SendTestEmailInput {
    constructor(public emailAddress: string) {}
}
class SendTestLdapInput {
    constructor(public domain: string, public port: number, public container: string, public userNameAttribute: string, public userName: string, public password: string, public searchFilter: string) {}
}
class ClientAutoSaveSettingsDto {
    constructor(public isEnabled: boolean, public timeOutSeconds: number) {}
}
class ClientDocumentSettingsDto {
    constructor(public isPublicByDefault: boolean, public canTogglePublic: boolean, public hardDeleteEnabled: boolean, public softDeleteRetentionPeriodDays: number, public softDeletePollPeriodSeconds: number, public emailShareDomain ? : string) {}
}
class FeedbackSettingsDto {
    constructor(public toEmail ? : string, public ccEmail ? : string, public bccEmail ? : string) {}
}
class TourGuideConfigurationDto {
    constructor(public enabled: boolean, public captureUserMetrics: boolean) {}
}
class EmailSettingsDto {
    constructor(public enableEmailSending: boolean) {}
}
class ClientHostSettingsDto {
    constructor(public sessionTimeout ? : SessionTimeOutSettingsEditDto, public autoSaveSettings ? : ClientAutoSaveSettingsDto, public documentSettings ? : ClientDocumentSettingsDto, public feedbackSettings ? : FeedbackSettingsDto, public tourGuideSettings ? : TourGuideConfigurationDto, public emailSettings ? : EmailSettingsDto) {}
}
class ImportedAndValidatedDto {
    constructor(public importId: number, public documentId: number) {}
}
class ImportedDataItemElementDataPropertyDto {
    constructor(public dataPropertyValue ? : string, public dataPropertyDefinitionId ? : number) {}
}
class ImportedDataItemElementValidatioErrorDto {
    constructor(public row: number, public value ? : string, public message ? : string) {}
}
class ImportedDataItemElementDto {
    constructor(public labels ? : string[], public jurisdictionId ? : number, public stateId ? : number, public dataProperty ? : ImportedDataItemElementDataPropertyDto, public shapeId ? : number, public symbolId ? : number, public fill ? : string, public outline ? : string, public ownership ? : string, public errors ? : ImportedDataItemElementValidatioErrorDto[]) {}
}
class ImportValidationError {
    constructor(public row: number, public message ? : string, public column ? : string) {}
}
class ImportedDataItemDto {
    constructor(public dataElement ? : ImportedDataItemElementDto, public importValidationErrors ? : ImportValidationError[], public children ? : ImportedDataItemDto[]) {}
}
class ThemeElementDto {
    constructor(public themeId: number, public systemOwned: boolean, public inShapeCycle: boolean, public id: number, public name ? : string, public style ? : any, public elementType ? : ElementType, public behaviourId ? : number, public behaviour ? : ElementBehaviourDto) {}
}
class ThemeAttachmentDto {
    constructor(public themeId: number, public fileAttachmentId: number, public fileAttachment ? : FileAttachmentDto, public attachmentType ? : ThemeAttachmentType, public id ? : number) {}
}
class ThemeDataPropertyItemDto {
    constructor(public dataPropertyDefinitionItemId: number, public imageData ? : string, public itemValue ? : string, public id ? : number) {}
}
class ThemeDto {
    constructor(public systemOwned: boolean, public isEditable: boolean, public isApplicable: boolean, public isUsed: boolean, public isDefault: boolean, public showBackgroundPanel: boolean, public name ? : string, public creatorUserId ? : number, public swatch ? : any, public logoPosition ? : PageElementPosition, public legendPosition ? : PageElementPosition, public elements ? : ThemeElementDto[], public attachments ? : ThemeAttachmentDto[], public themeDataPropertyItems ? : ThemeDataPropertyItemDto[], public headerHtml ? : string, public footerHtml ? : string, public coverPageTemplate ? : PageTemplateDto, public fillerPageTemplate ? : PageTemplateDto, public closingPageTemplate ? : PageTemplateDto, public headerStyle ? : HeaderFooterStyleDto, public footerStyle ? : HeaderFooterStyleDto, public tableSwatch ? : any, public fontStyles ? : FontStyleDto[], public defaultPageType ? : DocumentPageType, public id ? : number) {}
}
class ImportedResultDto {
    constructor(public importId: number, public items ? : ImportedDataItemDto[], public theme ? : ThemeDto) {}
}
class ProcessImportedFileDto {
    constructor(public binaryObjectId: string, public importDefinitionId: number, public name ? : string, public password ? : string) {}
}
class ImportedFileValidationDto {
    constructor(public importId: number, public validationErrors ? : ImportedDataItemElementValidatioErrorDto[], public theme ? : ThemeDto, public name ? : string) {}
}
enum ImportFileType {
    Unknown = 0, Excel = 1, CSVComma = 2, CSVTab = 3
}
enum ImportDefinitionColumnType {
    Unique = 0, PointsToParent = 1, Jurisdiction = 2, Shape = 3, Symbol = 4, Fill = 5, Outline = 6, DataProperty = 7, Ownership = 8, Label = 9
}
class MappingValueDto {
    constructor(public mappingId ? : number, public mapping ? : MappingDto, public mappedValue ? : string, public destination ? : DataPropertyDefinitionItemDto) {}
}
class MappingDto {
    constructor(public dataPropertyDefinition ? : DataPropertyDefinitionDto, public mappingValues ? : MappingValueDto[]) {}
}
class ImportDefinitionColumnDto {
    constructor(public importDefinitionId: number, public addToLabel: boolean, public importDefinition ? : ImportDefinitionDto, public column ? : string, public columnType ? : ImportDefinitionColumnType, public mappingId ? : number, public mapping ? : MappingDto) {}
}
enum ImportDataType {
    Unstructured = 0, Tree = 1, Hierarchy = 2
}
class ImportDefinitionDto {
    constructor(public themeId: number, public id: number, public name ? : string, public theme ? : ThemeDto, public columns ? : ImportDefinitionColumnDto[], public creatorUserId ? : number, public user ? : UserDto, public dataType ? : ImportDataType) {}
}
class ImportDto {
    constructor(public binaryObjectId: string, public creationTime: string, public rows: number, public processed: number, public errors: number, public id: number, public name ? : string, public fileType ? : ImportFileType, public importDefinitionId ? : number, public importDefinition ? : ImportDefinitionDto, public creatorUserId ? : number, public creatorUser ? : UserDto) {}
}
class PagedResultDtoOfImportDto {
    constructor(public totalCount: number, public items ? : ImportDto[]) {}
}
class InstallDto {
    constructor(public connectionString: string, public adminPassword: string, public webSiteUrl: string, public defaultLanguage: string, public serverUrl ? : string, public smtpSettings ? : EmailSettingsEditDto, public billInfo ? : HostBillingSettingsEditDto) {}
}
class NameValue {
    constructor(public name ? : string, public value ? : string) {}
}
class AppSettingsJsonDto {
    constructor(public webSiteUrl ? : string, public serverSiteUrl ? : string, public languages ? : NameValue[]) {}
}
class CheckDatabaseOutput {
    constructor(public isDatabaseExist: boolean) {}
}
class InvoiceDto {
    constructor(public amount: number, public invoiceDate: string, public editionDisplayName ? : string, public invoiceNo ? : string, public tenantLegalName ? : string, public tenantAddress ? : string[], public tenantTaxNo ? : string, public hostLegalName ? : string, public hostAddress ? : string[]) {}
}
class CreateInvoiceDto {
    constructor(public subscriptionPaymentId: number) {}
}
class ApplicationLanguageListDto {
    constructor(public isDisabled: boolean, public isDeleted: boolean, public creationTime: string, public id: number, public tenantId ? : number, public name ? : string, public displayName ? : string, public icon ? : string, public deleterUserId ? : number, public deletionTime ? : string, public lastModificationTime ? : string, public lastModifierUserId ? : number, public creatorUserId ? : number) {}
}
class GetLanguagesOutput {
    constructor(public defaultLanguageName ? : string, public items ? : ApplicationLanguageListDto[]) {}
}
class ApplicationLanguageEditDto {
    constructor(public name: string, public isEnabled: boolean, public id ? : number, public icon ? : string) {}
}
class ComboboxItemDto {
    constructor(public isSelected: boolean, public value ? : string, public displayText ? : string) {}
}
class GetLanguageForEditOutput {
    constructor(public language ? : ApplicationLanguageEditDto, public languageNames ? : ComboboxItemDto[], public flags ? : ComboboxItemDto[]) {}
}
class CreateOrUpdateLanguageInput {
    constructor(public language ? : ApplicationLanguageEditDto) {}
}
class SetDefaultLanguageInput {
    constructor(public name: string) {}
}
class LanguageTextListDto {
    constructor(public key ? : string, public baseValue ? : string, public targetValue ? : string) {}
}
class PagedResultDtoOfLanguageTextListDto {
    constructor(public totalCount: number, public items ? : LanguageTextListDto[]) {}
}
class UpdateLanguageTextInput {
    constructor(public languageName: string, public sourceName: string, public key: string, public value: string) {}
}
class LicenseUserDto {
    constructor(public userId: number, public license: boolean) {}
}
class LicenseUserResultDto {
    constructor(public exceeded: boolean, public licensedUsers: number) {}
}
class LicenseDto {
    constructor(public id: number, public licenseInfo ? : string, public display ? : string) {}
}
enum LicenseTypes {
    Restricted = 0, Unrestricted = 1
}
class GetLicenseForViewDto {
    constructor(public licensedUsers: number, public unlicensedUsers: number, public licenses: number, public unlicensed: boolean, public license ? : LicenseDto, public features ? : string[], public licenseType ? : LicenseTypes) {}
}
class CreateOrEditLicenseDto {
    constructor(public licenseInfo: string) {}
}
class GetLicenseForEditOutput {
    constructor(public license ? : CreateOrEditLicenseDto) {}
}
enum UserNotificationState {
    Unread = 0, Read = 1
}
class NotificationData {
    constructor(public type ? : string, public properties ? : any) {}
}
enum NotificationSeverity {
    Info = 0, Success = 1, Warn = 2, Error = 3, Fatal = 4
}
class TenantNotification {
    constructor(public creationTime: string, public id: string, public tenantId ? : number, public notificationName ? : string, public data ? : NotificationData, public entityType ? : string, public entityTypeName ? : string, public entityId ? : any, public severity ? : NotificationSeverity) {}
}
class UserNotification {
    constructor(public userId: number, public id: string, public tenantId ? : number, public state ? : UserNotificationState, public notification ? : TenantNotification) {}
}
class GetNotificationsOutput {
    constructor(public unreadCount: number, public totalCount: number, public items ? : UserNotification[]) {}
}
class EntityDtoOfGuid {
    constructor(public id: string) {}
}
class NotificationSubscriptionWithDisplayNameDto {
    constructor(public name: string, public isSubscribed: boolean, public displayName ? : string, public description ? : string) {}
}
class GetNotificationSettingsOutput {
    constructor(public receiveNotifications: boolean, public notifications ? : NotificationSubscriptionWithDisplayNameDto[]) {}
}
class NotificationSubscriptionDto {
    constructor(public name: string, public isSubscribed: boolean) {}
}
class UpdateNotificationSettingsInput {
    constructor(public receiveNotifications: boolean, public notifications ? : NotificationSubscriptionDto[]) {}
}
class OrganizationUnitDto {
    constructor(public memberCount: number, public roleCount: number, public creationTime: string, public id: number, public parentId ? : number, public code ? : string, public displayName ? : string, public lastModificationTime ? : string, public lastModifierUserId ? : number, public creatorUserId ? : number) {}
}
class ListResultDtoOfOrganizationUnitDto {
    constructor(public items ? : OrganizationUnitDto[]) {}
}
class OrganizationUnitUserListDto {
    constructor(public addedTime: string, public id: number, public name ? : string, public surname ? : string, public userName ? : string, public emailAddress ? : string, public profilePictureId ? : string) {}
}
class PagedResultDtoOfOrganizationUnitUserListDto {
    constructor(public totalCount: number, public items ? : OrganizationUnitUserListDto[]) {}
}
class OrganizationUnitRoleListDto {
    constructor(public addedTime: string, public id: number, public displayName ? : string, public name ? : string) {}
}
class PagedResultDtoOfOrganizationUnitRoleListDto {
    constructor(public totalCount: number, public items ? : OrganizationUnitRoleListDto[]) {}
}
class CreateOrganizationUnitInput {
    constructor(public displayName: string, public parentId ? : number) {}
}
class UpdateOrganizationUnitInput {
    constructor(public id: number, public displayName: string) {}
}
class MoveOrganizationUnitInput {
    constructor(public id: number, public newParentId ? : number) {}
}
class UsersToOrganizationUnitInput {
    constructor(public organizationUnitId: number, public userIds ? : number[]) {}
}
class RolesToOrganizationUnitInput {
    constructor(public organizationUnitId: number, public roleIds ? : number[]) {}
}
class FindOrganizationUnitUsersInput {
    constructor(public organizationUnitId: number, public maxResultCount: number, public skipCount: number, public filter ? : string) {}
}
class FindOrganizationUnitRolesInput {
    constructor(public organizationUnitId: number, public maxResultCount: number, public skipCount: number, public filter ? : string) {}
}
class EditionSelectDto {
    constructor(public id: number, public isFree: boolean, public name ? : string, public displayName ? : string, public expiringEditionId ? : number, public dailyPrice ? : number, public weeklyPrice ? : number, public monthlyPrice ? : number, public annualPrice ? : number, public trialDayCount ? : number, public waitingDayAfterExpire ? : number, public additionalData ? : any) {}
}
class PaymentInfoDto {
    constructor(public additionalPrice: number, public edition ? : EditionSelectDto) {}
}
enum EditionPaymentType {
    NewRegistration = 0, BuyNow = 1, Upgrade = 2, Extend = 3
}
enum PaymentPeriodType {
    Daily = 1, Weekly = 7, Monthly = 30, Annual = 365
}
enum SubscriptionPaymentGatewayType {
    Paypal = 1, Stripe = 2
}
class CreatePaymentDto {
    constructor(public editionId: number, public recurringPaymentEnabled: boolean, public editionPaymentType ? : EditionPaymentType, public paymentPeriodType ? : PaymentPeriodType, public subscriptionPaymentGatewayType ? : SubscriptionPaymentGatewayType, public successUrl ? : string, public errorUrl ? : string) {}
}
class CancelPaymentDto {
    constructor(public paymentId ? : string, public gateway ? : SubscriptionPaymentGatewayType) {}
}
class SubscriptionPaymentListDto {
    constructor(public amount: number, public editionId: number, public dayCount: number, public tenantId: number, public creationTime: string, public id: number, public gateway ? : string, public paymentPeriodType ? : string, public externalPaymentId ? : string, public payerId ? : string, public status ? : string, public editionDisplayName ? : string, public invoiceNo ? : string, public lastModificationTime ? : string, public lastModifierUserId ? : number, public creatorUserId ? : number) {}
}
class PagedResultDtoOfSubscriptionPaymentListDto {
    constructor(public totalCount: number, public items ? : SubscriptionPaymentListDto[]) {}
}
class PaymentGatewayModel {
    constructor(public supportsRecurringPayments: boolean, public gatewayType ? : SubscriptionPaymentGatewayType) {}
}
enum SubscriptionPaymentStatus {
    NotPaid = 1, Paid = 2, Failed = 3, Cancelled = 4, Completed = 5
}
class SubscriptionPaymentDto {
    constructor(public amount: number, public editionId: number, public tenantId: number, public dayCount: number, public invoiceNo: number, public isRecurring: boolean, public id: number, public description ? : string, public gateway ? : SubscriptionPaymentGatewayType, public paymentPeriodType ? : PaymentPeriodType, public paymentId ? : string, public payerId ? : string, public editionDisplayName ? : string, public status ? : SubscriptionPaymentStatus, public externalPaymentId ? : string, public successUrl ? : string, public errorUrl ? : string, public editionPaymentType ? : EditionPaymentType) {}
}
class PayPalConfigurationDto {
    constructor(public clientId ? : string, public demoUsername ? : string, public demoPassword ? : string) {}
}
class CreateOrEditDataPropertyDefinitionItemDto {
    constructor(public isDeleted: boolean, public itemValue ? : string, public itemInitials ? : string, public imageData ? : string, public dataPropertyDefinitionId ? : number, public parentItemId ? : number, public id ? : number) {}
}
class PersonImportRowDto {
    constructor(public id: number, public name ? : string, public imageUrl ? : string) {}
}
class KeyValuePairOfStringString {
    constructor(public key ? : string, public value ? : string) {}
}
class FlatPermissionWithLevelDto {
    constructor(public level: number, public isGrantedByDefault: boolean, public parentName ? : string, public name ? : string, public displayName ? : string, public description ? : string) {}
}
class ListResultDtoOfFlatPermissionWithLevelDto {
    constructor(public items ? : FlatPermissionWithLevelDto[]) {}
}
class CurrentUserProfileEditDto {
    constructor(public name: string, public surname: string, public userName: string, public emailAddress: string, public isPhoneNumberConfirmed: boolean, public isGoogleAuthenticatorEnabled: boolean, public isLicensed: boolean, public userId ? : number, public phoneNumber ? : string, public timezone ? : string, public qrCodeSetupImageUrl ? : string, public authenticationSource ? : string, public roles ? : string[], public permissions ? : string[], public language ? : string) {}
}
class UpdateGoogleAuthenticatorKeyOutput {
    constructor(public qrCodeSetupImageUrl ? : string) {}
}
class SendVerificationSmsInputDto {
    constructor(public phoneNumber ? : string) {}
}
class VerifySmsCodeInputDto {
    constructor(public code ? : string, public phoneNumber ? : string) {}
}
class ChangePasswordInput {
    constructor(public currentPassword: string, public newPassword: string) {}
}
class UpdateProfilePictureInput {
    constructor(public fileToken: string, public x: number, public y: number, public width: number, public height: number) {}
}
class GetPasswordComplexitySettingOutput {
    constructor(public setting ? : PasswordComplexitySetting) {}
}
class GetProfilePictureOutput {
    constructor(public profilePicture ? : string) {}
}
class ChangeUserLanguageDto {
    constructor(public language: string) {}
}
class RoleListDto {
    constructor(public isStatic: boolean, public isDefault: boolean, public creationTime: string, public id: number, public name ? : string, public displayName ? : string) {}
}
class ListResultDtoOfRoleListDto {
    constructor(public items ? : RoleListDto[]) {}
}
class RoleEditDto {
    constructor(public displayName: string, public isDefault: boolean, public id ? : number) {}
}
class FlatPermissionDto {
    constructor(public isGrantedByDefault: boolean, public parentName ? : string, public name ? : string, public displayName ? : string, public description ? : string) {}
}
class GetRoleForEditOutput {
    constructor(public role ? : RoleEditDto, public permissions ? : FlatPermissionDto[], public grantedPermissionNames ? : string[]) {}
}
class CreateOrUpdateRoleInput {
    constructor(public grantedPermissionNames: [], public role ? : RoleEditDto) {}
}
class UserLoginInfoDto {
    constructor(public id: number, public name ? : string, public surname ? : string, public userName ? : string, public emailAddress ? : string, public profilePictureId ? : string) {}
}
enum SubscriptionPaymentType {
    Manual = 0, RecurringAutomatic = 1, RecurringManual = 2
}
class EditionInfoDto {
    constructor(public isHighestEdition: boolean, public isFree: boolean, public id: number, public displayName ? : string, public trialDayCount ? : number, public monthlyPrice ? : number, public annualPrice ? : number) {}
}
class TenantLoginInfoDto {
    constructor(public isInTrialPeriod: boolean, public creationTime: string, public id: number, public tenancyName ? : string, public name ? : string, public logoId ? : string, public logoFileType ? : string, public customCssId ? : string, public subscriptionEndDateUtc ? : string, public subscriptionPaymentType ? : SubscriptionPaymentType, public edition ? : EditionInfoDto, public paymentPeriodType ? : PaymentPeriodType, public subscriptionDateString ? : string, public creationTimeString ? : string) {}
}
class ApplicationInfoDto {
    constructor(public releaseDate: string, public allowTenantsToChangeEmailSettings: boolean, public userDelegationIsEnabled: boolean, public instanceId ? : string, public version ? : string, public currency ? : string, public currencySign ? : string, public features ? : any) {}
}
class ThemeLayoutSettingsDto {
    constructor(public layoutType ? : string) {}
}
class ThemeHeaderSettingsDto {
    constructor(public desktopFixedHeader: boolean, public mobileFixedHeader: boolean, public headerMenuArrows: boolean, public headerSkin ? : string, public minimizeDesktopHeaderType ? : string) {}
}
class ThemeSubHeaderSettingsDto {
    constructor(public fixedSubHeader: boolean, public subheaderStyle ? : string) {}
}
class ThemeMenuSettingsDto {
    constructor(public fixedAside: boolean, public allowAsideMinimizing: boolean, public defaultMinimizedAside: boolean, public searchActive: boolean, public position ? : string, public asideSkin ? : string, public submenuToggle ? : string) {}
}
class ThemeFooterSettingsDto {
    constructor(public fixedFooter: boolean) {}
}
class ThemeSettingsDto {
    constructor(public theme ? : string, public layout ? : ThemeLayoutSettingsDto, public header ? : ThemeHeaderSettingsDto, public subHeader ? : ThemeSubHeaderSettingsDto, public menu ? : ThemeMenuSettingsDto, public footer ? : ThemeFooterSettingsDto) {}
}
class UiCustomizationSettingsDto {
    constructor(public isLeftMenuUsed: boolean, public isTopMenuUsed: boolean, public isTabMenuUsed: boolean, public allowMenuScroll: boolean, public baseSettings ? : ThemeSettingsDto) {}
}
class GetCurrentLoginInformationsOutput {
    constructor(public user ? : UserLoginInfoDto, public tenant ? : TenantLoginInfoDto, public application ? : ApplicationInfoDto, public theme ? : UiCustomizationSettingsDto) {}
}
class UpdateUserSignInTokenOutput {
    constructor(public signInToken ? : string, public encodedUserId ? : string, public encodedTenantId ? : string) {}
}
class StripeConfigurationDto {
    constructor(public publishableKey ? : string) {}
}
class StripeCreatePaymentSessionInput {
    constructor(public paymentId: number, public successUrl ? : string, public cancelUrl ? : string) {}
}
class StripePaymentResultOutput {
    constructor(public paymentDone: boolean) {}
}
class TemplateConfiguration {
    constructor(public xAdjustment: number, public yAdjustment: number, public editableHeader: boolean, public logoOffsetX ? : number, public logoOffsetY ? : number, public headerWidth ? : number) {}
}
class TenantListDto {
    constructor(public isActive: boolean, public creationTime: string, public isInTrialPeriod: boolean, public id: number, public tenancyName ? : string, public name ? : string, public editionDisplayName ? : string, public connectionString ? : string, public subscriptionEndDateUtc ? : string, public editionId ? : number) {}
}
class PagedResultDtoOfTenantListDto {
    constructor(public totalCount: number, public items ? : TenantListDto[]) {}
}
class CreateTenantInput {
    constructor(public tenancyName: string, public name: string, public adminEmailAddress: string, public shouldChangePasswordOnNextLogin: boolean, public sendActivationEmail: boolean, public isActive: boolean, public isInTrialPeriod: boolean, public adminPassword ? : string, public connectionString ? : string, public editionId ? : number, public subscriptionEndDateUtc ? : string) {}
}
class TenantEditDto {
    constructor(public tenancyName: string, public name: string, public isActive: boolean, public isInTrialPeriod: boolean, public id: number, public connectionString ? : string, public editionId ? : number, public subscriptionEndDateUtc ? : string) {}
}
class GetTenantFeaturesEditOutput {
    constructor(public featureValues ? : NameValueDto[], public features ? : FlatFeatureDto[]) {}
}
class UpdateTenantFeaturesInput {
    constructor(public id: number, public featureValues: NameValueDto[]) {}
}
class MemberActivity {
    constructor(public cases: number, public closed: number, public name ? : string, public earnings ? : string, public rate ? : string) {}
}
class GetMemberActivityOutput {
    constructor(public memberActivities ? : MemberActivity[]) {}
}
enum SalesSummaryDatePeriod {
    Daily = 1, Weekly = 2, Monthly = 3
}
class SalesSummaryData {
    constructor(public sales: number, public profit: number, public period ? : string) {}
}
class GetDashboardDataOutput {
    constructor(public totalProfit: number, public newFeedbacks: number, public newOrders: number, public newUsers: number, public totalSales: number, public revenue: number, public expenses: number, public growth: number, public transactionPercent: number, public newVisitPercent: number, public bouncePercent: number, public salesSummary ? : SalesSummaryData[], public dailySales ? : number[], public profitShares ? : number[]) {}
}
class GetTopStatsOutput {
    constructor(public totalProfit: number, public newFeedbacks: number, public newOrders: number, public newUsers: number) {}
}
class GetProfitShareOutput {
    constructor(public profitShares ? : number[]) {}
}
class GetDailySalesOutput {
    constructor(public dailySales ? : number[]) {}
}
class GetSalesSummaryOutput {
    constructor(public totalSales: number, public revenue: number, public expenses: number, public growth: number, public salesSummary ? : SalesSummaryData[]) {}
}
class RegionalStatCountry {
    constructor(public sales: number, public averagePrice: number, public totalPrice: number, public countryName ? : string, public change ? : number[]) {}
}
class GetRegionalStatsOutput {
    constructor(public stats ? : RegionalStatCountry[]) {}
}
class GetGeneralStatsOutput {
    constructor(public transactionPercent: number, public newVisitPercent: number, public bouncePercent: number) {}
}
enum SubscriptionStartType {
    Free = 1, Trial = 2, Paid = 3
}
class RegisterTenantInput {
    constructor(public tenancyName: string, public name: string, public adminEmailAddress: string, public adminPassword ? : string, public captchaResponse ? : string, public subscriptionStartType ? : SubscriptionStartType, public editionId ? : number) {}
}
class RegisterTenantOutput {
    constructor(public tenantId: number, public isTenantActive: boolean, public isActive: boolean, public isEmailConfirmationRequired: boolean, public tenancyName ? : string, public name ? : string, public userName ? : string, public emailAddress ? : string) {}
}
class FlatFeatureSelectDto {
    constructor(public parentName ? : string, public name ? : string, public displayName ? : string, public description ? : string, public defaultValue ? : string, public inputType ? : IInputType, public textHtmlColor ? : string) {}
}
class EditionWithFeaturesDto {
    constructor(public edition ? : EditionSelectDto, public featureValues ? : NameValueDto[]) {}
}
class EditionsSelectOutput {
    constructor(public allFeatures ? : FlatFeatureSelectDto[], public editionsWithFeatures ? : EditionWithFeaturesDto[]) {}
}
class DocumentConfigurationDto {
    constructor(public isPublicByDefault: boolean, public canTogglePublic: boolean, public hardDeleteEnabled: boolean, public hardDeleteWorkerEnabled: boolean, public softDeleteRetentionPeriodDays: number, public softDeletePollPeriodSeconds: number, public emailShareDomain ? : string) {}
}
class TenantUserManagementSettingsEditDto {
    constructor(public allowSelfRegistration: boolean, public isNewRegisteredUserActiveByDefault: boolean, public isEmailConfirmationRequiredForLogin: boolean, public useCaptchaOnRegistration: boolean, public useCaptchaOnLogin: boolean, public isCookieConsentEnabled: boolean, public isQuickThemeSelectEnabled: boolean, public sessionTimeOutSettings ? : SessionTimeOutSettingsEditDto, public autoSaveSettings ? : ClientAutoSaveSettingsDto) {}
}
class LdapSettingsEditDto {
    constructor(public isModuleEnabled: boolean, public isEnabled: boolean, public port: number, public domain ? : string, public userName ? : string, public container ? : string, public userNameAttribute ? : string, public searchFilter ? : string, public password ? : string) {}
}
class LicenseSettingsEditDto {
    constructor(public autoAssignLicenses: boolean, public license ? : GetLicenseForViewDto, public licenceString ? : string, public toLicenceEmail ? : string, public ccToLicenceEmail ? : string, public bccToLicenceEmail ? : string) {}
}
class TemplateConfigurationDto {
    constructor(public xAdjustment: number, public yAdjustment: number, public editableHeader: boolean, public logoOffsetX: number, public logoOffsetY: number, public headerWidth: number) {}
}
class TenantBillingSettingsEditDto {
    constructor(public legalName ? : string, public address ? : string, public taxVatNo ? : string) {}
}
class TenantOtherSettingsEditDto {
    constructor(public isQuickThemeSelectEnabled: boolean) {}
}
class TenantSettingsEditDto {
    constructor(public general ? : GeneralSettingsEditDto, public document ? : DocumentConfigurationDto, public userManagement ? : TenantUserManagementSettingsEditDto, public email ? : EmailSettingsEditDto, public ldap ? : LdapSettingsEditDto, public license ? : LicenseSettingsEditDto, public feedbackSettings ? : FeedbackSettingsDto, public templateConfiguration ? : TemplateConfigurationDto, public security ? : SecuritySettingsEditDto, public billing ? : TenantBillingSettingsEditDto, public otherSettings ? : TenantOtherSettingsEditDto, public externalLoginProviderSettings ? : ExternalLoginProviderSettingsEditDto, public tourGuideSettings ? : TourGuideConfigurationDto) {}
}
class GetThemeElementForViewDto {
    constructor(public themeElement ? : ThemeElementDto, public themeName ? : string) {}
}
class PagedResultDtoOfGetThemeElementForViewDto {
    constructor(public totalCount: number, public items ? : GetThemeElementForViewDto[]) {}
}
class CreateOrEditThemeElementDto {
    constructor(public themeId: number, public systemOwned: boolean, public name ? : string, public style ? : any, public elementType ? : ElementType, public behaviourId ? : number, public behaviour ? : ElementBehaviourDto, public id ? : number) {}
}
class GetThemeElementForEditOutput {
    constructor(public themeElement ? : CreateOrEditThemeElementDto) {}
}
class GetThemeForViewDto {
    constructor(public theme ? : ThemeDto, public logo ? : string) {}
}
class PagedResultDtoOfGetThemeForViewDto {
    constructor(public totalCount: number, public items ? : GetThemeForViewDto[]) {}
}
class CloneThemeDto {
    constructor(public id: number, public name ? : string) {}
}
class SetDefaultThemeInput {
    constructor(public themeId: number) {}
}
class GetThemeOutput {
    constructor(public theme ? : ThemeDto) {}
}
class CreateOrEditThemeDto {
    constructor(public isEditable: boolean, public isApplicable: boolean, public isDefault: boolean, public showBackgroundPanel: boolean, public name ? : string, public creatorUserId ? : number, public swatch ? : any, public headerHtml ? : string, public footerHtml ? : string, public coverPageTemplate ? : PageTemplateDto, public fillerPageTemplate ? : PageTemplateDto, public closingPageTemplate ? : PageTemplateDto, public headerStyle ? : HeaderFooterStyleDto, public footerStyle ? : HeaderFooterStyleDto, public logoPosition ? : PageElementPosition, public legendPosition ? : PageElementPosition, public attachmentType ? : ThemeAttachmentType, public elements ? : ThemeElementDto[], public attachments ? : ThemeAttachmentDto[], public themeDataPropertyItems ? : ThemeDataPropertyItemDto[], public tableSwatch ? : any, public fontStyles ? : FontStyleDto[], public defaultPageType ? : DocumentPageType, public id ? : number) {}
}
enum SettingScopes {
    Application = 1, Tenant = 2, User = 4, All = 7
}
class ListResultDtoOfNameValueDto {
    constructor(public items ? : NameValueDto[]) {}
}
class UserListRoleDto {
    constructor(public roleId: number, public roleName ? : string) {}
}
class UserListDto {
    constructor(public isEmailConfirmed: boolean, public isActive: boolean, public isLicensed: boolean, public creationTime: string, public id: number, public name ? : string, public surname ? : string, public userName ? : string, public emailAddress ? : string, public phoneNumber ? : string, public profilePictureId ? : string, public roles ? : UserListRoleDto[]) {}
}
class PagedResultDtoOfUserListDto {
    constructor(public totalCount: number, public items ? : UserListDto[]) {}
}
class UserEditDto {
    constructor(public name: string, public surname: string, public emailAddress: string, public isActive: boolean, public shouldChangePasswordOnNextLogin: boolean, public isTwoFactorEnabled: boolean, public isLockoutEnabled: boolean, public isLicensed: boolean, public id ? : number, public userName ? : string, public phoneNumber ? : string, public password ? : string, public authenticationSource ? : string) {}
}
class UserRoleDto {
    constructor(public roleId: number, public isAssigned: boolean, public inheritedFromOrganizationUnit: boolean, public roleName ? : string, public roleDisplayName ? : string) {}
}
class GetUserForEditOutput {
    constructor(public profilePictureId ? : string, public user ? : UserEditDto, public roles ? : UserRoleDto[], public allOrganizationUnits ? : OrganizationUnitDto[], public memberedOrganizationUnits ? : string[]) {}
}
class GetUserPermissionsForEditOutput {
    constructor(public permissions ? : FlatPermissionDto[], public grantedPermissionNames ? : string[]) {}
}
class EntityDtoOfInt64 {
    constructor(public id: number) {}
}
class UpdateUserPermissionsInput {
    constructor(public id: number, public grantedPermissionNames: []) {}
}
class CreateOrUpdateUserInput {
    constructor(public assignedRoleNames: [], public sendActivationEmail: boolean, public setRandomPassword: boolean, public user ? : UserEditDto, public organizationUnits ? : number[]) {}
}
class CreateOrUpdateUserOutput {
    constructor(public forceLogout: boolean) {}
}
class UserDelegationDto {
    constructor(public startTime: string, public endTime: string, public id: number, public username ? : string) {}
}
class PagedResultDtoOfUserDelegationDto {
    constructor(public totalCount: number, public items ? : UserDelegationDto[]) {}
}
class CreateUserDelegationDto {
    constructor(public targetUserId: number, public startTime: string, public endTime: string) {}
}
class LinkToUserInput {
    constructor(public usernameOrEmailAddress: string, public password: string, public tenancyName ? : string) {}
}
class LinkedUserDto {
    constructor(public id: number, public tenantId ? : number, public tenancyName ? : string, public username ? : string) {}
}
class PagedResultDtoOfLinkedUserDto {
    constructor(public totalCount: number, public items ? : LinkedUserDto[]) {}
}
class ListResultDtoOfLinkedUserDto {
    constructor(public items ? : LinkedUserDto[]) {}
}
class UnlinkUserInput {
    constructor(public userId: number, public tenantId ? : number) {}
}
class UserLoginAttemptDto {
    constructor(public creationTime: string, public tenancyName ? : string, public userNameOrEmail ? : string, public clientIpAddress ? : string, public clientName ? : string, public browserInfo ? : string, public result ? : string) {}
}
class ListResultDtoOfUserLoginAttemptDto {
    constructor(public items ? : UserLoginAttemptDto[]) {}
}
class WebhookEvent {
    constructor(public webhookName: string, public creationTime: string, public isDeleted: boolean, public id: string, public data ? : string, public tenantId ? : number, public deletionTime ? : string) {}
}
enum HttpStatusCode {
    Continue = 100, SwitchingProtocols = 101, Processing = 102, EarlyHints = 103, OK = 200, Created = 201, Accepted = 202, NonAuthoritativeInformation = 203, NoContent = 204, ResetContent = 205, PartialContent = 206, MultiStatus = 207, AlreadyReported = 208, IMUsed = 226, MultipleChoices = 300, Ambiguous = 301, MovedPermanently = 302, Moved = 303, Found = 304, Redirect = 305, SeeOther = 306, RedirectMethod = 307, NotModified = 308, UseProxy = 400, Unused = 401, TemporaryRedirect = 402, RedirectKeepVerb = 403, PermanentRedirect = 404, BadRequest = 405, Unauthorized = 406, PaymentRequired = 407, Forbidden = 408, NotFound = 409, MethodNotAllowed = 410, NotAcceptable = 411, ProxyAuthenticationRequired = 412, RequestTimeout = 413, Conflict = 414, Gone = 415, LengthRequired = 416, PreconditionFailed = 417, RequestEntityTooLarge = 421, RequestUriTooLong = 422, UnsupportedMediaType = 423, RequestedRangeNotSatisfiable = 424, ExpectationFailed = 426, MisdirectedRequest = 428, UnprocessableEntity = 429, Locked = 431, FailedDependency = 451, UpgradeRequired = 500, PreconditionRequired = 501, TooManyRequests = 502, RequestHeaderFieldsTooLarge = 503, UnavailableForLegalReasons = 504, InternalServerError = 505, NotImplemented = 506, BadGateway = 507, ServiceUnavailable = 508, GatewayTimeout = 510, HttpVersionNotSupported = 511
}
class GetAllSendAttemptsOutput {
    constructor(public id: string, public webhookEventId: string, public creationTime: string, public webhookName ? : string, public data ? : string, public response ? : string, public responseStatusCode ? : HttpStatusCode) {}
}
class PagedResultDtoOfGetAllSendAttemptsOutput {
    constructor(public totalCount: number, public items ? : GetAllSendAttemptsOutput[]) {}
}
class GetAllSendAttemptsOfWebhookEventOutput {
    constructor(public id: string, public webhookSubscriptionId: string, public creationTime: string, public webhookUri ? : string, public response ? : string, public responseStatusCode ? : HttpStatusCode, public lastModificationTime ? : string) {}
}
class ListResultDtoOfGetAllSendAttemptsOfWebhookEventOutput {
    constructor(public items ? : GetAllSendAttemptsOfWebhookEventOutput[]) {}
}
class GetAllSubscriptionsOutput {
    constructor(public isActive: boolean, public id: string, public webhookUri ? : string, public webhooks ? : string[]) {}
}
class ListResultDtoOfGetAllSubscriptionsOutput {
    constructor(public items ? : GetAllSubscriptionsOutput[]) {}
}
class WebhookSubscription {
    constructor(public isActive: boolean, public id: string, public tenantId ? : number, public webhookUri ? : string, public secret ? : string, public webhooks ? : string[], public headers ? : any) {}
}
class ActivateWebhookSubscriptionInput {
    constructor(public subscriptionId: string, public isActive: boolean) {}
}
class GetAllAvailableWebhooksOutput {
    constructor(public name ? : string, public displayName ? : string, public description ? : string) {}
}
class ListResultDtoOfGetAllAvailableWebhooksOutput {
    constructor(public items ? : GetAllAvailableWebhooksOutput[]) {}
}
class GetLatestWebLogsOutput {
    constructor(public latestWebLogLines ? : string[]) {}
}
enum NodeShape {
    Circle = 0, Oval = 1, Rectangle = 2, Square = 3, Triangle = 4, RoundedRectangle = 5, Diamond = 6, ShearedRectangle = 7, ShearedRectangle2 = 8, Triangle2 = 9, Star5 = 10, Star6 = 11, Star8 = 12, Trapez = 13, Trapez2 = 14, Hexagon = 15, Octagon = 16, FatArrow = 17, FatArrow2 = 18, Pill = 19, TrapezShort = 20
}
enum NodeSize {
    ExtraSmall = 0, Small = 1, Medium = 2, Large = 3, ExtraLarge = 4
}
enum CompositeShape {
    RoundedRectangleWithTriangle = 0, RoundedRectangleWithOval = 1, TriangleWithRectangle = 2, TriangleWithOval = 3, OvalWithRectangle = 4, OvalWithTriangle = 5, RectangleWithTriangle2 = 6, RectangleWithTriangle = 7, OvalWithTriangle2 = 8, RectangleWithCross = 9, RectangleWithOval = 10, RectanglesWithOval = 11, RectangleWithDiamond = 12, RectangleWithRectangle = 13, RectangleWithRectangleWithRectangle = 14, TriangleWithTriangleWithTriangle = 15, OvalWithOvalWithOval = 16, RoundedRectangleWithTriangle2 = 17, TriangleStackedRight = 18, TriangleStackedLeft = 19
}
class ShapeNodeStyleDto {
    constructor(public fill ? : FillDto, public stroke ? : StrokeDto, public shape ? : NodeShape, public size ? : NodeSize, public visualType ? : NodeVisualType, public labelStyle ? : LabelStyleDto) {}
}
class CompositeNodeStyleShapeDto {
    constructor(public fill ? : FillDto, public stroke ? : StrokeDto, public shape ? : NodeShape) {}
}
class InsetsDto {
    constructor(public top: number, public left: number, public bottom: number, public right: number) {}
}
class CompositeStyleDefinitionDto {
    constructor(public nodeStyle ? : CompositeNodeStyleShapeDto, public insets ? : InsetsDto) {}
}
class CompositeNodeStyleDto {
    constructor(public shape ? : CompositeShape, public styleDefinitions ? : CompositeStyleDefinitionDto[], public visualType ? : NodeVisualType, public size ? : NodeSize, public labelStyle ? : LabelStyleDto) {}
}
class ImageNodeStyleDto {
    constructor(public visualType ? : NodeVisualType, public imageUrl ? : string, public imageRatio ? : number, public naturalHeight ? : number, public naturalWidth ? : number, public rotation ? : number, public labelStyle ? : LabelStyleDto) {}
}
class GroupNodeStyleDto {
    constructor(public visualType ? : NodeVisualType) {}
}
class TCellOptionsDto {
    constructor(public fontSize ? : number, public fontFamily ? : string, public backgroundColour ? : string, public colour ? : string) {}
}
class TImageCellOptionsDto {
    constructor(public imageWidth: number, public imageHeight: number, public fontSize ? : number, public fontFamily ? : string, public backgroundColour ? : string, public colour ? : string) {}
}
class FileItemDto {
    constructor(public id: number, public isFavourite: boolean, public type ? : FolderItemType, public lockedByUser ? : UserDto, public creatorUser ? : UserDto, public lastModifierUser ? : UserDto, public lockedCreated ? : string, public lastModificationTime ? : string, public creationTime ? : string, public deletionTime ? : string, public sharedWith ? : UserDto[], public thumb ? : string, public name ? : string, public removalTime ? : string, public currentUserAccessLevel ? : DocumentAccessLevel, public pathSegments ? : string[], public path ? : string) {}
}
class ZadarResponse < T > {
    public result: T;public success: boolean;public error: ErrorInfo;
}
class ErrorInfo {
    public code: number;
    public message: string;
    public details: string;
    public validationError: ValidationErrorInfo[];
}
class ValidationErrorInfo {
    public message: string;
    public members: string[];
}
export {
    ZadarResponse,
    ErrorInfo,
    ValidationErrorInfo,
    IsTenantAvailableInput,
    TenantAvailabilityState,
    IsTenantAvailableOutput,
    ResolveTenantIdInput,
    RegisterInput,
    RegisterOutput,
    SendPasswordResetCodeInput,
    ResetPasswordInput,
    ResetPasswordOutput,
    SendEmailActivationLinkInput,
    ActivateEmailInput,
    ImpersonateInput,
    ImpersonateOutput,
    DelegatedImpersonateInput,
    SwitchToLinkedAccountInput,
    SwitchToLinkedAccountOutput,
    AuditLogListDto,
    PagedResultDtoOfAuditLogListDto,
    FileDto,
    NameValueDto,
    EntityChangeType,
    EntityChangeListDto,
    PagedResultDtoOfEntityChangeListDto,
    EntityPropertyChangeDto,
    CacheDto,
    ListResultDtoOfCacheDto,
    EntityDtoOfString,
    FriendshipState,
    FriendDto,
    GetUserChatFriendsWithSettingsOutput,
    ChatSide,
    ChatMessageReadState,
    ChatMessageDto,
    ListResultDtoOfChatMessageDto,
    MarkAllUnreadMessagesOfUserAsReadInput,
    SubscribableEditionComboboxItemDto,
    ListResultDtoOfSubscribableEditionComboboxItemDto,
    FindUsersInput,
    PagedResultDtoOfNameValueDto,
    GetDefaultEditionNameOutput,
    Widget,
    Page,
    Dashboard,
    SavePageInput,
    RenamePageInput,
    AddNewPageInput,
    AddNewPageOutput,
    AddWidgetInput,
    WidgetFilterOutput,
    WidgetOutput,
    DashboardOutput,
    DataPropertyDto,
    GetDataPropertyForViewDto,
    PagedResultDtoOfGetDataPropertyForViewDto,
    CreateOrEditDataPropertyDto,
    DataPropertyType,
    DataPropertyValueScope,
    DataPropertyDefinitionItemDto,
    DataPropertyDefinitionDto,
    GetDataPropertyDefinitionForViewDto,
    CreateOrEditDataPropertyDefinitionDto,
    GetDataPropertyDefinitionForEditOutput,
    DataPropertyTagContext,
    DataPropertyTagDto,
    GetDataPropertyTagForViewDto,
    PagedResultDtoOfGetDataPropertyTagForViewDto,
    DataPropertyValueDto,
    GetDataPropertyValueForViewDto,
    PagedResultDtoOfGetDataPropertyValueForViewDto,
    CreateOrEditDataPropertyValueDto,
    GetDataPropertyValueForEditOutput,
    DataPropertyValueDataPropertyItemLookupTableDto,
    PagedResultDtoOfDataPropertyValueDataPropertyItemLookupTableDto,
    DateToStringOutput,
    NameValueOfString,
    StringOutput,
    HeaderFooterStyleDto,
    DocumentPageType,
    DocumentPageContentType,
    LayoutDto,
    TemplatePageItemStyle,
    TemplatePageItemMeta,
    TemplatePageItem,
    TemplatePage,
    NodeVisualType,
    INodeStyleDto,
    FileAttachmentDto,
    PowerPointDiagramNodeDto,
    FontDto,
    FillDto,
    LabelStyleDto,
    DashStyleType,
    DashStyleDto,
    StrokeDto,
    ArrowStyleDto,
    EdgeVisualType,
    EdgeStyleDto,
    PortDirection,
    EdgePortDto,
    PathCommandTypes,
    PointDto,
    PathCommand,
    PowerPointDiagramEdgeDto,
    TCellContentType,
    TCellContentDto,
    TCellDto,
    TRowOptionsDto,
    TRowDto,
    TColumnOptionsDto,
    TColumnDto,
    PageElementPosition,
    TDataDto,
    DiagramPosition,
    PowerPointExportSlideDto,
    PowerPointExportDto,
    PowerPointFileDto,
    DataExportFileAttachmentDto,
    DataExportDataPropertyDto,
    DataExportNodeDto,
    DataExportEdgeDto,
    DataExportDiagramDto,
    DataExportDocumentDto,
    DiagramImportRowValueDto,
    DiagramImportRowDto,
    GetDiagramImportRowForViewDto,
    PagedResultDtoOfGetDiagramImportRowForViewDto,
    CreateOrEditDiagramImportRowDto,
    GetDiagramImportRowForEditOutput,
    DiagramImportRowDiagramImportLookupTableDto,
    PagedResultDtoOfDiagramImportRowDiagramImportLookupTableDto,
    GetDiagramImportRowValueForViewDto,
    PagedResultDtoOfGetDiagramImportRowValueForViewDto,
    CreateOrEditDiagramImportRowValueDto,
    GetDiagramImportRowValueForEditOutput,
    DiagramImportRowValueDiagramImportRowLookupTableDto,
    PagedResultDtoOfDiagramImportRowValueDiagramImportRowLookupTableDto,
    DiagramImportRowValueDiagramTemplateLookupTableDto,
    PagedResultDtoOfDiagramImportRowValueDiagramTemplateLookupTableDto,
    DiagramImportDto,
    GetDiagramImportForViewDto,
    PagedResultDtoOfGetDiagramImportForViewDto,
    CreateOrEditDiagramImportDto,
    GetDiagramImportForEditOutput,
    DiagramImportDiagramLookupTableDto,
    PagedResultDtoOfDiagramImportDiagramLookupTableDto,
    DiagramNodeDto,
    DiagramEdgeDto,
    DiagramTemplateType,
    DiagramTemplateDto,
    QuickBuildState,
    DiagramDto,
    GetDiagramForViewDto,
    CloneDiagramDto,
    AllConnection,
    SemanticConnection,
    NodeConnection,
    Connections,
    Cv,
    Ocr,
    AIOutputDto,
    FileBase64Dto,
    GetDiagramTemplateForViewDto,
    PagedResultDtoOfGetDiagramTemplateForViewDto,
    CreateOrEditDiagramTemplateDto,
    GetDiagramTemplateForEditOutput,
    UserDto,
    DocumentAccessLevel,
    DocumentAccessDto,
    DocumentAccessRequestDto,
    PendingAccessRequestDto,
    GetPendingAccessRequestsOutputDto,
    RequestDocumentAccessDto,
    GrantDocumentAccessDto,
    ChangeDocumentAccessDto,
    ChangeDocumentIsPublicDto,
    ChangeDocumentDefaultAccessLevelDto,
    DocumentStepDto,
    DocumentPageLayoutType,
    PageOrientation,
    DocumentSubPageDto,
    DocumentPageDto,
    GetDocumentPageForViewDto,
    DocumentFilterDto,
    CreateOrEditDocumentPageDto,
    InsertNewPageOutputDto,
    StepsPagePropertiesDto,
    PagePosition,
    PageTemplateDto,
    DocumentAttachmentType,
    DocumentAttachmentDto,
    FontStyleDto,
    NodeStyleOverrideDto,
    DataPropertyStyleDto,
    DocumentDto,
    GetDocumentForViewDto,
    PagedResultDtoOfGetDocumentForViewDto,
    EditDocumentNameDto,
    EditDocumentAutoSaveDto,
    MarkAsFavouriteRequest,
    UpdateDocumentStructureDto,
    CreateOrEditDocumentDto,
    CreateOrEditDocumentOutput,
    EntityDto,
    RestoreDeletedEntityRequestDto,
    GetDocumentStepForViewDto,
    PagedResultDtoOfGetDocumentStepForViewDto,
    CreateOrEditDocumentStepDto,
    GetDocumentStepForEditOutput,
    DynamicParameterDto,
    ListResultDtoOfDynamicParameterDto,
    IValueValidator,
    IInputType,
    DynamicParameterValueDto,
    ListResultDtoOfDynamicParameterValueDto,
    EditionListDto,
    ListResultDtoOfEditionListDto,
    EditionEditDto,
    LocalizableComboboxItemDto,
    LocalizableComboboxItemSourceDto,
    FeatureInputTypeDto,
    FlatFeatureDto,
    GetEditionEditOutput,
    EditionCreateDto,
    CreateEditionDto,
    UpdateEditionDto,
    MoveTenantsToAnotherEditionDto,
    ElementType,
    ElementBehaviourDto,
    GetElementBehaviourForViewDto,
    PagedResultDtoOfGetElementBehaviourForViewDto,
    EmailTemplateDto,
    EmailRecipientDto,
    PrepareEmailTemplateRequestDto,
    PreparedEmailDto,
    EntityDynamicParameterDto,
    ListResultDtoOfEntityDynamicParameterDto,
    EntityDynamicParameterValueDto,
    ListResultDtoOfEntityDynamicParameterValueDto,
    GetAllEntityDynamicParameterValuesOutputItem,
    GetAllEntityDynamicParameterValuesOutput,
    InsertOrUpdateAllValuesInputItem,
    InsertOrUpdateAllValuesInput,
    CleanValuesInput,
    GetFileAttachmentForViewDto,
    PagedResultDtoOfGetFileAttachmentForViewDto,
    ThemeAttachmentType,
    CreateOrEditFileAttachmentDto,
    GetFileAttachmentForEditOutput,
    FolderItemType,
    IFolderItemDto,
    FolderSearchType,
    SearchFolderInputDto,
    SearchFolderResultDto,
    ListFolderContentsInputDto,
    FolderAccessLevel,
    FolderItemDto,
    FolderItemListResultDto,
    CreateFolderDto,
    RenameFolderRequestDto,
    FolderDto,
    DeleteFolderDto,
    MoveItemDto,
    MoveItemsRequestDto,
    CopyItemDto,
    CopyItemsRequestDto,
    GrantFolderAccessDto,
    RevokeFolderAccessDto,
    FolderAccessDto,
    CreateFriendshipRequestInput,
    CreateFriendshipRequestByUserNameInput,
    BlockUserInput,
    UnblockUserInput,
    AcceptFriendshipRequestInput,
    TopStatsData,
    RecentTenant,
    GetRecentTenantsOutput,
    ExpiringTenant,
    GetExpiringTenantsOutput,
    ChartDateInterval,
    IncomeStastistic,
    GetIncomeStatisticsDataOutput,
    TenantEdition,
    GetEditionTenantStatisticsOutput,
    GeneralSettingsEditDto,
    SessionTimeOutSettingsEditDto,
    HostUserManagementSettingsEditDto,
    EmailSettingsEditDto,
    TenantManagementSettingsEditDto,
    PasswordComplexitySetting,
    UserLockOutSettingsEditDto,
    TwoFactorLoginSettingsEditDto,
    SecuritySettingsEditDto,
    HostBillingSettingsEditDto,
    OtherSettingsEditDto,
    FacebookExternalLoginProviderSettings,
    GoogleExternalLoginProviderSettings,
    TwitterExternalLoginProviderSettings,
    MicrosoftExternalLoginProviderSettings,
    ExternalLoginProviderSettingsEditDto,
    HostSettingsEditDto,
    SendTestEmailInput,
    SendTestLdapInput,
    ClientAutoSaveSettingsDto,
    ClientDocumentSettingsDto,
    FeedbackSettingsDto,
    TourGuideConfigurationDto,
    EmailSettingsDto,
    ClientHostSettingsDto,
    ImportedAndValidatedDto,
    ImportedDataItemElementDataPropertyDto,
    ImportedDataItemElementValidatioErrorDto,
    ImportedDataItemElementDto,
    ImportValidationError,
    ImportedDataItemDto,
    ThemeElementDto,
    ThemeAttachmentDto,
    ThemeDataPropertyItemDto,
    ThemeDto,
    ImportedResultDto,
    ProcessImportedFileDto,
    ImportedFileValidationDto,
    ImportFileType,
    ImportDefinitionColumnType,
    MappingValueDto,
    MappingDto,
    ImportDefinitionColumnDto,
    ImportDataType,
    ImportDefinitionDto,
    ImportDto,
    PagedResultDtoOfImportDto,
    InstallDto,
    NameValue,
    AppSettingsJsonDto,
    CheckDatabaseOutput,
    InvoiceDto,
    CreateInvoiceDto,
    ApplicationLanguageListDto,
    GetLanguagesOutput,
    ApplicationLanguageEditDto,
    ComboboxItemDto,
    GetLanguageForEditOutput,
    CreateOrUpdateLanguageInput,
    SetDefaultLanguageInput,
    LanguageTextListDto,
    PagedResultDtoOfLanguageTextListDto,
    UpdateLanguageTextInput,
    LicenseUserDto,
    LicenseUserResultDto,
    LicenseDto,
    LicenseTypes,
    GetLicenseForViewDto,
    CreateOrEditLicenseDto,
    GetLicenseForEditOutput,
    UserNotificationState,
    NotificationData,
    NotificationSeverity,
    TenantNotification,
    UserNotification,
    GetNotificationsOutput,
    EntityDtoOfGuid,
    NotificationSubscriptionWithDisplayNameDto,
    GetNotificationSettingsOutput,
    NotificationSubscriptionDto,
    UpdateNotificationSettingsInput,
    OrganizationUnitDto,
    ListResultDtoOfOrganizationUnitDto,
    OrganizationUnitUserListDto,
    PagedResultDtoOfOrganizationUnitUserListDto,
    OrganizationUnitRoleListDto,
    PagedResultDtoOfOrganizationUnitRoleListDto,
    CreateOrganizationUnitInput,
    UpdateOrganizationUnitInput,
    MoveOrganizationUnitInput,
    UsersToOrganizationUnitInput,
    RolesToOrganizationUnitInput,
    FindOrganizationUnitUsersInput,
    FindOrganizationUnitRolesInput,
    EditionSelectDto,
    PaymentInfoDto,
    EditionPaymentType,
    PaymentPeriodType,
    SubscriptionPaymentGatewayType,
    CreatePaymentDto,
    CancelPaymentDto,
    SubscriptionPaymentListDto,
    PagedResultDtoOfSubscriptionPaymentListDto,
    PaymentGatewayModel,
    SubscriptionPaymentStatus,
    SubscriptionPaymentDto,
    PayPalConfigurationDto,
    CreateOrEditDataPropertyDefinitionItemDto,
    PersonImportRowDto,
    KeyValuePairOfStringString,
    FlatPermissionWithLevelDto,
    ListResultDtoOfFlatPermissionWithLevelDto,
    CurrentUserProfileEditDto,
    UpdateGoogleAuthenticatorKeyOutput,
    SendVerificationSmsInputDto,
    VerifySmsCodeInputDto,
    ChangePasswordInput,
    UpdateProfilePictureInput,
    GetPasswordComplexitySettingOutput,
    GetProfilePictureOutput,
    ChangeUserLanguageDto,
    RoleListDto,
    ListResultDtoOfRoleListDto,
    RoleEditDto,
    FlatPermissionDto,
    GetRoleForEditOutput,
    CreateOrUpdateRoleInput,
    UserLoginInfoDto,
    SubscriptionPaymentType,
    EditionInfoDto,
    TenantLoginInfoDto,
    ApplicationInfoDto,
    ThemeLayoutSettingsDto,
    ThemeHeaderSettingsDto,
    ThemeSubHeaderSettingsDto,
    ThemeMenuSettingsDto,
    ThemeFooterSettingsDto,
    ThemeSettingsDto,
    UiCustomizationSettingsDto,
    GetCurrentLoginInformationsOutput,
    UpdateUserSignInTokenOutput,
    StripeConfigurationDto,
    StripeCreatePaymentSessionInput,
    StripePaymentResultOutput,
    TemplateConfiguration,
    TenantListDto,
    PagedResultDtoOfTenantListDto,
    CreateTenantInput,
    TenantEditDto,
    GetTenantFeaturesEditOutput,
    UpdateTenantFeaturesInput,
    MemberActivity,
    GetMemberActivityOutput,
    SalesSummaryDatePeriod,
    SalesSummaryData,
    GetDashboardDataOutput,
    GetTopStatsOutput,
    GetProfitShareOutput,
    GetDailySalesOutput,
    GetSalesSummaryOutput,
    RegionalStatCountry,
    GetRegionalStatsOutput,
    GetGeneralStatsOutput,
    SubscriptionStartType,
    RegisterTenantInput,
    RegisterTenantOutput,
    FlatFeatureSelectDto,
    EditionWithFeaturesDto,
    EditionsSelectOutput,
    DocumentConfigurationDto,
    TenantUserManagementSettingsEditDto,
    LdapSettingsEditDto,
    LicenseSettingsEditDto,
    TemplateConfigurationDto,
    TenantBillingSettingsEditDto,
    TenantOtherSettingsEditDto,
    TenantSettingsEditDto,
    GetThemeElementForViewDto,
    PagedResultDtoOfGetThemeElementForViewDto,
    CreateOrEditThemeElementDto,
    GetThemeElementForEditOutput,
    GetThemeForViewDto,
    PagedResultDtoOfGetThemeForViewDto,
    CloneThemeDto,
    SetDefaultThemeInput,
    GetThemeOutput,
    CreateOrEditThemeDto,
    SettingScopes,
    ListResultDtoOfNameValueDto,
    UserListRoleDto,
    UserListDto,
    PagedResultDtoOfUserListDto,
    UserEditDto,
    UserRoleDto,
    GetUserForEditOutput,
    GetUserPermissionsForEditOutput,
    EntityDtoOfInt64,
    UpdateUserPermissionsInput,
    CreateOrUpdateUserInput,
    CreateOrUpdateUserOutput,
    UserDelegationDto,
    PagedResultDtoOfUserDelegationDto,
    CreateUserDelegationDto,
    LinkToUserInput,
    LinkedUserDto,
    PagedResultDtoOfLinkedUserDto,
    ListResultDtoOfLinkedUserDto,
    UnlinkUserInput,
    UserLoginAttemptDto,
    ListResultDtoOfUserLoginAttemptDto,
    WebhookEvent,
    HttpStatusCode,
    GetAllSendAttemptsOutput,
    PagedResultDtoOfGetAllSendAttemptsOutput,
    GetAllSendAttemptsOfWebhookEventOutput,
    ListResultDtoOfGetAllSendAttemptsOfWebhookEventOutput,
    GetAllSubscriptionsOutput,
    ListResultDtoOfGetAllSubscriptionsOutput,
    WebhookSubscription,
    ActivateWebhookSubscriptionInput,
    GetAllAvailableWebhooksOutput,
    ListResultDtoOfGetAllAvailableWebhooksOutput,
    GetLatestWebLogsOutput,
    NodeShape,
    NodeSize,
    CompositeShape,
    ShapeNodeStyleDto,
    CompositeNodeStyleShapeDto,
    InsetsDto,
    CompositeStyleDefinitionDto,
    CompositeNodeStyleDto,
    ImageNodeStyleDto,
    GroupNodeStyleDto,
    TCellOptionsDto,
    TImageCellOptionsDto,
    FileItemDto
}