import ISpecification from './ISpecification';
import NotSpecification from './NotSpecification';

// This Specification takes all other specifications, groups them by their type and processes with specific logic
// Basically Not Specification should be processed by using AND condition
// and other specifications should be processed by using OR condition
export default class MixedSpecification implements ISpecification {
  private conditions: ISpecification[];

  constructor(conditions: ISpecification[]) {
    this.conditions = conditions;
  }

  public isSatisfiedBy(candidate: any): boolean {
    const notSpecs = this.conditions.filter(
      (c) => c instanceof NotSpecification
    );
    let notSpecsResult = true;
    if (notSpecs.length > 0) {
      notSpecsResult = notSpecs.every((c) => c.isSatisfiedBy(candidate));
    }

    const otherSpecs = this.conditions.filter(
      (c) => !(c instanceof NotSpecification)
    );
    let otherSpecsResult = true;
    if (otherSpecs.length > 0) {
      otherSpecsResult = otherSpecs.some((c) => c.isSatisfiedBy(candidate));
    }

    return notSpecsResult && otherSpecsResult;
  }
}
