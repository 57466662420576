



































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { TMsgBoxTypes } from './index';
import { isVNode } from '@/core/utils/common.utils';

const iconClasses = {
  info: 'j-text-accent-purple',
  warning: 'j-text-warning-orange',
  success: 'j-text-success-green',
  error: 'j-text-danger-red',
  confirm: 'j-text-danger-red',
};

const iconTypes = {
  info: 'notification-info',
  warning: 'notification-warning',
  success: 'notification-success',
  error: 'notification-error',
  confirm: 'notification-alert',
};

@Component({
  name: 'Confirm',
})
export default class Confirm extends Vue {
  @Prop({ default: 'info' })
  type: TMsgBoxTypes;

  @Prop({ required: true })
  title: string;

  @Prop({ required: true })
  text: string;

  @Prop({ default: 'No' })
  denyText: string;

  @Prop({ default: 'Yes' })
  okText: string;

  @Prop({ default: false })
  closeOnEsc: boolean;

  @Prop({ default: false })
  closeOnBackdrop: boolean;

  @Prop({ default: null })
  promise: { resolve: () => void; reject: () => void };

  @Prop({ default: '' })
  bodyClasses: string;

  @Prop({ default: 560 })
  minWidth: string | number;

  @Prop({ default: 560 })
  maxWidth: string | number;

  @Prop({ default: 560 })
  isConfirm: string | number;

  value = false;

  get iconClass() {
    return this.isConfirm ? iconClasses.confirm : iconClasses[this.type];
  }

  get iconName() {
    return this.isConfirm ? iconTypes.confirm : iconTypes[this.type];
  }

  get isContextVNode() {
    return isVNode(this.text);
  }

  get modalStyles() {
    return {
      minWidth: `${this.minWidth}px`,
      maxWidth: `${this.maxWidth}px`,
    };
  }

  backdropClick() {
    this.close();
  }

  ok() {
    this.value = false;
    setTimeout(() => {
      this.promise.resolve();
    }, 100);
  }

  close() {
    this.value = false;
    setTimeout(() => {
      this.promise.reject();
    }, 100);
  }

  mounted() {
    this.$nextTick(() => {
      this.value = true;
    });

    const escHandler = (evt: KeyboardEvent) => {
      if (!this.closeOnEsc) return;

      if (evt.key === 'Escape' && this.value) {
        this.close();
      }
    };

    document.addEventListener('keydown', escHandler);

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escHandler);
    });
  }
}
