import appConfig from '@/core/config/appConfig';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AnalyticsEvent } from './AnalyticsEvent';
import Vue from 'vue';
import AuthService from '../auth/AuthService';

export default class AnalyticsService {
  private static _appInsights: ApplicationInsights;
  private static heartbeatHandle: number = null;
  private static readonly heartbeatInterval = 30000;
  private static instanceId = 'unknown';

  public static setAuthenticatedUser(userId: string) {
    try {
      if (appConfig.appInsights.enabled) {
        this.getAppInsights().setAuthenticatedUserContext(userId);
        console.debug(
          `[Application Insights] Authenticated user set to '${userId}'`
        );
      }
    } catch (e) {
      console.debug(
        '[Application Insights] Failed to set authenticated user',
        e
      );
    }
  }

  public static setInstanceId(instanceId: string) {
    if (appConfig.appInsights.enabled) {
      this.instanceId = instanceId;
      console.debug(`[Application Insights] Instance id set to ${instanceId}`);
    }
  }

  public static trackPageView(pageName: string) {
    try {
      if (appConfig.appInsights.enabled) {
        this.getAppInsights().trackPageView({ name: pageName });
        console.debug('[Application Insights] Page view tracked');
      }
    } catch (e) {
      console.debug('[Application Insights] Failed to track page view', e);
    }
  }

  public static trackEvent(event: AnalyticsEvent, properties: Object = null) {
    try {
      if (appConfig.appInsights.enabled) {
        this.getAppInsights().trackEvent({
          name: event,
          properties: properties,
        });
        console.debug(`[Application Insights] Event tracked: '${event}'`);
      }
    } catch (e) {
      console.debug(
        `[Application Insights] Failed to track event: '${event}'`,
        e
      );
    }
  }

  public static trackError(error: Error) {
    try {
      if (appConfig.appInsights.enabled) {
        this.getAppInsights().trackException({ exception: error });
        console.debug(`[Application Insights] Error tracked`);
      }
    } catch (e) {
      console.debug(`[Application Insights] Failed to track error`, e);
    } finally {
      return false;
    }
  }

  public static trackUnhandledErrors() {
    if (appConfig.appInsights.enabled) {
      Vue.config.errorHandler = (error, vm, info) => {
        this.trackError(error);
      };
      window.onerror = (msg, url, lineNo, columnNo, error) => {
        this.trackError(error);
      };
    }
  }

  public static enableHeartbeat() {
    if (appConfig.appInsights.enabled) {
      clearInterval(this.heartbeatHandle);
      this.heartbeatHandle = setInterval(() => {
        if (AuthService.isLoggedIn) {
          let token = AuthService.getCachedAccessToken();
          if (token) {
            fetch(`${appConfig.apiBaseUrl}/heartbeat`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }).then(() => {
              console.debug(`[Application Insights] Heartbeat`);
            });
          }
        }
      }, this.heartbeatInterval) as any;
      console.info(`[Application Insights] Heartbeat enabled`);
    }
  }

  public static disableHeartbeat() {
    clearInterval(this.heartbeatHandle);
    console.info(`[Application Insights] Heartbeat disabled`);
  }

  private static getAppInsights() {
    if (!this._appInsights && appConfig.appInsights.enabled) {
      this._appInsights = new ApplicationInsights({
        config: appConfig.appInsights,
      });
      this._appInsights.loadAppInsights();
      this._appInsights.addTelemetryInitializer((telemetryItem) => {
        telemetryItem.tags['ai.cloud.role'] = this.instanceId;
      });
      console.info('[Application Insights] Initialized');
    }
    return this._appInsights;
  }
}
