import Vue from 'vue';
import App from '@/App.vue';
import router, { RouteNames } from '@/router';
import store from '@/core/services/store';
import ApiService from '@/core/services/api.service';
import { SharedComponents } from '@/components/shared';
import { PopUpPlugin } from '@/components/shared/AppConfirm';
import { NotificationPlugin } from '@/components/shared/AppNotification';
import {
  GET_TENANT_CONFIG,
  LOAD_TENANT_CONFIG,
} from '@/core/services/store/tenant.module';
import appConfig from '@/core/config/appConfig';
import DebugComponent from '@/components/DebugComponent/Debug.vue';
import { PortalNames } from '@/core/config/portalNames';
import '@/filters';
Vue.config.productionTip = false;

// Global 3rd party plugins
import 'popper.js';
import 'tooltip.js';

import PerfectScrollbar from 'perfect-scrollbar';
(<any>window).PerfectScrollbar = PerfectScrollbar;
(<any>window).ClipboardJS;

// Vue 3rd party plugins

import '@/core/plugins/portal-vue';
import '@/core/plugins/bootstrap-vue';
import '@/core/plugins/perfect-scrollbar';
import '@/core/plugins/highlight-js';
import '@/core/plugins/inline-svg';
import '@/core/plugins/metronic';
import '@/core/plugins/virtual-scroller';
import '@mdi/font/css/materialdesignicons.css';
import Toasted from 'vue-toasted';
import {
  GET_CURRENT_USER,
  GET_PROFILE,
  SET_USER_LANGUAGE,
} from './core/services/store/user.module';
import ErrorMixin from './mixins/Error.mixin';
import EventBusMixin from './mixins/EventBus.mixin';
import QuickPanelMixin from './mixins/QuickPanel.mixin';
// VC Ribbon
import VcRibbon from '@jigsaw/vc-ribbon';
import '@jigsaw/vc-ribbon/dist/lib/vc-ribbon.min.css';

import './directives/click-outside';
import './directives/visible';
import './directives/scalefit';
import './directives/movable';

import '@/assets/tailwind/tailwind.css';
import '@/assets/icon-font/style.css';

import VueWait from 'vue-wait';
import { LOAD_LICENSE } from './core/services/store/licence.module';
import vSelect from 'vue-select';

import CommonNameRouteMixin from './mixins/CommonNameRoute.mixin';
import AnalyticsService from './core/services/analytics/AnalyticsService';
// import the API URL
import config from './config.json';
import AuthService from './core/services/auth/AuthService';
import { ErrorHandler } from './core/services/auth/Errors/ErrorHandler';
import { ErrorCodes } from './core/services/auth/Errors/ErrorCodes';
import { TokenRenewalResponseHandler } from './core/services/auth/TokenRenewalResponseHandler';
import { GET_SETTINGS } from './core/services/store/settings.module';
import {
  ClientHostSettingsDto,
  CurrentUserProfileEditDto,
  GetCurrentLoginInformationsOutput,
} from '@/api/models';
import FeaturesMixin from './mixins/FeaturesMixin';
import JigsawTranslateMixin from './mixins/JigsawTranslateMixin';
import i18nService from './core/services/i18n.service';
import IdleManager from './core/services/idle/IdleManager';
import i18n from './core/plugins/vue-i18n';
import '@jigsaw/ckeditor5-custom-build/build/translations/fr';
import PermissionMixin from './mixins/Permission.mixin';
import PendoService from './core/services/analytics/PendoService';

function loadConfig(): Promise<any> {
  return fetch(config.apiBaseUrl + '/configuration')
    .then((d) => {
      if (d.status != 200) {
        throw new Error('Error loading config');
      }
      return d.json();
    })
    .then((d) => {
      // Assign the imported API URL
      appConfig.apiBaseUrl = config.apiBaseUrl;
      // Copy config from API onto AppConfig
      Object.assign(appConfig, d.result);
    })
    .catch((d) => {
      console.debug('Error loading config');
      let appElement = document.getElementById('app');
      appElement.style.height = '100%';
      appElement.style.backgroundImage = 'url("media/bg/bg-2.jpg")';
      appElement.innerHTML =
        '<div  style="color:#ffffff;text-align: center; "><h1 style="margin-top: 3rem;font-size: 6rem;">Sorry, something went wrong</h1>' +
        '<p>Please try refreshing the page.</p>' +
        '<p>If the problem persist please let us know</p>' +
        '<p><a href="mailto:support@jigsawcreate.com">support@jigsawcreate.com</a></p>' +
        '</div>';
      return Promise.reject(d);
    });
}

/**
 * Called to start the application
 */
async function startApp() {
  // Auth service init
  await AuthService.init(() => {
    // Handle logout notifications from other browser tabs
    window.location.reload();
  });

  // API service init
  ApiService.init();

  PendoService.injectPendo(appConfig.pendo.apiKey);

  // Track all unhandled exceptions
  AnalyticsService.trackUnhandledErrors();
  // Post heartbeat event every 30 seconds
  AnalyticsService.enableHeartbeat();

  // Set analytics user
  store.watch(
    (state) => store.getters[GET_CURRENT_USER],
    (newValue: CurrentUserProfileEditDto) => {
      if (newValue?.emailAddress) {
        AnalyticsService.setAuthenticatedUser(newValue.emailAddress);
      }
      if (newValue?.language) {
        // Only setup Pendo once language selection has been completed
        PendoService.setAuthenticatedUser(newValue);
      }
    }
  );

  // Set analytics instance id
  store.watch(
    (state) => store.getters[GET_TENANT_CONFIG],
    (newValue: GetCurrentLoginInformationsOutput) => {
      if (newValue?.application?.instanceId) {
        AnalyticsService.setInstanceId(newValue.application.instanceId);
      }
    }
  );

  store.dispatch(LOAD_TENANT_CONFIG);
  if (AuthService.isLoggedIn) {
    await handleLogin();
    await handleLanguageSelection();
  }

  Vue.component('Debug', DebugComponent);

  Vue.use(Toasted, {
    duration: 1500,
  });
  Vue.use(PopUpPlugin);
  Vue.use(NotificationPlugin, { displayCount: 6 });
  Vue.use(SharedComponents);
  Vue.use(VueWait);
  Vue.use(VcRibbon);

  const wait = new VueWait({
    useVuex: true,
    registerComponent: true,
    componentName: 'v-wait',
    registerDirective: true,
    directiveName: 'wait',
  });

  vSelect.props.components.default = () => ({
    Deselect: {
      render: (createElement) =>
        createElement('i', {
          attrs: {
            class: 'las la-times',
          },
        }),
    },
    OpenIndicator: {
      render: (createElement) =>
        createElement('img', {
          attrs: {
            src: '/resources/icons/icon-caret-down_24_px.png',
          },
        }),
    },
  });
  Vue.component('v-select', vSelect);
  Vue.mixin(ErrorMixin);
  Vue.mixin(EventBusMixin);
  Vue.mixin(QuickPanelMixin);
  Vue.mixin(CommonNameRouteMixin);
  Vue.mixin(FeaturesMixin);
  Vue.mixin(JigsawTranslateMixin);
  Vue.mixin(PermissionMixin);

  Vue.prototype.$routeNames = RouteNames;
  Vue.prototype.$portalNames = PortalNames;
  Vue.prototype.$auth = AuthService;

  new Vue({
    router,
    store,
    // @ts-ignore
    wait,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
}

async function handleLogin() {
  await store.dispatch(GET_PROFILE);
  await store.dispatch(LOAD_LICENSE);
  const settings = (await store.dispatch(
    GET_SETTINGS
  )) as ClientHostSettingsDto;
  IdleManager.INSTANCE.setSessionIdle(settings.sessionTimeout);
  IdleManager.INSTANCE.setAutoSaveSettings(settings.autoSaveSettings);
}

async function handleLanguageSelection() {
  const user = store.getters.currentUser;
  i18nService.setActiveLanguage(user.language);
  await i18nService.init();
}

(window as any).jigsaw = {
  debug: {
    on: () => store.dispatch('debug/setDebug', true),
    off: () => store.dispatch('debug/setDebug', false),
  },
};

/**
 * Handles token renewal responses in an iframe
 */
async function handleTokenRenewal() {
  try {
    const handler = new TokenRenewalResponseHandler();
    await handler.handleSilentTokenRenewalResponse();
  } catch (e) {
    const error = ErrorHandler.getFromTokenError(
      e,
      ErrorCodes.tokenRenewalError
    );
    console.error(error);
  }
}

loadConfig().then(() => {
  if (window.top === window.self) {
    // Run the main app
    startApp();
  } else {
    // If the app is running in an iframe, handle token renewal responses
    handleTokenRenewal();
  }
});
