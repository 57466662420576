




























import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'ProgressLoaders',
})
export default class Buttons extends Vue {
  isLoading = false;
  isLoading2 = false;
  isLoading3 = false;
  isLoading4 = false;

  showLoading(prop) {
    this[prop] = true;

    setTimeout(() => {
      this[prop] = false;
    }, 2000);
  }
}
