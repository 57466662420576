import { PluginObject } from 'vue';

export interface IPopupBase {
  title: string;
  text: string | object; // VNode
  okText?: string;
  closeOnEsc?: boolean;
  closeOnBackdrop?: boolean,
  bodyClasses?: string;
  minWidth?: number;
  maxWidth?: number;
  isConfirm?: boolean;
}

export type TMsgBoxTypes = 'info' | 'warning' | 'error' | 'success';
export interface IMsgBox extends IPopupBase {
  type?: TMsgBoxTypes;
}
export interface IConfirm extends IPopupBase {
  denyText?: string;
}

export type Confirmation = (arg: IConfirm) => Promise<boolean>;
export type MsgBox = (arg: IMsgBox) => Promise<boolean>;

export const list = {
  list: [],
};

const removeById = (id: string | number) => {
  const idx = list.list.findIndex(x => x.id === id);
  list.list.splice(idx, 1);
}

const createNewPopUp = (confirmOptions: IPopupBase, isConfirm: boolean) => {
  const id = Date.now();

  return new Promise<boolean | void>((resolve, reject) => {
    const newPopup = {
      ...(!isConfirm && { okText: 'Ok' }),
      ...confirmOptions,
      promise: {
        resolve,
        reject
      },
      id,
      isConfirm,
    };

    list.list.push(newPopup);

    console.debug('PopUpPlugin list: ', list);
  }).then((sum) => {
    console.debug('PopUpPlugin: resolve', id);
    removeById(id);
    return sum;
  }).catch(err => {
    console.debug('PopUpPlugin: reject', id);
    removeById(id);
    throw (err);
  })
}


const confirm = (confirmOptions: IPopupBase): Promise<boolean | void> => createNewPopUp(confirmOptions, true)
const msgBox = (confirmOptions: IPopupBase): Promise<boolean | void> => createNewPopUp(confirmOptions, false)

export const PopUpPlugin: PluginObject<any> = {
  install(Vue) {
    console.debug('Installing popups [$confirm] [$msgBox]...');
    Vue.prototype.$confirm = confirm;
    Vue.prototype.$msgBox = msgBox;
  }
};
