







































































import { hasOwnProperty } from '@/core/utils/common.utils';
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';
import { AppTableField, AppTableSortOptions, TIndexedObject } from '.';

@Component({
  name: 'TableHeaderCell',
})
export default class TableHeader extends Vue {
  @Prop({ required: true })
  header: AppTableField;

  @Prop({ type: Object, default: () => ({}) })
  sorting: TIndexedObject;

  @Prop({ default: false, type: Boolean })
  readonly: boolean;

  get sortOptions(): AppTableSortOptions {
    const ascLabel = this.$t('SORT_OLDER_TO_NEWER');
    const descLabel = this.$t('SORT_NEWER_TO_OLDER');

    return (
      this.header?.sortOptions || {
        groupTitle: this.$t('SORT_BY_X', [this.header.label]),
        ascending: { label: ascLabel, value: 'asc' },
        descending: { label: descLabel, value: 'desc' }
      }
    );
  }

  get isSortingApplied() {
    return this.sorting?.key === this.header.key;
  }

  get isSortingAscending() {
    if (this.isSortingApplied) {
      return this.sorting?.value === this.sortOptions.ascending.value;
    }

    return false;
  }

  @Inject() filterHandler: (key: string, direction: string) => void;
}
