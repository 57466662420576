import Vue from 'vue';
import Router, { Route, RouterOptions } from 'vue-router';
import { RouterParams } from './core/config/routerParams';
import AnalyticsService from './core/services/analytics/AnalyticsService';
import { playgroundRoutes } from './playgrounds/routes';

// Hub Pages
const Home = () => import('@/view/pages/hub/home/Home.vue');
const AllDocumentsPage = () =>
  import('@/view/pages/hub/all-documents/AllDocuments.vue');
const DocumentsAccessRequests = () =>
  import(
    '@/view/pages/hub/documents-access-requests/DocumentsAccessRequests.vue'
  );
const DocumentPreview = () =>
  import('@/view/pages/hub/document-preview/DocumentPreview.vue');
const DocumentManager = () =>
  import('@/view/pages/hub/document-manager/DocumentManager.vue');
const Favourites = () => import('@/view/pages/hub/favourites/Favourites.vue');
const Templates = () => import('@/view/pages/hub/templates/Templates.vue');
const DeletedFiles = () =>
  import('@/view/pages/hub/deleted-files/DeletedFiles.vue');

// TODO: add props for all available routes
export const RouteNames = {
  home: 'landing',
  allDocuments: 'all-documents',
  favourites: 'favourites',
  shared: 'shared',
  templates: 'templates',
  deletedFiles: 'deleted',
  accessRequests: 'access-requests',
  preview: 'preview',
  documentManager: 'document-manager',
  themes: 'hub-themes'
};

Vue.use(Router);

class JigsawVueRouter extends Router {
  constructor(options: RouterOptions) {
    super(options);
  }

  resolve(to, current, append) {
    let resolved = super.resolve(to, current, append);
    return resolved;
  }
}

const router = new JigsawVueRouter({
  routes: [
    {
      path: '/documents',
      component: () => import('@/view/pages/document/DocumentLayout.vue'),
      children: [
        {
          path: ':' + RouterParams.documentId,
          components: {
            default: () => import('@/view/pages/document/DocumentDetails.vue'),
            side: () =>
              import('@/view/pages/document/sidebar/DocumentSideBar.vue'),
          },
          props: {
            default: (route) => {
              return {
                documentId: Number(route.params[RouterParams.documentId]),
              };
            },
          },
          children: [
            {
              path: '',
              name: 'document-details',
            },
            {
              name: 'document-themes',
              path: 'themes',
              props: {
                query: {
                  page: '1',
                },
              },
              components: {
                sidepanel: () =>
                  import('@/view/pages/administration/ThemesList.vue'),
              },
              meta: {
                openSidePanel: true,
                commonName: 'themes',
              },
            },
            {
              name: 'document-theme-details',
              path: 'themes/:themeid',
              components: {
                sidepanel: () =>
                  import('@/view/pages/administration/ThemeDetails.vue'),
              },
              props: {
                sidepanel: (route) => {
                  return {
                    id: Number(route.params.themeid),
                  };
                },
              },
              meta: {
                openSidePanel: true,
                commonName: 'theme-details',
              },
            },
            {
              path: 'templates',
              name: 'document-templates',
              meta: {
                commonName: 'templates',
              },
              components: {
                sidepanel: () =>
                  import('@/view/pages/templates/TemplatesPage.vue'),
              },
            },
            {
              path: 'favorites',
              name: 'document-favorites',
              meta: {
                commonName: 'favorites',
              },
              components: {
                sidepanel: () =>
                  import('@/view/pages/favorites/FavoritesPage.vue'),
              },
            },
          ],
        },
      ],
    },
    {
      path: '/hub',
      component: () => import('@/view/layout/AppLayout.vue'),
      children: [
        // New Pages
        {
          path: '/',
          name: RouteNames.home,
          component: Home,
          meta: {
            skipPadding: true,
          },
        },
        {
          path: `/all-documents/:${RouterParams.folderId}?`,
          name: RouteNames.allDocuments,
          component: AllDocumentsPage,
        },
        {
          path: `/favourites/:${RouterParams.folderId}?`,
          name: RouteNames.favourites,
          component: Favourites,
        },
        // Temporary disabled
        // {
        //   path: '/templates',
        //   name: RouteNames.templates,
        //   component: Templates,
        // },
        {
          path: '/deleted',
          name: RouteNames.deletedFiles,
          component: DeletedFiles,
        },
        {
          path: '/access-requests',
          name: RouteNames.accessRequests,
          component: DocumentsAccessRequests,
        },
        {
          path: '/preview/:' + RouterParams.documentId,
          name: RouteNames.preview,
          component: DocumentPreview,
        },
        {
          path: '/document-manager',
          name: RouteNames.documentManager,
          component: DocumentManager,
        },
        // New Pages End
        {
          path: 'themes',
          name: RouteNames.themes,
          props: {
            query: {
              page: '1',
            },
          },
          meta: {
            commonName: 'themes',
          },
          component: () => import('@/view/pages/administration/ThemesList.vue'),
        },
        {
          path: 'import',
          name: 'hub-import',
          props: {
            query: {
              page: '1',
            },
          },
          meta: {
            commonName: 'import',
          },
          component: () => import('@/view/pages/hub/ImportsPreview.vue'),
        },

        {
          name: 'hub-theme-details',
          path: 'themes/:themeid',
          meta: {
            commonName: 'theme-details',
          },
          component: () =>
            import('@/view/pages/administration/ThemeDetails.vue'),
          props: (route) => ({
            id: Number(route.params.themeid),
          }),
        },
        {
          path: 'templates',
          name: 'hub-templates',
          meta: {
            commonName: 'templates',
          },
          component: () => import('@/view/pages/templates/TemplatesPage.vue'),
        },
        {
          path: 'favorites',
          name: 'hub-favorites',
          meta: {
            commonName: 'favorites',
          },
          component: () => import('@/view/pages/favorites/FavoritesPage.vue'),
        },
      ],
    },

    {
      path: '/login',
      component: () => import('@/view/layout/Auth.vue'),
      children: [
        {
          name: 'login',
          path: '/login',
          component: () => import('@/view/pages/auth/Login.vue'),
          meta: {
            anonymous: true,
          },
        },
      ],
    },
    {
      path: '/changepassword',
      component: () => import('@/view/layout/Auth.vue'),
      children: [
        {
          name: 'changepassword',
          path: '/changepassword',
          component: () => import('@/view/pages/auth/ChangePassword.vue'),
          meta: {
            anonymous: false,
          },
        },
      ],
    },
    {
      path: '/logout',
      component: () => import('@/view/layout/Auth.vue'),
      children: [
        {
          name: 'logout',
          path: '/logout',
          component: () => import('@/view/pages/auth/Logout.vue'),
          meta: {
            commonName: 'logout',
            anonymous: false,
          },
        },
      ],
    },
    // playground routes will be included only if development mode
    ...(process.env.NODE_ENV === 'development' ? [playgroundRoutes] : []),
    {
      path: '*',
      redirect: '/hub',
      meta: {
        anonymous: true,
      },
    },
    {
      // the 404 route, when none of the above matches
      path: '/403',
      name: '403',
      component: () => import('@/view/pages/error/Error403.vue'),
      meta: {
        anonymous: true,
      },
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/view/pages/error/Error404.vue'),
      meta: {
        anonymous: true,
      },
    },
  ],
});

router.afterEach((to: Route, from: Route) => {
  AnalyticsService.trackPageView(to.name);
});

export default router;
