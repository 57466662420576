import DecorationStateManager from '@/core/services/DecorationStateManager';
import {
  IInputModeContext,
  ImageNodeStyle,
  INode,
  IRectangle,
  IRenderContext,
  Point,
  Rect,
  ShapeNodeShape,
  SimpleNode,
  Size,
  SvgVisual,
  SvgVisualGroup,
  Visual,
} from 'yfiles';
import DecorationState from '../DecorationState';
import HitResult from '../HitResult';
import JigsawNodeStyleBase from '../JigsawNodeStyleBase';
import JigsawNodeDecorator from './JigsawNodeDecorator';
import DiagramUtils from '@/core/utils/DiagramUtils';
import { AnnotationType } from '@/core/common/AnnotationType';

export interface IndicatorState extends DecorationState {
  indicators: string[];
}
export default class IndicatorDecorators implements JigsawNodeDecorator {
  public $class = 'IndicatorDecorators';
  public static INSTANCE: IndicatorDecorators = new IndicatorDecorators();
  /**
   * Dummy decoration node, used for layout positioning
   */
  public dummyDecorationNode: SimpleNode;
  /**
   * Image style used for rendering the indicator
   */
  public imageStyle: ImageNodeStyle;
  /**
   * Size  of indicator
   */
  public size: Size;
  /**
   * The spacing between each of the decorators to render
   */
  public spacing: number;
  /**
   * Horizonal padding of indicators, the padding is used to push the indicators from the left of the node
   */
  public horizonalPadding: number;
  /**
   * Vertical padding of indicators, the padding is used to push the indicators up from the bottom of the node
   */
  public verticalPadding: number;

  constructor(options?: {
    size?: Size;
    spacing?: number;
    horizonalPadding?: number;
    verticalPadding?: number;
  }) {
    this.size = options?.size ?? new Size(18, 18);
    this.spacing = options?.spacing ?? 20;
    this.horizonalPadding = options?.horizonalPadding ?? 5;
    this.verticalPadding = options?.verticalPadding ?? 2;
    /*setup */
    // dummy node for rendering
    this.dummyDecorationNode = new SimpleNode();
    // image style for rendering
    this.imageStyle = new ImageNodeStyle();
    // set default dummy node layout
    this.dummyDecorationNode.layout = new Rect(new Point(0, 0), this.size);
  }
  isVisible(renderContext: IRenderContext, node: INode): boolean {
    return true;
  }
  createVisual(context: IRenderContext, node: INode): Visual {
    let visualGroup = new SvgVisualGroup();
    let indicators = this.getNodeIndicators(node)!;

    for (let i = 0; i < indicators.length; i++) {
      let indicator = indicators[i];
      this.imageStyle.image = indicator;

      this.dummyDecorationNode.layout = this.getLayout(
        node.layout,
        i,
        indicators.length,
        DiagramUtils.getNodeShape(node),
        node.tag.annotationType
      );
      let visual = this.imageStyle.renderer
        .getVisualCreator(this.dummyDecorationNode, this.imageStyle)
        .createVisual(context) as SvgVisual;

      visualGroup.add(visual);
    }

    // We now need to tell yFiles where to place the circle. This is done with
    visualGroup[JigsawNodeStyleBase.RenderCacheKey] = {
      previousIndicatorsCount: indicators.length,
    };

    return visualGroup;
  }
  updateVisual(
    context: IRenderContext,
    node: INode,
    oldVisual: Visual
  ): Visual {
    let oldVisualGroup = oldVisual as SvgVisualGroup;

    let indicators = this.getNodeIndicators(node)!;
    let renderCache = oldVisualGroup[JigsawNodeStyleBase.RenderCacheKey];

    if (
      !renderCache ||
      renderCache.previousIndicatorsCount != indicators.length
    ) {
      // D'Sync bug: can't return a new SVG group here as this causes yFiles to short circuit
      // Returning null here causes all decorators on the node to be recreated
      return null;
    }

    for (let i = 0; i < indicators.length; i++) {
      let indicator = indicators[i];
      this.imageStyle.image = indicator;
      this.dummyDecorationNode.layout = this.getLayout(
        node.layout,
        i,
        indicators.length,
        DiagramUtils.getNodeShape(node),
        node.tag.annotationType
      );
      let visual = this.imageStyle.renderer
        .getVisualCreator(this.dummyDecorationNode, this.imageStyle)
        .updateVisual(
          context,
          oldVisualGroup.children.elementAt(i)
        ) as SvgVisual;

      oldVisualGroup.children.set(i, visual);
    }

    return oldVisualGroup;
  }

  isHit(context: IInputModeContext, location: Point, node: INode): HitResult {
    // indicators are not clickable
    return HitResult.NONE;
  }

  getNodeIndicators(node: INode): string[] {
    if (!node.tag) {
      return [];
    }
    const state = DecorationStateManager.getState(
      IndicatorDecorators.INSTANCE,
      node
    ) as IndicatorState;

    if (state.indicators && state.indicators.length > 0) {
      return state.indicators ?? [];
    }

    return [];
  }

  getLayout(
    nodeLayout: IRectangle,
    slotIndex: number,
    totalSlots: number,
    shape: ShapeNodeShape,
    annotationType: AnnotationType
  ) {
    const verticalPadding =
      annotationType === AnnotationType.ClipArt ? -17 : this.verticalPadding;

    let size = this.dummyDecorationNode.layout.toSize();
    let x =
      nodeLayout.x +
      nodeLayout.width -
      this.horizonalPadding -
      size.width -
      slotIndex * this.spacing;

    switch (shape) {
      case ShapeNodeShape.ELLIPSE:
        const slotGroupWidth = size.width * totalSlots;
        const slotStartX =
          nodeLayout.x + nodeLayout.width / 2 - slotGroupWidth / 2;
        x = slotStartX + this.spacing * slotIndex;
        break;
      case ShapeNodeShape.TRIANGLE:
        x =
          nodeLayout.x +
          nodeLayout.width -
          20 -
          size.width -
          slotIndex * this.spacing;
        break;
    }

    const y = nodeLayout.y + nodeLayout.height - size.height - verticalPadding;
    return new Rect(x, y, size.width, size.height);
  }

  public defaultState(): IndicatorState {
    return {
      indicators: [],
    };
  }
}
