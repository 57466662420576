import {
  DragDropEffects,
  DragDropItem,
  DragSource,
  GraphEditorInputMode,
  GraphItemTypes,
  IGraph,
  IInputModeContext,
  IModelItem,
  INode,
  ItemDropInputMode,
  Point,
  Rect,
  Size,
} from 'yfiles';
import diagramConfig from '@/core/config/diagram.definition.config';
import { addClass, removeClass } from '@/core/utils/html.utils';
import IPaletteBehaviour from '@/components/DiagramPalette/IPaletteBehaviour';
import DiagramUtils from '@/core/utils/DiagramUtils';
import PaletteItem from '@/components/DiagramPalette/PaletteItem';
import GraphService from '@/core/services/graph/graph.service';
import config from '@/core/config/diagram.definition.config';
import { NodeSize, QuickBuildState } from '@/api/models';
import PaletteItemOffsetHelper from './PaletteItemOffsetHelper';
import PaletteCategory from './PaletteCategory';
import { AnnotationType } from '@/core/common/AnnotationType';

export default class SvgPaletteBehaviour implements IPaletteBehaviour {
  private readonly _dragPreviewZIndex: number;
  private static _instance: SvgPaletteBehaviour = null;

  public static get INSTANCE(): SvgPaletteBehaviour {
    return (
      SvgPaletteBehaviour._instance ??
      (SvgPaletteBehaviour._instance = new SvgPaletteBehaviour())
    );
  }

  constructor() {
    this._dragPreviewZIndex = 10000;
  }

  themeElementDropInputMode: ThemeElementDropInputMode = null;

  insert(event, item, graphService: GraphService) {
    const offsetX = PaletteItemOffsetHelper.getOffsetX();
    const offsetY = PaletteItemOffsetHelper.getOffsetY();

    const itemPositionX =
      graphService.graphComponent.viewport.minX +
      graphService.graphComponent.viewport.width /
        config.offsetRightFromCanvasLeftBound.large +
      offsetX;

    const itemPositionY =
      graphService.graphComponent.viewport.centerY -
      graphService.graphComponent.viewport.height / 4 +
      offsetY;

    this.itemCreator(
      graphService.graphComponent.inputModeContext,
      graphService.graphComponent.graph,
      item,
      null,
      DiagramUtils.snapToNearestGridPoint(
        new Point(itemPositionX, itemPositionY)
      ),
      graphService
    );

    PaletteItemOffsetHelper.updatePalettItemDropOffset(
      graphService.graphComponent
    );
  }

  startDrag(event, item, graphService) {
    const inputMode = graphService.graphComponent
      .inputMode as GraphEditorInputMode;

    if (this.themeElementDropInputMode) {
      inputMode.remove(this.themeElementDropInputMode);
    }

    this.themeElementDropInputMode = new ThemeElementDropInputMode();
    this.themeElementDropInputMode.itemCreator = (
      context,
      graph,
      draggedItem,
      dropTarget,
      dropLocation
    ) =>
      this.itemCreator(
        context,
        graph,
        draggedItem,
        dropTarget,
        dropLocation,
        graphService
      );

    inputMode.add(this.themeElementDropInputMode);

    // We also want to show a preview of dragged node, while the dragging is not within the GraphComponent.
    // For this, we can provide an element that will be placed at the mouse position during the drag gesture.
    // Of course, this should resemble the node that is currently dragged.
    const dragPreview = document.createElement('div');
    dragPreview.setAttribute('id', 'draggable-theme');
    dragPreview.setAttribute('style', `z-index:${this._dragPreviewZIndex}`);

    dragPreview.appendChild(event.target.cloneNode(true));

    let dragSource = new DragSource(dragPreview);

    dragSource.startDrag(
      new DragDropItem(INode.$class.name, item),
      DragDropEffects.ALL,
      true,
      dragPreview
    );

    dragSource.addQueryContinueDragListener((src, args) => {
      if (args.dropTarget === null) {
        removeClass(dragPreview, 'd-none');
      } else {
        addClass(dragPreview, 'd-none');
      }
    });
  }

  private itemCreator(
    context: IInputModeContext,
    graph: IGraph,
    draggedItem: any,
    dropTarget: IModelItem | null,
    dropLocation: Point,
    graphService: GraphService
  ): PaletteItem | null {
    let size: Size = defaultSize;
    let svgDefaultSize = NodeSize.Medium;
    const simpleNode = DiagramUtils.createSimpleImageNode(
      draggedItem.text,
      draggedItem.img,
      calculateSnapWidth(size, svgDefaultSize)
    );

    let layout = new Rect(
      dropLocation?.x ?? 0,
      dropLocation?.y ?? 0,
      size.width,
      size.height
    );
    simpleNode.tag.isAnnotation = true;
    if (draggedItem.category == PaletteCategory.Logos) {
      simpleNode.tag.annotationType = AnnotationType.Logos;
    } else {
      simpleNode.tag.annotationType = AnnotationType.ClipArt;
    }

    let node = graph.createNodeAt(
      layout,
      simpleNode.style.clone(),
      simpleNode.tag
    );

    node.labels.forEach((x) => graph.remove(x));
    // Need to set the initial label content to a blank space for the label layout to size correctly
    DiagramUtils.setLabel(graph, node, ' ');

    const position = DiagramUtils.snapToNearestGridPoint(
      new Point(layout.x - layout.width / 2, layout.y - layout.height / 2)
    );

    const updatedLayout = new Rect(
      position,
      new Size(layout.width, layout.height)
    );

    graph.setNodeLayout(node, updatedLayout);

    if (!node.tag.isAnnotation) {
      graphService.quickBuildService.setQuickBuild(QuickBuildState.Complete);
    }

    if (this.themeElementDropInputMode) {
      (context.canvasComponent.inputMode as GraphEditorInputMode).remove(
        this.themeElementDropInputMode
      );
    }

    return draggedItem;
  }
}

class ThemeElementDropInputMode extends ItemDropInputMode<PaletteItem> {
  constructor() {
    super(INode.$class.name);
  }

  /**
   * @returns {IEdge}
   */
  // @ts-ignore
  get draggedItem() {
    return this.dropData;
  }

  /**
   * @param {Point} dragLocation - The location to return the drop target for.
   * @returns {IModelItem}
   */
  getDropTarget(dragLocation) {
    const parentMode = this.inputModeContext.parentInputMode;
    if (parentMode instanceof GraphEditorInputMode) {
      const hitItems = parentMode.findItems(dragLocation, [
        GraphItemTypes.NODE,
        GraphItemTypes.EDGE,
      ]);
      if (hitItems.size > 0) {
        return hitItems.first();
      }
    }
    return null;
  }

  initializePreview() {
    if (this.showPreview && this.draggedItem) {
      super.initializePreview();
    }
  }

  /**
   * @param {IGraph} previewGraph - The preview graph to fill.
   */
  populatePreviewGraph(previewGraph: IGraph) {
    const graph = previewGraph;

    const item = this.dropData as PaletteItem;

    const simpleNode = DiagramUtils.createSimpleImageNode(
      '',
      item.img,
      calculateSnapWidth(defaultSize, NodeSize.Medium)
    );
    const node = graph.createNode(
      simpleNode.layout.toRect(),
      simpleNode.style,
      simpleNode.tag
    );

    DiagramUtils.setLabel(graph, node);
  }

  /**
   * @param {IGraph} previewGraph - The preview graph to update.
   * @param {Point} dragLocation - The current drag location.
   */
  updatePreview(previewGraph: IGraph, dragLocation: Point) {
    previewGraph.setNodeCenter(
      previewGraph.nodes.elementAt(0),
      DiagramUtils.snapToNearestGridPoint(dragLocation)
    );
    if (this.inputModeContext.canvasComponent !== null) {
      this.inputModeContext.canvasComponent.invalidate();
    }
  }
}
const defaultSize = new Size(72, 72);
function calculateSnapWidth(
  size: Size,
  svgDefaultSize: NodeSize = NodeSize.Medium
): Size {
  const min = diagramConfig.svgElementFactors[svgDefaultSize]; //Math.min(floor, 6);
  const width = diagramConfig.grid.size * min;
  const height = (size.height / size.width) * width;
  return new Size(width, height);
}
