




















import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'UserStacks',
})
export default class Buttons extends Vue {
  userList = [
    { name: 'Bob', surname: 'You' },
    { name: 'Diederik', surname: 'Nerea' },
    { name: 'Zhanna', surname: 'Odell' },
    { name: 'Lauryn', surname: 'Suz' },
    { name: 'Ashlynn', surname: 'Amberly' },
    { name: 'Velvet', surname: 'Paget' },
  ];

  userListStrings = [
    'Lorainne Marigold',
    'Bertha Kacie',
    'Clarence Polly',
    'Knute Zola',
    'Jakki Amberlynn',
    'Andrew',
    'Colton Yolonda',
    'Penny Beryl',
  ];
}
