import IPoint from './IPoint';
import Rect from './Rect';

export default class Point implements IPoint {
  constructor(public x: number = 0, public y: number = 0) {}

  public constrainTo(rect: Rect): Point {
    let x = this.x;
    let y = this.y;

    if (this.x > rect.maxX) {
      x = rect.maxX;
    }
    if (this.y > rect.maxY) {
      y = rect.maxY;
    }
    return new Point(x, y);
  }

  public subtract(point: Point): Point {
    return new Point(this.x - point.x, this.y - point.y);
  }

  public add(point: Point): Point {
    return new Point(this.x + point.x, this.y + point.y);
  }

  multiply(factor: number) {
    return new Point(this.x * factor, this.y * factor);
  }
  divide(factor: number) {
    return new Point(this.x / factor, this.y / factor);
  }
}
