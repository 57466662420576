import {
  GraphComponent,
  GraphModelManager,
  HierarchicNestingPolicy,
  ICanvasObjectGroup,
  IEdge,
  IEnumerable,
  IEnumerableConvertible,
  ILabel,
  IModelItem,
  INode,
  LabelLayerPolicy,
} from 'yfiles';

export default class JigsawGraphModelManager extends GraphModelManager {
  private readonly placeHolderEdgeGroup: ICanvasObjectGroup;
  private readonly annotationEdgeGroup: ICanvasObjectGroup;
  private readonly defaultGroupNodeGroup: ICanvasObjectGroup;
  private readonly defaultEdgeLabelGroup: ICanvasObjectGroup;
  private readonly annotationNodeGroup: ICanvasObjectGroup;
  private readonly annotationNodeLabelGroup: ICanvasObjectGroup;
  private readonly frontGroup: ICanvasObjectGroup;
  private readonly backGroup: ICanvasObjectGroup;

  private lowestDisplayOrder = -100000;
  private highestDisplayOrder = 100000;

  constructor(
    graphComponent: GraphComponent,
    contentGroup: ICanvasObjectGroup = graphComponent.contentGroup,
    labelLayerPolicy: LabelLayerPolicy = LabelLayerPolicy.AT_OWNER
  ) {
    super(graphComponent, contentGroup);

    //https://docs.yworks.com/yfiles-html/dguide/customizing_view/customizing_view-z_order.html#tab_effect-LabelLayerPolicy
    this.labelLayerPolicy = labelLayerPolicy;

    // https://docs.yworks.com/yfiles-html/dguide/customizing_view/customizing_view-z_order.html#customizing_view-z_order_canvasobject
    this.hierarchicNestingPolicy = HierarchicNestingPolicy.NONE;

    this.placeHolderEdgeGroup = contentGroup.addGroup();
    this.placeHolderEdgeGroup.above(this.nodeGroup);
    this.annotationEdgeGroup = contentGroup.addGroup();
    this.annotationEdgeGroup.above(this.nodeGroup);
    this.defaultGroupNodeGroup = contentGroup.addGroup();
    this.defaultGroupNodeGroup.toBack();
    this.defaultEdgeLabelGroup = contentGroup.addGroup();
    this.defaultEdgeLabelGroup.toFront();
    this.annotationNodeGroup = contentGroup.addGroup();
    this.annotationNodeGroup.toFront();
    this.annotationNodeLabelGroup = contentGroup.addGroup();
    this.annotationNodeLabelGroup.toFront();

    this.edgeLabelGroup.below(this.nodeGroup);
    this.edgeGroup.below(this.nodeGroup);
    this.nodeLabelGroup.toFront();

    this.frontGroup = contentGroup.addGroup();
    this.frontGroup.toFront();
    this.backGroup = contentGroup.addGroup();
    this.backGroup.toBack();
  }

  toFront(
    items:
      | IModelItem
      | IEnumerable<IModelItem>
      | IEnumerableConvertible<IModelItem>
  ): void {
    if (IModelItem.isInstance(items)) {
      items = [items];
    }

    for (let item of [...(<IModelItem[]>items)]) {
      if (INode.isInstance(item)) {
        item.tag.displayOrder = this.getCurrentHighestDisplayOrder() + 1;
        const obj = this.getCanvasObject(item);
        obj.group = this.frontGroup;
        this.update(item);
        obj.toFront();
        item.labels.forEach((l) => {
          this.update(l);
          this.getCanvasObject(l).toFront();
        });
      } else {
        super.toFront(item);
      }
    }

    this.graph.invalidateDisplays();
  }

  toBack(
    items:
      | IModelItem
      | IEnumerable<IModelItem>
      | IEnumerableConvertible<IModelItem>
  ): void {
    if (IModelItem.isInstance(items)) {
      items = [items];
    }

    for (let item of [...(<IModelItem[]>items)]) {
      if (INode.isInstance(item)) {
        item.tag.displayOrder = this.getCurrentLowestDisplayOrder() - 1;
        const obj = this.getCanvasObject(item);
        obj.group = this.backGroup;
        this.update(item);
        obj.toBack();
        item.labels.forEach((l) => {
          this.update(l);
        });
      } else {
        super.toBack(item);
      }
    }

    this.graph.invalidateDisplays();
  }

  getCurrentLowestDisplayOrder() {
    let lowest = this.lowestDisplayOrder;
    this.backGroup.forEach((i) => {
      const displayOrder = i.userObject?.tag?.displayOrder;
      if (displayOrder < lowest) {
        lowest = displayOrder;
      }
    });
    return lowest;
  }

  getCurrentHighestDisplayOrder() {
    let highest = this.highestDisplayOrder;
    this.frontGroup.forEach((i) => {
      const displayOrder = i.userObject?.tag?.displayOrder;
      if (displayOrder > highest) {
        highest = displayOrder;
      }
    });
    return highest;
  }

  getEdgeCanvasObjectGroup(edge: IEdge): ICanvasObjectGroup {
    if (edge.tag && edge.tag.placeholder) {
      return this.placeHolderEdgeGroup;
    }

    if (edge.tag && edge.tag.isAnnotation) {
      return this.annotationEdgeGroup;
    }

    return super.getEdgeCanvasObjectGroup(edge);
  }

  getNodeCanvasObjectGroup(node: INode): ICanvasObjectGroup {
    if (node.tag && node.tag.displayOrder < this.lowestDisplayOrder) {
      return this.backGroup;
    } else if (node.tag && node.tag.displayOrder > this.highestDisplayOrder) {
      return this.frontGroup;
    }

    if (node.tag && node.tag.isAnnotation) {
      return this.annotationNodeGroup;
    }

    if (node.tag && node.tag.isGroupNode) {
      return this.defaultGroupNodeGroup;
    }

    return super.getNodeCanvasObjectGroup(node);
  }

  getLabelCanvasObjectGroup(label: ILabel): ICanvasObjectGroup {
    if (
      label.owner.tag &&
      label.owner.tag.displayOrder < this.lowestDisplayOrder
    ) {
      return this.backGroup;
    } else if (
      label.owner.tag &&
      label.owner.tag.displayOrder > this.highestDisplayOrder
    ) {
      return this.frontGroup;
    }

    if (label.owner.tag && label.owner.tag.isAnnotation) {
      return this.annotationNodeLabelGroup;
    }

    if (IEdge.isInstance(label.owner)) {
      return this.defaultEdgeLabelGroup;
    }

    return super.getLabelCanvasObjectGroup(label);
  }

  compare(item1: IModelItem, item2: IModelItem): number {
    const item1Order = item1.tag?.displayOrder ?? 0;
    const item2Order = item2.tag?.displayOrder ?? 0;
    if (item1Order != 0 || item2Order != 0) {
      return item1Order > item2Order ? -1 : 1;
    } else {
      return super.compare(item1, item2);
    }
  }

  getNextAvailableZIndex() {
    let highest = 0;
    if (this.graph.nodes.size == 1) {
      return highest;
    }
    this.graph.nodes.forEach((i) => {
      const displayOrd = i?.tag?.displayOrder;
      if (displayOrd > highest) {
        highest = displayOrd;
      }
    });

    return highest + 1;
  }
}
