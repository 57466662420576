import axios, {
    AxiosResponse
} from 'axios';
import {
    GetCurrentLoginInformationsOutput,
    ZadarResponse,
    UpdateUserSignInTokenOutput
} from './models'
export default class SessionApiService {
    public static getCurrentLoginInformations(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetCurrentLoginInformationsOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Session/GetCurrentLoginInformations`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static updateUserSignInToken(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < UpdateUserSignInTokenOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Session/UpdateUserSignInToken`,
            method: 'put',
            clientConfig: clientConfig
        })
    }
}