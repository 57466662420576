import ISpecification from './ISpecification';

export default class NotSpecification implements ISpecification {
  private condition: ISpecification;

  constructor(condition: ISpecification) {
    this.condition = condition;
  }

  public isSatisfiedBy(candidate: any): boolean {
    return !this.condition.isSatisfiedBy(candidate);
  }
}
