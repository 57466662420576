import DataPropertyDefinitionsApiService from '@/api/DataPropertyDefinitionsApiService';
import { DataPropertyDefinitionDto } from '@/api/models';
import { Module } from 'vuex';
// action types
export const SET_DATAPROPERTYDEFINITIONS = 'setDataPropertyDefinitions';
export const LOAD_DATAPROPERTYDEFINITIONS = 'loadDataPropertyDefinitions';
export const LOAD_DATAPROPERTYDEFINITIONSFROMTHEMES =
  'loadDataPropertyDefinitionsFromThemes';
export const LOAD_DATAPROPERTYDEFINITIONS_BY_DOCUMENT_ID =
  'loadDataPropertyDefinitionsByDocumentId';
export const DATAPROPERTYDEFINITIONS_NAMESPACE = 'dataPropertyDefinitions';
export const GET_DATAPROPERTYDEFINITIONS = 'getDataPropertyDefinitions';
export const ADD_DATAPROPERTYDEFINITION = 'addDataPropertyDefinition';
export const DELETE_DATAPROPERTYDEFINITION = 'deleteDataPropertyDefinition';

interface State {
  definitions: DataPropertyDefinitionDto[];
}

const datapropertydefinitionsModule: Module<State, any> = {
  namespaced: true,
  state: {
    definitions: [],
  },
  getters: {
    [GET_DATAPROPERTYDEFINITIONS](state) {
      return state.definitions;
    },
  },
  actions: {
    async [LOAD_DATAPROPERTYDEFINITIONSFROMTHEMES](context) {
      const response =
        await DataPropertyDefinitionsApiService.getAllForThemes();
      context.commit(SET_DATAPROPERTYDEFINITIONS, response.data.result);
      return response.data.result;
    },
    async [LOAD_DATAPROPERTYDEFINITIONS](context) {
      const response = await DataPropertyDefinitionsApiService.getAll();
      context.commit(SET_DATAPROPERTYDEFINITIONS, response.data.result);
      return response.data.result;
    },
    async [LOAD_DATAPROPERTYDEFINITIONS_BY_DOCUMENT_ID](context, payload) {
      const response =
        await DataPropertyDefinitionsApiService.getDataPropertyDefinitionsByDocumentId(
          { id: payload }
        );
      context.commit(SET_DATAPROPERTYDEFINITIONS, response.data.result);
      return response.data.result;
    },
    [SET_DATAPROPERTYDEFINITIONS](context, payload) {
      context.commit(SET_DATAPROPERTYDEFINITIONS, payload);
    },
    [ADD_DATAPROPERTYDEFINITION](context, payload) {
      context.commit(ADD_DATAPROPERTYDEFINITION, payload);
    },
    [DELETE_DATAPROPERTYDEFINITION](context, payload) {
      return DataPropertyDefinitionsApiService.delete({ id: payload.id })
        .then((response) => {
          context.commit(DELETE_DATAPROPERTYDEFINITION, payload);
        })
        .catch((err) => {
          throw err;
        });
    },
  },
  mutations: {
    [SET_DATAPROPERTYDEFINITIONS](state, payload) {
      state.definitions = payload;
    },
    [ADD_DATAPROPERTYDEFINITION](state, payload) {
      state.definitions.push(payload);
    },
    [DELETE_DATAPROPERTYDEFINITION](state, payload) {
      state.definitions = state.definitions.filter(
        (definition) => definition.id !== payload.id
      );
    },
  },
};
export default datapropertydefinitionsModule;
