import { triggerThreadSafeValidation } from 'vee-validate/dist/types/components/common';
import {
  SimpleNode,
  ImageNodeStyle,
  Size,
  INode,
  Rect,
  Point,
  IInputModeContext,
  IRenderContext,
  Visual,
  SvgVisual,
} from 'yfiles';
import HitResult, { HitResultLocation } from '../HitResult';
import JigsawNodeDecorator from './JigsawNodeDecorator';

/**
 * A generic button indicated, should not really be used as a stand alone button, see @type QuickBuildButtonsNodeDecorator
 * on how to use
 */
export default class ImageButtonNodeDecorator implements JigsawNodeDecorator {
  public $class = 'ImageButtonNodeDecorator';
  public dummyDecorationNode: SimpleNode;
  public imageStyle: ImageNodeStyle;
  public size: Size;
  public imageSrc: string;
  public fallbackImageSrc: string;
  public getLayout: (node: INode) => Rect;

  constructor(options: {
    imageSrc: string;
    fallbackImageSrc?: string;
    size?: Size;
    getLayout?: (node: INode) => Rect;
  }) {
    /* copy options */
    this.size = options.size ?? new Size(22, 22);
    this.imageSrc = options.imageSrc;
    this.fallbackImageSrc = options.fallbackImageSrc;
    this.getLayout =
      options.getLayout ??
      ((node) => {
        let half = this.size.multiply(0.5);
        return new Rect(
          node.layout.center.subtract(new Point(half.width, half.height)),
          this.size
        );
      });
    /*setup */
    // dummy node for rendering
    this.dummyDecorationNode = new SimpleNode();
    // image style for rendering
    this.imageStyle = new ImageNodeStyle();
    // set default dummy node layout
    this.dummyDecorationNode.layout = new Rect(new Point(0, 0), this.size);
    // set image style src
    if (this.imageSrc) {
      this.imageStyle.image = this.imageSrc;
    }

    if (this.fallbackImageSrc) {
      this.imageStyle.fallbackImage = this.fallbackImageSrc;
    }
  }
  isVisible(renderContext: IRenderContext, node: INode): boolean {
    return true;
  }
  isHit(context: IInputModeContext, location: Point, node: INode): HitResult {
    // get the layout (position) of the decorator for the given node
    let layout = this.getLayout(node);
    if (layout.contains(location)) {
      return new HitResult({
        hitLocation: HitResultLocation.DECORATOR,
        isHit: true,
        meta: null,
        decoratorType: this.$class,
      });
    }
    return HitResult.NONE;
  }

  createVisual(context: IRenderContext, node: INode): Visual {
    this.dummyDecorationNode.layout = this.getLayout(node);
    if (this.imageStyle.image != this.imageSrc) {
      this.imageStyle.image = this.imageSrc;
    }
    if (this.imageStyle.fallbackImage != this.fallbackImageSrc) {
      this.imageStyle.fallbackImage = this.fallbackImageSrc;
    }
    const decorationRenderer = this.imageStyle.renderer.getVisualCreator(
      this.dummyDecorationNode,
      this.imageStyle
    );
    let visual = decorationRenderer.createVisual(context) as SvgVisual;

    return visual;
  }

  updateVisual(
    context: IRenderContext,
    node: INode,
    oldVisual: Visual
  ): Visual {
    this.dummyDecorationNode.layout = this.getLayout(node);

    if (this.imageStyle.image != this.imageSrc) {
      this.imageStyle.image = this.imageSrc;
    }
    if (this.imageStyle.fallbackImage != this.fallbackImageSrc) {
      this.imageStyle.fallbackImage = this.fallbackImageSrc;
    }
    const decorationRenderer = this.imageStyle.renderer.getVisualCreator(
      this.dummyDecorationNode,
      this.imageStyle
    );
    return decorationRenderer.updateVisual(context, oldVisual) as SvgVisual;
  }
}
