

















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import chroma from 'chroma-js';
import colorjoe from 'colorjoe';
import 'colorjoe/css/colorjoe.css';
import { generateUuid } from '@/core/utils/common.utils';
import IColorPickerPalette from '@/core/services/graph/IColorPickerPalette';
import { EmptyColor, TransparentColor } from './ColorPickerConsts';

@Component
export default class ColorPicker extends Vue {
  @Prop({ default: false })
  readonly: boolean;
  @Prop()
  public palettes!: IColorPickerPalette[];
  @Prop()
  public initialColor!: string | null;
  @Prop()
  public changeOnMouseLeave: boolean | null;
  @Prop()
  public displayInline: boolean | null;
  @Prop()
  public enableTransparentColor: boolean | null;
  @Prop({ default: () => [1.66, 1.33, 1, 0.75, 0.5] })
  public lightnessGrades: number[];
  private palettesInternal: ColorPickerPalette[] = [];
  private selectedColor: string | null = null;
  private showAdvancedPicker = false;
  private maxPaletteColors = 10;
  private colorjoeId = generateUuid();
  private colorjoe: any;
  private ignoreColorjoeEvents: boolean = false;
  private transparentColor = TransparentColor;

  mounted() {
    this.preparePalettes();

    const element = document.getElementById(this.colorjoeId);
    if (element) {
      this.colorjoe = colorjoe.rgb(element, this.initialColor, [
        ['fields', { space: 'RGB', limit: 255, fix: 0 }],
        'hex',
        'text',
      ]);

      // Required to show HEX code without '#' symbol so remove it manually
      // Must be called after 'Colorjoe' object creation
      this.formatHexBoxOutputText();

      this.colorjoe.on('change', (color: any) => {
        if (this.ignoreColorjoeEvents) {
          return;
        }
        if (color.hex() != this.selectedColor) {
          this.updateSelectedColor(null, color.hex());
        }
      });
    }
  }

  closeColorPicker() {
    this.showAdvancedPicker = false;
    this.palettesInternal.forEach((p) => {
      p.isExpanded = false;
    });
  }

  mouseLeave(ev) {
    if (this.changeOnMouseLeave) {
      this.raiseChangeEvent(null);
    }
  }

  togglePaletteShades(palette: ColorPickerPalette) {
    palette.shades = [];
    palette.isExpanded = !palette.isExpanded;

    this.showAdvancedPicker = false;

    this.palettesInternal.forEach((p) => {
      if (p != palette) {
        p.isExpanded = false;
      }
    });

    if (palette.isExpanded) {
      this.lightnessGrades.forEach((l) => {
        palette.colors.forEach((color) => {
          if (color != EmptyColor) {
            const oldL = chroma(color).get('hsl.l');
            const newL = (1 - (oldL - 0.5)) * l;
            const newColor = chroma(color).set('hsl.l', `*${newL}`).toString();
            palette.shades.push(newColor);
          } else {
            palette.shades.push(EmptyColor);
          }
        });
      });
    }
  }

  toggleAdvancedPicker() {
    this.showAdvancedPicker = !this.showAdvancedPicker;
    this.palettesInternal.forEach((p) => {
      p.isExpanded = false;
    });
  }

  toggleTransparentColor() {
    this.selectColor(null, TransparentColor);
  }

  updateSelectedColor(palette: IColorPickerPalette, color: string) {
    this.deselectAll();
    this.selectedColor = color;
    if (!this.changeOnMouseLeave) {
      this.raiseChangeEvent(palette);
    }
  }

  raiseChangeEvent(palette: IColorPickerPalette) {
    if (this.selectedColor != EmptyColor) {
      this.$emit('change', {
        palette: palette,
        color: this.selectedColor,
      });
    }
  }

  selectColor(palette: ColorPickerPalette, color: string) {
    if (this.readonly) return;
    if (color == EmptyColor) return;
    this.updateSelectedColor(palette, color);
    this.ignoreColorjoeEvents = true;
    try {
      this.colorjoe.set(color);
    } catch {}
    this.ignoreColorjoeEvents = false;
    if (palette) {
      palette.selectedColor = color;
    }
  }

  deselectAll() {
    this.palettesInternal.forEach((palette) => {
      palette.selectedColor = null;
    });
  }

  @Watch('initialColor')
  initialColorChanged(): void {
    this.selectedColor = this.initialColor;

    this.palettesInternal.forEach((palette) => {
      if (this.initialColor && this.initialColor != EmptyColor) {
        palette.selectedColor = this.selectedColor;
      }
    });

    if (this.initialColor && this.initialColor != EmptyColor) {
      this.colorjoe.set(this.initialColor);
    }
  }

  @Watch('palettes')
  preparePalettes() {
    this.palettesInternal = [];
    if (this.palettes) {
      this.palettes.forEach((p) => {
        let palette = new ColorPickerPalette(p.name, p.colors);
        palette.colors = palette.colors.filter(
          (c, index) => c != EmptyColor && palette.colors.indexOf(c) === index
        ); // remove duplicates and empty colors

        if (palette.colors.length > this.maxPaletteColors) {
          palette.colors = palette.colors.slice(0, this.maxPaletteColors);
        } else if (palette.colors.length < this.maxPaletteColors) {
          for (let i = palette.colors.length; i < this.maxPaletteColors; i++) {
            palette.colors.push(EmptyColor);
          }
        }

        if (this.initialColor && this.initialColor != EmptyColor) {
          palette.selectedColor = this.initialColor;
        } else {
          palette.selectedColor = null;
        }
        this.palettesInternal.push(palette);
      });
    }
    this.selectedColor = this.initialColor;
  }

  formatHexBoxOutputText(): void {
    let elements = (
      this.$refs.advancedColorPicker as any
    ).getElementsByClassName('hex');
    for (let element of elements) {
      for (let i = 0; i < element.children.length; ++i) {
        if (
          element.children[i].id &&
          element.children[i].id.startsWith('colorPickerInput')
        ) {
          if (
            element.children[i].value &&
            element.children[i].value.startsWith('#')
          ) {
            element.children[i].value = element.children[i].value.slice(1);
          }
        }
      }
    }
  }

  colorsEqual(colorA: string, colorB: string): boolean {
    if (colorA && colorB) {
      return colorA.toLowerCase() === colorB.toLowerCase();
    }
    return false;
  }
}

class ColorPickerPalette implements IColorPickerPalette {
  name = 'Palette';
  colors: string[] = [];
  shades: string[] = [];
  selectedColor: string | null = null;
  isExpanded = false;

  constructor(name: string, colors: string[]) {
    this.name = name;
    this.colors = colors;
  }
}
