import { DocumentDto, DocumentPageDto } from '@/api/models';
import { calculateHash } from '@/core/utils/common.utils';

export default class DocumentHashHelper {
  private static readonly hashPrefix = 'hash';

  public static async storeCurrentDocumentHash(
    document: DocumentDto
  ): Promise<string> {
    const { pages, lastModificationTime, ...rest } = document;
    const hash = await calculateHash(rest);
    await this.storeDocumentHash(document.id, hash);
    return hash;
  }

  public static async storeCurrentPageHash(
    page: DocumentPageDto
  ): Promise<string> {
    if (page.diagram) {
      page.diagram.thumb = null;
    }
    const hash = await calculateHash(page);
    await this.storePageHash(page.id, hash);
    return hash;
  }

  public static async getCurrentDocumentHash(
    document: DocumentDto
  ): Promise<string> {
    const { pages, lastModificationTime, ...rest } = document;
    return await calculateHash(rest);
  }

  public static async getCurrentPageHash(
    page: DocumentPageDto
  ): Promise<string> {
    if (page.diagram) {
      page.diagram.thumb = null;
    }
    return await calculateHash(page);
  }

  public static getStoredDocumentHash(documentId: number): string {
    const key = this.getDocumentHashKey(documentId);
    return sessionStorage.getItem(key);
  }

  public static storeDocumentHash(documentId: number, hash: string) {
    const key = this.getDocumentHashKey(documentId);
    sessionStorage.setItem(key, hash);
  }

  public static getStoredPageHash(pageId: number) {
    const key = this.getPageHashKey(pageId);
    return sessionStorage.getItem(key);
  }

  public static storePageHash(pageId: number, hash: string) {
    const key = this.getPageHashKey(pageId);
    sessionStorage.setItem(key, hash);
  }

  public static getDocumentHashKey(documentId: number): string {
    return `${this.hashPrefix}.document.${documentId}`;
  }

  public static getPageHashKey(pageId: number): string {
    return `${this.hashPrefix}.page.${pageId}`;
  }

  public static clearAllHashes() {
    [...Object.keys(sessionStorage)].forEach((key) => {
      if (key.startsWith(this.hashPrefix)) {
        sessionStorage.removeItem(key);
      }
    });
  }
}
