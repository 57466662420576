import ISpecification from './ISpecification';

export default class AndSpecification implements ISpecification {
  private conditions: ISpecification[];

  constructor(conditions: ISpecification[]) {
    this.conditions = conditions;
  }

  public isSatisfiedBy(candidate: any): boolean {
    return this.conditions.every((c) => c.isSatisfiedBy(candidate));
  }
}
