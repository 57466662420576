
















import { Vue, Component } from 'vue-property-decorator';

const iconsJSON = require('../../assets/icon-font/selection.json');

@Component({
  name: 'Icons',
})
export default class Icons extends Vue {
  icons = [];
  prefix = 'icon-';

  created() {
    const {
      preferences: {
        fontPref: { prefix },
      },
      icons,
    } = iconsJSON;

    this.prefix = prefix;
    this.icons = icons;
  }
}
