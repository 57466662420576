

























































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import { LogoutConfirmationConfig } from '@/core/config/LogoutConfirmationConfig';
import DocumentService from '@/core/services/document/DocumentService';

import UserLanguageMixin from '@/mixins/UserLanguage.mixin';

import AppVersion from '@/view/layout/AppVersion.vue';
import AppUpdatePasswordModal from '@/view/modals/AppUpdatePasswordModal.vue';

import SidebarProfile from './SidebarProfile.vue';

@Component({
  name: 'SidebarProfileDropdown',
  components: {
    AppVersion,
    SidebarProfile,
    AppUpdatePasswordModal
  }
})
export default class AppSidebar extends mixins(
  UserLanguageMixin
) {

  isChangePasswordModalVisible: boolean = false;

  changePassword () {
    this.isChangePasswordModalVisible = true;
  }

  onLogoutHandler() {
    this.$confirm({
      title: this.$t(LogoutConfirmationConfig.config.title).toString(),
      text: this.$t(LogoutConfirmationConfig.content).toString(),
      okText: this.$t(LogoutConfirmationConfig.config.okTitle),
      denyText: this.$t(LogoutConfirmationConfig.config.cancelTitle),
    }).then(() => {
      DocumentService.closeDocument().finally(() => {
        this.$router.push({
          name: 'logout',
        });
      });
    });
  }
}
