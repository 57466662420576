


















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

type TTabs = {
  name: string;
  icon: null | string;
  marked: boolean;
  disabled: boolean;
};

@Component({
  name: 'AppTabs',
})
export default class AppTabs extends Vue {
  @Prop({ required: false })
  value: string;

  @Prop({ default: null })
  defaultTab: string;

  activeTab: null | string = null;
  tabs: TTabs[] = [];

  @Watch('value')
  handleActiveTab(newVal) {
    if (newVal) {
      this.activeTab = newVal;
    }
  }

  selectTab(tab) {
    this.activeTab = tab;
    this.$emit('input', this.activeTab);
    this.$emit('change', this.activeTab);
  }

  getTabs() {
    if (this.$slots.default) {
      console.debug('AppTabs $slots.default: ', this.$slots);
      const tabs = this.$slots.default
        .filter(
          (vnode) =>
            vnode.tag &&
            (vnode.componentOptions?.Ctor as any).options.name === 'AppTabItem'
        )
        .map(({ componentOptions }) => {
          const { name, icon, marked, disabled } =
            componentOptions.propsData as TTabs;

          return {
            name,
            icon,
            marked: marked !== undefined,
            disabled: disabled !== undefined,
          };
        });

      this.tabs = tabs;

      if (this.defaultTab) {
        this.activeTab = this.defaultTab;
      }

      if (tabs.length && !this.defaultTab) {
        this.activeTab = tabs[0].name;
        this.$emit('input', this.activeTab);
      }
    } else if (this.tabs.length !== 0) {
      this.tabs = [];
    }
  }

  created() {
    this.getTabs();
  }
}
