import moment from 'moment';
moment.updateLocale('fr-ca', {
  longDateFormat: {
    LT: 'h:mm A',
    LTS: 'h:mm:ss A',
    L: 'MM/DD/YYYY',
    LL: 'MMMM Do YYYY',
    LLL: 'MMMM Do YYYY LT',
    LLLL: 'dddd, MMMM Do YYYY LT',
  },
});

moment.updateLocale('en-us', {
  longDateFormat: {
    LT: 'h:mm A',
    LTS: 'h:mm:ss A',
    L: 'MM/DD/YYYY',
    LL: 'MMMM Do YYYY',
    LLL: 'MMMM Do YYYY LT',
    LLLL: 'dddd, MMMM Do YYYY LT',
  },
});

moment.updateLocale('en-gb', {
  longDateFormat: {
    LT: 'h:mm A',
    LTS: 'h:mm:ss A',
    L: 'DD/MM/YYYY',
    LL: 'MMMM Do YYYY',
    LLL: 'MMMM Do YYYY LT',
    LLLL: 'dddd, MMMM Do YYYY LT',
  },
});
export default class MomentLocalization {
  public static setMomentLocaleAndFormat(lang: string) {
    lang = lang.toLowerCase();
    moment.locale(lang);
  }
}
