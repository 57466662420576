import {
  CreateOrEditThemeElementDto,
  EdgeStyleDto,
  ElementType,
  NodeVisualType,
  ThemeElementDto,
  INodeStyleDto,
  ImageNodeStyleDto,
  LabelStyleDto,
} from '@/api/models';
import {
  GraphComponent,
  IEdge,
  IEdgeStyle,
  ILabelStyle,
  INode,
  INodeStyle,
  Insets,
  Point,
  Rect,
  ShapeNodeStyle,
  Size,
  SvgExport,
} from 'yfiles';
import config from '../config/diagram.definition.config';
import JigsawNodeStyle from '../styles/JigsawNodeStyle';
import DiagramUtils from './DiagramUtils';
import StyleCreator from './StyleCreator';
import DiagramWriter from '@/core/services/graph/serialization/diagram-writer.service';
import JigsawInteriorNodeLabelModel from '@/core/services/graph/label-models/JigsawInteriorNodeLabelModel';
import CKEditorUtils from './CKEditorUtils';

export default class ElementSvgRenderUtils {
  public static createSvgFromThemeElement(
    themeElement: ThemeElementDto | CreateOrEditThemeElementDto,
    labelText?: string
  ): Promise<string> {
    switch (themeElement.elementType) {
      case ElementType.Node:
        return ElementSvgRenderUtils.createSvgFromNodeStyle(
          themeElement.style,
          null,
          labelText
        );
      case ElementType.Edge:
        return ElementSvgRenderUtils.createSvgFromEdgeStyle(
          themeElement.style,
          themeElement.behaviourId
        );
    }

    throw `Unsupported element type ${themeElement?.elementType}`;
  }

  public static createSvgFromGraphNodeStyle(
    nodeStyle: INodeStyle,
    width?: number,
    height?: number,
    padding?: number,
    includeLabel?: boolean,
    labelStyle?: LabelStyleDto
  ): string {
    if (!width) {
      width = 30;
    }
    if (!height) {
      height = 30;
    }
    if (!padding && padding !== 0) {
      padding = 10;
    }
    if (nodeStyle instanceof JigsawNodeStyle) {
      // we don't want to render the top level node style, this will include all the indicators
      // take the base style
      nodeStyle = nodeStyle.baseStyle;
    }

    // another GraphComponent is utilized to export a visual of the given style
    const exportComponent = new GraphComponent();
    const exportGraph = exportComponent.graph;
    // we create a node in this GraphComponent that should be exported as SVG
    let node: INode = exportGraph.createNode(
      new Rect(0, 0, width, height),
      nodeStyle
    );
    if (includeLabel) {
      const labelText = CKEditorUtils.createHtmlStringFromStyle(
        labelStyle?.fill,
        labelStyle?.font,
        'Label ABC'
      );
      exportGraph.addLabel(
        node,
        labelText,
        JigsawInteriorNodeLabelModel.CENTER,
        StyleCreator.createLabelStyle()
      );
    }
    exportComponent.updateContentRect(new Insets(padding));
    // the SvgExport can export the content of any GraphComponent
    const svgExport = new SvgExport(exportComponent.contentRect);
    const svg = svgExport.exportSvg(exportComponent);
    this.replaceUsesWithDefs(svg);
    const svgString = SvgExport.exportSvgString(svg);
    return SvgExport.encodeSvgDataUrl(svgString);
  }

  public static createSvgFromNodeStyle(
    nodeStyle: INodeStyleDto,
    width?: number,
    labelText?: string
  ): Promise<string> {
    if (!width) {
      width = 60;
    }

    const includeLabel = !!labelText;

    return new Promise<string>((resolve) => {
      let style = StyleCreator.createNodeStyle(nodeStyle, []);
      let calculcatedHeight;
      let size;
      if (nodeStyle.visualType == NodeVisualType.Image) {
        // images need to be scaled correctly, to maintain aspect ratio.
        // this now has to be promise based.
        let img = new Image();
        img.onload = () => {
          var scale, newWidth, newHeight;
          let imgWidth = img.width;
          let imgHeight = img.height;
          if (imgWidth < width) {
            scale = width / imgWidth;
          } else {
            scale = width / imgHeight;
          }
          newWidth = imgWidth * scale;
          newHeight = imgHeight * scale;
          resolve(this.createSvgFromGraphNodeStyle(style, newWidth, newHeight));
        };
        img.src = (nodeStyle as ImageNodeStyleDto).imageUrl;
      } else {
        // if the node has a fixed with/height we need to display the preview correctly.
        // for example, an oval.
        size = DiagramUtils.getNodeSize(nodeStyle);
        if (size.width != size.height) {
          calculcatedHeight = width * 0.5;
        } else {
          calculcatedHeight = width;
        }

        resolve(
          this.createSvgFromGraphNodeStyle(
            style,
            width,
            calculcatedHeight,
            null,
            includeLabel,
            (nodeStyle as any).labelStyle
          )
        );
      }
    });
  }

  public static createSvgFromGraphEdgeStyle(
    edgeStyle: IEdgeStyle,
    includeLabel?: boolean,
    labelStyle?: LabelStyleDto,
    length?: number,
    addBend?: boolean,
    padding?: number
  ) {
    if (!length && length !== 0) {
      length = 30;
    }
    if (!padding && padding !== 0) {
      padding = 20;
    }

    const exportComponent = new GraphComponent();
    const exportGraph = exportComponent.graph;
    const n1 = exportGraph.createNode(
      new Rect(0, 0, 1, 0),
      new ShapeNodeStyle()
    );
    const n2 = exportGraph.createNode(
      new Rect(length, 30, 1, 0),
      new ShapeNodeStyle()
    );

    const edge = exportGraph.createEdge(n1, n2, edgeStyle);

    if (addBend) {
      exportGraph.addBend(edge, new Point(0, 30));
    }

    if (includeLabel) {
      const labelText = CKEditorUtils.createHtmlStringFromStyle(
        labelStyle?.fill,
        labelStyle?.font,
        'Label ABC'
      );
      exportGraph.addLabel(
        edge,
        labelText,
        DiagramUtils.getLabelModelParameter(edge),
        StyleCreator.createLabelStyle()
      );
    }

    exportComponent.updateContentRect(new Insets(padding));
    const svgExport = new SvgExport(exportComponent.contentRect);
    const svg = svgExport.exportSvg(exportComponent);
    this.replaceUsesWithDefs(svg);
    const svgString = SvgExport.exportSvgString(svg);
    return SvgExport.encodeSvgDataUrl(svgString);
  }

  public static createSvgFromEdgeStyle(
    edgeStyle: EdgeStyleDto,
    behaviourId: number,
    length?: number,
    includeLabel?: boolean
  ): Promise<string> {
    let style = StyleCreator.createEdgeStyle(edgeStyle);
    let addBend = behaviourId !== 1;
    let ret = this.createSvgFromGraphEdgeStyle(
      style,
      includeLabel,
      edgeStyle.labelStyle,
      length,
      addBend
    );
    return Promise.resolve<string>(ret);
  }

  public static createIconFromNode(node: INode): string {
    const size = this.calculateIconSize(node);

    if (node.tag.isGroupNode) {
      let svgString =
        '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="' +
        node.tag.groupColor +
        '" style="opacity:.6"><path d="M 0 0 L 0 4 L 1 4 L 1 18 L 0 18 L 0 22 L 4 22L 4 21 L 18 21 L 18 22 L 22 22 L 22 18L 21 18L 21 4L 22 4L 22 0L 18 0L 18 1L 4 1L 4 0M 4 3L 18 3L 18 4L 19 4L 19 18L 18 18L 18 19L 4 19L 4 18L 3 18L 3 4L 4 4M 5 5L 5 13L 9 13L 9 17L 17 17L 17 9L 13 9L 13 5M 7 7 L 11 7 L 11 11 L 7 11 M 13 11 L 15 11 L 15 15 L 11 15 L 11 13 L 13 Z"/></svg>';

      return SvgExport.encodeSvgDataUrl(svgString);
    }
    if (node.tag.style.visualType == NodeVisualType.Image) {
      return (node.tag.style as ImageNodeStyleDto).imageUrl;
    }

    return this.createSvgFromGraphNodeStyle(
      node.style,
      size.width,
      size.height,
      3
    );
  }

  public static createIconFromEdge(edge: IEdge): string {
    const size = this.calculateIconSize(edge);

    //Copy edge style identity, losing Edge linkage
    const edgeStyleDto = DiagramWriter.convertEdgeStyle(edge);
    const edgeStyle = StyleCreator.createEdgeStyle(edgeStyleDto);

    return this.createSvgFromGraphEdgeStyle(
      edgeStyle,
      false,
      null,
      size.width,
      edge.bends?.size > 0,
      0
    );
  }

  private static calculateIconSize(element: INode | IEdge): Size {
    if (element instanceof INode) {
      if (element.layout.width > element.layout.height) {
        return new Size(90, 60);
      } else {
        return new Size(60, 60);
      }
    } else if (element instanceof IEdge) {
      return new Size(30, 30);
    }
  }

  /**
   * Replaces all instances of <use href="#id">...</use> with
   * the corresponding element in <defs> that matches the id
   * This is needed for jsPdf to work correctly
   */
  private static replaceUsesWithDefs(svg: Element) {
    svg.removeAttribute('style');
    svg.querySelectorAll('use[*|href]').forEach((use) => {
      const id = use.getAttribute('href');
      const def = svg.querySelector(id);
      if (def) {
        use.parentNode.insertBefore(def, use);
        for (let attr of use.attributes) {
          def.setAttribute(attr.name, attr.value);
        }
        def.removeAttribute('href');
        def.removeAttribute('id');
        use.remove();
      }
    });
  }
}
