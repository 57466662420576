import {
  GraphComponent,
  GraphMLSupport,
  INode,
  Insets,
  PortDirections,
  SimpleNode,
  Size,
} from 'yfiles';
import DiagramWriter from '@/core/services/graph/serialization/diagram-writer.service';
import {
  convertSvgToImage,
  convertSvgElementToImage,
  RgbaToHex,
  getImageSize,
} from '@/core/utils/common.utils';
import {
  CompositeNodeStyleDto,
  DiagramDto,
  DiagramNodeDto,
  DocumentAttachmentType,
  DocumentPageContentType,
  DocumentPageDto,
  DocumentPageType,
  FillDto,
  ImageNodeStyleDto,
  NodeShape,
  NodeSize,
  NodeVisualType,
  PageElementPosition,
  PowerPointDiagramNodeDto,
  PowerPointDiagramEdgeDto,
  PowerPointExportDto,
  PowerPointExportSlideDto,
  ShapeNodeStyleDto,
  StrokeDto,
  TemplatePageItem,
  DiagramEdgeDto,
  TDataDto,
  TRowDto,
  TCellContentType,
  TCellOptionsDto,
  TRowOptionsDto,
  TColumnOptionsDto,
  TColumnDto,
  TImageCellOptionsDto,
  LayoutDto,
} from '@/api/models';
import { ExportPageElementType } from '@/core/services/export/ExportPageElementType';
import DiagramExportApiService from '@/api/DiagramExportApiService';
import DecorationStateManager from '../../DecorationStateManager';
import IndicatorDecorators, {
  IndicatorState,
} from '@/core/styles/decorators/IndicatorDecorators';
import JurisdictionDecorator, {
  JurisdictionDecorationState,
} from '@/core/styles/decorators/JurisdictionDecorator';
import { AnnotationType } from '@/core/common/AnnotationType';
import DiagramUtils from '@/core/utils/DiagramUtils';
import CompositeNodeStyle from '@/core/styles/composite/CompositeNodeStyle';
import JigsawNodeStyle from '@/core/styles/JigsawNodeStyle';
import IExportProvider from './IExportProvider';
import ExportOptions from '../ExportOptions';
import b64toBlob from '../../graph/b64ToBlob';
import ExportUtils from '../ExportUtils';
import { IGraph } from 'yfiles/typings/yfiles-api-npm';
import { ExportFormat } from '@/core/services/export/ExportFormat';
import BackgroundGraphService from '@/core/services/graph/BackgroundGraphService';
import ContentPagination from '@/core/services/export/ContentPagination';
import DataPropertiesDecorator from '@/core/styles/decorators/DataPropertiesDecorator';
import ExportConfig from '@/core/config/ExportConfig';
import ExportPage from '../ExportPage';
import DataPropertyUtils from '@/core/utils/DataPropertyUtils';
import INodeLabelData from '@/core/services/graph/serialization/INodeLabelData';
import IExportResult from './IExportResult';
import ILegendDefinition from '@/components/DiagramLegend/ILegendDefinition';
import LogoAsImageProvider from '../additional-element-providers/LogoAsImgProvider';
import _ from 'lodash';

export default class PowerPointExportProvider implements IExportProvider {
  private _fileExtension = 'pptx';
  private _mimeType = 'application/vnd.ms-powerpoint';

  async exportGraphAsBlob(
    options: ExportOptions,
    graphComponent: GraphComponent,
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    const exportResult = await this.exportGraphAsBase64(
      options,
      graphComponent,
      graphMLSupport
    );
    //let context = graphComponent.createRenderContext();
    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      size: exportResult.size,
      result: b64toBlob(exportResult.result, this._mimeType),
    };
  }

  async exportGraphAsBase64(
    options: ExportOptions,
    graphComponent: GraphComponent, //This may well be redundant in steps, rework signature?
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    const request = await this.getExportRequest(options, graphComponent);

    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      result: await this.getExport(request),
    };
  }

  async getExportRequest(
    options: ExportOptions,
    graphComponent: GraphComponent
  ): Promise<PowerPointExportDto> {
    await this.appendAdditionalElements(options);
    let logo = options.document?.attachments?.find(
      (a) => a.attachmentType == DocumentAttachmentType.Logo
    );

    const headerSeparatorFileId = DiagramUtils.getDocumentAttachmentId(
      options.document,
      DocumentAttachmentType.HeaderSeparator
    );

    if (headerSeparatorFileId) {
      options.document.headerStyle.dividerImgFileId = headerSeparatorFileId;
    }

    const footerSeparatorFileId = DiagramUtils.getDocumentAttachmentId(
      options.document,
      DocumentAttachmentType.FooterSeparator
    );

    if (footerSeparatorFileId) {
      options.document.footerStyle.dividerImgFileId = footerSeparatorFileId;
    }

    const headerBackgroundFileId = DiagramUtils.getDocumentAttachmentId(
      options.document,
      DocumentAttachmentType.HeaderBackground
    );

    if (headerBackgroundFileId && options.document.hasSteps) {
      logo = null;
      options.document.headerStyle.backgroundImgFileId = headerBackgroundFileId;
    }

    const footerBackgroundFileId = DiagramUtils.getDocumentAttachmentId(
      options.document,
      DocumentAttachmentType.FooterBackground
    );

    if (footerBackgroundFileId) {
      options.document.footerStyle.backgroundImgFileId = footerBackgroundFileId;
    }

    let backgroundImageFileAttachmentId = null;
    if (options.document.hasSteps) {
      backgroundImageFileAttachmentId = DiagramUtils.getDocumentAttachmentId(
        options.document,
        DocumentAttachmentType.PageBackground
      );
      if (backgroundImageFileAttachmentId) {
        logo = null;
      }
    }

    const additionalElementsLogo =
      options.metadata.currentPage.additionalElements.find(
        (x) => x.type == ExportPageElementType.Logo
      );

    const request: PowerPointExportDto = {
      slides: [],
      headerStyle: options.document.headerStyle,
      footerStyle: options.document.footerStyle,
      logoFileAttachmentId: logo?.fileAttachment?.fileId,
      backgroundImageFileAttachmentId: backgroundImageFileAttachmentId,
      showSlideNumber:
        options.document.showPageNumbering && options.document.showFooter,
      isStepsDocument: options.document.hasSteps,
      logoPosition:
        <PageElementPosition>additionalElementsLogo?.options?.position ?? 0,
    };

    let pageNumber = 1;
    for (const exportPage of options.pages) {
      const subPages = [];
      options.metadata.currentPage = exportPage;
      let slideRequest = {} as PowerPointExportSlideDto;
      if (exportPage.page.contentType === DocumentPageContentType.Layout) {
        slideRequest.templatePage = {
          pageItems: JSON.parse(exportPage.page.content) as TemplatePageItem[],
        };
        slideRequest.documentPageType = DocumentPageType.Content;
        slideRequest.documentPageContentType = DocumentPageContentType.Layout;
        if (exportPage.page.showLegend && exportPage.page.diagram?.legend) {
          slideRequest.tables = [
            await this.legendToTable(
              options,
              JSON.parse(exportPage.page.diagram.legend),
              graphComponent
            ),
          ];
        }
        request.slides.push(slideRequest);
      } else {
        //Replace tables with the string of <p>s
        const replaceExp = /<table.*?>.*?<\/table>/gs;

        if (
          exportPage.page.content &&
          exportPage.page.contentType == DocumentPageContentType.Html
        ) {
          const subPagesContent = this.getContent(exportPage.page);

          for (let index = 0; index < subPagesContent.length; index++) {
            const diagram =
              exportPage.page.subPageRefs?.find((x) => x.subPageIndex == index)
                ?.diagram ?? exportPage.page.diagram;

            const sourceGraph = diagram
              ? new BackgroundGraphService(diagram).graph
              : null;
            const subPageSlideRequest = await this.getPowerPointExportSlideDto(
              sourceGraph,
              options,
              diagram?.id
            );

            subPageSlideRequest.headerHtml = this.getPageHeader(
              exportPage,
              replaceExp,
              index
            );
            subPageSlideRequest.footerHtml = this.getPageFooter(
              exportPage,
              replaceExp,
              index
            );

            request.slides.push({
              ...subPageSlideRequest,
              textPanelContent: subPagesContent[index],
              slideNumber: pageNumber++,
            });
          }
        } else {
          const sourceGraph = exportPage.page.diagram
            ? new BackgroundGraphService(exportPage.page.diagram).graph
            : null;
          slideRequest = await this.getPowerPointExportSlideDto(
            sourceGraph,
            options,
            exportPage.page.diagram?.id
          );
          if (exportPage.page.showLegend && exportPage.page.diagram?.legend) {
            const legendDefinition = JSON.parse(
              exportPage.page.diagram.legend
            ) as ILegendDefinition;
            /* Now we need to inline all the images*/
            for (const item of legendDefinition.items) {
              item.symbol = (await convertSvgToImage(item.symbol, 'png')).src;
            }

            slideRequest.tables = [
              await this.legendToTable(
                options,
                JSON.parse(exportPage.page.diagram.legend),
                graphComponent
              ),
            ];
          }
          slideRequest.headerHtml = this.getPageHeader(exportPage, replaceExp);
          slideRequest.footerHtml = this.getPageFooter(exportPage, replaceExp);
          if (options.document.hasSteps) {
            slideRequest.slideNumber = pageNumber++;
          }

          request.slides.push(slideRequest);
        }
      }
    }
    return request;
  }

  private async legendToTable(
    options: ExportOptions,
    legend: ILegendDefinition,
    graphComponent: GraphComponent
  ): Promise<TDataDto> {
    if (!legend) {
      return null;
    }
    const imageColumnOptions: TColumnOptionsDto = {
      width: 30,
    };
    const labelColumnOptions: TColumnOptionsDto = {
      width: 140,
    };
    const rowOptions: TRowOptionsDto = {
      height: 30,
    };
    const data: TDataDto = {
      rows: [],
      columns: [
        new TColumnDto(imageColumnOptions),
        new TColumnDto(labelColumnOptions),
      ],
      position: options.document.legendPosition,
    };

    var defaultLabelCellOptions: TCellOptionsDto = {};

    //TODO: Restore this logic once legend positioning has been fixed

    //options.document.legendPosition;
    // const position = ExportUtils.getAdditionalElementPosition(
    //   graphComponent.contentRect,
    //   options.document.legendPosition,
    //   image.height,
    //   image.width
    // );

    const legendDefinition = options.metadata.currentPage.page?.diagram?.legend;
    if (legendDefinition) {
      let legendHeader = JSON.parse(legendDefinition).options.header;
      const headerRow: TRowDto = {
        options: rowOptions,

        cells: [
          {
            content: {
              options: defaultLabelCellOptions,
              contentType: TCellContentType.String,
              data: legendHeader,
            },
            horizontalMerge: false,
            gridSpan: 2,
          },
          {
            content: {
              options: defaultLabelCellOptions,
              contentType: TCellContentType.String,
            },
            horizontalMerge: true,
          },
        ],
      };
      data.rows.push(headerRow);
    }
    let legendItems = legend.items;
    if (options.withFilters) {
      legendItems = legend.items.filter((x) => !x.isFiltered);
    }
    for (const item of legendItems) {
      var imageResult = await convertSvgToImage(item.symbol, 'png');
      const row: TRowDto = {
        options: rowOptions,
        cells: [
          {
            content: {
              contentType: TCellContentType.PngImage,
              data: imageResult.src,
              options: new TImageCellOptionsDto(
                imageResult.width,
                imageResult.height
              ),
            },
          },
          {
            content: {
              options: defaultLabelCellOptions,
              contentType: TCellContentType.String,
              data: item.label,
            },
          },
        ],
      };

      data.rows.push(row);
    }
    return data;
  }

  // Powerpoint Export does not currently support full table layout in headers and footers so we need
  // to convert to something it will handle. Extract <td> data to <p>
  private getPageHeader(
    exportPage: ExportPage,
    replaceExp: RegExp,
    index?: number
  ) {
    const subPageRef = exportPage.page.subPageRefs?.find(
      (sp) => sp.subPageIndex === index
    );
    const headerHtml = subPageRef
      ? subPageRef.headerHtml
      : exportPage.page.headerHtml;
    if (headerHtml) {
      let exportHeaderHtml = '';
      const processedHeader = this.processHeaderFooterTables(headerHtml);
      exportHeaderHtml = headerHtml?.replace(replaceExp, processedHeader);
      return ExportUtils.tryInlineContentStyles(
        DocumentPageContentType.Html,
        exportHeaderHtml
      );
    }
    return null;
  }

  private getPageFooter(
    exportPage: ExportPage,
    replaceExp: RegExp,
    index?: number
  ) {
    const subPageRef = exportPage.page.subPageRefs?.find(
      (sp) => sp.subPageIndex === index
    );
    const footerHtml = subPageRef
      ? subPageRef.footerHtml
      : exportPage.page.footerHtml;
    if (footerHtml) {
      let exportFooterHtml = '';
      const processedHeader = this.processHeaderFooterTables(footerHtml);
      exportFooterHtml = footerHtml?.replace(replaceExp, processedHeader);
      return ExportUtils.tryInlineContentStyles(
        DocumentPageContentType.Html,
        exportFooterHtml
      );
    }
    return null;
  }

  private processHeaderFooterTables(htmlContent: string): string {
    // Match all <td> and convert to <div>. Discard any empty cells
    const exp = /(<td.*?>.*?<\/td>)/g;
    let tableToParagraph = '';
    const matches = htmlContent.match(exp);

    if (matches && matches.length > 0) {
      for (let i = 0; i < matches.length; i++) {
        const match = matches[i];
        if (match.includes(ExportConfig.pageNumberVariable)) {
          continue;
        }

        const paragraph = match
          .replace('<td', `<div`)
          .replace('</td>', '</div>');

        // Put whitespace between paragraphs
        if (tableToParagraph) {
          tableToParagraph += '&nbsp;&nbsp;&nbsp;';
        }
        tableToParagraph += paragraph;
      }
    }

    return tableToParagraph;
  }

  private async getPowerPointExportSlideDto(
    graph: IGraph,
    options: ExportOptions,
    diagramId: number
  ): Promise<PowerPointExportSlideDto> {
    const diagram = new DiagramDto(false, 0);
    if (graph) {
      const graphComponent = ExportUtils.copyGraphComponent(
        graph,
        options.withFilters,
        ExportFormat.PowerPoint
      );

      graphComponent.updateContentRect();
      graphComponent.zoomTo(graphComponent.contentRect);

      this.assignPortDirections(graphComponent.graph);

      DiagramWriter.fromGraph(graphComponent.graph, diagram, (x, y) => {
        return graphComponent.graphModelManager.comparer.compare(x, y);
      });
      for (const diagramNodeDto of diagram.nodes) {
        const elementIndex = diagram.nodes.indexOf(diagramNodeDto);
        const graphNode = this.getNode(graphComponent, diagramNodeDto);
        (diagramNodeDto as PowerPointDiagramNodeDto).children = [];

        switch (diagramNodeDto.style.visualType) {
          case NodeVisualType.Shape:
            if (graphNode.tag.annotationType != AnnotationType.Logos) {
              const path = graphNode.style.renderer
                .getShapeGeometry(graphNode, graphNode.style)
                .getOutline()
                .createSvgPathData();
              (diagramNodeDto as PowerPointDiagramNodeDto).svgPath = path;
            }
            break;
          case NodeVisualType.Composite:
            const children = [];
            const compositeStyle =
              diagramNodeDto.style as CompositeNodeStyleDto;

            compositeStyle.styleDefinitions.forEach((definition, index) => {
              const currentNode = _.cloneDeep(diagramNodeDto);

              const shapeNodeStyleDto = {
                ...definition.nodeStyle,
              } as ShapeNodeStyleDto;

              shapeNodeStyleDto.visualType = NodeVisualType.Shape;
              currentNode.style = shapeNodeStyleDto;

              const currentStyle = (
                (graphNode.style as JigsawNodeStyle)
                  .baseStyle as CompositeNodeStyle
              ).styleDefinitions[index].nodeStyle;

              const dummyNode = new SimpleNode();
              dummyNode.layout = graphNode.layout;

              const newInsets = CompositeNodeStyle.calculateInsets(
                definition.insets as Insets,
                dummyNode
              ) as Insets;

              dummyNode.layout = dummyNode.layout
                .toRect()
                .getReduced(newInsets);

              const svgPath = currentStyle.renderer
                .getShapeGeometry(dummyNode, currentStyle)
                .getOutline()
                .createSvgPathData();

              currentNode.layout = new LayoutDto(
                dummyNode.layout.x,
                dummyNode.layout.y,
                dummyNode.layout.width,
                dummyNode.layout.height
              );
              (currentNode as PowerPointDiagramNodeDto).svgPath = svgPath;
              currentNode.id = null;
              currentNode.uuid = null;
              currentNode.groupUuid = null;
              currentNode.label = '';
              children.push(currentNode);
            });
            (diagramNodeDto as PowerPointDiagramNodeDto).children = children;

            break;
          case NodeVisualType.Image:
            diagram.nodes[elementIndex] = await this.setupImageNode(
              graphNode,
              diagramNodeDto
            );

            break;
        }

        const nodeLabelData: INodeLabelData = diagramNodeDto.data.labelData;

        if (nodeLabelData && graphNode.labels.size == 1) {
          const label = graphNode.labels.first();
          const geometry = label.layoutParameter.model.getGeometry(
            label,
            label.layoutParameter
          );

          diagramNodeDto.data.labelLayout = {
            anchorX: geometry.anchorX,
            anchorY: geometry.anchorY,
            upX: geometry.upX,
            upY: geometry.upY,
            width: geometry.width,
            height: geometry.height,
          };
        }
      }

      for (const edge of diagram.edges) {
        edge.label = ExportUtils.tryInlineContentStyles(
          DocumentPageContentType.Html,
          edge.label
        );
        const graphEdge = this.getEdge(graphComponent, edge);
        const path = graphEdge.style.renderer
          .getPathGeometry(graphEdge, graphEdge.style)
          .getPath()
          .createSvgPathData();
        const pptEdge = edge as PowerPointDiagramEdgeDto;
        pptEdge.svgPath = path;
        const label = graphEdge.labels.firstOrDefault();
        if (label) {
          pptEdge.labelLayout = new LayoutDto(
            label.layout.anchorX,
            label.layout.anchorY,
            label.layout.width,
            label.layout.height
          );
        }
      }

      //TODO: revisit addition export items
      await this.addAdditionalElements(
        options,
        graphComponent,
        diagram.nodes,
        diagramId
      );
      await this.processNodes(diagram.nodes, graphComponent);
      this.processLabels(diagram.nodes, graphComponent);

      graphComponent.cleanUp();
    }

    const textPanelContent = this.getContent(options.metadata.currentPage.page);

    return {
      nodes: diagram.nodes?.filter((x) => !x.isGroupNode) ?? [],
      edges: diagram.edges ?? [],
      textPanelContent: textPanelContent[0],
      documentPageType: options.metadata.currentPage.page.pageType,
      diagramPosition: options.metadata.currentPage.page.diagramPosition,
      showHeader: options.metadata.currentPage.page.showHeader,
      showFooter: options.metadata.currentPage.page.showFooter,
      slideNumber: 0,
      showLogo:
        (options.metadata.currentPage.additionalElements.some(
          (x) => x.type == ExportPageElementType.Logo
        ) &&
          options.document.hasSteps) ||
        (!options.document.hasSteps &&
          options.metadata.currentPage.page.showLogo),
    };
  }

  private scaleSize(from: Size, maxWidth?: number, maxHeight?: number): Size {
    if (!maxWidth && !maxHeight)
      throw 'At least one scale factor (toWidth or toHeight) must not be null.';
    if (from.height == 0 || from.width == 0)
      throw 'Cannot scale size from zero.';

    let widthScale: number = null;
    let heightScale: number = null;

    if (maxWidth) {
      widthScale = maxWidth / from.width;
    }
    if (maxHeight) {
      heightScale = maxHeight / from.height;
    }

    const scale = Math.min(
      widthScale ?? heightScale,
      heightScale ?? widthScale
    );

    return new Size(
      Math.floor(from.width * scale),
      Math.ceil(from.height * scale)
    );
  }

  async setupImageNode(graphNode: INode, diagramNodeDto: DiagramNodeDto) {
    const imageNode = { ...diagramNodeDto } as DiagramNodeDto;
    imageNode.label = null;
    const imageNodeStyleDto = diagramNodeDto.style as ImageNodeStyleDto;
    diagramNodeDto.style = new ShapeNodeStyleDto(
      new FillDto(null),
      new StrokeDto(0, new FillDto(null), null),
      NodeShape.Rectangle,
      NodeSize.Medium,
      NodeVisualType.Shape
    );
    const imageSize = await getImageSize(imageNodeStyleDto.imageUrl);
    imageNode.layout = { ...diagramNodeDto.layout };

    // scale the image to fit
    const newSize = this.scaleSize(
      new Size(imageSize.width, imageSize.height),
      diagramNodeDto.layout.width,
      diagramNodeDto.layout.height
    );

    // apply new dimensions
    imageNode.layout.width = newSize.width;
    imageNode.layout.height = newSize.height;

    // horizontally and vertically align
    const xDiff = diagramNodeDto.layout.width - imageNode.layout.width;
    const yDiff = diagramNodeDto.layout.height - imageNode.layout.height;
    if (xDiff > 0) {
      imageNode.layout.x += xDiff / 2;
    }
    if (yDiff > 0) {
      imageNode.layout.y += yDiff / 2;
    }

    const pptDiagramNodeDto = diagramNodeDto as PowerPointDiagramNodeDto;
    pptDiagramNodeDto.children = [imageNode];

    const path = graphNode.style.renderer
      .getShapeGeometry(graphNode, graphNode.style)
      .getOutline()
      .createSvgPathData();
    (diagramNodeDto as PowerPointDiagramNodeDto).svgPath = path;

    const image = await convertSvgToImage(imageNodeStyleDto.imageUrl, 'png');
    imageNodeStyleDto.imageUrl = image.src;

    return diagramNodeDto;
  }

  private async getExport(request: PowerPointExportDto) {
    let exportString: string = null;
    this._fileExtension = 'pptx';

    try {
      const response = await DiagramExportApiService.postPowerPointExport(
        request
      );
      if (response.data.result.base64String.length > 0) {
        exportString = response.data.result.base64String;
      }
    } catch (e) {
      console.log(
        e,
        'Error calling DiagramExportApiService.postPowerPointExport'
      );
    }
    return exportString;
  }

  private async processNodes(
    diagramNodeDtos: DiagramNodeDto[],
    graphComponent: GraphComponent
  ) {
    const groupColorLookup = this.getGroupColorLookup(diagramNodeDtos);

    for (const diagramElementNode of diagramNodeDtos) {
      if (!diagramElementNode.uuid) continue;

      const node = this.getNode(graphComponent, diagramElementNode);

      if (!node || node.tag.isGroupNode) continue;

      if (node.tag.groupUuid && node.tag.groupUuid.length > 0) {
        diagramElementNode.data.groupColor =
          groupColorLookup[node.tag.groupUuid];
      }

      await this.configureIndicators(node, diagramElementNode);
      await this.configureJurisdictionDecorator(node, diagramElementNode);
      await this.configureDataPropertiesDecorator(node, diagramElementNode);
    }
  }

  private getNode(graphComponent: GraphComponent, element: DiagramNodeDto) {
    return graphComponent.graph.nodes.first((x) => x.tag.uuid === element.uuid);
  }

  private getEdge(graphComponent: GraphComponent, element: DiagramEdgeDto) {
    return graphComponent.graph.edges.first((x) => x.tag.uuid === element.uuid);
  }

  private async addAdditionalElements(
    options: ExportOptions,
    graphComponent: GraphComponent,
    nodes: DiagramNodeDto[],
    diagramId: number
  ) {
    for (const el of options.metadata.currentPage.additionalElements.filter(
      (x) => x.type != ExportPageElementType.Logo || x.key == diagramId
    )) {
      const svg = await el.toSvgAsync();

      const image = await convertSvgElementToImage(svg, 'png');

      if (image.height === 0 || image.width === 0) continue;

      const position = ExportUtils.getAdditionalElementPosition(
        graphComponent.contentRect,
        el.options.position,
        image.height,
        image.width
      );

      const additionalElementNode: PowerPointDiagramNodeDto = {
        style: new ImageNodeStyleDto(NodeVisualType.Image, image.src),
        isGroupNode: false,
        label: '',
        layout: {
          x: position.x,
          y: position.y,
          width: image.width,
          height: image.height,
        },
        data: { isAnnotation: true, annotationType: 0 },
        uuid: null,
      };

      nodes.push(additionalElementNode);
    }
  }

  /**
   Use path geometry to ascertain the direction that the line is coming from.
   Powerpoint needs this when constructing connectors
   */
  private assignPortDirections(graph: IGraph) {
    const graphEdges = graph.edges;
    graphEdges.forEach((edge) => {
      const pathGeometry = edge.style.renderer.getPathGeometry(
        edge,
        edge.style
      );

      const sourceTangent = pathGeometry.getTangent(0);
      const targetTangent = pathGeometry.getTangent(1);

      function getToNearest90Degrees(x, y) {
        let degrees = (Math.atan2(x, y) * 180) / Math.PI;
        degrees = Math.round(degrees / 90) * 90;
        return degrees;
      }

      const sourceDegrees = getToNearest90Degrees(
        sourceTangent.vector.x,
        sourceTangent.vector.y
      );
      const targetDegrees = getToNearest90Degrees(
        targetTangent.vector.x,
        targetTangent.vector.y
      );

      function getPortDirection(degrees, isSource: boolean) {
        switch (degrees) {
          case 90:
            return isSource ? PortDirections.WEST : PortDirections.EAST;
          case -90:
            return isSource ? PortDirections.EAST : PortDirections.WEST;
          case 0:
            return isSource ? PortDirections.NORTH : PortDirections.SOUTH;
          case 180:
          case -180:
            return isSource ? PortDirections.SOUTH : PortDirections.NORTH;
        }
      }

      edge.tag.sourcePortDirection = getPortDirection(sourceDegrees, true);
      edge.tag.targetPortDirection = getPortDirection(targetDegrees, false);
    });
  }

  private getGroupColorLookup(nodes: DiagramNodeDto[]) {
    const groupColorLookup = {};
    nodes
      .filter((x) => x.isGroupNode)
      .forEach((x) => {
        let groupColor = x.data.groupColor
          .replace('rgb(', '')
          .replace(')', '')
          .split(',');

        groupColorLookup[x.groupUuid] = RgbaToHex(
          groupColor[0],
          groupColor[1],
          groupColor[2],
          255
        );
      });
    return groupColorLookup;
  }

  /**
   Composite shapes are created in powerpoint by stacking shapes and grouping.
   Keep the label layout by creating a transparent node on top and assigning the label to that one.
   */
  private processLabels(
    nodes: DiagramNodeDto[],
    graphComponent: GraphComponent
  ) {
    nodes.forEach((node) => {
      node.label = ExportUtils.tryInlineContentStyles(
        DocumentPageContentType.Html,
        node.label
      );
    });
    nodes
      .filter(
        (x) =>
          (x as PowerPointDiagramNodeDto).children &&
          (x as PowerPointDiagramNodeDto).children.length > 0
      )
      .forEach((node) => {
        const children = (node as PowerPointDiagramNodeDto).children;
        const nodeStyle = node.style as ShapeNodeStyleDto;
        const label = node.label;
        const diagramNode = this.getNode(graphComponent, node);
        const diagramLabel = diagramNode.labels.firstOrDefault();

        children.push({
          style: new ShapeNodeStyleDto(
            { color: '#00000000' },
            { ...nodeStyle.stroke, fill: { color: '#00000000' } },
            nodeStyle.shape,
            nodeStyle.size,
            nodeStyle.visualType
          ),
          isGroupNode: false,
          label: label,
          layout: {
            x: node.layout.x,
            y: node.layout.y,
            width: node.layout.width,
            height: node.layout.height,
          },
          data: {
            isAnnotation: true,
            annotationType: 0,
            labelLayout: label
              ? {
                  anchorX: diagramLabel.layout.anchorX,
                  anchorY: diagramLabel.layout.anchorY,
                  width: diagramLabel.layout.width,
                  height: diagramLabel.layout.height,
                }
              : null,
          },
          uuid: null,
        });

        node.label = '';
      });
  }

  /**
   * Appends a child element to the @param diagramElementNode children if the node has a flag attached
   * @param node the node to query for a flag
   * @param diagramElementNode
   * @returns  promise
   */
  private async configureJurisdictionDecorator(
    node: INode,
    diagramElementNode: DiagramNodeDto
  ) {
    // get current state for the flag decorator on the given node
    const state = DecorationStateManager.getState(
      JurisdictionDecorator.INSTANCE,
      node
    ) as JurisdictionDecorationState;

    //check if flag toggled as visible
    const isJurisdictionFlagVisible =
      JurisdictionDecorator.INSTANCE.isJurisdictionDecoratorVisible(node);

    //check if state initials toggled as visible
    const isStateInitialsVisible =
      JurisdictionDecorator.INSTANCE.isStateDecoratorVisible(node);

    // no jurisdiction flag or state initials, return out
    if (!isJurisdictionFlagVisible && !isStateInitialsVisible) {
      return;
    }

    // ask the decorator for the correct position
    const layout = JurisdictionDecorator.INSTANCE.getInitialLayout(node);

    /*JURISDICTION FLAG*/

    if (isJurisdictionFlagVisible) {
      //create image for jurisdiction
      const imageJurisdiction = await convertSvgToImage(
        state.jurisdictionFlagImage,
        'png'
      );

      //set layout of jurisdictionFlag
      let layoutJurisdiction = {
        x: layout.x,
        y: layout.y,
        width: layout.width,
        height: layout.height,
      };

      // adjust the jurisdiction flag location to accomdate for the state visual if it is toggled on
      if (JurisdictionDecorator.INSTANCE.isStateDecoratorVisible(node)) {
        layoutJurisdiction = {
          x: layout.x - layout.width,
          y: layout.y,
          width: layout.width,
          height: layout.height,
        };
      }

      //create DiagramNodeDto for pushing to the PowerpointDiagramNodeDto
      const decoratorNodeJurisdiction: DiagramNodeDto = {
        style: new ImageNodeStyleDto(
          NodeVisualType.Image,
          imageJurisdiction.src
        ),
        isGroupNode: false,
        label: '',
        layout: layoutJurisdiction,
        data: { isAnnotation: true, annotationType: 0 },
      };

      //push node to the PowerPointDiagramNodeDto as child
      (diagramElementNode as PowerPointDiagramNodeDto).children.push(
        decoratorNodeJurisdiction
      );
    }

    /*STATE INITIALS*/
    if (isStateInitialsVisible) {
      //create image
      let circleSize = 72;
      let fontSize = 80;
      let circleX = 75;
      let circleY = 75;
      let strokeWidth = 6;
      let textOffsetX = 2.1;
      let textOffsetY = 4.25;
      let padding = 2;

      const imageStateSVG =
        DataPropertyUtils.createStateInitialsCircleSvgVisual(
          state.stateInitials,
          circleSize,
          fontSize,
          circleX,
          circleY,
          textOffsetX,
          textOffsetY,
          strokeWidth
        );
      const imageState = await convertSvgToImage(
        `data:image/svg+xml;utf8,${`<svg xmlns="http://www.w3.org/2000/svg" height="150" width="150">${imageStateSVG.svgElement.outerHTML}</svg>`}`,
        'png'
      );

      //create DiagramNodeDto for pushing to the PowerpointDiagramNodeDto as children
      const decoratorNodeState: DiagramNodeDto = {
        style: new ImageNodeStyleDto(
          NodeVisualType.Image,
          imageState.src,
          null,
          150,
          150,
          null
        ),
        isGroupNode: false,
        label: '',
        layout: {
          x: layout.x + padding,
          y: layout.y,
          width: layout.height,
          height: layout.height,
        },
        data: { isAnnotation: true, annotationType: 0 },
      };

      //push node to the PowerPointDiagramNodeDto as child
      (diagramElementNode as PowerPointDiagramNodeDto).children.push(
        decoratorNodeState
      );
    }
  }

  /**
   * Appends a child element to the @param diagramElementNode children if the node has data properties
   * @param node the node to query for a data properties
   * @param diagramElementNode
   * @returns  promise
   */
  private async configureDataPropertiesDecorator(
    node: INode,
    diagramElementNode: DiagramNodeDto
  ) {
    const isVisible = DataPropertiesDecorator.INSTANCE.shouldBeVisible(node);
    if (!isVisible) {
      return;
    }
    const layout = DataPropertiesDecorator.INSTANCE.getLayout(node);
    const image = await convertSvgToImage(
      DataPropertiesDecorator.INSTANCE.imageStyle.image,
      'png'
    );

    const decoratorNode: DiagramNodeDto = {
      style: new ImageNodeStyleDto(NodeVisualType.Image, image.src),
      isGroupNode: false,
      label: '',
      layout: {
        x: layout.x,
        y: layout.y,
        width: layout.width,
        height: layout.height,
      },
      data: { isAnnotation: true, annotationType: 0 },
    };

    (diagramElementNode as PowerPointDiagramNodeDto).children.push(
      decoratorNode
    );
  }

  private async configureIndicators(
    node: INode,
    diagramElementNode: DiagramNodeDto
  ) {
    const state = DecorationStateManager.getState(
      IndicatorDecorators.INSTANCE,
      node
    ) as IndicatorState;

    if (state.indicators && state.indicators.length > 0) {
      let offset = 0;
      for (let i = 0; i < state.indicators.length; i++) {
        const indicator = state.indicators[i];
        let layout = IndicatorDecorators.INSTANCE.getLayout(
          node.layout,
          i,
          state.indicators.length,
          DiagramUtils.getNodeShape(node),
          node.tag.annotationType
        );

        const image = await convertSvgToImage(indicator, 'png');

        const decoratorNode: DiagramNodeDto = {
          style: new ImageNodeStyleDto(NodeVisualType.Image, image.src),
          isGroupNode: false,
          label: '',
          // cannot clone object;
          layout: {
            x: layout.x,
            y: layout.y,
            width: layout.width,
            height: layout.height,
          },
          data: { isAnnotation: true, annotationType: 0 },
        };

        (diagramElementNode as PowerPointDiagramNodeDto).children.push(
          decoratorNode
        );

        offset = offset + 20; // TODO: find a way to remove this if statement
      }
    }
  }

  private getContent(exportPage: DocumentPageDto): string[][] {
    if (!exportPage.content) return [];

    const pages = ContentPagination.splitPagedContentIntoPages(
      ExportUtils.tryInlineContentStyles(
        exportPage.contentType,
        exportPage.content
      )
    );

    const pagesColumns = [];

    pages.forEach((x) => {
      const htmlContent = ContentPagination.appendElementData(
        x,
        exportPage.contentColumns,
        exportPage.pageType
      );

      const columns = ContentPagination.splitPageIntoColumns(
        htmlContent,
        exportPage.contentColumns,
        exportPage.pageType
      );

      pagesColumns.push(columns);
    });

    return pagesColumns;
  }

  private async appendAdditionalElements(
    options: ExportOptions
  ): Promise<void> {
    for (const exportPage of options.pages) {
      if (!exportPage.additionalElements) {
        exportPage.additionalElements = [];
      }

      // Logo
      if (
        options.document.logoPosition != PageElementPosition.Hidden &&
        exportPage.page.showLogo
      ) {
        const logoProvider = new LogoAsImageProvider();
        const additionalElement = await logoProvider.get(options, exportPage);
        if (additionalElement) {
          exportPage.additionalElements.push(...additionalElement);
        }
      }
    }
  }
}
