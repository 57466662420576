
































import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'Confirmations',
})
export default class Confirmations extends Vue {
  dummyCheck = false;

  confirm1() {
    this.$confirm({
      title: 'Are you sure?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum, veniam suscipit corporis a molestias, accusantium hic quod doloribus possimus sit ipsa dolores vitae reprehenderit, expedita error architecto eum. Magni, perspiciatis.',
    })
      .then(() => {
        console.debug('confirm in component - success');
        this.$notify({ title: 'Confirmed', type: 'success' });
      })
      .catch(() => {
        console.debug('confirm in component - deny');
        this.$notify({ title: 'Rejected', type: 'error' });
      });
  }

  confirm2() {
    const h = this.$createElement;
    const message = h('p', null, [
      h('span', null, 'Message can be '),
      h('i', { class: 'j-text-accent-purple' }, 'VNode'),
    ]);

    this.$confirm({
      title: 'Some title',
      text: message,
      denyText: 'Do not allow',
      okText: 'Allow all',
    })
      .then(() => {
        console.debug('confirm in component - success');
      })
      .catch(() => {
        console.debug('confirm in component - deny');
      });
  }

  confirm3() {
    this.$confirm({
      title: 'Document sharing',
      text: 'Are you sure to grant access to this document?',
      denyText: 'No',
      okText: 'Yes, I am',
    })
      .then(() => {
        console.debug('confirm in component - success');
      })
      .catch(() => {
        console.debug('confirm in component - deny');
      });
  }

  msgBox1() {
    this.$msgBox({
      title: 'Information Text',
      text: 'Once the Owner approves your request, you will receive an email.',
    }).then(() => {
      console.debug('message box in component - closed');
    });
  }

  msgBox2() {
    this.$msgBox({
      type: 'warning',
      title: 'Warning Text',
      text: 'Once the Owner approves your request, you will receive an email.',
    }).then(() => {
      console.debug('message box in component - closed');
    });
  }

  msgBox3() {
    this.$msgBox({
      type: 'success',
      title: 'Success Text',
      text: 'Once the Owner approves your request, you will receive an email.',
      okText: 'Hell yeah!',
    }).then(() => {
      console.debug('message box in component - closed');
    });
  }

  msgBox4() {
    this.$msgBox({
      type: 'error',
      title: 'Error Text',
      text: 'Once the Owner approves your request, you will receive an email.',
    }).then(() => {
      console.debug('message box in component - closed');
    });
  }
}
