import CKEditorUtils from '@/core/utils/CKEditorUtils';
import ITextEditorConfig from '@/core/common/ITextEditorConfig';
import i18n from '@/core/plugins/vue-i18n';
import ConfigurationBuilder from './ckEditorConfigurationBuilder';
import { defaultFontStyle } from '@/core/services/export/pdf/PdfStyles';

class CommonEditorConfig implements ITextEditorConfig {
  getConfig() {
    const config = {
      licenseKey: 'yaUw+FEiVj15D0frcgRStsMKUUUNQsgRiIwg+QRYlmt8VEueARMMxckU',
      language: 'en-gb',
      removePlugins: [
        'Image',
        'ImageCaption',
        'ImageResize',
        'ImageStyle',
        'ImageToolbar',
        'ImageUpload',
        'MediaEmbed',
        'TextTransformation',
      ],
      heading: {
        options: [
          {
            model: 'customParagraph',
            view: {
              name: 'p',
              classes: 'jigsaw-heading_normal',
            },
            title: i18n.t('NORMAL'),
            class: 'jigsaw-heading_normal',
            // It needs to be converted before the standard paragraph
            converterPriority: 'high',
          },
          {
            model: 'heading1',
            view: { name: 'h1', classes: 'jigsaw-heading_heading1' },
            title: i18n.t('TITLE'),
            class: 'jigsaw-heading_heading1',
          },
          {
            model: 'heading2',
            view: { name: 'h2', classes: 'jigsaw-heading_heading2' },
            title: i18n.t('HEADING_2'),
            class: 'jigsaw-heading_heading2',
          },
          {
            model: 'heading3',
            view: { name: 'h3', classes: 'jigsaw-heading_heading3' },
            title: i18n.t('HEADING_3'),
            class: 'jigsaw-heading_heading3',
          },
          {
            model: 'heading4',
            view: { name: 'h4', classes: 'jigsaw-heading_heading4' },
            title: i18n.t('SUBTITLE'),
            class: 'jigsaw-heading_heading4',
          },
          {
            model: 'heading5',
            view: { name: 'h5', classes: 'jigsaw-heading_heading5' },
            title: i18n.t('SUBTITLE'),
            class: 'jigsaw-heading_heading5',
          },
          {
            model: 'heading6',
            view: { name: 'h6', classes: 'jigsaw-heading_heading6' },
            title: i18n.t('SUBTITLE'),
            class: 'jigsaw-heading_heading6',
          },
        ],
      },
      fontColor: {
        colors: [
          {
            color: '#ffffff',
            hasBorder: true,
          },
          {
            color: '#000000',
            hasBorder: true
          },
          {
            color: '#60558e',
            hasBorder: true
          },
          {
            color: '#737F92',
            hasBorder: true
          },
          {
            color: '#259daf',
            hasBorder: true
          },
          {
            color: '#35cda5',
            hasBorder: true
          },
          {
            color: '#a5de35',
            hasBorder: true
          },
          {
            color: '#e6d845',
            hasBorder: true
          },
          {
            color: '#c79434',
            hasBorder: true
          },
          {
            color: '#d24c1d',
            hasBorder: true
          },
          {
            color: '#794b4b',
            hasBorder: true
          },
        ],
      },
      fontBackgroundColor: {
        colors: [
          {
            color: '#ffffff',
            hasBorder: true
          },
          {
            color: '#000000',
            hasBorder: true
          },
          {
            color: '#60558e',
            hasBorder: true
          },
          {
            color: '#737F92',
            hasBorder: true
          },
          {
            color: '#259daf',
            hasBorder: true
          },
          {
            color: '#35cda5',
            hasBorder: true
          },
          {
            color: '#a5de35',
            hasBorder: true
          },
          {
            color: '#e6d845',
            hasBorder: true
          },
          {
            color: '#c79434',
            hasBorder: true
          },
          {
            color: '#d24c1d',
            hasBorder: true
          },
          {
            color: '#794b4b',
            hasBorder: true
          },
        ],
      },
      fontSize: {
        options: CKEditorUtils.generatePtSetting([
          8, 9, 10, 11, 12, 14, 16, 20, 24, 26, 36, 42, 48, 72,
        ]),
        supportAllValues: true,
      },
      fontFamily: {
        supportAllValues: false,
        options: [
          'Arial',
          'Arial Narrow',
          'Calibri',
          'Courier New',
          'Georgia',
          'Trebuchet MS',
          'Times New Roman',
          'Verdana',
        ],
      },
      customFontSize: {
        defaultLabel: defaultFontStyle.fontSize.toString(),
      },
    };

    return ConfigurationBuilder.configure(config);
  }
}

let instance = new CommonEditorConfig();
export default instance;
