import appConfig from '@/core/config/appConfig';
import { UserManager, UserManagerSettings } from 'oidc-client';
import UrlParamsHelper from './Utils/UrlParamsHelper';

/**
 * Receive iframe token renewal responses
 */
export class TokenRenewalResponseHandler {
  private readonly userManager: UserManager;

  public constructor() {
    const oidcConfig: UserManagerSettings = {
      authority: appConfig.authentication.authority,
      client_id: appConfig.authentication.clientId,
      client_secret: appConfig.authentication.clientSecret,
      monitorSession: false,
    };

    this.userManager = new UserManager(oidcConfig);
  }

  /**
   * Handle token renewal responses from the authorization server
   */
  public async handleSilentTokenRenewalResponse(): Promise<void> {
    console.debug('[AUTH_SERVICE] handleSilentTokenRenewalResponse');
    // If the page loads with a state query parameter we classify it as an OAuth response
    const state = UrlParamsHelper.getState();
    if (state) {
      console.debug('[AUTH_SERVICE] signinSilentCallback');
      // Start processing of the authorization response on the iframe
      // Any errors are reported via the authenticator class of the main window
      await this.userManager.signinSilentCallback();
    }
  }
}
