import {
  CreateOrEditDataPropertyDto,
  DataPropertyDefinitionDto,
  DataPropertyDefinitionItemDto,
  DataPropertyDto,
  DataPropertyType,
  DataPropertyValueScope,
  ThemeDataPropertyItemDto,
} from '@/api/models';
import store from '@/core/services/store';
import {
  DATAPROPERTYDEFINITIONS_NAMESPACE,
  GET_DATAPROPERTYDEFINITIONS,
} from '@/core/services/store/datapropertydefinitions.module';
import { cloneDeep } from 'lodash';
import { GraphComponent } from 'yfiles/typings/yfiles-api-npm';
import { INode, SvgVisual, SvgVisualGroup } from 'yfiles';
import DecorationStateManager from '../services/DecorationStateManager';
import JurisdictionDecorator, {
  JurisdictionDecorationState,
} from '../styles/decorators/JurisdictionDecorator';
import { measureText } from './html.utils';
import i18n from '@/core/plugins/vue-i18n';
import GraphElementsHashGenerator from './GraphElementsHashGenerator';

export enum StaticDataPropertyNames {
  Jurisdiction = 'JURISDICTION',
  State = 'STATE',
}

export default class DataPropertyUtils {
  public static applyThemeDataPropertyItemStyle(
    item: DataPropertyDefinitionItemDto,
    themeDataPropertyItems: ThemeDataPropertyItemDto[]
  ): DataPropertyDefinitionItemDto {
    let clonedItem = cloneDeep(item);
    if (themeDataPropertyItems) {
      const themeDataPropertyItemStyle = themeDataPropertyItems.find(
        (x) => x.dataPropertyDefinitionItemId == clonedItem.id
      );

      if (themeDataPropertyItemStyle) {
        clonedItem.itemValue = themeDataPropertyItemStyle.itemValue;
        clonedItem.imageData = themeDataPropertyItemStyle.imageData;
        clonedItem.customized = true;
      }
    }

    return clonedItem;
  }

  public static getDisplayValue(
    value: any,
    definition: DataPropertyDefinitionDto,
    fallbackValue = '...'
  ): string {
    const displayValueArray = DataPropertyUtils.getDisplayValueAsArray(
      value,
      definition
    );
    if (displayValueArray.length > 0) {
      return displayValueArray.join(', ');
    }
    return fallbackValue;
  }

  public static getDisplayValueAsArray(
    value: any,
    definition: DataPropertyDefinitionDto
  ): string[] {
    if (definition == null) {
      return [value];
    }
    let displayValue = null;

    let isOptionsControl = DataPropertyUtils.isOptionsControl(
      definition.controlType
    );
    let isMultiSelectControl = DataPropertyUtils.isMultiSelectControl(
      definition.controlType
    );
    if (isOptionsControl && value) {
      if (
        definition.controlType == DataPropertyType.Text ||
        definition.controlType == DataPropertyType.Number ||
        definition.controlType == DataPropertyType.Date ||
        definition.controlType == DataPropertyType.DateTime ||
        definition.controlType == DataPropertyType.Time
      ) {
        if (typeof value === 'boolean') {
          displayValue = i18n.t(value.toString().toUpperCase());
        } else {
          displayValue = value;
        }
      } else if (isMultiSelectControl) {
        let definitionValues = definition.dataPropertyDefinitionItems;
        if (Array.isArray(value)) {
          displayValue = value.map(
            (val) => definitionValues.find((x) => x.id == val).itemValue
          );
        } else {
          displayValue = definitionValues.find((x) => x.id == value)?.itemValue;
        }
      } else if (value) {
        displayValue = definition.dataPropertyDefinitionItems.find(
          (x) => x.id == value
        )?.itemValue;
      }
    } else {
      displayValue = value;
    }

    if (!displayValue) {
      return [];
    } else if (!Array.isArray(displayValue)) {
      return [displayValue];
    }
    return displayValue;
  }

  public static getDefinitionItemByDefinitionId(
    node: INode,
    definitionId: number
  ): DataPropertyDefinitionItemDto {
    let dp = node.tag.dataProperties.find(
      (dp) => dp.dataPropertyDefinitionId == definitionId
    );
    if (!dp) return null;

    let dpd = DataPropertyUtils.getDefinition(dp.dataPropertyDefinitionId);
    return dpd.dataPropertyDefinitionItems.find((i) => i.id == dp.value);
  }

  public static getDefinition(
    dataPropertyDefinitionId: number
  ): DataPropertyDefinitionDto {
    return DataPropertyUtils.getAllDefinitions()?.find(
      (d) => d.id == dataPropertyDefinitionId
    );
  }

  public static getAllDefinitions(): DataPropertyDefinitionDto[] {
    return store.getters[
      `${DATAPROPERTYDEFINITIONS_NAMESPACE}/${GET_DATAPROPERTYDEFINITIONS}`
    ];
  }

  public static hasChildren(
    dataPropertyDefinitionItem: DataPropertyDefinitionItemDto
  ): boolean {
    return this.getAllDefinitions().some((x) =>
      x.dataPropertyDefinitionItems.some(
        (p) => p.parentItemId == dataPropertyDefinitionItem.id
      )
    );
  }

  public static getChildren(
    dataPropertyDefinitionId: number,
    dataPropertyDefinitionItem: DataPropertyDefinitionItemDto
  ): DataPropertyDefinitionItemDto[] {
    const definition = this.getAllDefinitions().find(
      (x) => x.id == dataPropertyDefinitionId
    );
    if (!definition) {
      return [];
    }

    return definition.dataPropertyDefinitionItems.filter(
      (x) => x.parentItemId == dataPropertyDefinitionItem.id
    );
  }

  public static getDefinitionItemByDataPropertyName(
    node: INode,
    dataPropertyName: string
  ): DataPropertyDefinitionItemDto {
    let element = node.tag.dataProperties
      .map((dp) => {
        return {
          dp: dp,
          dpd: DataPropertyUtils.getDefinition(dp.dataPropertyDefinitionId),
        };
      })
      .find((x) => x.dpd?.label === dataPropertyName);

    if (element) {
      const definitionItem = element.dpd.dataPropertyDefinitionItems.find(
        (definitionItem) => definitionItem.id == element.dp.value
      );
      return definitionItem;
    }
    return null;
  }

  public static getValueByDataPropertyName(
    node: INode,
    dataPropertyName: string
  ): string {
    const dpDefinition = this.getDefinitionItemByDataPropertyName(
      node,
      dataPropertyName
    );
    if (dpDefinition) {
      return dpDefinition?.itemValue;
    }
    return null;
  }

  public static getImageByDataPropertyName(
    node: INode,
    dataPropertyName: string
  ): string {
    const dpDefinition = this.getDefinitionItemByDataPropertyName(
      node,
      dataPropertyName
    );
    if (dpDefinition) {
      return dpDefinition?.imageData;
    }
    return null;
  }

  public static getDataPropertyFromNode(
    node: INode,
    definitionId: number
  ): DataPropertyDto | CreateOrEditDataPropertyDto {
    const dataProperties = node.tag.dataProperties;
    if (!dataProperties || dataProperties.length == 0) {
      return null;
    }
    return dataProperties.find(
      (x) => x.dataPropertyDefinitionId == definitionId
    );
  }

  public static createStateInitialsCircleSvgVisual(
    stateInitials: string,
    size: number,
    fontSize: number, //originally 10,
    circleX: number, //originally 15
    circleY: number, //originally 9.5
    textOffsetX: number, // originally 2.25
    textOffsetY: number, // originally 4.5
    strokeWidth: number = 1
  ): SvgVisual {
    const circleSvg = JurisdictionDecorator.INSTANCE.createCircleSVG(
      size,
      strokeWidth
    );
    const stateInitialsSvg =
      JurisdictionDecorator.INSTANCE.createStateInitialsSVG(
        stateInitials,
        fontSize
      );

    const fontFamily = 'arial';
    const html = `<div style="font-size: ${fontSize}px;font-family:${fontFamily}">${stateInitials}</div>`;
    const textSize = measureText(html);

    SvgVisual.setTranslate(circleSvg, circleX, circleY);

    SvgVisual.setTranslate(
      stateInitialsSvg,
      circleX - textSize.width / textOffsetX,
      circleY + textSize.height / textOffsetY
    );

    const circleSvgVisual = new SvgVisual(circleSvg);
    const stateInitialsSvgVisual = new SvgVisual(stateInitialsSvg);

    const group = new SvgVisualGroup();
    group.add(circleSvgVisual);
    group.add(stateInitialsSvgVisual);

    return group;
  }

  public static isOptionsControl(type: DataPropertyType): boolean {
    switch (+type) {
      case DataPropertyType.Dropdown:
      case DataPropertyType.MultiOptions:
      case DataPropertyType.MultiSelect:
      case DataPropertyType.Options:
      case DataPropertyType.Text:
      case DataPropertyType.Number:
      case DataPropertyType.DateTime:
      case DataPropertyType.Time:
      case DataPropertyType.Date:
        return true;
    }
    return false;
  }

  public static isMultiSelectControl(type: DataPropertyType): boolean {
    switch (+type) {
      case DataPropertyType.MultiOptions:
      case DataPropertyType.MultiSelect:
        return true;
    }
    return false;
  }
  public static isDataPropertyDefinitionItemIncluded(
    dpCollection: DataPropertyDto[],
    dataPropertyDefinitionItemId: number,
    dataPropertyDefinitions: DataPropertyDefinitionDto[]
  ): boolean {
    let definitionItem: DataPropertyDefinitionItemDto = null;
    return dpCollection.some((dp) => {
      /* lookup datapropertydefinition */
      const dpd = dataPropertyDefinitions.find(
        (dpd) => dpd.id == dp.dataPropertyDefinitionId
      );
      /* when dp is not jurisdiction */
      if (typeof dp.value == 'boolean') {
        return (
          dpd.dataPropertyDefinitionItems[0].id == dataPropertyDefinitionItemId
        );
      } else {
        definitionItem = dpd.dataPropertyDefinitionItems.find(
          (i) => i.id == dp.value
        );
        return definitionItem.id == dataPropertyDefinitionItemId;
      }
    });
  }
}
