import {
  DragAction,
  DragDropEffects,
  DragDropItem,
  DragSource,
  GraphEditorInputMode,
  GraphItemTypes,
  IGraph,
  IInputModeContext,
  IModelItem,
  INode,
  ItemDropInputMode,
  Point,
  Rect,
  ShapeNodeShape,
  ShapeNodeStyle,
  Size,
  VoidNodeStyle,
} from 'yfiles';
import { addClass, removeClass } from '@/core/utils/html.utils';
import IPaletteBehaviour from '@/components/DiagramPalette/IPaletteBehaviour';
import Vue from 'vue';
import DiagramUtils from '@/core/utils/DiagramUtils';
import PaletteItem from './PaletteItem';
import { AnnotationType } from '@/core/common/AnnotationType';
import GraphService from '@/core/services/graph/graph.service';
import config from '@/core/config/diagram.definition.config';
import JigsawNodeStyle from '@/core/styles/JigsawNodeStyle';
import EdgeCreationButtonDecorator from '@/core/styles/decorators/EdgeCreationButtonDecorator';
import PaletteItemOffsetHelper from './PaletteItemOffsetHelper';
import {
  DashStyleType,
  NodeShape,
  NodeSize,
  NodeVisualType,
  ShapeNodeStyleDto,
} from '@/api/models';
import StyleCreator from '@/core/utils/StyleCreator';
import i18n from '../../core/plugins/vue-i18n';

export default class AnnotationPaletteBehaviour
  extends Vue
  implements IPaletteBehaviour
{
  private readonly _dragPreviewZIndex: number;
  private static _instance: AnnotationPaletteBehaviour = null;

  public static get INSTANCE(): AnnotationPaletteBehaviour {
    return (
      AnnotationPaletteBehaviour._instance ??
      (AnnotationPaletteBehaviour._instance = new AnnotationPaletteBehaviour())
    );
  }

  constructor() {
    super();
    this._dragPreviewZIndex = 10000;
  }

  annotationDropInputMode: AnnotationDropInputMode = null;

  insert(event, item, graphService: GraphService) {
    const offsetX = PaletteItemOffsetHelper.getOffsetX();
    const offsetY = PaletteItemOffsetHelper.getOffsetY();

    const itemPositionX =
      graphService.graphComponent.viewport.minX +
      graphService.graphComponent.viewport.width /
        config.offsetRightFromCanvasLeftBound.large +
      offsetX;

    const itemPositionY =
      graphService.graphComponent.viewport.centerY -
      graphService.graphComponent.viewport.height / 4 +
      offsetY;

    this.itemCreator(
      graphService.graphComponent.inputModeContext,
      graphService.graphComponent.graph,
      item,
      null,
      DiagramUtils.snapToNearestGridPoint(
        new Point(itemPositionX, itemPositionY)
      )
    );

    PaletteItemOffsetHelper.updatePalettItemDropOffset(
      graphService.graphComponent,
      config.paletteItemDropOffsetStep
    );
  }
  startDrag(event, item, graphService: GraphService) {
    const inputMode = graphService.graphComponent
      .inputMode as GraphEditorInputMode;

    if (this.annotationDropInputMode) {
      inputMode.remove(this.annotationDropInputMode);
    }

    this.annotationDropInputMode = new AnnotationDropInputMode();
    this.annotationDropInputMode.itemCreator = this.itemCreator.bind(this);

    inputMode.add(this.annotationDropInputMode);

    const dragPreview = document.createElement('div');
    dragPreview.setAttribute('id', 'draggable-theme');
    dragPreview.setAttribute('style', `z-index:${this._dragPreviewZIndex}`);

    dragPreview.appendChild(event.target.cloneNode(true));

    let dragSource = new DragSource(dragPreview);

    dragSource.startDrag(
      new DragDropItem(INode.$class.name, item),
      DragDropEffects.ALL,
      true,
      dragPreview
    );

    dragSource.addQueryContinueDragListener((src, args) => {
      if (args.dropTarget === null) {
        removeClass(dragPreview, 'd-none');
      } else {
        addClass(dragPreview, 'd-none');
      }
    });
  }

  private itemCreator(
    context: IInputModeContext,
    graph: IGraph,
    draggedItem: any,
    dropTarget: IModelItem,
    dropLocation: Point
  ): PaletteItem {
    const size = new Size(40, 40);

    const nodeStlyeDto = new ShapeNodeStyleDto(
      { color: 'TRANSPARENT' },
      {
        thickness: 1,
        dashStyle: {
          type: DashStyleType.Solid,
        },
        fill: {
          color: 'TRANSPARENT',
        },
      },
      NodeShape.Rectangle,
      null,
      NodeVisualType.Shape
    );

    const style = StyleCreator.createNodeStyle(nodeStlyeDto, [
      EdgeCreationButtonDecorator.INSTANCE,
    ]);

    dropLocation = DiagramUtils.snapToNearestGridPoint(
      new Point(dropLocation.x, dropLocation.y)
    );

    let layout = new Rect(
      dropLocation?.x ?? 0,
      dropLocation?.y ?? 0,
      size.width,
      size.height
    );

    const tag = DiagramUtils.createNewNodeTag(null, null);
    tag.isAnnotation = true;
    tag.annotationType = AnnotationType.Text;
    tag.labelIsPlaceholder = true;
    let node = graph.createNodeAt(layout, style, tag);
    DiagramUtils.setLabelValue(graph, node, `[${i18n.t('ADD_TEXT_ELLIPSES')}]`);

    const inputMode = context.canvasComponent.inputMode as GraphEditorInputMode;
    if (this.annotationDropInputMode) {
      inputMode.remove(this.annotationDropInputMode);
    }

    (async () => {
      // Needs to happen asynchronously to allow drop to be finalised before entering edit mode
      inputMode.editLabel(node.labels.first());
      inputMode.setSelected(node, true);
    })();
    return draggedItem;
  }
}

class AnnotationDropInputMode extends ItemDropInputMode<PaletteItem> {
  private previewNodeOffset = new Point(0, -18);
  constructor() {
    super(INode.$class.name);
  }

  /**
   * @returns {IEdge}
   */
  // @ts-ignore
  get draggedItem() {
    return this.dropData;
  }

  /**
   * @param {Point} dragLocation - The location to return the drop target for.
   * @returns {IModelItem}
   */
  getDropTarget(dragLocation) {
    const parentMode = this.inputModeContext.parentInputMode;
    if (parentMode instanceof GraphEditorInputMode) {
      const hitItems = parentMode.findItems(dragLocation, [
        GraphItemTypes.NODE,
        GraphItemTypes.EDGE,
      ]);
      if (hitItems.size > 0) {
        return hitItems.first();
      }
    }
    return null;
  }

  initializePreview() {
    if (this.showPreview && this.draggedItem) {
      super.initializePreview();
    }
  }

  /**
   * @param {IGraph} previewGraph - The preview graph to fill.
   */
  populatePreviewGraph(previewGraph: IGraph) {
    const graph = previewGraph;

    const item = this.dropData as PaletteItem;

    const source = graph.createNode(
      new Rect(-100, 100, 10, 10),
      VoidNodeStyle.INSTANCE
    );
    graph.addLabel(source, `[${i18n.t('ADD_TEXT_ELLIPSES')}]`);
  }

  /**
   * @param {IGraph} previewGraph - The preview graph to update.
   * @param {Point} dragLocation - The current drag location.
   */
  updatePreview(previewGraph: IGraph, dragLocation: Point) {
    previewGraph.setNodeCenter(
      previewGraph.nodes.elementAt(0),
      dragLocation.add(this.previewNodeOffset)
    );
    if (this.inputModeContext.canvasComponent !== null) {
      this.inputModeContext.canvasComponent.invalidate();
    }
  }
}
