


























import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'Checkboxes',
})
export default class Checkboxes extends Vue {
  checked = false;
  multiple = ['4'];
}
