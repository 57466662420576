import IDisposable from '@/core/common/IDisposable';
import { Mapper } from 'yfiles';
import { CommandHandlerType } from './ComandHandlerType';
import { CommandType } from './CommandType';
import ICommandHandler from './ICommandHandler';
import ICommandProvider from './ICommandProvider';

export default class CommandManager implements IDisposable {
  isDisposed: boolean;
  private providers: Mapper<string, ICommandProvider>;
  private focusedHandler: ICommandHandler;

  public static INSTANCE: CommandManager = new CommandManager();

  /**
   *
   */
  constructor() {
    this.providers = new Mapper<string, ICommandProvider>();
  }

  public getFocusedHandlerType(): CommandHandlerType {
    if (!this.focusedHandler) {
      return null;
    }
    return this.focusedHandler.getCommandHandlerType();
  }

  public detach() {}

  public attachProvider(provider: ICommandProvider) {
    this.providers.set(provider.provider, provider);
  }

  public setFocus(focusedHandler: ICommandHandler) {
    if (!focusedHandler) {
      throw Error('FocusHandler should not be null');
    }
    focusedHandler.publishAllValues();

    if (this.focusedHandler == focusedHandler) {
      return;
    }

    this.focusedHandler = focusedHandler;
    this.providers.entries.forEach((p) => {
      p.value.refocused();
    });
  }

  public unfocus() {
    this.providers.entries.forEach((p) => {
      p.value.refocused(true);
    });
    this.focusedHandler = null;
  }

  public isEnabled(command: CommandType, enabled) {
    this.providers.entries.forEach((p) => {
      p.value.isEnabled(command, enabled);
    });
  }

  // Talks to the Providers
  public managerValueChanged(command: CommandType, value: any) {
    //console.log('managerValueChanged:' + CommandType[command] + ':' + value); // << KEEP
    this.providers.entries.forEach((p) => {
      p.value.valueChanged(command, value);
    });
  }

  // Talks to the currently selected manager
  public providerValueChanged(command: CommandType, value: any) {
    // Notify The Currently selected Manager ...
    if (this.focusedHandler) {
      //  console.log('providerValueChanged:' + CommandType[command] + ':' + value); //<< KEEP
      this.focusedHandler.executeCommand(command, value);
    }
  }

  dispose(): void {
    this.providers.clear();
    // Clean up ...
  }
}
