import { toJpeg } from 'html-to-image';
import { htmlToElement } from '@/core/utils/html.utils';
import ExportConfig from '@/core/config/ExportConfig';
import { Size } from 'yfiles';
import {
  DiagramPosition,
  DocumentPageContentType,
  DocumentPageType,
  HeaderFooterStyleDto,
} from '@/api/models';
import ElementSerializer from '@/components/DraggableElements/ElementSerializer';
import IItem from '@/components/DraggableElements/IItem';
import ExportUtils from '../ExportUtils';
import ThumbnailStyles from './ThumbnailStyles';
import { convertUrlToDataUrl } from '@/core/utils/common.utils';
import HtmlStyles from '../HtmlStyles';

export default class ThumbnailBuilder {
  public content: string;
  public graphSvg: string;
  public pageBackground: string;
  public headerHtml: string;
  public footerHtml: string;
  public headerStyle: HeaderFooterStyleDto;
  public footerStyle: HeaderFooterStyleDto;
  public headerDividerIsImage: boolean;
  public footerDividerIsImage: boolean;
  public headerBackground: string;
  public footerBackground: string;
  public logo: string;
  public pageType: DocumentPageType;
  public contentType: DocumentPageContentType;
  public contentColumns: number;
  public diagramPosition: DiagramPosition;
  public includeHeader = true;
  public includeFooter = true;

  private get bodySize(): Size {
    const size = ExportUtils.calculatePageBodySize(this.pageType);
    return new Size(
      Math.ceil(size.width),
      Math.ceil(
        size.height -
          ExportConfig.pageBreakThreshold / ExportConfig.upscaleMultiplier
      )
    );
  }

  private addStyles(el: HTMLElement): void {
    for (const key in HtmlStyles) {
      const elements = el.querySelectorAll(`.${key}`);
      for (const element of elements) {
        if (!(element instanceof HTMLElement)) {
          continue;
        }
        for (const valueProperty in HtmlStyles[key]) {
          const cssPropertyName = valueProperty;
          const cssPropertyValue = HtmlStyles[key][cssPropertyName];
          element.style[cssPropertyName] = cssPropertyValue;
        }
      }
    }
  }

  public async getThumbnail(): Promise<string> {
    const html = await this.buildPageHtml();
    const element = htmlToElement(html);
    this.addStyles(element);

    const pageSizePixels = new Size(
      ExportConfig.pageSize.width * ExportConfig.upscaleMultiplier,
      ExportConfig.pageSize.height * ExportConfig.upscaleMultiplier
    );

    const dataUrl = await toJpeg(element, {
      quality: 0.9,
      width: pageSizePixels.width,
      height: pageSizePixels.height,
      canvasWidth: pageSizePixels.width * ExportConfig.thumbScale,
      canvasHeight: pageSizePixels.height * ExportConfig.thumbScale,
      backgroundColor: 'white',
      style: {
        lineHeight: 'normal',
        fontSize: '12pt',
        fontFamily: 'Arial, sans-serif',
        overflowWrap: 'break-word',
      },
    });
    return dataUrl;
  }

  private buildPageHtml = async (): Promise<string> => {
    const pageImage = this.pageBackground
      ? await convertUrlToDataUrl(this.pageBackground)
      : null;

    return `
      <div style="
      background-image: url(${
        pageImage && this.contentType !== DocumentPageContentType.Layout
          ? pageImage
          : ''
      });
        background-size: 100% 100%;
        width: ${ExportConfig.pageSize.width}pt;
        height: ${ExportConfig.pageSize.height}pt;"
      >
        <style>${this.defaultStyles()}</style>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
          "
        >
          ${this.includeHeader ? await this.buildHeader() : ''}
          ${await this.buildBody()}
          ${this.includeFooter ? await this.buildFooter() : ''}
        </div>
      </div>`;
  };

  private buildBody = async (): Promise<string> => {
    switch (this.pageType) {
      case DocumentPageType.Split:
        return this.buildBodySplit();
      case DocumentPageType.Content:
        return await this.buildBodyContent();
      case DocumentPageType.Diagram:
        return this.buildBodyDiagram();
    }
  };

  private buildBodySplit = (): string => {
    const flexDirection =
      this.diagramPosition == DiagramPosition.Left ? 'row' : 'row-reverse';
    const diagramAligment =
      this.diagramPosition == DiagramPosition.Left ? 'left' : 'right';
    const diagramPadding =
      this.diagramPosition == DiagramPosition.Left
        ? `padding-left: ${ExportConfig.pageMargins.left / 2}pt`
        : `padding-right: ${ExportConfig.pageMargins.left / 2}pt`;

    return `
    <div style="
      display: flex;
      flex-direction: ${flexDirection};
      justify-content: space-between;
      padding-left: ${ExportConfig.pageMargins.left}pt;
      padding-right: ${ExportConfig.pageMargins.right}pt;
    ">
      <div style="
        height: ${this.bodySize.height}pt;
        width: calc(50% - ${ExportConfig.pageMargins.right / 2}pt);
        overflow: hidden;
        text-align: ${diagramAligment};
        ${diagramPadding}
      ">
        ${this.buildGraphSvg(true)}
      </div>
      <div style="
        height: ${this.bodySize.height}pt;
        width: calc(50% - ${ExportConfig.pageMargins.left / 2}pt);
        overflow: hidden;
      ">
        ${this.content ?? ''}
      </div>
    </div>`;
  };

  private buildBodyContent = async (): Promise<string> => {
    if (this.contentType == DocumentPageContentType.Layout) {
      const items = JSON.parse(this.content) as IItem[];
      const html = await ElementSerializer.serializeToHtml(items);
      return `
        <div style="
            transform: scale(${ExportConfig.upscaleMultiplier});
            transform-origin: left top;
        ">
          ${html}
        </div>`;
    } else {
      const layout = this.contentColumns == 2 ? 'two-column-layout' : '';
      return `
        <div style="
          padding-left: ${ExportConfig.pageMargins.left}pt;
          padding-right: ${ExportConfig.pageMargins.right}pt;
        ">
          <div class="${layout}" style="
            height: ${this.bodySize.height}pt;
            width: ${this.bodySize.width}pt;
            overflow: hidden
          ">
            ${this.content ?? ''}
          </div>
        </div>`;
    }
  };

  private buildBodyDiagram = (): string => {
    return `
    <div style="
      display: flex;
      padding-left: ${ExportConfig.pageMargins.left}pt;
      padding-right: ${ExportConfig.pageMargins.right}pt;
    ">
      <div style="
        height: ${this.bodySize.height}pt;
        width: ${this.bodySize.width}pt;
        overflow: hidden
      ">
        ${this.buildGraphSvg(false)}
      </div>
    </div>`;
  };

  private async getLogo(): Promise<string> {
    if (!this.logo || this.headerBackground || this.pageBackground) {
      return Promise.resolve(ExportConfig.blankImageUrl);
    }

    return await convertUrlToDataUrl(this.logo);
  }

  private buildGraphSvg = (padDiagram: boolean): string => {
    let svg: string = '';
    if (this.graphSvg) {
      const svgElement = htmlToElement(this.graphSvg);
      svgElement.removeAttribute('width');
      svgElement.removeAttribute('height');

      const svgSize = new Size(
        this.bodySize.width -
          (padDiagram ? ExportConfig.diagramHorizontalPadding : 0),
        this.bodySize.height
      );

      svgElement.style.width = svgSize.width.toFixed() + 'pt';
      svgElement.style.height = svgSize.height.toFixed() + 'pt';

      svg = svgElement.outerHTML;
    }
    return svg;
  };

  private buildHeader = async (): Promise<string> => {
    if (this.contentType == DocumentPageContentType.Layout) {
      return '';
    }

    const logoImage = await this.getLogo();
    const headerImage = this.headerBackground
      ? await convertUrlToDataUrl(this.headerBackground)
      : null;

    const headerHeight =
      ExportConfig.headerHeight - (this.headerStyle?.borderWidth ?? 0) * 2;

    let html = `
      <div style="
        width: 100%;
        height: ${headerHeight}pt;
        overflow: hidden;
        background-image: url(${headerImage ?? ''});
        background-size: 100% 100%;
        background-color: ${this.headerStyle?.backgroundColor};
        border-color: ${this.headerStyle?.borderColor};
        border-width: ${this.headerStyle?.borderWidth ?? 0}pt;
        border-style: ${this.headerStyle?.borderStyle};
        position: relative;
        flex-shrink: 0;
      ">
        <table>
          <tr>
            <td>
              <img src="${logoImage}" style="
                height: ${ExportConfig.headerHeight}pt;
                padding-right: 10pt"
              >
            </td>
            <td style="width: 100%; vertical-align: middle;">${
              this.headerHtml ?? ''
            }</td>
          </tr>
        </table>
      </div>`;

    if (this.headerStyle?.dividerHeight > 0) {
      html = `
          ${html}
          <div style="
              width: 100%;
              height: ${this.headerStyle.dividerHeight}pt;
              top: ${
                headerHeight +
                ExportConfig.dividerMargin +
                (this.headerStyle?.borderWidth ?? 0) * 2
              }pt;
              position: absolute;
              overflow: hidden;
              background-color: ${
                this.headerDividerIsImage
                  ? ExportConfig.dividerDefaultColor
                  : this.headerStyle.dividerColor
              };
            ">
          </div>
        `;
    }

    return html;
  };

  private buildFooter = async (): Promise<string> => {
    if (this.contentType == DocumentPageContentType.Layout) {
      return '';
    }

    const footerImage = this.footerBackground
      ? await convertUrlToDataUrl(this.footerBackground)
      : null;

    const footerHeight =
      ExportConfig.footerHeight - (this.footerStyle?.borderWidth ?? 0) * 2;

    let html = `
      <div style="
        width: 100%;
        height: ${footerHeight}pt;
        background-image: url(${footerImage ?? ''});
        background-size: 100% 100%;
        background-color: ${this.footerStyle?.backgroundColor};
        border-color: ${this.footerStyle?.borderColor};
        border-width: ${this.footerStyle?.borderWidth ?? 0}pt;
        border-style: ${this.footerStyle?.borderStyle};
        overflow: hidden;
        display: flex;
        align-items: center;
        flex-shrink: 0;
      ">
        <div style="width: 100%; vertical-align: middle; margin-left: 15pt; margin-right: 15pt">
          ${this.footerHtml ?? ''}
        </div>
      </div>`;

    if (this.footerStyle?.dividerHeight > 0) {
      html = `
            <div style="
                width: 100%;
                height: ${this.footerStyle.dividerHeight}pt;
                position: absolute;
                bottom: ${
                  footerHeight +
                  ExportConfig.dividerMargin +
                  (this.footerStyle?.borderWidth ?? 0) * 2
                }pt;
                overflow: hidden;
                background-color: ${
                  this.footerDividerIsImage
                    ? ExportConfig.dividerDefaultColor
                    : this.footerStyle.dividerColor
                };
              ">
            </div>
            ${html}
          `;
    }

    return html;
  };

  private defaultStyles = (): string => {
    return ThumbnailStyles;
  };
}
