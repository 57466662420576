// This needs to be kept in sync with styles for the document page content (see _page-content.scss) and thumbnail generation (ThumbnailStyles.ts, ThumbnailBuilder.ts)
// IMPORTANT: All sizes are in points (pt), 1px = 0.75pt

import ExportConfig from '@/core/config/ExportConfig';

export const defaultPageStyle = {
  b: { bold: true },
  strong: { bold: true },
  u: { decoration: 'underline' },
  s: { decoration: 'lineThrough' },
  em: { italics: true },
  i: { italics: true },
  h1: { fontSize: 24, bold: false, marginBottom: 5, lineHeight: 1.04 },
  h2: { fontSize: 22, bold: false, marginBottom: 5, lineHeight: 1.04 },
  h3: { fontSize: 20, bold: false, marginBottom: 5, lineHeight: 1.04 },
  h4: { fontSize: 18, bold: false, marginBottom: 5, lineHeight: 1.04 },
  h5: { fontSize: 16, bold: false, marginBottom: 5 },
  h6: { fontSize: 14, bold: false, marginBottom: 5 },
  a: { color: 'blue', decoration: 'underline' },
  strike: { decoration: 'lineThrough' },
  p: { margin: '', marginTop: 0, marginBottom: 14 },
  ul: { marginBottom: 5 },
  ol: { marginBottom: 5 },
  li: { marginLeft: 5 },
  table: { marginBottom: 5 },
  th: { bold: true, fillColor: '#EEEEEE' },
};

export const defaultFontStyle = {
  font: 'Arial',
  fontSize: 12,
  lineHeight: 1.005,
};

export const headerFooterStyle = Object.assign(
  Object.assign({}, defaultPageStyle),
  {
    p: { margin: '', marginTop: 0, marginBottom: 0 },
    table: { marginBottom: ExportConfig.dividerMargin },
  }
);
export const textElementStyle = Object.assign(
  Object.assign({}, defaultPageStyle),
  {
    p: { margin: '', marginTop: 0, marginBottom: 0 },
    table: { marginBottom: 0 },
  }
);


