export enum DataPropertyDisplayType {
  NodeLabel,
  Decorator,
}

export enum DataPropertyDisplayTypeNames {
  Jurisdiction = 'jurisdiction',
  State = 'state',
  Role = 'role'
}
