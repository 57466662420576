import {
  DiagramPosition,
  DocumentPageContentType,
  DocumentPageType,
  HeaderFooterStyleDto,
  PageElementPosition,
} from '@/api/models';
import {
  elementTypes,
  ImageMeta,
  TextMeta,
} from '@/components/DraggableElements/Components';
import IItem from '@/components/DraggableElements/IItem';
import ExportConfig from '@/core/config/ExportConfig';
import * as htmlToPdfmake from '@jigsaw/html-to-pdfmake/index';
import { Size } from 'yfiles';
import PdfMakeUtils from '@/core/services/export/pdf/PdfMakeUtils';
import {
  convertUrlToDataUrl,
  ensureFullUri,
  fitRectIntoBounds,
  getImageSize,
  scaleAndCropToBounds,
} from '@/core/utils/common.utils';
import {
  defaultFontStyle,
  defaultPageStyle,
  headerFooterStyle,
  textElementStyle,
} from './PdfStyles';
import HtmlStyles from '@/core/services/export/HtmlStyles';
import ContentPagination from '../ContentPagination';
import ExportUtils from '../ExportUtils';
import PdfExportSubPage from './PdfExportSubPage';
import Point from '@/core/common/Point';

export default class PdfPageBuilder {
  public content: string;
  public pageBackground: string;
  public pageBackgroundImage: string;
  public headerBackground: string;
  public headerBackgroundImage: string;
  public footerBackground: string;
  public footerBackgroundImage: string;
  public logo: string;
  public logoImage: string;
  public logoImageSize: Size;
  public headerDivider: string;
  public headerDividerImage: string;
  public footerDivider: string;
  public footerDividerImage: string;
  public legendPosition: PageElementPosition | Point;
  public startingPageNumber = 1;
  public pageType: DocumentPageType;
  public contentType: DocumentPageContentType;
  public headerStyle: HeaderFooterStyleDto;
  public footerStyle: HeaderFooterStyleDto;
  public contentColumns: number;
  public diagramPosition: DiagramPosition;
  public includeHeader = true;
  public includeFooter = true;
  public includePageNumber = true;
  public logoMargin: number;
  // subpage elements which can be different for each subpage
  public subPagesData: PdfExportSubPage[] = [];
  // legendScale needed to scale the legend in correct way
  public legendScale: number = 0.75;

  public async getPageDefinition(): Promise<Object> {
    await this.loadImages();
    let bodyDefinition = await this.getBodyDefinition();
    let backgroundDefinition = await this.getBackgroundDefinition();
    const pageMargins = this.getPageMargins();

    const pageDefinition = {
      content: bodyDefinition,
      header: this.includeHeader ? this.buildHeader : null,
      footer: this.includeFooter ? this.buildFooter : null,
      pageSize: 'A4',
      pageOrientation: ExportConfig.orientation,
      pageMargins: pageMargins,
      styles: HtmlStyles,
      defaultStyle: defaultFontStyle,
      pageBreakBefore: function (
        currentNode,
        followingNodesOnPage,
        nodesOnNextPage,
        previousNodesOnPage
      ) {
        return currentNode.pageBreak;
      },
      background: backgroundDefinition,
    };
    return pageDefinition;
  }

  private getPageMargins(): Array<number> {
    if (this.contentType == DocumentPageContentType.Layout) {
      return [0, 0, 0, 0];
    }
    return [
      ExportConfig.pageMargins.left,
      ExportConfig.pageMargins.top,
      ExportConfig.pageMargins.right,
      ExportConfig.pageMargins.bottom,
    ];
  }

  private async getBodyDefinition(): Promise<Object> {
    switch (this.contentType) {
      case DocumentPageContentType.None:
      case DocumentPageContentType.Html:
        let pageHtml = this.buildPageHtml();
        pageHtml = PdfMakeUtils.ensureValidHtml(pageHtml);
        return htmlToPdfmake(pageHtml, {
          tableAutoSize: true,
          defaultStyles: defaultPageStyle,
        });
      case DocumentPageContentType.Layout:
        return await this.getBodyTemplateDefinition();
    }
  }

  private async getBackgroundDefinition(): Promise<Object> {
    switch (this.contentType) {
      case DocumentPageContentType.Layout:
        return await this.getTemplateBackgroundDefinition();
      case DocumentPageContentType.None:
      case DocumentPageContentType.Html:
        return await this.getHtmlBackgroundDefinition();
    }
    return null;
  }

  private async getTemplateBackgroundDefinition(): Promise<Object> {
    const items = JSON.parse(this.content) as IItem[];
    const imageItem = items.find(
      (item) => item.type === elementTypes.ImageElement
    );
    let itemDefinition: any = null;
    if (imageItem) {
      let imageSrc = ensureFullUri((imageItem.meta as ImageMeta).imageSrc);
      itemDefinition = {
        nodeName: 'DIV',
        style: ['html-div'],
        image: await convertUrlToDataUrl(imageSrc),
      } as any;

      var size = await getImageSize(imageSrc);
      var newSize = scaleAndCropToBounds(size, ExportConfig.pageSize);

      itemDefinition.width = newSize.width;
      itemDefinition.height = newSize.height;

      itemDefinition.absolutePosition = {
        x: imageItem.layout.x,
        y: imageItem.layout.y,
      };
    }
    return itemDefinition;
  }

  private async getHtmlBackgroundDefinition(): Promise<Object> {
    const items = [];
    if (this.pageBackgroundImage) {
      items.push({
        image: this.pageBackgroundImage,
        width: ExportConfig.pageSize.width,
        height: ExportConfig.pageSize.height,
        absolutePosition: {
          x: 0,
          y: 0,
        },
      });
    }
    if (this.headerBackgroundImage) {
      items.push({
        image: this.headerBackgroundImage,
        width: ExportConfig.pageSize.width,
        height: ExportConfig.headerHeight,
        absolutePosition: {
          x: 0,
          y: 0,
        },
      });
    }
    if (this.footerBackgroundImage) {
      items.push({
        image: this.footerBackgroundImage,
        width: ExportConfig.pageSize.width,
        height: ExportConfig.footerHeight,
        absolutePosition: {
          x: 0,
          y: ExportConfig.pageSize.height - ExportConfig.footerHeight,
        },
      });
    }
    return items;
  }

  private async getBodyTemplateDefinition(): Promise<Array<any>> {
    const templateDefinition = [];
    let items = JSON.parse(this.content) as IItem[];
    items = items.filter((item) => item.type !== elementTypes.ImageElement);
    for (let item of items) {
      let itemDefinition: any = {};
      if (item.type == elementTypes.TextElement) {
        let html = this.getTextElementHtml(item);
        html = PdfMakeUtils.ensureValidHtml(html);
        const tableAttr = PdfMakeUtils.createDataAttribute({
          layout: 'textElement',
        });

        itemDefinition = htmlToPdfmake(
          `
          <table ${tableAttr} style="max-height: ${item.layout.height}">
            <tr>
              <td>
              ${html}
            </td>
            </tr>
          </table>
          `,
          {
            tableAutoSize: true,
            defaultStyles: textElementStyle,
            dontBreakRows: true,
            unbreakable: true,
          }
        )[0];

        const headerTable = itemDefinition.table;
        headerTable.widths = [item.layout.width];
        headerTable.heights = [item.layout.height];
      }
      itemDefinition.width = item.layout.width;
      itemDefinition.height = item.layout.height;
      itemDefinition.absolutePosition = {
        x: item.layout.x,
        y: item.layout.y,
      };
      templateDefinition.push(itemDefinition);
    }

    return templateDefinition;
  }

  private getTextElementHtml(item: IItem): string {
    let container = document.createElement('div');
    let element: HTMLElement = null;
    const div = document.createElement('div');
    div.innerHTML = (item.meta as TextMeta).html;
    element = div;
    container.appendChild(element);
    return container.outerHTML;
  }

  private buildPageHtml = (): string => {
    switch (this.pageType) {
      case DocumentPageType.Split:
      case DocumentPageType.Content:
        const contentSubPages = [];
        const chunks = ContentPagination.splitPagedContentIntoPages(
          this.content
        );

        for (let i = 0; i < chunks.length; i++) {
          const chunkHtml = chunks[i];
          const pageBreakAttr =
            i == 0 ? '' : PdfMakeUtils.createDataAttribute({ pageBreak: true });

          if (this.pageType == DocumentPageType.Split) {
            const left =
              this.diagramPosition == DiagramPosition.Left
                ? this.buildGraphContent(i)
                : chunkHtml;
            const right =
              this.diagramPosition == DiagramPosition.Left
                ? chunkHtml
                : this.buildGraphContent(i);

            contentSubPages.push(`
              <div ${pageBreakAttr}>
                <table>
                  <tr>
                    <td style="border: none; width: 50%">
                      <div>
                        ${left}
                      </div>
                    </td>
                    <td style="border: none; width: 50%">
                      <div>
                        ${right}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>`);
          } else {
            if (this.contentColumns > 0) {
              const columnsHtml = ContentPagination.splitPageIntoColumns(
                chunkHtml,
                this.contentColumns,
                this.pageType
              );
              contentSubPages.push(`
                <div ${pageBreakAttr}>
                  <table>
                    <tr>
                      <td style="border: none; width: 50%">
                        <div>
                          ${columnsHtml[0] ?? ''}
                        </div>
                      </td>
                      <td style="border: none; width: 50%">
                        <div>
                          ${columnsHtml[1] ?? ''}
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>`);
            } else {
              contentSubPages.push(`
                <div ${pageBreakAttr}>
                  ${chunkHtml}
                </div>`);
            }
          }
        }
        return contentSubPages.join('\n');
      case DocumentPageType.Diagram:
        return `
          <div>
            ${this.buildGraphContent(0)}
          </div>`;
    }
  };

  private buildGraphContent = (subPageIndex: number): string => {
    const subpage = this.subPagesData.find(
      (d) => d.subPageIndex === subPageIndex
    );
    let graphSvg = subpage?.graphSvg;
    if (graphSvg) {
      // This fixes issue with parts of bold text sliding up
      graphSvg = graphSvg.replaceAll(
        'dominant-baseline="text-after-edge"',
        'dominant-baseline="auto"'
      );
      // Set different baseline-shift to type label
      graphSvg = graphSvg.replaceAll(
        'data-label-baseline-shift',
        'baseline-shift'
      );

      const diagramSize = ExportUtils.calculateDiagramSize(
        this.pageType
      ).toMutableSize();

      // Scale legend and leave some space for it by reducing diagram height
      const legend = subpage?.legend;
      let legendWidth = 0;
      let legendHeight = 0;
      let isLegendAtTopPosition = false;
      if (legend) {
        legendWidth = parseFloat(legend.getAttribute('width'));
        legendHeight = parseFloat(legend.getAttribute('height'));

        if (<PageElementPosition>this.legendPosition in PageElementPosition) {
          isLegendAtTopPosition =
            this.legendPosition == PageElementPosition.TopRight ||
            this.legendPosition == PageElementPosition.TopLeft;
        }

        if (!legend.hasAttribute('scaled')) {
          legendWidth *= this.legendScale;
          legendHeight *= this.legendScale;

          legend.setAttribute('scaled', 'true');
          legend.setAttribute('width', legendWidth.toString());
          legend.setAttribute('height', legendHeight.toString());
        }
        if (<PageElementPosition>this.legendPosition in PageElementPosition) {
          diagramSize.height = diagramSize.height - (legendHeight - 20);
        }

        if (this.footerStyle?.dividerHeight) {
          diagramSize.height -= this.footerStyle.dividerHeight;
        }
      }

      let graphContent = graphSvg;
      let diagramScale = new Point(1, 1);
      let fittedSvgSize = diagramSize.toSize();

      // Centre diagram on the page and pad if necessary
      const match = graphSvg.match(/width="([\d.]+)"\s+height="([\d.]+)"/);
      if (match && match.length == 3) {
        const svgSize = new Size(parseInt(match[1]), parseInt(match[2]));
        fittedSvgSize = fitRectIntoBounds(svgSize, fittedSvgSize);
        diagramScale = new Point(
          fittedSvgSize.width / svgSize.width,
          fittedSvgSize.height / svgSize.height
        );

        let diagramHorizontalOffset =
          (diagramSize.width - fittedSvgSize.width) / 2;
        if (diagramHorizontalOffset < 0) diagramHorizontalOffset = 0;
        let diagramVerticalOffset =
          (diagramSize.height - fittedSvgSize.height) / 2;
        if (diagramVerticalOffset < 0) diagramVerticalOffset = 0;
        if (
          this.pageType == DocumentPageType.Split &&
          this.diagramPosition == DiagramPosition.Right
        ) {
          diagramHorizontalOffset += ExportConfig.diagramHorizontalPadding;
        }
        if (isLegendAtTopPosition) {
          diagramVerticalOffset += legendHeight + 20;
        }
        const relAttr = PdfMakeUtils.createDataAttribute({
          relativePosition: {
            x: diagramHorizontalOffset,
            y: diagramVerticalOffset,
          },
        });

        graphContent = [
          graphSvg.slice(0, 4),
          ` width="${fittedSvgSize.width}" height="${fittedSvgSize.height}" `,
          graphSvg.slice(4),
        ].join('');

        graphContent = `
            <div ${relAttr}>
              ${graphContent}
            </div>`;
      }

      if (legend) {
        // Append legend element below the diagram using relative position
        const legendWidth = parseFloat(legend.getAttribute('width'));
        const legendSvg = legend.outerHTML;
        let horizontalOffset = 0;
        let verticalOffset = 0;
        if (<PageElementPosition>this.legendPosition in PageElementPosition) {
          horizontalOffset =
            this.legendPosition == PageElementPosition.BottomRight ||
            this.legendPosition == PageElementPosition.TopRight
              ? diagramSize.width - legendWidth
              : 0;
          verticalOffset = isLegendAtTopPosition ? 0 : diagramSize.height;
        } else {
          horizontalOffset =
            diagramSize.width * (<Point>this.legendPosition).x * 0.01;
          verticalOffset =
            diagramSize.height * (<Point>this.legendPosition).y * 0.01;
        }

        // for diagram only it will be also "DiagramPosition.Right"
        // then we need to include it whenever it is Right position
        if (
          this.pageType == DocumentPageType.Split &&
          this.diagramPosition == DiagramPosition.Right
        ) {
          horizontalOffset += ExportConfig.diagramHorizontalPadding;
        }

        const relAttr = PdfMakeUtils.createDataAttribute({
          relativePosition: {
            x: horizontalOffset,
            y: verticalOffset,
          },
        });

        if (isLegendAtTopPosition) {
          return `
            <div ${relAttr}>
              ${legendSvg}
            </div>
            <div>
              ${graphContent}
            </div>
          `;
        }
        return `
          <div>
            ${graphContent}
          </div>
          <div ${relAttr}>
            ${legendSvg}
          </div>
        `;
      } else {
        return graphContent;
      }
    } else {
      return '<svg></svg>';
    }
  };

  private buildHeader = (currentPage, pageCount, pageSize): Promise<Object> => {
    if (this.contentType == DocumentPageContentType.Layout) {
      return null;
    }

    const subPageIndex = currentPage - 1;
    const subpage = this.subPagesData.find(
      (d) => d.subPageIndex === subPageIndex
    );
    let html = subpage?.headerHtml ?? '';

    // Apply header footer content layout to all footer tables
    const headerLayoutAttr = PdfMakeUtils.createDataAttribute({
      layout: 'noPadding',
    });
    html = html.replace('<table', `<table ${headerLayoutAttr}`);

    const headerBorderWidth = this.headerStyle?.borderWidth ?? 0;
    const headerDataAttr = PdfMakeUtils.createDataAttribute({
      layout: 'headerFooter',
      borderColor: this.headerStyle?.borderColor ?? 'white',
      borderWidth: headerBorderWidth,
    });
    const headerBackground = this.headerStyle?.backgroundColor
      ? `background-color: ${this.headerStyle.backgroundColor};`
      : '';

    const fittedLogoImageSize = fitRectIntoBounds(
      this.logoImageSize,
      new Size(
        ExportConfig.pageLogoWidth,
        ExportConfig.headerHeight - headerBorderWidth * 2
      )
    );

    html = `
        <div>
          <table ${headerDataAttr}>
            <tr>
              <td style="${headerBackground}">
                <img style="margin:${this.logoMargin}px" src="${
      this.logoImage
    }" 
                  width="${
                    fittedLogoImageSize.width * ExportConfig.upscaleMultiplier
                  }" 
                  height="${
                    fittedLogoImageSize.height * ExportConfig.upscaleMultiplier
                  }"
                >
              </td>
              <td style="${headerBackground}">
                ${html}
              </td>
            </tr>
          </table>
        </div>
    `;

    if (this.headerStyle?.dividerHeight > 0) {
      const dividerDataAttr = PdfMakeUtils.createDataAttribute({
        layout: 'noPadding',
      });
      const dividerBackground = this.headerStyle?.dividerColor ?? 'white';

      let dividerImage = '';
      if (this.headerDividerImage) {
        dividerImage = `<img
          src="${this.headerDividerImage}"
          width="${
            ExportConfig.pageSize.width * ExportConfig.upscaleMultiplier
          }"
          height="${this.headerStyle.dividerHeight}"
        ">`;
      }

      html = `
          ${html}
          <div>
            <table ${dividerDataAttr}>
              <tr>
                <td style="
                  width: 100%;
                  height: ${this.headerStyle.dividerHeight}pt;
                  background-color: ${dividerBackground};
                ">
                  ${dividerImage}
                </td>
              </tr>
            </table>
          </div>
      `;
    }

    html = PdfMakeUtils.ensureValidHtml(html);

    const definition = htmlToPdfmake(html, {
      defaultStyles: headerFooterStyle,
      tableAutoSize: true,
    });

    // Override header table size
    const headerTable = definition[0].stack[0].table;
    headerTable.widths = [fittedLogoImageSize.width, '*'];
    headerTable.heights = [
      ExportConfig.headerHeight - headerBorderWidth * 2,
      ExportConfig.headerHeight - headerBorderWidth * 2,
    ];

    return definition;
  };

  private buildFooter = (currentPage, pageCount, pageSize): Object => {
    if (this.contentType == DocumentPageContentType.Layout) {
      return;
    }

    const subPageIndex = currentPage - 1;
    const pageNumber = this.startingPageNumber + subPageIndex;
    const subpage = this.subPagesData.find(
      (d) => d.subPageIndex === subPageIndex
    );

    let html = subpage?.footerHtml ?? '';

    if (this.includePageNumber) {
      if (html.includes(ExportConfig.pageNumberVariable)) {
        html = html.replace(
          ExportConfig.pageNumberVariable,
          pageNumber.toString()
        );
      } else {
        const index = html.lastIndexOf('</td>');
        if (index > 0) {
          html =
            html.slice(0, index) + pageNumber.toString() + html.slice(index);
        }
      }
    } else {
      // Remove page number cell from footer table
      const regexString =
        '<td[^<]*>(([^<]|<(?!/td>))*)' +
        ExportConfig.pageNumberVariable +
        '.*?</td>';
      html = html.replace(new RegExp(regexString), '');
    }
    // Apply header footer content layout to all footer tables
    const footerLayoutAttr = PdfMakeUtils.createDataAttribute({
      layout: 'noPadding',
    });
    html = html.replace('<table', `<table ${footerLayoutAttr}`);

    const footerBorderWidth = this.footerStyle?.borderWidth ?? 0;
    const footerDataAttr = PdfMakeUtils.createDataAttribute({
      layout: 'headerFooter',
      borderColor: this.footerStyle?.borderColor ?? 'white',
      borderWidth: footerBorderWidth,
    });
    const footerBackground = this.footerStyle?.backgroundColor
      ? `background-color: ${this.footerStyle.backgroundColor};`
      : '';

    html = `
        <div>
          <table ${footerDataAttr}>
            <tr>
              <td style="${footerBackground}">
                <img src="${ExportConfig.blankImageUrl}">
              </td>
              <td style="${footerBackground}">
                ${html}
              </td>
            </tr>
          </table>
        </div>
    `;

    // Fit footer image (blank)
    const fitImageAttr = PdfMakeUtils.createDataAttribute({
      fit: [1, ExportConfig.footerHeight - footerBorderWidth * 2],
    });
    html = html.replace('<img ', `<img ${fitImageAttr}`);

    if (this.footerStyle?.dividerHeight > 0) {
      const dividerDataAttr = PdfMakeUtils.createDataAttribute({
        layout: 'noPadding',
      });
      const dividerBackground = this.footerStyle?.dividerColor ?? 'white';

      let dividerImage = '';
      if (this.footerDividerImage) {
        dividerImage = `<img
          src="${this.footerDividerImage}"
          width="${
            ExportConfig.pageSize.width * ExportConfig.upscaleMultiplier
          }"
          height="${this.footerStyle.dividerHeight}"
        ">`;
      }

      html = `
          <div>
            <table ${dividerDataAttr}>
              <tr>
                <td style="
                  width: 100%;
                  height: ${this.footerStyle.dividerHeight}pt;
                  background-color: ${dividerBackground};
                ">
                  ${dividerImage}
                </td>
              </tr>
            </table>
          </div>
          ${html}
      `;
    }

    const footerContainerDataAttr = PdfMakeUtils.createDataAttribute({
      marginTop:
        // Actual size of the footer
        ExportConfig.pageMargins.bottom -
        ExportConfig.footerHeight -
        // Divider size
        (this.footerStyle?.dividerHeight ?? 0) -
        // Divider margin
        (this.footerStyle?.dividerHeight > 0 ? ExportConfig.dividerMargin : 0),
    });
    html = `
        <div ${footerContainerDataAttr}>
          ${html}
        </div>
    `;

    html = PdfMakeUtils.ensureValidHtml(html);

    const definition = htmlToPdfmake(html, {
      defaultStyles: headerFooterStyle,
      tableAutoSize: true,
    });

    // Override footer table size
    const footerTable =
      definition[0].stack[definition[0].stack.length - 1].stack[0].table;
    footerTable.widths = [0, '*'];
    footerTable.heights = [
      ExportConfig.footerHeight - footerBorderWidth * 2,
      ExportConfig.footerHeight - footerBorderWidth * 2,
    ];

    return definition;
  };

  private async loadImages() {
    if (this.logo) {
      const result = await convertUrlToDataUrl(this.logo);
      this.logoImage = result;
    }
    if (!this.logoImage) {
      this.logoImage = ExportConfig.blankImageUrl;
    }
    const imageSize = await getImageSize(this.logoImage);
    this.logoImageSize = new Size(imageSize.width, imageSize.height);

    if (this.pageBackground) {
      const result = await convertUrlToDataUrl(this.pageBackground);
      this.pageBackgroundImage = result;
    }
    if (this.headerBackground) {
      const result = await convertUrlToDataUrl(this.headerBackground);
      this.headerBackgroundImage = result;
    }
    if (this.footerBackground) {
      const result = await convertUrlToDataUrl(this.footerBackground);
      this.footerBackgroundImage = result;
    }

    if (this.headerDivider) {
      const result = await convertUrlToDataUrl(this.headerDivider);
      this.headerDividerImage = result;
    }
    if (this.footerDivider) {
      const result = await convertUrlToDataUrl(this.footerDivider);
      this.footerDividerImage = result;
    }
  }
}
