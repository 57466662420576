import { render, staticRenderFns } from "./AppConfirmation.vue?vue&type=template&id=f2f203e0&"
import script from "./AppConfirmation.vue?vue&type=script&lang=ts&"
export * from "./AppConfirmation.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports