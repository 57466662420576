import { PageElementPosition } from '@/api/models';
import ExportConfig from '@/core/config/ExportConfig';
import { htmlToElement } from '@/core/utils/html.utils';
import { toPng } from 'html-to-image';
import { GraphComponent, GraphMLSupport, Size } from 'yfiles';
import b64toBlob from '../../graph/b64ToBlob';
import IAdditionalElementProvider from '../additional-element-providers/IAdditionalElementProvider';
import LegendAsImgProvider from '../additional-element-providers/LegendAsImgProvider';
import LogoAsImgProvider from '../additional-element-providers/LogoAsImgProvider';
import { ExportArea } from '../ExportArea';
import { ExportFormat } from '../ExportFormat';
import ExportOptions from '../ExportOptions';
import IExportProvider from './IExportProvider';
import IExportResult from './IExportResult';
import SvgExportProvider from './SvgExportProvider';

export default class PngExportProvider implements IExportProvider {
  private _fileExtension = 'png';
  private _mimeType = 'image/png';
  private _svgExportProvider = new SvgExportProvider();

  async exportGraphAsBase64(
    options: ExportOptions,
    graphComponent: GraphComponent,
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    await this.appendAdditionalElements(options);
    const exportResult = await this._svgExportProvider.exportGraphAsString(
      options,
      graphComponent
    );

    const svgElement = htmlToElement(<string>exportResult.result);

    const dataUrl = await toPng(svgElement, {
      backgroundColor: 'white',
      width: exportResult.size.width,
      height: exportResult.size.height,
      pixelRatio: 4,
    });
    const dataUrlParts = dataUrl.split(',');

    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      size: exportResult.size,
      result: dataUrlParts[1],
    };
  }

  async exportGraphAsBlob(
    options: ExportOptions,
    graphComponent: GraphComponent,
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    const exportResult = await this.exportGraphAsBase64(
      options,
      graphComponent,
      graphMLSupport
    );
    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      size: exportResult.size,
      result: b64toBlob(exportResult.result, this._mimeType),
    };
  }

  private async appendAdditionalElements(
    options: ExportOptions
  ): Promise<void> {
    for (const exportPage of options.pages) {
      if (!exportPage.additionalElements) {
        exportPage.additionalElements = [];
      }
      // Logo
      if (
        options.format != ExportFormat.Pdf &&
        options.document.logoPosition != PageElementPosition.Hidden &&
        exportPage.page.showLogo
      ) {
        const logoProvider = new LogoAsImgProvider();
        const additionalElement = await logoProvider.get(options, exportPage);
        if (additionalElement) {
          exportPage.additionalElements.push(...additionalElement);
        }
      }

      // Diagram legend
      if (
        options.document.legendPosition != PageElementPosition.Hidden &&
        exportPage.page.showLegend
      ) {
        const legendProvider: IAdditionalElementProvider =
          new LegendAsImgProvider();

        const additionalElement = await legendProvider.get(options, exportPage);
        if (additionalElement) {
          exportPage.additionalElements.push(...additionalElement);
        }
      }
    }
  }
}
