import { GraphComponent, GraphMLSupport } from 'yfiles';
import ExportOptions from '../ExportOptions';
import IExportProvider from './IExportProvider';
import IExportResult from './IExportResult';
import {
  DocumentAttachmentType,
  DocumentPageContentType,
  DocumentPageType,
  PageElementPosition,
} from '@/api/models';
import ContentPagination from '../ContentPagination';
import ExportService from '../ExportService';
import PdfDocumentBuilder from '../pdf/PdfDocumentBuilder';
import ExportConfig from '@/core/config/ExportConfig';
import DiagramUtils from '@/core/utils/DiagramUtils';
import BackgroundGraphService from '../../graph/BackgroundGraphService';
import { ExportPageElementType } from '../ExportPageElementType';
import PdfExportSubPage from '../pdf/PdfExportSubPage';
import { customPdfFonts } from '../pdf/PdfFonts';
import PdfPageBuilder from '../pdf/PdfPageBuilder';
import { PdfTableLayouts } from '../pdf/PdfTableLayouts';
import { ExportArea } from '../ExportArea';
import IAdditionalElementProvider from '../additional-element-providers/IAdditionalElementProvider';
import LegendAsImgProvider from '../additional-element-providers/LegendAsImgProvider';
import ExportUtils from '../ExportUtils';
const pdfMakeImport = () => import('@jigsaw/pdfmake/build/pdfmake.js');
/* const pdfMakeImport = () => import('pdfmake-playground'); // Playground mode, don't delete */

export default class PdfExportProvider implements IExportProvider {
  private _fileExtension = 'pdf';
  private _mimeType = 'application/pdf';

  async exportGraphAsBase64(
    options: ExportOptions,
    graphComponent: GraphComponent,
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    const exportResult = await this.exportDocumentPdf(options);
    exportResult.result = btoa(exportResult.result as string);
    return exportResult;
  }

  async exportGraphAsBlob(
    options: ExportOptions,
    graphComponent: GraphComponent,
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    return this.exportDocumentPdf(options);
  }

  private async exportDocumentPdf(
    options: ExportOptions
  ): Promise<IExportResult> {
    await this.appendAdditionalElements(options);
    const builder = new PdfDocumentBuilder();

    for (const exportPage of options.pages) {
      options.metadata.currentPage = exportPage;
      const pageExportResult = await this.exportPagePdf(options);
      builder.pages.push(pageExportResult.result as Blob);

      // Adjust current page number taking into account all the preceeding sub-pages
      if (exportPage.page.contentType != DocumentPageContentType.Layout) {
        options.metadata.currentPageNumber += ContentPagination.getPageCount(
          exportPage.page
        );
      }
    }
    const pdfData = await builder.getDocument();
    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      result: new Blob([pdfData], { type: 'application/pdf' }),
    };
  }

  private async exportPagePdf(options: ExportOptions): Promise<IExportResult> {
    if (!options.metadata.currentPage) {
      throw 'No page to export';
    }
    const exportPage = options.metadata.currentPage;

    const builder = new PdfPageBuilder();
    builder.pageType = exportPage.page.pageType;
    builder.contentType = exportPage.page.contentType;
    builder.content = ExportUtils.tryInlineContentStyles(
      builder.contentType,
      exportPage.page.content ?? ''
    );
    builder.contentColumns = exportPage.page.contentColumns;
    builder.diagramPosition = exportPage.page.diagramPosition;
    builder.startingPageNumber = options.metadata.currentPageNumber;
    builder.includeHeader =
      !options.document.hasSteps || exportPage.page.showHeader;
    builder.includeFooter =
      options.document.hasSteps && exportPage.page.showFooter;
    builder.includePageNumber =
      options.document.hasSteps && options.document.showPageNumbering;

    builder.logoMargin = options.document.hasSteps
      ? ExportConfig.stepsLogoMargin
      : ExportConfig.pageLogoMargin;
    if (options.document.hasSteps) {
      builder.legendScale =
        exportPage.page.pageType == DocumentPageType.Diagram
          ? ExportConfig.diagramLegendScale.medium
          : ExportConfig.diagramLegendScale.small;
    } else {
      builder.legendScale = ExportConfig.diagramLegendScale.medium;
    }

    const subPageCount = ContentPagination.getPageCount(exportPage.page);
    let primaryGraphSvg = null;

    for (let i = 0; i < subPageCount; i++) {
      const subPageRef = exportPage.page.subPageRefs?.find(
        (r) => r.subPageIndex === i
      );
      const subpage = new PdfExportSubPage();

      if (options.document.hasSteps) {
        subpage.headerHtml = ExportUtils.tryInlineContentStyles(
          DocumentPageContentType.Html,
          subPageRef
            ? subPageRef?.headerHtml ?? ''
            : exportPage.page?.headerHtml ?? ''
        );
        subpage.footerHtml = ExportUtils.tryInlineContentStyles(
          DocumentPageContentType.Html,
          subPageRef
            ? subPageRef?.footerHtml ?? ''
            : exportPage.page?.footerHtml ?? ''
        );
      }
      subpage.subPageIndex = i;

      const diagram = subPageRef?.diagram ?? exportPage.page.diagram;
      const isPrimaryDiagram = diagram == exportPage.page.diagram;

      if (diagram) {
        let graphSvgExportResult: IExportResult = isPrimaryDiagram
          ? primaryGraphSvg
          : null;
        if (!graphSvgExportResult) {
          const sourceGraph = new BackgroundGraphService(diagram).graph;
          graphSvgExportResult = await ExportService.exportGraphAsSvg(
            options,
            sourceGraph,
            options.withFilters
          );
          if (isPrimaryDiagram) {
            primaryGraphSvg = graphSvgExportResult;
          }
        }
        subpage.graphSvg = graphSvgExportResult.result as string;

        const legendElement = exportPage.additionalElements.find(
          (e) => e.key == diagram.id && e.type == ExportPageElementType.Legend
        );
        if (legendElement) {
          const legend = await legendElement.toSvgAsync();
          subpage.legend = legend;
          builder.legendPosition = legendElement.options.position;
        }
      }
      builder.subPagesData.push(subpage);
    }

    if (options.document.hasSteps) {
      builder.pageBackground = DiagramUtils.getDocumentAttachmentPath(
        options.document,
        DocumentAttachmentType.PageBackground
      );
      builder.headerBackground = DiagramUtils.getDocumentAttachmentPath(
        options.document,
        DocumentAttachmentType.HeaderBackground
      );
      builder.headerStyle = options.document?.headerStyle;
      builder.headerDivider = DiagramUtils.getDocumentAttachmentPath(
        options.document,
        DocumentAttachmentType.HeaderSeparator
      );
      builder.footerBackground = DiagramUtils.getDocumentAttachmentPath(
        options.document,
        DocumentAttachmentType.FooterBackground
      );
      builder.footerStyle = options.document?.footerStyle;
      builder.footerDivider = DiagramUtils.getDocumentAttachmentPath(
        options.document,
        DocumentAttachmentType.FooterSeparator
      );
    }

    if (
      (options.document.hasSteps &&
        !builder.pageBackground &&
        !builder.headerBackground) ||
      (exportPage.page.showLogo &&
        options.document.logoPosition != PageElementPosition.Hidden)
    ) {
      builder.logo = DiagramUtils.getDocumentAttachmentPath(
        options.document,
        DocumentAttachmentType.Logo
      );
    }
    const pageDefinition = await builder.getPageDefinition();

    var blob = await new Promise<Blob>(async (resolve, reject) => {
      try {
        const tableLayouts = {
          noPadding: PdfTableLayouts.noPaddingTableLayout,
          headerFooter: PdfTableLayouts.headerFooterTableLayout,
          textElement: PdfTableLayouts.textElementTableLayout,
        };
        const pdfMake = await pdfMakeImport();
        pdfMake
          .createPdf(pageDefinition, tableLayouts, customPdfFonts)
          .getBlob((blob: Blob) => {
            resolve(blob);
          });
        if (ExportConfig.pdfmakePlaygroundMode) {
          console.debug(pageDefinition);
        }
      } catch (e) {
        reject(e);
      }
    });

    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      result: blob,
    };
  }

  private async appendAdditionalElements(
    options: ExportOptions
  ): Promise<void> {
    for (const exportPage of options.pages) {
      if (!exportPage.additionalElements) {
        exportPage.additionalElements = [];
      }

      // Diagram legend
      if (
        options.document.legendPosition != PageElementPosition.Hidden &&
        exportPage.page.showLegend
      ) {
        const legendProvider: IAdditionalElementProvider =
          new LegendAsImgProvider();

        const additionalElement = await legendProvider.get(options, exportPage);
        if (additionalElement) {
          exportPage.additionalElements.push(...additionalElement);
        }
      }
    }
  }
}
