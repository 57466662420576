import humanizeString from 'humanize-string';
import arrows from './ArrowElementData';
import PaletteCategory from '../PaletteCategory';
import PaletteItem from '../PaletteItem';
import { IElementDataProvider } from './IElementDataProvider';
import ArrowPaletteBehaviour from '@/components/DiagramPalette/ArrowPaletteBehaviour';

export default class ArrowElementDataProvider implements IElementDataProvider {
  getElements(): PaletteItem[] {
    return arrows.map<PaletteItem>((e) => {
      return {
        category: PaletteCategory.Annotate,
        subcategory: humanizeString(e.subcategory),
        img: e.imagePath,
        data: e,
        text: e.name.toString(),
        id: e.id,
        behaviour: ArrowPaletteBehaviour.INSTANCE,
        canDrag: true,
        style: e.style,
      };
    });
  }
}
