import axios, {
    AxiosResponse
} from 'axios';
import {
    PagedResultDtoOfGetDocumentForViewDto,
    ZadarResponse,
    UserDto,
    DocumentAccessLevel,
    EditDocumentNameDto,
    EditDocumentAutoSaveDto,
    MarkAsFavouriteRequest,
    GetDocumentForViewDto,
    UpdateDocumentStructureDto,
    CreateOrEditDocumentOutput,
    CreateOrEditDocumentDto,
    DocumentDto,
    EntityDto,
    DocumentAttachmentDto,
    DocumentAttachmentType,
    RestoreDeletedEntityRequestDto
} from './models'
export default class DocumentsApiService {
    public static getAll(params ? : {
        filter ? : string,
        sorting ? : string,
        skipCount ? : number,
        maxResultCount ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < PagedResultDtoOfGetDocumentForViewDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/GetAll`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getDocumentOwner(params ? : {
        documentId ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < UserDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/GetDocumentOwner`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getDocumentName(params ? : {
        documentId ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < string >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/GetDocumentName`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getDocumentDefaultAccessLevel(params ? : {
        documentId ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < DocumentAccessLevel >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/GetDocumentDefaultAccessLevel`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getDocumentLastModificationTime(params ? : {
        documentId ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < string >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/GetDocumentLastModificationTime`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static updateDocumentName(body: EditDocumentNameDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/UpdateDocumentName`,
            method: 'put',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static updateDocumentAutoSave(body: EditDocumentAutoSaveDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/UpdateDocumentAutoSave`,
            method: 'put',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static markAsFavourite(body: MarkAsFavouriteRequest, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/MarkAsFavourite`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static unmarkAsFavourite(body: MarkAsFavouriteRequest, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/UnmarkAsFavourite`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static get(params ? : {
        tryLock ? : boolean,
        includeDeleted ? : boolean,
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetDocumentForViewDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/Get`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static updateDocumentStructure(body: UpdateDocumentStructureDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/UpdateDocumentStructure`,
            method: 'put',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static createOrEdit(body: CreateOrEditDocumentDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < CreateOrEditDocumentOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/CreateOrEdit`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static create(body: CreateOrEditDocumentDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < CreateOrEditDocumentOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/Create`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static clone(body: DocumentDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < number >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/Clone`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static uploadToAI(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < number >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/UploadToAI`,
            method: 'post',
            clientConfig: clientConfig
        })
    }
    public static unlockDocument(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/UnlockDocument`,
            method: 'post',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static hardDelete(body ? : EntityDto[], clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/HardDelete`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static delete(body: EntityDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/Delete`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static deleteDocuments(body ? : EntityDto[], clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/DeleteDocuments`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static getDocumentAttachments(params ? : {
        documentId ? : number,
        attachmentType ? : DocumentAttachmentType
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < DocumentAttachmentDto[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/GetDocumentAttachments`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static addDocumentAttachment(body: DocumentAttachmentDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < number >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/AddDocumentAttachment`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static restoreDocument(body: RestoreDeletedEntityRequestDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/RestoreDocument`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static restoreDocuments(body ? : RestoreDeletedEntityRequestDto[], clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/RestoreDocuments`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
}