















import { debounce } from '@/core/common/DebounceDecorator';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'AppLazyRenderer'
})
export default class AppLazyRenderer extends Vue {
  @Prop({ default: null })
  rootElement: Element;
  @Prop({ default: '0px' })
  rootMargin: string;
  @Prop({
    default: 0,
    validator: function (value) {
      return value >= 0 && value <= 1;
    }
  })
  threshold: number;

  targetElement: Element = null;
  observer: IntersectionObserver = null;
  shouldRender = false;

  mounted() {
    this.targetElement = this.$refs['targetElelement'] as Element;

    const options = {
      root: this.rootElement,
      rootMargin: this.rootMargin,
      threshold: this.threshold,
    };
    this.observer = new IntersectionObserver(this.intersectionChanged, options);

    setTimeout(() => {
      this.observer.observe(this.targetElement);
    });
  }

  beforeDestroy() {
    this.observer?.disconnect();
  }

  intersectionChanged(
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) {
    const entry = entries[0];
    this.updateShouldRender(entry.isIntersecting);
  }

  @debounce(100)
  updateShouldRender(value: boolean) {
    if (value) {
      this.shouldRender = value;
      this.observer.unobserve(this.targetElement);
    }
  }
}
