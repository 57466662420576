import axios, {
    AxiosResponse
} from 'axios';
import {
    ZadarResponse,
    LicenseUserResultDto,
    LicenseUserDto,
    GetLicenseForViewDto,
    GetLicenseForEditOutput,
    CreateOrEditLicenseDto
} from './models'
export default class LicensesApiService {
    public static hasReachedMaxUsers(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < boolean >>> {
        return axios.request( < any > {
            url: `/api/services/app/Licenses/HasReachedMaxUsers`,
            method: 'post',
            clientConfig: clientConfig
        })
    }
    public static requestLicence(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < boolean >>> {
        return axios.request( < any > {
            url: `/api/services/app/Licenses/RequestLicence`,
            method: 'post',
            clientConfig: clientConfig
        })
    }
    public static licenseUser(body: LicenseUserDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < LicenseUserResultDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Licenses/LicenseUser`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static getLicenseForView(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetLicenseForViewDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Licenses/GetLicenseForView`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static get(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetLicenseForEditOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Licenses/Get`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static create(body: CreateOrEditLicenseDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetLicenseForViewDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Licenses/Create`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static delete(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Licenses/Delete`,
            method: 'delete',
            clientConfig: clientConfig
        })
    }
}