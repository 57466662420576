

























































































































import { Vue, Component } from 'vue-property-decorator';

import tailwindConfig from '../../../tailwind.config';

const {
  prefix,
  theme: {
    colors,
    fontSize,
    spacing,
    opacity,
    borderRadius,
    boxShadow,
    zIndex,
  },
} = tailwindConfig;

@Component({
  name: 'Tailwind',
})
export default class Tailwind extends Vue {
  prefix = prefix;
  colors = colors;
  fontSize = fontSize;
  spacing = spacing;
  opacity = opacity;
  borderRadius = borderRadius;
  boxShadow = boxShadow;
  zIndex = zIndex;
}
