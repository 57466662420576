import {
  IInputModeContext,
  Point,
  BezierEdgeStyleRenderer,
  IEdgeStyle,
  PathBasedEdgeStyleRenderer,
  PolylineEdgeStyleRenderer,
  PathType,
  Visual,
  IRenderContext,
  SvgVisualGroup,
  SvgVisual,
  ArcEdgeStyleRenderer,
  IHitTestable,
  IEdge,
} from 'yfiles';
import DiagramUtils from '@/core/utils/DiagramUtils';

export abstract class JigsawBaseEdgeStyleRenderer<
  TStyle extends IEdgeStyle
> extends PathBasedEdgeStyleRenderer<TStyle> {
  isHit(context: IInputModeContext, location: Point): boolean {
    return EdgeHitTestHelper.isHit(context, location, this);
  }

  createVisual(context: IRenderContext): Visual | null {
    const visual = super.createVisual(context) as SvgVisual;
    DiagramUtils.updateSvgFilteredStyles(this.edge, visual.svgElement);
    return visual;
  }

  updateVisual(
    context: IRenderContext,
    oldVisual: Visual | null
  ): Visual | null {
    let oldVisualGroup = oldVisual as SvgVisual;
    DiagramUtils.updateSvgFilteredStyles(this.edge, oldVisualGroup.svgElement);
    return super.updateVisual(context, oldVisualGroup);
  }
}

export class JigsawBezierEdgeStyleRenderer extends BezierEdgeStyleRenderer {
  isHit(context: IInputModeContext, location: Point): boolean {
    return EdgeHitTestHelper.isHit(context, location, this);
  }

  createVisual(context: IRenderContext): Visual | null {
    const visual = super.createVisual(context) as SvgVisual;
    if (visual?.svgElement) {
      DiagramUtils.updateSvgFilteredStyles(this.edge, visual.svgElement);
    }
    return visual;
  }

  updateVisual(
    context: IRenderContext,
    oldVisual: Visual | null
  ): Visual | null {
    let oldSvg = oldVisual as SvgVisual;
    if (oldSvg?.svgElement) {
      DiagramUtils.updateSvgFilteredStyles(this.edge, oldSvg.svgElement);
    }
    return super.updateVisual(context, oldSvg);
  }

  getSegmentCount() {
    const cursor = this.getPath().createCursor();
    let segCount = 0;
    while (cursor.moveNext()) {
      switch (cursor.pathType) {
        case PathType.LINE_TO:
        case PathType.CUBIC_TO:
        case PathType.CLOSE:
          segCount++;
          break;
        default:
      }
    }
    return segCount;
  }
}

export class JigsawPolylineEdgeStyleRenderer extends PolylineEdgeStyleRenderer {
  //@ts-ignore
  get addBridges(): boolean {
    return this.edge.tag?.style?.bridge ?? false;
  }

  getHitTestable(edge: IEdge, style: IEdgeStyle): IHitTestable {
    const hitTestable = super.getHitTestable(edge, style);
    if (hitTestable) {
      this.configure();
      return hitTestable;
    }
    return null;
  }

  isHit(context: IInputModeContext, location: Point): boolean {
    return EdgeHitTestHelper.isHit(context, location, this);
  }

  createVisual(context: IRenderContext): Visual | null {
    const visual = super.createVisual(context) as SvgVisual;

    if (visual?.svgElement) {
      DiagramUtils.updateSvgFilteredStyles(this.edge, visual.svgElement);
    }
    return visual;
  }

  updateVisual(
    context: IRenderContext,
    oldVisual: Visual | null
  ): Visual | null {
    let oldSvg = oldVisual as SvgVisual;
    if (oldSvg?.svgElement) {
      DiagramUtils.updateSvgFilteredStyles(this.edge, oldSvg.svgElement);
    }
    return super.updateVisual(context, oldSvg);
  }
}

export class JigsawArcEdgeStyleRenderer extends ArcEdgeStyleRenderer {
  createVisual(context: IRenderContext): Visual | null {
    const visual = super.createVisual(context) as SvgVisual;
    if (visual?.svgElement) {
      DiagramUtils.updateSvgFilteredStyles(this.edge, visual.svgElement);
    }
    return visual;
  }

  updateVisual(
    context: IRenderContext,
    oldVisual: Visual | null
  ): Visual | null {
    let visual = oldVisual as SvgVisual;
    if (visual?.svgElement) {
      DiagramUtils.updateSvgFilteredStyles(this.edge, visual.svgElement);
    }
    return super.updateVisual(context, visual);
  }
}

const edgeHitRadiusModifier = 2;
export class EdgeHitTestHelper {
  static isHit<TStyle extends IEdgeStyle>(
    context: IInputModeContext,
    location: Point,
    renderer: PathBasedEdgeStyleRenderer<TStyle>
  ): boolean {
    let stroke = renderer.getStroke();
    if (!stroke) return false;
    let path = renderer.getPath();
    if (!path) {
      return false;
    }
    let radius = context.hitTestRadius * (edgeHitRadiusModifier * context.zoom);
    return path.pathContains(location, radius);
  }
}
