import {
  DragDropEffects,
  DragDropItem,
  DragSource,
  FreeNodePortLocationModel,
  GraphComponent,
  GraphEditorInputMode,
  GraphItemTypes,
  IEdge,
  IGraph,
  IInputModeContext,
  IModelItem,
  INode,
  ItemDropInputMode,
  Point,
  Rect,
  Size,
  VoidNodeStyle,
} from 'yfiles';
import { addClass, removeClass } from '@/core/utils/html.utils';
import IPaletteBehaviour from '@/components/DiagramPalette/IPaletteBehaviour';
import Vue from 'vue';
import DiagramUtils from '@/core/utils/DiagramUtils';
import diagramConfig from '@/core/config/diagram.definition.config';
import StyleCreator from '@/core/utils/StyleCreator';
import PaletteItem from '@/components/DiagramPalette/PaletteItem';
import { AnnotationType } from '@/core/common/AnnotationType';
import GraphService from '@/core/services/graph/graph.service';
import config from '@/core/config/diagram.definition.config';
import PaletteItemOffsetHelper from './PaletteItemOffsetHelper';
import SystemEntityTypes from '@/core/services/corporate/SystemEntityTypes';

export default class ArrowPaletteBehaviour
  extends Vue
  implements IPaletteBehaviour
{
  private readonly _dragPreviewZIndex: number;
  private static _instance: ArrowPaletteBehaviour = null;

  public static get INSTANCE(): ArrowPaletteBehaviour {
    return (
      ArrowPaletteBehaviour._instance ??
      (ArrowPaletteBehaviour._instance = new ArrowPaletteBehaviour())
    );
  }

  constructor() {
    super();
    this._dragPreviewZIndex = 10000;
  }

  anchorNode: INode = null;
  sourceNode: INode = null;
  targetNode: INode = null;

  calculateSnapWidth(size: Size): Size {
    const min = 6;
    const width = diagramConfig.grid.size * min;
    const height = (size.height / size.width) * width;
    return new Size(width, height);
  }

  edgeDropInputMode = null;

  insert(event, item, graphService: GraphService) {
    const offsetX = PaletteItemOffsetHelper.getOffsetX();
    const offsetY = PaletteItemOffsetHelper.getOffsetY();

    const itemPositionX =
      graphService.graphComponent.viewport.minX +
      graphService.graphComponent.viewport.width /
        config.offsetRightFromCanvasLeftBound.large +
      offsetX;

    const itemPositionY =
      graphService.graphComponent.viewport.centerY -
      graphService.graphComponent.viewport.height / 4 +
      offsetY;

    this.itemCreator(
      graphService.graphComponent.inputModeContext,
      graphService.graphComponent.graph,
      item,
      null,
      DiagramUtils.snapToNearestGridPoint(
        new Point(itemPositionX, itemPositionY)
      )
    );

    PaletteItemOffsetHelper.updatePalettItemDropOffset(
      graphService.graphComponent,
      config.paletteItemDropOffsetStep
    );
  }
  startDrag(event, item, graphService: GraphService) {
    const inputMode = graphService.graphComponent
      .inputMode as GraphEditorInputMode;

    if (this.edgeDropInputMode) {
      inputMode.remove(this.edgeDropInputMode);
    }

    this.edgeDropInputMode = new EdgeDropInputMode();
    this.edgeDropInputMode.itemCreator = this.itemCreator.bind(this);

    inputMode.add(this.edgeDropInputMode);

    const dragPreview = document.createElement('div');
    dragPreview.setAttribute('id', 'draggable-theme');
    dragPreview.setAttribute('style', `z-index:${this._dragPreviewZIndex}`);

    dragPreview.appendChild(event.target.cloneNode(true));

    const dragSource = new DragSource(dragPreview);

    dragSource.startDrag(
      new DragDropItem(IEdge.$class.name, item),
      DragDropEffects.ALL,
      true,
      dragPreview
    );

    dragSource.addQueryContinueDragListener((src, args) => {
      if (args.dropTarget === null) {
        removeClass(dragPreview, 'd-none');
      } else {
        addClass(dragPreview, 'd-none');
        graphService.graphComponent.selection.clear();
      }
    });
  }

  itemCreator(
    ctx: IInputModeContext,
    graph: IGraph,
    draggedItem: any,
    dropTarget: IModelItem,
    dropLocation: Point
  ): PaletteItem {
    const sourceNodeTag = DiagramUtils.createNewNodeTag(
      SystemEntityTypes.ARROW_NODE
    );
    sourceNodeTag.isAnnotation = true;
    sourceNodeTag.quickBuildDisabled = true;
    sourceNodeTag.annotationType = AnnotationType.ArrowHead;
    sourceNodeTag.isIncluded = false;

    const targetNodeTag = DiagramUtils.createNewNodeTag(
      SystemEntityTypes.ARROW_NODE
    );
    targetNodeTag.isAnnotation = true;
    targetNodeTag.quickBuildDisabled = true;
    targetNodeTag.annotationType = AnnotationType.ArrowHead;
    targetNodeTag.isIncluded = false;

    const nodeStyleDto = DiagramUtils.getSystemDefaultEdgeToNoWhereNodeStyle();
    const nodeStyle = StyleCreator.createNodeStyle(nodeStyleDto, []);

    const source = graph.createNode(
      new Rect(dropLocation.x - 100, dropLocation.y, 1, 1),
      nodeStyle,
      sourceNodeTag
    );

    const yAdjustor = draggedItem.data.name === 'ArcArrow' ? 100 : 0;

    const target = graph.createNode(
      new Rect(dropLocation.x + 100, dropLocation.y - yAdjustor, 1, 1),
      nodeStyle,
      targetNodeTag
    );

    const sourcePort = graph.addPort(
      source,
      FreeNodePortLocationModel.NODE_CENTER_ANCHORED
    );
    const targetPort = graph.addPort(
      target,
      FreeNodePortLocationModel.NODE_CENTER_ANCHORED
    );

    const edgeStyleDto = draggedItem.style;
    const edgeStyle = StyleCreator.createEdgeStyle(edgeStyleDto);
    const edgeTag = DiagramUtils.createNewEdgeTag(draggedItem.data.name);
    edgeTag.autoCreated = true;
    edgeTag.isAnnotation = true;
    const edge = graph.createEdge(sourcePort, targetPort, edgeStyle, edgeTag);

    if (draggedItem.data.name === 'ArcArrow') {
      graph.addBend(
        edge,
        new Point(dropLocation.x - 100, dropLocation.y - 100)
      );
    }

    ctx.canvasComponent.focus();

    if (this.edgeDropInputMode) {
      (ctx.canvasComponent.inputMode as GraphEditorInputMode).remove(
        this.edgeDropInputMode
      );
    }
    return draggedItem;
  }
}

class EdgeDropInputMode extends ItemDropInputMode<PaletteItem> {
  previewSourceNodeOffset: Point = null;
  previewTargetNodeOffset: Point = null;
  previewBendOffset: Point = null;

  constructor() {
    super(IEdge.$class.name);
    this.previewSourceNodeOffset = new Point(-100, 1);
    this.previewTargetNodeOffset = new Point(100, 1);
    this.previewBendOffset = new Point(0, 0);
  }

  /**
   * @returns {IEdge}
   */
  // @ts-ignore
  get draggedItem() {
    return this.dropData;
  }

  /**
   * @param {Point} dragLocation - The location to return the drop target for.
   * @returns {IModelItem}
   */
  getDropTarget(dragLocation) {
    const parentMode = this.inputModeContext.parentInputMode;
    if (parentMode instanceof GraphEditorInputMode) {
      const hitItems = parentMode.findItems(dragLocation, [
        GraphItemTypes.NODE,
        GraphItemTypes.EDGE,
      ]);
      if (hitItems.size > 0) {
        return hitItems.first();
      }
    }
    return null;
  }

  initializePreview() {
    if (this.showPreview && this.draggedItem) {
      // there's no dragged item when using the native drag-and-drop approach
      super.initializePreview();
    }
  }

  /**
   * @param {IGraph} previewGraph - The preview graph to fill.
   */
  populatePreviewGraph(previewGraph) {
    const graph = previewGraph;
    graph.nodeDefaults.style = VoidNodeStyle.INSTANCE;

    const item = this.dropData as PaletteItem;
    const edgeStyleDto = item.style;
    const style = StyleCreator.createEdgeStyle(edgeStyleDto);

    const source = graph.createNode(new Rect(-100, 0, 0, 0));

    const yAdjustor = item.data.name === 'ArcArrow' ? 100 : 0;

    this.previewTargetNodeOffset = new Point(100, 0 - yAdjustor);
    const target = graph.createNode(new Rect(100, 0 - yAdjustor, 0, 0));

    const dummyEdge = graph.createEdge(source, target, style);

    this.previewBendOffset = new Point(0, 0);
    if (item.data.name === 'ArcArrow') {
      this.previewBendOffset = new Point(-50, -100);
      graph.addBend(dummyEdge, this.previewBendOffset);
    }
  }

  /**
   * @param {IGraph} previewGraph - The preview graph to update.
   * @param {Point} dragLocation - The current drag location.
   */
  updatePreview(previewGraph, dragLocation) {
    previewGraph.setNodeCenter(
      previewGraph.nodes.elementAt(0),
      dragLocation.add(this.previewSourceNodeOffset)
    );
    previewGraph.setNodeCenter(
      previewGraph.nodes.elementAt(1),
      dragLocation.add(this.previewTargetNodeOffset)
    );
    const edge = previewGraph.edges.first();
    previewGraph.clearBends(edge);
    previewGraph.addBend(edge, dragLocation.add(this.previewBendOffset));
    if (this.inputModeContext.canvasComponent !== null) {
      this.inputModeContext.canvasComponent.invalidate();
    }
  }
}
