import {
  NodeStyleBase,
  IRenderContext,
  INode,
  Visual,
  SvgVisualGroup,
  SvgVisual,
  IInputModeContext,
  Point,
  ICanvasContext,
  Rect,
  NodeDecorator,
} from 'yfiles';
import HitResult, { HitResultLocation } from './HitResult';
import JigsawNodeDecorator from './decorators/JigsawNodeDecorator';

interface IRenderCache {
  decoratorCount: number;
}
export default abstract class JigsawNodeStyleBase extends NodeStyleBase {
  private hasDecorators = false;
  public static RenderCacheKey = 'render-cache';
  public static CanvasComponentKey = 'canvas-component';
  constructor(protected decorators: JigsawNodeDecorator[]) {
    super();
    if (!this.decorators) {
      this.decorators = [];
    }
    this.hasDecorators =
      decorators && decorators.length && decorators.length > 0;
  }

  // D'Sync bug: can't store cache on the SVG group as it is getting dropped for some reason
  private getRenderCache(node: INode): IRenderCache {
    let cache: IRenderCache = node[JigsawNodeStyleBase.RenderCacheKey];

    if (cache) {
      return cache;
    }

    return { decoratorCount: 0 };
  }

  // D'Sync bug: can't store cache on the SVG group as it is getting dropped for some reason
  private setRenderCache(node: INode, cache: IRenderCache) {
    node[JigsawNodeStyleBase.RenderCacheKey] = cache;
  }

  createVisual(context: IRenderContext, node: INode): Visual {
    if (!this.hasDecorators) {
      return new SvgVisualGroup();
    }
    let group = new SvgVisualGroup();

    for (const decorator of this.decorators) {
      if (!decorator.isVisible(context, node)) {
        group.add(new SvgVisualGroup());
        continue;
      }
      let visual = decorator.createVisual(context, node);
      group.add(visual as SvgVisual);
    }
    let cache = this.getRenderCache(node);
    cache.decoratorCount = this.decorators.length;
    this.setRenderCache(node, cache);
    return group;
  }

  updateVisual(context: IRenderContext, oldVisual: SvgVisual, node: INode) {
    if (!this.hasDecorators) {
      return new SvgVisualGroup();
    }
    let oldGroup = oldVisual as SvgVisualGroup;
    let cache = this.getRenderCache(node);
    if (cache.decoratorCount != this.decorators.length) {
      cache.decoratorCount = this.decorators.length;
      return this.createVisual(context, node);
    }

    for (let index = 0; index < this.decorators.length; index++) {
      const decorator = this.decorators[index];
      if (!decorator.isVisible(context, node)) {
        oldGroup.children.set(index, new SvgVisualGroup());
        continue;
      }
      let oldDecoratorVisual = oldGroup.children.elementAt(index);
      let visual = decorator.updateVisual(
        context,
        node,
        oldDecoratorVisual as SvgVisual
      ) as SvgVisual;

      if (visual) {
        oldGroup.children.set(index, visual);
      } else {
        // D'Sync bug: nuke the old visual completely when one of the decorators returns null
        // from its updateVisual (when its internal state changes and it needs to be recreated)
        return this.createVisual(context, node);
      }
    }

    return oldGroup;
  }

  isHit(context: IInputModeContext, location: Point, node: INode): boolean {
    // while dragging and edge, we want the node to appear larger
    let hitResult = this.getHitLocation(context, location, node);
    return hitResult.isHit;
  }

  isVisible(context: ICanvasContext, rectangle: Rect, node: INode): boolean {
    return true;
  }

  getHitLocation(
    context: IInputModeContext,
    location: Point,
    node: INode
  ): HitResult {
    if (this.hasDecorators) {
      for (let i = 0; i < this.decorators.length; i++) {
        let decorator = this.decorators[i];
        if (
          decorator.isVisible(
            context.canvasComponent.createRenderContext(),
            node
          )
        ) {
          let hitResult = decorator.isHit(context, location, node);
          if (hitResult.isHit) {
            return hitResult;
          }
        }
      }
    }

    let rect = node.layout.toRect();
    if (rect.containsWithEps(location, context.hitTestRadius)) {
      return new HitResult({
        hitLocation: HitResultLocation.NODE,
        isHit: true,
        meta: null,
        decoratorType: null,
      });
    }
    // we didn't hit anything
    return HitResult.NONE;
  }

  addDecorator(decorator: JigsawNodeDecorator) {
    this.decorators.push(decorator);
    this.hasDecorators = this.decorators.length > 0;
  }
  removeDecorator(decoratorType: string) {
    let existingDecoratorIndex = this.decorators.findIndex(
      (x) => x.$class === decoratorType
    );
    if (existingDecoratorIndex >= 0) {
      this.decorators.splice(existingDecoratorIndex, 1);
      this.hasDecorators = this.decorators.length > 0;
    }
  }
}
