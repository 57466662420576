














import { Vue, Component, Prop, Inject } from 'vue-property-decorator';

@Component({
  name: 'AppDropdownItem',
})
export default class AppDropdownItem extends Vue {
  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Inject() hideDropdown;

  clickHandler() {
    if (this.disabled) return;

    this.hideDropdown();
    this.$emit('click');
  }
}
