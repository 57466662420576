
























import { Vue, Component, Prop } from 'vue-property-decorator';

import { Placement } from './AppFlyout.vue';

@Component({
  name: 'AppTooltip',
})
export default class AppTooltip extends Vue {
  @Prop({ default: 'auto' })
  placement: Placement;

  @Prop({ default: '' })
  content: string;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: null })
  maxWidth: string | number;

  @Prop({ default: false, type: Boolean })
  appendToBody: boolean;
}
