export default {
  ribbonLicense: '1F8B7CA2ACB3C92673490F9B80A56185',
  apiBaseUrl: null,
  version: null,
  debugMode: false,
  leaveConfirmation: true,
  diagramConfig: {
    portZoomLevelThreshold: null,
  },
  toastDurationms: 1500,
  minPasswordLength: 3,
  maxPasswordLength: 256,
  appInsights: {
    enabled: false,
    instrumentationKey: null,
  },
  pendo: {
    enabled: false,
    apiKey: null,
    additionalDetail: false,
  },
  spellChecker: {
    enabled: false,
    server: null,
    serviceId: null,
    defaultLanguage: null,
  },
  authentication: {
    authority: null,
    clientId: null,
    clientSecret: null,
    externalProvider: null,
    externalUsernameRequired: false,
    externalLoginOnly: false,
    externalLoginText: 'Get Started',
    externalAutoLogin: false,
  },
  oidc: {
    scope: 'openid profile default-api',
    responseType: 'code',
    responseMode: 'query',
    redirectUri: '/#/login-callback',
    logoutRedirectUri: '/#/login',
    loginUrl: '/admin/Account/Login',
    loginDevUrl: '/admin/Account/LoginDev',
    externalLoginUrl: '/admin/Account/ExternalLogin',
    logoutUrl: '/admin/Account/Logout',
    loginPageHash: '#/login',
    landingPageHash: '#/landing',
    revokeAccessTokenUrl: '/api/services/app/UserLogin/RevokeAccessToken',
  },
};
