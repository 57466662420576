import ExportConfig from '../config/ExportConfig';
import BackgroundDomService from '../services/BackgroundDomService';
import { wait } from './common.utils';
import { ZERO_WIDTH_SPACE } from './DiagramUtils';

export function addClass(e, className) {
  const classes = e.getAttribute('class');
  if (classes === null || classes === '') {
    e.setAttribute('class', className);
  } else if (!hasClass(e, className)) {
    e.setAttribute('class', `${classes} ${className}`);
  }
  return e;
}

export function removeClass(e, className) {
  const classes = e.getAttribute('class');
  if (classes !== null && classes !== '') {
    if (classes === className) {
      e.setAttribute('class', '');
    } else {
      const result = classes
        .split(' ')
        .filter((s) => s !== className)
        .join(' ');
      e.setAttribute('class', result);
    }
  }
  return e;
}

export function hasClass(e, className) {
  const classes = e.getAttribute('class');
  const r = new RegExp(`\\b${className}\\b`, '');
  return r.test(classes);
}

/**
 *
 * @param {Element} e
 * @param {string} className
 * @return {Element}
 */
export function toggleClass(e, className) {
  if (hasClass(e, className)) {
    removeClass(e, className);
  } else {
    addClass(e, className);
  }
  return e;
}

/**
 * Binds the given command to the input element specified by the given selector.
 *
 * @param {string} selector
 * @param {ICommand} command
 * @param {CanvasComponent|GraphComponent} target
 * @param {Object?} parameter
 */
export function bindCommand(selector, command, target, parameter) {
  const element = document.querySelector(selector);
  if (arguments.length < 4) {
    parameter = null;
    if (arguments.length < 3) {
      target = null;
    }
  }
  if (!element) {
    return;
  }
  command.addCanExecuteChangedListener((sender, e) => {
    if (command.canExecute(parameter, target)) {
      element.removeAttribute('disabled');
    } else {
      element.setAttribute('disabled', 'disabled');
    }
  });
  element.addEventListener('click', (e) => {
    if (command.canExecute(parameter, target)) {
      command.execute(parameter, target);
    }
  });
}

/**
 * @param {string} selector
 * @param {function(Event)} action
 */
export function bindAction(selector, action) {
  const element = document.querySelector(selector);
  if (!element) {
    return;
  }
  element.addEventListener('click', (e) => {
    action(e);
  });
}

/**
 * @param {string} selectors
 * @param {function(Event)} action
 */
export function bindActions(selectors, action) {
  const elements = document.querySelectorAll(selectors);
  if (!elements) {
    return;
  }
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    element.addEventListener('click', (e) => {
      action(e);
    });
  }
}

/**
 * @param {string} selector
 * @param {function(string|boolean)} action
 */
export function bindChangeListener(selector, action) {
  const element = document.querySelector(selector);
  if (!element) {
    return;
  }
  element.addEventListener('change', (e) => {
    if (e.target instanceof HTMLInputElement && e.target.type === 'checkbox') {
      action(e.target.checked);
    } else {
      action(e.target.value);
    }
  });
}

export function htmlToElement(html: string): HTMLElement {
  var template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild as HTMLElement;
}

export function encodeHtml(html: string): string {
  return html
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;');
}

export function measureElement(element: HTMLElement): DOMRect {
  BackgroundDomService.appendElement(element);
  const size = element.getBoundingClientRect();
  BackgroundDomService.removeElement(element);
  return size;
}

function createMeasureContainer(): HTMLElement {
  const el = BackgroundDomService.createElement('div');
  el.style.position = 'absolute';
  el.style.border = '1px solid black';
  el.style.width = 'fit-content';
  return el;
}

export function measureText(html: string) {
  const container = createMeasureContainer();
  container.innerHTML = html;
  return measureElement(container);
}

type InlineStyleOptions = {
  recursive: boolean;
  properties?: string[];
  containerClassList?: string[];
  // if set to true, elements that have a font-size style in pixels won't be convert to points
  maintainPixelValues?: boolean;
};
export function elementStylesToInline(
  element: HTMLElement,
  options: InlineStyleOptions
) {
  if (!element) {
    throw new Error('No element specified.');
  }
  if (options.recursive) {
    for (const child of element.children) {
      elementStylesToInline(child as HTMLElement, options);
    }
  }

  const computedStyle = getComputedStyle(element);

  var props = options.properties || computedStyle;
  for (var property in props) {
    var propertyName = props[property];
    // TODO this won't work if there is a span inside the paragraph with inline styles set to it
    // Need to remove all inner span styles after applying headings
    //if (!element.style[propertyName]) {
    // Temp fix for pdf export for text-decoration property
    if (propertyName === 'text-decoration-line') {
      element.style['text-decoration'] =
        computedStyle.getPropertyValue(propertyName);
    } else if (propertyName === 'font-weight') {
      const value = computedStyle.getPropertyValue(propertyName);
      element.style[propertyName] = value === '700' ? 'bold' : value;
    } else {
      element.style[propertyName] =
        computedStyle.getPropertyValue(propertyName);
    }
    //}

    if (!options.maintainPixelValues) {
      // here we convert all px values to pt values
      let propertyValue = element.style[propertyName] as string;
      if (/^\d+(\.\d{1,})?px$/.test(propertyValue)) {
        const pixelValue = propertyValue.substring(
          0,
          propertyValue.indexOf('px')
        );
        const pt = Math.round(
          Number(pixelValue) / ExportConfig.upscaleMultiplier
        );
        element.style[propertyName] = `${pt}pt`;
      }
    }
  }
}

export function htmlStylesToInline(html: string, options: InlineStyleOptions) {
  //create container element
  var element = BackgroundDomService.createElement('div');
  element.style.position = 'absolute';
  element.classList.add(...options.containerClassList);
  element.innerHTML = html;

  // append the element into the dom so that styles can be calculated.
  BackgroundDomService.appendElement(element);
  elementStylesToInline(element, options);
  BackgroundDomService.removeElement(element);
  return element.innerHTML;
}

/**
 *
 * @param container Parent element relative to which the child will be calculated
 * @param el Children element within the parent one
 * @param absolute Determine whether the element is absolutely invisible (e.g. if 1px of the children element still visible - function will return true)
 * @returns true/false
 */

const isElementInView = function (
  container: HTMLElement,
  el: HTMLElement,
  absolute = false
) {
  if (!(container instanceof HTMLElement)) {
    throw new Error('Container is not valid HTML element');
  }

  if (!(el instanceof HTMLElement)) {
    throw new Error('Element is not valid HTML element');
  }

  const { top: cTop, bottom: cBottom } = container.getBoundingClientRect();
  const { top: elTop, bottom: elBottom, height } = el.getBoundingClientRect();

  if (
    elTop >= cTop - (absolute ? height : 0) &&
    elBottom <= cBottom + (absolute ? height : 0)
  ) {
    return true;
  }

  return false;
};

export { isElementInView };

export function copyElementAttributes(
  sourceElement: HTMLElement,
  targetElement: HTMLElement
) {
  for (const attr of sourceElement.attributes) {
    targetElement.setAttribute(attr.name, attr.value);
  }
}

export function stripHtml(htmlString: string): string {
  if (!htmlString) {
    return htmlString;
  }
  return htmlString.replace(/<[^>]+>/g, '').replaceAll(ZERO_WIDTH_SPACE, '');
}

export async function waitDocumentHasFocus(
  timeoutMs: number
): Promise<boolean> {
  if (document.hasFocus()) {
    return true;
  }
  const startTime = new Date().getTime();
  while (new Date().getTime() - startTime < timeoutMs) {
    if (document.hasFocus()) {
      return true;
    }
    await wait(100);
  }
  return false;
}

export function findElementParents(el: HTMLOrSVGElement): HTMLOrSVGElement[] {
  if (!el) {
    return [];
  }
  const parents = [el];
  const parentElement = (el as SVGElement | HTMLElement).parentElement;
  const outerParents = findElementParents(parentElement);
  parents.push(...outerParents);
  return parents;
}

export function convertPixelsToPoints(pixels) {
  return pixels * (72 / 96);
}

export function convertPointsToPixels(points) {
  return points * (96 / 72);
}
