




















import { Vue, Component } from 'vue-property-decorator';
import Notification from './Notification.vue';

import { list } from './index';

@Component({
  name: 'AppNotification',
  components: { Notification },
})
export default class AppNotification extends Vue {
  list = list;

  // notification to be displayed
  get notifications() {
    return [...list.list].reverse().slice(0, list.displayCount);
  }
}
