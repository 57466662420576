var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"j-inline-flex j-cursor-default",on:{"click":function($event){return _vm.$emit('click', $event)}}},_vm._l((_vm.userList),function(user,i){return _c('li',{key:i,staticClass:"j-inline-flex j-items-center j-justify-center j-leading-none j-uppercase j-rounded-full j--mr-4 last:j-mr-0",class:[
      _vm.sizeClass,
      _vm.backgroundColor,
      _vm.fontColor,
      {
        'j-ring-2 j-ring-white': !_vm.noBorder,
      } ],style:(Object.assign({}, (!_vm.backgroundColor
        ? {
            backgroundColor: _vm.isPrimitive
              ? _vm.randomColor()
              : _vm.randomColor(user.id),
          }
        : null)))},[_vm._v(" "+_vm._s(_vm.isPrimitive ? user : user.abbr)+" ")])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }