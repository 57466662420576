import { FillDto, FontDto, FontStyleDto } from '@/api/models';
import _, { size } from 'lodash';
import userConfig from '../config/userConfig';
import CommandManager from '../services/editor/CommandManager';
import ICommandHandler from '../services/editor/ICommandHandler';
import { ZERO_WIDTH_SPACE } from './DiagramUtils';
import i18n from '@/core/plugins/vue-i18n';
import CommonEditorConfig from '@/core/config/ckEditor/commonEditorConfig';
import i18nService from '../services/i18n.service';

export default class CKEditorUtils {
  private static getFontStyleFromModel(
    model: String,
    fontStyles: FontStyleDto[]
  ): FontStyleDto {
    if (!fontStyles) return null;
    return fontStyles.find((fs) => fs.model == model);
  }

  public static applyFontStyleToConfiguration(
    configToUpdate: any,
    fontStyles: FontStyleDto[]
  ) {
    if (!fontStyles || fontStyles.length === 0) {
      return configToUpdate;
    }

    const config = _.cloneDeep(configToUpdate);

    for (let i = 0; i < config.heading.options.length; i++) {
      const fs = this.getFontStyleFromModel(
        config.heading.options[i].model,
        fontStyles
      );
      if (!fs) continue;
      config.heading.options[i].title = fs.title;
    }
    return config;
  }

  public static generatePtSetting(sizes: number | number[]) {
    if (!Array.isArray(sizes) && !isNaN(sizes)) {
      sizes = [sizes];
    }
    return (sizes as number[]).map((size) => {
      return {
        model: `${size}pt`,
        title: size,
        view: {
          name: 'span',
          styles: {
            'font-size': `${size}pt`,
          },
        },
      };
    });
  }

  public static setCkEditorFontStyles(fontStyles: FontStyleDto[]) {
    fontStyles.forEach((fs) => {
      CKEditorUtils.removeFontStyleSheet(fs);
    });
    CKEditorUtils.addStyleSheet(fontStyles);
  }

  public static removeFontStyleSheet(fontStyle: FontStyleDto) {
    const styleSheetId = fontStyle.model + fontStyle.cssElement;
    var sheet = document.getElementById(styleSheetId);
    if (sheet) {
      sheet.parentNode.removeChild(sheet);
    }
  }

  public static addStyleSheet(fontStyles: FontStyleDto[]) {
    fontStyles.forEach((fs) => {
      var style = document.createElement('style');
      style.id = fs.model + fs.cssElement;
      style.type = 'text/css';

      style.innerHTML = `${fs.cssElement}, ${fs.cssElement} span
       { font-family: ${fs.style.fontFamily}, sans-serif !important;
         font-size: ${fs.style.fontSize}pt !important;
         font-weight: ${fs.style.fontWeight} !important;
         text-decoration: ${fs.style.textDecoration} !important;
         font-style: ${fs.style.fontStyle} !important; }`;
      document.getElementsByTagName('head')[0].appendChild(style);
    });
  }

  public static createHtmlStringFromStyle(
    fill?: FillDto,
    font?: FontDto,
    text?: string,
    backgroundColor?: string
  ): string {
    const paragraphElement = document.createElement('p');
    paragraphElement.style.textAlign = 'center';

    // font color,family & size must be attached to this element
    const spanElement = document.createElement('span');
    let innerElement: HTMLElement = spanElement;

    if (font) {
      spanElement.style.fontFamily = font.fontFamily;
      spanElement.style.fontSize = `${font.fontSize}pt`;

      if (font.fontStyle == 'Italic') {
        const childElement = document.createElement('i');
        innerElement.appendChild(childElement);
        innerElement = childElement;
      }

      if (font.fontWeight == 'Bold') {
        const childElement = document.createElement('b');
        innerElement.appendChild(childElement);
        innerElement = childElement;
      }

      if (font.textDecoration == 'Underline') {
        const childElement = document.createElement('u');
        innerElement.appendChild(childElement);
        innerElement = childElement;
      }
    }
    if (fill) {
      spanElement.style.color = `${fill.color}`;
    }
    if (backgroundColor) {
      spanElement.style.backgroundColor = backgroundColor;
    }

    // once all elements have been placed, insert the final inner span.
    innerElement.innerText = text ?? ZERO_WIDTH_SPACE;
    paragraphElement.appendChild(spanElement);
    return paragraphElement.outerHTML.replaceAll('&quot;', "'");
  }
  /**
   * Syncronizes the state of the toolbar with the ribbon
   * @param editor The CKEditor Instance
   * @param ribbonToolbarSelector The toolbar inside
   * @param toolbarToolsSelector The Ribbon tool selctor
   * @param isFocused is ckEditor focused?
   * @param commandHandler the command manager to send the state through
   */
  public static toolbarStateSync(
    editor,
    ribbonToolbarSelector: string,
    toolbarToolsSelector: string,
    isFocused: boolean,
    commandHandler: ICommandHandler
  ) {
    // locate the toolbar element
    const toolbarElement = editor.ui.view.toolbar.element;

    // Set focused editor toolbar

    CKEditorUtils.ensureToolbar(
      ribbonToolbarSelector,
      toolbarToolsSelector,
      toolbarElement
    );

    if (isFocused) {
      CommandManager.INSTANCE.setFocus(commandHandler);
    } else {
      CommandManager.INSTANCE.unfocus();
    }
  }
  /**
   * Inserts the toolbarElement into the correct container
   * @param ribbonToolbarSelector
   * @param toolbarToolsSelector
   * @param toolbarElement
   * @returns
   */
  public static ensureToolbar(
    ribbonToolbarSelector: string,
    toolbarToolsSelector: string,
    toolbarElement: HTMLElement
  ) {
    const ribbonToolbarElement = document.querySelector(ribbonToolbarSelector);
    let container: HTMLElement = ribbonToolbarElement as HTMLElement;
    if (!ribbonToolbarElement) {
      console.debug(
        'Unable to locate the top level container for the toolbar, does it exist?'
      );
      return;
    }
    const toolsElement =
      ribbonToolbarElement.querySelector(toolbarToolsSelector);
    if (toolsElement) {
      container = toolsElement as HTMLElement;
    }
    if (container.querySelectorAll('.ck.ck-toolbar').length != 0) {
      (container as any).replaceChildren(...[]);
    }
    container.appendChild(toolbarElement);
  }

  /**
   * Adds predefined elements to the ckEditor focusTracker elements list
   * @param editor The CKEditor Instance
   */
  public static addElementsToFocusTracker(editor) {
    const clipboardEl = document.querySelector('[rb-name="clipboard"]');
    if (clipboardEl) {
      editor.ui.focusTracker.add(clipboardEl);
    }
    const undoRedoEl = document.querySelector('[rb-name="undo-redo"]');
    if (undoRedoEl) {
      editor.ui.focusTracker.add(undoRedoEl);
    }
  }

  private static translationsLoaded = false;
  public static ensureTranslationsLoaded() {
    if (this.translationsLoaded) {
      return;
    }
    this.translationsLoaded = true;
    const activeLanguage = i18nService.getActiveLanguage();
    const allMessages: { [key: string]: string } = i18n.messages[
      activeLanguage
    ] as any;
    const editorMessages = {};
    Object.keys(allMessages)
      .filter((key) => key.startsWith('EDITOR_'))
      .forEach(
        (key) =>
          (editorMessages[key.substr('EDITOR_'.length)] = allMessages[key])
      );

    // Make sure that the global object is defined. If not, define it.
    CKEDITOR_TRANSLATIONS = CKEDITOR_TRANSLATIONS || {};

    // Make sure that the dictionary for translations exists
    const lang = CommonEditorConfig.getConfig().language;
    CKEDITOR_TRANSLATIONS[lang] = CKEDITOR_TRANSLATIONS[lang] || {};
    CKEDITOR_TRANSLATIONS[lang].dictionary =
      CKEDITOR_TRANSLATIONS[lang].dictionary || {};

    // Extend the CKEditor dictionary with our translations
    Object.assign(CKEDITOR_TRANSLATIONS[lang].dictionary, editorMessages);
  }
}

const ribbonToolsSelector: string = '.vc-ribbon-ribbon-tools';
const toolbarSelector: string = '[rb-name="ckeditor-toolbar"]';

export { toolbarSelector, ribbonToolsSelector };
