




























export default {
  name: 'debug',
  data: () => {
    return {
      currentPosition: null,
      hidden: false,
      isDragging: true,
      JSON: JSON,
    };
  },
  methods: {
    beginDrag(e) {
      if (this.hidden) {
        return;
      }
      let elRect = this.getElement().getBoundingClientRect();
      this.offset = { x: elRect.left - e.pageX, y: elRect.top - e.pageY };
      this.isDragging = true;
      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mouseup', this.endDrag);
    },
    endDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.handleMouseMove);
      document.removeEventListener('mouseup', this.endDrag);
    },
    handleMouseMove(e) {
      let pos = {
        x: e.pageX + this.offset.x,
        y: e.pageY + this.offset.y,
      };
      this.setPos(pos);
    },

    setPos(pos) {
      this.currentPosition = pos;
      let el = this.getElement();
      if (pos.x < 0) {
        pos.x = 0;
      }
      if (pos.x + el.clientWidth > window.innerWidth) {
        pos.x = window.innerWidth - el.clientWidth;
      }

      if (pos.y < 0) {
        pos.y = 0;
      }
      if (pos.y + el.clientHeight > window.innerHeight) {
        pos.y = window.innerHeight - el.clientHeight;
      }
      el.style.left = `${pos.x}px`;
      el.style.top = `${pos.y}px`;
      localStorage.setItem('debug-pos', JSON.stringify(pos));
    },
    setHiddenState(hidden: boolean) {
      this.hidden = hidden;
      localStorage.setItem('debug-hidden', JSON.stringify(hidden));
    },
    getElement() {
      return this._el ?? (this._el = this.$refs['draggable']);
    },
    handleWindowResize(e) {
      this.setPos(this.currentPosition);
    },
  },
  mounted() {
    if (localStorage.getItem('debug-pos')) {
      this.currentPosition = JSON.parse(localStorage.getItem('debug-pos'));
      this.setPos(this.currentPosition);
    }
    if (localStorage.getItem('debug-hidden')) {
      this.hidden = JSON.parse(localStorage.getItem('debug-hidden'));
    }
    window.addEventListener('resize', this.handleWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
    this.endDrag(); // cancel out any existing drag
  },
};
