import {
  CurrentUserProfileEditDto,
  DiagramDto,
  DocumentAccessDto,
  DocumentAccessLevel,
  DocumentAccessRequestDto,
  DocumentAttachmentDto,
  DocumentAttachmentType,
  DocumentDto,
  DocumentPageDto,
  DocumentPageType,
  DocumentSubPageDto,
  EditDocumentAutoSaveDto,
  ElementType,
  HeaderFooterStyleDto,
  PageElementPosition,
  QuickBuildState,
  ThemeAttachmentType,
  ThemeDto,
} from '@/api/models';
import { StepsViewType } from '@/core/common/StepsViewType';
import { RibbonTab } from '@/view/pages/document/ribbon/RibbonTab';
import { StepsViewTypeState } from '@/view/pages/document/StepsViewTypeState';
import { Module } from 'vuex';
import * as _ from 'lodash';
import { isArray, isNil, set } from 'lodash';
import DocumentsApiService from '@/api/DocumentsApiService';
import { SidebarMenu } from '@/view/pages/document/sidebar';
import store from '.';
import { DocumentFocusedSide } from '@/view/pages/document/DocumentFocusedSide';
import DocumentAccessApiService from '@/api/DocumentAccessApiService';
import { GET_CURRENT_USER } from './user.module';
import {
  IPersistedDataPropertyDisplayTypes,
  JurisdictionValueType,
} from '@/core/styles/decorators/JurisdictionDecorator';
import { DataPropertyDisplayType } from '@/core/common/DataPropertyDisplayType';
import NotificationUtils, {
  NotificationType,
} from '@/core/utils/NotificationUtils';

// mutation types
export const SET_DOCUMENT = 'setDocument';
export const SET_DOCUMENT_FILTERS = 'setDocumentFilters';
export const SET_DOCUMENT_MODIFICATION_TIME = 'setDocumentModificationTime';
export const SET_SELECTED_PAGE = 'setSelectedPage';
export const SET_SELECTED_SUBPAGE_INDEX = 'setSelectedSubPageIndex';
export const SET_SAVING_STATE = 'setSavingState';
export const SET_SELECTION = 'setSelection';
export const SET_CURRENT_THEME = 'setCurrentTheme';
export const SET_DOCUMENT_HEADER_STYLE = 'setDocumentHeaderStyle';
export const SET_DOCUMENT_FOOTER_STYLE = 'setDocumentFooterStyle';
export const GET_DOCUMENT = 'getDocument';
export const GET_DOCUMENT_FILTERS = 'getDocumentFilters';
export const GET_DOCUMENT_MODIFICATION_TIME = 'getDocumentModificationTime';
export const GET_SELECTED_PAGE = 'getSelectedPage';
export const GET_SELECTED_SUBPAGE_INDEX = 'getSelectedSubpageIndex';
export const GET_SAVING_STATE = 'getSavingState';
export const GET_THEME_ELEMENT_BY_NAME = 'getThemeElementByName';
export const GET_CURRENT_THEME = 'getCurrentTheme';
export const DOCUMENT_NAMESPACE = 'document';
export const REFRESH_CURRENT_THEME = 'refreshCurrentTheme';
export const GET_SELECTION = 'getSelection';
export const SET_QUICK_BUILD_STATE = 'setQuickBuild';
export const GET_QUICK_BUILD_STATE = 'getQuickBuild';
export const GET_RIBBON_DEFAULT_TAB = 'getRibbonDefaultTab';
export const SET_RIBBON_DEFAULT_TAB = 'setRibbonDefaultTab';
export const SET_DOCUMENT_HEADER_FOOTER_STYLE = 'setDocumentHeaderFooterStyle';
export const SET_DOCUMENT_NAME = 'setDocumentName';
export const SET_DOCUMENT_AUTOSAVE = 'setDocumentAutoSave';
export const UNLOAD_DOCUMENT = 'unloadDocument';
export const GET_LEGEND_DEFINITION = 'getLegendDefinition';
export const SET_LEGEND_DEFINITION = 'setLegendDefinition';
export const SET_STEPS_VIEW_TYPE_STATE = 'setStepsViewType';
export const GET_STEPS_VIEW_TYPE_STATE = 'getStepsViewType';
export const UPDATE_DOCUMENT_FROM_THEME = 'updateDocumentFromTheme';
export const SET_DOCUMENT_HEADER_FOOTER_VISIBILITY =
  'setDocumentHeaderFooterVisibility';
export const SET_DOCUMENT_PAGE_HEADER_FOOTER_VISIBILITY =
  'setDocumentPageHeaderFooterVisibility';
export const ADD_DOCUMENT_ATTACHMENT = 'addDocumentAttachment';
export const SET_ACTIVE_SIDEBAR_BUTTON = 'setActiveSidebarButton';
export const GET_SELECTED_DIAGRAM = 'getSelectedDiagram';
export const UPDATE_SELECTED_DIAGRAM = 'updateSelectedDiagram';
export const GET_SHOWN_HEADER = 'getShownHeader';
export const GET_SHOWN_FOOTER = 'getShownFooter';
export const GET_IS_SIDEBAR_OPEN = 'getIsSidebarOpen';
export const GET_LAST_SELECTED_SIDEBAR_BUTTON = 'getLastSelectedSidebarButton';
export const SET_LAST_SELECTED_SIDEBAR_BUTTON = 'setLastSelectedSidebarButton';
export const GET_SELECTED_SUBPAGE_REF = 'getSelectedSubpageRef';
export const GET_SELECTED_PAGE_HEADER_HTML = 'getSelectedPageHeaderHtml';
export const SET_SELECTED_PAGE_HEADER_HTML = 'setSelectedPageHeaderHtml';
export const GET_SELECTED_PAGE_FOOTER_HTML = 'getSelectedPageFooterHtml';
export const SET_SELECTED_PAGE_FOOTER_HTML = 'setSelectedPageFooterHtml';
export const SET_SELECTED_PAGE_CONTENT = 'setSelectedPageContent';
export const SET_PAGE_HEADERS_FROM_THEME = 'setPageHeadersFromTheme';
export const SET_PAGE_FOOTERS_FROM_THEME = 'setPageFootersFromTheme';
export const SET_SUBPAGE_HEADER_FROM_THEME = 'setSubPageHeaderFromTheme';
export const SET_SUBPAGE_FOOTER_FROM_THEME = 'setSubPageFooterFromTheme';
export const SET_READONLY = 'setReadOnly';
export const GET_READONLY = 'getReadOnly';
export const GET_FOCUSED_SIDE = 'getFocusedSide';
export const SET_FOCUSED_SIDE = 'setFocusedSide';
export const SET_DOCUMENT_PAGE_NUMBERING =
  'setDocumentPageNumberingVisibility;';
export const GET_SHOW_PAGE_NUMBERING = 'getShowPageNumbering';
export const SET_SAVE_FAILED = 'setSaveFailed';
export const GET_SAVE_FAILED = 'getSaveFailed';
export const SET_DOCUMENT_LOADING = 'setDocumentLoading';
export const GET_DOCUMENT_LOADING = 'getDocumentLoading';
export const SET_SELECTED_PAGE_FILTER_DEFINITION =
  'setSelectedPageFilterDefinition';
export const SET_DOCUMENT_LEGEND_POSITION = 'setDocumentLegendPosition';

export const GET_DOCUMENT_ACCESS = 'getDocumentAccess';
export const SET_DOCUMENT_ACCESS = 'setDocumentAccess';
export const RELOAD_DOCUMENT_ACCESS = 'reloadDocumentAccess';
export const GET_DOCUMENT_ACCESS_REQUESTS = 'getDocumentAccessRequests';
export const SET_DOCUMENT_ACCESS_REQUESTS = 'setDocumentAccessRequests';
export const RELOAD_DOCUMENT_ACCESS_REQUESTS = 'reloadDocumentAccessRequests';
export const GET_DOCUMENT_ACCESS_LEVEL = 'getDocumentAccessLevel';
export const SET_DOCUMENT_ACCESS_LEVEL = 'setDocumentAccessLevel';
export const GET_DOCUMENT_ACCESS_FORBIDDEN = 'getDocumentAccessForbidden';
export const SET_DOCUMENT_ACCESS_FORBIDDEN = 'setDocumentAccessForbidden';
export const SET_DOCUMENT_OWNER = 'setDocumentOwner';
export const GET_IS_DOCUMENT_OWNER = 'getIsDocumentOwner';

export const SET_PERSISTED_DATA_PROPERTY_DISPLAY_TYPES =
  'setPersistedDataPropertyDisplayTypes';
export const GET_PERSISTED_DATA_PROPERTY_DISPLAY_TYPES =
  'getPersistedDataPropertyDisplayTypes';
export const GET_DOCUMENT_AUTOSAVE = 'getDocumentAutoSave';

const getInitialState = () => {
  return {
    document: null,
    access: [],
    accessRequests: [],
    accessLevel: DocumentAccessLevel.View,
    documentModificationTime: null,
    selectedPage: null,
    selectedSubPageIndex: 0,
    saving: false,
    saveFailed: false,
    selection: null,
    currentTheme: null,
    quickBuildState: QuickBuildState.Initial,
    ribbonDefaultTab: RibbonTab.Home,
    legendDefinition: null,
    stepsViewType: {
      previous: null,
      current: StepsViewType.Vertical,
    },
    selectedSidebarButton: null,
    lastSelectedSidebarButton: null,
    focusedSide: null,
    isReadOnly: false,
    isLoading: false,
    isAccessForbidden: false,
    skipLeaveConfirmation: false,
    persistedDataPropertyDisplayTypes: {},
  };
};
interface State {
  document: DocumentDto;
  access: DocumentAccessDto[];
  accessRequests: DocumentAccessRequestDto[];
  accessLevel: DocumentAccessLevel;
  documentModificationTime: null | string;
  selectedPage: DocumentPageDto;
  selectedSubPageIndex: number;
  saving: boolean;
  saveFailed: boolean;
  selection: any;
  currentTheme: ThemeDto;
  quickBuildState: QuickBuildState;
  ribbonDefaultTab: RibbonTab;
  legendDefinition: any;
  stepsViewType: {
    previous: StepsViewType;
    current: StepsViewType;
  };
  selectedSidebarButton: SidebarMenu;
  lastSelectedSidebarButton: SidebarMenu;
  focusedSide: DocumentFocusedSide;
  isReadOnly: boolean;
  isLoading: boolean;
  isAccessForbidden: boolean;
  skipLeaveConfirmation: boolean;
  persistedDataPropertyDisplayTypes: IPersistedDataPropertyDisplayTypes;
}
const documentModule: Module<State, any> = {
  namespaced: true,
  state: getInitialState(),
  getters: {
    [GET_DOCUMENT](state) {
      return state.document;
    },
    [GET_DOCUMENT_ACCESS](state) {
      return state.access;
    },
    [GET_DOCUMENT_ACCESS_REQUESTS](state) {
      return state.accessRequests;
    },
    [GET_DOCUMENT_ACCESS_LEVEL](state) {
      return state.accessLevel;
    },
    [GET_DOCUMENT_ACCESS_FORBIDDEN](state) {
      return state.isAccessForbidden;
    },
    [GET_IS_DOCUMENT_OWNER](state) {
      const currentUser = store.getters[
        GET_CURRENT_USER
      ] as CurrentUserProfileEditDto;
      return currentUser?.userId == state.document?.ownerUserId;
    },
    [GET_DOCUMENT_FILTERS](state) {
      return state.document?.filters;
    },
    [GET_DOCUMENT_MODIFICATION_TIME](state) {
      return state.documentModificationTime;
    },
    [GET_THEME_ELEMENT_BY_NAME](state) {
      return (name: string, elementType?: ElementType) => {
        let element = state.currentTheme.elements.find(
          (x) =>
            x.name.toLowerCase() == name.toLowerCase() &&
            (!elementType || x.elementType == elementType)
        );
        return element;
      };
    },
    [GET_QUICK_BUILD_STATE](state) {
      return state.quickBuildState;
    },
    [GET_CURRENT_THEME](state) {
      return state.currentTheme;
    },
    [GET_SELECTED_PAGE](state) {
      return state.selectedPage;
    },
    [GET_SELECTED_SUBPAGE_INDEX](state) {
      return state.selectedSubPageIndex;
    },
    [GET_SAVING_STATE](state) {
      return state.saving;
    },
    [GET_RIBBON_DEFAULT_TAB](state) {
      return state.ribbonDefaultTab;
    },
    [GET_LEGEND_DEFINITION](state) {
      return state.legendDefinition;
    },
    [GET_STEPS_VIEW_TYPE_STATE](state) {
      return state.stepsViewType;
    },
    [GET_SELECTED_DIAGRAM](state) {
      if (
        state.selectedPage &&
        (state.selectedPage.pageType === DocumentPageType.Diagram ||
          state.selectedPage.pageType === DocumentPageType.Split)
      ) {
        let diagram = state.selectedPage.subPageRefs?.find(
          (r) =>
            r.pageId == state.selectedPage.id &&
            r.subPageIndex == state.selectedSubPageIndex
        )?.diagram;
        if (!diagram) {
          diagram = state.selectedPage.diagram;
        }
        return diagram;
      }
      return null;
    },
    [GET_SHOWN_HEADER](state) {
      return state.selectedPage?.showHeader;
    },
    [GET_SHOWN_FOOTER](state) {
      return state.selectedPage?.showFooter;
    },
    [GET_DOCUMENT_AUTOSAVE](state) {
      return state.document?.autoSave;
    },
    [GET_IS_SIDEBAR_OPEN](state) {
      return state.selectedSidebarButton !== null;
    },
    [GET_LAST_SELECTED_SIDEBAR_BUTTON](state) {
      return state.lastSelectedSidebarButton;
    },
    [GET_SELECTED_SUBPAGE_REF](state) {
      return state.selectedPage?.subPageRefs?.find(
        (sp) => sp.subPageIndex === state.selectedSubPageIndex
      );
    },
    [GET_SELECTED_PAGE_HEADER_HTML](state, getters) {
      const selectedSubpageRef = getters[GET_SELECTED_SUBPAGE_REF];
      if (selectedSubpageRef) {
        return selectedSubpageRef.headerHtml ?? '';
      }
      return state.selectedPage?.headerHtml ?? '';
    },
    [GET_SELECTED_PAGE_FOOTER_HTML](state, getters) {
      const selectedSubpageRef = getters[GET_SELECTED_SUBPAGE_REF];
      if (selectedSubpageRef) {
        return selectedSubpageRef.footerHtml ?? '';
      }
      return state.selectedPage?.footerHtml ?? '';
    },
    [GET_FOCUSED_SIDE](state) {
      return state.focusedSide;
    },
    [GET_SHOW_PAGE_NUMBERING](state) {
      return state.document.showPageNumbering;
    },
    [GET_READONLY](state) {
      return state.isReadOnly;
    },
    [GET_DOCUMENT_LOADING](state) {
      return state.isLoading;
    },
    [GET_SAVE_FAILED](state) {
      return state.saveFailed;
    },
    [GET_PERSISTED_DATA_PROPERTY_DISPLAY_TYPES](state) {
      return state.persistedDataPropertyDisplayTypes;
    },
  },
  actions: {
    [SET_READONLY](context, payload) {
      context.commit(SET_READONLY, payload);
    },
    [SET_DOCUMENT_LOADING](context, payload) {
      context.commit(SET_DOCUMENT_LOADING, payload);
    },
    [SET_SAVE_FAILED](context, payload) {
      context.commit(SET_SAVE_FAILED, payload);
    },
    [REFRESH_CURRENT_THEME](context) {
      if (!context.state.currentTheme) {
        return;
      }
      let theme = context.rootState.themes?.themes?.find(
        (d) => d.id == context.state.currentTheme.id
      );
      if (!theme) {
        return;
      }
      context.commit(REFRESH_CURRENT_THEME, theme);
    },
    async [SET_DOCUMENT](context, payload: DocumentDto) {
      context.commit(SET_DOCUMENT, payload);
      context.commit(
        SET_DOCUMENT_MODIFICATION_TIME,
        payload.lastModificationTime
      );
      await context.dispatch(RELOAD_DOCUMENT_ACCESS, { documentId: payload.id });
      await context.dispatch(RELOAD_DOCUMENT_ACCESS_REQUESTS, { documentId: payload.id });
    },
    async [RELOAD_DOCUMENT_ACCESS](context, payload) {
      const documentAccess = (
        await DocumentAccessApiService.getDocumentAccess({
          documentId: payload.documentId,
        })
      ).data.result;
      context.commit(SET_DOCUMENT_ACCESS, documentAccess);
    },
    async [RELOAD_DOCUMENT_ACCESS_REQUESTS](context, payload) {
      const documentAccessRequests = (
        await DocumentAccessApiService.getDocumentAccessRequests({
          documentId: payload.documentId
        })
      ).data.result;
      context.commit(SET_DOCUMENT_ACCESS_REQUESTS, documentAccessRequests);
    },
    [SET_DOCUMENT_ACCESS](context, payload) {
      context.commit(SET_DOCUMENT_ACCESS, payload);
    },
    [SET_DOCUMENT_ACCESS_REQUESTS](context, payload) {
      context.commit(SET_DOCUMENT_ACCESS_REQUESTS, payload);
    },
    [SET_DOCUMENT_ACCESS_LEVEL](context, payload) {
      context.commit(SET_DOCUMENT_ACCESS_LEVEL, payload);
    },
    [SET_DOCUMENT_ACCESS_FORBIDDEN](context, payload) {
      context.commit(SET_DOCUMENT_ACCESS_FORBIDDEN, payload);
    },
    [SET_DOCUMENT_OWNER](context, payload) {
      context.commit(SET_DOCUMENT_OWNER, payload);
    },
    [SET_DOCUMENT_FILTERS](context, payload) {
      context.commit(SET_DOCUMENT_FILTERS, payload);
    },
    [SET_SELECTED_PAGE](context, payload) {
      context.commit(SET_SELECTED_PAGE, payload);
    },
    [SET_SELECTED_SUBPAGE_INDEX](context, payload) {
      context.commit(SET_SELECTED_SUBPAGE_INDEX, payload);
    },
    [SET_SAVING_STATE](context, payload) {
      context.commit(SET_SAVING_STATE, payload);
    },
    [SET_SELECTION](context, payload) {
      context.commit(SET_SELECTION, payload);
    },
    [SET_CURRENT_THEME](context, payload) {
      context.commit(SET_CURRENT_THEME, payload);
    },
    [SET_QUICK_BUILD_STATE](context, payload) {
      context.commit(SET_QUICK_BUILD_STATE, payload);
    },
    [SET_RIBBON_DEFAULT_TAB](state, payload) {
      state.commit(SET_RIBBON_DEFAULT_TAB, payload);
    },
    [SET_DOCUMENT_HEADER_STYLE](state, payload) {
      state.commit(SET_DOCUMENT_HEADER_STYLE, payload);
    },
    [SET_DOCUMENT_FOOTER_STYLE](state, payload) {
      state.commit(SET_DOCUMENT_FOOTER_STYLE, payload);
    },
    [UNLOAD_DOCUMENT](state) {
      state.commit(UNLOAD_DOCUMENT);
    },
    [SET_DOCUMENT_HEADER_FOOTER_STYLE](state, payload) {
      state.commit(SET_DOCUMENT_HEADER_FOOTER_STYLE, payload);
    },
    [SET_DOCUMENT_NAME](state, payload) {
      state.commit(SET_DOCUMENT_NAME, payload);
    },
    async [SET_DOCUMENT_AUTOSAVE](state, payload: EditDocumentAutoSaveDto) {
      await DocumentsApiService.updateDocumentAutoSave({
        documentId: payload.documentId,
        autoSave: payload.autoSave,
      });
      state.commit(SET_DOCUMENT_AUTOSAVE, payload.autoSave);
    },
    [SET_LEGEND_DEFINITION](state, payload) {
      state.commit(SET_LEGEND_DEFINITION, payload);
    },
    [SET_STEPS_VIEW_TYPE_STATE](state, payload) {
      state.commit(SET_STEPS_VIEW_TYPE_STATE, payload);
    },
    [SET_SELECTED_PAGE_HEADER_HTML](context, payload) {
      context.commit(SET_SELECTED_PAGE_HEADER_HTML, payload);
    },
    [SET_SELECTED_PAGE_FOOTER_HTML](context, payload) {
      context.commit(SET_SELECTED_PAGE_FOOTER_HTML, payload);
    },
    [SET_SELECTED_PAGE_CONTENT](context, payload) {
      context.commit(SET_SELECTED_PAGE_CONTENT, payload);
    },
    [UPDATE_SELECTED_DIAGRAM](state, payload) {
      state.commit(UPDATE_SELECTED_DIAGRAM, payload);
    },
    [UPDATE_DOCUMENT_FROM_THEME](ctx, payload: { theme: ThemeDto }) {
      const theme = payload.theme;
      const document = ctx.state.document;

      // LOGO
      replaceDocumentAttachmentsFromTheme(
        document,
        theme,
        DocumentAttachmentType.Logo,
        ThemeAttachmentType.Logo
      );
      replaceDocumentAttachmentsFromTheme(
        document,
        theme,
        DocumentAttachmentType.CoverImage,
        ThemeAttachmentType.CoverImage
      );
      replaceDocumentAttachmentsFromTheme(
        document,
        theme,
        DocumentAttachmentType.HeaderSeparator,
        ThemeAttachmentType.HeaderSeparator
      );
      replaceDocumentAttachmentsFromTheme(
        document,
        theme,
        DocumentAttachmentType.FooterSeparator,
        ThemeAttachmentType.FooterSeparator
      );

      replaceDocumentAttachmentsFromTheme(
        document,
        theme,
        DocumentAttachmentType.FillerImage,
        ThemeAttachmentType.FillerImage
      );
      replaceDocumentAttachmentsFromTheme(
        document,
        theme,
        DocumentAttachmentType.ClosingImage,
        ThemeAttachmentType.ClosingImage
      );

      replaceDocumentAttachmentsFromTheme(
        document,
        theme,
        DocumentAttachmentType.PageBackground,
        ThemeAttachmentType.PageBackground
      );
      replaceDocumentAttachmentsFromTheme(
        document,
        theme,
        DocumentAttachmentType.HeaderBackground,
        ThemeAttachmentType.HeaderBackground
      );
      replaceDocumentAttachmentsFromTheme(
        document,
        theme,
        DocumentAttachmentType.FooterBackground,
        ThemeAttachmentType.FooterBackground
      );

      document.headerHtml = theme.headerHtml;
      document.footerHtml = theme.footerHtml;
      document.coverPageTemplate = theme.coverPageTemplate;
      document.fillerPageTemplate = theme.fillerPageTemplate;
      document.closingPageTemplate = theme.closingPageTemplate;
      document.logoPosition = theme.logoPosition;
      document.legendPosition = theme.legendPosition;
      document.lastSelectedThemeId = theme.id;
      document.defaultPageType = theme.defaultPageType;
      document.headerStyle = theme.headerStyle
        ? _.cloneDeep(theme.headerStyle)
        : {};
      document.footerStyle = theme.footerStyle
        ? _.cloneDeep(theme.footerStyle)
        : {};
      document.tableSwatch = theme.tableSwatch
        ? _.cloneDeep(theme.tableSwatch)
        : {};
      document.fontStyles = _.cloneDeep(theme.fontStyles);
      ctx.commit(UPDATE_DOCUMENT_FROM_THEME, document);
    },
    [SET_DOCUMENT_HEADER_FOOTER_VISIBILITY](ctx, payload) {
      ctx.commit(SET_DOCUMENT_HEADER_FOOTER_VISIBILITY, payload);
    },

    [SET_DOCUMENT_PAGE_HEADER_FOOTER_VISIBILITY](ctx, payload) {
      ctx.commit(SET_DOCUMENT_PAGE_HEADER_FOOTER_VISIBILITY, payload);
    },
    [ADD_DOCUMENT_ATTACHMENT](ctx, payload: DocumentAttachmentDto) {
      const attachment = payload;
      DocumentsApiService.addDocumentAttachment(payload).then((response) => {
        if (response.data.result && response.data.result > 0) {
          attachment.id = response.data.result;
          let attachmentExists = ctx.state.document.attachments.some(
            (a) =>
              a.documentId === payload.documentId &&
              a.fileAttachmentId === payload.fileAttachmentId
          );
          if (attachmentExists) return;
          ctx.commit(ADD_DOCUMENT_ATTACHMENT, attachment);
        }
      });
    },
    [SET_FOCUSED_SIDE](ctx, payload) {
      ctx.commit(SET_FOCUSED_SIDE, payload);
    },
    [SET_PAGE_HEADERS_FROM_THEME](ctx, payload) {
      ctx.commit(SET_PAGE_HEADERS_FROM_THEME, payload);
    },
    [SET_PAGE_FOOTERS_FROM_THEME](ctx, payload) {
      ctx.commit(SET_PAGE_FOOTERS_FROM_THEME, payload);
    },
    [SET_SUBPAGE_HEADER_FROM_THEME](ctx, payload) {
      ctx.commit(SET_SUBPAGE_HEADER_FROM_THEME, payload);
    },
    [SET_SUBPAGE_FOOTER_FROM_THEME](ctx, payload) {
      ctx.commit(SET_SUBPAGE_FOOTER_FROM_THEME, payload);
    },
    [SET_DOCUMENT_PAGE_NUMBERING](ctx, payload) {
      ctx.commit(SET_DOCUMENT_PAGE_NUMBERING, payload);
    },
    [SET_SELECTED_PAGE_FILTER_DEFINITION](ctx, payload) {
      ctx.commit(SET_SELECTED_PAGE_FILTER_DEFINITION, payload);
    },
  },
  mutations: {
    [SET_READONLY](state, payload) {
      state.isReadOnly = payload;
    },
    [SET_DOCUMENT_LOADING](state, payload) {
      state.isLoading = payload;
    },
    [SET_SAVE_FAILED](state, payload) {
      state.saveFailed = payload;
    },
    [SET_DOCUMENT](state, payload) {
      if (!payload.headerStyle) {
        payload.headerStyle = {} as HeaderFooterStyleDto;
      }
      if (!payload.footerStyle) {
        payload.footerStyle = {} as HeaderFooterStyleDto;
      }
      state.document = payload;
    },
    [SET_DOCUMENT_ACCESS](state, payload) {
      state.access = payload;
    },
    [SET_DOCUMENT_ACCESS_REQUESTS](state, payload) {
      state.accessRequests = payload;
    },
    [SET_DOCUMENT_ACCESS_LEVEL](state, payload) {
      state.accessLevel = payload;
    },
    [SET_DOCUMENT_ACCESS_FORBIDDEN](state, payload) {
      state.isAccessForbidden = payload;
    },
    [SET_DOCUMENT_OWNER](state, payload) {
      state.document.ownerUserId = payload;
    },
    [SET_DOCUMENT_FILTERS](state, payload) {
      state.document.filters = payload;
    },
    [SET_DOCUMENT_MODIFICATION_TIME](state, payload) {
      state.documentModificationTime = payload;
    },
    [SET_SELECTED_PAGE](state, payload) {
      state.selectedPage = payload;
    },
    [SET_SELECTED_SUBPAGE_INDEX](state, payload) {
      state.selectedSubPageIndex = payload;
    },
    [SET_SAVING_STATE](state, payload) {
      state.saving = payload;
    },
    [SET_SELECTION](state, payload) {
      state.selection = payload;
    },
    [SET_CURRENT_THEME](state, payload) {
      state.currentTheme = payload;
    },
    [REFRESH_CURRENT_THEME](state, payload) {
      state.currentTheme = null;
      state.currentTheme = payload;
    },
    [SET_QUICK_BUILD_STATE](state, payload) {
      state.quickBuildState = payload;
    },
    [SET_RIBBON_DEFAULT_TAB](state, payload) {
      state.ribbonDefaultTab = payload;
    },
    [SET_DOCUMENT_HEADER_STYLE](state, payload) {
      state.document.headerStyle = payload;
    },
    [SET_DOCUMENT_FOOTER_STYLE](state, payload) {
      state.document.footerStyle = payload;
    },
    [UNLOAD_DOCUMENT](state) {
      Object.assign(state, getInitialState());
    },
    [SET_DOCUMENT_HEADER_FOOTER_STYLE](
      state,
      payload: {
        headerStyle: HeaderFooterStyleDto;
        footerStyle: HeaderFooterStyleDto;
      }
    ) {
      state.document.headerStyle = payload.headerStyle;
      state.document.footerStyle = payload.footerStyle;
    },

    [SET_DOCUMENT_NAME](state, payload: string) {
      state.document.name = payload;
    },
    [SET_DOCUMENT_AUTOSAVE](state, payload: boolean) {
      state.document.autoSave = payload;
    },
    [SET_LEGEND_DEFINITION](state, payload) {
      state.legendDefinition = payload;
    },
    [SET_STEPS_VIEW_TYPE_STATE](state, payload) {
      state.stepsViewType = {
        previous: state.stepsViewType.current,
        current: payload,
      } as StepsViewTypeState;
    },
    [UPDATE_DOCUMENT_FROM_THEME](state, payload: DocumentDto) {
      state.document = payload;
    },
    [SET_DOCUMENT_HEADER_FOOTER_VISIBILITY](state, payload) {
      if (!isNil(payload.showHeader)) {
        state.document.showHeader = payload.showHeader;
        state.document.pages.forEach(
          (page) => (page.showHeader = payload.showHeader)
        );
      }
      if (!isNil(payload.showFooter)) {
        state.document.showFooter = payload.showFooter;
        state.document.pages.forEach(
          (page) => (page.showFooter = payload.showFooter)
        );
      }
    },
    [SET_DOCUMENT_PAGE_HEADER_FOOTER_VISIBILITY](state, payload) {
      state.selectedPage.showHeader =
        payload?.showHeader ?? state.selectedPage.showHeader;
      state.selectedPage.showFooter =
        payload?.showFooter ?? state.selectedPage.showFooter;
    },
    [ADD_DOCUMENT_ATTACHMENT](state, payload: DocumentAttachmentDto) {
      state.document.attachments.push(payload);
    },
    [SET_ACTIVE_SIDEBAR_BUTTON](state, payload: SidebarMenu) {
      state.selectedSidebarButton = payload;
    },
    [SET_LAST_SELECTED_SIDEBAR_BUTTON](state, payload: SidebarMenu) {
      state.lastSelectedSidebarButton = payload;
    },
    [UPDATE_SELECTED_DIAGRAM](state, payload: DiagramDto) {
      if (isNil(state.selectedPage?.subPageRefs)) {
        state.selectedPage.diagram = payload;
        return;
      }
      const ref = state.selectedPage.subPageRefs.find(
        (r) => r.diagramId == payload.id
      );
      if (ref) {
        ref.diagram = payload;
      } else {
        state.selectedPage.diagram = payload;
      }
    },
    [SET_SELECTED_PAGE_HEADER_HTML](state, payload) {
      const selectedSubPageRef =
        store.getters[`${DOCUMENT_NAMESPACE}/${GET_SELECTED_SUBPAGE_REF}`];
      if (selectedSubPageRef) {
        state.selectedPage.subPageRefs.forEach((r) => {
          if (r.diagramId === selectedSubPageRef.diagramId) {
            r.headerHtml = payload;
          }
        });
      } else {
        state.selectedPage.headerHtml = payload;
      }
    },
    [SET_SELECTED_PAGE_FOOTER_HTML](state, payload) {
      const selectedSubPageRef =
        store.getters[`${DOCUMENT_NAMESPACE}/${GET_SELECTED_SUBPAGE_REF}`];
      if (selectedSubPageRef) {
        state.selectedPage.subPageRefs.forEach((r) => {
          if (r.diagramId === selectedSubPageRef.diagramId) {
            r.footerHtml = payload;
          }
        });
      } else {
        state.selectedPage.footerHtml = payload;
      }
    },
    [SET_SELECTED_PAGE_CONTENT](state, payload) {
      state.selectedPage.content = payload;
    },
    [SET_PAGE_HEADERS_FROM_THEME](state, payload) {
      const page = state.document.pages.find(
        (page) => page.id == payload.page.id
      );
      if (page) {
        page.headerHtml = payload.headerHtml;
      }
    },
    [SET_PAGE_FOOTERS_FROM_THEME](state, payload) {
      const page = state.document.pages.find(
        (page) => page.id == payload.page.id
      );
      if (page) {
        page.footerHtml = payload.footerHtml;
      }
    },
    [SET_SUBPAGE_HEADER_FROM_THEME](
      state,
      payload: { subPage: DocumentSubPageDto; headerHtml: string }
    ) {
      const subPpage = state.document.pages
        .find((page) => page.id === payload.subPage.pageId)
        .subPageRefs.find(
          (subPage) => subPage.subPageIndex === payload.subPage.subPageIndex
        );
      if (subPpage) {
        subPpage.headerHtml = payload.headerHtml;
      }
    },
    [SET_SUBPAGE_FOOTER_FROM_THEME](
      state,
      payload: { subPage: DocumentSubPageDto; footerHtml: string }
    ) {
      const subPpage = state.document.pages
        .find((page) => page.id === payload.subPage.pageId)
        .subPageRefs.find(
          (subPage) => subPage.subPageIndex === payload.subPage.subPageIndex
        );
      if (subPpage) {
        subPpage.footerHtml = payload.footerHtml;
      }
    },
    [SET_FOCUSED_SIDE](state, payload: DocumentFocusedSide) {
      state.focusedSide = payload;
    },
    [SET_DOCUMENT_PAGE_NUMBERING](state, payload) {
      state.document.showPageNumbering = payload;
    },
    [SET_SELECTED_PAGE_FILTER_DEFINITION](state, payload) {
      state.selectedPage.filterDefinition = payload;
    },
    [SET_DOCUMENT_LEGEND_POSITION](state, legendPosition: PageElementPosition) {
      state.document.legendPosition = legendPosition;
    },
    [SET_PERSISTED_DATA_PROPERTY_DISPLAY_TYPES](
      state,
      data: {
        type: JurisdictionValueType;
        dataPropertyDisplayType: DataPropertyDisplayType;
      }
    ) {
      const persistedTypes = state.persistedDataPropertyDisplayTypes;
      const persistedType = state.persistedDataPropertyDisplayTypes[data.type];

      if (
        persistedType &&
        persistedType.includes(data.dataPropertyDisplayType)
      ) {
        const existingIndex = persistedType.findIndex(
          (dp) => dp === data.dataPropertyDisplayType
        );
        existingIndex >= 0 &&
          persistedTypes[data.type].splice(existingIndex, 1);
      } else {
        if (isArray(persistedType)) {
          persistedTypes[data.type].push(data.dataPropertyDisplayType);
        } else {
          persistedTypes[data.type] = [data.dataPropertyDisplayType];
        }
      }
    },
  },
};

/**
 * Takes a document and updates all images from the supplied theme
 * @param document the document to update
 * @param theme the theme to pull from
 * @param documentAttachmentType the document attachment type
 * @param themeAttachmentType the theme attachment type that matches document attachment type
 */
function replaceDocumentAttachmentsFromTheme(
  document: DocumentDto,
  theme: ThemeDto,
  documentAttachmentType: DocumentAttachmentType,
  themeAttachmentType: ThemeAttachmentType
) {
  // find any existing attachments to remove
  const documentAttachments = document.attachments.filter(
    (x) => x.attachmentType == documentAttachmentType
  );

  // remove any attachments in revsere
  for (let index = documentAttachments.length - 1; index >= 0; index--) {
    document.attachments.splice(
      document.attachments.indexOf(documentAttachments[index]),
      1
    );
  }

  // find any theme attachments to use
  const themeImages = theme.attachments.filter(
    (x) => x.attachmentType == themeAttachmentType
  );

  // add theme attachments to document
  if (themeImages && themeImages.length > 0) {
    for (const themeImage of themeImages) {
      document.attachments.push(
        new DocumentAttachmentDto(
          document.id,
          themeImage.fileAttachmentId,
          null,
          themeImage.fileAttachment,
          documentAttachmentType
        )
      );
    }
  }
}
export default documentModule;
