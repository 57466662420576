import ExportConfig from '../config/ExportConfig';
import config from '@/core/config/diagram.definition.config';

class CssVariablesService {
  private cssVariablesData = {
    '--page-margins-left': `${ExportConfig.pageMargins.left}pt`,
    '--page-margins-horizontal': `${
      ExportConfig.pageMargins.left + ExportConfig.pageMargins.right
    }pt`,
    '--legend-scale-md': ExportConfig.diagramLegendScale.medium,
    '--legend-scale-sm': ExportConfig.diagramLegendScale.small,
    '--diagram-horizontal-padding': `${ExportConfig.diagramHorizontalPadding}pt`,
    '--diagram-scrollbar-size': `${config.diagramScrollbarSize}px`,
  };

  public applyCssVariables() {
    for (const variable in this.cssVariablesData) {
      document.documentElement.style.setProperty(
        variable,
        this.cssVariablesData[variable]
      );
    }
  }
}

export default new CssVariablesService();
