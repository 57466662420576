


















import { Vue, Component, Prop, Model } from 'vue-property-decorator';

@Component({
  name: 'AppCheckbox',
})
export default class AppCheckbox extends Vue {
  @Prop({ default: null })
  value: string | number;

  @Model('change', { type: [Boolean, Array] })
  checked!: boolean | Array<string | number>;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: null })
  id: string | number;

  private _uid: string;
  inputId: string | number;

  get isMultiple() {
    return Array.isArray(this.checked) && this.value !== null;
  }

  get isInMultiple() {
    return (
      this.isMultiple &&
      (this.checked as Array<string | number>).includes(this.value)
    );
  }

  get isChecked() {
    return !this.isMultiple ? this.checked : this.isInMultiple;
  }

  get inputClasses() {
    const checkedIconClass = this.isChecked
      ? 'icon-checkbox-checked'
      : 'icon-checkbox-unchecked';

    if (this.disabled) {
      return [checkedIconClass, 'j-text-main-dark-20 j-cursor-not-allowed'];
    }

    return [
      checkedIconClass,
      'j-cursor-pointer',
      this.isChecked
        ? 'j-text-accent-purple hover:j-text-accent-purple-90 focus:j-text-accent-purple-80'
        : 'j-text-main-dark-60',
    ];
  }

  get labelClasses() {
    return !this.disabled
      ? 'j-text-main-dark'
      : 'j-text-main-dark-20 j-cursor-not-allowed j-select-none';
  }

  created() {
    this.inputId = this.id || this._uid;
  }

  changeValue(e) {
    if (this.isMultiple) {
      const newValue = [...(this.checked as Array<string | number>)];
      const idx = (this.checked as Array<string | number>).indexOf(this.value);

      if (~idx) {
        newValue.splice(idx, 1);
      } else {
        newValue.push(this.value);
      }

      this.$emit('change', newValue);
    } else {
      this.$emit('change', e.target.checked);
    }
  }
}
