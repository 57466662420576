import { stripHtml } from '@/core/utils/html.utils';
import { IEdge, INode } from 'yfiles';
import i18n from '@/core/plugins/vue-i18n';
export class OwnershipRelationshipDisplay {
  public getDisplayString(edge: IEdge, node?: INode): string {
    if (
      !node ||
      (edge.sourceNode.tag.uuid !== node.tag.uuid &&
        edge.targetNode.tag.uuid !== node.tag.uuid)
    ) {
      return '';
    }

    let isParent = false;

    let value = edge.labels.firstOrDefault()?.text ?? '';

    //regex will set value to a placeholder if value is not null but the user does not specify a number that can be construed as a %
    value = OwnershipRelationshipDisplay.formatUnknownPercentage(value);

    let nodeName = '';

    if (edge.sourceNode.tag.uuid === node.tag.uuid) {
      isParent = true;
      nodeName = edge.targetNode.labels.firstOrDefault()?.text;
    }

    if (edge.targetNode.tag.uuid === node.tag.uuid) {
      nodeName = edge.sourceNode.labels.firstOrDefault()?.text;
    }

    if (value.length === 0 && nodeName.length === 0) return;
    return isParent
      ? i18n.t('BOLD_OWNS_X_OF_X', [value, nodeName]).toString()
      : i18n.t('BOLD_X_OWNED_BY_X', [value, nodeName]).toString();
  }

  public static formatUnknownPercentage(value: string) {
    value = stripHtml(value);
    const regexPercent = new RegExp(/^((100)|(\d{1,2}(.\d*)?))%$/);

    if (!regexPercent.test(value)) {
      value = '[•]%';
    }
    return value;
  }
}
