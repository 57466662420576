import ITextEditorConfig from '../../common/ITextEditorConfig';
import ConfigurationBuilder from './ckEditorConfigurationBuilder';
import CommonEditorConfig from './commonEditorConfig';
class BasicEditorConfig implements ITextEditorConfig {
  getConfig() {
    const config = {
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          'fontFamilyDropdown',
          'fontSizeDropdown',
          '|',
          'alignment',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'insertTable',
          '|',
          'insertPageBreak',
          '-',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'subscript',
          'superscript',
          '|',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'headingDropdown',
        ],
      },
    };

    const mergedConfig = ConfigurationBuilder.configure(
      CommonEditorConfig.getConfig(),
      config
    );
    return mergedConfig;
  }
}

let instance = new BasicEditorConfig();
export default instance;
