
































import { Vue, Component, Prop } from 'vue-property-decorator';
import Timer from './timer';

const iconClasses = {
  info: 'j-text-accent-purple',
  warning: 'j-text-warning-orange',
  success: 'j-text-success-green',
  error: 'j-text-danger-red',
};

const iconTypes = {
  info: 'notification-info',
  warning: 'notification-warning',
  success: 'notification-success',
  error: 'notification-error',
};

@Component({
  name: 'Notification',
  inheritAttrs: false,
})
export default class Notification extends Vue {
  @Prop({ required: true })
  type: string;

  @Prop({ default: null })
  title: string;

  @Prop({ default: null })
  text: string;

  @Prop({ default: 4000, type: Number })
  duration: number;

  @Prop({ default: null })
  promise: { resolve: (boolean) => boolean };

  @Prop({ default: null })
  component: Function;

  timer: Timer<boolean>;

  get iconClass() {
    return iconClasses[this.type];
  }

  get iconName() {
    return iconTypes[this.type];
  }

  close(isClosed: boolean = false) {
    this.timer.stop();
    this.timer = null;

    this.promise.resolve(isClosed);
  }

  toggleTimer(pause: boolean) {
    if (this.timer) pause ? this.timer.pause() : this.timer.resume();
  }

  mounted() {
    this.timer = new Timer(this.close, this.duration || 4000);
  }

  beforeDestroy() {
    if (this.timer) {
      this.timer.stop();
    }
    this.timer = null;
  }
}
