



















































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'AppTextarea',
})
export default class AppTextarea extends Vue {
  @Prop({ required: true })
  value: string;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: '' })
  errorText: string;

  @Prop({ default: false, type: Boolean })
  fullWidth: boolean;

  // v-model
  get modelValue() {
    return this.value;
  }

  set modelValue(value: string) {
    this.$emit('input', value);
  }
}
