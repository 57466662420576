import IMeta from '../IMeta';
export default class ImageMeta implements IMeta {
  public imageSrc: string;
  constructor(options: { imageSrc: string }) {
    if (!options) {
      return;
    }
    this.imageSrc = options.imageSrc;
  }
}
