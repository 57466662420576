






import { Vue, Component, Watch } from 'vue-property-decorator';
import Confirm from './Confirm.vue';

import { list } from './index';

@Component({
  name: 'AppConfirmation',
  components: { Confirm },
})
export default class AppConfirmation extends Vue {
  list = list;
}
