









































































import { Vue, Component, Prop } from 'vue-property-decorator';

type inputSizes = 'md' | 'lg';

const sizeClasses = {
  md: 'j-h-40 j-max-h-40',
  lg: 'j-h-52 j-max-h-52',
};

@Component({
  name: 'AppInput',
  inheritAttrs: false,
})
export default class AppInput extends Vue {
  @Prop({ required: true })
  value: string;

  @Prop({ default: 'text' })
  type: string;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: false, type: Boolean })
  readonly: boolean;

  @Prop({ default: false, type: Boolean })
  autoFill: boolean;

  @Prop({ default: false, type: Boolean })
  fullWidth: boolean;

  @Prop({ default: '' })
  errorText: string;

  @Prop({ default: null })
  appendIcon: string;

  @Prop({ default: null })
  prependIcon: string;

  @Prop({ default: false, type: Boolean })
  borderless: boolean;

  @Prop({ default: false, type: Boolean })
  clearable: boolean;

  @Prop({ default: 'md', type: String })
  size: inputSizes;

  $refs: {
    input: HTMLInputElement;
  };

  isPasswordShown = false;
  private isFocused = false;

  // v-model
  get modelValue() {
    return this.value;
  }

  set modelValue(value: string) {
    this.$emit('input', value);
  }

  get listeners() {
    const { input, ...listeners } = this.$listeners; // exclude `input` listener
    return listeners;
  }

  get isPassword() {
    return this.type === 'password';
  }

  get isPrependSlot() {
    return this.$slots.prepend;
  }

  get isAppendSlot() {
    return this.$slots.append;
  }

  get appendIconClass() {
    return this.appendIcon && `icon-${this.appendIcon}`;
  }
  get prependIconClass() {
    return this.prependIcon && `icon-${this.prependIcon}`;
  }

  get isReadonly() {
    return (!this.autoFill && !this.isFocused) || this.readonly;
  }

  get isEmpty() {
    return !this.modelValue.trim();
  }

  get inputClasses() {
    return [
      this.borderless ? 'j-ring-transparent' : 'j-ring-main-dark-20',
      this.disabled && 'j-ring-main-dark-10 j-cursor-not-allowed',
      this.errorText
        ? 'j-ring-danger-red'
        : 'focus-within:j-ring-accent-purple',
      sizeClasses[this.size],
    ];
  }

  focusHandler() {
    this.isFocused = true;
  }
  blurHandler() {
    this.isFocused = false;
  }

  focus() {
    this.$nextTick(() => {
      this.$refs.input.focus();
    });
  }

  clear() {
    this.$emit('input', '');
  }
}
