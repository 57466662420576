






















import { Vue, Component } from 'vue-property-decorator';
import AppBreadcrumb, {
  LastItemOptions,
} from '@/components/shared/AppBreadcrumb.vue';

@Component({
  name: 'Breadcrumbs',
  components: {
    AppBreadcrumb,
  },
})
export default class Breadcrumbs extends Vue {
  crumbsBasic = [
    { id: 1, label: 'Research' },
    { id: 2, label: 'October 2021' },
    { id: 3, label: 'Last Folder' },
  ];
  crumbsMulti = [
    { id: 1, label: 'All Documents' },
    { id: 2, label: 'Folder 1' },
    { id: 3, label: 'Folder 2' },
    { id: 4, label: 'Research' },
    { id: 5, label: 'October 2021' },
    { id: 6, label: 'Test folder' },
    { id: 7, label: 'Last Folder' },
  ];

  lastFolderOptions: LastItemOptions = [
    { label: 'Some custom stuff', command: '1' },
    { label: 'More custom stuff', command: '2' },
    { label: 'You get the point', command: '3' },
    'divider',
    { label: 'Remove', command: 'remove' },
  ];

  selected(crumb) {
    console.log(crumb);
  }

  customActionHandler(payload) {
    console.log('customActionHandler: ', payload);
  }
}
