import PaletteItem from '@/components/DiagramPalette/PaletteItem';
import { Module } from 'vuex';
import ArrowElementDataProvider from '@/components/DiagramPalette/DataProviders/ArrowElementDataProvider';
import ShapeElementDataProvider from '@/components/DiagramPalette/DataProviders/ShapeElementDataProvider';
import SvgElementDataProvider from '@/components/DiagramPalette/DataProviders/SvgElementDataProvider';
import TextBoxDataProvider from '@/components/DiagramPalette/DataProviders/TextBoxDataProvider';
import ThemedElementsDataProvider from '@/components/DiagramPalette/DataProviders/ThemedElementsDataProvider';
import _ from 'lodash';
// action types
export const GET_PALETTE_ITEMS = 'getPaletteItems';
export const REFRESH_PALETTE = 'refreshPalette';
export const SET_PALETTE_ITEMS = 'setPaletteItems';
export const PREVIEW_PALETTE_ITEM = 'previewPaletteItem';
export const PALETTE_NAMESPACE = 'palette';

const dataProviders = [
  ThemedElementsDataProvider,
  SvgElementDataProvider,
  TextBoxDataProvider,
  ArrowElementDataProvider,
  ShapeElementDataProvider,

];
interface State {
  paletteItems: PaletteItem[];
}
const paletteModule: Module<State, any> = {
  namespaced: true,
  state: {
    paletteItems: [],
  },
  getters: {
    [GET_PALETTE_ITEMS](state) {
      return state.paletteItems;
    },
  },
  actions: {
    async [REFRESH_PALETTE](context) {
      let allItems = [];
      // load each data provider in turn

      for (const dpClass of dataProviders) {
        // initialise the class
        const dp = new dpClass();
        // invoke the getElements function, this can return a promise data
        const elementsOrPromise = await dp.getElements();
        if (elementsOrPromise instanceof Promise) {
          // if handling a promise, commit to store in a call back
          let items = await elementsOrPromise;
          if (items && items.length > 0) {
            allItems.push(...items);
          }
        } else {
          // commit data to store
          if (elementsOrPromise && elementsOrPromise.length > 0) {
            allItems.push(...elementsOrPromise);
          }
        }
      }
      context.commit(SET_PALETTE_ITEMS, allItems);
      return allItems;
    },
  },
  mutations: {
    [SET_PALETTE_ITEMS](state, payload) {
      if (!payload) {
        return;
      } // combine existing store items with new items.

      state.paletteItems = payload ?? [];
    },
  },
};

export default paletteModule;
