
















import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'Radios',
})
export default class Radios extends Vue {
  lang = 'two';
}
