


















































































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'AppSelect',
})
export default class AppSelect extends Vue {
  @Prop({ required: true })
  value: Array<string | number> | string | number;

  @Prop({ default: 'Select options' })
  placeholder: string;

  @Prop({ required: true })
  options: any[];

  @Prop({ default: 'value' })
  valueKey: string;

  @Prop({ default: 'label' })
  labelKey: string;

  @Prop({ default: null })
  disabledOptions: string[];

  @Prop({ default: false, type: Boolean })
  multiple: boolean;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: false, type: Boolean })
  returnSingleValue: boolean;

  @Prop({ default: true, type: Boolean })
  fixedHeight: boolean;

  @Prop({ default: false, type: Boolean })
  fullWidth: boolean;

  @Prop({ default: 180, type: Number })
  minWidth: number;

  isOpen = false;

  get rawValue() {
    if (this.multiple) {
      return this.options.filter((i) =>
        (this.value as Array<string | number>).includes(i[this.valueKey])
      );
    }

    if (this.returnSingleValue) {
      return this.options.find((i) => i[this.valueKey] == this.value);
    }

    return this.value;
  }

  get hasInitialValue() {
    if (typeof this.value === 'number') {
      return true;
    }

    if (!this.value) {
      return false;
    }

    if (Array.isArray(this.value)) {
      return this.value.length > 0;
    } else {
      return Object.entries(this.value).length > 0;
    }
  }

  get selectedValue() {
    if (!this.hasInitialValue) {
      return '';
    }

    if (this.multiple) {
      return this.options
        .filter((i) =>
          (this.value as Array<string | number>).includes(i[this.valueKey])
        )
        .map((i) => i[this.labelKey])
        .join(', ');
    } else if (this.returnSingleValue) {
      const selectedOption = this.options.find(
        (i) => i[this.valueKey] == this.value
      );
      return selectedOption && (selectedOption[this.labelKey] as string);
    } else {
      console.log(this.value);
      return this.value[this.labelKey] as string;
    }
  }

  // use for programmatically open via ref from parent (e.g. this.$refs.select.open())
  open() {
    if (this.disabled) return;
    this.isOpen = true;
  }

  close() {
    if (this.disabled) return;
    this.isOpen = false;
  }

  toggle() {
    if (this.disabled) return;
    this.isOpen = !this.isOpen;
  }

  handleMultipleSelection(option: any) {
    const value = option[this.valueKey];
    const set = new Set(this.value as Array<string | number>);

    set.has(value) ? set.delete(value) : set.add(value);

    return Array.from(set);
  }

  setSelection(option) {
    const toEmit = this.multiple
      ? this.handleMultipleSelection(option)
      : this.returnSingleValue
      ? option[this.valueKey]
      : option;

    this.$emit('input', toEmit);
    this.$emit('change', toEmit);

    if (!this.multiple) {
      this.close();
    }
  }

  isSelected(option) {
    if (!this.hasInitialValue) {
      return false;
    }

    if (this.multiple) {
      return (this.value as Array<number | string>).some(
        (i) => i == option[this.valueKey]
      );
    } else if (this.returnSingleValue) {
      return this.value == option[this.valueKey];
    } else {
      return this.value[this.valueKey] === option[this.valueKey];
    }
  }

  isDisabled(option) {
    return this.disabledOptions
      ? this.disabledOptions.includes(option[this.valueKey])
      : false;
  }
}
