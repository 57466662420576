import ExportPageElement from './ExportPageElement';
import { ISize, MutableSize, Rect, Size } from 'yfiles';
import { DocumentPageType, PageElementPosition } from '@/api/models';
import Point from '@/core/common/Point';
import ExportConfig from '@/core/config/ExportConfig';
import ExportUtils from './ExportUtils';
import { scaleRectIntoBounds } from '@/core/utils/common.utils';

export default class GraphExportHelper {
  public static async finalizeSvgElement(
    svgElement: SVGElement,
    additionalElements: ExportPageElement[],
    targetRect: Rect,
    pageType: DocumentPageType,
    minExportSize: Size = Size.EMPTY
  ): Promise<ISize> {
    // Export size will be used for later rendering, it will increase with additional elements
    let exportSize = new MutableSize(targetRect.width, targetRect.height);
    this.cleanupAttributes(svgElement);
    this.setSvgElementSize(svgElement, exportSize);

    // Order elements by topmost first (TopLeft, TopRight) to calculate the export size correctly
    const orderedElements = (additionalElements ?? []).sort((a, b) => {
      if (
        <PageElementPosition>a.options.position in PageElementPosition &&
        <PageElementPosition>b.options.position in PageElementPosition
      ) {
        return (
          <PageElementPosition>a.options.position -
          <PageElementPosition>b.options.position
        );
      } else {
        return 1;
      }
    });

    // Add margins to the graph to fit proportionally into minimal diagram size
    if (minExportSize?.isEmpty) {
      // Calculate minimal diagram size (either from pageType or use config default)
      minExportSize = ExportConfig.pageSize;
      if (pageType !== undefined) {
        minExportSize = ExportUtils.calculateDiagramSize(pageType);
      }
      minExportSize = new Size(
        minExportSize.width * ExportConfig.upscaleMultiplier,
        minExportSize.height * ExportConfig.upscaleMultiplier
      );
      minExportSize = scaleRectIntoBounds(exportSize, minExportSize);

      // Determine the margins to add to the graph in order to satisfy the minimum size restriction
      const marginWidth =
        exportSize.width < minExportSize.width
          ? minExportSize.width - exportSize.width
          : 0;
      const marginHeight =
        exportSize.height < minExportSize.height
          ? minExportSize.height - exportSize.height
          : 0;
      const svgElementMargins = new Size(marginWidth, marginHeight);
      exportSize.width += svgElementMargins.width;
      exportSize.height += svgElementMargins.height;

      this.setSvgElementSize(svgElement, exportSize);
      this.addMargins(svgElement, svgElementMargins);
    }

    // Insert additional export one by one into the parent svg element
    let svgElementOffset = new Point(0, 0);
    for (const e of orderedElements) {
      const element = await e.toSvgAsync();

      if (<PageElementPosition>e.options.position in PageElementPosition) {
        const elementWidth = parseFloat(element.getAttribute('width'));
        const elementHeight = parseFloat(element.getAttribute('height'));
        const elementPadding = e.options.padding ?? 0;
        exportSize.height += elementHeight + elementPadding;

        const position = <PageElementPosition>e.options.position;
        switch (position) {
          case PageElementPosition.BottomLeft:
            element.setAttribute(
              'y',
              (exportSize.height - elementHeight).toString()
            );
            break;
          case PageElementPosition.BottomRight:
            element.setAttribute(
              'y',
              (exportSize.height - elementHeight).toString()
            );
            element.setAttribute(
              'x',
              (exportSize.width - elementWidth).toString()
            );
            break;
          case PageElementPosition.TopLeft:
            const offsetTL = new Point(0, elementHeight + elementPadding);
            svgElementOffset = svgElementOffset.add(offsetTL);
            GraphExportHelper.moveSvgElement(
              svgElement.querySelector('g[transform]'),
              offsetTL
            );
            break;
          case PageElementPosition.TopRight:
            const offsetTR = new Point(0, elementHeight + elementPadding);
            svgElementOffset = svgElementOffset.add(offsetTR);
            GraphExportHelper.moveSvgElement(
              svgElement.querySelector('g[transform]'),
              offsetTR
            );
            element.setAttribute(
              'x',
              (exportSize.width - elementWidth).toString()
            );
            break;
          default:
            throw (
              'Unsupported diagram export position: ' +
              PageElementPosition[position]
            );
        }
      } else {
        const position = <Point>e.options.position;
        element.setAttribute(
          'x',
          (
            (exportSize.width - svgElementOffset.x) * position.x * 0.01 +
            svgElementOffset.x
          ).toString()
        );
        element.setAttribute(
          'y',
          (
            (exportSize.height - svgElementOffset.y) * position.y * 0.01 +
            svgElementOffset.y
          ).toString()
        );
      }

      svgElement.append(element);
    }

    this.setSvgElementSize(svgElement, exportSize);

    return exportSize;
  }

  /**
   * Make sure that the final SVG being passed into PDF, PNG and other exports
   * does not contain any unnecessary attributes or attributes that may break it
   */
  private static cleanupAttributes(svgElement: SVGElement) {
    svgElement.removeAttribute('style');
    svgElement.removeAttribute('viewBox');

    svgElement.querySelector('clipPath')?.remove();
    svgElement.querySelector('g[clip-path]')?.removeAttribute('clip-path');
  }

  /**
   * Wrap the contents of the final SVG in a group, increase the size of the
   * container by margin and move the group to centre via translate(x y)
   */
  private static addMargins(svgElement: SVGElement, margins: ISize) {
    const originalSize = new Size(
      parseFloat(svgElement.getAttribute('width')),
      parseFloat(svgElement.getAttribute('height'))
    );

    const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    group.setAttribute('width', originalSize.width.toString());
    group.setAttribute('height', originalSize.height.toString());
    for (let i = svgElement.childNodes.length - 1; i >= 0; i--) {
      const node = svgElement.childNodes[i];
      node.remove();
      group.appendChild(node);
    }
    svgElement.appendChild(group);

    const newSize = new Size(
      originalSize.width + margins.width,
      originalSize.height + margins.height
    );
    this.setSvgElementSize(svgElement, newSize);

    this.moveSvgElement(
      group,
      new Point(margins.width / 2, margins.height / 2)
    );
  }

  /**
   * Move the SVG element by adjusting existing transform attribute
   * or creating a new one with translate(x y) values
   */
  private static moveSvgElement(svgElement: SVGElement, offset: Point) {
    let transform = svgElement?.getAttribute('transform'); // translate(x y)
    const match = transform?.match(/translate\((?<x>[-\d.]+)\s(?<y>[-\d.]+)\)/);
    if (match?.length > 0) {
      const x = parseFloat(match.groups['x']) + offset.x;
      const y = parseFloat(match.groups['y']) + offset.y;
      transform = `translate(${x} ${y})`;
    } else {
      transform = `translate(${offset.x} ${offset.y})`;
    }
    svgElement?.setAttribute('transform', transform);
  }

  /**
   * Update SVG element size including the viewBox attribute
   */
  private static setSvgElementSize(svgElement: SVGElement, newSize: ISize) {
    svgElement.setAttribute('width', newSize.width.toString());
    svgElement.setAttribute('height', newSize.height.toString());
    svgElement.setAttribute(
      'viewBox',
      `0 0 ${newSize.width} ${newSize.height}`
    );
  }
}
