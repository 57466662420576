
































































import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'Modals',
})
export default class Modals extends Vue {
  dummyCheck = false;

  isModalOpen = false;

  isModalOpen2 = false;
  testInput = '';
  selectedOption = null;
  demoOptions = [
    { label: 'Cool stuff!', value: 1 },
    { label: 'Not Cool stuff!', value: 2 },
    { label: 'Nice green apple', value: 3 },
    { label: 'Hop Chilli Pepper', value: 4 },
  ];

  doBeforeClose({ close, keep }) {
    this.$confirm({
      title: 'Modal close',
      text: 'Are you sure to close modal?',
    })
      .then(() => {
        console.debug('confirm in component - success');
        close();
      })
      .catch(() => {
        console.debug('confirm in component - deny');
        keep();
      });
  }
}
