import {
  CanvasComponent,
  GraphComponent,
  GraphEditorInputMode,
  IInputModeContext,
  INode,
  IRenderContext,
  Point,
  Rect,
  ShapeNodeShape,
  Size,
  SvgVisual,
  Visual,
} from 'yfiles';
import DecorationStateManager from '@/core/services/DecorationStateManager';
import HitResult from '../HitResult';
import ImageButtonNodeDecorator from './ImageButtonNodeDecorator';
import JigsawNodeDecorator from './JigsawNodeDecorator';
import DecorationState from '../DecorationState';
import store from '@/core/services/store';
import { QuickBuildState } from '@/api/models';
import DiagramUtils from '@/core/utils/DiagramUtils';
import {
  DOCUMENT_NAMESPACE,
  GET_READONLY,
} from '@/core/services/store/document.module';

export class EdgeCreationButtonDecoratorMeta {
  constructor(public node: INode) {}
}

export interface EdgeCreationButtonState extends DecorationState {
  alwaysVisible: boolean;
}

/**
 * A decorator placed around the nodes permiter
 */
export default class EdgeCreationButtonDecorator
  implements JigsawNodeDecorator
{
  public $class = 'EdgeCreationButtonDecorator';
  public static INSTANCE: EdgeCreationButtonDecorator =
    new EdgeCreationButtonDecorator();
  private imageButtonNodeDecorator: ImageButtonNodeDecorator;
  private imageSrc = '/media/yFiles/icons/icon-bold-edge-creation.svg';

  constructor(options?: { size?: Size }) {
    let size = options?.size ?? new Size(28, 28);
    // create imageButton to proxy the rendering
    this.imageButtonNodeDecorator = new ImageButtonNodeDecorator({
      imageSrc: this.imageSrc,
      size: size,
    });
    this.imageButtonNodeDecorator.getLayout = this.getLocation.bind(this);
  }

  isVisible(renderContext: IRenderContext, node: INode): boolean {
    const gc = renderContext.canvasComponent as GraphComponent;
    const isReadonly = store.getters[
      `${DOCUMENT_NAMESPACE}/${GET_READONLY}`
    ] as boolean;

    if (
      isReadonly ||
      gc.selection.selectedEdges.size > 0 ||
      gc.selection.selectedNodes.size > 1 ||
      this.isEdgeCreationInProgress(gc)
    ) {
      return false;
    }

    return true;
  }

  isHit(context: IInputModeContext, location: Point, node: INode): HitResult {
    if (this.quickStartEnabled) return HitResult.NONE;
    const gc = context.canvasComponent as GraphComponent;

    let hitResult = this.imageButtonNodeDecorator.isHit(
      context,
      location,
      node
    );

    if (hitResult.isHit) {
      hitResult.decoratorType = this.$class;
      hitResult.meta = new EdgeCreationButtonDecoratorMeta(node);
    }

    return hitResult;
  }

  createVisual(context: IRenderContext, node: INode): Visual {
    let visual = this.imageButtonNodeDecorator.createVisual(
      context,
      node
    ) as SvgVisual;

    const hitResult = this.isHit(
      context.canvasComponent.inputModeContext,
      context.canvasComponent.lastMouseEvent.location,
      node
    );
    const isNodeSelected = this.isNodeSelected(context, node);
    const isLabelEditing = DiagramUtils.isLabelEditing(
      context.canvasComponent,
      node
    );

    const state = this.getState(node);
    const gc = context.canvasComponent as GraphComponent;
    this.attachEvents(visual.svgElement, gc);
    this.setVisibility(
      visual,
      state,
      isNodeSelected,
      isLabelEditing,
      hitResult,
      gc
    );
    return visual;
  }

  updateVisual(
    context: IRenderContext,
    node: INode,
    oldVisual: Visual
  ): Visual {
    if (oldVisual == null) {
      return this.createVisual(context, node);
    }
    let visual = this.imageButtonNodeDecorator.updateVisual(
      context,
      node,
      oldVisual
    ) as SvgVisual;

    const state = this.getState(node);
    const hitResult = this.isHit(
      context.canvasComponent.inputModeContext,
      context.canvasComponent.lastMouseEvent.location,
      node
    );
    const isNodeSelected = this.isNodeSelected(context, node);
    const isLabelEditing = DiagramUtils.isLabelEditing(
      context.canvasComponent,
      node
    );
    const gc = context.canvasComponent as GraphComponent;
    if (visual != oldVisual) {
      this.attachEvents(visual.svgElement, gc);
    }
    this.setVisibility(
      visual,
      state,
      isNodeSelected,
      isLabelEditing,
      hitResult,
      gc
    );
    return visual;
  }

  private getLocation(node: INode): Rect {
    const nodeLayout = node.layout;
    let size = this.imageButtonNodeDecorator.size;
    let xOffset = -size.width - 3;
    let yOffset = -size.height;

    const shape = DiagramUtils.getNodeShape(node);
    if (shape == ShapeNodeShape.TRIANGLE) {
      xOffset = 3;
      yOffset = 3;
    }
    let x = nodeLayout.x + xOffset;
    let y = nodeLayout.y + yOffset;

    return new Rect(x, y, size.width, size.height);
  }
  private isNodeSelected(context: IRenderContext, node: INode): boolean {
    return (context.canvasComponent as GraphComponent).selection.isSelected(
      node
    );
  }

  private isEdgeCreationInProgress(graphComponent: GraphComponent): boolean {
    let geim = graphComponent.inputMode as GraphEditorInputMode;

    return geim?.createEdgeInputMode?.isCreationInProgress ?? false;
  }

  private setVisibility(
    visual: SvgVisual,
    state: EdgeCreationButtonState,
    isNodeSelected: boolean,
    isLabelEditing: boolean,
    hitResult: HitResult,
    graphComponent: GraphComponent
  ) {
    if (isNodeSelected && isLabelEditing) {
      this.hide(visual.svgElement);
      return;
    }

    const noEdgesSelected = graphComponent.selection.selectedEdges.size === 0;

    if (noEdgesSelected && state.alwaysVisible) {
      this.show(visual.svgElement);
      return;
    }
    if (
      noEdgesSelected &&
      isNodeSelected &&
      graphComponent.selection.selectedNodes.size === 1 &&
      !this.quickStartEnabled &&
      !this.isEdgeCreationInProgress(graphComponent)
    ) {
      this.show(visual.svgElement);
      return;
    }

    if (
      noEdgesSelected &&
      !this.quickStartEnabled &&
      hitResult.isHit &&
      hitResult.decoratorType == this.$class &&
      !this.isEdgeCreationInProgress(graphComponent)
    ) {
      this.show(visual.svgElement);
      return;
    }

    this.hide(visual.svgElement);
  }

  private show(element: SVGElement) {
    element.style.animation = '';
    element.style.opacity = '1';
    element.style.cursor = 'pointer';
  }

  attachEvents(element: SVGElement, gc: CanvasComponent) {
    element.addEventListener('mouseenter', () => {
      gc.invalidate();
    });
    element.addEventListener('mouseleave', () => {
      gc.invalidate();
    });
  }

  private hide(element: SVGElement) {
    element.style.opacity = '0';
    element.style.cursor = 'default';
  }

  private getState(node: INode): EdgeCreationButtonState {
    return DecorationStateManager.getState(
      EdgeCreationButtonDecorator.INSTANCE,
      node
    ) as EdgeCreationButtonState;
  }

  private get quickStartEnabled(): boolean {
    return (
      store?.state?.document?.quickBuildState === QuickBuildState.InProgress ??
      false
    );
  }

  public defaultState(): EdgeCreationButtonState {
    return {
      alwaysVisible: false,
    };
  }
}
