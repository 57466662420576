import { stringContainsCI } from '@/core/utils/common.utils';
import { INode } from 'yfiles';
import TextParameter from '../parameters/TextParameter';
import { ITypedMatcher } from './IMatcher';

export default class NodeTextMatcher
  implements ITypedMatcher<INode, TextParameter> {
  public canMatch(candidate: any, parameter: TextParameter): boolean {
    return candidate instanceof INode;
  }

  public isMatch(candidate: INode, parameter: TextParameter): boolean {
    return (
      stringContainsCI(candidate?.tag?.name, parameter?.text) ||
      candidate.labels?.some((l) => stringContainsCI(l.text, parameter?.text))
    );
  }
}
