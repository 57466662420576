






import { Vue, Component, Prop, InjectReactive } from 'vue-property-decorator';

@Component({
  name: 'AppTabItem',
})
export default class AppTabItem extends Vue {
  @Prop({ required: true }) name: string;
  @Prop({ default: null }) icon: null | string;
  @Prop({ default: false, type: Boolean }) marked: boolean;
  @Prop({ default: false, type: Boolean }) disabled: boolean;
}
