


































































































































































































import { Vue, Component } from 'vue-property-decorator';
import AppFlyout from '@/components/shared/AppFlyout.vue';

@Component({
  name: 'Flyouts',
})
export default class Flyouts extends Vue {
  $refs!: {
    ctxMenuRef: AppFlyout;
  };

  showCtxMenu(evt) {
    this.$refs.ctxMenuRef.show(evt);
  }
}
