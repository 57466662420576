import humanizeString from 'humanize-string';
import { Color, ISize, MutableSize, Point, Size } from 'yfiles';
import store from '@/core/services/store';
import { Base64Utils } from '@/core/utils/Base64Utils';
import appConfig from '../config/appConfig';
import i18n from '../plugins/vue-i18n';
import { md5 } from 'hash-wasm';
import Vue from 'vue';

export function generateUuid(): string {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function RgbaToHex(r, g, b, a) {
  //YFiles uses AARRGGBB
  let hex =
    (a | (1 << 8)).toString(16).slice(1) +
    (r | (1 << 8)).toString(16).slice(1) +
    (g | (1 << 8)).toString(16).slice(1) +
    (b | (1 << 8)).toString(16).slice(1);

  return `#${hex}`;
}

export function HexToRgb(hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? new Color(
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      )
    : null;
}

export function HexToRgba(hex) {
  if (hex?.length < 8) {
    return HexToRgb(hex);
  }
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(
    hex
  );
  return result
    ? new Color(
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
        parseInt(result[4], 16)
      )
    : null;
}

export function RgbToHex(r, g, b) {
  let hex =
    (r | (1 << 8)).toString(16).slice(1) +
    (g | (1 << 8)).toString(16).slice(1) +
    (b | (1 << 8)).toString(16).slice(1);

  return `#${hex}`;
}

/**
 * Converts enum to an array
 * @param enumType : enum to convert
 * @param translateText: when true, values are translated as translatedText propterty. Otherwise it equals null
 * @returns array of enum
 */

export function getEnumAsArray(
  enumType,
  translateText?: boolean
): Array<{ value: string; text: string; translatedText: string }> {
  return Object.getOwnPropertyNames(enumType)
    .filter((d) => {
      return !isNaN(parseInt(d));
    })
    .map((d) => {
      return {
        value: d,
        text: humanizeString(enumType[d]),
        translatedText: translateText
          ? i18n.t(formatStringForTranslate(enumType[d])).toString()
          : null,
      };
    });
}

/**
 * Converts camel case, kebab case, pascal case to the translation key format
 * @param s : String to be formatted
 * @returns string: ready for translation: FORMATTED_STRING
 */
export function formatStringForTranslate(s: string) {
  let x = humanizeString(s).toUpperCase().replaceAll(' ', '_');
  return x;
}
export function formatString(s: string, ...values: any[]) {
  for (var arg in values) {
    s = s.replace('{' + arg + '}', values[arg]);
  }
  return s;
}

export function roundToClosest(input: number, nearest: number): number {
  return Math.round(input / nearest) * nearest;
}

export function firstLetterToLowerCase(str: string): string {
  return str[0].toLowerCase() + str.substring(1);
}

export function toTitleCase(text: string): string {
  const conjunctions = ['and', 'or', 'for', 'nor', 'but', 'yet', 'so'];

  if (text) {
    const wordsArray = [];
    const words = text.split(' ');

    words.forEach((word) => {
      if (conjunctions.includes(word)) {
        wordsArray.push(word);
      } else {
        wordsArray.push(word[0].toUpperCase() + word.substr(1));
      }
    });

    return wordsArray.join(' ');
  } else {
    return null;
  }
}

const cachedSvgImages = {};
export function convertSvgToImage(
  src: string,
  outputFormat: string
): Promise<{
  src: string;
  height?: number;
  width?: number;
}> {
  if (src.startsWith('data:image') && !src.startsWith('data:image/svg+xml')) {
    return Promise.resolve({ src: src });
  }
  if (cachedSvgImages[src]) {
    return Promise.resolve(cachedSvgImages[src]);
  }
  return new Promise<any>((resolve) => {
    const img = new Image();
    if (appConfig.debugMode) {
      img.crossOrigin = 'Anonymous';
    }
    img.onload = function () {
      const canvas: any = document.createElement('CANVAS');
      const ctx: any = canvas.getContext('2d');
      let dataURL: any;
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat, 1);
      const result = {
        src: dataURL,
        height: img.height,
        width: img.width,
      };
      if (!src.startsWith('data:image')) {
        cachedSvgImages[src] = result;
      }
      resolve(result);
    };
    img.onerror = function () {
      resolve({
        src: null,
      });
    };
    img.src = src;
  });
}

export function convertSvgElementToImage(svg, outputFormat) {
  return convertSvgToImage(
    'data:image/svg+xml;base64,' + Base64Utils.encode(svg.outerHTML),
    outputFormat
  );
}

const cachedDataUrls = {};
export async function convertUrlToDataUrl(url: string): Promise<string> {
  if (url.startsWith('data:')) {
    return Promise.resolve(url);
  }
  if (cachedDataUrls[url]) {
    return Promise.resolve(cachedDataUrls[url]);
  }
  const blob = await fetch(url).then((r) => r.blob());
  const dataUrl = await new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
  return dataUrl;
}

const cachedImageSizes = {};
export function getImageSize(
  src: string
): Promise<{ width: number; height: number }> {
  if (cachedImageSizes[src]) {
    return Promise.resolve(cachedImageSizes[src]);
  }
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = function () {
      const result = { width: img.width, height: img.height };
      if (!src.startsWith('data:')) {
        cachedImageSizes[src] = result;
      }
      resolve(result);
    };
    img.src = src;
  });
}

export function splitArrayIntoChunks<T>(array: T[], chunkSize: number): T[][] {
  const output = new Array<T[]>();
  let arrayIndex = 0;
  while (arrayIndex < array.length) {
    output.push(array.slice(arrayIndex, (arrayIndex += chunkSize)));
  }
  return output;
}

export function selectText(containerId) {
  if ((document as any).selection) {
    // IE
    var range = (document.body as any).createTextRange();
    range.moveToElementText(document.getElementById(containerId));
    range.select();
  } else if (window.getSelection) {
    var range = document.createRange() as any;
    range.selectNode(document.getElementById(containerId));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }
}

export function stringsEqualCI(a: string, b: string): boolean {
  return typeof a === 'string' && typeof b === 'string'
    ? a.localeCompare(b, undefined, { sensitivity: 'base' }) === 0
    : a === b;
}

export function stringContainsCI(str: string, match: string): boolean {
  return typeof str === 'string' && typeof match === 'string'
    ? str.toLowerCase().includes(match.toLowerCase())
    : false;
}

export function randomNumber(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function randomRgb(includeAlpha?: boolean): {
  r: number;
  g: number;
  b: number;
  a?: number;
} {
  const rgb = {
    r: randomNumber(255),
    g: randomNumber(255),
    b: randomNumber(255),
    a: null,
  };
  if (includeAlpha) {
    rgb.a = Math.random();
  }
  return rgb;
}

export function getAngle(anchor: Point, location: Point): number {
  return (
    ((-(
      Math.atan2(anchor.x - location.x, anchor.y - location.y) *
      (180 / Math.PI)
    ) %
      360) +
      360) %
    360
  );
}

export function setDebug(val) {
  store.dispatch('debug/setDebug', val);
}

export function elementTextWidthPx(elementId: string, text: string): number {
  let el = document.getElementById(elementId);
  if (!el) return 0;
  let fontSizeStr = window
    .getComputedStyle(el, null)
    .getPropertyValue('font-size');
  let fontSize = parseFloat(fontSizeStr);
  let fontFamily = window
    .getComputedStyle(el, null)
    .getPropertyValue('font-family');
  let font = fontSize + 'px ' + fontFamily;
  let canvas = document.createElement('canvas');
  let context = canvas.getContext('2d');
  context.font = font;
  let width = context.measureText(text).width;
  let formattedWidth = Math.ceil(width);
  return formattedWidth;
}

/**
 * Fits the rectangle into specified boundaries both vertically & horizontally
 */
export function fitRectIntoBounds(rect: ISize, bounds: ISize): Size {
  const rectRatio = rect.width / rect.height;
  const boundsRatio = bounds.width / bounds.height;
  const newDimensions = new MutableSize();

  // Rect is more landscape than bounds - fit to width
  if (rectRatio > boundsRatio) {
    newDimensions.width = bounds.width;
    newDimensions.height = rect.height * (bounds.width / rect.width);
  }
  // Rect is more portrait than bounds - fit to height
  else {
    newDimensions.width = rect.width * (bounds.height / rect.height);
    newDimensions.height = bounds.height;
  }
  return newDimensions.toSize();
}

/**
 * Scales the rectangle to match the aspect ratio and minimum size of the specified boundaries
 */
export function scaleRectIntoBounds(rect: ISize, bounds: ISize): Size {
  const rectRatio = rect.width / rect.height;
  const boundsRatio = bounds.width / bounds.height;
  const newDimensions = new MutableSize();

  // Rect is more landscape than bounds - scale to width
  if (rectRatio > boundsRatio) {
    newDimensions.width = Math.max(bounds.width, rect.width);
    newDimensions.height = newDimensions.width * (bounds.height / bounds.width);
  }
  // Rect is more portrait than bounds - scale to height
  else {
    newDimensions.height = Math.max(bounds.height, rect.height);
    newDimensions.width = newDimensions.height * (bounds.width / bounds.height);
  }
  return newDimensions.toSize();
}

export function scaleAndCropToBounds(
  size: Size | { width: number; height: number },
  bounds: Size
): Size {
  var newWidth = 0;
  var newHeight = 0;
  if (size.width > size.height) {
    let scale = bounds.height / size.height;
    newWidth = size.width * scale;
    newHeight = bounds.height;
  } else {
    let scale = bounds.width / size.width;
    newHeight = size.height * scale;
    newWidth = bounds.width;
  }
  return new Size(newWidth, newHeight);
}

export function ensureFullUri(path) {
  if (path.startsWith('http') || path.startsWith('data:')) {
    return path;
  }
  return appConfig.apiBaseUrl + path;
}

export async function blobToBase64(blob: Blob): Promise<string> {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      const dataUrl = reader.result as string;
      const base64 = dataUrl.split(',')[1];
      resolve(base64);
    };
    reader.onerror = () => {
      reject();
    };
  });
}

export function plainTextToHtml(text: string): string {
  text = text
    // Encode <>.
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    // Creates a paragraph for each double line break.
    .replace(/\r?\n\r?\n/g, '</p><p>')
    // Creates a line break for each single line break.
    .replace(/\r?\n/g, '<br>')
    // Preserve trailing spaces (only the first and last one – the rest is handled below).
    .replace(/^\s/, '&nbsp;')
    .replace(/\s$/, '&nbsp;')
    // Preserve other subsequent spaces now.
    .replace(/\s\s/g, ' &nbsp;');

  if (text.includes('</p><p>') || text.includes('<br>')) {
    // If we created paragraphs above, add the trailing ones.
    text = `<p>${text}</p>`;
  }

  return text;
}
export function postForm(url: string, params: any) {
  const formElement = document.createElement('form');
  formElement.setAttribute('method', 'post');
  formElement.setAttribute('action', url);
  formElement.setAttribute('target', '_parent');

  for (let param in params) {
    var hiddenField = document.createElement('input');
    hiddenField.setAttribute('name', param);
    hiddenField.setAttribute('value', params[param]);
    formElement.appendChild(hiddenField);
  }

  document.body.appendChild(formElement);
  formElement.submit();
}

export function appendUrl(baseUrl: string, path: string): string {
  if (!baseUrl.endsWith('/') && !path.startsWith('/')) {
    return `${baseUrl}/${path}`;
  }
  return `${baseUrl}${path}`;
}

export async function calculateHash(value: any): Promise<string> {
  if (!value) {
    return null;
  }
  const data =
    typeof value == 'object' ? JSON.stringify(value) : value.toString();
  const hash = await md5(data);
  return hash;
}

export function wait(timeMs: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeMs);
  });
}

export async function waitCondition(
  condition: () => boolean,
  timeoutMs: number,
  checkIntervalMs: number = 50
): Promise<boolean> {
  if (condition()) {
    return true;
  }
  const startTime = new Date().getTime();
  while (new Date().getTime() - startTime < timeoutMs) {
    if (condition()) {
      return true;
    }
    await wait(checkIntervalMs);
  }
  return false;
}

export function elementsCollide(el1: HTMLElement, el2: HTMLElement) {
  if (!el1 || !el2) return false;

  const rect1 = el1.getBoundingClientRect();
  const rect2 = el2.getBoundingClientRect();

  return !(
    rect1.top > rect2.bottom ||
    rect1.right < rect2.left ||
    rect1.bottom < rect2.top ||
    rect1.left > rect2.right
  );
}

export function hasOwnProperty(obj: object, key: string) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}

export function isVNode(node): boolean {
  return (
    node !== null &&
    typeof node === 'object' &&
    hasOwnProperty(node, 'componentOptions')
  );
}

const Observer = new Vue().$data.__ob__.constructor;

export function nonReactive(value) {
  // Set dummy observer on value
  value.__ob__ = new Observer({});
  value.__ob___ = 'non-reactive';
  return value;
}

export function isPrimitive(arg) {
  const valueType = typeof arg;
  return arg === null || (valueType !== 'object' && valueType !== 'function');
}

export const seedRandom = (seed: number) => {
  const x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
};

export function copyToClipboard(value: string): Promise<void> {
  return navigator.clipboard.writeText(value);
}

/**
 *
 * @param {object} obj An object to lookup
 * @param path A dot notation path (e.g: "obj.a.b")
 * @returns The value that was found in specified path
 */
export const getNestedProp = (obj: object, path: string) => {
  if (!isObject(obj)) {
    return undefined;
  }

  const arr: Array<string> = path.split('.');

  while (arr.length && obj) {
    const shift = arr.shift();
    if (shift) obj = obj?.[shift];
  }

  return obj as any;
};

export const isObject = (obj: object) => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

export const formatCsvString = (value: string) => {
  if (!value) {
    return '';
  }

  return value
    .split(',')
    .filter((x) => x.trim())
    .join(', ');
};

/**
 *
 * @param obj The object to flatten
 * @param next A function to select the next object
 * @param selector A function to create the item to be added to the array
 * @param acc The accumulator, should be null on first call.
 * @returns
 */
export function flattenObject(
  obj: any,
  next: (a) => any,
  selector: (a) => any,
  acc: any[] = null
): any[] {
  if (!acc) {
    acc = [selector(obj)];
  }
  var nested = next(obj);
  if (nested) {
    acc.push(selector(nested));
    flattenObject(nested, next, selector, acc);
  }
  return acc;
}

/**
 * Split Camel or Kebab case to a string with space between words
 * @param input The camel or kebab case string that will be split

 * @returns String with a space between words
 */
export function camelToHuman(input) {
  return input.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });
}
