export default {
  name: null,
  surname: null,
  userName: null,
  emailAddress: null,
  isPhoneNumberConfirmed: null,
  isGoogleAuthenticatorEnabled: null,
  userId: null,
  phoneNumber: null,
  timezone: null,
  qrCodeSetupImageUrl: null,
  authenticationSource: null,
  roles: null,
  language: null,
};
