import { stringContainsCI } from '@/core/utils/common.utils';
import DataPropertyUtils from '@/core/utils/DataPropertyUtils';
import { IEdge, INode } from 'yfiles';
import TextParameter from '../parameters/TextParameter';
import { ITypedMatcher } from './IMatcher';

export default class DataPropertyTextMatcher
  implements ITypedMatcher<INode | IEdge, TextParameter> {
  public canMatch(candidate: any, parameter: TextParameter): boolean {
    return candidate instanceof INode || candidate instanceof IEdge;
  }

  public isMatch(candidate: INode | IEdge, parameter: TextParameter): boolean {
    const dataProperties = candidate.tag?.dataProperties;
    if (!dataProperties) {
      return false;
    }

    const stringContains = stringContainsCI;
    return dataProperties.some((dp) => {
      const definition = DataPropertyUtils.getDefinition(
        dp.dataPropertyDefinitionId
      );
      const displayValue = DataPropertyUtils.getDisplayValue(
        dp.value,
        definition,
        dp.value
      );
      return (
        stringContains(displayValue, parameter?.text) ||
        stringContains(definition?.label, parameter?.text)
      );
    });
  }
}
