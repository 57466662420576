


import { EventBusActions } from '@/core/services/events/eventbus.service';
import { Component, Vue } from 'vue-property-decorator';
import ModalDialogOptions, { ModalDialogType } from './ModalDialogOptions';

@Component
export default class ModalDialogAdapter extends Vue {
  eventBusActionsHandlers: Array<{ action: string; method: Function }> = [
    {
      action: EventBusActions.SHOW_MODAL_DIALOG,
      method: this.showModalDialog,
    },
  ];

  showModalDialog(options: ModalDialogOptions) {
    if (!options) {
      throw 'Must specify modal dialog options';
    }

    const func = (result) => {
      if (options.callback) {
        options.callback(result);
      }
    };

    if (options.type == ModalDialogType.OK) {
      this.$bvModal.msgBoxOk(options.content, options).then(func);
    } else if (options.type == ModalDialogType.Confirm) {
      this.$bvModal.msgBoxConfirm(options.content, options).then(func);
    }
  }
}
