import DataPropertyDefinitionItemApiService from '@/api/DataPropertyDefinitionItemApiService';
import {
  CreateOrEditDataPropertyDto,
  DataPropertyDefinitionItemDto,
  ImageNodeStyleDto,
  NodeVisualType,
} from '@/api/models';

import Vue from 'vue';
import appConfig from '../config/appConfig';
import appConsts from '../config/appConsts';
import {
  EventBus,
  EventBusActions,
} from '@/core/services/events/eventbus.service';
import JigsawExteriorNodeLabelModel from '../services/graph/label-models/JigsawExteriorNodeLabelModel';
import { generateUuid } from './common.utils';
import DataPropertyUtils from './DataPropertyUtils';
import DiagramUtils from './DiagramUtils';
import StyleCreator from './StyleCreator';
import { IGraph, INode } from 'yfiles';
import CKEditorUtils from './CKEditorUtils';
import DataPropertyStyleService from '../services/graph/DataPropertyStyleService';
import { DataPropertyDisplayType } from '../common/DataPropertyDisplayType';
export type PeopleValueType = 'people' | 'role';

export class PeopleUtils {
  static get peopleDefinition() {
    return DataPropertyUtils.getDefinition(appConsts.PEOPLE_DEFINITION_ID);
  }

  static get roleDefinition() {
    return DataPropertyUtils.getDefinition(appConsts.ROLE_DEFINITION_ID);
  }

  public static getPersonDataPropertyFromNode(
    node: INode
  ): DataPropertyDefinitionItemDto {
    if (
      node.tag.dataProperties.some(
        (x) => x.dataPropertyDefinitionId == appConsts.PEOPLE_DEFINITION_ID
      )
    ) {
      let dpd: CreateOrEditDataPropertyDto[] = node.tag.dataProperties.filter(
        (x) => x.dataPropertyDefinitionId == appConsts.PEOPLE_DEFINITION_ID
      );
      if (dpd) {
        //Find correct dpdi. Value is string and also key of dpdi
        let person =
          PeopleUtils.peopleDefinition.dataPropertyDefinitionItems.filter(
            (x) => x.id.toString() == dpd[0].value
          );
        return person[0];
      }
    }
    return null;
  }

  private static async setPersonRole(
    person: DataPropertyDefinitionItemDto,
    node: INode
  ) {
    if (person) {
      let role = this.getRole(person, node);
      this.setDataProperty(appConsts.ROLE_DEFINITION_ID, role.id, node);
    }
    return;
  }

  public static async toggleRoleDisplay(
    selectedNode: INode,
    graph: IGraph,
    showRole: boolean
  ) {
    let person = this.getPersonDataPropertyFromNode(selectedNode);
    let role = this.getRole(person, selectedNode);
    await this.setNodeLabel(selectedNode, graph, person, role, showRole);
  }
  public static async syncPersonNode(
    selectedNode: INode,
    graph: IGraph,
    showRole: boolean
  ) {
    let person = this.getPersonDataPropertyFromNode(selectedNode);
    if (!person) {
      //When a person is removed, the style and label of the node are reset
      DataPropertyStyleService.updateNodeWithThemeStyle(selectedNode, graph);
      this.setNodeLabel(selectedNode, graph, null, null, false);
      return;
    }
    await this.setNodePersonStyle(person, selectedNode, graph);
    await this.setPersonRole(person, selectedNode);
    let role = this.getRole(person, selectedNode);
    //Roles will automatically show on nodes, turned on by default
    EventBus.$emit(
      EventBusActions.DATA_PROPERTIES_DATA_PROPERTY_DISPLAY_TYPE_CHANGED,
      {
        type: 'role',
        node: selectedNode,
        dataPropertyDisplayType: DataPropertyDisplayType.NodeLabel,
        active: true,
      }
    );
    await this.setNodeLabel(selectedNode, graph, person, role, showRole);
    await EventBus.$emit(EventBusActions.PEOPLE_NODE_DECORATOR_CHANGED);
  }

  public static async getPeople() {
    let dpdi = await DataPropertyDefinitionItemApiService.getAll();
    return dpdi?.data?.result.filter(
      (i) => i.dataPropertyDefinitionId == appConsts.PEOPLE_DEFINITION_ID
    );
  }

  //This function will return the dpdi attached to the node, otherwise it falls back to the default (role's parent item)
  //to allow user to overwrite the role of a node, without breaking the dpdi relationship
  public static getRole(
    person: DataPropertyDefinitionItemDto,
    selectedNode: INode
  ) {
    let defaultRole = this.roleDefinition.dataPropertyDefinitionItems.find(
      (x) => x.parentItemId === person.id
    );

    let existingRoleDp = selectedNode.tag.dataProperties.find(
      (x) => x.dataPropertyDefinitionId == appConsts.ROLE_DEFINITION_ID
    );

    return existingRoleDp.value
      ? this.roleDefinition.dataPropertyDefinitionItems.find(
          (x) => x.id == existingRoleDp.value
        )
      : defaultRole;
  }

  public static buildLabelText(
    node: INode,
    roleLabelVisible: boolean,
    role: DataPropertyDefinitionItemDto,
    person: DataPropertyDefinitionItemDto
  ): string {
    let text = '';
    const labelStyleDto = DiagramUtils.getSystemDefaultLabelStyle();

    text += CKEditorUtils.createHtmlStringFromStyle(
      labelStyleDto.fill,
      labelStyleDto.font,
      person.itemValue,
      '#FFFFFF' //Label and label background are white, needed for PPT export
    );
    if (roleLabelVisible && role) {
      labelStyleDto.font.fontSize--;
      text += CKEditorUtils.createHtmlStringFromStyle(
        labelStyleDto.fill,
        labelStyleDto.font,
        `(${role.itemValue})`,
        '#FFFFFF'
      );
    }
    return text ?? '';
  }

  public static setNodeLabel(
    node: INode,
    graph: IGraph,
    person: DataPropertyDefinitionItemDto,
    role: DataPropertyDefinitionItemDto,
    showRole: boolean
  ) {
    if (!person) {
      const labelText = DiagramUtils.getPlaceholderLabelText(node);
      DiagramUtils.setLabelValue(graph, node, labelText);
      return;
    }
    let label = this.buildLabelText(node, showRole, role, person);

    DiagramUtils.setLabelValue(graph, node, label);

    graph.setLabelLayoutParameter(
      node.labels.first(),
      JigsawExteriorNodeLabelModel.SOUTH
    );
  }

  public static async setNodePersonStyle(
    person: DataPropertyDefinitionItemDto,
    selectedNode: INode,
    graph: IGraph
  ) {
    let headshotImageUrl = this.getHeadshot(person);

    // Create a node style
    let style: ImageNodeStyleDto = {
      visualType: NodeVisualType.Image,
      imageUrl: headshotImageUrl,
    };

    let nodeStyle = StyleCreator.createNodeStyle(style);
    await DiagramUtils.setStyle(graph, selectedNode, nodeStyle, true);
  }

  public static getHeadshot(person: DataPropertyDefinitionItemDto): string {
    return `${appConfig.apiBaseUrl}/file/downloadattachment?id=${person.imageData}`;
  }

  public static setDataProperty(
    dataPropertyDefinitionId: number,
    value: number,
    selectedNode: INode
  ) {
    let dataProperty = selectedNode.tag.dataProperties.find(
      (dp) => dp.dataPropertyDefinitionId == dataPropertyDefinitionId
    );

    if (!dataProperty) {
      dataProperty = {
        uuid: generateUuid(),
        dataPropertyDefinitionId: dataPropertyDefinitionId,
        diagramNodeId: selectedNode.tag.id,
      } as CreateOrEditDataPropertyDto;
      selectedNode.tag.dataProperties.push(dataProperty);
    }

    Vue.set(dataProperty, 'value', value);
  }
}
