import i18nService from '@/core/services/i18n.service';
import { CHANGE_USER_LANGUAGE } from '@/core/services/store/user.module';
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@Component({})
export default class UserLanguageMixin extends Vue {
  @Action(CHANGE_USER_LANGUAGE)
  changeUserLanguageAction: (lang: string) => void;

  get languages() {
    return i18nService.languages;
  }

  get activeLanguageDisplayName() {
    if (this.languages?.length) {
      return this.languages.find(
        (l) => l.name === i18nService.getActiveLanguage()
      )?.displayName;
    }

    return 'Language';
  }

  async setLanguage(language) {
    if (this.isSelectedLanguage(language)) {
      return;
    }

    const h = this.$createElement;
    const message = h('span', {
      class: 'font-15',
      domProps: {
        innerHTML: `${this.$t(
          'JIGSAW_WILL_BE_RELOADED_TO_CHANGE_THE_LANGUAGE_TO'
        ).toString()}&nbsp;<strong>${language.displayName}
          </strong>`,
      },
    });

    this.$confirm({
      title: this.$t('CHANGE_SYSTEM_LANGUAGE').toString(),
      text: message,
      okText: this.$t('YES').toString(),
      denyText: this.$t('NO').toString(),
    }).then(async () => {
      await this.changeUserLanguageAction(language.name);
      i18nService.setActiveLanguage(language.name, false);
      window.location.reload();
    });
  }

  isSelectedLanguage(language) {
    return i18nService.getActiveLanguage() == language.name;
  }
}
