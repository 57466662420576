import PdfMakeUtils from './PdfMakeUtils';

type LayoutItem = (i, node) => number | string;
const zeroLayout: LayoutItem = () => 0;
const defaultColorLayout = () => 'white';

export class PdfTableLayouts {
  public static noPaddingTableLayout = PdfTableLayouts.createTableLayout({});

  public static headerFooterTableLayout = {
    paddingTop: (index, node) => {
      PdfMakeUtils.applyVerticalAlignment(node, index, 'center', 0);
      return 0;
    },
    paddingLeft: (i, node) => {
      // Header/footer text
      if (i == 1) {
        return 10;
      }
      return 0;
    },
    paddingRight: (i, node) => {
      if (i == node?.table?.body?.length) {
        return 10;
      }
      return 0;
    },
    paddingBottom: (i, node) => 0,
    hLineWidth: (i, node) => {
      return i === 0 || i === node?.table?.body?.length
        ? node?.table?.borderWidth ?? 0
        : 0;
    },
    vLineWidth: (i, node) => {
      return i === 0 || i === node?.table?.widths?.length
        ? node?.table?.borderWidth ?? 0
        : 0;
    },
    hLineColor: (i, node) => {
      return i === 0 || i === node?.table?.body?.length
        ? node?.table?.borderColor ?? 'white'
        : 'white';
    },
    vLineColor: (i, node) => {
      return i === 0 || i === node?.table?.widths?.length
        ? node?.table?.borderColor ?? 'white'
        : 'white';
    },
  };

  public static textElementTableLayout = {
    paddingTop: (i, node) => 0,
    paddingLeft: (i, node) => 0,
    paddingRight: (i, node) => 0,
    paddingBottom: (i, node) => 0,
    hLineWidth: (i, node) => {
      return i === 0 || i === node?.table?.body?.length
        ? node?.table?.borderWidth ?? 0
        : 0;
    },
    vLineWidth: (i, node) => {
      return i === 0 || i === node?.table?.widths?.length
        ? node?.table?.borderWidth ?? 0
        : 0;
    },
    hLineColor: (i, node) => {
      return i === 0 || i === node?.table?.body?.length
        ? node?.table?.borderColor ?? 'white'
        : 'white';
    },
    vLineColor: (i, node) => {
      return i === 0 || i === node?.table?.widths?.length
        ? node?.table?.borderColor ?? 'white'
        : 'white';
    },
  };

  public static createTableLayout(args: {
    paddingTop?: LayoutItem;
    paddingBottom?: LayoutItem;
    paddingLeft?: LayoutItem;
    paddingRight?: LayoutItem;
    hLineWidth?: LayoutItem;
    vLineWidth?: LayoutItem;
    hLineColor?: LayoutItem;
    vLineColor?: LayoutItem;
  }) {
    return {
      paddingTop: args.paddingTop ?? zeroLayout,
      paddingBottom: args.paddingBottom ?? zeroLayout,
      paddingLeft: args.paddingLeft ?? zeroLayout,
      paddingRight: args.paddingRight ?? zeroLayout,
      hLineWidth: args.hLineWidth ?? zeroLayout,
      vLineWidth: args.vLineWidth ?? zeroLayout,
      hLineColor: args.hLineColor ?? defaultColorLayout,
      vLineColor: args.vLineColor ?? defaultColorLayout,
    };
  }
}
