import { Mapper } from 'yfiles';
import { defaultFontStyle } from '../export/pdf/PdfStyles';
import { CommandHandlerType } from './ComandHandlerType';
import CommandManager from './CommandManager';
import { CommandType } from './CommandType';
import ICommandHandler from './ICommandHandler';

export default class CkEditorCommandHandler implements ICommandHandler {
  private suppressEvent: boolean = false;
  private commands: Mapper<CommandType, any>;
  private editor;
  /**
   *
   */
  constructor(editor) {
    this.editor = editor;
    this.setupCommands();
    this.publishAllValues();
  }

  getCommandHandlerType(): CommandHandlerType {
    return CommandHandlerType.CKEditor;
  }

  private setupCommands() {
    this.commands = new Mapper<CommandType, any>();

    this.commands.set(CommandType.bold, this.editor.commands.get('bold'));
    this.commands.set(CommandType.italic, this.editor.commands.get('italic'));
    this.commands.set(
      CommandType.underline,
      this.editor.commands.get('underline')
    );
    this.commands.set(
      CommandType.fontsize,
      this.editor.commands.get('fontSize')
    );
    this.commands.set(CommandType.indent, this.editor.commands.get('indent'));
    this.commands.set(
      CommandType.imageinsert,
      this.editor.commands.get('uploadImage')
    );
    this.commands.set(
      CommandType.bulletedList,
      this.editor.commands.get('bulletedList')
    );
    this.commands.set(
      CommandType.numberedList,
      this.editor.commands.get('numberedList')
    );
    this.commands.set(
      CommandType.fontfamily,
      this.editor.commands.get('fontFamily')
    );
    this.commands.set(
      CommandType.fontcolor,
      this.editor.commands.get('fontColor')
    );
    this.commands.set(
      CommandType.fontbackgroundcolor,
      this.editor.commands.get('fontBackgroundColor') // <<<<< DLS INCORRECT
    );

    this.commands.set(
      CommandType.heading,
      this.editor.commands.get('heading') // <<<<< DLS INCORRECT
    );

    this.commands.entries.forEach((c) => {
      if (!c.value) {
        return;
      }
      c.value.on('change:isEnabled', (evt, name, isEnabled) => {
        CommandManager.INSTANCE.isEnabled(evt.source.attributeKey, isEnabled);
      });

      c.value.on('change:value', (evt, name, value) => {
        if (!this.suppressEvent) {
          let key = evt.source.attributeKey;
          if (key) {
            key = key.toLowerCase();
          }
          const command = CommandType[key] as unknown as CommandType;
          this.publishValue(command, value);
        }
      });
    });
  }

  async executeCommand(command: CommandType, value: any) {
    this.suppressEvent = true;
    //&& this.isFocused) {
    var cmd = this.commands.get(command);
    if (command == CommandType.fontcolor) {
      this.editor.execute('fontColor', { value: value });
    } else if (command == CommandType.fontsize) {
      this.editor.execute('fontSize', {
        value: this.formatValue(command, value),
      });
    } else if (command == CommandType.fontbackgroundcolor) {
      this.editor.execute('fontBackgroundColor', { value: value });
    } else if (command == CommandType.imageinsert) {
      //this.editor.execute('uploadImage');
      cmd.execute({ file: value });
    } else if (command == CommandType.fontfamily) {
      // Need to Map Fonts -> CKEditor
      //this.editor.execute('fontFamily', { value: 'Arial' });
      this.editor.execute('fontFamily', {
        value: this.formatValue(command, value),
      });
    } else if (command == CommandType.paste) {
      const dt = await this.getTextDataFromClipboardOrDefault();
      if (dt) {
        try {
          this.editor.editing.view.document.fire('paste', {
            dataTransfer: dt,
            preventDefault: (a) => [],
            stopPropagation: () => {},
          });
        } catch (ex) {
          console.error(ex);
        }
      }
    } else if (command == CommandType.copy) {
      // document.execCommand is deprecated. Instead of it we can use navigator.clipboard.write() (Clipboard Api)
      // and ckeditor clipboardOutput event from the ckeditor clipboard plugin (clipboardpipeline.js)
      // But on the moment of this implementation clipboard.write method supports only one type as argument in array
      // like only 'text/html' or "text/html" but we need both of them. When multiple types of clipboard.write will
      // be implemented - then we can use it instead of document.execCommand
      // Examples how can be used ckeditor cut/copy events can be found in tests:
      // https://github.com/ckeditor/ckeditor5-clipboard/blob/master/tests/clipboard.js
      // Also example of cut/copy is in the bottom of this file
      document.execCommand('copy');
    } else if (command == CommandType.cut) {
      document.execCommand('cut');
    } else if (command == CommandType.undo) {
      this.editor.execute('undo');
    } else if (command == CommandType.redo) {
      this.editor.execute('redo');
    } else {
      if (cmd && cmd.value != value) cmd.execute(value);
    }
    this.suppressEvent = false;
    this.editor.focus();
  }

  appendCommand(commandType: CommandType, command: string) {}

  protected formatValue(command, value) {
    if (!value) {
      return value;
    }
    if (command == CommandType.fontsize) {
      if (value > 0 && value <= 72) {
        return value + 'pt';
      }
    }
    if (command == CommandType.fontfamily) {
      if (value) {
        if (value.indexOf(' ') > 0) {
          return `'${value}'`;
        } else {
          return value;
        }
      }
    }
    return value;
  }

  protected formatManagerValue(command: CommandType, value: any) {
    if (!value) {
      return value;
    }
    if (command == CommandType.fontsize) {
      return Number.parseInt(value.toString().replace('pt', ''));
    }
    if (command == CommandType.fontfamily) {
      return value.replaceAll("'", '').replaceAll('"', '');
    }
    return value;
  }

  /**
   * Takes each command type, gets the correct value, the publishes this out for any listeners
   */
  publishAllValues() {
    this.commands.entries.forEach((c) => {
      if (c.value) {
        let value = c.value.value;
        if (value === undefined || value === null) {
          value = this.getDefaultValue(c.value.attributeKey);
        }
        if (value !== undefined && value !== null) {
          this.publishValue(c.key, this.formatManagerValue(c.key, value));
        }
      }
    });
  }

  /**
   * If we can't get a value from CKEditor (usually when the content is empty), reset it to default
   */
  getDefaultValue(key: string): any {
    switch (key) {
      case 'fontSize':
        return defaultFontStyle.fontSize + 'pt';
      case 'fontFamily':
        return defaultFontStyle.font;
      default:
        return null;
    }
  }

  publishValue(commandType: CommandType, value: any) {
    CommandManager.INSTANCE.managerValueChanged(
      commandType,
      this.formatManagerValue(commandType, value)
    );
  }

  private async getTextDataFromClipboardOrDefault(): Promise<any> {
    try {
      const clipboardItem = (await (navigator.clipboard as any).read())[0];
      const clipboardData = {};
      const clipboardTypes = clipboardItem.types;
      for (const type of clipboardTypes) {
        if (type == 'text/html' || type == 'text/plain' || type == 'text/rtf') {
          const blob = await clipboardItem.getType(type);
          clipboardData[type] = await blob.text();
        }
      }
      return new DataTransfer(clipboardTypes, clipboardData);
    } catch (ex) {
      console.error(ex);
      return null;
    }
  }
}

// Use only for ckeditor paste event
class DataTransfer {
  data = [];
  files = [];
  types = [];
  getData = (type) => {
    return this.data[type];
  };

  constructor(types, data, files?) {
    this.types = types;
    this.data = data;
    if (files) {
      this.files = files;
    }
  }
}

// Implementation of using ckEditor cut/copy events and Clipboard Api
// private async generateCopyCutEvent(eventName: 'copy' | 'cut') {
//   let evt = new ClipboardEvent(eventName, {
//     clipboardData: new DataTransfer(),
//   });
//   const modelDocument = this.editor.model.document;
//   const content = this.editor.data.toView(
//     this.editor.model.getSelectedContent(modelDocument.selection)
//   );

//   // CKDataTransfer is facade over native DataTransfer object and can be found inside of ckEditor
//   let dt = new CKDataTransfer(evt.clipboardData);
//   let blob = new Blob([this.editor.data.htmlProcessor.toData(content)], {
//     type: 'text/html',
//   });
//   // viewToPlainText should be taken from ckeditor clipboard plugin from clipboardpipeline.js
//   let text = this.viewToPlainText(content);
//   let blob2 = new Blob([text], { type: 'text/plain' });
//   // Multiple types are not supported yet
//   let data = [new ClipboardItem({ 'text/plain': blob2 }), new ClipboardItem({ ['text/html']: blob })];

//   this.editor.editing.view.document.fire('clipboardOutput', {
//     dataTransfer: dt,
//     content,
//     method: eventName,
//   });
// }
