import { Features } from '@/core/common/Features';
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class FeaturesMixin extends Vue {
  hasFeature(feature: Features): boolean {
    return this.$store.state.licence.features.includes(feature);
  }
  hasAnyFeature(features: Features[]): boolean {
    let hasAny: boolean = false;
    features.forEach((feature) => {
      if (!hasAny) {
        hasAny = this.$store.state.licence.features.includes(feature);
      }
    });
    return hasAny;
  }
  get featureType(): typeof Features {
    return Features;
  }
}
