



































































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'AppModal',
})
export default class AppModal extends Vue {
  @Prop({ required: true })
  value: boolean;

  @Prop({ default: 'Modal title' })
  title: string;

  @Prop({ default: true })
  closeOnEsc: boolean;

  @Prop({ default: true })
  closeOnBackdrop: boolean;

  @Prop({ default: null })
  beforeClose: (fn: any) => Promise<boolean>;

  @Prop({ default: 560 })
  minWidth: string | number;

  @Prop({ default: 760 })
  maxWidth: string | number;

  @Prop({ default: 'j-rounded-8' })
  modalClasses: string;

  @Prop({ default: 'j-p-36' })
  modalPaddingClasses: string;

  @Prop({ default: false })
  loading: boolean;

  @Prop({ default: true })
  contentVerticalScroll: boolean;

  get modalStyles() {
    return {
      minWidth: `${this.minWidth}px`,
      maxWidth: `${this.maxWidth}px`,
    };
  }

  handleClose() {
    if (!this.beforeClose) {
      this.close();
      return;
    }

    if (typeof this.beforeClose === 'function') {
      new Promise((resolve, reject) => {
        this.beforeClose({ close: resolve, keep: reject });
      })
        .then(() => {
          console.debug('Closed');
          this.close();
        })
        .catch(() => {
          console.debug('Closing denied');
        });
    }
  }

  backdropClick() {
    this.handleClose();
  }

  close() {
    this.$emit('input', false);
    this.$emit('closed');
  }

  mounted() {
    const escHandler = (evt: KeyboardEvent) => {
      if (!this.closeOnEsc) return;

      if (evt.key === 'Escape' && this.value) {
        this.handleClose();
      }
    };

    document.addEventListener('keydown', escHandler);

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escHandler);
    });
  }
}
