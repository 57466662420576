



































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import { GET_CURRENT_USER } from '@/core/services/store/user.module';

import UserLanguageMixin from '@/mixins/UserLanguage.mixin';
import PermissionMixin from '@/mixins/Permission.mixin';
import { CurrentUserProfileEditDto } from '@/api/models';
import { Getter } from 'vuex-class';
import { ApplicationPermissions } from '@/api/ApplicationPermissions';

@Component({
  name: 'SidebarProfile',
})
export default class SidebarProfile extends mixins(
  UserLanguageMixin,
  PermissionMixin
) {
  @Getter(GET_CURRENT_USER)
  currentUser: CurrentUserProfileEditDto;
}
