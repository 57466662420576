// action types

import { Module } from 'vuex';

export const DEBUG_NAMESPACE = 'debug';
const debugModule: Module<any, any> = {
  namespaced: true,
  state: {
    enabled: localStorage.getItem('debugEnabled') == 'true',
  },
  actions: {
    setDebug: function (ctx, enabled) {
      ctx.state.enabled = enabled;
      localStorage.setItem('debugEnabled', enabled);
    },
  },
  mutations: {},
};

export default debugModule;
