import { PageElementPosition } from '@/api/models';
import Point from '@/core/common/Point';
import { elementToSVG, inlineResources } from '@jigsaw/dom-to-svg';
import { ExportPageElementType } from './ExportPageElementType';

export default class ExportPageElement {
  /**
   * Called when export is finished
   * Use to dispose of temporary resources
   */
  destroy: () => void;

  constructor(
    public key: any,
    public type: ExportPageElementType,
    public element: Element,
    public options: IExportPageElementOptions,
    private augmenters?: {
      selector: string;
      handler: (node: Element) => Promise<void>;
    }[]
  ) {}

  toHtml(): string {
    return this.element.innerHTML;
  }

  private cachedSvg: SVGElement = null;
  async toSvgAsync(): Promise<SVGElement> {
    if (this.cachedSvg) {
      return this.cachedSvg;
    }
    const svgDocument = elementToSVG(this.element);
    await inlineResources(svgDocument.documentElement);

    if (this.augmenters) {
      for (let a of this.augmenters) {
        const nodes = svgDocument.documentElement.querySelectorAll(a.selector);
        for (let n of nodes) {
          await a.handler(n);
        }
      }
    }

    this.cachedSvg = svgDocument.documentElement as unknown as SVGElement;
    return this.cachedSvg;
  }
}

export interface IExportPageElementOptions {
  position: PageElementPosition | Point;
  padding: number;
}
