import DecorationStateManager from '@/core/services/DecorationStateManager';
import store from '@/core/services/store';
import TooltipHelper from '@/core/services/TooltipHelper';
import DocumentUtils from '@/core/utils/DocumentUtils';
import {
  GraphComponent,
  GraphEditorInputMode,
  IInputModeContext,
  INode,
  IRenderContext,
  Matrix,
  Point,
  Rect,
  Size,
  SvgVisual,
  SvgVisualGroup,
  Visual,
} from 'yfiles';
import DecorationState from '../DecorationState';
import HitResult from '../HitResult';
import JigsawNodeStyle from '../JigsawNodeStyle';
import ImageButtonNodeDecorator from './ImageButtonNodeDecorator';
import JigsawNodeDecorator from './JigsawNodeDecorator';
import { QuickBuildMode } from '@/core/styles/decorators/QuickBuildMode';
import { QuickBuildState } from '@/api/models';
import { setDebug } from '@/core/utils/common.utils';
import {
  DOCUMENT_NAMESPACE,
  GET_READONLY,
} from '@/core/services/store/document.module';
export enum QuickBuildButtons {
  TOP,
  BOTTOM,
  LEFT,
  RIGHT,
}

export class QuickBuildButtonsNodeDecoratorMeta {
  constructor(public button: QuickBuildButtons) {}
}

export interface QuickBuildButtonsState extends DecorationState {
  buttons: [
    {
      alwaysVisible: boolean;
    }
  ];
}

/**
 * Quick build buttons node decorator
 */
export default class QuickBuildButtonsNodeDecorator
  implements JigsawNodeDecorator
{
  public $class = 'QuickBuildButtonsNodeDecorator';
  public static INSTANCE: QuickBuildButtonsNodeDecorator =
    new QuickBuildButtonsNodeDecorator();

  private imageButtonNodeDecorator: ImageButtonNodeDecorator;
  private simpleQuickBuildImage =
    '/media/yFiles/icons/chevron-circle-left-solid.svg';
  private algoQuickBuildImage = '/media/yFiles/icons/plus-circle-solid.svg';
  private algoQuickBuildHoverImage =
    '/media/yFiles/icons/plus-circle-solid-hovered.svg';
  private readonly size = new Size(28, 28);

  private buttonLocations = [
    QuickBuildButtons.LEFT,
    QuickBuildButtons.TOP,
    QuickBuildButtons.RIGHT,
    QuickBuildButtons.BOTTOM,
  ];

  constructor(options?: { size?: Size }) {
    if (options?.size) {
      this.size = options.size;
    }
    // create imageButton to proxy the rendering
    this.imageButtonNodeDecorator = new ImageButtonNodeDecorator({
      imageSrc: null,
      fallbackImageSrc: null,
      size: this.size,
    });
  }

  isHit(context: IInputModeContext, location: Point, node: INode): HitResult {
    const gc = context.canvasComponent as GraphComponent;

    const buttonLocations = this.buttonLocations;
    const state = this.getState(node);

    for (let i = 0; i < buttonLocations.length; ++i) {
      let quickBuildButtonLocation = buttonLocations[i];
      // We need to process isHit if decorator is in alwaysVisible state
      if (
        !state.buttons[i].alwaysVisible &&
        !this.quickStartEnabled &&
        (!gc.selection.isSelected(node) ||
          gc.selection.selectedNodes.size !== 1)
      ) {
        return HitResult.NONE;
      }

      this.imageButtonNodeDecorator.getLayout = (node) =>
        this.getLocation(node, quickBuildButtonLocation);
      let hitResult = this.imageButtonNodeDecorator.isHit(
        context,
        location,
        node
      );

      if (hitResult.isHit) {
        hitResult.decoratorType = this.$class;
        hitResult.meta = new QuickBuildButtonsNodeDecoratorMeta(
          quickBuildButtonLocation
        );
        return hitResult;
      }
    }
    return HitResult.NONE;
  }

  isVisible(context: IRenderContext, node: INode): boolean {
    const gc = context.canvasComponent as GraphComponent;
    const isReadonly = store.getters[
      `${DOCUMENT_NAMESPACE}/${GET_READONLY}`
    ] as boolean;

    if (isReadonly) {
      return false;
    }

    let geim = gc.inputMode as GraphEditorInputMode;
    if (!geim) {
      return false;
    }
    if (geim.createEdgeInputMode?.isCreationInProgress) {
      return false;
    }

    return true;
  }

  private getImage(hitResult: HitResult) {
    if (this.quickStartEnabled) {
      const hitBottomButton =
        hitResult.isHit &&
        (hitResult.meta as QuickBuildButtonsNodeDecoratorMeta).button ==
          QuickBuildButtons.BOTTOM;
      return hitBottomButton
        ? this.algoQuickBuildHoverImage
        : this.algoQuickBuildImage;
    }
    return this.simpleQuickBuildImage;
  }

  createVisual(context: IRenderContext, node: INode): Visual {
    let group = new SvgVisualGroup();

    // ButtonLocations array for simpleQuickBuild decorators and for algoQuickBuild decorator
    // should be different that force yfiles to create new visual and correctly rerender decorator images
    const buttonLocations = this.quickStartEnabled
      ? [QuickBuildButtons.BOTTOM]
      : this.buttonLocations;
    const isNodeSelected = this.isNodeSelected(context, node);
    const state = this.getState(node);
    const hitResult = this.isHit(
      context.canvasComponent.inputModeContext,
      context.canvasComponent.lastMouseEvent.location,
      node
    );
    for (let index = 0; index < buttonLocations.length; index++) {
      const buttonLocation = buttonLocations[index];

      // get image for current button
      this.imageButtonNodeDecorator.imageSrc = this.getImage(hitResult);
      // change layout fn for current button
      this.imageButtonNodeDecorator.getLayout = (node) =>
        this.getLocation(node, buttonLocation);

      // render the visual for the current button
      let visual = this.imageButtonNodeDecorator.createVisual(
        context,
        node
      ) as SvgVisual;

      // store canavas on svgElement (this probably needs changing)
      visual.svgElement[JigsawNodeStyle.CanvasComponentKey] =
        context.canvasComponent;

      /* Configure some defaults */

      this.applyRotateTransform(buttonLocation, node, visual.svgElement);

      this.applySvgClass(visual, node);
      this.attachEvents(visual, node);
      const gc = context.canvasComponent as GraphComponent;
      this.setVisibility(visual, buttonLocation, state, isNodeSelected, gc);
      group.add(visual);
    }
    group[JigsawNodeStyle.RenderCacheKey] = {
      buttonCount: buttonLocations.length,
    };

    return group;
  }

  updateVisual(
    context: IRenderContext,
    node: INode,
    oldVisual: Visual
  ): Visual {
    if (oldVisual == null) {
      return this.createVisual(context, node);
    }
    let oldVisualGroup = oldVisual as SvgVisualGroup;

    // ButtonLocations array for simpleQuickBuild decorators and for algoQuickBuild decorator
    // should be different that force yfiles to create new visual and correctly rerender decorator images
    const buttonLocations = this.quickStartEnabled
      ? [QuickBuildButtons.BOTTOM]
      : this.buttonLocations;

    const renderCache = oldVisualGroup[JigsawNodeStyle.RenderCacheKey];
    if (renderCache?.buttonCount != buttonLocations.length) {
      return this.createVisual(context, node);
    }

    const isNodeSelected = this.isNodeSelected(context, node);
    const state = this.getState(node);
    const hitResult = this.isHit(
      context.canvasComponent.inputModeContext,
      context.canvasComponent.lastMouseEvent.location,
      node
    );
    for (let index = 0; index < buttonLocations.length; index++) {
      const buttonLocation = buttonLocations[index];

      // get image for current button
      this.imageButtonNodeDecorator.imageSrc = this.getImage(hitResult);
      // change layout fn for current button
      this.imageButtonNodeDecorator.getLayout = (node) =>
        this.getLocation(node, buttonLocation);

      // render the visual for the current button
      let oldVisual = oldVisualGroup.children.elementAt(index);
      let visual = this.imageButtonNodeDecorator.updateVisual(
        context,
        node,
        oldVisual
      ) as SvgVisual;

      /* Configure some defaults */

      this.applyRotateTransform(buttonLocation, node, visual.svgElement);
      this.applySvgClass(visual, node);
      this.attachEvents(visual, node);
      const gc = context.canvasComponent as GraphComponent;
      this.setVisibility(visual, buttonLocation, state, isNodeSelected, gc);

      oldVisualGroup.children.set(index, visual);
    }

    return oldVisualGroup;
  }

  private isEdgeCreationInProgress(graphComponent: GraphComponent): boolean {
    let geim = graphComponent.inputMode as GraphEditorInputMode;

    return geim?.createEdgeInputMode?.isCreationInProgress;
  }

  /**
   * applies a rotational transform to the given svgElement based on the button index
   * @param index
   * @param node
   * @param svgElement
   */
  private applyRotateTransform(
    buttonLocation: QuickBuildButtons,
    node: INode,
    svgElement: SVGElement
  ): void {
    const index = this.buttonLocations.findIndex(
      (location) => location == buttonLocation
    );
    const angle = index * 90;
    const matrix = this.getMatrixTransform(angle, node);
    svgElement.setAttribute('transform', matrix);
  }

  private isNodeSelected(context: IRenderContext, node: INode): boolean {
    return (context.canvasComponent as GraphComponent).selection.isSelected(
      node
    );
  }
  private get quickStartEnabled(): boolean {
    return (
      store?.state?.document?.quickBuildState === QuickBuildState.InProgress ??
      false
    );
  }

  private getMatrixTransform(angle: number, node: INode): string {
    const matrix = new Matrix();
    matrix.rotate(
      (angle / 180) * Math.PI,
      this.imageButtonNodeDecorator.getLayout(node).center
    );
    return matrix.toSvgTransform();
  }

  private getLocation(node: INode, buttonLocation: QuickBuildButtons): Rect {
    const nodeLayout = node.layout;
    let size =
      this.imageButtonNodeDecorator.dummyDecorationNode.layout.toSize();
    let distanceFromNodeX = -this.size.height / 2;
    let distanceFromNodeY = -this.size.width / 2;
    let x, y;
    switch (buttonLocation) {
      case QuickBuildButtons.TOP:
        x = nodeLayout.width * 0.5 - size.width * 0.5;
        y = -distanceFromNodeY - size.height;
        break;
      case QuickBuildButtons.BOTTOM:
        x = nodeLayout.width * 0.5 - size.width * 0.5;
        y =
          nodeLayout.height +
          distanceFromNodeY +
          (this.quickStartEnabled ? 20 : 0);
        break;
      case QuickBuildButtons.LEFT:
        x = -distanceFromNodeX - size.width;
        y = nodeLayout.height * 0.5 - size.height * 0.5;
        break;
      case QuickBuildButtons.RIGHT:
        x = nodeLayout.width + distanceFromNodeX;
        y = nodeLayout.height * 0.5 - size.height * 0.5;
        break;
    }

    x += nodeLayout.x;
    y += nodeLayout.y;

    let rect = new Rect(x, y, size.width, size.height);
    return rect;
  }

  private applySvgClass(visual: SvgVisual, node: INode) {
    visual.svgElement.classList.add('quickbuild-button');
    visual.svgElement.style.width = `${this.size.width}px`;
    visual.svgElement.style.height = `${this.size.height}px`;
  }

  private attachEvents(visual: SvgVisual, node: INode) {
    const state = this.getState(node);
    let image: SVGImageElement = null;

    visual.svgElement.addEventListener('mousedown', (e) => {
      image = e.target as SVGImageElement;
      const isAlreadyVisible = Number(image.style.opacity) > 0;
      const isPrimaryButton = e.button == 0;
      if (isPrimaryButton && isAlreadyVisible) {
        image.style.opacity = '0.8';
        image.style.width = `${this.size.width - 2}px`;
        image.style.height = `${this.size.height - 2}px`;
      }
    });

    visual.svgElement.addEventListener('mouseup', (e) => {
      if (!image) {
        return;
      }
      const isPrimaryButton = e.button == 0;
      const isAlreadyVisible = Number(image.style.opacity) > 0;
      if (isPrimaryButton && isAlreadyVisible) {
        let image: SVGImageElement = e.target as SVGImageElement;
        image.style.opacity = '1';
        image.style.width = `${this.size.width}px`;
        image.style.height = `${this.size.height}px`;
      }
    });
  }

  private setVisibility(
    visual: SvgVisual,
    buttonLocation: QuickBuildButtons,
    state: QuickBuildButtonsState,
    isNodeSelected: boolean,
    graphComponent: GraphComponent
  ) {
    const noEdgesSelected = graphComponent.selection.selectedEdges.size === 0;

    const index = this.buttonLocations.findIndex(
      (location) => location == buttonLocation
    );

    if (noEdgesSelected && state.buttons[index].alwaysVisible) {
      this.show(visual.svgElement);
      return;
    }

    if (
      !this.isEdgeCreationInProgress(graphComponent) &&
      this.quickStartEnabled &&
      buttonLocation == QuickBuildButtons.BOTTOM
    ) {
      // during quick start we allow the button button to be visible
      this.show(visual.svgElement);
      return;
    }

    // don't show any other buttons during quickstart, even when node is selected
    if (
      noEdgesSelected &&
      isNodeSelected &&
      graphComponent.selection.selectedNodes.size === 1 &&
      !this.quickStartEnabled &&
      !this.isEdgeCreationInProgress(graphComponent)
    ) {
      this.show(visual.svgElement);
      return;
    }
    this.hide(visual.svgElement);
  }

  private show(element: SVGElement) {
    element.style.animation = '';
    element.style.opacity = '1';
    element.style.cursor = 'pointer';
  }

  private hide(element: SVGElement) {
    element.style.opacity = '0';
    element.style.cursor = 'default';
  }

  private getState(node: INode): QuickBuildButtonsState {
    return DecorationStateManager.getState(
      QuickBuildButtonsNodeDecorator.INSTANCE,
      node
    ) as QuickBuildButtonsState;
  }

  public defaultState(): QuickBuildButtonsState {
    const buttons = [];
    for (let i = 0; i < this.buttonLocations.length; i++) {
      buttons.push({
        hovered: false,
        alwaysVisible: false,
        quickBuildMode: QuickBuildMode.Simple,
        hoverTimer: null,
      });
    }
    return { buttons: <any>buttons };
  }
}
