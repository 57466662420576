















import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import appInfo from '@/app-info.json';

import { GET_TENANT_CONFIG } from '@/core/services/store/tenant.module';

@Component({
  components: {},
  computed: {
    ...mapGetters([GET_TENANT_CONFIG]),
    appVersion(): string {
      return appInfo.version;
    },
    appReleaseDate(): string {
      return appInfo.buildDate;
    },
  },
})
export default class AppVersion extends Vue {
  showVersion = false;
  debugInfo: string = null;

  mounted() {
    this.showVersion = JSON.parse(
      localStorage.getItem('show-version') ?? 'false'
    );
  }

  toggleVersion(e) {
    this.showVersion = !this.showVersion;
    localStorage.setItem('show-version', JSON.stringify(this.showVersion));
  }
}
