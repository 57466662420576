






import ExportConfig from '@/core/config/ExportConfig';
import { ensureFullUri, getImageSize } from '@/core/utils/common.utils';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
@Component({})
export default class ImageElement extends Vue {
  @Prop()
  item: any;

  mounted() {
    if (!this.item.meta) {
      throw 'meta not set';
    }
    this.updateImageSize();
  }

  @Watch('imgSrc')
  private onImgSrcChange() {
    this.updateImageSize();
  }

  private updateImageSize() {
    var fullUri = ensureFullUri(this.item.meta.imageSrc);
    getImageSize(fullUri).then((size) => {
      var newWidth = 0;
      var newHeight = 0;
      if (size.width > size.height) {
        let scale = ExportConfig.pageSize.height / size.height;
        newWidth = size.width * scale;
        newHeight = ExportConfig.pageSize.height;
      } else {
        let scale = ExportConfig.pageSize.width / size.width;
        newHeight = size.height * scale;
        newWidth = ExportConfig.pageSize.width;
      }

      this.imageStyle = {
        height: `${newHeight}px`,
        width: `${newWidth}px`,
      };
    });
  }

  get imgSrc() {
    return ensureFullUri(this.item.meta.imageSrc);
  }

  imageStyle: any = {
    width: '100%',
  };
}
