import { DiagramDto } from '@/api/models';
import { DefaultGraph, IGraph } from 'yfiles';
import DiagramReader from './serialization/diagram-reader.service';

/**
 * This will allow you to load a diagram into an IGraph which can be manipulated without a graph component
 */
export default class BackgroundGraphService {
  private _graph: IGraph;
  constructor(private diagramDto: DiagramDto) {
    this._graph = new DefaultGraph();
    DiagramReader.toGraphFromDiagram(this.graph, this.diagramDto);
  }

  public get graph(): IGraph {
    return this._graph;
  }
}
