























































import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'Tabs',
})
export default class Tabs extends Vue {
  activeTab1 = '';
}
