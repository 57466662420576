












































































































import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'Buttons',
})
export default class Buttons extends Vue {
  count = 0;
}
