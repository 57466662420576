import { GraphComponent, GraphMLSupport, Size } from 'yfiles';
import b64toBlob from '../../graph/b64ToBlob';
import ExportOptions from '../ExportOptions';
import IExportProvider from './IExportProvider';
import IExportResult from './IExportResult';
import { Base64Utils } from '@/core/utils/Base64Utils';

export default class GraphMLExportProvider implements IExportProvider {
  private _fileExtension = 'graphml';
  private _mimeType = 'application/xml';

  async exportGraphAsBase64(
    options: ExportOptions,
    graphComponent: GraphComponent
  ): Promise<IExportResult> {
    const graphMLString = await options.graphMLSupport.saveFile(
      graphComponent.graph
    );
    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      result: Base64Utils.encode(graphMLString),
    };
  }

  async exportGraphAsBlob(
    options: ExportOptions,
    graphComponent: GraphComponent,
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    const exportResult = await this.exportGraphAsBase64(
      options,
      graphComponent
    );
    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      size: exportResult.size,
      result: b64toBlob(exportResult.result, this._mimeType),
    };
  }
}
